import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ContentChange, QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { HttpServices } from '../../../utilities/_services/http.service';
import { MasterServices } from '../../../utilities/_services/master.service';

export interface PHDialogData {
  text: string;
  headerName: string;
  phtypeId: any;
}
const parchment = Quill.import('parchment')
const block = parchment.query('block')
block.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true)

@Component({
  selector: 'app-termsAndCondition',
  templateUrl: './termsAndCondition.component.html',
  styleUrls: ['./termsAndCondition.component.scss']
})
export class termsAndConditionComponent implements OnInit {
  submitted = false;
  emailtemplateForm: FormGroup | any;
  phdetails: any = [];
  lstEmailTemplate: any;
  defaultselectedOption: any;
  loading = false;
  SucessBody: any;

  @Input() sendEmailmodal: string = 'hide';
  @Input() sendTitle: string = '';
  @Input() typeID: string = '7'
  @Input() text: string;
  @Output() hidepoSendEmailmodal = new EventEmitter();

  @ViewChild(QuillEditorComponent, { static: true }) body: QuillEditorComponent
  @ViewChild('showmodal') public showemailmodal: ModalDirective;
  constructor(private formBuilder: FormBuilder, private http: HttpServices, private el: ElementRef,
    private master: MasterServices, private alertMessage: AlertMessage, @Inject(MAT_DIALOG_DATA) public data: PHDialogData) {
    this.phdetails = [];
  }

  ngOnInit(): void {
    this.emailFormValues();
    this.body
      .onContentChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((data: ContentChange) => {
        this.text = data.html;
      })
  }

  ngOnChanges() {
    if (this.sendEmailmodal == 'show') {
      this.showemailmodal.show();
      this.loadPlaceholder(this.typeID);
    }
  }

  get f() { return this.emailtemplateForm.controls; }
  emailFormValues() {
    this.emailtemplateForm = this.formBuilder.group({
      body: [this.text],
    });
  }

  async loadPlaceholder(mapPlaceHolderTypeID) {
    this.phdetails = await this.master.GetPlaceholder(mapPlaceHolderTypeID);
  }

  onSubmit() {
    this.submitted = true;
    if (this.emailtemplateForm.invalid) {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
      firstInvalidControl.focus();
      return;
    }
    if (this.text == undefined || this.text.replace(/<[^>]+>/g, '').trim() == '') {
      const editor = this.body.quillEditor;
      editor.focus();
      this.emailtemplateForm.value.body = null;
      this.text = null;
      return;
    }
    let emailTemplates: any;
    this.submitted = false;
    this.loading = true;

    emailTemplates = this.emailtemplateForm.value;
    this.http.postservice(environment.templateApiUrl + '/EmailTemplate/SendEmailTemplates', emailTemplates).subscribe({
      next: (data: any) => {
        if (data.mailStatus == "1") {
          this.alertMessage.successNotifier(data.message, 0);
          this.reset();
          this.CloseModel();
        }
        else {
          this.alertMessage.errorNotifier(data.error, 0);
        }
        this.loading = false;
      }
    });
  }

  CloseModel() {
    this.submitted = false;
    this.reset();
    this.sendEmailmodal = 'hide';
    this.showemailmodal.hide();
    this.hidepoSendEmailmodal.emit(this.sendEmailmodal);
  }

  reset() {
    this.emailtemplateForm.reset();
  }

  changeCustom(event, codevalue) {
    const editor = this.body.quillEditor;
    if (codevalue != "") {
      let PlaceholderValue: string = codevalue;
      const cursorPosition = editor.getSelection(true).index;
      editor.insertText(cursorPosition, PlaceholderValue);
      editor.setSelection(cursorPosition, PlaceholderValue.length);
    }
  }

}
