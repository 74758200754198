import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  constructor(private _sanitize: DomSanitizer) { }

  isHTML(input) {
    var a = document.createElement('div');
    a.innerHTML = input;
    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType == 1)
        return true;
    }
    return false;
  }

  isURL(input) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    var urls = input.match(urlRegex);
    if ((urls != undefined && urls.length > 0) || input.includes('localhost')) {
      return true;
    } else {
      return false;
    }
  }

  verify(input: string) {
    if (input != undefined && input != null && input != '') {
      if (typeof input == 'number' && !isNaN(input)) {
        return input;
      }
      input = input.normalize('NFKC');
      if (this.isURL(input)) {
        input = "";
        return false;
      }
      else if (this.isHTML(input) || this._sanitize.sanitize(1, input) == '') {
        input = "";
        return false;
      }
      else if (String(input).includes("cmd") || String(input).includes("<script")) {
        input = "";
        return false;
      }
    }
    return true;
  }
}
