<div
[ngClass]="(currentUser.licenseTypeID == 2 && currentUser.isSubscriber == true  && SubscriberOrgID > 0) ? 'trial-add': ((currentUser.licenseTypeID == 6 && currentUser.licenseRemaining <= 7 && isDigiSMESuccess == false) ? 'trial-add': ((currentUser.licenseTypeID !== 2 && this.currentUser.orgCountryID==129 && hidegstDiv==false) ? 'trial-remove dboard-add' : 'trial-remove')) ">
  <app-header id="btn-app-header" [navbarBrandRouterLink]="['/dashboard']" [fixed]="true" [navbarBrandFull]="logoimg"
    [navbarBrandMinimized]="minilogoimg" [sidebarToggler]="'lg'" [asideMenuToggler]="''"
    [mobileAsideMenuToggler]="false">
    <div class="trial-alert1"
      *ngIf="currentUser.licenseTypeID == 2 && currentUser.isSubscriber == true && SubscriberOrgID > 0">
      <div class="p-1">
        You have {{currentUser.licenseRemaining}} days left in your trial. Please contact with Into-Tech team.
        <button id="btnredirectSubscriptionPayment" class="ml-3 btn btn-success btn-sm d-none"
          (click)="redirectSubscriptionPayment()" *ngIf="currentUser.orgCountryID != 196">Choose plan to Buy</button>
        <button id="btnredirectToBookDemo" *ngIf="this.isProduction==false" type="button" class="btn btn-info btn-sm"
          (click)="redirectToBookDemo()">Book
          free Training</button>
      </div>
    </div>   

    <div class="trial-alert1" *ngIf="currentUser.licenseTypeID == 6">      
      <div class="p-1">
        <ng-container *ngIf="currentUser.licenseRemaining <= 7 && isDigiSMESuccess == false">
          <ng-container *ngIf="currentUser.licenseRemaining > 0">Your license will be expire in {{currentUser.licenseRemaining}} day(s)</ng-container>
          <ng-container *ngIf="currentUser.licenseRemaining == 0">Your license will be expire on today</ng-container>
          <!-- <button id="btndigismePurchase" class="ml-3 btn btn-primary btn-sm" (click)="ondigisme('P');">Purchase now</button> -->
        </ng-container>
        <!-- <ng-container>
          <button id="btnredirectToBookDemo" type="button" class="btn btn-info btn-sm"
          (click)="redirectToBookDemo()">Book free Training</button>
        </ng-container> -->
      </div>
    </div>
 
    <div class="trial-alert1"
      *ngIf="this.isProduction==false && currentUser.licenseTypeID == 1 && currentUser.isSubscriber == true && SubscriberOrgID > 0">
      <div class="p-1">
        <button id="btnredirectToBookDemo" class="btn btn-info btn-sm" (click)="redirectToBookDemo()">Book
          free Training</button>
      </div>
    </div>

    <!-- <div class="dashboard-alert"  *ngIf="currentUser.licenseTypeID !== 2 && this.currentUser.orgCountryID==129 && hidegstDiv==false">
      <div class="p-1 w-75 mx-auto">
        8% Service Tax rate is currently available. Please click Update button if you would like to auto-update tax codes SV-6 6% and SVI-6 6% which are applied in Contacts, Items and Chart of Accounts to SV-8 8% and SVI-8 8%. 
        Note: The 6% rate can still be used.
        <button type="button" class="btn btn-sm btn-primary" *userCanEdit="[true]" (click)="UpdateMYTaxRate();">Update</button>        
      </div>
      <button class="ml-3 btn btn-sm btn-danger" style="position: absolute;top: 10px;right: 25px;" (click)="hidegstDiv=true">X</button>
    </div> -->

    <ul class="nav navbar-nav ml-auto c-header-nav ml-auto mr-2">
      <!-- <li *ngIf="currentUser.isSubscriber == true && SubscriberOrgID > 0">
        <div class="c-icon" title="Add organization"><span class="material-icons-outlined text-info" (click)='OpenAddOrg()'>
          add_business
          </span></div> 
      </li> -->

      <ng-container *ngIf="currentUser.accessRightID != 1">
        <li class="c-header-nav-item  mx-1 dropdown" *ngIf="shortcutnav.length > 0">
          <a id="btnsidePanel-toggle" class="c-header-nav-link " data-bs-toggle="dropdown" role="button"
            aria-haspopup="true" aria-expanded="false" title='{{"Title.Shortcuts" | translate}}' (click)="sidePanel=false">
            <div class="c-icon">
              <span class="material-icons-outlined text-warning">add_circle_outline</span>
            </div>
          </a>
          <ul class="dropdown-menu dropdown-menu-right pt-0">
            <ng-container *ngFor="let s of shortcutnav">
              <li><a id="bnt-{{s.name}}" class="dropdown-item" routerLink={{s.url}}>{{s.name | translate}}</a></li>
            </ng-container>
          </ul>
        </li>

        <li class="c-header-nav-item  mx-1 dropdown">
          <a id="btnManageFavorite" class="c-header-nav-link " data-bs-toggle="dropdown"
            (click)="ManageFavorite('');sidePanel=false;" role="button" aria-haspopup="true" aria-expanded="false"
            title='{{"Title.Favorite" | translate}}'>
            <div class="c-icon" *ngIf="isFavorite">
              <span class="material-icons-outlined text-warning">star</span>
            </div>
            <div class="c-icon" *ngIf="!isFavorite">
              <span class="material-icons-outlined text-warning">grade</span>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right pt-0">
            <div *ngIf="manageFavorite=='Add'" class="drop-down-add" style="width: 220px;">
              <input class="form-control mr-sm-2 w-100" type="text" placeholder= '{{"Common.Enter favorite menu name" | translate}}'
                [(ngModel)]="favoriteName" xssDirective>
              <div id="btnAddtofavorite" class="t-link" (click)="ManageFavorite(manageFavorite);"><span
                  class="c-icon material-icons-outlined text-warning mr-2">star</span>
                  {{ 'Common.Add to favorite' | translate }} </div>
            </div>
            <div id="btnRemovefromfavorite" *ngIf="manageFavorite=='Remove'" class="drop-down-add t-link"
              style="width: 220px;" (click)="ManageFavorite(manageFavorite);">
              <span class="c-icon material-icons-outlined text-warning mr-2">grade</span>
              {{ 'Common.Remove from favorite' | translate }} 
            </div>
            <ng-container *ngIf="favoritenav.length>0">
              <ng-container *ngFor="let s of favoritenav">
                <ng-container *ngIf="s.favoriteQueryParams == ''">
                  <a id="btn-fav-{{s.PageName}}" class="dropdown-item" routerLink={{s.url}} *ngIf="s.ModuleName ==''">{{s.FavoriteName | translate}}</a>
                  <a id="btn-fav-{{s.ModuleName}}" class="dropdown-item" routerLink={{s.url}} *ngIf="s.ModuleName !='' && s.FavoriteName !=''">{{s.ModuleName | translate}} -> {{s.FavoriteName | translate}}</a>
                  <a id="btn-fav-{{s.FavoriteName}}" class="dropdown-item" routerLink={{s.url}} *ngIf="s.ModuleName !='' && s.FavoriteName ==''">{{s.ModuleName | translate}} -> {{s.PageName | translate}}</a>
                </ng-container>
                <ng-container *ngIf="s.favoriteQueryParams != ''">
                  <a id="btn-fav-{{s.name}}" class="dropdown-item" routerLink={{s.url}}
                    [queryParams]="queryformat(s.favoriteQueryParams)">{{s.name | translate}}</a>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="favoritenav.length==0">
              <a class="dropdown-item"> {{ 'Common.No more favorite' | translate }} </a>
            </ng-container>
          </div>
        </li>
        <li class="c-header-nav-item mx-1 dropdown">
          <a id="btnSearch" class="c-header-nav-link"  data-bs-toggle="dropdown"  role="button" aria-haspopup="true" aria-expanded="false"
          title='{{"Common.Search" | translate}}' (click)="onSearch(1);" >
            <div class="c-icon">
              <span class="material-icons-outlined">search</span>
            </div>
          </a>
          <div class="header-search">
            <li class="dropdown-menu dropdown-menu-right" style="height: 300px;overflow-y: hidden;" >
            <ng-container style="width: 500px;" *ngIf="isSearch==true"  >
              <ng-select #selectContent class="header-search ng-select ng-arrow-wrapper"  style="width:500px;padding: 10px;" [items]="lstSearchedContent" groupBy="TransTypeName"  bindLabel="SearchContent" bindValue="SearchContent"
               (search)="onSearchFilter($event)"   placeholder="{{ 'Common.Search minimum 3 character'| translate }}" (loading)="loadingContent"
              (clear)="onClear()" (focus)="onFocus()"  (change)="onSelectContent($event,selectContent)" >
            </ng-select>
            </ng-container>
          </li>
        </div>
        </li>
       </ng-container> 
      <span class="h-divider d-md-down-none"></span>
      <li class="c-header-nav-item d-md-down-none dropdown" *ngIf="currentUser.userType == 2">
        <a id="btnSearch-briefcase" class="c-header-nav-link" data-bs-toggle="dropdown" role="button"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-briefcase"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right pt-0">
          <div class="dropdown-header py-2"><strong>Change Customer</strong></div>
          <mat-form-field class="w-100">
            <input id="txtsearchCustomerCtrl" matInput placeholder="Search customer name..." aria-label="State"
              [matAutocomplete]="auto" [formControl]="searchCustomerCtrl">
            <mat-autocomplete id="matAutocomplete" #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
              <mat-option *ngIf="loading" class="is-Loading">Loading...</mat-option>
              <ng-container *ngIf="!loading">
                <mat-option *ngFor="let cust of filteredCustomers" [value]="bank"
                  (onSelectionChange)="onSelectionChanged($event, cust)" autoActiveFirstOption>
                  <span>{{cust.CustomerCodeName}}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </li>
      <li class="c-header-nav-item d-md-down-none dropdown" *ngIf="currentUser.userType == 2">
        <a id="btnorganizationName" class="c-header-nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button"
          aria-haspopup="true" aria-expanded="false">
          {{ currentUser.organizationName | translate }}
        </a>
        <div class="dropdown-menu dropdown-menu-right pt-0">
          <div class="dropdown-header py-2"><strong>Organizations</strong></div>
          <ng-container *ngFor="let op of currentUser.organizations;let i = index">
            <button id="btnChangeOrg-{{i}}" class="dropdown-item" (click)='ChangeOrg($event, op)'>
              <span>{{op.organizationName}}</span>
            </button>
          </ng-container>
        </div>
      </li>
      <li class="c-header-nav-item d-md-down-none dropdown right" *ngIf="currentUser.userType == 1">
        <ngx-dropdown-treeview-select id="btnChangeOrg" [items]="items" [(value)]="value"
          (valueChange)="onValueChange($event)">
        </ngx-dropdown-treeview-select>
      </li>
      <li *ngIf="this.currentUser.orgCountryID == 94" class="c-header-nav-item d-md-down-none dropdown">
        <select class="form-select" id="ddlLang" (change)='switchLang($event)' [(ngModel)]="selectedLang">
          <option *ngFor="let lang of languages" [value]="lang.LanguageCode" class="dropdown-item">
            {{lang.LanguageName}}
          </option>
        </select>
      </li>
      <li *ngIf="!isDigiSME" class="c-header-nav-item d-md-down-none mx-1 dropdown">
        <a id="btnApps" class="c-header-nav-link " data-bs-toggle="dropdown" role="button" aria-haspopup="true"
          aria-expanded="false" title='{{"Title.Apps" | translate}}' (click)="sidePanel=false">
          <div class="c-icon">
            <span class="material-icons-outlined">apps</span>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right pt-0">
          <a class="dropdown-item">
            <div class="avatar avatar-xs mr-3">
              <span class="avatar-text avatar-text-inv-primary rounded"><span
                  class="initial-wrap"><span>{{ 'AC' | translate }}</span></span>
              </span>
            </div>Accounting
          </a>
          <!-- <a class="dropdown-item" href="https://www.infotech-cloudhr.com.sg/" target="_blank" rel="noopener noreferrer"> -->
          <a id="btngetHRMSURL" class="dropdown-item" (click)="getHRMSURL()" target="_blank" rel="noopener noreferrer">
            <div class="avatar avatar-xs mr-3">
              <span class="avatar-text avatar-text-inv-primary rounded"><span
                  class="initial-wrap"><span>{{ 'HR' | translate }}</span></span>
              </span>
            </div>HRMS
          </a>

        </div>
      </li>
      <li class="c-header-nav-item mx-1">
        <a id="btnNotifications" class="c-header-nav-link" (click)="onSetting('N')" role="button" aria-haspopup="true"
          aria-expanded="false" title='{{"Title.Notifications" | translate}}'>
          <div class="c-icon">
            <span class="material-icons-outlined">notifications</span>
            <!-- <span class="badge badge-pill badge-danger p-1" style="font-ssize: 8px !important;"
            *ngIf="TotalNoteCnt > 0 && UnReadCnt > 0">{{UnReadCnt}}</span> -->
            <span class="badge badge-pill badge-danger p-1" style="font-size: 8px !important;"
              *ngIf="TotalNoteCnt > 0">{{TotalNoteCnt}}</span>
          </div>
        </a>
      </li>

      <ng-container>
        <!--*ngIf="currentUser.accessRightID != 1">-->
        <li class="c-header-nav-item mx-1" *ngIf="topnav.length > 0">
          <a id="btnSettings" class="c-header-nav-link" (click)="onSetting('S')" role="button" aria-haspopup="true"
            aria-expanded="false" title='{{"Title.Settings" | translate}}'>
            <div class="c-icon">
              <span class="material-icons-outlined">settings</span>
            </div>
          </a>
        </li>
      </ng-container>      
      <li class="c-header-nav-item mx-1" *ngIf="!(currentUser.customerCode >= 602 && currentUser.customerCode <= 677)" >
        <a id="btnHelp" class="c-header-nav-link" href="{{usermanualurl}}" target="_blank" role="button"
          aria-haspopup="true" aria-expanded="false" title='{{"Title.Help & Support" | translate}}'>
          <div class="c-icon">
            <span class="material-icons-outlined text-dark">help_outline</span>
          </div>
        </a>
      </li>
      <li class="c-header-nav-item mx-1">
        <a id="btnlogout" class="c-header-nav-link" (click)="logout();" role="button" aria-haspopup="true"
          aria-expanded="false" title='{{"Title.Logout" | translate}}'>
          <div class="c-icon">
            <span class="material-icons-outlined">logout</span>
          </div>
        </a>
      </li>
    </ul>
  </app-header>
  <div class="app-body" [ngClass]="isDashboard?'dashboard-bg':''">
    <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
      (minimizedChange)="toggleMinimize($event)">
      <div class="c-sidebar-nav-item dropdown">
        <div class="c-sidebar-user">
          <div class="c-avatar-lg"><img class="c-avatar-img" src="{{currentUser.image}}" alt="User Name">
          </div>
          <div class="c-sidebar-userdetails">
            <div class="c-sidebar-username">{{currentUser.firstName}}</div>
            <div class="c-sidebar-useraccount">{{currentUser.userRole}}</div>
          </div>
        </div>
        <ul class="dropdown-content user-expand">
          <li class="c-sidebar-nav-item">
            <div class="full-uname">{{currentUser.firstName}} {{currentUser.lastName}}</div><a
              class="user-link">{{currentUser.username}}</a>
          </li>
          <ng-container *ngFor="let p of profilenav">
            <li class="c-sidebar-nav-item">
              <a id="btnProfileNav-{{p.name}}" class="c-sidebar-nav-link" routerLink={{p.url}}>{{'UsersModule.' + p.name | translate}}</a>
            </li>
          </ng-container>
          <li class="c-sidebar-nav-item" *ngIf="isDigiSME">
            <a id="btnpaymentHistory" class="c-sidebar-nav-link" (click)="digiSMEPaymentHistory();" role="button" aria-haspopup="true"
              aria-expanded="false">
              Payment History</a>
          </li>
          <li class="c-sidebar-nav-item">
            <a id="btnLogout" class="c-sidebar-nav-link" (click)="logout();" role="button" aria-haspopup="true"
              aria-expanded="false">
              {{'UsersModule.Logout' | translate}}</a>
          </li>
        </ul>
      </div>

      <app-sidebar-nav-custom class="sidebar-nav" [navItems]="sidenav" [perfectScrollbar]
        [disabled]="appSidebar.minimized"></app-sidebar-nav-custom>
      <app-sidebar-minimizer></app-sidebar-minimizer>
    </app-sidebar>
    <!-- Main content -->
    <main class="main" (window:scroll)="onWindowScroll($event)">
      <app-custom-breadcrumb></app-custom-breadcrumb>
      <!-- <cui-breadcrumb>
        <li class="breadcrumb-menu d-md-down-none">
        </li>
      </cui-breadcrumb> -->
        <div class="container-fluid">
        <app-loader></app-loader>
        <router-outlet></router-outlet>
      </div><!-- /.container-fluid -->
      <div class="wizard-pin">
        <div class="feedback-btn feedback-btn-blue" id="ticketcapture" style="width:150px;" (click)="SubmitTicket()"  cdkDrag>
          <div *ngIf="isCaptureLoading">
            <span class="spinner-border spinner-border-sm mr-1"></span>
            <span>Capturing</span>
          </div> 
          <span *ngIf="!isCaptureLoading">Submit Ticket</span>
        </div>
      </div> 
    </main>
    <!-- Side panel -->
    <div class="side-panel" [ngClass]="sidePanel?'active':''">
      <div class="side-panel-body" *ngIf="sidePanelAction=='S'">
        <div class="side-panel-header bg-white border-bottom">
          <div class="w-100">
            <div class="input-group p-2">
              <input class="form-control mr-sm-2 w-100" type="text" placeholder='{{"Common.Search" | translate}}' aria-label="Search"
                [(ngModel)]="searchtopnav" id="btnfiltertopnav" (keyup)="filtertopnav()"
                (click)="sidePanel=true;isSetting=true;">
            </div>
          </div>
        </div>
        <div class="side-panel-content scrollbox">
          <div class="scrollbox-content">
            <nav class="navbar">
              <ul class="navbar-nav">
                <ng-container *ngFor="let cm of topnav">
                  <li class="nav-item" *ngIf="cm.childMenu.length == 0">
                    <a class="nav-link" (click)="sidePanel=false" [routerLink]="cm.url" *ngIf="cm.name!='License Info'">
                      <span class="c-icon mr-2 material-icons-outlined">{{cm.icon}}</span>
                      {{cm.name | translate}}</a>
                    <a class="nav-link" (click)="onLicenseInfo('O');" *ngIf="cm.name=='License Info'">
                      <span class="c-icon mr-2 material-icons-outlined">{{cm.icon}}</span>
                      {{cm.name | translate}}</a>
                  </li>
                  <li class="nav-item dropdown" [ngClass]="searchtopnav.length>0?'show':''"
                    *ngIf="cm.childMenu.length > 0">
                    <a class="nav-link dropdown-toggle" (click)="sidePanel=true;isSetting=true;"
                      data-bs-toggle="dropdown">
                      <span class="c-icon mr-2 material-icons-outlined">{{cm.icon}}</span>{{cm.name | translate}}
                    </a>

                    <div class="dropdown-menu" [ngClass]="searchtopnav.length>0?'show':''">
                      <ng-container *ngFor="let tm of cm.childMenu">
                        <!--<a class="dropdown-item" *ngIf="cm.name=='Templates'"
                      (click)="redirect(tm.url,tm.class);sidePanel=false">{{tm.name}}</a>-->
                        <a class="dropdown-item" (click)="sidePanel=false" [routerLink]="tm.url">{{tm.name | translate}}</a>
                      </ng-container>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="side-panel-body " *ngIf="sidePanelAction=='N'">
        <div class="side-panel-content scrollbox">
          <div class="scrollbox-content">
            <div class="side-panel-header border-bottom"
              *ngIf="notifications.length > 0 || approvalNotifications.length > 0">
              {{'You have' | translate}} {{TotalNoteCnt}} {{'notifications' | translate}}.
            </div>
            <ng-container *ngFor="let approval of approvalNotifications">
              <div class="dropdown-item pl-3 border-bottom" (click)="approvalNotificationsRedirect(approval)">
                <div class="message w-100">
                  <span class="btn-link pr-1" [innerHTML]="approval.NotificationType"></span>
                  <span class="text-wrap pr-1" [innerHTML]="approval.NotificationContent"></span>
                  <span>(</span>
                  <span class="text-wrap" style="color: red;" [innerHTML]="approval.NotificationCount"></span>
                  <span>)</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngFor="let note of notifications">
              <div class="border-bottom">
                <a class="dropdown-item pl-3" (click)="onViewedUserNotification($event,note)">
                  <div class="message w-100">
                    <div>
                      <small class="text-muted float-right mt-1">{{note.NotifiedOn}}</small>
                    </div>
                    <div class="text-truncate font-weight-bold">{{note.NotificationType}}</div>
                    <div class="small text-muted text-wrap" [innerHTML]="note.NotificationContent"></div>
                  </div>
                </a>

                <div class="small text-muted text-right" style="cursor: pointer;"
                  *ngIf="note.IsHidable && !note.IsHidden">
                  <span class="text-danger pr-3" (click)="onHideNotification($event,note)">Hide</span>
                </div>
              </div>

            </ng-container>

            <div class="side-panel-header border-bottom" *ngIf="backgroundTaskNotification.length > 0">
              {{'You have' | translate}} {{backgroundTaskNotification.length}} {{'pending background task(s)' | translate}}
            </div>
            <ng-container *ngFor="let bgTask of backgroundTaskNotification">
              <a class="dropdown-item pl-3 border-bottom" routerLink="/tools/userbackgroundtasks">
                <div class="message w-100">
                  <span class="text-wrap pr-1" [innerHTML]="bgTask.NotificationType"></span>
                  <span class="text-wrap pr-1" [innerHTML]="bgTask.NotificationContent"></span>
                  <span>(</span>
                  <span class="text-wrap" style="color: red;" [innerHTML]="bgTask.NotificationCount"></span>
                  <span>)</span>
                </div>
              </a>
            </ng-container>
          </div>
          <div class="scrollbox-content"
            *ngIf="notifications.length == 0 && approvalNotifications.length == 0 && backgroundTaskNotification.length == 0">
            <small class="text-muted">{{'Common.No New Notifications' | translate}} </small>
          </div>
        </div>
        <div class="side-panel-footer">
          <div class="w-100">
            <a class="" [routerLink]="['/users/usernotifications']"><strong>{{'Common.View all messages' | translate}} </strong></a>
          </div>
        </div>

      </div>


    </div>


  </div>
  <!-- </div> -->
  <app-footer> 
    <div>  
      <!-- <button (click)="topFunction()" id="myBtn" title="Go to top">^</button> -->
      <button (click)="topFunction()" id="myBtn" class="btn"><i class="material-icons font-16">arrow_upward</i></button>
      <!-- <button class="btn btn-danger" id="myBtn" title="Go to top" (click)="topFunction()" >
      <i class="material-icons font-16">arrow_upward</i>
      </button> -->
    </div>

    <div *ngIf="!isDigiSME">© 2024 <a href="https://www.info-tech.com.sg/">Info-Tech Systems Integrators</a>, All rights
      reserved</div>
    <div *ngIf="isDigiSME">© 2024 <a href="https://www.digi-sme.com/">Digi-SME</a>, All rights reserved</div>
    <span *ngIf="!isProduction">&nbsp;&nbsp;&nbsp; {{lacalspace}}</span>
    <span *ngIf="!isProduction">&nbsp;&nbsp;&nbsp; {{sessionspace}}</span>
    <div *ngIf="!isDigiSME" class="ml-auto">Powered by&nbsp;<a href="https://www.info-tech.com.sg/">Info-Tech Systems
        Integrators</a></div>
    <div *ngIf="isDigiSME" class="ml-auto">Powered by&nbsp;<a href="https://www.digi-sme.com/">Digi-SME</a></div>
  </app-footer>
</div>


<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" 
aria-labelledby="dialog-child-name"  aria-hidden="true"  [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">You have been Idle!</h4>
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-body">
        <div class="row mt-2">
          <div class="col-12 text-center font-lg">
            {{idleState}}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 pull-right text-right">
            <button type="button" (click)="stay()" class="btn btn-success br-2">Continue</button>
            <button type="button" (click)="logout()" class="btn btn-danger">Logout</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #modalPDPAAlert="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="modalStartDateLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font-weight-bold">PDPA Acknowledgement</h4>
      </div>
      <div class="modal-body text-center">
        <div class="row">
          <div class="col-md-12">
            <p>Please click on below link to read the PDPA policy and Kindly acknowledge to proceed further.</p>
            <div>
              <button id="urlLink" class="btn btn-link" (click)="redirectPDPA()"> Click here to Read the PDPA Policy to
                Acknowledge </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button class="btn btn-success btn-normal" (click)="ConfirmClick()" [disabled]=isLinkcliked type="button">
          Acknowledge
        </button>
        <button class="btn btn-danger btn-normal" (click)="CancelClick()" type="button">
          Deny
        </button>
      </div>
    </div>
  </div>
</div>
<div bsModal #modallicenseGUID="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="modallicenseGUIDLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font-weight-bold">License Info</h4>
        <button type="button" class="close" (click)="onLicenseInfo('C');">
          <span aria-hidden="true">×</span>
      </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            Organization Name: {{currentUser.organizationName}}
            <br>
            License GUID: {{currentUser.licenseGUID}}
          </div>
          <ng-container *ngIf="isDigiSME">
            <div class="mt-3">
              <div class="card">
                <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                    <ng-select  [clearable]="false" [clearOnBackspace]="false" 
                     [items]="subscriptionDate"  bindLabel="DateRange" [(ngModel)]="date" (change)="getTransactionCount($event)">
                    </ng-select>
                </div>
              </div> 
               <div > 
                <table class="table table-sm table-bordered mt-3">
                  <thead>
                    <tr>
                      <th>Transaction</th>
                      <th>Total</th>
                      <th>Created</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
               <tbody>
                <tr *ngFor="let key  of allTransactionsCount">
                  <td>{{key.PageName}}</td>
                  <td>{{key.Total}}</td>
                  <td>{{key.Created}}</td>  
                  <td>{{key.Balance}}</td>
                </tr>
              </tbody>
            </table>
            </div>  
            </div>
            </div>
          </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center ">
        <button class="btn btn-danger btn-normal" type="button" (click)="onLicenseInfo('C');">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div bsModal #modalDigismePlan="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="modalDigismePlanLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font-weight-bold">Oops! Your trial has expired.</h4>       
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
              Your free trial has ended. Click the button below to view our plans to enjoy using DigiSME again!
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center ">
        <button class="btn btn-primary btn-normal" type="button" (click)="ondigisme('V');">
          View Plan
        </button>
      </div>
    </div>
  </div>
</div>
<style>
  #myBtn {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Fixed/sticky position */
    bottom: 50px;
    /* Place the button at the bottom of the page */
    right: 30px;
    /* Place the button 30px from the right */
    z-index: 99;
    /* Make sure it does not overlap */
    border: none;
    /* Remove borders */
    outline: none;
    /* Remove outline */
    background-color: #d85531;
    /* Set a background color */
    color: white;
    /* Text color */
    cursor: pointer;
    /* Add a mouse pointer on hover */
    /* padding: 14px; */
    /* Some padding */
    border-radius: 10px;
    /* Rounded corners */
    font-size: 18px;
    /* Increase font size */

  }

  i.material-icons.font-16 {
    font-size: 16px;
  }
  

  #myBtn:hover {
    background-color: #555;
    /* Add a dark-grey background on hover */
  }
 
  @keyframes glowing {
    0% {
      background-color: #2ba805;
      box-shadow: 0 0 5px #2ba805;
    }

    50% {
      background-color: #49e819;
      box-shadow: 0 0 20px #49e819;
    }

    100% {
      background-color: #2ba805;
      box-shadow: 0 0 5px #2ba805;
    }
  }

  .flash-button {
    animation: glowing 1300ms infinite;
  }

.feedback-btn-blue {
    text-align: center;
    cursor: pointer;
    background-color: #357ae8;
    background-image: -webkit-linear-gradient(top, #4d90fe, #357ae8);
    background-image: -moz-linear-gradient(top, #4d90fe, #357ae8);
    background-image: -ms-linear-gradient(top, #4d90fe, #357ae8);
    background-image: -o-linear-gradient(top, #4d90fe, #357ae8);
    background-image: linear-gradient(top, #4d90fe, #357ae8);
    border: transparent;
    color: #fff;
    border-radius: 20px;
    animation-duration: 10s;
    animation-fill-mode: both;
}
.feedback-btn {
    padding: 6px 25px;
    outline: 0;
    background-clip: padding-box;
    -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, .2);
    box-shadow: 0 4px 16px rgba(0, 0, 0, .2);
    z-index: 40000;
}
.wizard-pin {
  position: fixed;
  left: 80%;
  bottom: 5%;
  z-index: 99;
}
.wizard-pin .btn-icon {
  width: 45px;
  height: 45px;
}
.wizard-pin .btn-icon:hover {
  animation: pulse-animation 3s infinite;
}
</style>
<app-onboarding *ngIf="this.currentUser.orgCountryID == 129"></app-onboarding>
