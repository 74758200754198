<div style="height: 100%; width:100%;">
  <ng-select [items]="lstSerial" bindValue="serialID" bindLabel="serialNo" id="ddlSerialNumberList" (change)="onChange()"
    name="ddlSerialNumberList" [(ngModel)]="serialInfo" placeholder="Select SerialNo"
    (search)="onSearchForApi($event)">
    <ng-template ng-header-tmp *ngIf="isShowCreateNew">
      <a class="t-link" (click)="saveNewSerial()"><b>+</b>Add New Serial
      </a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
      {{item.serialNo }}
    </ng-template>
  </ng-select>
</div> 