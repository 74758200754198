import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-totaltax',
  templateUrl: './totaltax.component.html',
  styleUrls: ['./totaltax.component.scss']
})
export class TotaltaxComponent implements OnInit {

  @Input() LinetaxtotalAmountsAreList: any;
  constructor() { }

  ngOnInit(): void {
  }

  
  numberFormatToFixed(val: Number) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    });
    let toFixed: Number = val ? val : 0;
    return formatter.format(Number(toFixed))
  }

  braceFormatter(params) {
    let num;
    if (params > 0)
      num = Number(params).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    else if (Number(params) == 0) {
      num = "";
    }
    else {
      num = "(" + Number(params).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
    }
    return num;
  }
  
}
