<form class="form-horizontal" #addNode=ngForm (ngSubmit)="submit(addNode)" novalidate>

  <div class="form-group">
    <label for="groupHeader" class="form-label">Header</label>
    <input type="text" class="form-control" id="groupHeader" placeholder="Enter header name"
      [(ngModel)]="nodedetails.groupHeader" name="groupHeader" required>
    <span class="text-danger" *ngIf="showExistErrorMsg">Group name already exist!. Please try with different
      name.</span>
  </div>


  <div class="form-group">
    <span>Balance Type</span>
    <br>

    <span [ngClass]="{'font-weight-bold text-primary': !isChecked}" class="pr-2"> Credit Positive</span>
    <mat-slide-toggle [color]="'warm'" [(ngModel)]="isChecked" name="balanceType" (change)="setBalanceType(isChecked)">
    </mat-slide-toggle>
    <span [ngClass]="{'font-weight-bold text-primary': isChecked}" class="pl-2"> Debit Positive
    </span>
  </div>

  <div class="form-check pl-1">
    <mat-checkbox [(ngModel)]="nodedetails.totalRequired" name="totalRequired">
      Total Required
    </mat-checkbox>

    <!-- <input class="form-check-input" name="totalRequired" [(ngModel)]="nodedetails.totalRequired" type="checkbox"
      value="" id="flexCheckDefault">
    <label class="form-check-label" for="flexCheckDefault">
      Total Required
    </label> -->
  </div>




</form>

<button id="btnAddGroup" type="submit" (click)="submit(addNode)" class="btn btn-info"> Add Group </button>
<button id="btnCancelGroup" type="button" (click)="onClose()" class="btn btn-secondary"> Cancel </button>