<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card mx-4">
            <div class="card-body p-4">
              <form [formGroup]="registerForm"  (ngSubmit)="onSubmit()">
                <h1>Register</h1>
                <p class="text-muted">Create your account</p>
                <div class="form-group row">
                  <label for="firstName" class="col-sm-3 col-form-label">First Name*</label>
                  <div class="col-sm-9">
                    <input type="text" formControlName="firstName" class="form-control" alphabetOnly
                      [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                      <div *ngIf="f.firstName.errors.required">First name is required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="lastName" class="col-sm-3 col-form-label">Last Name*</label>
                  <div class="col-sm-9">
                    <input type="text" formControlName="lastName" class="form-control" alphabetOnly
                      [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                      <div *ngIf="f.lastName.errors.required">Last name is required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="email" class="col-sm-3 col-form-label">Email*</label>
                  <div class="col-sm-9">
                    <input type="text" formControlName="email" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Email is required</div>
                      <div *ngIf="f.email.errors.email">Email address is invalid</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="phone" class="col-sm-3 col-form-label">Phone*</label>
                  <div class="col-sm-9">
                    <input type="text" formControlName="phone" class="form-control" numbersOnly
                      [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                      <div *ngIf="f.phone.errors.required">Phone is required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="country" class="col-sm-3 col-form-label">Country*</label>
                  <div class="col-sm-9">
                    <select formControlName="country" class="form-select" [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                      <option value="" selected>Select</option>
                      <option value="1">Singapore</option>
                      <option value="2">India</option>
                    </select>
                    <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                      <div *ngIf="f.country.errors.required">Country is required</div>
                    </div>
                  </div>
                </div>
                <button type="button" class="btn btn-link px-0" (click)="onLogin();">Go to  login</button>
                <div class="text-right">
                  <button type="button" class="btn btn-secondary" (click)="onCancel();">Cancel</button>
                  <button [disabled]="loading" class="btn btn-success">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Submit
                  </button>
                </div>
               
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
