import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MasterServices } from './master.service';

@Injectable({
  providedIn: 'root'
})

export class TranslationService implements TranslateLoader {

  constructor(private _http: HttpClient, private master: MasterServices) { }

  getTranslation(lang: string): Observable<any> {
    // return this._http.get(environment.organizationApiUrl + "/Master/GetLanguageJson/" + lang).pipe(map(data => {
    //   //console.log(data);
    //   return data;
    // }));
    return this._http.get(`/assets/i18n/${lang}.json`).pipe(map(data => {
      return data;
     }));
  }
}
