<form class="form-horizontal h-100" [formGroup]="emailtemplateForm" novalidate="novalidate" >



  <div class="d-flex flex-row align-items-center h-100">

    <div  class="requiredError" *ngIf="!emailtemplateForm.controls.mail.valid">
    <mat-icon style="vertical-align: middle;">
      error_outline
    </mat-icon>
    </div>

    <div style="flex: 1;"> <input class="form-control m-0 pr-1 pl-1" name="mail"  id="name" type="text" formControlName="mail" xssDirective >
    </div>
  </div>

  <ng-template #popTemplateHtml>
    <div><span class="help-block text-warning">
      Email is invalid. Use semi colon ';' to add multiple email
    </span>
    </div>
</ng-template>

</form>
