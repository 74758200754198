import { ICellEditorParams, IAfterGuiAttachedParams } from '@ag-grid-community/all-modules';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdownwithtextbox',
  templateUrl: './dropdownwithtextbox.component.html',
  styleUrls: ['./dropdownwithtextbox.component.scss']
})
export class DropdownwithtextboxComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: any;
  lstBatch: any[] = [];
  constructor() { }
  ngAfterViewInit(): void {

  }
  getValue() {
    return this.value;
  }
  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = this.params.value;
    this.lstBatch = this.params.lstBatch.map(ele => ({
      batchNo: ele.batchNo,
      batchID: ele.batchID
    }));
    this.lstBatch = [...new Map(this.lstBatch.map(v => [v.batchNo, v])).values()]
  }
  ngOnInit(): void {

  }

}
