import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { DeleteAccountTransactions } from '../_models/reconciletransactionentities';
import * as internal from 'stream';
import { PageServices } from './page.service';
import { AnyCnameRecord } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class BankaccountService {
  dynamicFieldTransId = '';
  apiUrl: string = "";
  yodleeEnvironment: string = "";
  showYodleediv: boolean = false;
  YodleedivVisibilityChange: Subject<boolean> = new Subject<boolean>();

  constructor(private _http: HttpClient, private page: PageServices) {
    this.apiUrl = environment.reconcilationApiUrl;
    this.yodleeEnvironment = environment.yodleeEnvironment;

    this.YodleedivVisibilityChange.subscribe((value) => {
      this.showYodleediv = value
    });
    this.YodleedivVisibilityChange.next(false);
  }

  setYodleedivVisibility(param: any) {
    this.YodleedivVisibilityChange.next(param);
  }

  // async getBankAccbyOrg<T>(period:string='') {
  //   let data = await this._http.get<T>(this.apiUrl + '/reconcile/Bank/GetBankAccbyOrg/' + period).toPromise();
  //   return data;
  // }

  async getBankAccbyOrg<T>(period:string='',CAAccountID:number=0) {
    let data = await this._http.get<T>(this.apiUrl + '/reconcile/Bank/GetBankAccbyOrg?period=' + period + '&CAAccountID=' + CAAccountID).toPromise();
    return data;
  }

  async getSimpleBankAccbyOrg<T>() {
    let data = await this._http.get<T>(this.apiUrl + '/reconcile/Bank/GetSimpleBankAccbyOrg').toPromise();
    return data;
  }

  async GetBankHeaderByAccountID<T>(CAAccountID) {
    if (isNaN(CAAccountID) == false && CAAccountID > 0) {
      let params = new HttpParams();
      params = params.set('PageID', this.page.pageDetails.pageID.toString());
      params = params.set('CAAccountID', CAAccountID.toString());
      let response = await this._http.post(this.apiUrl + '/reconcile/Bank/GetBankHeaderByAccountID', params).toPromise();
      return response;
    }
    else
      return null;
  }

  getBanksbySearchText(SearchText: string) {
    if (typeof (SearchText) == "string") {
      let data = this._http.post(this.apiUrl + '/reconcile/Bank/GetBanksbySearchText', { SearchText });
      return data;
    }
    else {
      let data = this._http.post(this.apiUrl + '/reconcile/Bank/GetBanksbySearchText', { SearchText: "" });
      return data;
    }
  }

  async getPopularBanksinOrgCountry() {
    let data = await this._http.get(this.apiUrl + '/reconcile/Bank/GetPopularBanksinOrgCountry').toPromise();
    return data;
  }

  async SaveBankAccount(bankAccount: any, BankCode: string) {
    const myPostBody = {
      accountCode: bankAccount.accountCode,
      accountName: bankAccount.accountName,
      accountNumber: bankAccount.accountNumber,
      accountTypeID: bankAccount.accountTypeID,
      currencyCode: bankAccount.currencyCode,
      BankCode: BankCode,
      IsAuthennticatedtoGetAutoFeed: bankAccount.IsAuthennticatedtoGetAutoFeed ? bankAccount.IsAuthennticatedtoGetAutoFeed : false,
      CAAccountID:bankAccount.CAAccountID ? bankAccount.CAAccountID : 0,
      feedTypeId: bankAccount.IsAuthennticatedtoGetAutoFeed ? 4 : 0,
      oldAccountNumber: bankAccount.oldAccountNumber ?? ""
    }
    let data = await this._http.post(this.apiUrl + '/reconcile/Bank/SaveBankAccount', myPostBody)
    return data;
  }

  async GetActiveYodleeDetails() {
    let params = new HttpParams();
    params = params.set('environment', this.yodleeEnvironment);
    let data = await this._http.post(this.apiUrl + '/reconcile/Yodlee/GetActiveYodleeDetails', params).toPromise();
    return data;
  }

  async GetAccessTokenforYodlee() {
    let params = new HttpParams();
    params = params.set('environment', this.yodleeEnvironment);
    let data = await this._http.post(this.apiUrl + '/reconcile/Yodlee/GetAccessTokenforYodlee', params).toPromise();
    return data;
  }

  async GetYodleeProviders(BankName: any, accessToken: any, yodleeDetails: any) {
    let params = new HttpParams();
    params = params.set('BankName', BankName);
    params = params.set('accessToken', JSON.stringify(accessToken));
    params = params.set('yodleeDetails', JSON.stringify(yodleeDetails));
    let data = await this._http.post(this.apiUrl + '/reconcile/Yodlee/GetYodleeProviders', params).toPromise();
    return data;
  }

  async SaveStatement(bankStatement: any, CAAccountID: any) {
    let url = this.apiUrl + '/reconcile/Bank/SaveBankStatement/SaveBankStatement/' + CAAccountID;
    let data = await this._http.post(url, bankStatement);
    return data;
  }

  async FastLinkOnClose(data: any, BankCode: any, accessToken: any, yodleeDetails: any) {
    let params = new HttpParams();
    params = params.set('data', JSON.stringify(data));
    params = params.set('BankCode', BankCode);
    params = params.set('accessToken', JSON.stringify(accessToken));
    params = params.set('yodleeDetails', JSON.stringify(yodleeDetails));
    let response = await this._http.post(this.apiUrl + '/reconcile/Yodlee/FastLinkOnClose', params).toPromise();
    return response;
  }

  async FastLinkEditOnClose(data: any, editedAccount: any, accessToken: any, yodleeDetails: any) {
    let params = new HttpParams();
    params = params.set('data', JSON.stringify(data));
    params = params.set('editedAccount', JSON.stringify(editedAccount));
    params = params.set('accessToken', JSON.stringify(accessToken));
    params = params.set('yodleeDetails', JSON.stringify(yodleeDetails));
    let response = await this._http.post(this.apiUrl + '/reconcile/Yodlee/FastLinkEditOnClose', params).toPromise();
    return response;
  }

  GetStatement<T>(CAAccountID: any): Observable<T> {
    let req = this._http.get<T>(
      this.apiUrl + '/reconcile/Bank/GetBanksStatement/BanksStatements/' + CAAccountID
    ).pipe(
      retry(1), catchError(error => {
        return throwError(error.message || "server error");
      })
    )
    return req;
  }

  GetStatementLine(CAAccountID: any): Observable<any> {
    let req = this._http.get(
      this.apiUrl + '/reconcile/Bank/GetBanksStatementLine/banksStatementLines/' + CAAccountID
    ).pipe(
      retry(1), catchError(error => {
        return throwError(error.message || "server error");
      })
    )
    return req;
  }

  DeleteStatementLine(DeleteRecordObj: any) {
    let req = this._http.post(this.apiUrl + '/reconcile/Bank/DeleteBankStatementLine', DeleteRecordObj);
    return req;
  }

  async GetAccountTransactions(CAAccountID: number) {
    let params = new HttpParams();
    params = params.set('CAAccountID', CAAccountID.toString());
    let data = await this._http.post(this.apiUrl + '/reconcile/Bank/GetAccountTransactions', params).toPromise();
    return data;
  }

  async getStatementTransaction(stlid: number) {
    let params = new HttpParams();
    // let data = this._http.post(this.apiUrl + '/reconcile/Bank/GetReconciledTransaction?CAAcountID='+ stlid).toPromise();
    let data =  await this._http.get(this.apiUrl + '/reconcile/Bank/GetReconciledTransaction?stlid=' + stlid).toPromise();
    return data;
  }

  async DeleteAccountTransactions(accountTransactions: DeleteAccountTransactions[]) {
    let reqparams = new HttpParams();
    reqparams = reqparams.set('accountTransactions', JSON.stringify(accountTransactions));
    reqparams = reqparams.set('PageID', this.page.pageDetails.pageID.toString());

    let data = await this._http.post(this.apiUrl + '/reconcile/Bank/DeleteAccountTransactions', reqparams).pipe(
      catchError(error => {
        return throwError(error.message || "server error");
      })
    ).toPromise();
    return data;
  }

  async isExistsAllocateCredit(TransactionTypeID: number, TransactionID: number) {
    let reqparams = new HttpParams();
    reqparams = reqparams.set('TransactionTypeID', TransactionTypeID.toString());
    reqparams = reqparams.set('TransactionID', TransactionID.toString());

    let data = await this._http.post(this.apiUrl + '/reconcile/Bank/isExistsAllocateCredit', reqparams).pipe(
      catchError(error => {
        return throwError(error.message || "server error");
      })
    ).toPromise();
    return data;
  }

  async GetSpendMoneyBankRules(CAAccountID: Number) {
    let params = new HttpParams();
    params = params.set('CAAccountID', CAAccountID.toString());
    let response = await this._http.post(this.apiUrl + '/reconcile/Bank/GetSpendMoneyBankRules', params).toPromise();
    return response;
  }

  async SaveSpendMoneyBankRule<T>(newspendBankRule: any, oldspendBankRule: any) {
    let params = new HttpParams();
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    params = params.set('newspendBankRule', JSON.stringify(newspendBankRule));
    params = params.set('oldspendBankRule', JSON.stringify(oldspendBankRule));
    let response = await this._http.post<T>(this.apiUrl + '/reconcile/Bank/SaveSpendMoneyBankRule', params).toPromise();
    return response;
  }

  async DeleteSpendMoneyBankRules(RuleIDs: any) {
    let reqparams = new HttpParams();
    reqparams = reqparams.set('PageID', this.page.pageDetails.pageID.toString());
    reqparams = reqparams.set('RuleIDs', RuleIDs);
    const httpOptions = {
      params: reqparams
    };

    let data = await this._http.delete(this.apiUrl + '/reconcile/Bank/DeleteSpendMoneyBankRules', httpOptions).pipe(
      retry(1), catchError(error => {
        return throwError(error.message || "server error");
      })
    ).toPromise();
    return data;
  }

  async UpdateSpendMoneyBankRulesOrder(Data: any) {
    let reqparams = new HttpParams();
    reqparams = reqparams.set('PageID', this.page.pageDetails.pageID.toString());
    reqparams = reqparams.set('Data', JSON.stringify(Data));

    let url = this.apiUrl + '/reconcile/Bank/UpdateSpendMoneyBankRulesOrder';
    return await this._http.put(url, reqparams).toPromise();
  }

  async GetReceiveMoneyBankRules(CAAccountID: Number) {
    let params = new HttpParams();
    params = params.set('CAAccountID', CAAccountID.toString());
    let response = await this._http.post(this.apiUrl + '/reconcile/Bank/GetReceiveMoneyBankRules', params).toPromise();
    return response;
  }

  async SaveReceiveMoneyBankRule(newReceiveBankRule: any, oldReceiveBankRule: any) {
    let params = new HttpParams();
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    params = params.set('newReceiveBankRule', JSON.stringify(newReceiveBankRule));
    params = params.set('oldReceiveBankRule', JSON.stringify(oldReceiveBankRule));
    let response = await this._http.post(this.apiUrl + '/reconcile/Bank/SaveReceiveMoneyBankRule', params).toPromise();
    return response;
  }

  async DeleteReceiveMoneyBankRules(RuleIDs: any) {
    let reqparams = new HttpParams();
    reqparams = reqparams.set('PageID', this.page.pageDetails.pageID.toString());
    reqparams = reqparams.set('RuleIDs', RuleIDs);
    const httpOptions = {
      params: reqparams
    };

    let data = await this._http.delete(this.apiUrl + '/reconcile/Bank/DeleteReceiveMoneyBankRules', httpOptions).pipe(
      retry(1), catchError(error => {
        return throwError(error.message || "server error");
      })
    ).toPromise();
    return data;
  }

  async UpdateReceiveMoneyBankRulesOrder(Data: any) {
    let reqparams = new HttpParams();
    reqparams = reqparams.set('PageID', this.page.pageDetails.pageID.toString());
    reqparams = reqparams.set('Data', JSON.stringify(Data));

    let url = this.apiUrl + '/reconcile/Bank/UpdateReceiveMoneyBankRulesOrder';
    return await this._http.put(url, reqparams).toPromise();
  }

  async GetTransferMoneyBankRules(CAAccountID: Number) {
    let params = new HttpParams();
    params = params.set('CAAccountID', CAAccountID.toString());
    let response = await this._http.post(this.apiUrl + '/reconcile/Bank/GetTransferMoneyBankRules', params).toPromise();
    return response;
  }

  async SaveTransferMoneyBankRule(newTransferBankRule: any, oldTransferBankRule: any) {
    let params = new HttpParams();
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    params = params.set('newTransferBankRule', JSON.stringify(newTransferBankRule));
    params = params.set('oldTransferBankRule', JSON.stringify(oldTransferBankRule));
    let response = await this._http.post(this.apiUrl + '/reconcile/Bank/SaveTransferMoneyBankRule', params).toPromise();
    return response;
  }

  async DeleteTransferMoneyBankRules(RuleIDs: any) {
    let reqparams = new HttpParams();
    reqparams = reqparams.set('PageID', this.page.pageDetails.pageID.toString());
    reqparams = reqparams.set('RuleIDs', RuleIDs);
    const httpOptions = {
      params: reqparams
    };

    let data = await this._http.delete(this.apiUrl + '/reconcile/Bank/DeleteTransferMoneyBankRules', httpOptions).pipe(
      retry(1), catchError(error => {
        return throwError(error.message || "server error");
      })
    ).toPromise();
    return data;
  }

  async UpdateTransferMoneyBankRulesOrder(Data: any) {
    let reqparams = new HttpParams();
    reqparams = reqparams.set('PageID', this.page.pageDetails.pageID.toString());
    reqparams = reqparams.set('Data', JSON.stringify(Data));

    let url = this.apiUrl + '/reconcile/Bank/UpdateTransferMoneyBankRulesOrder';
    return await this._http.put(url, reqparams).toPromise();
  }

  async ChangeDisplayOrder(CAAccountID: number, ChangeOrder: number) {
    let params = new HttpParams();
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    params = params.set('CAAccountID', CAAccountID.toString());
    params = params.set('ChangeOrder', ChangeOrder.toString());
    let response = await this._http.put(this.apiUrl + '/reconcile/Bank/ChangeDisplayOrder', params).toPromise();
    return response;
  }

  async IsShowOnDashboard(CAAccountID: number, Show: boolean) {
    let params = new HttpParams();
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    params = params.set('CAAccountID', CAAccountID.toString());
    params = params.set('Show', Show.toString());
    let response = await this._http.put(this.apiUrl + '/reconcile/Bank/IsShowOnDashboard', params).toPromise();
    return response;
  }

  async DragDropDisplayOrder(OrderJSON: any) {
    let params = new HttpParams();
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    params = params.set('OrderJSON', JSON.stringify(OrderJSON));
    let response = await this._http.put(this.apiUrl + '/reconcile/Bank/DragDropDisplayOrder', params).toPromise();
    return response;
  }

  async GetStatementMatches<T>(): Promise<T> {
    let response = await this._http.get<T>(this.apiUrl + '/reconcile/Bank/GetStatementMatches').toPromise();
    return response;
  }

  async GetStatementFields<T>(): Promise<T> {
    let response = await this._http.get<T>(this.apiUrl + '/reconcile/Bank/GetStatementFields').toPromise();
    return response;
  }

  async GetStatementConditions<T>(): Promise<T> {
    let response = await this._http.get<T>(this.apiUrl + '/reconcile/Bank/GetStatementConditions').toPromise();
    return response;
  }

  async GetStatementReferenceFields<T>(): Promise<T> {
    let response = await this._http.get<T>(this.apiUrl + '/reconcile/Bank/GetStatementReferenceFields').toPromise();
    return response;
  }

  async GetCashCoding<T>(CAAccountID): Promise<T> {
    let response = await this._http.get<T>(this.apiUrl + '/reconcile/Bank/GetCashCoding?CAAccountID=' + CAAccountID).toPromise();
    return response;
  }
  async SaveCashCoding(datas: any) {
    // async SaveCashCoding(CashCodingData: any) {
    let params = new HttpParams();
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    params = params.set('JSON', JSON.stringify(datas));
    let response = await this._http.post(this.apiUrl + '/reconcile/Bank/SaveCashCoding', params).toPromise();
    return response;
  }

  async deleteBankAccount(CAAccountID: number) {
    let params = new HttpParams();
    params = params.set('CAAccountID', CAAccountID.toString());
    return await this._http.post(this.apiUrl + '/reconcile/Bank/DeleteBankAccount', params).toPromise();
  }

  async deactivateBankAccount(CAAccountID: number) {
    let params = new HttpParams();
    params = params.set('CAAccountID', CAAccountID.toString());
    return await this._http.post(this.apiUrl + '/reconcile/Bank/DeactivateBankAccount', params).toPromise();
  }
}
