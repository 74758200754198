import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PageServices } from './page.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private ApiUrl: string = environment.administrationApiUrl + "/Subscription/";

  constructor(
    private _http: HttpClient,
    private _page: PageServices) { }

  async CreateORUpdateCustomer<T>(Data) {
    var Url = this.ApiUrl + "CreateORUpdateCustomer";
    let result = await this._http.post<T>(Url, Data).toPromise();
    return result;
  }
}
