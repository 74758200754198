import { Injectable } from "@angular/core";
import { HttpServices } from "../../../utilities/_services/http.service";
import { ExcelService } from "../../../utilities/_common/excelExport.service";
import { AlertMessage } from "../../../utilities/_helpers/alert.message";
import { environment } from "../../../../environments/environment";
import { HttpParams } from "@angular/common/http";
import { LocalCacheServices } from "../../../utilities/_services/acclocalcache.service";

@Injectable({
  providedIn: "root",
})
export class ExportService {
  localCurrentUser: any;
  constructor(
    private http: HttpServices,
    private excelService: ExcelService,
    private local: LocalCacheServices,
    private alertMessage: AlertMessage
  ) {
    this.localCurrentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
  }

  async export(payload: ExportPayload) {
    const url = `${environment.administrationApiUrl}/Admin/SaveandExport`;
    const reqparams = {
      SelectedTransactions: JSON.stringify(payload.SelectedTransactions),
      FrmDt: payload.FromDate,
      ToDt: payload.ToDate,
      SearchWithin: payload.FilterBy ?? "0",
      ExportType: payload.ExportType,
      DeleteVoid: payload.DeleteVoid ?? false,
      TransTypeID: payload.TransactionTypeID,
      SelectedDetailColumns: JSON.stringify(payload.SelectedDetailColumns ?? []),
      ContactGroupID: payload.ContactGroupID ?? 0,
      ColumnsDisplayOrder: JSON.stringify(payload.ColumnsDisplayOrder),
      StatusID: payload.StatusID ?? 0
    };

    try {
      let user = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
      const data: any = await this.http.postservice(url, reqparams).toPromise();
      if (data.isSuccess) {
        if (user.licenseTypeID == 9 && payload.TransactionTypeID == 2){
          data.ResponseData.headerColumns = data.ResponseData.headerColumns.filter(item => item.DisplayName !== "Received");
        }
        if (user.countryID != 129){
          data.ResponseData.headerColumns = data.ResponseData.headerColumns.filter(item => item.DisplayName !== "Classification Code")
        }
        data.ResponseData.headerColumns.forEach((element) => {
          if (element.DisplayName.indexOf("%CurCode%") > -1) {
            element.DisplayName = element.DisplayName.replace("%CurCode%",this.localCurrentUser["orgCurrencyCode"]);
          }
        });
        this.exportFile(data.ResponseData.datas ?? [], data.ResponseData.headerColumns ?? [], payload.TransactionType);
      } else {
        this.alertMessage.errorNotifier(`There is no ${payload.TransactionType} to export`, 1);
      }
    } catch (error) {
      console.error("Error occurred during export:", error);
      this.alertMessage.errorNotifier("An error occurred during export", 1);
    }
  }

  exportFile(datas: any[], headerColumns: any[], transactionType: string) {
    const { headerNames, formats } = headerColumns.reduce(
      (acc, { DisplayName, Format }) => {
        acc.headerNames.push(DisplayName);
        acc.formats.push(Format);
        return acc;
      },
      { headerNames: [], formats: [] }
    );
    const dataRows = datas.map((row) =>
      headerColumns.map(({ ColumnName }) => row[ColumnName])
    );

    const excelParams = {
      header: headerNames,
      data: dataRows,
      format: formats,
      title: transactionType,
    };

    this.excelService.generateExcel(excelParams);
  }
}

type ExportPayload = {
  ExportType;
  TransactionType;
  TransactionTypeID;
  ToDate?;
  FromDate?;
  FilterBy?;
  DeleteVoid?;
  ContactGroupID?;
  TransactionStatus?;
  ColumnsDisplayOrder?;
  SelectedTransactions?;
  SelectedDetailColumns?;
  StatusID?;
};
