import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpServices } from '../../../utilities/_services/http.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MasterServices } from '../../../utilities/_services/master.service';
import { TaxCalculationServices } from '../../../utilities/_services/taxcalculation.service';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { AdditemComponent } from '../../shared/inventoryitem/additem.component';
import { AddtextdescComponent } from '../../shared/inventoryitem/addtextdesc/addtextdesc.component';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { ItemhistoryComponent } from '../itemhistory/itemhistory.component';
import { UnitpricefrompricelistComponent } from '../unitpricefrompricelist/unitpricefrompricelist.component';
import * as moment from "moment";
import Swal from 'sweetalert2';
import { DeleteAccountTransactions } from '../../../utilities/_models/reconciletransactionentities';
import { BankaccountService } from '../../../utilities/_services/bankaccount.service';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { CreatebatchComponent } from '../createbatch/createbatch.component';
import { AssignbatchComponent } from '../assignbatch/assignbatch.component';
import { CreateserialComponent } from '../createserial/createserial.component';
import { AssignserialComponent } from '../assignserial/assignserial.component';
import { AuthenticationService } from '../../../utilities/_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { evaluate } from 'mathjs';

@Component({
    selector: 'app-ctable',
    templateUrl: './ctable.component.html',
    styleUrls: ['./ctable.component.scss'],
    host: {
        "(window:click)": "onOutClick()"
    }
})
export class CtableComponent implements OnInit {
    environment = { name: environment.name };
    @Input() CAAccountID: number = null;
    @Input() transID: number = null;
    //for spend type and receive type
    @Input() transTypeID: number = null;
    @Input() copyTransID: number = 0;
    @Input() copyTransTypeID: number = 0;
    @Input() statusTypeID: number = null;
    @Input() isGridEditable: boolean = true;
    @Input() isAddCnDnFromInvoiceBill: boolean = true;
    @Input() isOnlyGridCustomCellEdit: boolean = false;
    @Output() allocateCNClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitOnBillSerialNoSaved: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitOnInvoiceSerialNoSaved: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitOnDOSerialNoSaved: EventEmitter<any> = new EventEmitter<any>();
    @Output() onTriggerAfterBatchSerialSaved: EventEmitter<any> = new EventEmitter<any>();
    @Output() OnGridChanges: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(UnitpricefrompricelistComponent) public getunitprice: UnitpricefrompricelistComponent;
    @ViewChild(CreatebatchComponent) public createBatch: CreatebatchComponent;
    @ViewChild(AssignbatchComponent) public assignBatch: AssignbatchComponent;
    @ViewChild(CreateserialComponent) public createSerial: CreateserialComponent;
    @ViewChild(AssignserialComponent) public assignSerial: AssignserialComponent;
    @Output() emitonSubmit = new EventEmitter<any>();
    @Output() emitOnProgressiveInvChanges: EventEmitter<any> = new EventEmitter<any>();
    @Input() billTransactionID: number = 0;
    @Input() IsNewViewScreen: boolean = false;
    baseUnitPrice: number = 0;
    private _contact: any = {
        contactID: 0,
        isNewContact: false,
    };
    @Input() get contact(): any {
        return this._contact;
    }
    set contact(val: any) {
        if (val == undefined || val == null) {
            this._contact = {
                contactID: 0,
                isNewContact: false,
            };
            if (!!this._date && !!this._contact)
                this.setItemUnitPrice("contact");
        }
        else {
            let OldContact = this._contact;
            this._contact = val;
            if (!!this._date && !!this._contact) {
                if (OldContact.contactID == 0 && (Number(this.transID) > 0 || Number(this.copyTransID) > 0))
                    this.setItemUnitPrice("edit");
                else
                    this.setItemUnitPrice("contact");
            }
        }
        this.inlineIdx = -1;
    }

    private _date: Date;
    @Input() get TransDate(): Date {
        return this._date;
    }
    set TransDate(val: Date) {
        this._date = val;
        if (!!this._date) {
            this.setItemUnitPrice("date");
        }
        else {
            this._date = new Date(); //moment(new Date()).format('YYYY-MMM-DD');
        }
        this.inlineIdx = -1;
    }

    private _pageMode: string = '';
    @Input() get PageMode(): string {
        return this._pageMode;
    }
    set PageMode(val: string) {
        if (val != null && val != undefined && val != "") {
            if (val.toLowerCase() != "view") {
                val = "";
            }
        }
        this._pageMode = val;
    }

    private _currencyCode: string = '';
    @Input()
    get currencyCode(): string {
        return this._currencyCode;
    }
    set currencyCode(val: string) {
        if (val != undefined && val != null && val != "") {
            this._currencyCode = val
            this.onCurrencyCodeChange();
        }
        this.inlineIdx = -1;
    }
    private _OldTaxTypeName: string = null;
    private _taxTypeName: string = null;
    @Input()
    get taxTypeName(): string {
        return this._taxTypeName;
    }
    set taxTypeName(val: string) {
        this._OldTaxTypeName = this._taxTypeName;
        this._taxTypeName = val
        if (val != undefined && val != null && val != "") {
            //this.onChangeAmountsAre();
        }
        this.inlineIdx = -1;
    }

    private _typeName: string = null;
    @Input()
    get typeName(): string {
        return this._typeName;
    }
    set typeName(val: string) {
        this._typeName = val
        if (val != undefined && val != null && val != "") {
            //this.onChangeAmountsAre();
        }
        this.inlineIdx = -1;
    }

    @Input() columns: any = [];
    private _rowData: any[] = [];
    @Input()
    get rowData(): any[] {
        return this._rowData;
    }
    set rowData(val: any[]) {
        this._rowData = val
        if (val != undefined && val != null && val.length > 0) {
            //this.onChangeAmountsAre();
        }
        this.inlineIdx = -1;
    }

    @Input() validRowData: any[] = [];
    private _paymentData: any;
    @Input() get PaymentData(): any {
        return this._paymentData;
    }
    set PaymentData(val: any) {
        if (val != undefined && val != null && val != "") {
            this.taxAdjustment = val.TaxAdjustment;
            this.AmountDue = val.AmountDue;
            this.CreditData = val.AppliedCreditsForPayment;
            if (this.CreditData != null) {
                this.TotalCreditAmount = this.CreditData.reduce<number>((accum, obj) => {
                    return Number((Math.round((accum + obj.PaidAmount) * 100) / 100).toFixed(2))
                }, 0);
                this.CreditData.forEach(credit => {
                    if (credit.Desc) {
                        credit.Desc = credit.Desc.trim()
                    }
                })
            }
            this._paymentData = val;
        }
    }
    @Input() currencyTooltip: any;
    @Input() LivecurrencyTooltip: any;
    @Input() BankCurrencyCode: string;
    @Input() deposit: any;
    @Input() invoiceDetailsDepositallocated: any;
    @Input() isPartiallypaid: number = null;
    @Input() quoteTotal: number = null;
    subTotal: any = 0;
    total: number = 0;
    discount: any = 0;
    taxAdjustment: any = null;
    taxAmountsAreList: any = [];
    LinetaxtotalAmountsAreList: any = [];

    lstInventoryItem: any = [];
    lstChartAcounts: any = [];
    lstItemCostPrice: any = [];
    lsttaxRate: any = [];
    UntracklstInventoryItem: any = [];  

    itemBuffer: any = [];
    bufferSize = 50;
    numberOfItemsFromEndBeforeFetchingMore = 10;
    itemloading = false;
    HidetaxAdjustment: boolean = true;

    inlineIdx: any = -1;
    addNumberOfRows = [2, 3, 5];
    orgSettings: any;
    enableHTMLDesc: boolean = false;
    totalQuantity: number = 0;
    @ViewChild(AdditemComponent) public additemyModal: AdditemComponent;
    @ViewChild(AddtextdescComponent) public addtextmodal: AddtextdescComponent;
    @ViewChild(ItemhistoryComponent) public itemhistoryModal: ItemhistoryComponent;
    curparentIndex: number = null;
    curchildindex: number = null;
    orgCurrencyCode: string = '';
    //LivecurrencyTooltip: string = "";
    AmountDue: number = 0;
    CreditData = [];
    TotalCreditAmount: number = 0;
    errorNotifierID: number = 1
    disableinvTypeAcc: boolean = false;
    totalTax: any = 0;
    @Input() AdvanceInventoryPopupMode: string = 'View';
    _RODetailID: number = 0;
    _billDetailID: number = 0;
    _invoiceDetailID: number = 0;
    _DeliveryDetailID: number = 0;
    _isShowBatchModalPopup: boolean = false;
    _isShowSerialModalPopup: boolean = false;
    currentUser: any;
    SMDelete: boolean = false;
    RMDelete: boolean = false;
    PCNDelete: boolean = false;
    SCNDelete: boolean = false;
    isDeposit: boolean = false;
    currentInvoiceDeposit: number = 0;
    currentInvoiceDepositTax: number = 0;
    toastrID:number = 0;
    @Input() isDOEnabled: boolean = true;
    constructor(private http: HttpServices, private taxcal: TaxCalculationServices, private local: LocalCacheServices,
        private masterServices: MasterServices, private alertMessage: AlertMessage, private bankService: BankaccountService,
        private router: Router,private authenticationService: AuthenticationService, private translate: TranslateService) {

        this.orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
        this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));

        this.orgCurrencyCode = this.orgSettings.currencyCode;
        if (this.orgSettings.enableHTMLDesc == true) {
            this.enableHTMLDesc = true;
        }
        if (this.rowData.length > 0) {
            this.onTotalCalculation();
        }
    }

    ngOnInit(): void {
        this.getChartAcounts();
        this.getTaxRates();
        if (this.isGridEditable == true) {
            this.getItems();
        }
        this.taxcal.getTaxCompoundRate();
        this.SMDelete = this.currentUser.menuAccessRights.filter(x => x.pageURL == '/transactions/newspendmoney' && x.accessRight == this.currentUser.userRole)[0]?.canDelete;
        this.RMDelete = this.currentUser.menuAccessRights.filter(x => x.pageURL == '/transactions/newreceivemoney' && x.accessRight == this.currentUser.userRole)[0]?.canDelete;
        this.PCNDelete = this.currentUser.menuAccessRights.filter(x => x.pageURL == '/purchase/creditnotes/creditnoteview' && x.accessRight == this.currentUser.userRole)[0]?.canDelete;
        this.SCNDelete = this.currentUser.menuAccessRights.filter(x => x.pageURL == '/sales/creditnotes/creditnoteview' && x.accessRight == this.currentUser.userRole)[0]?.canDelete;

    }

    ngAfterViewInit() {
        this.setItemUnitPrice("edit");
    }

    ngOnChanges(changes: SimpleChanges): void {
        // if (!!changes['deposit'].currentValue)
        //     this.remainingAllocatedDeposit = Math.abs(this.deposit.SubTotalAmountExclusive - this.deposit.DepositUtilized).toFixed(2);

    }

    async getItemCostPrice(itemId, trnDate) {
        let trndate = moment(trnDate).format('YYYY-MM-DD');
        let itemsList: any = [];
        if (itemId == 0) {
            this.rowData.forEach(rw => {
                let rowFeild = {
                    ItemID: (rw.itemID == 0) ? "" : rw.itemID,
                    TrnDate: trndate,
                    TransTypeID : this.copyTransTypeID==undefined ? 0 : this.copyTransTypeID,
                    TransID:this.copyTransID==undefined? 0 : Number(this.copyTransID),
                    TransDetailID: rw.detailID == undefined ? 0 : Number(rw.detailID)
                }
                itemsList.push(rowFeild);
            });
        }
        else {
            let rowFeild = {
                ItemID: itemId,
                TrnDate: trndate,
                TransTypeID : this.copyTransTypeID==undefined ? 0 : this.copyTransTypeID,
                TransID:this.copyTransID==undefined? 0 : Number(this.copyTransID),
                TransDetailID: 0
            }
            itemsList.push(rowFeild);
        }
        let apiUrl = environment.inventoryApiUrl + '/Inventory/GetItemCostPrice';
        this.lstItemCostPrice = await this.http.awaitPostservice(apiUrl,itemsList);
        if (itemId == 0) {
            this.rowData.forEach(rw => {
                let avgCost;
                avgCost = this.lstItemCostPrice.filter(a => a.itemID == rw.itemID);
                rw.itemAvgCost = avgCost.length > 0 ? avgCost[0].avgCost : null;
            });
        }
    }

    getChartAcounts() {
        let apiUrl = environment.organizationApiUrl + '/Master/GetTransChartOfAccounts';
        // if ([1, 2, 3, 4, 10, 20].includes(this.transTypeID)) {
        //   apiUrl = environment.organizationApiUrl + '/Master/GetPurchaseChartOfAccounts';
        //   // TrasactionTypeID	TrasactionTypeName
        //   // 1	Purchase Order
        //   // 2	Bill
        //   // 3	Bill Delivery
        //   // 4	Bill CreditNote
        //   // 10	Spend money
        //   // 20	Receive Order

        // } else if ([5, 6, 7, 8, 11, 21].includes(this.transTypeID)) {
        //   apiUrl = environment.organizationApiUrl + '/Master/GetSalesChartOfAccounts';
        //   // TrasactionTypeID	TrasactionTypeName
        //   // 5	Quotation
        //   // 6	Invoice
        //   // 7	Invoice Delivery
        //   // 8	Invoice CreditNote
        //   // 11	Receive money
        //   // 21	Delivery Order
        // }

        let params = new HttpParams();
        params = params.set('TransTypeID', this.transTypeID.toString());
        this.http.postservice(apiUrl, params).subscribe(
            {
                next: (data) => {
                    // if (data['isSuccess'] === true) {
                    this.lstChartAcounts = data;
                    // }
                }
            });
    }
    getTaxRates() {
        let apiUrl = environment.organizationApiUrl + '/Master/GetTaxRateMaster';
        if ([1, 2, 3, 4, 10, 20,31].includes(this.transTypeID)) {
            apiUrl = environment.organizationApiUrl + '/Master/GetPOTaxRate';
            // TrasactionTypeID	TrasactionTypeName
            // 1	Purchase Order
            // 2	Bill
            // 3	Bill Delivery
            // 4	Bill CreditNote

        } else if ([5, 6, 7, 8, 11, 21, 29,30].includes(this.transTypeID)) {
            apiUrl = environment.organizationApiUrl + '/Master/GetSalesTaxRate';
            // TrasactionTypeID	TrasactionTypeName
            // 5	Quotation
            // 6	Invoice
            // 7	Invoice Delivery
            // 8	Invoice CreditNote
        }

        this.http.getservice(apiUrl).subscribe({
            next: (data) => {
                this.lsttaxRate = data;
            }
        });
    }
    customSearchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        let lst = (item.itemName + item.itemCode).toLocaleLowerCase().indexOf(term) > -1;
        return lst;
    }
    getItems() {
        let isCopy = false;
        isCopy = this.copyTransID != 0 ? true : false;
        let apiUrl = environment.inventoryApiUrl + '/Inventory/GetItems?TransactionTypeID=' + this.transTypeID + '&ItemID=0' + `&TransID=${this.transID}`+'&IsCopy='+isCopy;
        this.http.getservice(apiUrl).subscribe({
            next: (data) => {
                this.lstInventoryItem = data;
                this.UntracklstInventoryItem=this.lstInventoryItem.filter(y => y.isTrackedItem != true && y.itemTypeID!=3);
            }
        });
    }
    fetchMore() {
        const len = this.itemBuffer.length;
        const more = this.lstInventoryItem.slice(len, this.bufferSize + len);
        this.itemloading = true;
        // using timeout here to simulate backend API delay
        setTimeout(() => {
            this.itemloading = false;
            this.itemBuffer = this.itemBuffer.concat(more);
        }, 200)
    }
    getCustomFields(isCopyTransaction?: boolean) {
        if (isCopyTransaction == undefined)
            isCopyTransaction = false;

        let _transID: number = 0;
        if (this.copyTransID > 0 && Number(this.transID) == 0) {
            _transID = this.copyTransID;
        }
        else {
            _transID = this.transID;
        }
        let _pos: number = 3;
        this.http.getservice(environment.organizationApiUrl + '/CustomFieldSetting/GetCustomFieldSettingsByField?fieldID=0' +
            '&transTypeId=' + ((this.copyTransTypeID ?? 0) == 0 ? this.transTypeID : (this.copyTransTypeID == 20 ? 1 : this.copyTransTypeID)) + '&transId=' + _transID + '&isCopyTransaction=' + isCopyTransaction).subscribe({
                next: (data: any) => {
                    if (data.StatusMessage!!) {
                        let detailCustomFields = JSON.parse(data.StatusMessage);
                        if (this.transTypeID == 1) {
                            detailCustomFields = detailCustomFields.filter(y => y.POEnabled == true && y.IsDetail == true);
                        }
                        else if (this.transTypeID == 2) {
                            detailCustomFields = detailCustomFields.filter(y => y.BillsEnabled == true && y.IsDetail == true);
                        }
                        else if (this.transTypeID == 4) {
                            detailCustomFields = detailCustomFields.filter(y => y.CreditNoteEnabled == true && y.IsDetail == true);
                        }
                        else if (this.transTypeID == 5) {
                            detailCustomFields = detailCustomFields.filter(y => y.QuotationEnabled == true && y.IsDetail == true);
                        }
                        else if (this.transTypeID == 6) {
                            detailCustomFields = detailCustomFields.filter(y => y.InvoiceEnabled == true && y.IsDetail == true);
                        }
                        else if (this.transTypeID == 8) {
                            detailCustomFields = detailCustomFields.filter(y => y.SCreditNoteEnabled == true && y.IsDetail == true);
                        }
                        else if (this.transTypeID == 10) {
                            detailCustomFields = detailCustomFields.filter(y => y.SpendMoneyEnabled == true && y.IsDetail == true);
                            if(this.statusTypeID==3){
                                _pos= this.columns.findIndex(e=>e.field=="exclusiveAmount" || e.field=="amount");
                            }
                        }
                        else if (this.transTypeID == 11) {
                            detailCustomFields = detailCustomFields.filter(y => y.ReceiveMoneyEnabled == true && y.IsDetail == true);
                            if(this.statusTypeID==3){
                                _pos= this.columns.findIndex(e=>e.field=="exclusiveAmount" || e.field=="amount");
                            }
                        }
                        else if (this.transTypeID == 12) {
                            detailCustomFields = detailCustomFields.filter(y => y.InventoryEnabled == true && y.IsDetail == true);
                        }
                        else if (this.transTypeID == 14) {
                            detailCustomFields = detailCustomFields.filter(y => y.JournalEntryEnabled == true && y.IsDetail == true);
                        }
                        else if (this.transTypeID == 21) {
                            detailCustomFields = detailCustomFields.filter(y => y.DOEnabled == true && y.IsDetail == true);
                            _pos = 1;
                        }
                        else if (this.transTypeID == 29) {
                            detailCustomFields = detailCustomFields.filter(y => y.SalesOrderEnabled == true && y.IsDetail == true);
                        }
                        else if (this.transTypeID == 30) {
                            detailCustomFields = detailCustomFields.filter(y => y.DebitNoteEnabled == true && y.IsDetail == true);
                        }
                        else if (this.transTypeID == 31) {
                            detailCustomFields = detailCustomFields.filter(y => y.VDebitNoteEnabled == true && y.IsDetail == true);
                        }
                        detailCustomFields.forEach(async (dcf: any, r) => {
                            let cobj = {
                                field: dcf.FieldID,
                                title: dcf.FieldName,
                                type: dcf.TypeID == 2 ? 'list' : 'text',
                                lstValues: dcf.TypeID == 2 ? dcf.CustomListValues : [],
                                isVisible: true,
                                isEditable: true,
                                isCustom: true,
                                isMandatory: dcf.IsMandatory,
                                css: {
                                    'width': '100px',
                                    'max-width': '100px',
                                    'text-align': 'left'
                                }
                            };
                            if (!this.columns.some(o => o.field == dcf.FieldID))
                                this.columns.splice(this.columns.length - _pos, 0, cobj);
                            if (this.transID > 0 || this.copyTransID > 0) {
                                if (dcf.CustomSelectedListDetailValues!!) {
                                    for (let i = 0; i < dcf.CustomSelectedListDetailValues.length; i++) {
                                        this.rowData.forEach((row: any, r) => {
                                            if (row.isGroup == false) {
                                                if (row.detailID == dcf.CustomSelectedListDetailValues[i].TransDetailId) {
                                                    row[dcf.FieldID] = dcf.TypeID == 2 ? dcf.CustomSelectedListDetailValues[i].CustomValueID : dcf.CustomSelectedListDetailValues[i].CustomValueText;
                                                    row['txt' + dcf.FieldID] = dcf.TypeID == 2 ? dcf.CustomSelectedListDetailValues[i].CustomValueText : null;
                                                }
                                            }
                                            else if (row.isGroup == true) {
                                                row.lstChild.forEach((childrow: any, c) => {
                                                    if (childrow.detailID == dcf.CustomSelectedListDetailValues[i].TransDetailId) {
                                                        childrow[dcf.FieldID] = dcf.TypeID == 2 ? dcf.CustomSelectedListDetailValues[i].CustomValueID : dcf.CustomSelectedListDetailValues[i].CustomValueText;
                                                        childrow['txt' + dcf.FieldID] = dcf.TypeID == 2 ? dcf.CustomSelectedListDetailValues[i].CustomValueText : null;
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                            }
                        });
                    }
                }
            });
    }

    onScrollToEnd() {
        this.fetchMore();
    }
    onScroll({ end }) {
        if (this.itemloading || this.lstInventoryItem.length <= this.itemBuffer.length) {
            return;
        }
        if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.itemBuffer.length) {
            this.fetchMore();
        }
    }

    onOutClick() {
        //this.inlineIdx  = -1;
        //this.childinlineIdx = -1;
    }

    onSetText(column, id, parentIndex, childindex) {
        let text = column.lstValues.filter(x => x.CustomValueID == id)
        //const app = document.getElementById("div_" + parentIndex + "_" + childindex);
        //app.innerText = text[0].CustomValueText;
        let field = 'txt' + column.field;
        if (childindex == -1) {
            this.rowData[parentIndex][field] = text.length > 0 ? text[0].CustomValueText : null;
        }
        else {
            this.rowData[parentIndex].lstChild[childindex][field] = text.length > 0 ? text[0].CustomValueText : null;
        }
    }

    addTable(count, parentIndex, callfrom, isUpdate = false) {
        if (((this.typeName === 'Overpayment' && this.rowData.length > 0) || this.isOnlyGridCustomCellEdit)) {
            return;
        }
        for (let i = 0; i < count; i++) {
            let obj = {
                index: 0,
                drag: callfrom == 'p' ? true : false,
                detailID: null,
                itemID: 0,
                itemName: '',
                itemTypeID: 0,
                productCategoryID: null,
                productCategoryName: '',
                itemGroupID: callfrom == 'p' ? null : this.rowData[parentIndex].itemID,
                groupItemName: callfrom == 'p' ? '' : this.rowData[parentIndex].itemName,
                description: '',
                HTMLDesc: '',
                quantity: '',
                itemUnitTypeID: null,
                itemUnitTypeName: '',
                unitPrice: '',
                itemAvgCost: '',
                discount: '',
                discountAmount: null,
                isTrackedItem: false,
                accountID: null,
                accountName: '',
                isAccdisabled: false,
                taxRateID: null,
                taxrateName: '',
                taxAmount: null,
                grossAmount: '',
                amount: '',
                delete: true,
                isGroup: false,
                IsExisting: false,
                grpQuantity: callfrom == 'p' ? '' : 1,
                lstChild: [],
                IsQtyReturn :true
            }
            if (callfrom == 'p') {
                if (!isUpdate)
                    this._rowData.splice(this.rowData.length, 0, obj);
                else
                    this._rowData[parentIndex] = obj;
            }
            else if (callfrom == 'c') {
                let childItems = this.rowData[parentIndex].lstChild;
                this._rowData[parentIndex].lstChild.splice(childItems.length, 0, obj);
            }
        }
    }

    UpdateAccount(rwData: any) {
        // Below section will trigger each transaction page in edit logic and copy option for PO -> Invoice & Quote
        // This will assign account details and keep it as disabled control for Bill, PCN (only Inventory type)
        // While copy PO to Invoice/Quotation -> will assing respective sales account (based on item or contact)
        console.log("Copy: ",this.transTypeID,this.copyTransTypeID, this.taxTypeName)
        setTimeout(() => {
            if (([1, 2, 4, 10].some(o => o == this.transTypeID)) || ([5, 6, 29].some(o => o == this.transTypeID)) && ([1].some(o => o == this.copyTransTypeID))) {
                if (rwData.length > 0) {
                    //if ((this.lstInventoryItem) && this.lstInventoryItem.length > 0) {
                        rwData.forEach(ele => {
                            if (ele.isGroup == true) {
                                ele.lstChild.forEach(ch => {
                                    if(ch.accountID || ch.taxRateID){
                                        this.AssignAccountDetail(ch);
                                    }
                                });
                            }
                            else {
                                if(ele.accountID || ele.taxRateID){
                                    this.AssignAccountDetail(ele);
                                }
                            }
                        });
                    //}
                }
            }
        }, 600);
    }

    AssignAccountDetail(ele) {
        debugger       
        let curItem = this.lstInventoryItem.filter(m => m.itemID == ele.itemID); 
        //Account
        if (curItem.length > 0) {           
            if (curItem[0].isTrackedItem == true && ([1, 2, 4, 10, 20, 31].some(o => o == this.transTypeID))) {
                ele.accountID = curItem[0].itemTypeID == 3 ? null : curItem[0].inventoryAssetAccountID;
                ele.accountName = curItem[0].inventoryAssetAccountName;
                ele.isAccdisabled = true;
            }
            else {
                ele.isAccdisabled = false;
                if (this.contact.contactID > 0) {
                    ele.accountID = curItem[0].itemTypeID == 3 ? null : ([1, 2, 4, 10, 20, 31].some(o => o == this.transTypeID)) ? (this.contact.purchaseAccontID > 0 ? this.contact.purchaseAccontID : curItem[0].purchaseAccountID) : (this.contact.salesAccountID > 0 ? this.contact.salesAccountID : curItem[0].salesAccountID);
                }
                else {
                    ele.accountID = curItem[0].itemTypeID == 3 ? null : ([1, 2, 4, 10, 20, 31].some(o => o == this.transTypeID)) ? curItem[0].purchaseAccountID : curItem[0].salesAccountID;
                }
                if (ele.accountID) {
                    let an = this.lstChartAcounts.filter(x => x.CAAccountID == ele.accountID);
                    ele.accountName = an == undefined ? '' : an[0].DisplayAccountName;
                }
                else {
                    ele.accountID = null;
                    ele.accountName = "";
                }
            }
        }
        else {
            ele.isAccdisabled = false;
            if (this.contact.contactID > 0) {
                ele.accountID = ([1, 2, 4, 10, 20, 31].some(o => o == this.transTypeID)) ? this.contact.purchaseAccontID : this.contact.salesAccountID;
            }
            if (ele.accountID) {
                let an = this.lstChartAcounts.filter(x => x.CAAccountID == ele.accountID);
                ele.accountName = an == undefined ? '' : an[0].DisplayAccountName;
            }
            else {
                ele.accountID = null;
                ele.accountName = "";
            }
        }  

        //Tax Rate       
        if (curItem.length > 0) {
            if (this.contact.contactID > 0) {
                ele.taxRateID = this.taxTypeName == 'No Tax' ? ele.taxRateID : (curItem[0].itemTypeID == 3 ? null : ([1, 2, 4, 10, 20, 31].some(o => o == this.transTypeID)) ? (this.contact.purchaseTaxID > 0 ? this.contact.purchaseTaxID : curItem[0].purchaseTaxID) : (this.contact.salesTaxID > 0 ? this.contact.salesTaxID : curItem[0].salesTaxID));
            }
            else {
                ele.taxRateID = this.taxTypeName == 'No Tax' ? ele.taxRateID : (curItem[0].itemTypeID == 3 ? null : ([1, 2, 4, 10, 20, 31].some(o => o == this.transTypeID)) ? curItem[0].purchaseTaxID : curItem[0].salesTaxID);
            }
            if (ele.taxRateID) {
                let an = this.lsttaxRate.filter(x => x.mstrTaxRateID == ele.taxRateID);
                ele.taxrateName = an == undefined ? '' : an[0].taxName;
            }
            else {
                ele.taxRateID = null;
                ele.taxrateName = "";
                ele.taxAmount = null;
            }
        }
        else {
            if (this.contact.contactID > 0) {
                ele.taxRateID = this.taxTypeName == 'No Tax' ? ele.taxRateID : (([1, 2, 4, 10, 20, 31].some(o => o == this.transTypeID)) ? this.contact.purchaseTaxID : this.contact.salesTaxID);
            }
            if (ele.taxRateID) {
                let an = this.lsttaxRate.filter(x => x.mstrTaxRateID == ele.taxRateID);
                ele.taxrateName = an == undefined ? '' : an[0].taxName;
            }
            else {
                ele.taxRateID = null;
                ele.taxrateName = "";
                ele.taxAmount = null;
            }
        }  

        this.onTaxCalculation();
    }

    inlineEdit(parentIndex, childindex, callfrom, field) {
        if (this.isGridEditable == false) {
            this.inlineIdx = null;
            return;
        }

        let editcell = field + parentIndex.toString() + childindex.toString();
        if (this.inlineIdx === editcell) {
            return;
        }

        if (!(field == 'taxRateID' && this.taxTypeName == 'No Tax')) {
            let isEditable = this.columns.filter(x => x.field == field)[0].isEditable;
            if ((field == 'accountID' || field == 'taxRateID') && this.rowData[parentIndex].isDeposit) {
                isEditable = false;
            }
            if (isEditable == true) {
                this.inlineIdx = editcell;
            }
            else {
                this.inlineIdx = null;
            }
        }

        if (field == 'unitPrice' && this.rowData[parentIndex].isDeposit && (!this.isPartiallypaid && this.statusTypeID != 4)) {
            this.inlineIdx = editcell;
        }
        // If receive order restrict to add new line item
        if (this.transTypeID != 20 && this.rowData[parentIndex].Deposit != '1'  && this.rowData[parentIndex].Deposit != '2') {
            //default empty row added
            if (callfrom == 'p') {
                if (this.rowData[this.rowData.length - 1].itemID > 0 && this.isAddCnDnFromInvoiceBill) {
                    this.addTable(1, parentIndex, callfrom);
                }
            }
            else {
                let childItems = this.rowData[parentIndex].lstChild;
                if (childItems[childItems.length - 1].itemID > 0 && this.isAddCnDnFromInvoiceBill) {
                    this.addTable(1, parentIndex, callfrom);
                }
            }
        }
        return;
    }

    prevItemID: any;
    OnItemFocus(_row: any) {
        this.prevItemID = _row.itemID; 
    }
  
    onCellValueClick(field, parentIndex, childindex) {
        if (field == 'delete') {
            if (this.rowData[parentIndex].isROExist) {
                let msg = this.translate.instant("SharedModule.RO already created for this item. Cannot be allowed to delete.");
                this.alertMessage.errorNotifier(msg, this.errorNotifierID);
                return false;
            }
            if (this.rowData[parentIndex].AdvTrackedItemRowIsLocked) {
                let msg = this.translate.instant("Batch/Serial assigned for this item, Cannot be allowed to delete.");
                this.alertMessage.errorNotifier(msg, this.errorNotifierID);
                return false;
            }

            let deleteindex = field + parentIndex.toString() + childindex.toString();
            if (this.inlineIdx === deleteindex) {
                this.inlineIdx = null;
            }

            if (childindex == -1) {
                if (this.rowData[parentIndex].isDeposit) {
                    this.currentInvoiceDeposit = 0;
                    this.currentInvoiceDeposit = 0;
                    this.currentInvoiceDepositTax = 0;
                    this.emitOnProgressiveInvChanges.emit(this.currentInvoiceDeposit);

                }
                this.rowData.splice(parentIndex, 1);
                let Obj = {
                    callFrom: "delete",
                    index: parentIndex,
                }
                this.getunitprice.getItemUnitPrice(Obj);
            }
            else if (childindex >= 0) {
                this.rowData[parentIndex].lstChild.splice(childindex, 1);
                this.onCellValueChanged(this.rowData[parentIndex], "quantity", parentIndex, childindex, 'c')
            }
            this.onTotalCalculation();
            if (this.taxTypeName == 'Tax Exclusive') {
                this.HideAdjustmentTax('', field);
            }
        }
    }

    HideAdjustmentTax(row, field) {
        let tn: any;
        let result = false;

        if (field == 'accountID' || field == 'taxRateID') {
            if (field == 'accountID') {
                let an = this.lstChartAcounts.filter(x => x.CAAccountID == row.accountID)[0];
                tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == row.taxRateID)[0];
                row.taxRateID = tn == undefined ? null : an.TaxID;
            }
            else if (field == 'taxRateID') {
                tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == row.taxRateID)[0];
            }

            if (tn !== undefined && tn.rate > 0) {
                result = true;
            }
        }
        for (let i = 0; i < this.rowData.length; i++) {
            if (this.rowData[i].isGroup == false) {
                let id = (this.rowData[i].taxRateID == null ? 0 : this.rowData[i].taxRateID);
                if (this.lsttaxRate.filter(x => x.mstrTaxRateID == id).length > 0) {
                    if (this.lsttaxRate.filter(x => x.mstrTaxRateID == id)[0].rate > 0) {
                        this.HidetaxAdjustment = false;
                        //this.taxAdjustment=this.backuptaxAdjustment;
                        break;
                    }
                    else {
                        this.HidetaxAdjustment = true;
                        //this.taxAdjustment=0;
                    }
                }
            }
            else if (this.rowData[i].lstChild) {
                for (let j = 0; j < this.rowData[i].lstChild.length; j++) {
                    let id = (this.rowData[i].lstChild[j].taxRateID == null ? 0 : this.rowData[i].lstChild[j].taxRateID);
                    if (this.lsttaxRate.filter(x => x.mstrTaxRateID == id).length > 0) {
                        if (this.lsttaxRate.filter(x => x.mstrTaxRateID == id)[0].rate > 0) {
                            this.HidetaxAdjustment = false;
                            //this.taxAdjustment=this.backuptaxAdjustment;
                            break;
                        }
                        else {
                            this.HidetaxAdjustment = true;
                            // this.taxAdjustment=0;
                        }
                    }
                }
            }
        }
        if (result == true && this.HidetaxAdjustment == true) {
            this.HidetaxAdjustment = false;
            //this.taxAdjustment=this.backuptaxAdjustment;
        }
        else if (result == false && this.HidetaxAdjustment == true) {
            this.HidetaxAdjustment = true;
            //this.taxAdjustment=0;
        }
    }

    onCellValueChanged(row, field, parentIndex, childindex, callfrom) {

        if (field === "unitPrice" && row.isDeposit) {
            if (row.unitPrice > 0) {
                row.unitPrice = -row.unitPrice;
            }
            if (isNaN(row.unitPrice)) {
                // this.currentInvoiceDeposit = 0;
                // this.currentInvoiceDepositTax = 0;
                // this.emitOnProgressiveInvChanges.emit(this.currentInvoiceDeposit);
                // row.unitPrice =0;
            }
            // if (Math.abs(row.unitPrice) > Math.abs(this.deposit.Subtotal)) {
            //     row.unitPrice = -(this.deposit.Subtotal ).toFixed(2);
            // } else if (row.unitPrice > 0) {
            //     row.unitPrice = -row.unitPrice
            // }
        }
        if (field == 'itemID') {
            let itemValue = null;
            if (callfrom == 'c') {
                itemValue = this.lstInventoryItem.filter(item => item.itemID == row.lstChild[childindex].itemID);
                if (itemValue[0].itemTypeID == 3) {
                    row.lstChild[childindex].detailID = null;
                    row.lstChild[childindex].itemID = 0;
                    row.lstChild[childindex].itemName = '';
                    row.lstChild[childindex].itemTypeID = 0;
                    row.lstChild[childindex].productCategoryID = null;
                    row.lstChild[childindex].productCategoryName = '';
                    //row.lstChild[childindex].itemGroupID = row.lstChild[childindex].itemGroupID;
                    // row.lstChild[childindex].groupItemName =  row.lstChild[childindex].groupItemName
                    row.lstChild[childindex].description = '';
                    row.lstChild[childindex].HTMLDesc = '';
                    row.lstChild[childindex].quantity = '';
                    row.lstChild[childindex].itemUnitTypeID = null;
                    row.lstChild[childindex].itemUnitTypeName = '';
                    row.lstChild[childindex].unitPrice = '';
                    row.lstChild[childindex].isTrackedItem = false;
                    row.lstChild[childindex].accountID = null;
                    row.lstChild[childindex].accountName = '';
                    row.lstChild[childindex].isAccdisabled = false;
                    row.lstChild[childindex].taxRateID = null;
                    row.lstChild[childindex].taxrateName = '';
                    row.lstChild[childindex].taxAmount = null;
                    row.lstChild[childindex].grossAmount = null;
                    row.lstChild[childindex].amount = null; // -discount
                    row.lstChild[childindex].isGroup = false;
                    row.lstChild[childindex].lstChild = [];
                    if(this.transTypeID==4 || this.transTypeID==8){
                        row.lstChild[childindex].IsQtyReturn = true;
                    }
                    let msg = this.translate.instant("SharedModule.Cannot allowed to add nested group");
                    this.alertMessage.errorNotifier(msg, this.errorNotifierID);
                    return false;
                }
                else {
                    row.lstChild[childindex].itemID = itemValue[0].itemID;
                    row.lstChild[childindex].itemName = itemValue[0].itemName;
                    row.lstChild[childindex].itemTypeID = itemValue[0].itemTypeID;
                    row.lstChild[childindex].productCategoryID = itemValue[0].itemTypeID == 3 ? null : itemValue[0].productCategoryID;
                    row.lstChild[childindex].productCategoryName = itemValue[0].itemTypeID == 3 ? '' : itemValue[0].productCategoryName;
                    //row.lstChild[childindex].itemGroupID = row.lstChild[childindex].itemGroupID;
                    // row.lstChild[childindex].groupItemName =  row.lstChild[childindex].groupItemName
                    row.lstChild[childindex].description = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? itemValue[0].purchaseDescription : itemValue[0].salesDescription;
                    row.lstChild[childindex].HTMLDesc = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? itemValue[0].purchaseHTMLDesc : itemValue[0].salesHTMLDesc;
                    row.lstChild[childindex].quantity = 1;
                    row.lstChild[childindex].itemUnitTypeID = itemValue[0].itemUnitTypeID;
                    row.lstChild[childindex].itemUnitTypeName = itemValue[0].itemUnitTypeName;
                    row.lstChild[childindex].unitPrice = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? itemValue[0].purchasePrice : itemValue[0].salesPrice;
                    row.lstChild[childindex].isTrackedItem = itemValue[0].isTrackedItem;
                    if (itemValue[0].isTrackedItem == true && ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID))) {
                        row.lstChild[childindex].accountID = itemValue[0].inventoryAssetAccountID;
                        row.lstChild[childindex].accountName = itemValue[0].inventoryAssetAccountName;
                    }
                    else if (this.contact.contactID > 0) {
                        if (row.lstChild[childindex].IsExisting == false) {
                            row.lstChild[childindex].accountID = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ?
                                (this.contact.purchaseAccontID > 0 ? this.contact.purchaseAccontID : itemValue[0].purchaseAccountID) :
                                (this.contact.salesAccountID > 0 ? this.contact.salesAccountID : itemValue[0].salesAccountID);
                        }
                    }
                    else {
                        if (row.lstChild[childindex].IsExisting == false) {
                            row.lstChild[childindex].accountID = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? itemValue[0].purchaseAccountID : itemValue[0].salesAccountID;
                        }
                    }

                    if (this.taxTypeName == 'No Tax') {
                        let taxRate = this.lsttaxRate.filter(x => x.taxCode == 'NOTAX')[0];
                        row.lstChild[childindex].taxRateID = taxRate == undefined ? null : taxRate.mstrTaxRateID;
                    }
                    else if (this.contact.contactID > 0) {
                        if (row.lstChild[childindex].IsExisting == false) {
                            row.lstChild[childindex].taxRateID = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ?
                                (this.contact.purchaseTaxID > 0 ? this.contact.purchaseTaxID : itemValue[0].purchaseTaxID) :
                                (this.contact.salesTaxID > 0 ? this.contact.salesTaxID : itemValue[0].salesTaxID);
                        }
                    }
                    else {
                        if (row.lstChild[childindex].IsExisting == false) {
                            row.lstChild[childindex].taxRateID = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? itemValue[0].purchaseTaxID : itemValue[0].salesTaxID;
                        }
                    }

                    if (itemValue[0].isTrackedItem == true && ([1, 2, 4, 10,31].some(o => o == this.transTypeID))) {
                        this.disableinvTypeAcc = true;
                        row.lstChild[childindex].isAccdisabled = true;
                    }
                    else {
                        this.disableinvTypeAcc = false;
                        row.lstChild[childindex].isAccdisabled = false;
                        let an = this.lstChartAcounts.filter(x => x.CAAccountID == row.lstChild[childindex].accountID)[0];
                        row.lstChild[childindex].accountName = an == undefined ? '' : an.DisplayAccountName;
                    }

                    let tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == row.lstChild[childindex].taxRateID)[0];
                    row.lstChild[childindex].taxrateName = tn == undefined ? '' : tn.taxName;
                    row.lstChild[childindex].taxAmount = 0;
                    // row.lstChild[childindex].grossAmount = Number(row.lstChild[childindex].quantity) * Number(row.lstChild[childindex].unitPrice);
                    row.lstChild[childindex].grossAmount = isNaN(Number(row.lstChild[childindex].unitPrice)) ? 0 : Number(row.lstChild[childindex].quantity) * Number(row.lstChild[childindex].unitPrice);

                    row.lstChild[childindex].discount = ([5, 6, 29].some(o => o == this.transTypeID) && this.contact.contactID > 0 && this.contact.salesDiscount > 0) ? this.contact.salesDiscount + '%' : ([2, 1].some(o => o == this.transTypeID) && this.contact.contactID > 0 && this.contact.purchaseDiscount > 0) ? this.contact.purchaseDiscount + '%' : '';

                    // if (Number(row.discount) > 0) {
                    //   row.lstChild[childindex].discount = Number(row.discount);
                    // }
                    // else {
                    //   row.lstChild[childindex].discount = '';
                    // }
                    let discountAmt = 0;
                    if (Number(row.lstChild[childindex].discount) > 0) {
                        discountAmt = (Number(row.lstChild[childindex].grossAmount) / 100) * (row.lstChild[childindex].discount);
                        if (isNaN(discountAmt)) {
                            discountAmt = 0;
                        }
                    }
                    row.lstChild[childindex].discountAmount = Number(discountAmt);
                    row.lstChild[childindex].amount = ((Number(Number(row.lstChild[childindex].grossAmount) - Number(discountAmt)) * 100) / 100).toFixed(2);
                    //row.lstChild[childindex].amount = Number(Math.round(row.lstChild[childindex].amount * 100) / 100).toFixed(2);

                    row.lstChild[childindex].isGroup = false;
                    row.lstChild[childindex].lstChild = [];

                    //Parent row
                    let sumofchildgrossAmount = Number(row.unitPrice) + Number(row.lstChild[childindex].grossAmount);
                    row.unitPrice = sumofchildgrossAmount / Number(row.quantity);
                    if (isNaN(row.unitPrice)) {
                        row.unitPrice = 0;
                    }
                    // row.grossAmount = Number(row.quantity) * Number(row.unitPrice);
                    row.grossAmount = isNaN(Number(row.unitPrice)) ? 0 : Number(row.quantity) * Number(row.unitPrice);
                    let grpdiscountAmt = 0;
                    if (Number(row.discount) > 0) {
                        grpdiscountAmt = (Number(row.grossAmount) / 100) * (row.discount);
                        if (isNaN(grpdiscountAmt)) {
                            grpdiscountAmt = 0;
                        }
                    }
                    row.discountAmount = Number(grpdiscountAmt);
                    row.amount = ((Number(Number(row.grossAmount) - Number(grpdiscountAmt)) * 100) / 100).toFixed(2);
                    //row.amount = Number(Math.round(Number(row.amount) * 100) / 100).toFixed(2);
                    //
                    if(this.transTypeID==4 || this.transTypeID==8){
                        row.lstChild[childindex].IsQtyReturn = itemValue[0].IsQtyReturn;
                    }
                }
            }
            else if (callfrom == 'p') {
                if (this.rowData[parentIndex].isROExist) {
                    itemValue = this.lstInventoryItem.filter(item => item.itemID == this.prevItemID);
                    row.itemID = itemValue[0].itemID;
                    row.itemName = itemValue[0].itemName;
                    row.itemTypeID = itemValue[0].itemTypeID;
                    row.productCategoryID = itemValue[0].itemTypeID == 3 ? null : itemValue[0].productCategoryID;
                    row.productCategoryName = itemValue[0].itemTypeID == 3 ? '' : itemValue[0].productCategoryName;
                    let msg = this.translate.instant("SharedModule.RO already created for this item. Cannot be allowed to change.");
                    this.alertMessage.errorNotifier(msg, this.errorNotifierID);
                    this.inlineEdit(parentIndex, childindex + 1, callfrom, field);
                    return false;
                }

                itemValue = this.lstInventoryItem.filter(item => item.itemID == row.itemID);
                if (this.rowData.filter((o, i) => i != parentIndex && o.isGroup == true && o.itemID == itemValue[0].itemID).length > 0) {
                    let msg = this.translate.instant("SharedModule.Cannot allowed to add duplicate group");
                    this.alertMessage.errorNotifier(msg, this.errorNotifierID);
                    this.addTable(1, parentIndex, 'p', true);
                    return false;
                }

                row.itemID = itemValue[0].itemID;
                row.itemName = itemValue[0].itemName;
                row.itemTypeID = itemValue[0].itemTypeID;
                row.productCategoryID = itemValue[0].itemTypeID == 3 ? null : itemValue[0].productCategoryID;
                row.productCategoryName = itemValue[0].itemTypeID == 3 ? '' : itemValue[0].productCategoryName;
                row.itemGroupID = null;
                row.groupItemName = '';
                row.description = itemValue[0].itemTypeID == 3 ? itemValue[0].groupDescription : ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? (itemValue[0].purchaseDescription == null ? '' : itemValue[0].purchaseDescription) : (itemValue[0].salesDescription == null ? '' : itemValue[0].salesDescription);
                row.HTMLDesc = itemValue[0].itemTypeID == 3 ? '' : ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? itemValue[0].purchaseHTMLDesc : itemValue[0].salesHTMLDesc;
                row.quantity = 1;
                row.discount = ([5, 6, 29].some(o => o == this.transTypeID) && this.contact.contactID > 0 && this.contact.salesDiscount > 0) ? this.contact.salesDiscount + '%' : ([2, 1].some(o => o == this.transTypeID) && this.contact.contactID > 0 && this.contact.purchaseDiscount > 0) ? this.contact.purchaseDiscount + '%' : "";
                row.discountAmount = null;
                row.itemUnitTypeID = itemValue[0].itemTypeID == 3 ? null : itemValue[0].itemUnitTypeID;
                row.itemUnitTypeName = itemValue[0].itemTypeID == 3 ? '' : itemValue[0].itemUnitTypeName;
                row.unitPrice = itemValue[0].itemTypeID == 3 ? null : ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? itemValue[0].purchasePrice : itemValue[0].salesPrice;
                row.isTrackedItem = itemValue[0].isTrackedItem;
                row.IsQtyReturn =1
                if(row.isTrackedItem==1 && (this.transTypeID==4 || this.transTypeID==8)){
                    this.baseUnitPrice=row.unitPrice;
                }
                if (row.isTrackedItem && !!this.TransDate && this.transTypeID == 8 && itemValue[0].avgCostTypeID == 1) {
                    this.getItemCostPrice(itemValue[0].itemID, this.TransDate).then(o => {
                        if (this.lstItemCostPrice.length > 0) {
                            row.itemAvgCost = this.lstItemCostPrice[0].avgCost;
                        }
                    });
                } else if (row.isTrackedItem && (this.transTypeID == 8 || this.transTypeID == 4) && itemValue[0].avgCostTypeID == 2) {
                    let param = this.rowData.map(ele => ({
                        quantity: ele.quantity,
                        itemID: ele.itemID
                    }));

                    row.itemAvgCost = null;

                    if ([2, 6].some(e => e == this.copyTransTypeID)) {
                        row.isDisableCostPrice = true;
                    } else {
                        row.isDisableCostPrice = false;
                    }

                    // this.showFIFOCostPrice(param);
                }
                if (itemValue[0].isTrackedItem == true && ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID))) {
                    row.accountID = itemValue[0].itemTypeID == 3 ? null : itemValue[0].inventoryAssetAccountID;
                    row.accountName = itemValue[0].inventoryAssetAccountName;
                }
                else if (this.contact.contactID > 0) {
                    if (row.IsExisting == false || (row.accountID == null || row.accountID == 0 )) {
                        row.accountID = itemValue[0].itemTypeID == 3 ? null : ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? (this.contact.purchaseAccontID > 0 ? this.contact.purchaseAccontID : itemValue[0].purchaseAccountID) : (this.contact.salesAccountID > 0 ? this.contact.salesAccountID : itemValue[0].salesAccountID);
                    }
                }
                else {
                    if (row.IsExisting == false || (row.accountID == null || row.accountID == 0 )) {
                        row.accountID = itemValue[0].itemTypeID == 3 ? null : ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? itemValue[0].purchaseAccountID : itemValue[0].salesAccountID;
                    }
                }

                if (this.taxTypeName == 'No Tax') {
                    let taxRate = this.lsttaxRate.filter(x => x.taxCode == 'NOTAX')[0];
                    row.taxRateID = itemValue[0].itemTypeID == 3 ? null : (taxRate == undefined ? null : taxRate.mstrTaxRateID);
                }
                else if (this.contact.contactID > 0) {
                    if (row.IsExisting == false || (row.taxRateID == null || row.taxRateID == 0)) {
                        row.taxRateID = itemValue[0].itemTypeID == 3 ? null : ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? (this.contact.purchaseTaxID > 0 ? this.contact.purchaseTaxID : itemValue[0].purchaseTaxID) : (this.contact.salesTaxID > 0 ? this.contact.salesTaxID : itemValue[0].salesTaxID);
                    }
                }
                else {
                    if (row.IsExisting == false || (row.taxRateID == null || row.taxRateID == 0)) {
                        row.taxRateID = itemValue[0].itemTypeID == 3 ? null : ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? itemValue[0].purchaseTaxID : itemValue[0].salesTaxID;
                    }
                }

                if (itemValue[0].isTrackedItem == true && ([1, 2, 4, 10,31].some(o => o == this.transTypeID))) {
                    this.disableinvTypeAcc = true;
                    row.isAccdisabled = true;
                }
                else {
                    this.disableinvTypeAcc = false;
                    row.isAccdisabled = false;

                    let an = this.lstChartAcounts.filter(x => x.CAAccountID == row.accountID)[0];
                    row.accountName = an == undefined ? '' : an.DisplayAccountName;
                }

                let tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == row.taxRateID)[0];
                row.taxrateName = tn == undefined ? '' : tn.taxName;
                row.taxAmount = null;
                // row.grossAmount = Number(row.quantity) * Number(row.unitPrice);
                row.grossAmount = isNaN(Number(row.unitPrice)) ? 0 : Number(row.quantity) * Number(row.unitPrice);
                row.amount = ((Number(row.grossAmount) * 100) / 100).toFixed(2); // -discount
                //row.amount = Number(Math.round(Number(row.amount) * 100) / 100).toFixed(2);
                row.isGroup = itemValue[0].itemTypeID == 3 ? true : false;
                row.lstChild = [];
                if(row.isGroup){
                    row.accountID = null;
                    row.accountName = null;
                    row.taxRateID = null;
                    row.taxrateName = null;
                    row.itemUnitTypeID= null;
                    row.itemUnitTypeName = '';
                    row.taxAmount = '';
                }

                if (this.rowData[this.rowData.length - 1].itemID > 0 && this.isAddCnDnFromInvoiceBill) {
                    this.addTable(1, parentIndex, callfrom);
                }
                if (itemValue[0].itemTypeID === 3) {
                    let pgItem = JSON.parse(itemValue[0].groupItems);
                    let grpUnitPrice = 0;
                    for (var i = 0; i < pgItem?.length; i++) {
                        let qty = pgItem[i].grpQuantity ? pgItem[i].grpQuantity : 1;
                        let unitPrice = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? pgItem[i].grpPurchasePrice : pgItem[i].grpSellingPrice;

                        let accName = "";
                        let accountID = null
                        if (pgItem[i].isTrackedItem == true && ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID))) {
                            accountID = pgItem[i].inventoryAssetAccountID;
                            accName = pgItem[i].InventoryAssetAccountName;
                        }
                        else if (this.contact.contactID > 0) {
                            accountID = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? (this.contact.purchaseAccontID > 0 ? this.contact.purchaseAccontID : pgItem[i].purchaseAccountID) : (this.contact.salesAccountID > 0 ? this.contact.salesAccountID : pgItem[i].salesAccountID);
                        }
                        else {
                            accountID = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? pgItem[i].purchaseAccountID : pgItem[i].salesAccountID;
                        }

                        if (pgItem[i].isTrackedItem == true && ([1, 2, 4, 10,31].some(o => o == this.transTypeID))) {
                            this.disableinvTypeAcc = true;
                        }
                        else {
                            this.disableinvTypeAcc = false;

                            let an = this.lstChartAcounts.filter(x => x.CAAccountID == accountID)[0];
                            accName = an == undefined ? '' : an.DisplayAccountName;
                        }

                        let taxRateID = null;
                        if (this.taxTypeName == 'No Tax') {
                            let taxRate = this.lsttaxRate.filter(x => x.taxCode == 'NOTAX')[0];
                            taxRateID = taxRate == undefined ? null : taxRate.mstrTaxRateID;
                        }
                        else if (this.contact.contactID > 0) {
                            taxRateID = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? (this.contact.purchaseTaxID > 0 ? this.contact.purchaseTaxID : pgItem[i].purchaseTaxID) : (this.contact.salesTaxID > 0 ? this.contact.salesTaxID : pgItem[i].salesTaxID);
                        }
                        else {
                            taxRateID = ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? pgItem[i].purchaseTaxID : pgItem[i].salesTaxID;
                        }

                        //let an = this.lstChartAcounts.filter(x => x.CAAccountID == accountID)[0];
                        let tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == taxRateID)[0];
                        grpUnitPrice = grpUnitPrice + (Number(qty) * Number(unitPrice));

                        let obj = {
                            index: 0,
                            drag: false,
                            detailID: null,
                            itemID: pgItem[i].itemID,
                            itemName: pgItem[i].itemName,
                            itemTypeID: pgItem[i].itemTypeID,
                            productCategoryID: pgItem[i].productCategoryID,
                            productCategoryName: pgItem[i].productCategoryName,
                            itemGroupID: itemValue[0].itemID,
                            groupItemName: itemValue[0].itemName,
                            description: ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? pgItem[i].purchaseDescription : pgItem[i].salesDescription,
                            HTMLDesc: ([1, 2, 4, 10, 20,31].some(o => o == this.transTypeID)) ? pgItem[i].purchaseHTMLDesc : pgItem[i].salesHTMLDesc,
                            quantity: qty,
                            itemUnitTypeID: pgItem[i].itemUnitTypeID,
                            itemUnitTypeName: pgItem[i].itemUnitTypeName,
                            unitPrice: unitPrice,
                            discount: ([5, 6, 29].some(o => o == this.transTypeID) && this.contact.contactID > 0 && this.contact.salesDiscount > 0) ? this.contact.salesDiscount + '%' : ([2, 1].some(o => o == this.transTypeID) && this.contact.contactID > 0 && this.contact.purchaseDiscount > 0) ? this.contact.purchaseDiscount + '%' : '',
                            discountAmount: null,
                            isTrackedItem: pgItem[i].isTrackedItem,
                            accountID: accountID,
                            accountName: (!accName) ? '' : accName,
                            isAccdisabled: this.disableinvTypeAcc,
                            taxRateID: taxRateID,
                            taxrateName: tn == undefined ? '' : tn.taxName,
                            taxAmount: null,
                            grossAmount: Number(qty) * Number(unitPrice),
                            amount: Number(qty) * Number(unitPrice), // - discount
                            delete: true,
                            isGroup: false,
                            IsExisting: false,
                            grpQuantity: qty,
                            lstChild: [],
                            itemGroupMappingID: pgItem[i].ItemGroupMappingID,
                            IsQtyReturn:true
                        }
                        row.lstChild.splice(i, 0, obj);
                    }
                    row.unitPrice = grpUnitPrice;
                    // row.grossAmount = Number(row.quantity) * Number(row.unitPrice);
                    row.grossAmount = isNaN(Number(row.unitPrice)) ? 0 : Number(row.quantity) * Number(row.unitPrice);
                    row.amount = (Number((row.grossAmount) * 100) / 100).toFixed(2); // - discount
                    //row.amount = Number(Math.round(Number(row.amount) * 100) / 100).toFixed(2);
                    if(this.isAddCnDnFromInvoiceBill){
                        this.addTable(1, parentIndex, 'c');
                    }
                }
            }
            if (callfrom == 'c') {
                this.setItemUnitPrice('item', parentIndex, childindex);
            }
            else if (callfrom == 'p') {
                this.setItemUnitPrice('item', parentIndex);
            }
        }
        else if (field == 'accountID') {
            if (callfrom == 'p') {
                let an = this.lstChartAcounts.filter(x => x.CAAccountID == row.accountID)[0];
                row.accountName = an == undefined ? '' : an.DisplayAccountName;
                if (this.taxTypeName != "No Tax") {
                    if (an !== undefined) {
                        let tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == an.TaxID)[0];
                        if (tn !== undefined) {
                            row.taxrateName = tn == undefined ? '' : tn.taxName;
                            row.taxRateID = an == undefined ? null : an.TaxID;
                        }
                    }
                }
                else {
                    let taxRate = this.lsttaxRate.filter(x => x.taxCode == 'NOTAX')[0];
                    if (row.isGroup == false) {
                        if (row.accountID!!) {
                            row.taxRateID = taxRate == undefined ? '' : taxRate.mstrTaxRateID;
                            row.taxrateName = taxRate == undefined ? '' : taxRate.taxName;
                        }
                    }
                }

                if (row.Deposit == '2') {
                    let isCalled = false;
                    this.rowData.forEach((r, index) => {
                        if (r.accountID != row.accountID && !isCalled) {
                            isCalled = true;
                            setTimeout(() => {
                                r.accountName = row.accountName;
                                r.accountID = row.accountID;
                                this.onCellValueChanged(r, 'accountID', index, -1, 'P');
                            }, 100);

                        }
                    })
                }
            }
            else if (callfrom == 'c') {
                let an = this.lstChartAcounts.filter(x => x.CAAccountID == row.lstChild[childindex].accountID)[0];
                row.lstChild[childindex].accountName = an == undefined ? '' : an.DisplayAccountName;

                if (this.taxTypeName != "No Tax") {
                    if (an !== undefined) {
                        row.lstChild[childindex].accountName = an == undefined ? '' : an.DisplayAccountName;
                        let tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == an.TaxID)[0];
                        if (tn !== undefined) {
                            row.lstChild[childindex].taxRateID = an == undefined ? null : an.TaxID;
                            row.lstChild[childindex].taxrateName = tn == undefined ? '' : tn.taxName;
                        }
                    }
                }
                else {
                    let taxRate = this.lsttaxRate.filter(x => x.taxCode == 'NOTAX')[0];
                    if (row.lstChild[childindex].accountID!!) {
                        row.lstChild[childindex].taxRateID = taxRate == undefined ? '' : taxRate.mstrTaxRateID;
                        row.lstChild[childindex].taxrateName = taxRate == undefined ? '' : taxRate.taxName;
                    }
                }
            }
        }
        else if (field == 'taxRateID') {
            let tn: any;
            if (callfrom == 'p') {
                tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == row.taxRateID)[0];
                row.taxrateName = tn == undefined ? '' : tn.taxName;

            }
            else if (callfrom == 'c') {
                tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == row.lstChild[childindex].taxRateID)[0];
                row.lstChild[childindex].taxrateName = tn == undefined ? '' : tn.taxName;
            }
            if (row.Deposit == '2') {
                let isCalled = false;
                this.rowData.forEach((r, index) => {
                    if (r.taxRateID != row.taxRateID && !isCalled) {
                        isCalled = true;
                        setTimeout(() => {
                            r.taxRateID = row.taxRateID;
                            r.taxrateName = row.taxrateName;
                            this.onCellValueChanged(r, 'taxRateID', index, -1, 'P');
                        }, 100);

                    }
                })
            }

        }
        else if(field=="IsQtyReturn"){
            if(row.IsQtyReturn==1 && row.isTrackedItem==1 && (this.transTypeID==4||this.transTypeID==8)){
                if(this.baseUnitPrice==0){
                    this.baseUnitPrice= row.unitPrice;
                }
                else{
                    row.unitPrice=this.baseUnitPrice;
                    row.amount=Number(row.quantity)*Number(row.unitPrice);
                    this.baseUnitPrice=0;
                }
            }
            else{
                if(this.baseUnitPrice==0){
                    this.baseUnitPrice= row.unitPrice;
                }
            }
        }
        if (callfrom == 'p' && row.isGroup == false && (field == 'itemID' || field == 'quantity' || field == 'unitPrice' || field == 'discount' || field == 'accountID' || field == 'taxRateID' || field == 'progressPercentage')) {

            let itemInfo = this.lstInventoryItem.filter(item => item.itemID == row.itemID);

            if (field === "quantity") {
                this.setItemUnitPrice('quantity', parentIndex);
            }

            if (itemInfo.length > 0 && itemInfo[0].avgCostTypeID == 2 && (field === 'quantity' || field === 'unitPrice')) {
                if (this.transTypeID == 8 || this.transTypeID == 4) {
                    let param = this.rowData.map(ele => ({
                        quantity: ele.quantity,
                        itemID: ele.itemID
                    }));
                    this.showFIFOCostPrice(param);
                }
            }

            if (field === "progressPercentage") {
                if (row.progressType == 'Quantity') {
                    if (this.isPercentageIncluded(row.progressPercentage)) {
                        let percent = Number(row.progressPercentage.replace('%', ''));
                        row.ProgressPercent = percent;
                        row.ProgressAmount = null;
                        row.quantity = (row.OrginalQuantity * percent) / 100;
                    } else {
                        row.ProgressPercent = null;
                        row.ProgressAmount = row.progressPercentage;
                        if (row.OrginalQuantity < Number(row.progressPercentage)) {
                            row.progressPercentage = row.OrginalQuantity;
                        }

                        row.quantity = row.OrginalQuantity > Number(row.progressPercentage) ? Number(row.progressPercentage) : row.OrginalQuantity;
                    }
                }
            }
            row.grossAmount = isNaN(Number(row.unitPrice)) ? 0 : Number(row.quantity) * Number(row.unitPrice);
            let discountAmt = 0;

            if ([1, 2, 5, 6, 21,29,30,31].some(a => a == this.transTypeID) &&
                ['itemID', 'quantity', 'unitPrice', 'discount', 'accountID', 'taxRateID', 'progressPercentage'].some(b => b == field)) { //Added for Discount flat amount feature review point
                let isPercentageIncluded = this.isPercentageIncluded(row.discount);
                let discountAmount: any = '';
                // if (row.isDeposit) {
                //     if(this.taxTypeName == 'Tax Exclusive'){
                //         this.currentInvoiceDeposit = Number(row.unitPrice) ;
                //         this.currentInvoiceDepositTax = (row.taxAmount);
                //     }else if(this.taxTypeName == 'Tax Inclusive'){
                //         this.currentInvoiceDeposit = Number(row.unitPrice) - (row.taxAmount);
                //         this.currentInvoiceDepositTax = (row.taxAmount);
                //     }else{
                //         this.currentInvoiceDeposit = Number(row.unitPrice);
                //         this.currentInvoiceDepositTax = 0;
                //     }
                //     this.emitOnProgressiveInvChanges.emit(this.currentInvoiceDeposit);
                // }
                if (isPercentageIncluded)
                    discountAmount = this.discountAmount(row.discount);
                else
                    discountAmount = Number(this.discountAmount(row.discount)) * (row.grossAmount < 0 ? -1 : 1);
                if (isPercentageIncluded) {
                    if (Number(discountAmount) > 0) {
                        discountAmt = (Number(row.grossAmount) / 100) * (discountAmount);
                        if (isNaN(discountAmt)) {
                            discountAmt = 0;
                        }
                    }
                    row.discountAmount = Number(discountAmt);
                    row.amount = ((Number(Number(row.grossAmount) - Number(discountAmt) * (row.grossAmount < 0 ? -1 : 1)) * 100) / 100).toFixed(2);
                } else {
                    discountAmount = Number(discountAmount);
                    row.discountAmount = discountAmount;
                    row.amount = Number(Number(row.grossAmount) - Number(discountAmount) * (row.grossAmount < 0 ? -1 : 1));
                }
                if (!row.isDeposit) {
                    if (row.progressType == 'UnitPrice') {
                        if (this.isPercentageIncluded(row.progressPercentage)) {
                            let percent = Number(row.progressPercentage.replace('%', ''));
                            if(percent > 100){
                                let msg = this.translate.instant("SharedModule.The Progress percentage must be maximum 100%.");
                                this.alertMessage.errorNotifier(msg,this.toastrID);
                                percent =100;
                                row.progressPercentage = '100%';
                            }
                            row.amount = ((row.amount * percent) / 100).toFixed(2);
                        } else {
                            if (Math.abs(Number(Number(row.amount).toFixed(2))) < Math.abs(Number(row.progressPercentage))) {
                                if (Number(row.progressPercentage) <= 100) {
                                    row.progressPercentage = Number(row.progressPercentage) + '%';
                                } else {
                                    let msg = this.translate.instant("SharedModule.The Progress amount must be maximum");
                                    this.alertMessage.errorNotifier(msg + this.currencyCode + ' ' + row.amount + '.', this.toastrID);
                                    row.progressPercentage = row.amount;
                                }
                            }
                            row.amount = Math.abs(row.amount) > Math.abs(Number(row.progressPercentage)) ? Number(row.progressPercentage) : row.amount.toFixed(2);
                        }
                    }
                }
            }
            else {
                row.discountAmount = 0;
                row.amount = Number(row.grossAmount);
            }




        }
        else if (callfrom == 'p' && row.isGroup == true && (field == 'itemID' || field == 'quantity' || field == 'unitPrice' || field == 'discount' || field == 'accountID' || field == 'taxRateID')) {// unitPrice disabled
            let sumofchildgrossAmount = 0;
            let childitems = JSON.parse(this.lstInventoryItem.filter(o => o.itemID == row.itemID)[0].groupItems);
            for (let i = 0; i < row.lstChild.length; i++) {
                if ((row.lstChild[i].description!!) || (row.lstChild[i].itemID!!)) {
                    if (field == 'quantity') {
                        if (row.lstChild[i].grpQuantity == undefined) {
                            let childitem = childitems.filter(o => o.itemID == row.lstChild[i].itemID);
                            if (childitem.length > 0) {
                                row.lstChild[i].grpQuantity = childitem[0].grpQuantity;
                            }
                            else {
                                row.lstChild[i].grpQuantity = 1;
                            }
                        }
                        row.lstChild[i].quantity = Number(row.lstChild[i].grpQuantity) * (isNaN(Number(row.quantity)) ? 1 : Number(row.quantity));
                    }
                }
            }
            if (field === "quantity") {
                this.setItemUnitPrice('quantity', parentIndex);
            }

            let parentgroupDiscountAmount = 0.00;
            for (let i = 0; i < row.lstChild.length; i++) {
                if ((row.lstChild[i].description!!) || (row.lstChild[i].itemID!!)) {
                    if ([1, 2, 5, 6,29,30,31].some(a => a == this.transTypeID) &&
                        ['itemID', 'quantity', 'unitPrice', 'discount', 'accountID', 'taxRateID'].some(b => b == field)) { //Added for Discount flat amount feature review point

                        row.lstChild[i].grossAmount = Number(row.lstChild[i].quantity) * Number(row.lstChild[i].unitPrice);
                        if (row.lstChild[i].isDeposit) {
                            // this.deposit.Subtotal = this.deposit.orgTotalAmount - row.lstChild[i].unitPrice;
                        }
                        let isPercentageIncluded = this.isPercentageIncluded(row.lstChild[i].discount);
                        let discountAmount: any = '';
                        if (isPercentageIncluded)
                            discountAmount = this.discountAmount(row.lstChild[i].discount);
                        else
                            discountAmount = Number(this.discountAmount(row.lstChild[i].discount)) * (row.lstChild[i].grossAmount < 0 ? -1 : 1);
                        if (isPercentageIncluded) {
                            row.lstChild[i].discount = discountAmount + '%';
                            let discountAmt = 0;
                            if (!!discountAmount) {
                                discountAmt = ((Number(row.lstChild[i].grossAmount) / 100) * (discountAmount));
                                if (isNaN(discountAmt)) {
                                    discountAmt = 0;
                                }
                            }
                            row.lstChild[i].discountAmount = Number(discountAmt);
                            row.lstChild[i].amount = (Number(row.lstChild[i].grossAmount) - Number(discountAmt) * (row.lstChild[i].grossAmount < 0 ? -1 : 1)).toFixed(2);
                            parentgroupDiscountAmount += Number(discountAmt);
                        } else {
                            row.lstChild[i].discount = discountAmount == '' ? '' : Number(discountAmount);
                            let discountAmt = 0;
                            row.lstChild[i].discountAmount = Number(discountAmount);
                            row.lstChild[i].amount = ((Number(Number(row.lstChild[i].grossAmount) - Number(discountAmount) * (row.lstChild[i].grossAmount < 0 ? -1 : 1)) * 100) / 100).toFixed(2);

                            if (discountAmount != '')
                                parentgroupDiscountAmount += Number(discountAmount);
                        }
                    }
                    else {
                        row.lstChild[i].grossAmount = Number(row.lstChild[i].quantity) * Number(row.lstChild[i].unitPrice);
                        row.lstChild[i].discountAmount = 0;
                        row.lstChild[i].amount = Number(row.lstChild[i].grossAmount);
                    }
                    sumofchildgrossAmount = sumofchildgrossAmount + Number(row.lstChild[i].grossAmount);
                }
            }

            //Parent row
            row.unitPrice = sumofchildgrossAmount / Number(row.quantity);
            if (isNaN(row.unitPrice) || row.unitPrice == Infinity) {
                row.unitPrice = 0;
            }
            row.grossAmount = isNaN(Number(row.unitPrice)) ? 0 : Number(row.quantity) * Number(row.unitPrice);
            row.discountAmount = Number(parentgroupDiscountAmount);
            row.amount = (Number(Number(row.grossAmount) - Number(parentgroupDiscountAmount))).toFixed(2);
            row.discount = Number(parentgroupDiscountAmount) == 0 ? '' : parentgroupDiscountAmount; //To show sum of all child discount amount in parent row.
        }
        else if (callfrom == 'c' && row.isGroup == true && (field == 'itemID' || field == 'quantity' || field == 'unitPrice' || field == 'discount' || field == 'accountID' || field == 'taxRateID')) { //discount disabled
            if (field === "quantity") {
                this.setItemUnitPrice('quantity', parentIndex, childindex);
            }
            row.lstChild[childindex].grossAmount = isNaN(Number(row.lstChild[childindex].unitPrice)) ? 0 : Number(row.lstChild[childindex].quantity) * Number(row.lstChild[childindex].unitPrice);
            let discountAmt = 0;

            let isPercentageIncluded = this.isPercentageIncluded(row.lstChild[childindex].discount);
            let discountAmount: any = '';
            if (isPercentageIncluded)
                discountAmount = this.discountAmount(row.lstChild[childindex].discount);
            else
                discountAmount = Number(this.discountAmount(row.lstChild[childindex].discount)) * (row.lstChild[childindex].grossAmount < 0 ? -1 : 1);

            if (isPercentageIncluded) {
                if (Number(discountAmount) > 0) {
                    discountAmt = (Number(row.lstChild[childindex].grossAmount) / 100) * (discountAmount);
                    if (isNaN(discountAmt)) {
                        discountAmt = 0;
                    }
                }
                row.lstChild[childindex].discountAmount = Number(discountAmt);
                row.lstChild[childindex].amount = (Number((Number(row.lstChild[childindex].grossAmount) - Number(discountAmt) * (row.lstChild[childindex].grossAmount < 0 ? -1 : 1)) * 100) / 100).toFixed(2);
            } else {
                if (Number(row.lstChild[childindex].discount) > 0) {
                    discountAmt = Number(row.lstChild[childindex].discount) * (row.lstChild[childindex].grossAmount < 0 ? -1 : 1);
                    if (isNaN(discountAmt)) {
                        discountAmt = 0;
                    }
                }
                row.lstChild[childindex].discountAmount = Number(discountAmt);
                row.lstChild[childindex].amount = (Number((Number(row.lstChild[childindex].grossAmount) - Number(discountAmt) * (row.lstChild[childindex].grossAmount < 0 ? -1 : 1)) * 100) / 100).toFixed(2);
            }

            let sumOfChildDiscountAmount: number = 0.00;
            let sumofchildgrossAmount = 0;
            for (let i = 0; i < row.lstChild.length; i++) {
                row.lstChild[i].grossAmount = Number(row.lstChild[i].quantity) * Number(row.lstChild[i].unitPrice);
                sumofchildgrossAmount = sumofchildgrossAmount + Number(row.lstChild[i].grossAmount);

                if (this.isPercentageIncluded(row.lstChild[i].discount)) {
                    let discountAmount = this.discountAmount(row.lstChild[i].discount);
                    if (Number(discountAmount) > 0 && Number(row.lstChild[i].grossAmount) !== 0) {
                        discountAmount = (Number(row.lstChild[i].grossAmount) / 100) * (discountAmount);
                        sumOfChildDiscountAmount += discountAmount;
                    }
                } else {
                    if (Number(row.lstChild[i].discount) > 0) {
                        let discountAmount = Number(row.lstChild[i].discount) * (row.lstChild[i].grossAmount < 0 ? -1 : 1);
                        if (isNaN(discountAmount)) {
                            discountAmount = 0;
                        }
                        if (Number(discountAmount) > 0 && Number(row.lstChild[i].grossAmount) !== 0) {
                            sumOfChildDiscountAmount += discountAmount;
                        }
                    }
                }
            }

            //Update parent row to = sum of all child discount amount
            row.discount = Number(sumOfChildDiscountAmount) !== 0 ? Math.abs(Number(Number(sumOfChildDiscountAmount).toFixed(2))) : '';
            row.discountAmount = Number(sumOfChildDiscountAmount);

            //Parent row
            row.unitPrice = sumofchildgrossAmount / Number(row.quantity);
            if (isNaN(row.unitPrice) || row.unitPrice == Infinity) {
                row.unitPrice = 0;
            }
            row.grossAmount = isNaN(Number(row.unitPrice)) ? 0 : Number(row.quantity) * Number(row.unitPrice);
            let grpdiscountAmt = 0;
            if (Number(row.discount) > 0) {
                grpdiscountAmt = (row.discountAmount); //Discount Flat amount implmentation.
                if (isNaN(grpdiscountAmt)) {
                    grpdiscountAmt = 0;
                }
            }
            row.amount = ((Number(Number(row.grossAmount) - Number(grpdiscountAmt)) * 100) / 100).toFixed(2);
        }

        if (field == 'QuantitytoReceive') {
            if (callfrom == 'p') {
                row.grossAmount = isNaN(Number(row.unitPrice)) ? 0 : Number(row.QuantitytoReceive) * Number(row.unitPrice);

                let discountAmount: any = '';
                if (Number(row.discount) > 0) {
                    discountAmount = (Number(row.grossAmount) / 100) * (row.discount);
                    if (isNaN(discountAmount)) {
                        discountAmount = 0;
                    }
                }
                else if (Number(row.discountAmount) > 0) {
                    discountAmount = row.discountAmount;
                }

                row.discountAmount = discountAmount;
                row.amount = ((Number(Number(Number(row.grossAmount) - Number(discountAmount) * (row.grossAmount < 0 ? -1 : 1))) * 100) / 100).toFixed(2);
            }
            else if (callfrom == 'c') {
                row.lstChild[childindex].grossAmount = isNaN(Number(row.lstChild[childindex].unitPrice)) ? 0 : Number(row.lstChild[childindex].QuantitytoReceive) * Number(row.lstChild[childindex].unitPrice);

                let discountAmount: any = '';
                if (Number(row.lstChild[childindex].discount) > 0) {
                    discountAmount = (Number(row.lstChild[childindex].grossAmount) / 100) * (row.lstChild[childindex].discount);
                    if (isNaN(discountAmount)) {
                        discountAmount = 0;
                    }
                }
                else if (Number(row.lstChild[childindex].discountAmount) > 0) {
                    discountAmount = row.lstChild[childindex].discountAmount;
                }

                row.lstChild[childindex].discountAmount = discountAmount;
                row.lstChild[childindex].amount = ((Number(Number(Number(row.lstChild[childindex].grossAmount) - Number(discountAmount) * (row.lstChild[childindex].grossAmount < 0 ? -1 : 1))) * 100) / 100).toFixed(2);

                row.unitPrice = (row.lstChild.reduce((n, {unitPrice, QuantitytoReceive}) => Number(n) + Number(unitPrice * QuantitytoReceive), 0))
                row.QuantitytoReceive = 1;
                row.discountAmount = (row.lstChild.reduce((n, {discountAmount}) => Number(n) + Number(discountAmount), 0));
                row.amount = (((row.lstChild.reduce((n, {amount}) => Number(n) + Number(amount), 0)) * 100) / 100).toFixed(2);
            }
        }


        if (callfrom == 'c' && this.transTypeID != 20 && this.isAddCnDnFromInvoiceBill) {
            if (row.lstChild.length - 1 == childindex)
                this.addTable(1, parentIndex, 'c');
        }


        if (field != 'description') {
            this.taxcal.onRowTaxCalculation(this.taxTypeName, row, parentIndex, childindex, field);
            this.onTotalCalculation(callfrom);

            if (row.isDeposit) {
                if (isNaN(row.unitPrice)) {
                    this.currentInvoiceDeposit = 0;
                    this.currentInvoiceDepositTax = 0;
                }
                else {
                    if (this.taxTypeName == 'Tax Exclusive') {
                        this.currentInvoiceDeposit = Number(row.unitPrice);
                        this.currentInvoiceDepositTax = (row.taxAmount);
                    } else if (this.taxTypeName == 'Tax Inclusive') {
                        this.currentInvoiceDeposit = Number(row.unitPrice) - (row.taxAmount);
                        this.currentInvoiceDepositTax = (row.taxAmount);
                    } else {
                        this.currentInvoiceDeposit = Number(row.unitPrice);
                        this.currentInvoiceDepositTax = 0;
                    }
                }
                this.emitOnProgressiveInvChanges.emit(this.currentInvoiceDeposit);
            }


            if (this.taxTypeName == 'Tax Exclusive') {
                this.HideAdjustmentTax(row, field);
            }
        }
        else if (field == 'description') {
            //this.ReAuthentication();
        }
        // this.OnGridChanges.emit();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.rowData, event.previousIndex, event.currentIndex);
    }

    onCurrencyCodeChange() {
        if (this.columns.length === 0) {
            return;
        }
        let amountInx = this.columns.filter(x => x.field == 'amount' || x.field == 'exclusiveAmount') as any[];
        amountInx.forEach(ele => {
            if (this.typeName != "") {
                ele.title = this.translate.instant('Common.Amount') + ' ' + this.currencyCode;
            }
            else {
                ele.title = this.translate.instant('Common.Amount') + ' ' + (this.orgCurrencyCode === this.currencyCode ? '' : this.currencyCode);
            }
        });
    }

    //Grid disable
    disableTransGrid() {
        if (this.columns.length > 0) {
            this.columns.forEach((col: any, colIndex) => {
                this.columns[colIndex].isEditable = (this.columns[colIndex].isCustom);
                if (this.transTypeID == 6 && this.columns[colIndex].field == "description" && [205,1111,200100012,105].includes(this.currentUser.customerCode)){
                    this.columns[colIndex].isEditable = true;
                }
                if (this.columns[colIndex].field == "delete") this.columns[colIndex].isVisible = false;
                this.isOnlyGridCustomCellEdit = true;
            });
        }
    }

    onChangeAmountsAre() {

        if (this.taxTypeName == 'Tax Exclusive') {
            this.HideAdjustmentTax('', '');
        }
        else {
            this.taxAdjustment = null;
        }
        //this.taxAdjustment = null;
        let ColTaxID = this.columns.filter(o => o.field == "taxRateID");
        let ColAccountID = this.columns.filter(o => o.field == "accountID");
        if (ColTaxID.length > 0)
            ColTaxID[0].isEditable = !(this._typeName == 'Overpayment');
        if (ColAccountID.length > 0)
            ColAccountID[0].isEditable = !(this._typeName == 'Overpayment');

        if (ColTaxID.length > 0) {
            if (!(this._typeName == 'Overpayment')) {
                if (this._taxTypeName == 'No Tax') {
                    ColTaxID[0].isEditable = false;
                }
                else if(this.typeName !='PInvoice'){
                    ColTaxID[0].isEditable = true;
                }
            }
        }


        if (this.transTypeID != 21) {
            let taxAmountIndex = this.columns.findIndex(x => x.field == 'taxAmount');
            if (taxAmountIndex >= 0) {
                if (this.taxTypeName == 'No Tax' || this._typeName == 'Overpayment') {
                    let taxRate = this.lsttaxRate.filter(x => x.taxCode == 'NOTAX')[0];
                    this.rowData.forEach((row: any, index) => {
                        if (this.rowData[index].isGroup == false) {
                            if (this.rowData[index].accountID!!) {
                                this.rowData[index].taxRateID = taxRate == undefined ? '' : taxRate.mstrTaxRateID;
                                this.rowData[index].taxrateName = taxRate == undefined ? '' : taxRate.taxName;
                                this.rowData[index].taxAmount = 0;
                            }
                        }
                        else if (this.rowData[index].isGroup == true) {
                            this.rowData[index].lstChild.forEach((childrow: any, childindex) => {
                                if (this.rowData[index].lstChild[childindex].accountID!!) {
                                    this.rowData[index].lstChild[childindex].taxRateID = taxRate == undefined ? '' : taxRate.mstrTaxRateID;
                                    this.rowData[index].lstChild[childindex].taxrateName = taxRate == undefined ? '' : taxRate.taxName;
                                    this.rowData[index].lstChild[childindex].taxAmount = 0;
                                }
                            });
                        }
                    });
                }
                else {
                    if (this._OldTaxTypeName == "No Tax") {
                        this.rowData.forEach((row: any, index) => {
                            if (this.rowData[index].isGroup == false) {
                                if (this.rowData[index].accountID!!) {
                                    let itemValue = this.lstInventoryItem.filter(item => item.itemID == row.itemID);

                                    if (itemValue.length > 0) {
                                        if (this.contact.contactID > 0) {
                                            if (row.IsExisting == false) {
                                                row.taxRateID = ([1, 2, 4, 10, 20].some(o => o == this.transTypeID)) ?
                                                    (this.contact.purchaseTaxID > 0 ? this.contact.purchaseTaxID : itemValue[0].purchaseTaxID) :
                                                    (this.contact.salesTaxID > 0 ? this.contact.salesTaxID : itemValue[0].salesTaxID);
                                            }
                                        }
                                        else {
                                            if (row.IsExisting == false) {
                                                row.taxRateID = ([1, 2, 4, 10, 20].some(o => o == this.transTypeID)) ?
                                                    itemValue[0].purchaseTaxID :
                                                    itemValue[0].salesTaxID;
                                            }
                                        }
                                    }
                                    if (row.IsExisting == false) {
                                        let tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == row.taxRateID)[0];
                                        row.taxrateName = tn == undefined ? '' : tn.taxName;
                                    }
                                }
                            }
                            else if (this.rowData[index].isGroup == true) {
                                this.rowData[index].lstChild.forEach((childrow: any, childindex) => {
                                    if (this.rowData[index].lstChild[childindex].accountID!!) {
                                        let itemValue = this.lstInventoryItem.filter(item => item.itemID == childrow.itemID);

                                        if (itemValue.length > 0) {
                                            if (this.contact.contactID > 0) {
                                                if (childrow.IsExisting == false) {
                                                    childrow.taxRateID = ([1, 2, 4, 10, 20].some(o => o == this.transTypeID)) ?
                                                        (this.contact.purchaseTaxID > 0 ? this.contact.purchaseTaxID : itemValue[0].purchaseTaxID) :
                                                        (this.contact.salesTaxID > 0 ? this.contact.salesTaxID : itemValue[0].salesTaxID);
                                                }
                                            }
                                            else {
                                                if (childrow.IsExisting == false) {
                                                    childrow.taxRateID = ([1, 2, 4, 10, 20].some(o => o == this.transTypeID)) ?
                                                        itemValue[0].purchaseTaxID :
                                                        itemValue[0].salesTaxID;
                                                }
                                            }
                                        }
                                        if (childrow.IsExisting == false) {
                                            let tn = this.lsttaxRate.filter(x => x.mstrTaxRateID == childrow.taxRateID)[0];
                                            childrow.taxrateName = tn == undefined ? '' : tn.taxName;
                                        }
                                    }
                                });
                            }
                        });
                    }
                }

                if (this.taxTypeName == 'No Tax') {
                    this.columns[taxAmountIndex].isVisible = false;
                }
                if (this.taxTypeName == 'Tax Exclusive') {
                    if (this.typeName == "Overpayment")
                        //Tax column hide for Spend & Receive OP transactions
                        this.columns[taxAmountIndex].isVisible = false;
                    else
                        this.columns[taxAmountIndex].isVisible = true;
                    //Tax column hide for Spend & Receive OP transactions
                }
                if (this.taxTypeName == 'Tax Inclusive' && this.PageMode == 'view') {
                    this.columns[taxAmountIndex].isVisible = true;
                }
                if (this.taxTypeName == 'Tax Inclusive' && this.PageMode != 'view') {
                    this.columns[taxAmountIndex].isVisible = false;
                }
            }
            this.onTaxCalculation();
        }
    }

    pretaxAdjustment = null;
    onTaxCalculation(callFrom?: string) {
        if (callFrom == 'Adjustment' && Number(this.pretaxAdjustment) != Number(this.taxAdjustment)) {
            this.taxcal.onGridCalculation(this.taxTypeName, this.rowData, callFrom, this.taxAdjustment);
            this.onTotalCalculation();
        }
        else if (callFrom != 'Adjustment') {
            this.taxcal.onGridCalculation(this.taxTypeName, this.rowData, callFrom, this.taxAdjustment);
            this.onTotalCalculation();
        }
    }

    onTotalCalculation(callfrom?: string, IsNewViewScreen = false) {
        if (this.transTypeID == 21) {
            // only for Delivery Order
            let gItem: number = 0;
            let dItem: number = 0;

            if (this.rowData.filter(m => m.isGroup == true).length > 0) {
                this.rowData.filter(m => m.isGroup == true).forEach(element => {
                    gItem = gItem + Number(element.lstChild.map(e => e.quantity).reduce((a, b) => Number(a) + Number(b), 0));
                });
            }

            dItem = Number(this.rowData.filter(m => m.isGroup == false).map(e => e.quantity).reduce((a, b) => Number(a) + Number(b), 0));
            this.totalQuantity = gItem + dItem;
            return;
        }
        let taxAmountIndex = this.columns.findIndex(x => x.field == 'taxAmount');
        if (taxAmountIndex >= 0) {
            if (this.taxTypeName == 'No Tax') {
                this.columns[taxAmountIndex].isVisible = false;
            }
            else if (this.taxTypeName == 'Tax Exclusive') {
                if (this.typeName == "Overpayment")
                    //Tax column hide for Spend & Receive OP transactions
                    this.columns[taxAmountIndex].isVisible = false;
                else
                    this.columns[taxAmountIndex].isVisible = true;
                //Tax column hide for Spend & Receive OP transactions
            }
            else if (this.taxTypeName == 'Tax Inclusive' && this.PageMode == 'view') {
                this.columns[taxAmountIndex].isVisible = true;
            }
            //else if (IsNewViewScreen && this.taxTypeName == 'Tax Inclusive' && this.PageMode == 'view') {
            //    this.columns[taxAmountIndex].isVisible = false;
            //}
            else if (this.taxTypeName == 'Tax Inclusive' && this.PageMode != 'view') {
                this.columns[taxAmountIndex].isVisible = false;
            }
        }
        if (this.taxTypeName == 'Tax Exclusive') {
            this.HideAdjustmentTax('', '');
        }
        let rtnObj = this.taxcal.onTotalCalculation(this.taxTypeName, this.rowData, this.PageMode, IsNewViewScreen);
        this.subTotal = rtnObj.subTotal;
        this.totalTax = rtnObj.totalTax;
        this.taxAdjustment = Math.round((Number(rtnObj.adjustmentAmount)) * 100) / 100;
        this.pretaxAdjustment = this.taxAdjustment;
        //this.taxAmountsAreList = rtnObj.taxAmountsAreList;
        this.LinetaxtotalAmountsAreList = rtnObj.LinetaxtotalAmountsAreList;
        this.total = rtnObj.total;
        this.discount = this.rowData.reduce((sum, cur) => cur.discountAmount + sum, 0);

        if (this.PageMode == '' && this.CreditData.length > 0) {
            this.CreditData[0].PaidAmount = this.total >= this.PaymentData.AmountDue ? this.PaymentData.AmountDue : this.total;
            this.AmountDue = (this.total - this.PaymentData.AmountDue) < 0 ? 0 : (this.total - this.PaymentData.AmountDue);
            this.TotalCreditAmount = this.AmountDue;
        }
        if (this.typeName == 'PInvoice' && callfrom == 'progressPercentage' && this.rowData.length > 0 && this.rowData[0].ProgressType == 'UnitPrice') {
            let progressPercentageList = this.rowData.filter(r => !!r.progressPercentage).map(res => res.progressPercentage);
            let progressPercentageListDuplicateRemoved = progressPercentageList.filter((el, i, a) => i === a.indexOf(el));
            if (progressPercentageListDuplicateRemoved.length == 1 && progressPercentageListDuplicateRemoved[0].includes('%')) {
                let percent = progressPercentageListDuplicateRemoved[0].replace('%', '');
                let total = ((this.quoteTotal * percent / 100) * 100) / 100
                if (this.total != total) {
                    if (Math.abs(this.total - total) < 0.05) {
                        console.log('Value dont match');
                        if (this.taxTypeName == 'Tax Exclusive') {
                            this.taxAdjustment = total - this.total;
                            this.onTaxCalculation();
                        } else {
                            let denominator = 0.01;
                            if (this.total < total) {
                                let differenceAmount = Math.round((total - this.total) * 100) / 100;// Rounding upto .2 digit
                                if (differenceAmount > 0) {
                                    let df = differenceAmount / denominator;
                                    for (let index = 0; index < df; index++) {
                                        // this.ctbl.rowData.findIndex(ele=>ele == index);
                                        this.rowData[index].amount = Number(this.rowData[index].amount) + denominator;
                                    }
                                    this.onTotalCalculation();
                                }
                            }
                            else if (this.total > total) {
                                let differenceAmount = Math.round((total - this.total) * 100) / 100;// Rounding upto .2 digit
                                if (differenceAmount < 0) {
                                    let df = Math.abs(differenceAmount / denominator);
                                    for (let index = 0; index < df; index++) {
                                        this.rowData[index].amount = Number(this.rowData[index].amount) - denominator;
                                    }
                                    this.onTotalCalculation();
                                }
                            }
                        }
                    }
                }
            }
        }


    }


    braceFormatter(params, def = '') {
        let num;
        if (params > 0)
            num = Number(Math.round(params * 100) / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        else if (Number(params) == 0) {
            num = def;
        }
        else {
            if (!isNaN(Number(params)))
                num = "(" + Number(Math.round(params * 100) / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
            else if (params == '-')
                num = '-';
        }
        return num;
    }

    onTabIndex(parentIndex, childindex, field) {
        this.inlineIdx = -1;
        if (this.isGridEditable == false) {
            return;
        }

        let fIndex = this.columns.findIndex(x => x.field == field);
        let fstEditField: string = "";

        let lstEditFields = this.columns.filter(e => e.isEditable == true);
        if (lstEditFields.length > 0) {
            fstEditField = lstEditFields[0].field.toString();
        }

        if (childindex == -1 && this.rowData[parentIndex].isGroup == true && field == 'quantity') {
            fIndex = fIndex + 1;
        }
        else if (childindex == -1 && this.rowData[parentIndex].isGroup == true && field == 'discount') {
            this.inlineIdx = this.columns[1].field + (parentIndex).toString() + "0";
            return;
        }
        else if (childindex >= 0 && this.rowData[parentIndex].isGroup == true && field == 'unitPrice') {
            fIndex = fIndex + 1;
        }

        for (let i = 0; i < this.columns.length; i++) {
            if (i > fIndex) {
                let nextCell = this.columns[i];
                if (nextCell!!) {
                    if (nextCell.isEditable == true && nextCell.isVisible == true) {
                        let editcell = nextCell.field + parentIndex.toString() + childindex.toString();
                        if (nextCell.field == 'accountID') {
                            if (childindex == -1 && this.rowData[parentIndex].isGroup == false) {
                                this.inlineIdx = editcell;
                                break;
                            }
                            else if (childindex >= 0) {
                                this.inlineIdx = editcell;
                                break;
                            }
                        }
                        else if (nextCell.field == 'taxRateID') {
                            if (childindex == -1 && this.rowData[parentIndex].isGroup == false) {
                                this.inlineIdx = editcell;
                                break;
                            }
                            else if (childindex >= 0) {
                                this.inlineIdx = editcell;
                                break;
                            }
                        }
                        else {
                            this.inlineIdx = editcell;
                            break;
                        }
                    }
                    else if (this.columns.length - 1 == i && fstEditField != "") {
                        if (childindex == -1 && this.rowData[parentIndex].isGroup == false) {
                            if (this.rowData.length != (parentIndex + 1)) {
                                this.inlineIdx = fstEditField + (parentIndex + 1).toString() + childindex.toString();
                                break;
                            }
                        }
                        else if (childindex == -1 && this.rowData[parentIndex].isGroup == true) {
                            this.inlineIdx = fstEditField + (parentIndex).toString() + "0";
                            break;
                        }
                        else {
                            if (this.rowData[parentIndex].lstChild.length == (childindex + 1)) {
                                this.inlineIdx = fstEditField + (parentIndex + 1).toString() + "-1";
                                break;
                            }
                            else {
                                this.inlineIdx = fstEditField + parentIndex.toString() + (childindex + 1).toString();
                                break;
                            }
                        }
                    }
                }
            }
        }
        
        if(field == 'unitPrice' || field == 'quantity' || field == 'QuantitytoReceive'){
            this.calculate(parentIndex,childindex,field);
        }
        
    }

    calculate(parentIndex, childindex, field){
        let cell = childindex >= 0 ? 'c' : 'p';
        let data = this.rowData[parentIndex];
        let cellData;
        let max = 999999999;

        if(field == 'unitPrice'){
            try{
                if(this.rowData[parentIndex].unitPrice === ""){
                    return;
                }
                cellData = this.rowData[parentIndex].unitPrice.toString();
                if(this.rowData[parentIndex].itemTypeID === 3){
                    if(childindex >= 0){
                        if(this.rowData[parentIndex].lstChild[childindex].unitPrice === ""){
                            return;
                        }
                        cellData = this.rowData[parentIndex].lstChild[childindex].unitPrice.toString();
                        this.rowData[parentIndex].lstChild[childindex].unitPrice = Number(evaluate(cellData) > max ? 0 : evaluate(cellData)).round(4);                        
                    }else{
                        this.rowData[parentIndex].unitPrice = Number(evaluate(cellData) > max ? 0 : evaluate(cellData)).round(4);                        
                    }
                }else{
                    this.rowData[parentIndex].unitPrice = Number(evaluate(cellData) > max ? 0 : evaluate(cellData)).round(4);                    
                }
            }catch(error){
                if (this.rowData[parentIndex].itemTypeID ===3) {
                    if(childindex >= 0){
                        this.rowData[parentIndex].lstChild[childindex].unitPrice = 0;
                    }else{
                        this.rowData[parentIndex].unitPrice = 0;
                    }                    
                }else{
                    this.rowData[parentIndex].unitPrice = 0;
                }
            }
            this.onCellValueChanged(data,field,parentIndex,childindex,cell);
        }else if (field == 'quantity' || field == 'QuantitytoReceive') {
            try{
                if(this.rowData[parentIndex][field] === ""){
                    return;
                }
                cellData = this.rowData[parentIndex][field].toString();
                if (this.rowData[parentIndex].itemTypeID === 3) {
                    if(childindex >= 0){
                        if(this.rowData[parentIndex].lstChild[childindex][field] === ""){
                            return;
                        }
                        cellData = this.rowData[parentIndex].lstChild[childindex][field].toString();
                        this.rowData[parentIndex].lstChild[childindex][field] = evaluate(cellData) < 0 ? 1 : Number(evaluate(cellData) > max ? 1 : evaluate(cellData)).round(10);                        
                    }else {
                        this.rowData[parentIndex][field] = evaluate(cellData) < 0 ? 1 : Number(evaluate(cellData) > max ? 1 : evaluate(cellData)).round(10);                        
                    }
                }else{
                    this.rowData[parentIndex][field] = evaluate(cellData) < 0 ? 1 : Number(evaluate(cellData) > max ? 1 : evaluate(cellData)).round(10);                    
                }
            }catch(error){
                if (this.rowData[parentIndex].itemTypeID === 3) {
                    if(childindex >= 0){
                        this.rowData[parentIndex].lstChild[childindex][field] = 1;
                    }else{
                        this.rowData[parentIndex][field] = 1;
                    }
                }else{
                    this.rowData[parentIndex][field] = 1;
                }
            }
            this.onCellValueChanged(data,field,parentIndex,childindex,cell);
        }       
    }

    onGridValidationNew(statusTypeID: any) {
        this.validRowData = [];
        let errorList: any = [];
        let i: any = 0;
        this.rowData.forEach((row: any) => {
            if (row.isGroup == true) {
                i++;
                var ParentValid = this.ParentRowValidation(statusTypeID, row, errorList, i);
                if (ParentValid) {
                    this.validRowData.push(row);
                }
                row.lstChild.forEach((childrow: any) => {
                    i++;
                    var Valid = this.RowValidation(statusTypeID, childrow, errorList, i);
                    if (Valid) {
                        this.validRowData.push(childrow);
                    }
                });
            }
            else {
                i++;
                var Valid = this.RowValidation(statusTypeID, row, errorList, i);
                if (Valid) {
                    this.validRowData.push(row);
                }
            }
        });

        if (statusTypeID != 1) {
            if (this.rowData.filter(row =>
                (row.description != '') ||
                (row.itemName != '' || row.itemID != 0) ||
                (row.quantity != '') ||
                (row.unitPrice) != '' ||
                (row.accountID != '' && row.accountID != null) ||
                (row.taxRateID != '' && row.taxRateID != null)).length == 0) {
                errorList = [];
                errorList.push({ message: "Cannot be empty all rows. Atleast enter one item." });
            }
        }

        if (Number(this.total) < 0 && statusTypeID !== 1) {
            errorList.push({ message: "Total amount should be greater than or equal to zero." });
        }
        else if (Number(this.total) > 922337203685477) {
            errorList.push({ message: "Total amount should be less than 922,337,203,685,477" });
        }
        return errorList;
    }
    RowValidation(statusTypeID, row, errorList, i): Boolean {
        let rowerrorList: any[] = [];

        if ((this.statusTypeID == 0 || this.statusTypeID == 1 || this.statusTypeID == 2 || this.statusTypeID == undefined)
            && statusTypeID == 3 && row.itemID && row.itemID > 0
            && this.lstInventoryItem.find(item => item.itemID == row.itemID) === undefined) {
            errorList.push({ message: `Row #${i}: Item is inactive` });
            return false;
        }
        if ((this.statusTypeID == 0 || this.statusTypeID == 1 || this.statusTypeID == 2 || this.statusTypeID == undefined)
            && statusTypeID == 3 && row.taxRateID && this.transTypeID != 21
            && this.lsttaxRate.find(tax => tax.mstrTaxRateID == row.taxRateID) === undefined) {
            errorList.push({ message: `Row #${i}: Taxrate is inactive` });
            return false;
        }

        if (
            (row.description != '') ||
            (row.itemName != '' || row.itemID != 0) ||
            (row.quantity != '') ||
            (row.unitPrice != '') ||
            (row.accountID != '' && row.accountID != null) ||
            (row.taxRateID != '' && row.taxRateID != null)
        ) {
            if (row.description === "" && statusTypeID != 1) {
                rowerrorList.push({ message: "Row #" + i + ": Description cannot be empty." });
            }
            if ((
                (row.description != '') ||
                (row.quantity.toString() != '') ||
                (row.unitPrice) != '' ||
                (row.accountID != '' && row.accountID != null) ||
                (row.taxRateID != '' && row.taxRateID != null)) &&
                (statusTypeID != 1)) {
                if (row.quantity === "" || row.quantity == null || row.quantity == undefined) {
                    rowerrorList.push({ message: "Row #" + i + ": Quantity cannot be empty." });
                }
                if (row.quantity.toString() == '0') {
                    rowerrorList.push({ message: "Row #" + i + ": Quantity should be greater than 0." });
                }

                if ((row.unitPrice === "" || row.unitPrice == undefined || row.unitPrice == null) && this.transTypeID != 21 ) {
                    rowerrorList.push({ message: "Row #" + i + ": Unit Price cannot be empty." });
                }

                let o = Number(row.orginalTaxAmount) < 0 ? -1 * Number(row.orginalTaxAmount) : Number(row.orginalTaxAmount);
                if (o > 0) {
                    if ((Number(row.taxAmount) <= 0 || Number(row.taxAmount) > Number(row.amount)) && Number(row.amount) > 0) {
                        rowerrorList.push({ message: "Row #" + i + ": Tax amount should be greater than 0 or less than or equal to line amount." });
                    }
                    else if (Number(row.amount) < 0) {
                        let a = -1 * Number(row.amount);
                        let t = -1 * Number(row.taxAmount);
                        if ((Number(t) < 0 || Number(t) > Number(a)) && Number(a) > 0) {
                            rowerrorList.push({ message: "Row #" + i + ": Tax amount should be less than  or equal to 0 or less than or equal to line amount." });
                        }
                    }
                }

                if (((Number(row.unitPrice) !== 0) ||
                    (row.accountID != '' && row.accountID != null) ||
                    (row.taxRateID != '' && row.taxRateID != null)) && this.transTypeID != 21) {

                    if (!(((row.itemTypeID == 2 || row.itemTypeID == 0) && this.transTypeID == 1) || (this.transTypeID == 5))) {
                        if (row.accountID === 0 || row.accountID === "" || row.accountID === undefined || row.accountID === null) {
                            rowerrorList.push({ message: "Row #" + i + ": Account cannot be empty." });
                        }
                        if (row.taxRateID === 0 || row.taxRateID === "" || row.taxRateID === undefined || row.taxRateID === null) {
                            rowerrorList.push({ message: "Row #" + i + ": Tax Rate cannot be empty." });
                        }

                    }
                }
            }
            // if (Number(row.taxAmount) !== 0 && Number(row.taxAmount) > Number(row.amount)) {
            //   rowerrorList.push({ message: "Row #" + i + ": Tax amount cannot be greater than the line amount." });
            // }

            if (row.discount?.toString()?.includes('%')) {
                let discount = row.discount?.toString()?.replace('%', '');
                if (Number(discount) < 0) {
                    errorList.push({ message: "Row #" + i + ": Discount percetage should be greater than zero." });
                }
                if (Number(discount) > 100) {
                    errorList.push({ message: "Row #" + i + ": Discount percetage should be less than 100." });
                }
                let grossAmount = row.unitPrice * row.quantity;
                if (Number(grossAmount) == 0) {
                    if (Number(discount) > Math.abs(Number(grossAmount))) {
                        errorList.push({ message: "Row #" + i + ": Discount should be Less than or Equal to Amount." });
                    }
                }
            } if (row.discount?.toString()?.includes('%') == false) {
                if (Number(row.discount) > 0) {
                    let discount = row.discount;
                    let grossAmount = row.unitPrice * row.quantity;
                    if (Number(grossAmount) >= 0) {
                        if (Number(discount) > Math.abs(Number(grossAmount))) {
                            errorList.push({ message: "Row #" + i + ": Discount should be Less than or Equal to Amount." });
                        }
                    }
                }
            }
            if (statusTypeID != 1) {
                let customField = this.columns.filter(x => x.isCustom == true && x.isMandatory == true);
                if (customField?.length > 0) {
                    customField.forEach(element => {
                        if (row[element.field] == undefined) {
                            rowerrorList.push({ message: "Row #" + i + ": " + element.title + " cannot be empty." });
                        }
                    });
                }
            }
            if (this.transTypeID == 8) {
                if (row.itemAvgCost != "" && row.itemAvgCost != undefined && row.itemAvgCost != null && Number(row.itemAvgCost) < 0) {
                    rowerrorList.push({ message: "Row #" + i + ": Unit Cost Price cannot be negative." });
                }
            }
        }
        else {
            return false;
        }

        if (statusTypeID === 1) {
            // if (Number(row.taxAmount) !== 0 && Number(row.taxAmount) > Number(row.amount)) {
            //   errorList.push({ message: "Row #" + i + ": Tax amount cannot be greater than the line amount." });
            // }
            //else {
            row.index = i;
            //}
        }
        else {
            const whenInvoiceNotCopiedFromDO = (this.transTypeID == 6 && this.copyTransTypeID != 21) || (this.transTypeID == 21 && this.copyTransTypeID != 6) || (this.transTypeID != 6 && this.transTypeID != 21);      
            if (
                (whenInvoiceNotCopiedFromDO) &&
                    (this.transTypeID == 4 || this.transTypeID == 6 || this.transTypeID == 11 || this.transTypeID == 30) &&
                (Number(row.quantity)) > 0 &&
                (row.isTrackedItem == true)) {
                let availableQty = this.lstInventoryItem.filter(item => item.itemID === row.itemID)[0].availableQuantity;
                let enteredQty = this.getQuantity(row.itemID);
                if (availableQty < enteredQty) {
                    rowerrorList.push({ message: "" + row.itemName + " is available only " + availableQty + " quantity to sell." })
                }
            }
            //Unit Price Validation for Tracked Item
            if ((this.transTypeID == 2 || this.transTypeID == 4 || this.transTypeID == 6 || this.transTypeID == 8 || this.transTypeID == 10 || this.transTypeID == 11 || this.transTypeID == 30 || this.transTypeID==31) && (row.isTrackedItem == true)) {
                if (row.unitPrice < 0) {
                    rowerrorList.push({ message: "Row #" + i + ": Unit price should not be less than zero for tracked items." });
                }
            }
            if (rowerrorList.length > 0) {
                rowerrorList.forEach((err: any, c) => {
                    errorList.push(err);
                });
            }
            else {
                row.index = i;
            }
        }
        return rowerrorList.length == 0;
    }

    ParentRowValidation(statusTypeID, row, errorList, i) {
        let rowerrorList: any[] = [];

        if ((this.statusTypeID == 0 || this.statusTypeID == 1 || this.statusTypeID == 2 || this.statusTypeID == undefined)
            && statusTypeID == 3 && row.itemID && row.itemID > 0
            && this.lstInventoryItem.find(item => item.itemID == row.itemID) === undefined) {
            errorList.push({ message: `Row #${i}: Product group is inactive` });
            return false;
        }

        if (
            (row.description != '') ||
            (row.itemName != '' || row.itemID != 0) ||
            (row.quantity != '') ||
            (row.unitPrice) != '' ||
            (row.accountID != '' && row.accountID != null) ||
            (row.taxRateID != '' && row.taxRateID != null)
        ) {
            if (row.description === "" && statusTypeID != 1) {
                rowerrorList.push({ message: "Row #" + i + ": Description cannot be empty." });
            }
            if ((
                (row.description != '') ||
                (row.quantity.toString() != '') ||
                (row.unitPrice) != '' ||
                (row.accountID != '' && row.accountID != null) ||
                (row.taxRateID != '' && row.taxRateID != null)) &&
                (statusTypeID != 1)) {
                if (row.quantity === "" || row.quantity == null || row.quantity == undefined) {
                    rowerrorList.push({ message: "Row #" + i + ": Quantity cannot be empty." });
                }
                if (row.quantity.toString() == '0') {
                    rowerrorList.push({ message: "Row #" + i + ": Quantity should be greater than 0." });
                }
            }
            // if (Number(row.amount) > 0) {
            // if (Number(row.taxAmount) < 0) {
            //   rowerrorList.push({ message: "Row #" + i + ": Tax amount must be between 0 and the line amount." });
            // }
            // }
            if (Number(row.taxAmount) !== 0 && Number(row.taxAmount) > Number(row.amount)) {
                rowerrorList.push({ message: "Row #" + i + ": Tax amount cannot be greater than the line amount." });
            }
        }
        else {
            return false;
        }

        if (statusTypeID === 1) {
            row.index = i;
        }
        else {
            if (rowerrorList.length > 0) {
                rowerrorList.forEach((err: any, c) => {
                    errorList.push(err);
                });
            }
            else {
                row.index = i;
            }
        }

        var child = row.lstChild.filter(ch => ch.index >= 0 && ((ch.description) || (ch.itemName) || (ch.itemID)));
        if (child.length === 0) {
            errorList.push({ message: "Row #" + i + ": should be add atleast one child item." });
        }
        return rowerrorList.length == 0;
    }

    onGridValidationNew1(statusTypeID: any, errorList: any[], backorderList: any[]) {
        this.validRowData = [];
        //let errorList: any = [];
        let i: any = 0;
        this.rowData.forEach((row: any) => {
            if (row.isGroup == true) {
                i++;
                var ParentValid = this.ParentRowValidation1(statusTypeID, row, errorList, i);
                if (ParentValid) {
                    this.validRowData.push(row);
                }
                row.lstChild.forEach((childrow: any) => {
                    i++;
                    var Valid = this.RowValidation1(statusTypeID, childrow, errorList, backorderList, i);
                    if (Valid) {
                        this.validRowData.push(childrow);
                    }
                });
            }
            else {
                i++;
                var Valid = this.RowValidation1(statusTypeID, row, errorList, backorderList, i);
                if (Valid) {
                    this.validRowData.push(row);
                }
            }
        });

        if (statusTypeID != 1) {
            if (this.rowData.filter(row =>
                (row.description != '') ||
                (row.itemName != '' || row.itemID != 0) ||
                (row.quantity != '') ||
                (row.unitPrice) != '' ||
                (row.accountID != '' && row.accountID != null) ||
                (row.taxRateID != '' && row.taxRateID != null)).length == 0) {
                errorList = [];
                errorList.push({ message: "Cannot be empty all rows. Atleast enter one item." });
            }
        }

        if (Number(this.total) < 0 && statusTypeID !== 1) {
            errorList.push({ message: "Total amount should be greater than or equal to zero." });
        }
        else if (Number(this.total) > 922337203685477) {
            errorList.push({ message: "Total amount should be less than 922,337,203,685,477" });
        }
        //return errorList;
    }
    RowValidation1(statusTypeID, row, errorList, backorderList, i): Boolean {
        let rowerrorList: any[] = [];
        //let rowbackorderList: any[] = [];

        if ((this.statusTypeID == 0 || this.statusTypeID == 1 || this.statusTypeID == 2 || this.statusTypeID == undefined)
            && statusTypeID == 3 && row.itemID && row.itemID > 0
            && this.lstInventoryItem.find(item => item.itemID == row.itemID) === undefined) {
            errorList.push({ message: `Row #${i}: Item is inactive` });
            return false;
        }
        if ((this.statusTypeID == 0 || this.statusTypeID == 1 || this.statusTypeID == 2 || this.statusTypeID == undefined)
            && statusTypeID == 3 && row.taxRateID && this.transTypeID != 21
            && this.lsttaxRate.find(tax => tax.mstrTaxRateID == row.taxRateID) === undefined) {
            errorList.push({ message: `Row #${i}: Taxrate is inactive` });
            return false;
        }

        if (
            (row.description != '') ||
            (row.itemName != '' || row.itemID != 0) ||
            (row.quantity != '') ||
            (row.unitPrice != '') ||
            (row.accountID != '' && row.accountID != null) ||
            (row.taxRateID != '' && row.taxRateID != null)
        ) {
            if (row.description === "" && statusTypeID != 1) {
                rowerrorList.push({ message: "Row #" + i + ": Description cannot be empty." });
            }
            if (((row.description != '') ||
                (row.quantity != '') ||
                (row.unitPrice) != '' ||
                (row.accountID != '' && row.accountID != null) ||
                (row.taxRateID != '' && row.taxRateID != null)) &&
                (statusTypeID != 1)) {
                if (row.quantity === "" || row.quantity == null || row.quantity == undefined) {
                    rowerrorList.push({ message: "Row #" + i + ": Quantity cannot be empty." });
                }
                if (row.quantity == '0' || row.quantity == 0) {
                    rowerrorList.push({ message: "Row #" + i + ": Quantity should be greater than 0." });
                }

                if ((row.unitPrice === "" || row.unitPrice == undefined || row.unitPrice == null) && this.transTypeID != 21) {
                    rowerrorList.push({ message: "Row #" + i + ": Unit Price cannot be empty." });
                }

                let o = Number(row.orginalTaxAmount) < 0 ? -1 * Number(row.orginalTaxAmount) : Number(row.orginalTaxAmount);
                if (o > 0) {
                    if ((Number(row.taxAmount) <= 0 || Number(row.taxAmount) > Number(row.amount)) && Number(row.amount) > 0) {
                        rowerrorList.push({ message: "Row #" + i + ": Tax amount should be greater than 0 or less than or equal to line amount." });
                    }
                    else if (Number(row.amount) < 0) {
                        let a = -1 * Number(row.amount);
                        let t = -1 * Number(row.taxAmount);
                        if ((Number(t) < 0 || Number(t) > Number(a)) && Number(a) > 0) {
                            rowerrorList.push({ message: "Row #" + i + ": Tax amount should be less than  or equal to 0 or less than or equal to line amount." });
                        }
                    }
                }

                if (((Number(row.unitPrice) !== 0) ||
                    (row.accountID != '' && row.accountID != null) ||
                    (row.taxRateID != '' && row.taxRateID != null)) && this.transTypeID != 21) {

                    if (!(((row.itemTypeID == 2 || row.itemTypeID == 0) && this.transTypeID == 1) || (this.transTypeID == 5))) {
                        if (row.accountID === 0 || row.accountID === "" || row.accountID === undefined || row.accountID === null) {
                            rowerrorList.push({ message: "Row #" + i + ": Account cannot be empty." });
                        }
                        if (row.taxRateID === 0 || row.taxRateID === "" || row.taxRateID === undefined || row.taxRateID === null) {
                            rowerrorList.push({ message: "Row #" + i + ": Tax Rate cannot be empty." });
                        }

                    }
                }
            }
            // if (Number(row.taxAmount) !== 0 && Number(row.taxAmount) > Number(row.amount)) {
            //   rowerrorList.push({ message: "Row #" + i + ": Tax amount cannot be greater than the line amount." });
            // }

            if (row.discount?.toString()?.includes('%')) {
                let discount = row.discount?.toString()?.replace('%', '');
                if (Number(discount) < 0) {
                    errorList.push({ message: "Row #" + i + ": Discount percetage should be greater than zero." });
                }
                if (Number(discount) > 100) {
                    errorList.push({ message: "Row #" + i + ": Discount percetage should be less than 100." });
                }
                let grossAmount = row.unitPrice * row.quantity;
                if (Number(grossAmount) == 0) {
                    if (Number(discount) > Math.abs(Number(grossAmount))) {
                        errorList.push({ message: "Row #" + i + ": Discount should be Less than or Equal to Amount." });
                    }
                }
            } if (row.discount?.toString()?.includes('%') == false) {
                if (Number(row.discount) > 0) {
                    let discount = row.discount;
                    let grossAmount = row.unitPrice * row.quantity;
                    if (Number(grossAmount) >= 0) {
                        if (Number(discount) > Math.abs(Number(grossAmount))) {
                            errorList.push({ message: "Row #" + i + ": Discount should be Less than or Equal to Amount." });
                        }
                    }
                }
            }
            if (statusTypeID != 1) {
                let customField = this.columns.filter(x => x.isCustom == true && x.isMandatory == true);
                if (customField?.length > 0) {
                    customField.forEach(element => {
                        if (row[element.field] == undefined) {
                            rowerrorList.push({ message: "Row #" + i + ": " + element.title + " cannot be empty." });
                        }
                    });
                }
            }
            if (this.transTypeID == 8) {
                if (row.itemAvgCost != "" && row.itemAvgCost != undefined && row.itemAvgCost != null && Number(row.itemAvgCost) < 0) {
                    rowerrorList.push({ message: "Row #" + i + ": Unit Cost Price cannot be negative." });
                }
            }
        }
        else {
            return false;
        }

        if (statusTypeID === 1) {
            // if (Number(row.taxAmount) !== 0 && Number(row.taxAmount) > Number(row.amount)) {
            //   errorList.push({ message: "Row #" + i + ": Tax amount cannot be greater than the line amount." });
            // }
            //else {
            row.index = i;
            //}
        }
        else {
            const whenInvoiceNotCopiedFromDO = (this.transTypeID == 6 && this.copyTransTypeID != 21) || (this.transTypeID == 21 && this.copyTransTypeID != 6) || (this.transTypeID != 6 && this.transTypeID != 21);      
            if ((whenInvoiceNotCopiedFromDO) &&
                ((this.transTypeID == 4 && row.IsQtyReturn==true) || this.transTypeID == 6 || this.transTypeID == 11 || (this.transTypeID == 21 && this.isDOEnabled==true)) &&
                (Number(row.quantity)) > 0 &&
                (row.isTrackedItem == true)) {
                let availableQty = this.lstInventoryItem.filter(item => item.itemID === row.itemID)[0].availableQuantity;
                let enableNegativeStock = this.lstInventoryItem.filter(item => item.itemID === row.itemID)[0].enableNegativeStock;
                let itemCode = this.lstInventoryItem.filter(item => item.itemID === row.itemID)[0].itemCode;
                let warehouseName = this.lstInventoryItem.filter(item => item.itemID === row.itemID)[0].warehouseName;
                let enteredQty = this.getQuantity(row.itemID);
                let progressInvoiceCosting = this.transTypeID != 6 || ((this.rowData[0].Deposit != '2' && this.rowData[0].Deposit != '1') || (this.rowData[0].Deposit == '1' && ((this.rowData[0].progressType == 'UnitPrice' && this.rowData[0].IsCostingAppliedPI) || (this.rowData[0].progressType == 'Quantity'))))
                if (availableQty < enteredQty  && !enableNegativeStock && progressInvoiceCosting) {
                    rowerrorList.push({ message: "" + row.itemName + " is available only " + availableQty + " quantity to sell." })
                }
                if (availableQty < enteredQty  && enableNegativeStock) {
                    backorderList.push({
                        itemCode: "" + itemCode + "",
                        itemname: "" + row.itemName + "",
                        warehouseName: "" + warehouseName + "",
                        productCategoryName: "" + row.productCategoryName + "",
                        itemUnitTypeName: "" + row.itemUnitTypeName + "",
                        availableQty: "" + availableQty + "",
                        enteredQty: "" + enteredQty + "",
                        finalQty: "" + (availableQty - enteredQty)  + ""
                    })
                }
            }
            //Unit Price Validation for Tracked Item
            if ((this.transTypeID == 2 || this.transTypeID == 4 || this.transTypeID == 6 || this.transTypeID == 8 || this.transTypeID == 10 || this.transTypeID == 11) && (row.isTrackedItem == true)) {
                if (row.unitPrice < 0) {
                    rowerrorList.push({ message: "Row #" + i + ": Unit price should not be less than zero for tracked items." });
                }
            }
            if (rowerrorList.length > 0) {
                rowerrorList.forEach((err: any, c) => {
                    errorList.push(err);
                });
            }
            else {
                row.index = i;
            }
        }
        return rowerrorList.length == 0;
    }
    ParentRowValidation1(statusTypeID, row, errorList, i) {
        let rowerrorList: any[] = [];

        if ((this.statusTypeID == 0 || this.statusTypeID == 1 || this.statusTypeID == 2 || this.statusTypeID == undefined)
            && statusTypeID == 3 && row.itemID && row.itemID > 0
            && this.lstInventoryItem.find(item => item.itemID == row.itemID) === undefined) {
            errorList.push({ message: `Row #${i}: Product group is inactive` });
            return false;
        }

        if (
            (row.description != '') ||
            (row.itemName != '' || row.itemID != 0) ||
            (row.quantity != '') ||
            (row.unitPrice) != '' ||
            (row.accountID != '' && row.accountID != null) ||
            (row.taxRateID != '' && row.taxRateID != null)
        ) {
            if (row.description === "" && statusTypeID != 1) {
                rowerrorList.push({ message: "Row #" + i + ": Description cannot be empty." });
            }
            if ((
                (row.description != '') ||
                (row.quantity != '') ||
                (row.unitPrice) != '' ||
                (row.accountID != '' && row.accountID != null) ||
                (row.taxRateID != '' && row.taxRateID != null)) &&
                (statusTypeID != 1)) {
                if (row.quantity === "" || row.quantity == null || row.quantity == undefined) {
                    rowerrorList.push({ message: "Row #" + i + ": Quantity cannot be empty." });
                }
                if (row.quantity == '0') {
                    rowerrorList.push({ message: "Row #" + i + ": Quantity should be greater than 0." });
                }
            }
            // if (Number(row.amount) > 0) {
            // if (Number(row.taxAmount) < 0) {
            //   rowerrorList.push({ message: "Row #" + i + ": Tax amount must be between 0 and the line amount." });
            // }
            // }
            if (Number(row.taxAmount) !== 0 && Number(row.taxAmount) > Number(row.amount)) {
                rowerrorList.push({ message: "Row #" + i + ": Tax amount cannot be greater than the line amount." });
            }
        }
        else {
            return false;
        }

        if (statusTypeID === 1) {
            row.index = i;
        }
        else {
            if (rowerrorList.length > 0) {
                rowerrorList.forEach((err: any, c) => {
                    errorList.push(err);
                });
            }
            else {
                row.index = i;
            }
        }

        var child = row.lstChild.filter(ch => ch.index >= 0 && ((ch.description) || (ch.itemName) || (ch.itemID)));
        if (child.length === 0) {
            errorList.push({ message: "Row #" + i + ": should be add atleast one child item." });
        }
        return rowerrorList.length == 0;
    }


    convertToNullIfNotValid(value) {
        if (value == '' && typeof (value) != 'number')
            return null;
        else if (value == undefined)
            return null;
        else if (value == null)
            return null;
        else
            return value;
        // else if (typeof (value) == 'number')
        //   return value;
        // else if (typeof (value) == 'string')
        //   return parseInt(value);
    }

    onGridValidate(statusTypeID: any) {
        this.validRowData = [];
        let errorList: any = [];
        let isGridValid = false;
        let isRowValid = false;
        let rowerrorList = [];

        let a: any = 0;
        this.rowData.forEach((row: any, r) => {
            a = a + 1;
            let rowItemIsActive = true;
            if (this.transTypeID == 21 && statusTypeID == 3 && row.itemID && row.itemID > 0 &&
                (this.statusTypeID == 0 || this.statusTypeID == 1 || this.statusTypeID == 2 || this.statusTypeID == undefined)
                && this.lstInventoryItem.find(item => item.itemID == row.itemID) === undefined) {
                errorList.push({ message: `Row #${a}: Item is inactive` });
                rowItemIsActive = false;
                isGridValid = true;
            }
            if ((this.statusTypeID == 0 || this.statusTypeID == 1 || this.statusTypeID == 2 || this.statusTypeID == undefined)
                && statusTypeID == 3 && row.taxRateID && this.transTypeID != 21
                && this.lsttaxRate.find(tax => tax.mstrTaxRateID == row.taxRateID) === undefined) {
                errorList.push({ message: `Row #${a}: Taxrate is inactive` });
                rowItemIsActive = false;
                isGridValid = true;
            }

            if (rowItemIsActive && (row.index >= 0 &&
                ((row.description) || (row.itemName) || (row.itemID) || (row.quantity) || (row.unitPrice) || (row.accountID) || (row.taxRateID))) ||
                (this.typeName === 'Overpayment')) {
                //Unit Price Validation for Tracked Item
                if ((this.transTypeID == 10 || this.transTypeID == 11 || this.transTypeID == 21) && (row.isTrackedItem == true)) {
                    if (row.unitPrice < 0) {
                        rowerrorList.push({ message: "Row #" + a + ": Unit price should not be less than zero for tracked items." });
                    }
                }
                if (row.isGroup == false) {
                    //row validation
                    this.columns.forEach((column: any, c) => {
                        if (column.isCustom == true && row[column.field] == undefined) {
                            row[column.field] = null;
                        }
                        if (column.isVisible == true && column.isEditable == true && column.isMandatory == true) {
                            if (row[column.field] != null && row[column.field] != undefined) {
                                if (row[column.field].toString().trim() != '') {
                                    if (Number(row.quantity) === 0 && column.field == 'quantity') {
                                        rowerrorList.push({ message: "Row #" + a + ": " + column.title + "  should be greater than 0." });
                                    }
                                    if (isNaN(Number(row.unitPrice)) && column.field == 'unitPrice') {
                                        rowerrorList.push({ message: "Row #" + a + ": " + column.title + " should be a valid number" });
                                    }
                                    isRowValid = true;
                                    isGridValid = true;
                                }
                                else {
                                    rowerrorList.push({ message: "Row #" + a + ": " + column.title + " cannot be empty." });
                                }
                            }
                            else {
                                rowerrorList.push({ message: "Row #" + a + ": " + column.title + " cannot be empty." });
                            }
                        }
                    });
                    if (rowerrorList.length > 0) {
                        rowerrorList.forEach((err: any, c) => {
                            errorList.push(err);
                        });
                    }
                    rowerrorList = [];
                    if (isRowValid == true) {
                        if (statusTypeID == 1 || rowerrorList.length === 0) {
                            row.index = a;
                            this.validRowData.push(row);
                        }
                        else {
                            const whenDONotCopiedFromInvoice = (this.transTypeID == 21 && this.copyTransTypeID != 6) || this.transTypeID != 21;
                            if (whenDONotCopiedFromInvoice && Number(row.quantity) > 0 && row.isTrackedItem == true &&(this.transTypeID == 4 || this.transTypeID == 6 || this.transTypeID == 11 || this.transTypeID == 30)) {
                                let availableQty = this.lstInventoryItem.filter(item => item.itemID === row.itemID)[0].availableQuantity;
                                let enableNegativeStock = this.lstInventoryItem.filter(item => item.itemID === row.itemID)[0].enableNegativeStock;
                                let enteredQty = this.getQuantity(row.itemID);
                                if (availableQty < enteredQty && !enableNegativeStock) {
                                    rowerrorList.push({ message: "" + row.itemName + " is available only " + availableQty + " quantity to sell." })
                                }
                            }
                            if (rowerrorList.length > 0) {
                                rowerrorList.forEach((err: any, c) => {
                                    errorList.push(err);
                                });
                            }
                            else {
                                row.index = a;
                                this.validRowData.push(row);
                            }
                        }
                        isRowValid = false;
                        rowerrorList = [];
                    }

                }
                else if (row.isGroup == true) {
                    //parent row validation
                    this.columns.forEach((column: any, c) => {
                        if (column.isCustom == true && row[column.field] == undefined) {
                            row[column.field] = null;
                        }
                        if (column.field == 'itemID' || column.field == 'description' || column.field == 'quantity') {
                            if (row[column.field] != null && row[column.field] != undefined) {
                                if (row[column.field].toString().trim() != '') {
                                    if (Number(row.quantity) === 0 && column.field == 'quantity') {
                                        rowerrorList.push({ message: "Row #" + a + ": " + column.title + "  should be greater than 0." });
                                    }
                                    if (isNaN(Number(row.unitPrice)) && column.field == 'unitPrice') {
                                        rowerrorList.push({ message: "Row #" + a + ": " + column.title + " should be a valid number." });
                                    }
                                    isRowValid = true;
                                    isGridValid = true;
                                }
                                else {
                                    rowerrorList.push({ message: "Row #" + a + ": " + column.title + " cannot be empty." });
                                }
                            }
                            else {
                                rowerrorList.push({ message: "Row #" + a + ": " + column.title + " cannot be empty." });
                            }
                        }
                    });

                    if (isRowValid == true) {
                        if (statusTypeID == 1 || rowerrorList.length === 0) {
                            row.index = a;
                            this.validRowData.push(row);
                        }
                        else {
                            if (rowerrorList.length > 0) {
                                rowerrorList.forEach((err: any, c) => {
                                    errorList.push(err);
                                });
                            }
                            else {
                                row.index = a;
                                this.validRowData.push(row);
                            }
                        }

                        isRowValid = false;
                        rowerrorList = [];
                    }

                    //child row validation
                    var child = row.lstChild.filter(ch => ch.index >= 0 && ((ch.description) || (ch.itemName) || (ch.itemID)));
                    // var child = row.lstChild.filter(ch => ch.itemID > 0 && ch.index >= 0 && (ch.itemName != "" && ch.itemName != undefined && ch.itemName != null))
                    if (child.length === 0) {
                        errorList.push({ message: "Row #" + a + ": should be add atleast one child item." });
                    }
                    row.lstChild.forEach(async (childrow: any, cr) => {
                        a = a + 1;
                        if (childrow.index >= 0 &&
                            ((childrow.description) || (childrow.itemName) || (childrow.itemID) || (childrow.quantity) || (childrow.unitPrice) || (childrow.accountID) || (childrow.taxRateID))) {
                            this.columns.forEach((column: any, c) => {
                                if (column.isCustom == true && childrow[column.field] == undefined) {
                                    childrow[column.field] = null;
                                }
                                if (column.isVisible == true && column.isEditable == true && column.isMandatory == true) {
                                    if (childrow[column.field] != null && childrow[column.field] != undefined) {
                                        if (childrow[column.field].toString().trim() != '') {
                                            if (Number(childrow.quantity) === 0 && column.field == 'quantity') {
                                                rowerrorList.push({ message: "Row #" + a + ": " + column.title + "  should be greater than 0." });
                                            }
                                            if (isNaN(Number(childrow.unitPrice)) && column.field == 'unitPrice') {
                                                rowerrorList.push({ message: "Row #" + a + ": " + column.title + " should be a valid number." });
                                            }
                                            isRowValid = true;
                                            isGridValid = true;
                                        }
                                        else {
                                            rowerrorList.push({ message: "Row #" + a + ": " + column.title + " cannot be empty." });
                                        }
                                    }
                                    else {
                                        rowerrorList.push({ message: "Row #" + a + ": " + column.title + " cannot be empty." });
                                    }
                                }
                            });
                            if (rowerrorList.length > 0) {
                                rowerrorList.forEach((err: any, c) => {
                                    errorList.push(err);
                                });
                            }
                            rowerrorList = [];

                            if (isRowValid == true) {
                                if (statusTypeID == 1 || rowerrorList.length === 0) {
                                    childrow.index = a;
                                    this.validRowData.push(childrow);
                                }
                                else {
                                    const whenDONotCopiedFromInvoice = (this.transTypeID == 21 && this.copyTransTypeID != 6) || this.transTypeID != 21;
                                    if (whenDONotCopiedFromInvoice && Number(childrow.quantity) > 0 && childrow.isTrackedItem == true && (this.transTypeID == 4 || this.transTypeID == 6 || this.transTypeID == 11 || this.transTypeID == 30)) {
                                        let availableQty = this.lstInventoryItem.filter(item => item.itemID === childrow.itemID)[0].availableQuantity;
                                        let enableNegativeStock = this.lstInventoryItem.filter(item => item.itemID === childrow.itemID)[0].enableNegativeStock;
                                        let enteredQty = this.getQuantity(childrow.itemID);
                                        if (availableQty < enteredQty && !enableNegativeStock) {
                                            rowerrorList.push({ message: "" + childrow.itemName + " is available only " + availableQty + " quantity to sell." })
                                        }
                                    }
                                    if (rowerrorList.length > 0) {
                                        rowerrorList.forEach((err: any, c) => {
                                            errorList.push(err);
                                        });
                                    }
                                    else {
                                        childrow.index = a;
                                        this.validRowData.push(childrow);
                                    }
                                }
                                isRowValid = false;
                                // //Added for unit price - validation
                                // if (rowerrorList?.length === 0) {
                                //   rowerrorList = [];
                                // }
                                // Added for unit price - validation
                                rowerrorList = [];
                            }
                        }
                    });
                }
            }
        });

        if (statusTypeID == 1) {
            // //Added for unit price - validation
            // if (rowerrorList?.length > 0) {
            //   isGridValid = false;
            //   return rowerrorList;
            // } else {
            //   errorList = [];
            //   isGridValid = true;
            // }
            // //Added for unit price - validation

            // errorList = [];
            isGridValid = true;
        }
        if (isGridValid == false) {
            errorList = [];
            errorList.push({ message: "Cannot be empty all rows. Atleast enter one item." });
        }
        else {
            if (Number(this.total) < 0) {
                errorList.push({ message: "Total amount should be greater than or equal to zero." });
            }
            else if (Number(this.total) > 922337203685477) {
                errorList.push({ message: "Total amount should be less than 922,337,203,685,477" });
            }
        }
        return errorList;
    }

    getQuantity(itemID) {
        let qty = 0;
        this.rowData.forEach(async (row: any, r) => {
            if (row.itemID == itemID && row.isGroup == false) {
                qty = qty + Number(row.quantity);
            }
            else if (row.isGroup == true) {
                if (row.lstChild.length > 0) {
                    row.lstChild.forEach((childrow: any, cr) => {
                        if (childrow.itemID == itemID) {
                            qty = qty + Number(childrow.quantity);
                        }
                    });
                }
            }
        });
        return qty;
    }
    addItem(parentIndex, childindex) {
        this.curparentIndex = parentIndex;
        this.curchildindex = childindex;
        this.additemyModal.openItemModal(0, 1, 'NTA', this.transTypeID);
    }
    returnAddItem(invItems: any) {
        if (((this.transTypeID==8 && this.copyTransID!=0 && this.PaymentData!=undefined) || (this.transTypeID==4 && this.copyTransID!=null && this.transID==0)) && (invItems.isTrackedItem || invItems.itemTypeID==3)) {
            let msg = this.translate.instant("Tracked and Product group cannot be added to this credit note. However, this item has been added to the inventory.");
            this.alertMessage.errorNotifier(msg, this.errorNotifierID);
            return false;
        }
        if((this.transTypeID==8 && this.copyTransID!=0) || (this.transTypeID==4 && this.copyTransID!=null)){
            this.UntracklstInventoryItem.push(invItems);
            this.UntracklstInventoryItem = this.UntracklstInventoryItem.slice(0);
        }

        this.lstInventoryItem.push(invItems);
        this.lstInventoryItem = this.lstInventoryItem.slice(0);     
        let row: any = null;
        if (this.curchildindex == -1) {
            row = this.rowData[this.curparentIndex];
            row.itemID = invItems.itemID;
            this.onCellValueChanged(row, 'itemID', this.curparentIndex, -1, 'p')
        }
        else if (this.curchildindex >= 0) {
            this.rowData[this.curparentIndex].lstChild[this.curchildindex].itemID = invItems.itemID;
            row = this.rowData[this.curparentIndex];
            this.onCellValueChanged(row, 'itemID', this.curparentIndex, this.curchildindex, 'c')
        }
    }

    addhtmlDescription(row, parentIndex, childindex) {
        this.curparentIndex = parentIndex;
        this.curchildindex = childindex;
        if (!this.isOnlyGridCustomCellEdit)
            if (this.isGridEditable == true) {
                this.addtextmodal.openTextModal(row.HTMLDesc, 'TA');
            }
            else if (this.isGridEditable == false) {
                this.addtextmodal.openTextModal(row.HTMLDesc, 'TV');
            }
    }

    viewItemHistoricalPrice(row) {
        if (row.itemID > 0) {
            this.itemhistoryModal.getItemHistory(row.itemID);
        }
    }

    returnTextDescription(htmlText) {
        if (this.curchildindex == -1) {
            this.rowData[this.curparentIndex].HTMLDesc = htmlText;
        }
        else if (this.curchildindex >= 0) {
            this.rowData[this.curparentIndex].lstChild[this.curchildindex].HTMLDesc = htmlText;
        }
    }

    // LivegetinnerHTML() {
    //   return this.LivecurrencyTooltip;
    // }

    numberFormatToFixed(val: Number) {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 5,
        });
        let toFixed: Number = val ? val : 0;
        return formatter.format(Number(toFixed))
    }

    encodebtoa(id: any) {
        return btoa(id);
    }


    setItemUnitPrice(callFrom: string, parentIndex: number = 0, childindex: number = 0) {
        let Obj = {
            rowData: this.rowData,
            contactID: this._contact.contactID,
            transDate: moment(this._date).format('YYYY-MM-DD'),
            transType: (this.transTypeID == 1 || this.transTypeID == 2 || this.transTypeID == 3 || this.transTypeID == 4) ? 'Purchase' : 'Sales',
            callFrom: callFrom,
            index: parentIndex,
            childindex: childindex,
            itemKey: "itemID",
            qtyKey: "quantity",
            unitPriceKey: "unitPrice",
            amountKey: "amount",
            affectedRowIndexes: []
        }
        if (this.typeName != 'PInvoice') {
            if (!!this.getunitprice) {
                this.getunitprice.getItemUnitPrice(Obj);
            }
        }
    }

    returnUnitPrice(e) {
        // if(e == true){
        //   this.gridOptions.api.refreshCells({ force: true });
        //   let params :any;
        if (e.affectedRowIndexes.length > 0) {
            e.affectedRowIndexes.forEach(rowIndex => {
                this.onCellValueChanged(this.rowData[rowIndex], "unitPrice", rowIndex, 0, 'p');
            });
        } else {
            var row = this.rowData[e.index];
            if (row.isGroup === false) {
                this.onCellValueChanged(row, "unitPrice", e.index, 0, 'p')
            }
            else {
                var row = row.lstChild[e.childindex];
                this.onCellValueChanged(row, "unitPrice", e.index, e.childindex, 'c')
            }
        }
        this.onTotalCalculation();
    }


    async removeCredits(params: any) {
        var con = await Swal.fire({
            title: 'Delete',
            icon: 'warning',
            text: 'Are you sure want to delete?',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        });
        if (con.dismiss === Swal.DismissReason.cancel) {
            return;
        }
        // this.loading = true;
        let accountTransactions: DeleteAccountTransactions[] = [];
        if (params.Desc.trim() == "Less Refund" || params.Desc.trim() == "Refund payment") {
            accountTransactions.push({
                AccountTranTypeID: params.MappingTransactionTypeID,
                AccountTranID: params.CreditID
            })
        }
        else {
            accountTransactions.push({
                AccountTranTypeID: params.MappingTransactionTypeID,
                AccountTranID: params.MappingTransactionID,
                CreditID: params.CreditID,
                SpendTransID: this.transID,
                TransactiontypeID: this.transTypeID,
                isAllocateDelete: true,
            })
        }
        await this.bankService.DeleteAccountTransactions(accountTransactions).then((data) => {
            if (data !== "" && data !== null) {
                this.alertMessage.warningNotifier(data.toString(), 0);
            }
            else {
                this.alertMessage.successNotifier("Successfully deleted the Account Transactions!!", 0);
                let CurrentURL = this.router.url.split('?')[0];
                this.router.navigate([CurrentURL]).then(x => {
                    if ([10, 11].includes(this.transTypeID)) {
                        this.router.navigate([CurrentURL], {
                            queryParams: {
                                account: btoa(this.CAAccountID.toString()),
                                tranid: btoa(this.transID.toString()),
                                trantypeid: btoa(this.transTypeID.toString())
                            }
                        });
                    }
                    else {
                        let creditnotetransID=this.transID.toString()+ "," + this.transTypeID.toString();
                        this.router.navigate([CurrentURL], {
                            queryParams: {
                                id:btoa(creditnotetransID) ,
                            }
                        });
                    }
                });
            }
        }, error => {
            console.log(error);
            let msg = this.translate.instant("SharedModule.Error while deleting the Account Transactions");
            this.alertMessage.errorNotifier(msg, 0);
        });
    }

    OnallocateCNClick(event: any) {
        // trigger an event from creditntoe view page for allocate credit amount on "Allocate Remaining Credit"
        this.allocateCNClick.emit(event);
    }

    clickRefund(credit) {
        if ( credit.MappingTransactionTypeID === 11 || credit.MappingTransactionTypeID === 10 ) {
            this.router.navigate(['/transactions/viewtransaction'], {
                queryParams: {
                    account: btoa(credit.CAAccountID),
                    tranid: btoa(credit.MappingTransactionID),
                    trantypeid: btoa(credit.MappingTransactionTypeID),
                    transTypeID :  btoa(this.transTypeID.toString())
                }
            });
        }
        else {
            this.router.navigate(['/transactions/viewtransaction'], {
                queryParams: {
                    account: btoa(credit.CAAccountID),
                    tranid: btoa(credit.TransactionID),
                    trantypeid: btoa(credit.TransactionTypeID),
                    transTypeID :  btoa(this.transTypeID.toString())
                }
            });
        }
    }

    ExchangeRate: any;
    lstEexchangeRate: any;
    setExchangeRate() {
        let fromCurrency: string = "";
        if ((this.orgCurrencyCode == this.currencyCode) && (this.BankCurrencyCode != this.currencyCode)) {
            fromCurrency = this.BankCurrencyCode;
        }
        else {
            fromCurrency = this.orgCurrencyCode;
        }

        if (fromCurrency && this.currencyCode) {
            if(this.transTypeID!=10 && this.transTypeID!=11){
                if ((this.orgCurrencyCode != this.currencyCode) || (this.BankCurrencyCode != this.currencyCode)) {
                    this.http.getservice(environment.organizationApiUrl + '/CurrencySetting/GetUsedCurrenyType?OrgCurrencyCode=' + fromCurrency + '&ToCurrencyCode=' + this.currencyCode + '&ExchangeDate=' + moment(this.TransDate).format('YYYY-MM-DD')).subscribe({
                        next: (exdata) => {
                            this.lstEexchangeRate = exdata;
                            let data = this.lstEexchangeRate.filter(x => x.isSelected == true);
                            if (data.length > 0) {
                                if((data[0].unitsper < 0) || (data[0].unitsper == null) ||(data[0].unitsper == "")||(data[0].unitsper == undefined))
                                {
                                    data[0].unitsper = 1;
                                }
                                this.ExchangeRate = "1 " + this.orgCurrencyCode + " = " + data[0].unitsper + " " + this.currencyCode + "(" + moment(data[0].exchangeDate).format('YYYY-MM-DD') + ")";
                                this.currencyTooltip = this.numberFormatToFixed(Number(this.total) / data[0].unitsper) + " " + this.orgCurrencyCode +
                                    "<br/>1 " + this.orgCurrencyCode + " = " + data[0].unitsper + " " + data[0].toCurrencyCode + " <br/>" +
                                    "1 " + data[0].toCurrencyCode + " = " + data[0].perUnit + " " + this.orgCurrencyCode

                                if (Number(this.AmountDue) > 0) {
                                    this.LivecurrencyTooltip = this.numberFormatToFixed(Number(this.AmountDue) / data[0].unitsper) + " " + this.orgCurrencyCode +
                                        "<br/>1 " + this.orgCurrencyCode + " = " + data[0].unitsper + " " + data[0].toCurrencyCode + " <br/>" +
                                        "1 " + data[0].toCurrencyCode + " = " + this.numberFormatToFixed(Number(1 / data[0].unitsper)) + " " + this.orgCurrencyCode
                                }
                                else {
                                    this.LivecurrencyTooltip = "";
                                }
                            }
                        }
                    });
                }
                else {
                    this.ExchangeRate = "";
                    this.currencyTooltip = "";
                    this.LivecurrencyTooltip = "";
                }
            }
        }
    }

    isPercentageIncluded(discountAmount): Boolean {
        if (typeof (discountAmount) == 'string' && discountAmount?.includes('%')) {
            return true;
        } else {
            return false;
        }
    }

    discountAmount(discountAmount): any {
        if (discountAmount == '')
            return '';
        let discountAmt = 0.00;
        if (typeof (discountAmount) == 'string') {
            discountAmt = Number(discountAmount?.replace('%', ''));
            return discountAmt;
        }
        else {
            return discountAmount;
        }
    }

    onBillAdvanceInventoryTrackingClick(trackingType, _itemID, _transactionQuantity, _transactionDetailId) {
        if (trackingType == 'Batch') {
            this._isShowBatchModalPopup = true;
            this.createBatch.openAllocateBatchPopup(_itemID, _transactionQuantity, _transactionQuantity, null, _transactionDetailId, this.transTypeID);
        } else if (trackingType == 'Serial' && (this.transTypeID == 4 || this.transTypeID == 8)) { //|| this.transTypeID == 8
            this._isShowSerialModalPopup = true;
            this.assignSerial.openModal(_itemID, _transactionQuantity, _transactionDetailId, this.transTypeID, null);
        } else if (trackingType == 'Serial') {
            this._isShowSerialModalPopup = true;
            this.createSerial.openModal(_itemID, _transactionQuantity, _transactionQuantity, null, _transactionDetailId, this.transTypeID, 0);
        }
    }

    onInvoiceAdvanceInventoryTrackingClick(trackingType, _itemID, _transactionQuantity, _transactionDetailId) {
        if (trackingType == 'Batch') {
            this._isShowBatchModalPopup = true;
            this.assignBatch.openModal(_itemID, _transactionQuantity, _transactionDetailId, this.transTypeID, null);
        } else if (trackingType == 'Serial') {
            this._isShowSerialModalPopup = true;
            this.assignSerial.openModal(_itemID, _transactionQuantity, _transactionDetailId, this.transTypeID, null);
        }
    }

    onDeliveryOrderAdvanceInventoryTrackingClick(trackingType, _itemID, _transactionQuantity, _transactionDetailId) {
        if (trackingType == 'Batch') {
            this._isShowBatchModalPopup = true;
            this.assignBatch.openModal(_itemID, _transactionQuantity, _transactionDetailId, this.transTypeID, null);
        } else if (trackingType == 'Serial') {
            this._isShowSerialModalPopup = true;
            this.assignSerial.openModal(_itemID, _transactionQuantity, _transactionDetailId, this.transTypeID, null);
        }
    }

    onReceiveOrderAdvanceInventoryTrackingClick(trackingType, _itemID, _quantity, _POQuantity, _RODetailId, _receivedQuantity) {
        this._RODetailID = _RODetailId;
        const transactionTypeID = this.transTypeID;
        const _mode = this.AdvanceInventoryPopupMode;
        let msg = this.translate.instant("SharedModule.Please enter Quantity to Receive");
        if (trackingType == 'Batch') {
            const tempBatchAllocationData = this.rowData.filter(x => x.ROTransDetailID == this._RODetailID).map(a => a.batchAllocationData);
            if (_mode != "View" && tempBatchAllocationData[0]?.length > 0) {
                let TempData = this.rowData.find(e => e.ROTransDetailID == _RODetailId);
                if (Number(_quantity) > 0) {
                    this.createBatch.openTempAllocateBatchPopup(_itemID, _quantity, _POQuantity, _mode, _RODetailId, transactionTypeID, tempBatchAllocationData[0], TempData);
                }
                else {
                    this.alertMessage.errorNotifier(msg, 0);
                }
            } else {
                if (_mode == 'View') {
                    this.createBatch.openAllocateBatchPopup(_itemID, _receivedQuantity, _POQuantity, _mode, _RODetailId, transactionTypeID);
                }
                else if (_mode == 'Edit') {
                    if (Number(_quantity) > 0) {
                        this.createBatch.openAllocateBatchPopup(_itemID, _quantity, _POQuantity, _mode, _RODetailId, transactionTypeID);
                    } else {
                        this.alertMessage.errorNotifier(msg, 0);
                    }
                }
                else {
                    if (Number(_quantity) > 0)
                        this.createBatch.openAllocateBatchPopup(_itemID, _quantity, _POQuantity, _mode, _RODetailId, transactionTypeID);
                    else
                        this.alertMessage.errorNotifier(msg, 0);
                }
            }
        } else if (trackingType == 'Serial') {
            if (_mode == 'View') {
                this._isShowSerialModalPopup = true;
                this.createSerial.openModal(_itemID, _receivedQuantity, _POQuantity, _mode, _RODetailId, transactionTypeID, _receivedQuantity);
            }
            else if (_mode == 'New' || _mode == 'Edit') {
                let serialDetails = this.rowData.filter(x => x.ROTransDetailID == _RODetailId).map(a => a.serialAllocationData);
                if (Number(_quantity) > 0) {
                    this._isShowSerialModalPopup = true;
                    this.createSerial.openModal(_itemID, _quantity, _POQuantity, _mode, _RODetailId, transactionTypeID, _receivedQuantity);
                }
                else {
                    this.alertMessage.errorNotifier(msg, 0);
                }
            }
        }
    }

    emitAfterBatchorSerialSaved(params: any) {
        this.emitonSubmit.emit(params);
    }

    emitOnBillSumbit(transactionName) {
        if (transactionName == 'Bill') {
            this.emitOnBillSerialNoSaved.emit('Audit');
        }
    }

    emitOnSubmitBatchTracking(_Model) {
        if (_Model.length > 0) {
            const itemId = _Model[0].ItemID;
            const RODetailId = this._RODetailID;
            this.rowData.filter(x => x.ROTransDetailID == RODetailId).map(a => a.batchAllocationData = _Model);
        }
    }

    emitOnSubmitSerialTracking(_Model) {
        if (_Model.length > 0) {
            const itemId = _Model[0].ItemID;
            const RODetailId = this._RODetailID;
            this.rowData.filter(x => x.ROTransDetailID == RODetailId).map(a => a.serialAllocationData = _Model);
        }
    }

    InventoryTrackingClick(row) {
        if (this.transTypeID == 20) {
            // this.onReceiveOrderAdvanceInventoryTrackingClick(row['trackingType'], row['ItemID'], row['QuantitytoReceive'], row['quantity'], row['ROTransDetailID'], row['ReceivedQuantity']);
            this.onReceiveOrderAdvanceInventoryTrackingClick(row['trackingType'], row['itemID'], row['QuantitytoReceive'], row['quantity'], row['ROTransDetailID'], row['ReceivedQuantity']);
        }
        else if ([6, 21].some(o => o == this.transTypeID)) {
            this.onInvoiceAdvanceInventoryTrackingClick(row['trackingType'], row['itemID'], row['quantity'], row['detailID']);
        }
        else if (this.transTypeID == 2) {
            this.onBillAdvanceInventoryTrackingClick(row['trackingType'], row['itemID'], row['quantity'], row['BillDetailID']);
        }
        else if ([4, 8].some(o => o == this.transTypeID)) {
            this.onBillAdvanceInventoryTrackingClick(row['trackingType'], row['itemID'], row['quantity'], row['detailID']);
        }
    }

    emitOnInvoiceSumbit(transactionName) {
        if (['Invoice', 'DO'].some(ele => ele == transactionName.trans)) {
            this.onTriggerAfterBatchSerialSaved.emit({trans:'Audit',tracktype:transactionName.track});
        }
    }

    emitOnDOSerialSumbit(transactionName) {
        if (transactionName == 'DO') {
            this.emitOnDOSerialNoSaved.emit('Audit');
        }
    }

    IsIncludedTransaction(): boolean {
        return [2, 4, 6, 8, 20, 21].some(o => o == this.transTypeID);
    }

    recalculateRowData() {
        this.rowData.forEach((r, i) => {
            this.onCellValueChanged(r, 'quantity', i, -1, 'p');
        });
        this.onTotalCalculation();
    }

    remainingAllocatedDeposit: any = 0;
    remainingAllocatedDepositWithTax  = 0;
    OnallocateDepositClick(deposit: any) {
        let depositavailable = this.rowData.find(r => { return r.isDeposit == true });
        let invDetails = JSON.parse(deposit.InvoiceDetails);
        let tax = 0;
        let taxRate = this.lsttaxRate.find(x => x.mstrTaxRateID == invDetails[0].TaxID);
        let coa = this.lstChartAcounts.find(x => x.CAAccountID == invDetails[0].AccountID);
        if (this.total > 0) {
            let amountToDisburse = (this.total + Math.abs(this.currentInvoiceDeposit) + Math.abs(this.currentInvoiceDepositTax)) >= (this.remainingAllocatedDepositWithTax + Math.abs(this.currentInvoiceDeposit) + Math.abs(this.currentInvoiceDepositTax)) ? this.remainingAllocatedDepositWithTax + Math.abs(this.currentInvoiceDeposit)  + Math.abs(this.currentInvoiceDepositTax): (this.total + Math.abs(this.currentInvoiceDeposit) + Math.abs(this.currentInvoiceDepositTax) );
            if (this.taxTypeName == 'Tax Exclusive') {
                tax = amountToDisburse - (amountToDisburse * (100 / (100 + taxRate.rate)))
                // tax = (taxRate.rate / 100) * amountToDisburse;
                this.currentInvoiceDeposit = -(amountToDisburse - tax);
                this.currentInvoiceDepositTax = -tax;
                amountToDisburse = amountToDisburse -tax;
            } else if (this.taxTypeName == 'Tax Inclusive') {
                tax = amountToDisburse - (amountToDisburse * (100 / (100 + taxRate.rate)))
                // tax = Math.round((amountToDisburse * (taxRate.rate) / 100) * 100) / 100
                this.currentInvoiceDeposit = -(amountToDisburse - tax);
                this.currentInvoiceDepositTax = -tax;
                amountToDisburse = amountToDisburse;
            } else {
                this.currentInvoiceDeposit = -amountToDisburse;
                this.currentInvoiceDepositTax = 0;
            }
            this.emitOnProgressiveInvChanges.emit(this.currentInvoiceDeposit);

            if (!depositavailable) {
                let obj = {
                    index: 0,
                    drag: false,
                    detailID: null,
                    itemID: 0,
                    itemName: '',
                    itemTypeID: 0,
                    productCategoryID: null,
                    productCategoryName: '',
                    itemGroupID: null,
                    groupItemName: '',
                    description: 'Less: Deposit',
                    HTMLDesc: '',
                    quantity: 1,
                    itemUnitTypeID: null,
                    itemUnitTypeName: '',
                    unitPrice: (amountToDisburse * -1).toFixed(2),
                    itemAvgCost: '',
                    discount: '',
                    discountAmount: null,
                    isTrackedItem: false,
                    accountID: coa.CAAccountID,
                    accountName: coa.AccountName,
                    isAccdisabled: false,
                    taxRateID: taxRate.mstrTaxRateID,
                    taxrateName: taxRate.taxRateName,
                    taxAmount: tax * -1,
                    grossAmount: '',
                    amount: (amountToDisburse * -1).toFixed(2),
                    delete: true,
                    isGroup: false,
                    IsExisting: false,
                    lstChild: [],
                    isEditable: false,
                    isDeposit: true
                }

                this.rowData.push(obj);
                this.onCellValueChanged(obj, "unitPrice", null, null, 'p');
            } else {
                depositavailable.unitPrice = (amountToDisburse * -1).toFixed(2);
                depositavailable.amount = (amountToDisburse * -1).toFixed(2);
                this.onCellValueChanged(depositavailable, "unitPrice", null, null, 'p')
            }

        }
    }

    async ReAuthentication() {
        const currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.accessToken;
        if (isLoggedIn) {
            let timeout = this.authenticationService.getTokenTime();
            if (timeout <= 180 && timeout > 0) {
            await this.authenticationService.refreshToken();
            }
        }
    }

    isJSON(jsonData: any): boolean {
        let isValid = false;
        try {
          JSON.parse(jsonData);
          isValid = true;
        } catch (exception) {
          isValid = false;
        }
        return isValid
      }

    async showFIFOCostPrice(rowData) {
        let transactionID: number = 0;
        if (this.transTypeID == 8) {
            transactionID = Number(this.transID);
        } else if (this.transTypeID == 4) {
            transactionID = Number(this.billTransactionID);
        }
        let apiUrl = environment.inventoryApiUrl + '/Inventory/GetItemFIFOCostPrice?Details=' + JSON.stringify(rowData) + '&TransactionID=' + transactionID + '&TransactionTypeID=' + this.transTypeID;
        let response: any = await this.http.getserviceawait(apiUrl);
        if (response.isSuccess == true) {
            if (this.transTypeID == 8) {
                if (this.isJSON(response.responseData)) {
                    let rowDetails = JSON.parse(response.responseData);
                    this.rowData.forEach((row, index) => {
                        index++;
                        // row.itemAvgCost = rowDetails.filter(x => x.RowIndex == index).length > 0 ? rowDetails.find(x => x.RowIndex == index).UnitPrice : null;
                        row.itemAvgCost = rowDetails.filter(x => x.RowIndex == index).length > 0 ? null : row.itemAvgCost;
                    });
                }
            } else if (this.transTypeID == 4) {
                let rowDetails = JSON.parse(response.responseData);
                this.rowData.forEach((row, index) => {
                    index++;
                    //   row.itemAvgCost = rowDetails[0].isValid ? Number(rowDetails[0].unitPrice) * Number(row.quantity) : null;
                    row.itemAvgCost = rowDetails.filter(x => x.RowIndex == index).length > 0 ? null : row.itemAvgCost;
                });
            }
        }
    }
    onChangeDisc(currContact){
        this.rowData.forEach((row: any, index) => {
            // let itemValue = this.lstInventoryItem.filter(item => item.itemID == row.itemID);
            if (row.quantity > 0) {
                if(row.isGroup == false){
                    row.discount = ([5, 6, 29].some(o => o == this.transTypeID) && currContact.contactID > 0 &&
                    currContact.salesDiscount > 0) ? currContact.salesDiscount + '%' :
                    ([2, 1].some(o => o == this.transTypeID) && currContact.contactID > 0
                    && currContact.purchaseDiscount > 0) ? currContact.purchaseDiscount + '%' : row.discount;
                }
                else if (row.isGroup) {
                    if (row.lstChild.length > 0) {
                        row.lstChild.forEach((childrow: any, i) => {
                            if (i < row.lstChild.length - 1) {
                                childrow.discount = ([5, 6, 29].some(o => o == this.transTypeID) && currContact.contactID > 0 &&
                                currContact.salesDiscount > 0) ? currContact.salesDiscount + '%' :
                                ([2, 1].some(o => o == this.transTypeID) && currContact.contactID > 0
                                && currContact.purchaseDiscount > 0) ? currContact.purchaseDiscount + '%' : childrow.discount;
                            }
                        });
                    }
                }
                this.onCellValueChanged(row, 'discount', index, -1, 'p');
            }
        });
    }
}
