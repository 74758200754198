import { Component } from '@angular/core';
import { AgRendererComponent, ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { parseMetadata } from '@angular/localize/src/utils';

@Component({
  selector: 'app-agcolorcellrenderer',
  templateUrl: './agcolorcellrenderer.component.html'
})
export class AgcolorcellrendererComponent implements AgRendererComponent {

  params;
  cssClass: any;
  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;

    if (params.value == 'Unreconciled')
      this.cssClass = "text-warning";
    else
      this.cssClass = "text-success";
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    if (params.value == 'Unreconciled')
      this.cssClass = "text-warning";
    else
      this.cssClass = "text-success";
    return true;
  }
}
