import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { CustomTxtValidator, CustomValidator, DateRegEx, Decimal2RegEx } from '../../../utilities/_directives/customvalidator.directive';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { HttpServices } from '../../../utilities/_services/http.service';
import { MasterServices } from '../../../utilities/_services/master.service';
import { PageServices } from '../../../utilities/_services/page.service';
import { DynamicitemfieldComponent } from '../dynamicitemfield/dynamicitemfield.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { AllCommunityModules, GridOptions, Module } from '@ag-grid-community/all-modules';
import { QuanityUOMComponent } from '../gridComponent/quanity-uom.component';
import { InventoryItemSelectComponent } from '../gridComponent/inventoryitem-select.component';
import { NumericNegativeComponent } from '../gridComponent/numeric-negative.component';
import { AddtextdescComponent } from './addtextdesc/addtextdesc.component';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { ValidationService } from '../../../utilities/_common/validation.service';
import { PrintService } from '../../template/services/print.service';
import { NZTaxrateListServices } from '../../../utilities/_services/nztaxratelist.service copy';
import { NgbDate, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter } from '../../../utilities/_services/CustomDateAdapter';
import { CustomDateParserFormatter_FullDate } from '../../../utilities/_services/CustomDateParserFormatter';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HostService } from '../../../utilities/_services/host.serices';
import { NumericEditorComponent } from '../gridComponent/numeric-editor.component';
import { NumericEditorDecimalComponent } from '../gridComponent/numeric-editor-decimal.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import Cropper from 'cropperjs';
declare var $:any;

@Component({
  selector: 'app-additem',
  templateUrl: './additem.component.html',
  styleUrls: ['./additem.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: CustomDateAdapter },
  { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter_FullDate }]
})
export class AdditemComponent implements OnInit {
  @ViewChild(DynamicitemfieldComponent) dynamicItemField;
  @ViewChild('showaddItemmodal') public showaddItemmodal: ModalDirective;
  @Output() toParentAfterItemSave = new EventEmitter<String>();
  @Output() ItemClsCodeList = new EventEmitter<any[]>();
  @ViewChild('fileUploader') fileUploader: ElementRef;
  inventoryForm: FormGroup;
  submitted = false;
  itemloading = false;
  lstItemType: any = [];
  lstItemUnitType: any = [];
  lstProductCategory: any = [];
  lstPurchaseAccount: any = [];
  lstPurchaseTaxRate: any = [];
  lstSalesAccount: any = [];
  lstSalesTaxRate: any = [];
  lstInventoryAssAc: any = [];
  lstWareHouse: any = [];
  itemID: number = 0;
  callFrom: string = '';
  selecteditemTypeID: number = 0;
  successNotifierId: number = 0;
  warningNotifierId: number = 0;
  oldValue: any = null;
  //
  showNewProductBtn: boolean = false;
  filteredProduct: any;
  newAddedProductName = '';
  //
  modules: Module[] = AllCommunityModules;
  groupcolumnDefs: any;
  groupData: any[] = [];
  public progroupGridOptions: GridOptions;
  frameworkComponents: any;
  lstInventoryItem: any;
  infoNotifierId: any;
  errornotifierId: any;
  domLayout: string = "autoHeight";
  addNumberOfRows = [2, 3, 4, 5];
  isControl1 = false;
  isControl2 = false;
  isControl3 = false;
  isControl4 = false;
  isRadioButtonDisable = false;
  isEditAdvTracking = true;
  selectedAttributes:any;
  CheckEnableWareHouse:boolean=false;
  //Test Description
  @ViewChild(AddtextdescComponent) public addtextmodal: AddtextdescComponent;
  callDescFrom: string;
  enableHTMLDesc: boolean = false;
  lstTariffCode: any;
  curentUser: any;
  transTypeID: string;
  defatulSelection: string = 'BatchTracking';
  enableAdvInventory: boolean = false ? true : false;
  // isenableWarehouse: boolean =  false ? true : false;
  isEnableWarehouse: boolean =  false;
  currentUser: any;
  isDigiSME = false;
  openingBalanceDate: any;
  lstCostingType: any[] = [];
  isCostingOptionDisabled: boolean = false;
  CanEditInventoryAccount: boolean = false;
  ClassificationCode:any=[];
  isNegativeStockEnabled: boolean = false;
  fileAttachmentsData: any = [];
  fileData: any = 0;
  imageSrc: any;
  isUpload:boolean = false;
  imageAlreadyExists: boolean = false;
  isImageViewerOpen = false;
  fileID: any;
  fileName: string = '';
  filePath: string = '';
  isfileChanged: boolean = false;
  cropper: Cropper | null = null; 
  UploadedFileName: string; 
  clCode: string;
  isClCodeDisable: boolean = true;

  constructor(private formBuilder: FormBuilder, private http: HttpServices, private master: MasterServices,
    private page: PageServices, private alertMessage: AlertMessage, public datepipe: DatePipe, private local: LocalCacheServices,
    private validationService: ValidationService, private printService: PrintService, private nztaxrate: NZTaxrateListServices,
    private router: Router,private hostService:HostService,private _decimalPipe: DecimalPipe, private translate: TranslateService) {
    this.groupData = [];
    let orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    this.enableHTMLDesc = orgSettings.enableHTMLDesc;
    this.enableAdvInventory = orgSettings.enableAdvInventory;
      this.isEnableWarehouse = orgSettings.enableWarehouse;
      this.isNegativeStockEnabled = orgSettings.enableNegativeStock;
    this.isDigiSME = this.hostService.getDigisme();
    this.ItemType();
    this.ItemClassificationCode();
    this.ProductCategory();
    this.PurchaseAccount();
    this.PurchaseTaxRate();
    this.SalesAccount();
    this.SalesTaxRate();
    this.InventoryAssAc();
    this.LoadCostingType();


    this.curentUser = JSON.parse(
      this.local.getlocalStorage(this.local.localCurrentUserKey)
    );
    if (this.curentUser.orgCountryID === 129) {
      this.getTariffCodeList();
    }

  }
  async ItemType() {
    let licensetype=this.master.getlicensetype();
    let data: any = await this.http.getserviceawait(environment.inventoryApiUrl + '/Master/GetItemType/');
    if (!licensetype  && data.length > 2) {
      data.splice(2, 1)
    }
    this.lstItemType = data;
  }
  async ItemUnitType() {
    let data = await this.http.getserviceawait(environment.inventoryApiUrl + '/Master/GetItemUnitType/');
    this.lstItemUnitType = data;

    var itemUnitType = this.lstItemUnitType.filter(o => o.isDefault == 1) as any[];
    var itemUnitTypeID = (itemUnitType.length > 0 ? itemUnitType[0].itemUnitTypeID : 0);
    this.inventoryForm.patchValue({ itemUnitTypeID: itemUnitTypeID });
  }
  async ProductCategory() {
    let data = await this.http.getserviceawait(environment.inventoryApiUrl + '/Master/GetProductGroup/');
    this.lstProductCategory = data;
  }
  async PurchaseAccount() {
    this.lstPurchaseAccount = await this.master.GetPurchaseChartOfAccounts();
  }
  async PurchaseTaxRate() {
    this.lstPurchaseTaxRate = await this.master.getPOTaxRate();
  }
  async SalesAccount() {
    this.lstSalesAccount = await this.master.GetSalesChartOfAccounts();
  }
  async SalesTaxRate() {
    this.lstSalesTaxRate = await this.master.getSalesTaxRate();
  }

  async Warehouse() {
    let data = await this.http.getserviceawait(environment.inventoryApiUrl + '/Master/GetWarehouseList');
    this.lstWareHouse = data;
    this.lstWareHouse = JSON.parse(this.lstWareHouse.responseData);
    this.lstWareHouse = this.lstWareHouse[0].Warehouses.map(ele => <any>{
      WarehouseName: ele.WarehouseName,
      WarehouseId: ele.WarehouseID,
      IsActive: ele.IsActive,
      IsPrimary : ele.IsPrimary
    });
    this.lstWareHouse = this.lstWareHouse.filter(e => e.IsActive == 1);
    if(this.lstWareHouse != null && this.lstWareHouse.length > 0){
      this.selectedAttributes = [{
        WarehouseName:this.lstWareHouse[0].WarehouseName,
        WarehouseId:this.lstWareHouse[0].WarehouseId
      }];
    }
    //console.log(this.selectedAttributes);
  }

  async InventoryAssAc() {
    let newRowData: any = await this.master.GetChartOfAccounts();
    let invarrayList: any = [];
    for (var i = 0; i < newRowData.length; i++) {
      if (newRowData[i].AccountTypeName === "Inventory") {
        invarrayList.push(newRowData[i]);
      }
    }
    this.lstInventoryAssAc = invarrayList;
  }

  ngOnInit(): void {
    this.inventoryForm = this.formBuilder.group({
      itemID: [0],
      itemName: ['', CustomTxtValidator.required],
      itemCode: ['', CustomTxtValidator.required],
      itemTypeID: [0, CustomValidator.required],
      itemUnitTypeID: [0],
      productCategoryID: [0],
      itemGroupID: [0],
      openingBalanceUnit: [null],
      isIPurchase: [true],
      purchaseAccountID: [0],
      purchaseTaxID: [0],
      purchasePrice: [null],
      purchaseDescription: [''],
      purchaseHTMLDesc: [null],
      isISell: [true],
      salesAccountID: [0],
      salesTaxID: [0],
      salesPrice: [null],
      salesDescription: [''],
      salesHTMLDesc: [null],
      isTrackedItem: [false],
      openingBalanceUnitPrice: [null],
      isActive: [true],
      inventoryAssetAccountID: [0],
      openingBalanceDate: [null],
      orgID: [0],
      quantity: [0],
      userID: [0],
      groupDescription: [''],
      isHideUnitPrice: [false],
      groupList: null,
      customFieldValue: [null],
      minimumQuantity: [null],
      canDelete: [true],
      canEditTrackedItem: [true],
      tariffID: [null],
      enableAdvTracking: [false],
      // BatchTrackingType: ['BatchTracking'],
      isBatchTracking: [false],
      isSerialNoTracking: [false],
      warehouseID: [0],
      avgCostTypeID: [1],
      advancedInventoryTrackingType: [null],
        itemClassificationCode: ['022'],
        enableNegativeStock: [false]
    });
    if (this.currentUser.orgCountryID==129) {
      this.inventoryForm.controls['itemClassificationCode'].setValidators([Validators.required])
     }
    this.createColumnDef();
  }

  get f() { return this.inventoryForm.controls; }

  openItemModal(itemID, itemTypeID, callFrom, transTypeID?) {
    // this.inventoryForm.patchValue({
    //   avgCostTypeID: 1
    // });

    this.ItemUnitType();
    this.Warehouse();

    this.itemID = itemID;
    this.callFrom = callFrom;
    this.transTypeID = transTypeID > 0 ? transTypeID : 0;
    this.submitted = false;
    this.dynamicItemField.submitted = false;
    this.isfileChanged = false;
    this.imageSrc = '';

    if (this.itemID == 0) {
      this.inventoryForm.reset();
      this.formReset();
      this.dynamicItemField.dynamicform.reset();
      this.onChangeItemType(itemTypeID);
      this.showaddItemmodal.show();
      this.editRadioButton(false, false);
    }
    else {
      if (itemTypeID == 3) {
        this.getProductGroupItem();
      }
      else {
        this.getInventoryListByID();
        this.showaddItemmodal.show()
      }
    }
  }

  //Product category add new - start
  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.productCategoryName.toLocaleLowerCase().indexOf(term) > -1;
  }

  onSearchForApi($event) {
    this.showNewProductBtn = false;
    this.filteredProduct = null;
    let searchValue = $event.term.trim();
    if (searchValue.length >= 3) {
      this.filteredProduct = this.lstProductCategory.filter(x => x.productCategoryName.toLocaleLowerCase().trim() == searchValue.toLocaleLowerCase().trim());
      if (this.filteredProduct.length == 0) {
        this.showNewProductBtn = true;
        this.newAddedProductName = $event.term;
        searchValue == "";
      }
      else {
        this.showNewProductBtn = false;
      }
    } else {
      this.showNewProductBtn = false;
    }
  }

  addNewProduct(productCategory) {
    var isValid = this.validationService.verify(this.newAddedProductName);
    if (isValid) {
      let newProductgrp;
      let productRows = [];
      if (this.newAddedProductName !== "") {
        productRows =
          [{
            productCategoryID: 0,
            productCategoryName: this.newAddedProductName
          }]
        this.filteredProduct = productRows;
        newProductgrp = JSON.stringify(productRows[0]);
        let payload = { oldValue: null, newValue: newProductgrp, pageID: this.page.pageDetails.pageID }

        this.http.postservice(environment.inventoryApiUrl + '/Inventory/SaveProductGroup/SaveProductGroup', payload).subscribe({
          next: (data) => {
            this.showNewProductBtn = false;
            this.lstProductCategory.push({
              productCategoryID: data,
              productCategoryName: this.newAddedProductName,
            })
            this.ProductCategory();
            this.inventoryForm.patchValue({
              productCategoryID: data
            })
            this.newAddedProductName == "";
          }
        });
      }
      else {
        this.showNewProductBtn = false;
      }
    } else {
      let msg = this.translate.instant("InventoryModule.Item.Entered text is invalid due to security reasons");
      this.alertMessage.errorNotifier(msg, 0);
    }
  }
  //Product category add new - End

  onChangeItemType(itemTypeID: any) {
    if (this.selecteditemTypeID != itemTypeID) {
      this.selecteditemTypeID = itemTypeID;
      this.inventoryForm.patchValue({
        itemTypeID: itemTypeID,
        isTrackedItem: false
      });
      if (itemTypeID == 3) {
        this.groupData = [];
        this.addRows(4, '');
        this.getProductGroupItem();
      }
      this.onTrackItem();
      this.submitted = false;
      this.dynamicItemField.submitted = false;
      this.dynamicItemField.GetItemCustomFields(itemTypeID, 0);
    }
  }

  onChangeAccount(from) {
    if (from == "P") {
      let pt = this.lstPurchaseAccount.filter(x => x.CAAccountID == this.inventoryForm.value.purchaseAccountID)
      if (this.curentUser.orgCountryID === 153) {//Only for NZ Country
        if (pt.length > 0) {
          this.lstPurchaseTaxRate = this.nztaxrate.onNZTaxRateList(pt[0].AccGroupID);
        }
        let t = this.lstPurchaseTaxRate.filter(x => x.mstrTaxRateID == pt[0].TaxID);
        this.inventoryForm.patchValue({
          purchaseTaxID: t.length == 0 ? null : pt[0].TaxID,
        });
      }
      else {
        this.inventoryForm.patchValue({
          purchaseTaxID: pt[0].TaxID,
        });
      }
    }
    else if (from == "S") {
      let st = this.lstSalesAccount.filter(x => x.CAAccountID == this.inventoryForm.value.salesAccountID)
      if (this.curentUser.orgCountryID === 153) {//Only for NZ Country
        if (st.length > 0) {
          this.lstSalesTaxRate = this.nztaxrate.onNZTaxRateList(st[0].AccGroupID);
        }
        let t = this.lstSalesTaxRate.filter(x => x.mstrTaxRateID == st[0].TaxID);
        this.inventoryForm.patchValue({
          salesTaxID: t.length == 0 ? null : st[0].TaxID,
        });
      }
      else {
        this.inventoryForm.patchValue({
          salesTaxID: st[0].TaxID,
        });
      }
    }
  }

  async onTrackItem() {    
    const validateControlp = this.inventoryForm.get('purchaseAccountID');
    const validateControls = this.inventoryForm.get('salesAccountID');
    const validateControli = this.inventoryForm.get('inventoryAssetAccountID');
      const enableAdvInventoryControl = this.inventoryForm.get('enableAdvTracking');
      const enableNegativeStockControl = this.inventoryForm.get('enableNegativeStock');
    const CostingMethodControl = this.inventoryForm.get('avgCostTypeID');
    const enableWareHouseControl = this.inventoryForm.get('warehouseID');
    enableWareHouseControl.disable();   
    if (this.inventoryForm.value.isTrackedItem == false) {
      this.inventoryForm.patchValue({
        openingBalanceUnit: null,
        openingBalanceUnitPrice: null,
        inventoryAssetAccountID: 0,
        minimumQuantity: null,
        openingBalanceDate: null,
        enableAdvTracking: false,
        advancedInventoryTrackingType: null,
        warehouseID: 0,
          avgCostTypeID: null,
          enableNegativeStock: false
      });

        enableNegativeStockControl.disable();

      //Disable Advanced Tracking
      enableAdvInventoryControl.disable();
      this.isRadioButtonDisable = true;
       //Disable Advanced Tracking

      validateControlp.clearValidators();
      validateControls.clearValidators();
      validateControli.clearValidators();
      CostingMethodControl.clearValidators();
    }
    else if (this.inventoryForm.value.isTrackedItem == true) {
      await this.getMigrationDate();
      this.inventoryForm.patchValue({
        isIPurchase: true,
        isISell: true,
        avgCostTypeID: this.inventoryForm.get('avgCostTypeID').value == null ? 1 : this.inventoryForm.get('avgCostTypeID')?.value
      });

        if(this.inventoryForm.get('quantity').value>=0)
        {
            enableNegativeStockControl.enable();
            if (this.itemID == 0 && this.isNegativeStockEnabled && this.page.accessRights.canApprove == true) {
                this.inventoryForm.patchValue({
                    enableNegativeStock: true
                });
            }
        }
        else{
            enableNegativeStockControl.disable();
        }

      if (this.itemID == 0 || this.inventoryForm.value.openingBalanceDate == null) {
        this.inventoryForm.patchValue({
          openingBalanceDate: this.openingBalanceDate ?? null
        });
      }

      if (this.callFrom == 'AN') {
        this.inventoryForm.patchValue({
          avgCostTypeID: 1
          // enableAdvTracking :true,
        })
      }

      if (this.inventoryForm.controls.enableAdvTracking.value == true) {
        if (this.callFrom == 'E') {
          if (this.isRadioButtonDisable)
            enableAdvInventoryControl.disable();
          const isBatchTracking = this.inventoryForm.get('isBatchTracking').value;
          const isSerialNoTracking = this.inventoryForm.get('isSerialNoTracking').value;
          this.editRadioButton(isBatchTracking, isSerialNoTracking);
          enableWareHouseControl.enable();
        } else {
          this.inventoryForm.patchValue({
            isBatchTracking: false,
            isSerialNoTracking: false
          });
          enableAdvInventoryControl.enable();
          this.isRadioButtonDisable = false;
        }
      }              
      

      if (this.isEditAdvTracking) {
        enableAdvInventoryControl.enable();
        this.isRadioButtonDisable = false;
      }

      validateControlp.setValidators([CustomValidator.required]);
      validateControls.setValidators([CustomValidator.required]);
      validateControli.setValidators([CustomValidator.required]);
      CostingMethodControl.setValidators([CustomValidator.required]);
    }
    validateControlp.updateValueAndValidity();
    validateControls.updateValueAndValidity();
    validateControli.updateValueAndValidity();
    enableWareHouseControl.updateValueAndValidity();
    CostingMethodControl.updateValueAndValidity();
    this.formConditionalRequired();
  }

  onthisItem(from) {
    if (from == "P") {
      if (this.inventoryForm.value.isIPurchase == false) {
        this.inventoryForm.patchValue({
          purchaseAccountID: 0,
          purchaseTaxID: 0,
          purchasePrice: null,
          purchaseDescription: "",
          purchaseHTMLDesc: null,
          isTrackedItem: false,
        });
      }
    }
    else if (from == "S") {
      if (this.inventoryForm.value.isISell == false) {
        this.inventoryForm.patchValue({
          salesAccountID: 0,
          salesTaxID: 0,
          salesPrice: null,
          salesDescription: "",
          salesHTMLDesc: null,
          isTrackedItem: false,
        });
      }
    }

    if (this.inventoryForm.value.isIPurchase == true && this.inventoryForm.value.itemTypeID != 3 && this.itemID == 0) {
      this.inventoryForm.patchValue({
        purchaseDescription: this.inventoryForm.value.itemName,
      });

    }
    if (this.inventoryForm.value.isISell == true && this.inventoryForm.value.itemTypeID != 3 && this.itemID == 0) {

      this.inventoryForm.patchValue({
        salesDescription: this.inventoryForm.value.itemName,
      });

    }

    this.isClCodeDisable = (this.inventoryForm.value.itemName != null && this.inventoryForm.value.itemName != '') ? false : true;
  }

  getInventoryListByID() {
    this.http.getservice(environment.inventoryApiUrl + '/Inventory/GetInventoryDetailByID?ItemID=' + this.itemID).subscribe({
      next: (data) => {
        if (data["openingBalanceDate"] != null) {
          data["openingBalanceDate"] = this.datepipe.transform(data["openingBalanceDate"], 'yyyy/MM/dd');
          if (data["canEditTrackedItem"] == false) {
            this.isControl4 = true;
          }
        }
        if (data["canEditTrackedItem"] == false) {
          if (data["inventoryAssetAccountID"] > 0) {
            this.isControl1 = true;
          }
          if (data["openingBalanceUnitPrice"] != null) {
            this.isControl2 = true;
          }
          if (data["openingBalanceUnit"] != null) {
            this.isControl3 = true;
          }
        }
        if (data["canEditInventoryAccount"] != null) {
          this.CanEditInventoryAccount = data["canEditInventoryAccount"];
        }

        //Item Image Details
        this.imageSrc = data["fileURL"];
        this.fileID = data["fileID"];
        this.fileName = data["fileName"];
        this.filePath = data["filePath"];
        if (!!this.imageSrc) {
            this.imageAlreadyExists = true;
        }

        if (data["itemTypeID"] == 3) {
          this.groupData = JSON.parse(data["productGroupItem"]);
          this.groupData = this.groupData.map((ele, ind) => ({
            ItemGroupID: ele.ItemGroupID,
            GroupItemID: ele.GroupItemID,
            ItemID: ele.ItemID,
            ItemCode: ele.ItemCode,
            ItemName: ele.ItemName,
            PurchaseTaxID: ele.PurchaseTaxID,
            PurchaseAccountID: ele.PurchaseAccountID,
            PurchaseDescription: ele.PurchaseDescription,
            PurchasePrice: ele.PurchasePrice,
            Isactive: ele.Isactive,
            IsIPurchase: ele.IsIPurchase,
            IsTrackedItem: ele.IsTrackedItem,
            InventoryAssetAccountID: ele.InventoryAssetAccountID,
            CreatedDate: ele.CreatedDate,
            ItemUnitTypeID: ele.ItemUnitTypeID,
            ItemUnitType: ele.ItemUnitType,
            ProductCategoryID: ele.ProductCategoryID,
            ProductCategoryName: ele.ProductCategoryName,
            ItemTypeID: ele.ItemTypeID,
            Quantity: ele.Quantity,
            SellingPrice: ele.SellingPrice,
            PGPurchasePrice: ele.PGPurchasePrice,
            SalesTaxID: ele.SalesTaxID,
            SalesAccountID: ele.SalesAccountID,
            SalesDescription: ele.SalesDescription,
            SalesPrice: ele.SalesPrice,
            IsISell: ele.IsISell,
            PurchaseHTMLDesc: ele.PurchaseHTMLDesc,
            SalesHTMLDesc: ele.SalesHTMLDesc,
            TariffID: ele.TariffID,
            IsHideFullRow: ele.IsHideFullRow,
            //EnableAdvTracking:ele.EnableAdvTracking,
            Index: ind
          }));
        }
        // const isSerialNoTracking = this.inventoryForm.get('isSerialNoTracking');
        // const isBatchTracking = this.inventoryForm.get('isBatchTracking');
        if (data["isEditAdvTracking"] == false) {
          this.isEditAdvTracking = false;
          this.isRadioButtonDisable = true;
          data["canEditTrackedItem"] = false;
          if (data["isBatchTracking"] == true) {
            // isSerialNoTracking.disable();
          }
          else if (data["isSerialNoTracking"] == true) {
            // isBatchTracking.disable();
          }
        }
        this.inventoryForm.patchValue(data);
        if (data['inventoryAdjTable'].length > 0) {
          this.isCostingOptionDisabled = true;
        }

        let purchase_price;
        let sales_price;
        let openingBalanceUnit_Price;
        if (data['purchasePrice'] != null) {
        let decimalValuepp = data['purchasePrice'].toString().indexOf(".");
        let resultpp = data['purchasePrice'].toString().substring(decimalValuepp+1);
        if(resultpp.length <2){
          purchase_price = data['purchasePrice'].toFixed(2);
        }else{
          purchase_price =data['purchasePrice']
        }
        }

        if (data['salesPrice'] != null) {
          let decimalValuesp = data['salesPrice'].toString().indexOf(".");
          let resultsp = data['salesPrice'].toString().substring(decimalValuesp + 1);
          if (resultsp.length < 2) {
            sales_price = data['salesPrice'].toFixed(2);
          } else {
            sales_price = data['salesPrice']
          }
        }

        if (data['openingBalanceUnitPrice'] != null) {
          let decimalValueob = data['openingBalanceUnitPrice'].toString().indexOf(".");
          let resultob = data['openingBalanceUnitPrice'].toString().substring(decimalValueob + 1);
          if (resultob.length < 2) {
            openingBalanceUnit_Price = data['openingBalanceUnitPrice'].toFixed(2);
          } else {
            openingBalanceUnit_Price = data['openingBalanceUnitPrice']
          }

        }

        if(data['itemName'] != null){
          this.isClCodeDisable = false;
        }

        this.inventoryForm.patchValue({
          purchasePrice :  data['purchasePrice'] != null ? purchase_price :data['purchasePrice'],
          salesPrice: data['salesPrice'] != null ? sales_price :data['salesPrice'],
          openingBalanceUnitPrice: data['openingBalanceUnitPrice'] != null ? openingBalanceUnit_Price :data['openingBalanceUnitPrice'],

        });
        this.oldValue = this.inventoryForm.value;
        this.dynamicItemField.submitted = false;
        this.selecteditemTypeID = this.inventoryForm.value.itemTypeID;
        this.dynamicItemField.GetItemCustomFields(this.inventoryForm.value.itemTypeID, this.itemID);
        this.onTrackItem()
      }
    });
  }



  numberFormatToFixed(val: Number) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    });
    let toFixed: Number = val ? val : 0;
    return formatter.format(Number(toFixed))
  }

  onSubmit(action: string) {
    this.submitted = true;
    this.dynamicItemField.submitted = true;
    if (this.dynamicItemField.lstCustomFields.length == 0) {
      if (this.inventoryForm.invalid) {
        return;
      }
    }
    else if (this.dynamicItemField.lstCustomFields.length > 0) {
      if (this.inventoryForm.invalid || this.dynamicItemField.dynamicform.invalid) {
        return;
      }
      else {
        let customFieldValue = [];
        for (let i = 0; i < this.dynamicItemField.dynamicform.value.fieldArray.length; i++) {
          let key = Object.keys(this.dynamicItemField.dynamicform.value.fieldArray[i])[0];
          let values = Object.values(this.dynamicItemField.dynamicform.value.fieldArray[i])[0];
          let fieldobj = this.dynamicItemField.lstCustomFields.filter(x => x.fieldID == Number(key));
          if (fieldobj[0].fieldTypeID == 2) {
            let txt = null;
            txt = fieldobj[0].customValue.filter(c => c.CustomValueID == values);
            if (txt.length == 0) {
              txt = null;
            }
            else {
              txt = txt[0].CustomValueText;
            }

            customFieldValue.push({
              fieldID: key,
              customValueText: txt,
              customValueID: values,
            })
          }
          else {
            customFieldValue.push({
              fieldID: key,
              customValueText: values,
              customValueID: null,
            })
          }
        }

        this.inventoryForm.patchValue({
          customFieldValue: JSON.stringify(customFieldValue),
        });
      }
    }
    this.submitted = false;
    this.dynamicItemField.submitted = false;

    if (this.inventoryForm.value.itemTypeID == 3) {
      let newPGitems = this.groupData.filter(x => x.ItemID !== null && x.ItemID > 0);
      if (newPGitems.length == 0) {
        let msg = this.translate.instant("InventoryModule.Item.All item rows cannot be empty");
        this.errornotifierId = this.alertMessage.errorNotifier(msg, this.errornotifierId);
        return;
      }
      if (newPGitems.filter(x => x.Quantity == null || x.Quantity == '' || x.Quantity == 0).length > 0) {
        let msg = this.translate.instant("InventoryModule.Item.Quantity cannot be empty or zero");
        this.errornotifierId = this.alertMessage.errorNotifier(msg, this.errornotifierId);
        return;
      }


      // if (newPGitems.filter(x => x.SellingPrice == null || x.SellingPrice == '' || x.SellingPrice == 0).length > 0) {
      //   this.errornotifierId = this.alertMessage.errorNotifier("Selling Price cannot be empty or zero", this.errornotifierId);
      //   return;
      // }
      // if (newPGitems.filter(x => x.PGPurchasePrice == null || x.PGPurchasePrice == '' || x.PGPurchasePrice == 0).length > 0) {
      //   this.errornotifierId = this.alertMessage.errorNotifier("Purchase Price cannot be empty or zero", this.errornotifierId);
      //   return;
      // }


      {
        this.inventoryForm.patchValue({
          groupList: newPGitems,
        });
      }
    }
    if(this.inventoryForm.value.isTrackedItem){
      if((this.inventoryForm.value.openingBalanceUnitPrice * this.inventoryForm.value.openingBalanceUnit) > 922337203685477){
        let msg = this.translate.instant("Total Stock Amount (Opening Cost Per Unit * Opening Stock)  should be less than 922,337,203,685,477");
          this.errornotifierId = this.alertMessage.errorNotifier(msg, this.errornotifierId);
          return;
      }
    }

    // if (this.inventoryForm.value.itemTypeID == 1 && this.inventoryForm.value.enableAdvTracking == true) {
    //   if (this.inventoryForm.controls.isBatchTracking.value == false && this.inventoryForm.controls.isSerialNoTracking.value == false) {
    //     this.errornotifierId = this.alertMessage.errorNotifier("Select atleast any one trackingtype", this.errornotifierId);
    //     return;
    //   }
    // }

    let openingBalanceDate = null;
    if (!!this.inventoryForm.value.openingBalanceDate) {
      openingBalanceDate = this.DBDateFormat(this.inventoryForm.value.openingBalanceDate)  // moment(this.inventoryForm.value.openingBalanceDate).format('YYYY-MM-DD')
    }
    else {
      openingBalanceDate = null;
    }
    if (!!this.oldValue) {
      if (!!this.oldValue.openingBalanceDate) {
        this.oldValue.openingBalanceDate = this.DBDateFormat(this.oldValue.openingBalanceDate) // moment(this.inventoryForm.value.openingBalanceDate).format('YYYY-MM-DD')
      }
      else {
        this.oldValue.openingBalanceDate = null;
      }
    }

    this.inventoryForm.patchValue({
      purchasePrice: this.inventoryForm.value.purchasePrice ? this.inventoryForm.value.purchasePrice : null,
      salesPrice: this.inventoryForm.value.salesPrice ? this.inventoryForm.value.salesPrice : null,
      openingBalanceUnit: this.inventoryForm.value.openingBalanceUnit ? this.inventoryForm.value.openingBalanceUnit : null,
      openingBalanceUnitPrice: this.inventoryForm.value.openingBalanceUnitPrice ? this.inventoryForm.value.openingBalanceUnitPrice : null,
      warehouseID: Number(this.inventoryForm.value.warehouseID) > 0 ? this.inventoryForm.value.warehouseID : null
    });

    //Added for encoding
    if (this.inventoryForm.value.purchaseHTMLDesc != undefined && this.inventoryForm.value.purchaseHTMLDesc != null) {
      this.inventoryForm.value.purchaseHTMLDesc = this.printService.returnEncodedString(this.inventoryForm.value.purchaseHTMLDesc);
    } if (this.inventoryForm.value.salesHTMLDesc != undefined && this.inventoryForm.value.salesHTMLDesc != null) {
      this.inventoryForm.value.salesHTMLDesc = this.printService.returnEncodedString(this.inventoryForm.value.salesHTMLDesc);
    }

    if (this.inventoryForm.value.groupList != undefined && this.inventoryForm.value.groupList != null) {
      this.inventoryForm.value.groupList.forEach(item => {
        if (item.PurchaseHTMLDesc != undefined && item.PurchaseHTMLDesc != null) {
          item.PurchaseHTMLDesc = this.printService.returnEncodedString(item.PurchaseHTMLDesc);
        } if (item.SalesHTMLDesc != undefined && item.SalesHTMLDesc != null) {
          item.SalesHTMLDesc = this.printService.returnEncodedString(item.SalesHTMLDesc);
        }
      });
    }

    if (!!this.oldValue) {
      if (this.oldValue.purchaseHTMLDesc != undefined && this.oldValue.purchaseHTMLDesc != null) {
        this.oldValue.purchaseHTMLDesc = this.printService.returnEncodedString(this.oldValue.purchaseHTMLDesc);
      } if (this.oldValue.salesHTMLDesc != undefined && this.oldValue.salesHTMLDesc != null) {
        this.oldValue.salesHTMLDesc = this.printService.returnEncodedString(this.oldValue.salesHTMLDesc);
      }
    }
    //console.log(this.inventoryForm.value);
    //console.log(this.oldValue);
    //Added for encoding
      let newValue = this.inventoryForm.getRawValue();
      newValue.purchaseHTMLDesc=this.inventoryForm.value.purchaseHTMLDesc;
      newValue.salesHTMLDesc=this.inventoryForm.value.salesHTMLDesc;
    newValue.openingBalanceDate = openingBalanceDate;
    newValue.isBatchTracking = newValue.advancedInventoryTrackingType == 'batchTracking' ? true : false;
    newValue.isSerialNoTracking = newValue.advancedInventoryTrackingType == 'serialTracking' ? true : false;
    newValue.enableAdvTracking = (newValue.isBatchTracking || newValue.isSerialNoTracking) ? true : false;
    let licensetype=this.master.getlicensetype('trackeditem');
    let inventory = { oldvalue: this.oldValue, newvalue: newValue, pageID: this.page.pageDetails.pageID }
    if(newValue.minimumQuantity == ''){
        newValue.minimumQuantity = null;
    }
    if ((this.inventoryForm.value.itemTypeID == 1) && ((this.inventoryForm.value.itemID == 0 || (this.inventoryForm.value.itemID > 0 && this.oldValue.isTrackedItem == 1)) && this.inventoryForm.value.isTrackedItem == 0) && licensetype ) {
      Swal.fire({
        title: this.translate.instant("InventoryModule.Item.Do you wish to proceed?"),
        text: this.translate.instant("InventoryModule.Item.Tracking is not enabled. System will not track available quantity, opening/closing balance for untracked items."),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant("Common.Yes"),
        cancelButtonText: this.translate.instant("Common.No")
      }).then((result) => {
        if (result.value) {
          this.callItemsSaveService(inventory, action);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          return false;
        }
      });
    }
    else {
      this.UpdateOpeningBalanceDate().then((r) => {
        if (r.result == 'Accepted') {
          inventory.newvalue.openingBalanceDate = this.DBDateFormat(this.openingBalanceDate);
          this.callItemsSaveService(inventory, action);
        } else if (r.result == 'Cancel') {
          this.callItemsSaveService(inventory, action);
        }
      });
    }
  }

  callItemsSaveService(inventory: any, action: string) {
    this.itemloading = true;
    this.http.postservice(environment.inventoryApiUrl + '/Inventory/SaveUpdateInventory', inventory).subscribe({
      next: (data) => {
        if (data['isSuccess'] == true) {
          let splitMsg = data["statusMessage"].split('_')[1];
          this.itemID = Number(splitMsg);
          let selecteditemTypeID = this.inventoryForm.value.itemTypeID;
          // Image upload
          if (this.imageSrc != '' && this.isfileChanged) {
           this.fileAttachmentsData.forEach((u) => u.transactionID = this.itemID);
           this.fileUpload(this.fileAttachmentsData,this.itemID);
          }
          // Image delete
          if (this.fileID != 0 && this.imageAlreadyExists && this.isfileChanged) {
            this.deleterecord(this.fileID, this.fileName, this.filePath)
          }
         // End Image upload
          if (this.inventoryForm.value.enableAdvTracking != undefined && selecteditemTypeID == 1 && this.inventoryForm.value.enableAdvTracking == true) {
            this.showManageBatchTrackingPopup(this.itemID);
          } else {
            let msg = this.translate.instant("InventoryModule.Item.You submitted successfully");
            this.successNotifierId = this.alertMessage.successNotifier(msg, this.successNotifierId);
            let notificationMessage = this.costingNotificationMessage();
            this.successNotifierId = this.alertMessage.successNotifier(msg, this.successNotifierId);
          }
          this.itemloading = false;
          if (this.callFrom == 'TA' && this.itemID > 0) {
            let apiUrl = environment.inventoryApiUrl + '/Inventory/GetInventoryDetailByID?ItemID=' + this.itemID;
            this.http.getservice(apiUrl).subscribe({
              next: (data1) => {
                const invItems: any = data1;
                this.toParentAfterItemSave.emit(invItems);
              }
            });
          }
          else if (this.callFrom == 'NTA' && this.itemID > 0) {
            let apiUrl = environment.inventoryApiUrl + '/Inventory/GetItems?TransactionTypeID=' + this.transTypeID + '&ItemID=' + this.itemID;
            this.http.getservice(apiUrl).subscribe({
              next: (data1) => {
                const invItems: any = data1;
                this.toParentAfterItemSave.emit(invItems[0]);
              }
            });
          }
          else {
            if(this.imageSrc == '' || !this.imageSrc || this.isfileChanged == false){
              this.toParentAfterItemSave.emit(this.itemID.toString());
            }
          }

          this.inventoryForm.reset();
          this.formReset();
          this.dynamicItemField.dynamicform.reset();

          if (action == "savenaddanother") {
            this.inventoryForm.patchValue({
              itemTypeID: selecteditemTypeID
            });
            if (selecteditemTypeID == 3) {
              this.addRows(4, '');
            }
            this.onTrackItem();
            this.showaddItemmodal.show();
          }
          else {
            if (this.imageSrc == '' || !this.imageSrc || this.isfileChanged == false) {
              this.showaddItemmodal.hide();
            }
          }
        }
        else if (data['isSuccess'] == false) {
          this.submitted = false;
          this.itemloading = false;
          this.warningNotifierId = this.alertMessage.errorNotifier(data["statusMessage"], this.warningNotifierId);
          this.showaddItemmodal.show();
        }
      }
    });
  }

  formReset() {
    this.submitted = false;
    this.itemloading = false;
    this.selecteditemTypeID = 0;
    this.itemID = 0;
    this.groupData = [];

    var itemUnitType = this.lstItemUnitType.filter(o => o.isDefault == 1) as any[];
    var itemUnitTypeID = (itemUnitType.length > 0 ? itemUnitType[0].itemUnitTypeID : 0);

    this.inventoryForm.patchValue({
      itemID: 0,
      itemName: '',
      itemCode: '',
      itemTypeID: 0,
      itemUnitTypeID: itemUnitTypeID,
      productCategoryID: 0,
      itemGroupID: 0,
      openingBalanceUnit: null,
      isIPurchase: true,
      purchaseAccountID: 0,
      purchaseTaxID: 0,
      purchasePrice: null,
      purchaseDescription: '',
      purchaseHTMLDesc: null,
      isISell: true,
      salesAccountID: 0,
      salesTaxID: 0,
      salesPrice: null,
      salesDescription: '',
      salesHTMLDesc: null,
      isTrackedItem: false,
      openingBalanceUnitPrice: null,
      isActive: true,
      inventoryAssetAccountID: 0,
      openingBalanceDate: null,
      orgID: 0,
      quantity: 0,
      userID: 0,
      groupDescription: '',
      isHideUnitPrice: false,
      groupList: null,
      customFieldValue: null,
      minimumQuantity: null,
      canDelete: true,
      canEditTrackedItem: true,
      tariffID: null,
      isBatchTracking: true,
      isSerialNoTracking: true,
      isenableWarehouse:true
    })
    $('#fileUpload').val('');
    this.closeCropImage();
  }

  hideaddItemmodal() {
    this.formReset()
    this.showaddItemmodal.hide();
  }

  formConditionalRequired() {
    const openingBalanceDate = this.inventoryForm.get('openingBalanceDate');
    const openingBalanceUnitPrice = this.inventoryForm.get('openingBalanceUnitPrice');
    const openingBalanceUnit = this.inventoryForm.get('openingBalanceUnit');

    if (this.inventoryForm.value.isTrackedItem == true) {
      openingBalanceDate.enable();
      openingBalanceUnitPrice.enable();
      openingBalanceUnit.enable();
    }
    else {
      openingBalanceDate.disable();
      openingBalanceUnitPrice.disable();
      openingBalanceUnit.disable();
    }

    openingBalanceDate.clearValidators();
    openingBalanceUnitPrice.clearValidators();
    openingBalanceUnit.clearValidators();

    // openingBalanceDate.setValidators([Validators.pattern(DateRegEx.pattern)]);
    //openingBalanceUnit.setValidators([Validators.pattern(Decimal2RegEx.pattern)]);
    //openingBalanceUnitPrice.setValidators([Validators.pattern(Decimal2RegEx.pattern)]);

    if (this.inventoryForm.value.openingBalanceUnitPrice >= 0 && this.inventoryForm.value.openingBalanceUnitPrice != "" && this.inventoryForm.value.openingBalanceUnitPrice != null && this.inventoryForm.value.openingBalanceUnitPrice != undefined) {
        openingBalanceDate.setValidators([Validators.required]);
      /*
        if(this.inventoryForm.value.enableNegativeStock==false){
            openingBalanceUnit.setValidators([Validators.required, Validators.min(0)]);
        }
        else{
            openingBalanceUnit.setValidators([Validators.required]);
        }
      */
    }

    if (this.inventoryForm.value.openingBalanceUnit > 0) {
      openingBalanceDate.setValidators([Validators.required]);
      openingBalanceUnitPrice.setValidators([CustomValidator.requiredAllowZero]);
    }
    
    if(Number(this.inventoryForm.value.openingBalanceUnit) === 0 && Number(this.inventoryForm.value.openingBalanceUnitPrice) !== 0) {
      openingBalanceUnitPrice.setValidators([Validators.max(0)]);
    }
    // validate2.updateValueAndValidity();
    // validate3.updateValueAndValidity();

    // const validate4 = this.inventoryForm.get('isBatchTracking');
    // const validate5 = this.inventoryForm.get('')

    // openingBalanceDate.updateValueAndValidity();
    let OBDateCondition = this.inventoryForm.value.openingBalanceUnitPrice > 0 || this.inventoryForm.value.openingBalanceUnit > 0;
    this.openingBalanceDateValidition(openingBalanceDate, OBDateCondition);
    openingBalanceUnitPrice.updateValueAndValidity();
    openingBalanceUnit.updateValueAndValidity();
  }

  //Product Group
  createColumnDef() {
    this.progroupGridOptions = <GridOptions>{
      sortable: false,
      filter: false,
      wrapText: true,
      autoHeight: true,
      onGridReady: (params: any) => {
        this.progroupGridOptions.api.sizeColumnsToFit();
        params.api.showLoadingOverlay();
      },
      onGridSizeChanged: () => {
        this.progroupGridOptions.api.sizeColumnsToFit();
      }
    }

    this.frameworkComponents = {
      itemEditor: InventoryItemSelectComponent,
      qtyEditor: QuanityUOMComponent,
      numberEditer: NumericNegativeComponent
    };


    this.groupcolumnDefs = [
      {
        headerName: "Items",
        field: "ItemID",
        editable: true,
        cellEditor: "itemEditor",
        cellEditorParams: {
          lstInventoryItem: [],
          page: 'Item',
        },
        headerValueGetter:(params)=>this.translate.instant("Items"),
        cellRenderer: (params: any) => {
          return this.lookupValue(this.lstInventoryItem, params);
        }
      },
      {
        headerName: "Qty",
        field: "Quantity",
        editable: true,
        type: 'rightAligned',
        cellEditor: "qtyEditor",
        cellEditorParams: {
          decimalType : 'Decimal10'
        },
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Qty"),
        cellRenderer: (params) => {
          if (params.data.ItemUnitType != null && params.data.ItemUnitType != "") {
            return '<div >' + params.value + '</div><div class="badge badge-pill badge-success text-wrap">' + params.data.ItemUnitType + '</div>';
          }
          else {
            return params.value;
          }
        },
      },
      {
        headerName: "Purchase Unit Price",
        field: "PGPurchasePrice",
        editable: true,
        type: 'rightAligned',
        cellEditor: "numberEditer",
        cellEditorParams: {
          decimalType : 'decimal4'
        },
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Purchase Unit Price"),
        valueFormatter: (params: any) => {
          return this.numberFormatToFixed(params.value);
        },
      },
      {
        headerName: "Selling Unit Price",
        field: "SellingPrice",
        editable: true,
        type: 'rightAligned',
        cellEditor: "numberEditer",
        cellEditorParams: {
          decimalType : 'decimal4'
        },
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Selling Unit Price"),
        valueFormatter: (params: any) => {
          return this.numberFormatToFixed(params.value);
        },
      },
      {
        headerName: "Hide Row From Print",
        field: "IsHideFullRow",
        width: 120, maxWidth: 120,
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Hide Row From Print"),
        cellRenderer: (params) => {
          if (params.node.data.IsHideFullRow) {
            return params.node.setSelected(true);
          };
        },
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: "",
        field: "Delete",
        editable: false,
        width: 25,
        maxWidth: 25,
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params) => {
          return '<span class="close-x" data-action="delete">x</span>';
        }
      }
    ]

  }
  onSelectionChanged(params: any) {
    let selectedcell = params.api.getSelectedRows();
    for (let i = 0; i <= this.groupData.length - 1; i++) {
      this.groupData[i].IsHideFullRow = false;
      for (let j = 0; j <= selectedcell.length - 1; j++) {
        if (this.groupData[i].Index == params.api.getSelectedRows()[j].Index) {
          this.groupData[i].IsHideFullRow = true;
        }
      }
    }
    params.api.refreshCells({
      force: true,
    });
  }
  onprogroupGridReady(params: any) {
    this.progroupGridOptions.api.sizeColumnsToFit();
  }
  lookupValue(mappings: any, params: any) {
    if (params.value == null || params.value == "" || params.value == undefined || mappings == null) {
      return;
    }
    else {
      let index = 0;
      if (params.column.colId == 'ItemID') {
        index = mappings.findIndex(item => item.itemID == params.value);
        if (index !== -1) {
          if (mappings[index].productCategoryName.length > 0) {
            params.data.ProductCategoryID = mappings[index].productCategoryID;
            return '<div >' + mappings[index].itemName + '</div><div class="badge badge-pill badge-success text-wrap">' + mappings[index].productCategoryName + '</div>';
          }
          else
            return mappings[index].itemName;
        } else {
          return "";
        }
      }
    }
  }
  getProductGroupItem() {
    this.http.getservice(environment.inventoryApiUrl + '/Inventory/GetInventoryList?Active=Active').subscribe({
      next: (data: any) => {
        this.lstInventoryItem = data.filter(x => x.isActive == 1 && (x.isIPurchase == true || x.isISell == true));
        this.setNameCellEditorValues(this.lstInventoryItem);
        if (this.itemID > 0) {
          setTimeout(() => {
            this.getInventoryListByID();
            this.showaddItemmodal.show()
          }, 200);
        }
      },
    });
  }
  setNameCellEditorValues(values) {
    let colDefs: any = this.progroupGridOptions.api.getColumnDefs();
    colDefs[0].cellEditorParams.lstInventoryItem = values;
    this.progroupGridOptions.api.setColumnDefs(colDefs);
  }
  ongroupCellValueChanged(params: any) {
    const colId = params.column.getId();
    if (colId === "ItemID") {
      let itemValue = this.lstInventoryItem.filter(item => item.itemID == params.value);
      if (itemValue?.length == 1) {
        this.groupData[params.node.childIndex].ItemID = itemValue[0].itemID;
        this.groupData[params.node.childIndex].Quantity = 1;
        this.groupData[params.node.childIndex].PGPurchasePrice = itemValue[0].purchasePrice;
        this.groupData[params.node.childIndex].SellingPrice = itemValue[0].salesPrice;
        this.groupData[params.node.childIndex].ItemUnitType = itemValue[0].itemUnitType;
        this.groupData[params.node.childIndex].IsHideFullRow = false;
      }
      params.api.refreshCells({
        force: true,
      });
    }
  }
  ongroupCellClicked(params: any) {
    const colId = params.column.getId();
    if (colId === "Delete") {
      if (this.groupData.length == 1) {
        let msg = this.translate.instant("InventoryModule.Item.You must have at least 1 items.");
        this.infoNotifierId = this.alertMessage.warningNotifier(msg, this.infoNotifierId)
      }
      else {
        const selectedRow = this.progroupGridOptions.api.getFocusedCell();
        this.groupData.splice(selectedRow.rowIndex, 1);
        this.progroupGridOptions.api.setRowData(this.groupData);
        this.progroupGridOptions.api.sizeColumnsToFit();
      }
    }
  }
  addRows(id, action): void {
    for (let i = 1; i <= id; i++) {
      let rowFeild = {
        ItemID: '',
        Quantity: '',
        PGPurchasePrice: '',
        SellingPrice: '',
        ItemUnitType: '',
        IsHideFullRow: '',
        Index: this.groupData.length
      }
      this.groupData.push(rowFeild);
      this.progroupGridOptions.api.addItems([rowFeild]);
    }
  }

  //Text Description
  addMore(callDescFrom) {
    this.callDescFrom = callDescFrom;
    let htmlText = null;
    if (callDescFrom == 'P') {
      htmlText = this.inventoryForm.value.purchaseHTMLDesc;
    }
    else if (callDescFrom == 'S') {
      htmlText = this.inventoryForm.value.salesHTMLDesc
    }
    this.addtextmodal.openTextModal(htmlText, 'IA');
  }

  returnTextDescription(htmlText) {
    if (this.callDescFrom == 'P') {
      this.inventoryForm.patchValue({
        purchaseHTMLDesc: htmlText
      });
    }
    else if (this.callDescFrom == 'S') {
      this.inventoryForm.patchValue({
        salesHTMLDesc: htmlText
      });
    }
  }

  getTariffCodeList() {
    this.http.getservice(environment.organizationApiUrl + '/TariffCode/GetTariffCodeList').subscribe({
      next: (data) => {
        this.lstTariffCode = data
      }
    });
  }
  // OnRadioButtonChange(selectedRadioButton){
  //   debugger
  //   if(selectedRadioButton.value.toString().toLowerCase() === 'batchtracking'){
  //     this.inventoryForm.isBatchTracking = true
  //     this.inventoryForm.isBatchTracking = ""
  //   }
  //   else if(selectedRadioButton.value.toString().toLowerCase() === 'serialtracking'){
  //     this.inventoryForm.isSerialNoTracking = true
  //     this.inventoryForm.isSerialNoTracking = ""
  //   }
  // }
  OnRadioButtonChange(selectedRadioButton) {
    if (selectedRadioButton.value.toString().toLowerCase() === 'batchtracking') {
      this.inventoryForm.patchValue({
        isBatchTracking: true,
        isSerialNoTracking: false
      })
    }
    else if (selectedRadioButton.value.toString().toLowerCase() === 'serialtracking') {
      this.inventoryForm.patchValue({
        isBatchTracking: false,
        isSerialNoTracking: true
      })
    }
    else if (this.inventoryForm.value.enableAdvTracking == false) {
      this.inventoryForm.patchValue({
        isBatchTracking: false,
        isSerialNoTracking: false
      })

    }
  }

  editRadioButton(isBatchTracking, isSerialNoTracking) {
    if (isBatchTracking) {
      this.inventoryForm.patchValue({
        advancedInventoryTrackingType: 'batchTracking'
      });
    } else if (isSerialNoTracking) {
      this.inventoryForm.patchValue({
        advancedInventoryTrackingType: 'serialTracking'
      });
    } else {
      this.inventoryForm.patchValue({
        advancedInventoryTrackingType: null
      });
    }
  }

  onChangeAdvanceTracking(eventSelection) {    
    const enableWareHouseControl = this.inventoryForm.get('warehouseID');
    let primaryWarehouseID = 0;
    if (this.inventoryForm.controls.enableAdvTracking.value == true) {
      const warehouse = this.lstWareHouse.filter(ele => ele.IsPrimary == 1);      
      if (enableWareHouseControl.value == 0 && warehouse.length > 0)
        primaryWarehouseID = warehouse[0].WarehouseId;
      else
        primaryWarehouseID = enableWareHouseControl.value
  
        if (this.isEnableWarehouse == true) {
          enableWareHouseControl.setValidators([CustomValidator.required]);
        }
        enableWareHouseControl.enable();       
    }
    else {
      primaryWarehouseID = 0;
      enableWareHouseControl.disable();
    }
    enableWareHouseControl.updateValueAndValidity();

    this.inventoryForm.patchValue({
      warehouseID: primaryWarehouseID
    });


    if (this.inventoryForm.value.enableAdvTracking == false) {
      this.editRadioButton(false, false);
      return;
    }
    else if (this.inventoryForm.value.enableAdvTracking == true) {
      this.editRadioButton(true, false);
      return;
    }
    return;
    }

    onChangeNegativeStock(eventSelection) {
        // if (this.inventoryForm.value.enableAdvTracking == false) {
        //   this.editRadioButton(false, false);
        //   return;
        // }
        if(this.inventoryForm.value.enableNegativeStock==false){
            if(this.inventoryForm.value.openingBalanceUnit < 0){
                this.inventoryForm.patchValue({
                    openingBalanceUnit : null
                })
            }
        }
        return;
    }

  showManageBatchTrackingPopup(_itemID: number) {
    let trackingType = ''; 
    if (this.inventoryForm.value.advancedInventoryTrackingType == "batchTracking")
      trackingType = 'Batch'
    else if (this.inventoryForm.value.advancedInventoryTrackingType == "serialTracking")
      trackingType = 'Serial'
    Swal.fire({
      title: this.translate.instant("InventoryModule.Item.Advanced Inventory"),
      icon: 'info',
      text: this.translate.instant("InventoryModule.Item.You enabled "  +  trackingType + " " + "tracking. You will need to add the tracking numbers in order to fulfil orders."),
      showCancelButton: true,
      confirmButtonText: this.translate.instant("InventoryModule.Item.Go To Tracking"),
      cancelButtonText: this.translate.instant("Common.Cancel"),
    }).then((result) => {
      if (result.value) {
        if (trackingType == 'Batch')
          this.router.navigate(['/inventory/batchtracking'], { queryParams: { itemId: btoa(_itemID.toString()) } });
        else if (trackingType == 'Serial')
          this.router.navigate(['/inventory/serialtracking'], { queryParams: { itemId: btoa(_itemID.toString()) } });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return false;
      }
    });
  }

  openingBalanceDateValidition(control: AbstractControl, condition: boolean) {
    if (control.enabled && condition && control.value == null && this.openingBalanceDate == null) {
      control.setErrors({ 'required': true });
    } else {
      control.setErrors(null);
    }
  }

  async getMigrationDate() {
    let payload = { oldValue: null, newValue: null, action: 'Get', pageID: 0 };
    let data = await this.http.postservice(environment.accountsApiUrl + '/migration/GetSaveMigrationDate/', payload).toPromise();
    let rdata: any = data;
    let retundata = JSON.parse(rdata.responseData);
    let migrationEndDate = retundata[0].migrationEDate;
    if (migrationEndDate) {
      const date = moment(migrationEndDate).add(1, 'days').format('YYYY/MM/DD');
      this.openingBalanceDate = date;
    } else {
      this.openingBalanceDate = null;
    }
  }

  async UpdateOpeningBalanceDate(): Promise<{ result: 'Accepted'| 'Cancel' | 'Other' }> {
    let formDate = this.inventoryForm.get('openingBalanceDate').value;
    //formDate = moment(formDate).format('DD/MM/yyyy');
    if (this.openingBalanceDate && formDate && formDate != this.openingBalanceDate) {
      let msg = this.translate.instant("InventoryModule.Item.There is a mismatch between the opening balance date and the migration date.Is it okay to change the opening balance date to match the migration date?");
      let obDate = this.translate.instant("InventoryModule.Item.Opening Balance Date");
      let mDate = this.translate.instant("InventoryModule.Item.Migration Date");
      var msgs: any = {
        html: `
        <div class="text-justify">
            ${msg}
        </div>
        <div class="mt-3 d-flex align-items-start flex-column">
          <span><b>${obDate}:</b> ${moment(formDate).format('DD/MM/yyyy')}</span>
          <span class="mt-2"><b>${mDate}:</b> ${moment(this.openingBalanceDate).format('DD/MM/yyyy')}</span>
        </div>
        `,
        title: this.translate.instant("InventoryModule.Item.Suggestion"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.translate.instant("Common.Yes"),
        cancelButtonText: this.translate.instant("Common.No"),
      };
      let result = await Swal.fire(msgs);
      if (result.value) {
        return { result : 'Accepted' };
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return { result: 'Cancel' };
      } else {
        return { result: 'Other' }
      }
    }
    return { result: 'Cancel' };
  }

  DBDateFormat(date: any): any {
    return this.datepipe.transform(date, 'yyyy-MM-dd');
  }
  costingNotificationMessage(): string {
    if (this.oldValue!!) {
      if ((this.oldValue.inventoryAssetAccountID != this.inventoryForm.value.inventoryAssetAccountID)
        || (this.oldValue.openingBalanceUnitPrice != this.inventoryForm.value.openingBalanceUnitPrice)
        || (this.oldValue.openingBalanceUnit != this.inventoryForm.value.openingBalanceUnit)
        || (this.oldValue.openingBalanceDate != this.inventoryForm.value.openingBalanceDate)
        || (this.oldValue.avgCostTypeID != this.inventoryForm.value.avgCostTypeID)) {
        return ', Recalculating Average/FIFO Cost is in Backround progress. Please check notification icon for Backgound Task Status';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  async LoadCostingType() {
    let response: any = await this.master.getCostingMethod();
    if (response.isSuccess) {
      this.lstCostingType = response.responseData.map(ele => ({
        avgCostTypeID: ele.typeId,
        avgCostTypeName: ele.typeName
      }))
    }
  }
  async ItemClassificationCode(){
    this.ClassificationCode =  await this.master.getClassificationCode();
    this.ItemClsCodeList.emit(this.ClassificationCode);
  }

  ClassificationCodeMatch(){
    var matched = false;
    for (const element of this.ClassificationCode) {
      var keywords = element.keywords.split(',');
      if(element.code != "022"){
        for (const e of keywords) {
          var isInclude = e.toLowerCase().indexOf(this.inventoryForm.value.itemName.toLowerCase());        
          if(isInclude != -1){
            this.clCode = element.code;
            matched = true;
            break;
          }
        }
        if(isInclude != -1){
          break;
        }
      }
    }
    if(!matched){
      this.clCode = "022";
    }
  }

  fileUpload(fileAttachments,itemid) {
    this.http.postservice(environment.organizationApiUrl + '/Document/fileattachment?PageID=' + this.page.pageDetails.pageID, fileAttachments).subscribe({
      next: (data: any) => {
        if (data > 0) {
          this.fileAttachmentsData = [];
        }
        this.showaddItemmodal.hide();
        if(this.callFrom != 'TA' && this.callFrom != 'NTA'){
          this.toParentAfterItemSave.emit(itemid.toString());
        }
      },
      error:(data: any) =>{
        this.showaddItemmodal.hide();
      }
    });
  }
  emitattachmentFile(event) {
    this.fileAttachmentsData = event;
  }
  
onFileChange(event: any) {
    const reader = new FileReader();
    let files: any;

    if (event instanceof Event && event.target instanceof HTMLInputElement) {
        files = event.target.files;
    } else if (event instanceof FileList) {
        files = event;
    } else {
        return;
    }
    if (!!files.length) {
        let [file] = files;
        this.fileAttachmentsData = [];

        // Validate image type
        if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
            // Validate file size
            if (file.size <= 1048576) { // 1MB or less
                reader.readAsDataURL(file);
                reader.onload = (event: any) => {
                    this.imageSrc = reader.result as string;
                    this.isUpload = true;

                    this.openCropperModal();

                    this.UploadedFileName = file.name;
                    let url = [];
                    url.push(event.target.result);
                    let filesAttachObj = {
                        fileID: 0,
                        transactionID: this.itemID ? this.itemID : 0,
                        transactionTypeID: 12,
                        filbytes: this.imageSrc,
                        fileName: this.UploadedFileName,
                        FileGroup: 'Inventory',
                        contentLength: file.size
                    };
                    this.fileAttachmentsData.push(filesAttachObj);
                    this.isfileChanged = true;

                };
            } else {
                this.fileUploader.nativeElement.value = null;
                let msg = this.translate.instant("InventoryModule.Item.Item Image size should be maximum 1MB.");
                this.errornotifierId = this.alertMessage.errorNotifier(msg, this.errornotifierId);
                return false;
            }
        } else {
            this.fileUploader.nativeElement.value = null;
            let msg = this.translate.instant("InventoryModule.Item.Item Image type should be jpeg/jpg/png.");
            this.errornotifierId = this.alertMessage.errorNotifier(msg, this.errornotifierId);
            return false;
        }
    }
}

  
  openImageViewer() {
    this.isImageViewerOpen = true;
  }

  closeImageViewer() {
    this.isImageViewerOpen = false;
  }

  onShowHide() {
    this.imageSrc = "";
    this.isUpload = false;
    this.isImageViewerOpen = false;
    $('#fileUpload').val('');
    this.isfileChanged = true;
    this.closeCropImage();
  }

deleterecord(fileID, filename, filePath) {
    let apiUrl = environment.organizationApiUrl + '/Document/DeleteAttachementFile';
    this.http.DeleteAttachmentFile(apiUrl, fileID, 12, this.page.pageDetails.pageID, this.itemID, filename, filePath).subscribe({
        next: (data) => {
            console.log(data);
        }
    })
}
/**zoom image container**/

imageZoom(imgID, resultID) {
  const img = document.getElementById(imgID) as HTMLImageElement; // Cast to HTMLImageElement
  const result = document.getElementById(resultID) as HTMLElement;

  // Show the result div
  result.style.display = "block";

  // Create lens
  const lens = document.createElement("DIV");
  lens.setAttribute("class", "img-zoom-lens");
  img.parentElement.insertBefore(lens, img);

  // Calculate ratios
  const cx = result.offsetWidth / lens.offsetWidth;
  const cy = result.offsetHeight / lens.offsetHeight;

  // Set background properties for the result DIV
  result.style.backgroundImage = `url('${img.src}')`; // Now TypeScript knows `img` has a `src`
  result.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`;

  // Add event listeners
  lens.addEventListener("mousemove", (e) => this.moveLens(e, img, lens, result, cx, cy));
  img.addEventListener("mousemove", (e) => this.moveLens(e, img, lens, result, cx, cy));
  lens.addEventListener("touchmove", (e) => this.moveLens(e, img, lens, result, cx, cy));
  img.addEventListener("touchmove", (e) => this.moveLens(e, img, lens, result, cx, cy));
}

moveLens(e: MouseEvent | TouchEvent, img: HTMLImageElement, lens: HTMLElement, result: HTMLElement, cx: number, cy: number) {
  e.preventDefault();
  
  // Get cursor position
  const pos = this.getCursorPos(e, img);
  let x = pos.x - lens.offsetWidth / 2;
  let y = pos.y - lens.offsetHeight / 2;

  // Prevent lens from being positioned outside the image
  if (x > img.clientWidth - lens.offsetWidth) x = img.clientWidth - lens.offsetWidth;
  if (x < 0) x = 0;
  if (y > img.clientHeight - lens.offsetHeight) y = img.clientHeight - lens.offsetHeight;
  if (y < 0) y = 0;

  lens.style.left = `${x}px`;
  lens.style.top = `${y}px`;
  result.style.backgroundPosition = `-${x * cx}px -${y * cy}px`;
}

getCursorPos(e: MouseEvent | TouchEvent, img: HTMLImageElement) {
  const a = img.getBoundingClientRect();
  let x, y;

  if (e instanceof MouseEvent) {
    x = e.pageX - a.left - window.pageXOffset;
    y = e.pageY - a.top - window.pageYOffset;
  } else if (e instanceof TouchEvent) {
    const touch = e.touches[0];
    x = touch.pageX - a.left - window.pageXOffset;
    y = touch.pageY - a.top - window.pageYOffset;
  }

  return { x, y };
}

resetZoom(resultID) {
  const result = document.getElementById(resultID) as HTMLElement;
  result.style.display = "none"; // Hide the zoom result
  const lens = document.querySelector('.img-zoom-lens') as HTMLElement;
  if (lens) lens.remove(); // Remove the lens element
}

openCropperModal() {
  const image = document.getElementById('imageToCrop') as HTMLImageElement;

  if (this.cropper) {
    this.cropper.destroy(); 
    this.cropper = null;
  }

  image.onload = () => {
    this.cropper = new Cropper(image, {
      dragMode: 'move',
      autoCropArea: 1,
      guides: true,
      center: true,
      highlight: true,
      cropBoxMovable: true,
      cropBoxResizable: true,
      toggleDragModeOnDblclick: true,
      background: false,
      viewMode: 1,
      ready: () => {
        this.cropper.setCropBoxData({
          width: 175,
          height: 175,
        });
      },
    });
  };

  $('#cropperModal').modal('show');
}


  cropImage() {
    if (this.cropper) {
      // Get the cropped canvas
      const canvas = this.cropper.getCroppedCanvas();
      
      // Convert canvas to data URL
      const croppedImageDataUrl = canvas.toDataURL('image/png'); 
      this.fileAttachmentsData = [];
      const filesAttachObj = {
        fileID: 0,
        transactionID: this.itemID ? this.itemID : 0,
        transactionTypeID: 12,
        filbytes: croppedImageDataUrl, // Store the cropped image data
        fileName: this.UploadedFileName,
        FileGroup: 'Inventory',
        contentLength: canvas.toBlob(blob => {
          // Use blob for content length if needed
          console.log(blob.size);
        })
      };
  
      this.fileAttachmentsData.push(filesAttachObj);
      this.imageSrc = croppedImageDataUrl;
      // Close the modal after cropping
      this.closeCropImage();
    }
  }
  
closeCropImage(){
  if (this.cropper) {
    this.cropper.destroy(); // Clean up the cropper instance
    this.cropper.clear(); // clear cropper instance
    this.cropper.reset(); // reset cropper instance
  }
  this.cropper = null;
  $('#imageToCrop').val('');
  $('#cropperModal').modal('hide');
}

}
