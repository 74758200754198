import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PageServices } from './page.service';
import { isValid } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class AdvanceinventoryService {
  private ApiUrl: string = environment.inventoryApiUrl;

  constructor(
    private _http: HttpClient,
    private _page: PageServices) {

  }

  async getWarehouseList() {
    var Url = this.ApiUrl + "/Inventory/GetWarehouseList";
    let result = await this._http.get(Url).toPromise();
    return result;
  }

  async getActiveTrackingList() {
    var Url = this.ApiUrl + "/Inventory/GetTrackingItems";
    let result = await this._http.get(Url).toPromise();
    return result;
  }

  async getDetailedID(TransId: number, TransactionTypeID: number) {
    var Url = this.ApiUrl + `/Inventory/GetDetailedID?TransId=${TransId}&TransactionTypeID=${TransactionTypeID}`;
    let result = await this._http.get(Url).toPromise();
    return result;
  }

  async onTransactionValidate(newTransaction: any, oldTransaction: string, transactionTypeId: number): Promise<boolean> {
    let isValid = false;
    return isValid;
  }

  async getWarehouseByItemId(itemId: number): Promise<any> {
    let data: any = await this._http.get(environment.inventoryApiUrl + `/Master/GetWarehouseByItemID?itemID=${itemId}`).toPromise();
    return data;
  }

  async getBatchList(_itemID: number, _transactionDetailId: number, _transactionTypeId: number): Promise<any> {
    let data: any = await this._http.get(environment.inventoryApiUrl + `/Inventory/GetBatchList?ItemId=${_itemID}&transDetailId=${_transactionDetailId}&transactionTypeId=${_transactionTypeId}`).toPromise();
    if (data.isSuccess && data.responseData != null) {
      return data.responseData.map(row => ({
        batchID: row.batchID,
        batchNo: row.batchNo,
        availableQty: row.quantity,
        manufacturedDate: row.manufacturedDate,
        expiryDate: row.expiryDate,
        warehouseID: row.warehouseID,
        description: row.description
      }));
    }
  }

  async getAuditNotesByTransactionTypeID(_transactionDetailId: number, _transactionTypeId: number): Promise<any> {
    let response: any = await this._http.get(environment.inventoryApiUrl + `/Inventory/GetAuditNotesByTransactionTypeID?transDetailId=${_transactionDetailId}
      &transactionTypeId=${_transactionTypeId}`).toPromise();
    if (response.isSuccess && response.responseData!!) {
      return response?.responseData?.map(ele => ({
        Action: ele.action,
        Date: ele.date,
        User: ele.user,
        Notes: ele.details,
        NoteID: ele.noteID,
        IsPrint: ele.isPrint,
      }));
    }
  }
}
