import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';
import { HostService } from '../_services/host.serices';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private authenticationService: AuthenticationService,private hostService:HostService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {    
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url    
    this.hostService.logout(state.url);
    return false;
  }
}
