import { ErrorHandler, Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    handleError(error: any): any {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            if (confirm("New version available. Load New Version?")) {
                window.location.reload();
            }
        }else{
          console.error(error?.message,error)
        }
    }
}
