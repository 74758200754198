<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title font-weight-bold">{{heading}}</h4>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pb-0">
    <mat-dialog-content style="overflow-x: hidden">
      <div class="row">
        <div class="col-md-7">
          <h5 class="modal-title font-weight-bold">{{'SharedModule.Type & Accounts' | translate}}</h5>
        </div>
        <div class="col-md-5 text-right" *ngIf="assignedRegisteredCount>0">
          <label>{{'SharedModule.Assigned Asset(s)' | translate}} : <a class="t-link edit" (click)="onAssignedRegisterCount()">
              {{assignedRegisteredCount}} {{'SharedModule.Registered' | translate}}</a></label>
        </div>
        <div class="col-md-5 text-right" *ngIf="assignedRegisteredCount==0">
          <div class="btn-group" dropdown>
            <button dropdownToggle class="btn btn-primary dropdown-toggle" type="button">
              {{'Common.Options' | translate}}
              <span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li role="menuitem"><a class="dropdown-item" (click)="onDelete()">{{'Common.Delete' | translate}}</a></li>
              <!-- onOpenModalPopup('DeleteAssetType') -->
            </ul>
          </div>
        </div>
      </div>
      <!-- <form class="form-horizontal needs-validation"> -->
        <div class="row mt-3">
          <div class="col-md-6">
            <label class="form-col-form-label" for="assetType">{{'SharedModule.Asset Type' | translate}}
              <span class="text-danger">*</span>
            </label>
         
            <input class="form-control" type="text" autocomplete="off" [(ngModel)]="assetType" id="assetType"
              name="assetType"  xssDirective>
              <!-- [ngModelOptions]="{standalone: true}" -->
          </div>
          <div class="col-md-6">
            <label class="form-col-form-label" for="assetAccount">{{'SharedModule.Asset Account' | translate}} <span
                class="text-danger">*</span></label>
            <ng-select [items]="lstAccounts" class="auto-grow" bindLabel="displayAccountName" bindValue="caAccountID"
              groupBy="accountGroupName" [(ngModel)]="assetAccount" name="assetAccount" id="assetAccount">
              <ng-template ng-optgroup-tmp let-item="item">
                {{item.accountGroupName || 'Unnamed group'}}
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-col-form-label" for="accumulatedDepreciationAccount">{{'SharedModule.Accumulated Depreciation Account' | translate}} <span class="text-danger">*</span></label>
            <ng-select [items]="lstAccounts" class="auto-grow" bindLabel="displayAccountName" bindValue="caAccountID"
              groupBy="accountGroupName" [(ngModel)]="accumulatedDepreciationAccount"
              name="accumulatedDepreciationAccount" id="accumulatedDepreciationAccount">
              <ng-template ng-optgroup-tmp let-item="item">
                {{item.accountGroupName || 'Unnamed group'}}
              </ng-template>
            </ng-select>
          </div>
          <div class="col-md-6">
            <label class="form-col-form-label" for="depreciationExpenseAccount">{{'SharedModule.Depreciation Expense Account' | translate}} <span
                class="text-danger">*</span></label>
            <ng-select [items]="lstDepreciationAccounts" class="auto-grow" bindLabel="displayAccountName"
              bindValue="caAccountID" groupBy="accountGroupName" [(ngModel)]="depreciationExpenseAccount"
              name="depreciationExpenseAccount" id="depreciationExpenseAccount">
              <ng-template ng-optgroup-tmp let-item="item">
                {{item.accountGroupName || 'Unnamed group'}}
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <h5 class="modal-title font-weight-bold">{{'SharedModule.Book Depreciation Default' | translate}}</h5>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <label class="form-col-form-label" for="depreciationMethod">{{'SharedModule.Depreciation Method' | translate}} <span
                class="text-danger">*</span></label>
            <ng-select name="dm" [items]="lstAverageMethod" bindValue="depreciationTypeID"
              bindLabel="depreciationMethod" id="depreciationMethod" name="depreciationMethod"
              [(ngModel)]="depreciationMethod" (change)="onChangeDepreciationMethod()">
            </ng-select>
          </div>
          <div class="col-md-6">
            <label class="form-col-form-label" for="averageMethod">{{'SharedModule.Average Method' | translate}} <span
                class="text-danger" *ngIf="!disableDepProperty">*</span></label>
            <ng-select [items]="lstDepreciationType" name='averageMethod' id="averageMethod" bindValue="avgMethodID"
              bindLabel="averageMethodName" [(ngModel)]="averageMethod" [disabled]="disableDepProperty">
            </ng-select>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <div class="form-group d-flex">
              <mat-radio-group [(ngModel)]="selectedRB" (change)="onRatioButtonChange($event.value)" name="selectedRB">
                <div class="col-md-1 col-form-label">
                  <mat-radio-button  [disabled]="disableDepProperty" value="rate" class="mr-2" name="rate">{{'SharedModule.Rate' | translate}}</mat-radio-button>
                </div>
              </mat-radio-group>
              <div class="col-md-3">
                <div class="input-group"><input type="text" maxlength="6" decimal2="" class="form-control" id="rate"
                    name="rate" [(ngModel)]="rate" [disabled]="!isRateSelected || disableDepProperty">
                  <div class="input-group-append"><span class="input-group-text">%</span></div>
                </div>
              </div>
              <mat-radio-group [(ngModel)]="selectedRB" (change)="onRatioButtonChange($event.value)" name="selectedRB">
                <div class="col-md-2 col-form-label pl-0 ">
                  <mat-radio-button  [disabled]="disableDepProperty" value="effectiveLife" name="effectiveLife" class="mr-2">{{'SharedModule.Effective Life(Yrs)' | translate}}</mat-radio-button>
                </div>
              </mat-radio-group>
              <div class="col-md-3 ">
                <input class="form-control" type="text" autocomplete="off" name="effectiveLife" id="effectiveLife"
                  [(ngModel)]="effectiveLife" [disabled]="isRateSelected || disableDepProperty" decimal2="">
              </div>
            </div>
          </div>
        </div>
      <!-- </form> -->
    </mat-dialog-content>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success btn-normal mr-3" type="button" (click)="onSaveAssetType();"> {{'Common.Save' | translate}} </button>
    <button class="btn btn-danger btn-normal" type="button" (click)="closeModal();">{{'Common.Cancel' | translate}}</button>
  </div>
</div>

<!-- Confirm Delete -->
<div bsModal #modalDeleteAssetType="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="modalDeleteAssetTypeLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font-weight-bold">{{'SharedModule.Delete Asset Type' | translate}}</h4>
        <button type="button" class="close" (click)="onCloseModalPopup('DeleteAssetType')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="import-list">
          <p>{{'SharedModule.If you click Delete, Computer Accessories/Parts will be permanently removed including from draft assets.This can't be undone' | translate}}</p>
        </div>
      </div>
      <div class="modal-footer">
        <div *userCanEdit="[true]" class="btn-group" dropdown>
          <button class="btn btn-success btn-normal" type="button" (click)="onDelete()">
            {{'Common.Delete' | translate}}
          </button>
        </div>
        <div class="btn-group" dropdown>
          <button class="btn btn-danger btn-normal" type="button" (click)="onCloseModalPopup('DeleteAssetType')">
            {{'Common.Cancel' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Confirm Delete -->
