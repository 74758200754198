import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../utilities/_services/authentication.service'
import { HttpParams } from '@angular/common/http';
import { HttpServices } from '../../utilities/_services/http.service';
import { environment } from '../../../environments/environment'
import { GridOptions, Module, AllCommunityModules } from "@ag-grid-community/all-modules";
import * as moment from "moment";
import { AlertMessage } from '../../utilities/_helpers/alert.message';
import { PrintService } from '../template/services/print.service';
import { GroupNames } from '../template/models/enumlist';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HostService } from '../../utilities/_services/host.serices';
import { removingTrailingZeros } from '../../utilities/_common/commonfunctions';

@Component({
  selector: 'app-customerQuote',
  templateUrl: './customerQuote.component.html',
  styleUrls: ['./customerQuote.component.scss'],
})
export class customerQuoteComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;
  @ViewChild('showPrint') public showPrint: ModalDirective;
  pdfhtml: any;
  key: any;
  resobj: any;
  submitted = false;
  loading = false;
  TransID: any;
  TransTypeID: any;
  columnDefs: any;
  rowData: any;
  // public gridOptions: GridOptions;
  // modules: Module[] = AllCommunityModules;
  subTotal = '';
  qoTotal = '';
  CurrencyCode: any = 0;
  taxAmountsAreList: any = [];
  TaxAdjustment: Number = 0;
  TaxTypeID: any = 3;
  quotedata: any = [];
  TaxType: any;
  lsttaxCompoundRate: any;
  pageID = 24;
  selectedTransactions: any = [];
  Qstatus: any;
  Status: string = "";
  ContactName: any;
  Qno: any;
  QDate: any;
  QExpDate: any;
  userid: any;
  orgid: any;
  custcode: any;
  ReplyTo: any;
  succesNotifierId: number = 0;
  errorNotifierId: number = 0;
  orgLegalName = '';
  orgPostalAttention = '';
  orgPostalAddress = '';
  orgPostalCity = '';
  orgPostalState = '';
  orgPostalZipcode = '';
  orgCurrencyCode = '';
  showExchangeRate = '';
  showTooltip = '';
  showToCurrencyCode = '';
  showPerUnit = '';
  showCurrencyInfo = false;
  statusMessage = '';
  totalDiscountAmount: number = 0;
  subTotalLabel: string = ' Sub Total:'
  isDigiSME = false;
  CentOrgID:any;
  CentralUserID:any;
  LicenseTypeID: any;
  Orgimg: string = "";
  constructor(private http: HttpServices, private route: ActivatedRoute, private authService: AuthenticationService,
    public printService: PrintService, private alertMessage: AlertMessage, private hostService: HostService) {
    this.route.queryParams
      .subscribe(params => {
        this.key = params.key;
      });

      this.isDigiSME = this.hostService.getDigisme();
  }

  updateQuoteAuditStatus(orgID: string, contactID: string, transID: string, amount: string, customerCode: string) {
    let payload = { pageID: 9, OrgID: orgID, ContactID: contactID, CustomerCode: customerCode, TransID: transID, Amount: amount }
    this.http.postservice(environment.salesApiUrl + '/Sales/UpdateOnlineQuoteViewStatus/', payload).subscribe({
      next: (data) => {
      }
    });
  }

  getAddressHTML() {
    return this.orgLegalName;
  }

  getOrganisationDetail(data) {
    return new Promise(resolve => {
      this.orgCurrencyCode = data[0].CurrencyCode;
      this.orgLegalName = data[0].LegalName + '<br/>';
      if (data[0].PostalAttention != '' && data[0].PostalAttention != null) {
        this.orgLegalName += data[0].PostalAttention + '<br/>'
      }
      if (data[0].postalAddress != '' && data[0].postalAddress != null) {
        this.orgLegalName += data[0].PostalAddress + '<br/>';
      }
      if (data[0].postalCity != '' && data[0].postalCity != null) {
        this.orgLegalName += data[0].PostalCity + '<br/>';
      }
      if (data[0].PostalState != '' && data[0].PostalZipCode != '' && data[0].PostalState != null && data[0].PostalZipCode != null) {
        this.orgLegalName += data[0].PostalState + ' - ' + data[0].PostalZipCode;
      } else if ((data[0].PostalState == '' || data[0].PostalState == null) && (data[0].PostalZipCode != '' && data[0].PostalZipCode != null)) {
        this.orgLegalName += data[0].PostalZipCode;
      } else if ((data[0].PostalState != '' && data[0].PostalState != null) && (data[0].PostalZipCode == '' || data[0].PostalZipCode == null)) {
        this.orgLegalName += data[0].PostalState;
      }
      resolve('');
    });
  }

  async ngOnInit(): Promise<void> {
    await this.authService.VerifyKey(this.key).then((res) => this.resobj = res);
    this.statusMessage = this.resobj.StatusMessage;
    if (this.resobj.isSuccess == false) {
      Swal.fire({
        title: "",
        text: this.resobj.StatusMessage,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          // this.router.navigateByUrl("/login");
        }
      })
      if (this.resobj.StatusMessage == 'Quotation link has been expired!') {
        this.TransID = this.resobj.ResponseData.TransID;
        this.TransTypeID = this.resobj.ResponseData.TransTypeID;
        this.userid = this.resobj.ResponseData.UserID;
        this.orgid = this.resobj.ResponseData.OrgID;
        this.custcode = this.resobj.ResponseData.CustCode;
        this.ReplyTo = this.resobj.ResponseData.ReplyToEmail;
        //this.createColumnDefs();
      }
    }
    else {
      if (this.resobj.ResponseData.Type == "CustomerQuote") {
        this.TransID = this.resobj.ResponseData.TransID;
        this.TransTypeID = this.resobj.ResponseData.TransTypeID;
        this.userid = this.resobj.ResponseData.UserID;
        this.orgid = this.resobj.ResponseData.OrgID;
        this.custcode = this.resobj.ResponseData.CustCode;
        this.ReplyTo = this.resobj.ResponseData.ReplyToEmail;

        this.CentOrgID = this.resobj.ResponseData.CentOrgID;
        this.CentralUserID = this.resobj.ResponseData.CentralUserID;
        this.LicenseTypeID = this.resobj.ResponseData.LicenseTypeID;
        this.createColumnDefs();
      }
      else {
        Swal.fire({
          title: "",
          text: "Invalid link",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            // this.router.navigateByUrl("/login");
          }
        })
      }
    }
  }

  setExchangeRate(exdata) {
    return new Promise(resolve => {
      let lstEexchangeRate: any = [];
      lstEexchangeRate = exdata;
      let data = lstEexchangeRate.filter(x => x.IsSelected == true);
      if (data.length > 0) {
        this.showToCurrencyCode = "1 " + this.orgCurrencyCode + " = " + removingTrailingZeros(data[0].Unitsper) + " " + data[0].ToCurrencyCode + "";
        this.showPerUnit = removingTrailingZeros(data[0].PerUnit);
      }
      resolve('');
    });
  }
  
  getQuoteDetailsByID() {
    let params = new HttpParams();
    params = params.set('TransID', this.TransID.toString());
    params = params.set('TransTypeID', this.TransTypeID.toString());
    params = params.set('userid', this.userid);
    params = params.set('orgid', this.orgid);
    params = params.set('custcode', this.custcode);
    this.http.postservice(environment.salesApiUrl + '/Sales/GetOnlineQuotedetailsById', params).subscribe({
      next: (data) => {
        this.getOrganisationDetail(data[0].OrgDetails).then(() => { });
        this.updateQuoteAuditStatus(this.orgid, data[0].ContactID, this.TransID, data[0].TotalAmount, this.custcode);
        if (this.orgCurrencyCode !== data[0].CurrencyCode) {
          this.setExchangeRate(data[0].UsedCurrencyTypes);
          this.showCurrencyInfo = true;
        }

        let OrgDetails = data[0].OrgDetails;
        this.Orgimg = OrgDetails[0].OrgLogo!= "" ? OrgDetails[0].OrgLogo : '';
        this.quotedata = data[0];
        const tempItem = data[0].QuoteItemTable;
        this.CurrencyCode = data[0].CurrencyCode;
        this.subTotal = this.numberFormatToFixed(data[0].SubTotalAmountExclusive);
        this.qoTotal = this.numberFormatToFixed(data[0].TotalAmount);
        this.TaxTypeID = data[0].TaxTypeID;
        this.TaxType = data[0].TaxType;
        this.Qstatus = data[0].QuoteStatusTypeID;
        this.Status = data[0].Status;
        this.ContactName = data[0].ContactName;
        this.Qno = data[0].QuoteNO;
        this.QDate = moment(data[0].QuotationDate).format('DD MMM YYYY');
        this.QExpDate = moment(data[0].ExpiryDate).format('DD MMM YYYY');

        this.selectedTransactions.push(
          {
            TransID: this.TransID,
            ContactID: data[0].ContactID,
            ContactName: data[0].ContactName,
            TransactionTypeID: this.TransTypeID,
            QuoteStatus: data[0].QuoteStatusTypeID,
            Amount: data[0].TotalAmount
          });
        // this.TaxAdjustment = data[0].TaxAdjustment;
        let allrowTotal: number = 0;
        let quoteItem: any = [];
        if (tempItem !== null) {
          tempItem.forEach(rw => {
            allrowTotal = allrowTotal + Number(rw.UnitPriceExclusive) * Number(rw.Quantity);
            let rowFeild = {
              ItemDescription: rw.ItemDescription ? rw.ItemDescription : "",
              Quantity: (rw.Quantity == 0) ? "" : rw.Quantity,
              UnitPrice: (rw.UnitPriceExclusive == 0) ? "" : rw.UnitPriceExclusive,
              TaxRateName: rw.TaxRateName,
              TaxAmount: rw.TaxAmount,
              Amount: rw.Amount,
              QuoteDetID: rw.QuoteDetID,
              ItemID: rw.InvItemID,
              ItemGroupID: rw.ItemGroupID,
              IsGroup: rw.IsGroup,
              DiscPercentage: rw.DiscPercentage,
              DiscAmount: rw.DiscAmount,
              Discount: rw.DiscPercentage != 0 ?
                (rw.DiscPercentage.toFixed(2) + '%') :
                (rw.DiscAmount != 0 ? (rw.DiscAmount.toFixed(2)) : '0.00')
            }
            quoteItem.push(rowFeild);
            quoteItem.sort((x, y) => x.id - y.id);
          });
          let TempRowResult = quoteItem.filter(o => !Object.keys(o).every(k => !o[k])); // find empty row
          this.rowData = TempRowResult;
          // this.loadgridoptions();

          // this.subTotal = this.numberFormatToFixed((allrowTotal * 100) / 100);
          this.taxAmountsAreList = data[0].QuoteTaxAmounts;
          this.totalDiscountAmount = data[0].TotalDiscount;
          this.subTotalLabel = this.totalDiscountAmount > 0 ? 'Sub Total (Include a discount of ' + this.totalDiscountAmount.toString() + ') : ' : this.subTotalLabel;
        }
      }
    });

  }


  numberFormatToFixed(val: Number) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    let toFixed: Number = val ? val : 0;
    return formatter.format(Number(toFixed))
  }

  //  loadgridoptions() {
  //   this.gridOptions = {};
  //   this.gridOptions = <GridOptions>{
  //     columnDefs: this.columnDefs,
  //     defaultColDef: {
  //       flex: 1
  //     },

  //     onGridReady: (params: any) => {
  //       this.gridOptions.api.sizeColumnsToFit();
  //     },
  //     onGridSizeChanged: () => {
  //       this.gridOptions.api.sizeColumnsToFit();
  //     },
  //     rowHeight: 48
  //   };
  // }

  currencyFormatter(params: any) {
    return params.value == null || params.value == '' ? '' : parseFloat(params.value).toFixed(params.colDef.precision);
  }

  // this.columnDefs = [
  //   {
  //     headerName: "Description",
  //     field: "ItemDescription",
  //     width: 600
  //   },
  //   {
  //     headerName: "Quantity",
  //     field: "Quantity",
  //     type: 'rightAligned',
  //     width: 100,
  //     precision: 2,
  //     valueFormatter: this.currencyFormatter,
  //   },
  //   {
  //     headerName: "Unit Price",
  //     field: "UnitPrice",
  //     type: 'rightAligned',
  //     width: 100,
  //     precision: 2,
  //     valueFormatter: this.currencyFormatter,
  //   },

  //   {
  //     headerName: "Tax Rate",
  //     field: "TaxID",
  //     width: 120
  //     // precision: 2,
  //     // valueFormatter: this.currencyFormatter,
  //   },
  //   {
  //     headerName: "Amount",
  //     field: "Amount",
  //     width: 110,
  //     type: 'rightAligned',
  //     precision: 2,
  //     valueFormatter: this.currencyFormatter,
  //   },
  //   {
  //     headerName: "QID",
  //     field: "QuoteDetID",
  //     type: 'rightAligned',
  //     hide: true
  //   }

  // ];
  createColumnDefs() {
    this.columnDefs = [];
    this.columnDefs = [
      {
        field: 'drag',
        title: "",
        type: 'action',
        isVisible: false,
        isEditable: false,
        isCustom: false,
        isMandatory: false,
        css: {
          'width': '20px',
          'text-align': 'center'
        }
      },
      {
        field: 'itemID',
        title: "Items",
        type: 'list',
        isVisible: false,
        isEditable: false,
        isCustom: false,
        isMandatory: false,
        css: {
          'width': '150px',
          'max-width': '150px',
          'text-align': 'left',
        }
      },

      {
        field: 'ItemDescription',
        title: "Description",
        type: 'text',
        isVisible: true,
        isEditable: true,
        isCustom: false,
        isMandatory: false,
        css: {
          'width': '230px',
          'text-align': 'left'
        }
      },
      {
        field: 'Quantity',
        title: "Qty",
        type: 'numeric',
        align: 'right',
        isVisible: true,
        isEditable: true,
        isCustom: false,
        isMandatory: false,
        css: {
          'width': '100px',
          'text-align': 'right'
        }
      },
      {
        field: 'UnitPrice',
        title: "Unit price",
        type: 'numeric',
        align: 'right',
        isVisible: true,
        isEditable: false,
        isCustom: false,
        isMandatory: false,
        css: {
          'width': '100px',
          'text-align': 'right'
        }
      },
      {
        field: 'Discount',
        title: "Disc%",
        type: 'numeric',
        align: 'right',
        isVisible: true,
        isEditable: false,
        isCustom: false,
        isMandatory: false,
        css: {
          'width': '60px',
          'text-align': 'right'
        }
      },
      {
        field: 'TaxRateName',
        title: "Tax Rate",
        type: 'text',
        isVisible: true,
        isEditable: false,
        isCustom: false,
        isMandatory: false,
        css: {
          'width': '130px',
          'max-width': '130px',
          'text-align': 'left',
        }
      },
      {
        field: 'TaxAmount',
        title: "Tax Amount",
        type: 'numeric',
        align: 'right',
        isVisible: true,
        isEditable: false,
        isCustom: false,
        isMandatory: false,
        css: {
          'width': '80px',
          'text-align': 'right'
        }
      },
      {
        field: 'Amount',
        title: "Amount",
        type: 'numeric',
        align: 'right',
        isVisible: true,
        isEditable: false,
        isCustom: false,
        isMandatory: false,
        css: {
          'width': '100px',
          'text-align': 'right'
        }
      },
      {
        field: 'delete',
        title: "",
        type: 'action',
        isVisible: false,
        isEditable: false,
        isCustom: false,
        isMandatory: false,
        css: {
          'width': '20px',
          'text-align': 'center'
        }
      },
    ];
    this.getQuoteDetailsByID();
  }


  acceptquotes() {
    Swal.fire({
      title: 'Confirm',
      text: 'You have to submit for Accept.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        let reqparams = new HttpParams();
        reqparams = reqparams.set('QuoteTransction', JSON.stringify(this.selectedTransactions));
        reqparams = reqparams.set('userid', '0');
        reqparams = reqparams.set('orgid', this.orgid);
        reqparams = reqparams.set('custcode', this.custcode);
        var decodedString = this.printService.returnEncodedString(JSON.stringify(this.quotedata));
        // reqparams = reqparams.set('quotedata', JSON.stringify(this.quotedata));
        reqparams = reqparams.set('quotedata', decodedString);
        reqparams = reqparams.set('ReplyTo', this.ReplyTo);
        reqparams = reqparams.set('CentOrgID', this.CentOrgID);
        reqparams = reqparams.set('CentralUserID', this.CentralUserID);
        reqparams = reqparams.set('LicenseTypeID', this.LicenseTypeID);
        this.http.postservice(environment.salesApiUrl + '/Sales/CustomerAcceptQuotes/' + this.pageID, reqparams).subscribe({
          next: (data) => {
            if (data) {
              this.succesNotifierId = this.alertMessage.successNotifier("Your quote has been accepted", this.succesNotifierId);
              this.getQuoteDetailsByID();
            }
          },
          error: error => {
            this.errorNotifierId = this.alertMessage.errorNotifier("Error to accept quote", this.errorNotifierId);
          }
        });
      }
    })
  }

  declinequotes() {
    Swal.fire({
      title: 'Confirm',
      text: 'You have to submit for Decline.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        let reqparams = new HttpParams();
        reqparams = reqparams.set('QuoteTransction', JSON.stringify(this.selectedTransactions));
        reqparams = reqparams.set('userid', '0');
        reqparams = reqparams.set('orgid', this.orgid);
        reqparams = reqparams.set('custcode', this.custcode);
        var decodedString = this.printService.returnEncodedString(JSON.stringify(this.quotedata));
        reqparams = reqparams.set('quotedata', decodedString);
        reqparams = reqparams.set('ReplyTo', this.ReplyTo);
        reqparams = reqparams.set('CentOrgID', this.CentOrgID);
        reqparams = reqparams.set('CentralUserID', this.CentralUserID);
        reqparams = reqparams.set('LicenseTypeID', this.LicenseTypeID);
        this.http.postservice(environment.salesApiUrl + '/Sales/CustomerDeclineQuotes/' + this.pageID, reqparams).subscribe({
          next: (data) => {
            if (data) {
              this.succesNotifierId = this.alertMessage.successNotifier("Your quote has been declined", this.succesNotifierId);
              this.getQuoteDetailsByID();
            }
          },
          error: error => {
            this.errorNotifierId = this.alertMessage.errorNotifier("Error to decline quote", this.errorNotifierId);
          }
        });
      }
    })
  }

  print() {
    this.pdfhtml = "<div class='spinner-border text-primary'></div>";
    let templateType:string = "";
    if(this.quotedata){
      templateType = this.quotedata.TemplateType;
    }
    this.printService.printOnlineQuotePDFForm(GroupNames.Quotes, this.TransID, this.orgid, this.custcode, this.pdfViewerOnDemand,templateType);
    this.showPrint.show();
  }


}
