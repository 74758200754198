import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbDateAdapter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { EmailTemplateTypes } from '../../../shared/sendemailtemplate/model/EmailTemplateTypes';
import { HttpServices } from '../../../../utilities/_services/http.service';
import { CustomValidator, CustomTxtValidator, ValidEmail, NumbersRegEx, ValidMultipleEmail, ValidMultipleEmailWithSemicolonComma } from '../../../../utilities/_directives/customvalidator.directive';
import { environment } from '../../../../../environments/environment';
import { MasterServices } from '../../../../utilities/_services/master.service';
import Quill from 'quill';
import { LocalCacheServices } from '../../../../utilities/_services/acclocalcache.service';
import { AlertMessage } from '../../../../utilities/_helpers/alert.message'
declare var $: any;
import { PageServices } from '../../../../utilities/_services/page.service';
import * as moment from "moment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { format } from 'path';
import { TranslateService } from '@ngx-translate/core';

export interface PHDialogData {
  text: string;
  headerName: string;
  phtypeId: any;
}

@Component({
  selector: 'app-recurring-setting',
  templateUrl: './recurring-setting.component.html',
  styleUrls: ['./recurring-setting.component.scss']
})
export class RecurringSettingComponent implements OnInit {

  @ViewChild('showRecurringInvoice') public showRecurringInvoice: ModalDirective;
  @ViewChild('stepper') public stepper: MatStepper;
  lstFilters: any;
  step1Form: FormGroup | any;
  emailtemplateForm: FormGroup | any;
  transactionForm: FormGroup | any;
  lstStatus: any[];
  //Send Email
  SendEmailmodal: string = 'show';
  typeID: number = EmailTemplateTypes.InvoiceEmail;
  sendTitle: any = 'Invoice';
  @Input() mode: any = 'E';
  @Input() transID: any;
  @Input() transTypeID: any;
  @Input() recurrenceSettingobj: any = {};
  @Input() OldRecurringSetting: any = {};
  @Input() transDate: any;
  @Output() saveEvent = new EventEmitter<any>();
  ContactID: number;
  ContactName: string;
  bccEnable: boolean = false;
  ccEnable: boolean = false;
  isMarkasSent: boolean = true;
  fromDate: string;
  toDate: string;
  dateRange: string;
  statementBalance: string;
  pageID: number;
  templateId: number;
  statementType: string;
  expiryDate: string;
  phdetails: any = [];
  text: string;
  submitted = false;
  startDateErrorMsg: string = "";
  toDateErrorMsg: string = "";
  statusErrorMsg: string = "";

  InvoiceDueDate: string;
  lstEmailTemplate: any;
  defaultselectedOption: any;
  intervalValue: number = 0;
  interval: number = 0;
  isSubmitted: boolean = false;

  errorDesc: string;
  lstErrors: any = [];
  errorNotifierId: number = 0;
  warningNotifierId: number = 0;
  isOptional: boolean = false;
  isDisable: boolean = false;
  isEnableDiv: boolean = false;
  isneverEnd: boolean = false;
  minDate: { year: number, month: number, day: number }; // previouse date disable
  minFDate: { year: number, month: number, day: number }; // previouse date disable
  totalOccurrences: number = 0;
  pendingOccurrences: number = 0;
  nextInvoiceDate: string = "";
  NewRecurringInvoiceSetting: any = null;

  constructor(private formBuilder: FormBuilder, private ngbDateAdapter: NgbDateAdapter<string>, private ngbCalendar: NgbCalendar,
    private http: HttpServices, private page: PageServices, private master: MasterServices, private el: ElementRef, private local: LocalCacheServices,
    private alertMessage: AlertMessage, private _http: HttpClient, private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.step1Form = this.formBuilder.group({
      enableRecurring: [false],
      interval: [1, [Validators.required, Validators.min(1)]],
      intervalValue: [1, Validators.required],
      statusID: [1, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null],
      neverEnd: [false]
    });
    let current:any;
    if (this.transDate !== undefined) {
       current = new Date(this.transDate);
    }
    else{
      current = new Date();
    }
      this.minFDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };

    this.phdetails = [];
    this.lstFilters = [{ value: 1, filterselect: 'Day(s)' }, { value: 2, filterselect: 'Week(s)' }, { value: 3, filterselect: 'Month(s)' }, { value: 4, filterselect: 'Year(s)' }]
    this.lstStatus = [];
    this.lstStatus.push({ value: 1, filterselect: 'Draft' })
    this.lstStatus.push({ value: 3, filterselect: 'Approve' })
    if (this.transTypeID != 2)
      this.lstStatus.push({ value: 4, filterselect: 'Approve and Send' })
  }

  get f() { 
    if(this.step1Form.controls.toDate.value==null || this.step1Form.controls.fromDate.value==null){
      this.totalOccurrences=0;
      this.pendingOccurrences=0;
    }
    return this.step1Form.controls;
   }
  get g() { return this.emailtemplateForm.controls; }

  onshowModel() {
    this.startDateErrorMsg = "";
    this.toDateErrorMsg = "";
    this.statusErrorMsg = "";

    // if(this.NewRecurringInvoiceSetting!==null)
    // {
    //   this.step1Form = this.formBuilder.group({
    //     enableRecurring:this.NewRecurringInvoiceSetting.EnableRecurringSetting,
    //     interval:this.NewRecurringInvoiceSetting.RecurInterval,
    //     intervalValue:this.NewRecurringInvoiceSetting.RecurPeriod,
    //     statusID:this.NewRecurringInvoiceSetting.statusID,
    //     fromDate:moment(this.NewRecurringInvoiceSetting.RecurStartDate).format('YYYY/MM/DD'),
    //     toDate:this.NewRecurringInvoiceSetting.RecurEndDate==null || this.NewRecurringInvoiceSetting.RecurEndDate =='1900-01-01T00:00:00' ? null : moment(this.NewRecurringInvoiceSetting.RecurEndDate).format('YYYY/MM/DD'),
    //     neverEnd: this.NewRecurringInvoiceSetting.isNeverEnd
    //   });
    //   this.totalOccurrences= this.NewRecurringInvoiceSetting.TotalOccurrences;
    //   this.pendingOccurrences= this.NewRecurringInvoiceSetting.PendingOccurrences;
    //   this.nextInvoiceDate=(this.NewRecurringInvoiceSetting.NextInvoiceDate==null|| this.NewRecurringInvoiceSetting.NextInvoiceDate.split('T')[0]=="1900-01-01" ? "" : this.NewRecurringInvoiceSetting.NextInvoiceDate);//moment(this.recurrenceSettingobj["NextInvoiceDate"]).format('DD/MM/YYYY');
    //   if(this.step1Form.value.enableRecurring==false) { this.isEnableDiv=true;}
    //   if(this.step1Form.value.neverEnd==true) { this.isneverEnd=true;}
    // }
    // else
    // {
    if (this.recurrenceSettingobj !== null && this.recurrenceSettingobj !== undefined) {
      this.step1Form = this.formBuilder.group({
        enableRecurring: this.recurrenceSettingobj["EnableRecurringSetting"],
        interval: this.recurrenceSettingobj["RecurInterval"],
        intervalValue: this.recurrenceSettingobj["RecurPeriod"],
        statusID: this.recurrenceSettingobj["statusID"],
        fromDate: moment(this.recurrenceSettingobj["RecurStartDate"]).format('YYYY/MM/DD'),
        toDate: this.recurrenceSettingobj["RecurEndDate"] == null || this.recurrenceSettingobj["RecurEndDate"] == '1900-01-01T00:00:00' ? null : moment(this.recurrenceSettingobj["RecurEndDate"]).format('YYYY/MM/DD'),
        neverEnd: this.recurrenceSettingobj["isNeverEnd"]
      });
      this.totalOccurrences = this.recurrenceSettingobj["TotalOccurrences"];
      this.pendingOccurrences = this.recurrenceSettingobj["PendingOccurrences"];
      this.nextInvoiceDate = (this.recurrenceSettingobj["NextInvoiceDate"] == null || this.recurrenceSettingobj["NextInvoiceDate"].split('T')[0] == "1900-01-01" ? "" : this.recurrenceSettingobj["NextInvoiceDate"]);//moment(this.recurrenceSettingobj["NextInvoiceDate"]).format('DD/MM/YYYY');
      if (this.step1Form.value.enableRecurring == false) { this.isEnableDiv = true; }
      if (this.step1Form.value.neverEnd == true) { this.isneverEnd = true; }
      //this.step1Form.patchValue({ fromDate:moment(this.transDate).format('YYYY/MM/DD')})
    }
    else {
      if (this.step1Form.value.enableRecurring == true) {
        this.isEnableDiv = false;
      }
      else {
        this.isEnableDiv = true;
      }
      const current = new Date(this.transDate);
      const CurrentDate = new Date();
      if(CurrentDate>current){
        const nextDate = new Date(CurrentDate)
        nextDate.setDate(CurrentDate.getDate() + 1)
        this.minFDate = {
          year: nextDate.getFullYear(),
          month: nextDate.getMonth(),
          day: nextDate.getDate()
        };
      }
      else{
        const nextDate = new Date(current)
        nextDate.setDate(current.getDate() + 1)
      this.minFDate = {
        year: nextDate.getFullYear(),
        month: nextDate.getMonth(),
        day: nextDate.getDate() 
      };
    }
      this.step1Form.patchValue({ fromDate: moment(this.minFDate).format('YYYY/MM/DD') })
      if(CurrentDate>current){
        const nextDate = new Date(CurrentDate)
        nextDate.setDate(CurrentDate.getDate() + 1)
        this.minFDate = {
          year: nextDate.getFullYear(),
          month: nextDate.getMonth()+1,
          day: nextDate.getDate()
        };
      }
      else{
        const nextDate = new Date(current)
        nextDate.setDate(current.getDate() + 1)
      this.minFDate = {
        year: nextDate.getFullYear(),
        month: nextDate.getMonth()+1,
        day: nextDate.getDate()
      };
    }
    }
    // }
    const current = new Date(this.step1Form.value.fromDate);
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    }
    this.showRecurringInvoice.show();
  }

  async SaveUpdateSetting() {
    this.isSubmitted = true;
    this.startDateErrorMsg='';
    if (this.step1Form.value.enableRecurring==true)
    {
     
      if(this.step1Form.value.neverEnd === true){
         this.step1Form.value.toDate=null;
      }
      if (this.step1Form.invalid) {
        const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
        firstInvalidControl.focus();
        return;
      }
      if (this.step1Form.value.fromDate < new Date(this.transDate)) {
        this.startDateErrorMsg = this.translate.instant("PurchaseModule.Bills.Start On should be greater than Invoice Date");
        return;
      }

      if (this.step1Form.value.toDate === null && this.step1Form.value.neverEnd !== true) {
        this.toDateErrorMsg = this.translate.instant("Common.Please provide a valid date");
        return;
      }
      if (this.step1Form.value.toDate !== null && this.step1Form.value.neverEnd!==true) {
        if (this.step1Form.value.toDate < this.step1Form.value.fromDate) {
          this.toDateErrorMsg = this.translate.instant("PurchaseModule.Bills.Ends On should be greater than Start Date");
          return;
        }
      }
      if (this.page.accessRights.canApprove == false && this.step1Form.value.statusID > 1) {
        this.statusErrorMsg = this.translate.instant("PurchaseModule.Bills.You don't have approve access rights.So You cannot change the status to approve/approve & send options");
        return;
      }
    }
    if (this.step1Form.value.fromDate>this.step1Form.value.toDate && this.step1Form.value.neverEnd !== true) {
      this.toDateErrorMsg = this.translate.instant("Common.Please provide a valid date");
      return;
    }
    this.isSubmitted = false;
    let RecurId: number = 0;
    if (this.OldRecurringSetting !== undefined && this.OldRecurringSetting !== null) {
      RecurId = this.OldRecurringSetting.RecurID;
    }

    //this.NewRecurringInvoiceSetting  = {};
    this.NewRecurringInvoiceSetting =
    {
      "EnableRecurringSetting": this.step1Form.value.enableRecurring,
      "RecurInterval": this.step1Form.value.interval,
      "RecurPeriod": this.step1Form.value.intervalValue,
      "RecurStartDate": this.step1Form.value.fromDate,
      "RecurEndDate": (this.step1Form.value.toDate == [] || this.step1Form.value.toDate == null ? null : this.step1Form.value.toDate),
      "isNeverEnd": this.step1Form.value.neverEnd,
      "statusID": this.step1Form.value.statusID,
      "RecurID": RecurId,
      "NextInvoiceDate": this.nextInvoiceDate,
      "TotalOccurrences": this.totalOccurrences,
      "PendingOccurrences": this.pendingOccurrences,
      "TransTypeID": this.transTypeID
    };

    const newInv =
    {
      "InvoiceTransID": this.transID,
      "RecurringInvoiceSetting": this.NewRecurringInvoiceSetting
    }
    if (this.mode == 'view') {
      const oldInv = {
        "InvoiceTransID": this.transID,
        "RecurringInvoiceSetting": this.OldRecurringSetting
      }
      this.pageID = this.page.pageDetails.pageID;
      let apiUrl = environment.salesApiUrl + '/SalesInvoice/SaveUpdateRecurrenceSetting/';
    
      this.http.postserviceNewInvoiceOrder(apiUrl, JSON.stringify(oldInv), JSON.stringify(newInv), 25,this.pageID).subscribe(
        {
          next: (data) => {
            this.saveEvent.emit(data);
          }
        });
    }
    else {
      this.saveEvent.emit(this.NewRecurringInvoiceSetting);
    }
    this.showRecurringInvoice.hide();
  }

  changeEnableRecurring(event) {
    if (event.target.checked) {
      this.step1Form.value.enableRecurring = true
      this.isEnableDiv = false;
    }
    else {
      this.step1Form.value.enableRecurring == false
      this.isEnableDiv = true;
    }
  }

  getRecurringOccurrences() {
    // if (this.step1Form.value.toDate == null) {
    //   this.step1Form.patchValue({ toDate: moment('1900-01-01').format('YYYY/MM/DD') })
    // }
    if (this.step1Form.value.interval == 0)
      return;

    if ((this.step1Form.value.fromDate !== null && this.step1Form.value.toDate !== null) || this.step1Form.value.neverEnd.toString() == "true") {
      let params = new HttpParams();
      params = params.set('startDate', this.step1Form.value.fromDate.toString());
      params = params.set('endDate', this.step1Form.value.neverEnd.toString() == "true" ? moment('1900-01-01').format('YYYY/MM/DD').toString() : this.step1Form.value.toDate.toString());
      params = params.set('RecurInterval', this.step1Form.value.interval);
      params = params.set('RecurPeriod', this.step1Form.value.intervalValue);
      params = params.set('isNeverEnd', this.step1Form.value.neverEnd);
      params = params.set('TransID', this.transID);
      params = params.set('TransTypeID', this.transTypeID);
      this.http.postservice(environment.salesApiUrl + '/SalesInvoice/GetRecurringOccurrences', params).subscribe({
        next: (data) => {
          this.totalOccurrences = data["ResponseData"][0]["Column1"];
          this.pendingOccurrences = data["ResponseData"][0]["Column2"];
          this.nextInvoiceDate = data["ResponseData"][0]["Column3"];//(data["ResponseData"][0]["Column3"]!==null? moment(data["ResponseData"][0]["Column3"]).format("DD/MM/YYYY").toString() :"");
        }
      });
    }
  }

  changeStartOn() {
    this.startDateErrorMsg='';
    if (this.step1Form.value.fromDate && this.isEnableDiv && !this.isneverEnd) {
      const current = new Date(this.step1Form.value.fromDate);
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
      // this.step1Form.patchValue({ toDate:[null]});
    }
    if (this.step1Form.value.toDate < this.step1Form.value.fromDate && this.step1Form.value.neverEnd!==true) {
      this.startDateErrorMsg = this.translate.instant("PurchaseModule.Bills.Start Date cannot be greater than end date");
      return;
    }
    if(this.toDateErrorMsg!=null && this.toDateErrorMsg!=undefined  && this.step1Form.value.toDate >= this.step1Form.value.fromDate && this.step1Form.value.neverEnd!==true){
      this.toDateErrorMsg='';
    }
    this.getRecurringOccurrences();

  }

  changeEndOn() {
    this.toDateErrorMsg='';
    if (this.step1Form.value.toDate) {
      if (this.step1Form.value.toDate >= this.step1Form.value.fromDate) {
        this.step1Form.patchValue({ neverEnd: false });
      }
      if(this.startDateErrorMsg!=null && this.startDateErrorMsg!=undefined && !this.startDateErrorMsg.includes("invoice") && this.step1Form.value.toDate >= this.step1Form.value.fromDate && this.step1Form.value.neverEnd!==true){
        this.startDateErrorMsg='';
      }else if(this.step1Form.value.toDate < this.step1Form.value.fromDate){
        this.toDateErrorMsg = this.translate.instant("PurchaseModule.Bills.Ends On should be greater than Start Date");
        return;
      }

      this.getRecurringOccurrences();
    }
  }

  changeNeverEnd(event) {
    if (this.step1Form.value.neverEnd == true) {
      this.isneverEnd = true;
      if(this.startDateErrorMsg!=null && this.startDateErrorMsg!=undefined && !this.startDateErrorMsg.includes("invoice")){
        this.startDateErrorMsg='';
      }
      //this.step1Form.patchValue({ toDate:['']})
    } else {
      this.isneverEnd = false;
      this.toDateErrorMsg='';
      if(this.step1Form.value.toDate < this.step1Form.value.fromDate){
        this.toDateErrorMsg = this.translate.instant("PurchaseModule.Bills.Ends On should be greater than Start Date");
        return;
      }
    }
    this.getRecurringOccurrences();

  }

  onRecurringSettingCancel() {
    this.step1Form.patchValue({toDate:null});
    this.showRecurringInvoice.hide();
  }

  statusChange(e) {
    if (e != undefined) {
      if (e.value === 4) {
        this.isOptional = true;
      }
      else {
        this.isOptional = false;
      }
    }
  }
  //*********Email send functions
  emailFormValues() {
    this.emailtemplateForm = this.formBuilder.group({
      emailTempID: [0],
      from: [''],
      replyTo: [''],
      orgID: [0],
      to: ['', [CustomTxtValidator.required, ValidMultipleEmailWithSemicolonComma()]],
      subject: ['', CustomTxtValidator.required],
      cc: ['', ValidMultipleEmailWithSemicolonComma()],
      bcc: ['', ValidMultipleEmailWithSemicolonComma()],
      //body: [this.text],
      markasSent: [true],
      Sendmecopy: [false],
      fromDate: [''],
      toDate: [''],
      ContactID: [0],
      transID: [0],
      UserId: [0],
      pageID: [0],
      typeID: [0],
      templateId: [0],
      statementType: [''],
      expiryDate: ['']
    });
  }

  loadTemplateName() {
    this.http.getservice(environment.templateApiUrl + '/EmailSetting/GetEmailTemplates?TypeID=' + this.typeID).subscribe({
      next: (data: any) => {
        this.lstEmailTemplate = data;
        this.defaultselectedOption = this.lstEmailTemplate.filter(x => x.isDefault == true)[0].emailTempID
        this.loadTemplate(this.defaultselectedOption);
      }
    });
  }

  loadTemplate(emailTempID) {
    let objEmailTempParams = {
      transID: 0,
      typeID: 6,
      emailTempID: emailTempID
    }
    this.http.postservice(environment.templateApiUrl + '/EmailTemplate/GetEmailTemplate', objEmailTempParams).subscribe({
      next: (data: any) => {
        this.emailtemplateForm.patchValue(data);
        this.loadPlaceholder(data.mapPlaceHolderTypeID);
        if (this.emailtemplateForm.value.cc != null) {
          if (this.emailtemplateForm.value.cc != "")
            this.ccEnable = true;
        }
        if (this.emailtemplateForm.value.bcc != null) {
          if (this.emailtemplateForm.value.bcc != "")
            this.bccEnable = true;
        }
      }
    });
  }

  async loadPlaceholder(mapPlaceHolderTypeID) {
    this.phdetails = await this.master.GetPlaceholder(mapPlaceHolderTypeID);
  }
  onSubmit() {
    this.submitted = true;
    if (this.emailtemplateForm.invalid) {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
      firstInvalidControl.focus();
      return;
    }
  }


  showCC() {
    if (!this.ccEnable)
      this.ccEnable = true;
    else
      this.ccEnable = false;
  }
  showBcc() {
    if (!this.bccEnable)
      this.bccEnable = true;
    else
      this.bccEnable = false;
  }

  SendEmail() {
    this.SendEmailmodal = 'show';
  }

  emailtemphidemodel(event) {
    this.SendEmailmodal = event;
  }

  SendStatus(issend: boolean) {
    if (issend) {
    }
  }
  //************End Email send functions****************
}
