import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timeStamp } from 'console';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {ContentChange, QuillEditorComponent } from 'ngx-quill';
import { environment } from '../../../../../environments/environment';
import { AlertMessage } from '../../../../utilities/_helpers/alert.message';
import { HttpServices } from '../../../../utilities/_services/http.service';
import { MasterServices } from '../../../../utilities/_services/master.service';
import { PrintService } from '../../../template/services/print.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Quill from 'quill';
import { TranslateService } from '@ngx-translate/core';

const parchment = Quill.import('parchment')
const block = parchment.query('block')
block.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true)
const fontSizeArr = ['6pt', '8pt', '9pt', '10pt', '11pt', '12pt', '13pt', '14pt', '16pt', '18pt', '24pt', '30pt', '36pt', '48pt'];
const Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size /* or NewBlock */, true);

@Component({
  selector: 'app-add-contact-tc',
  templateUrl: './add-contact-tc.component.html',
  styleUrls: ['./add-contact-tc.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddContactTCComponent implements OnInit {
  @ViewChild('showTCmodal') public showTCmodal: ModalDirective;
  alreadyAddressLableExist = false;
  tcForm: FormGroup | any;
  submitted = false;
  termID = 0;
  phdetails: any = [];
  arrayList = [];
  titleExists = false;
  text: string;
  @ViewChild(QuillEditorComponent, { static: true }) body: QuillEditorComponent;
  @Output() getTC: EventEmitter<any> = new EventEmitter<any>();
  @Input() TransactionTypeID: number = null;
  @Input() ContactID: number = null;

  constructor(private formBuilder: FormBuilder,

    private http: HttpServices,
    private masterServices: MasterServices,
    private alertMessage: AlertMessage,
    public printService: PrintService,
    private translate: TranslateService) {
    this.tcForm = this.formBuilder.group({
      TermsTitle: ['', Validators.required],
      TermCon: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.loadPlaceholder('1,3');
    
    this.body
    .onContentChanged
    .pipe(
      debounceTime(400),
      distinctUntilChanged()
    )
    .subscribe((data: ContentChange) => {
      // tslint:disable-next-line:no-console
      this.text = data.html;
    })
}
  
  get f() { return this.tcForm.controls; }


  hideTCmodal(isRefresh) {
    if (isRefresh) {
      this.getTC.emit(this.tcForm.controls.TermsTitle.value);
    }
    // this.formReset()
    this.tcForm.reset()
    this.submitted = false;
    this.termID = 0;
    this.showTCmodal.hide();
  }

  openModal(obj, arrayList) {
    if (obj != null) {
      this.termID = obj.termsID;
      var tc = obj.termsCondition;
      var tr = '<div>'
      while (tc.replace(/#tbs#/g, '').indexOf('<div>&emsp;') >= 0) {
        tc = tc.replaceAll(tr + '&emsp;', tr + '#tbs#');
        tr = tr + '#tbs#';
      }
      this.tcForm.patchValue({ TermCon: tc.replace(/#tbs#/g, '&nbsp;&nbsp;&nbsp;&nbsp;').replace(/&emsp;/g, '\t'), TermsTitle: obj.termsTitle });
      this.text=this.tcForm.value.TermCon;

    }
    this.arrayList = arrayList;
    this.showTCmodal.show();

  }

  async loadPlaceholder(mapPlaceHolderTypeID) {
    this.phdetails = await this.masterServices.GetPlaceholder(mapPlaceHolderTypeID);
    if (this.phdetails[2] != undefined && this.phdetails[2].submenu != undefined) {
      var filterquote = this.phdetails[2].submenu.filter(x => x.field == "OnlineQuote");
      if (filterquote.length > 0)
        this.phdetails[2].submenu.splice((filterquote[0].index - 1), 1);
    }
  }

  changeCustom(event, codevalue) {
    const editor = this.body.quillEditor;
    if (codevalue != "") {
      let PlaceholderValue: string = codevalue;
      const cursorPosition = editor.getSelection(true).index;
      editor.insertText(cursorPosition, PlaceholderValue);
      editor.setSelection(cursorPosition, PlaceholderValue.length);
    }
  }

  onSubmitTermsCondition() {

    this.submitted = true;
    this.tcForm.patchValue({
      TermCon: this.text
    })
    if (this.tcForm.invalid) {
      return;
    }

    let titlechcek = this.arrayList.filter(res => res.TermsTitle == this.tcForm.controls.TermsTitle.value && res.TermsID != this.termID);
    if (titlechcek.length > 0) {
      this.titleExists = true;
      return;
    }




    let oldTerms = '{}';
    if (this.termID > 0) {
      let oldDelAdd: any = this.arrayList.filter(x => x.termsID == this.termID)[0];
      let strOldTerms = {
        "TermsID": oldDelAdd.termsID,
        "Termscondition": this.printService.returnEncodedString(oldDelAdd.termsCondition.replace(/\t/g, '&emsp;')),
        "ContactID": oldDelAdd.contactID,
        "TermsTitle": oldDelAdd.TermsTitle,
      }
      oldTerms = JSON.stringify(strOldTerms);
    }

    let NewTerms = {
      "TermsID": this.termID,
      "Termscondition": this.printService.returnEncodedString(this.tcForm.controls.TermCon.value.replace(/\t/g, '&emsp;')),
      "TermsTitle": this.tcForm.value.TermsTitle,
      "TransactionTypeID": this.TransactionTypeID == 0 ? null : this.TransactionTypeID,
      "ContactID": this.ContactID==0?null:this.ContactID
    }
    let strNewTerms = JSON.stringify(NewTerms);

    let apiUrl = environment.organizationApiUrl + '/Organization/SaveUpdateContactsTermscondition/'
    this.http.postserviceTerms(apiUrl, oldTerms, strNewTerms).subscribe(
      {
        next: (res: any) => {
          if (res.responseData == 'Please Select Terms') {

            this.alertMessage.errorNotifierManualClose(res.responseData, 0);
          } else {
            let msg = this.translate.instant('PurchaseModule.PurchaseOrder.Terms and Condition has been added Successfully!');
            this.alertMessage.successNotifier(msg, 1);
            setTimeout(() => {
              this.hideTCmodal(true);
            }, 500);

          }
        }
      })
  }

}
