import { AllCommunityModules, ColDef, GridApi, GridOptions, Module } from '@ag-grid-community/all-modules';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpServices } from '../../../../utilities/_services/http.service';
import { ExportService } from '../export.service';
import { environment } from '../../../../../environments/environment';
import { AlertMessage } from '../../../../utilities/_helpers/alert.message';
import { LocalCacheServices } from '../../../../utilities/_services/acclocalcache.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-exportdetail',
  templateUrl: './exportdetail.component.html',
})
export class ExportdetailComponent implements OnInit {

  @ViewChild("exportDetailModal")
  exportDetailModal: ModalDirective;
  overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No more row</span>';
  overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait</span>';
  modules: Module[] = AllCommunityModules;
  columnDefs: ColDef[];
  rowSelection: any = 'multiple';
  rowGroupPanelShow: any = 'always'
  rowData: any[] = [];
  numSelectedColumn: number = 0;
  selectedRow: any[] = []
  gridApi: GridApi;
  payload: any;
  gridOptions: GridOptions;
  columnsDisplayOrder: any[];

  constructor(private http: HttpServices, private local: LocalCacheServices, private exportService: ExportService, private alertMessage: AlertMessage, private translate: TranslateService) {
    this.translate.onDefaultLangChange.subscribe(async () => {
      this.gridOptions.api.refreshHeader();
      this.gridOptions.api.refreshCells({force: true});
    });
    this.translate.onLangChange.subscribe(async () => {
      this.gridOptions.api.refreshHeader();
      this.gridOptions.api.refreshCells({force: true});
    });
  }

  ngOnInit(): void {
    this.gridOptions = <GridOptions>{
      animateRows: true,
      onGridReady: this.onGridReady.bind(this),
      onRowDragEnd: this.onRowDragEnd.bind(this),
      onRowDataChanged: this.onRowDataChange.bind(this),
      onSelectionChanged: this.onSelectionChanged.bind(this),
      onGridSizeChanged: this.onGridSizeChanged.bind(this),
      getRowNodeId: this.getRowNodeId.bind(this),
      getRowStyle: this.getRowStyle.bind(this),
      rowDragManaged: true,
    };
    this.createColumnDefs();
  }

  getRowNodeId(data: any) {
    return data.ColumnID?.toString() + data.IsCustomField?.toString();
  }

  onRowDragEnd(event: any) {
    this.columnsDisplayOrder = [];
    event.api.forEachNode((node, index) => {
      this.columnsDisplayOrder.push({ ColumnID: node.data.ColumnID, DisplayOrder: index + 1 });
    });
  }

  private createColumnDefs() {
    this.columnDefs = [
      {
        width: 20,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        rowDrag: (params) => {
          return !(params.data!.IsCustomField ?? false);
        },
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: (params) => {
          return params.data.IsMandatory
            ? { 'pointer-events': 'none', opacity: '0.5' }
            : '';
        }
      },
      {
        headerName: 'Column Name',
        field: 'DisplayName',
        headerValueGetter: (params) => this.translate.instant("InventoryModule.Import.Column Name"),
        cellRenderer: (data)=> {
          return this.translate.instant(data.value);
        }
      },
    ]
  }

  private getColumns() {
    let user = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    this.http.getservice(environment.administrationApiUrl + '/Admin/GetExportColumns?TransTypeID=' + this.payload.TransactionTypeID).subscribe({
      next: (data: any) => {
        if(user.licenseTypeID == 9 && this.payload.TransactionTypeID == 2){
          data = data.filter(item => item.DisplayName !== 'Received');
        }
        data.forEach((element) => {
          if (element.DisplayName.indexOf("%CurCode%") > -1) {
            element.DisplayName = element.DisplayName.replace("%CurCode%",user["orgCurrencyCode"]);
          }
        });
        this.rowData = data;
        this.gridApi.setRowData(this.rowData);
      }
    });
  }

  onRowDataChange() {
    this.gridApi?.forEachNode((n) => n.setSelected(n.data.IsMandatory || n.data.IsSelected));
    this.columnsDisplayOrder = this.rowData.map((row, index) => ({
      ColumnID: row.ColumnID,
      DisplayOrder: index + 1
    }));
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
    this.gridApi = params.api;
  }

  getRowStyle(params) {
    if (!params.data.IsActive) {
      return { 'background-color': '#FFDDDD' }
    }
    return null;
  }

  onGridSizeChanged(params: any) {
    params.api.sizeColumnsToFit();
  }

  onSelectionChanged(params: any) {
    let selectedcell = params.api.getSelectedRows();
    this.numSelectedColumn = selectedcell.length;
    this.gridApi.forEachNode((n) => {
      if (n.data.IsMandatory) n.setSelected(true, false);
    });
    this.numSelectedColumn = selectedcell.length;
    this.selectedRow = selectedcell;
  }

  onExport() {
    let selectedDetailColumns = this.gridApi.getSelectedRows();
    if (selectedDetailColumns.length <= 0) {
      this.alertMessage.errorNotifier('Select alteast one column', 0);
      return;
    }
    this.payload = {
      ...this.payload,
      SelectedDetailColumns: selectedDetailColumns,
      ColumnsDisplayOrder: this.columnsDisplayOrder
    }
    this.exportService.export(this.payload)
      .then(() => this.closeModal());
  }

  openModal(payload) {
    this.payload = payload;
    this.exportDetailModal.show();
    this.getColumns();
  }

  closeModal() {
    this.exportDetailModal.hide();
    this.payload = {};
  }

}