import { SimpleChanges } from "@angular/core";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from "@angular/core";
import { AlertMessage } from "../../../../utilities/_helpers/alert.message";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-column-selection-drop-down",
  templateUrl: "./column-selection-drop-down.component.html",
  styleUrls: ["./column-selection-drop-down.component.scss"],
})
export class ColumnSelectionDropDownComponent implements OnInit {
  @Input() columnsSelected = [];
  @Input() allColumns = [];
  @Input() label = 'Columns';
  @Input() placeHolder='Select columns';
  @Input() customLabel='Columns Selected';
  @Input() callFrom?="";
  @Output() ColumnSelectEmitValue = new EventEmitter<any[]>();

  allColumnsSelectedTxt: string = "Colums Selected";
  warningNotifierId = 0;
  constructor(private alertMessage: AlertMessage, private translate: TranslateService) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.columnsSelected){    
      this.columnsSelected=this.columnsSelected.filter(e=>e.field!='linkDetail');
      if (changes?.allColumns?.currentValue) {
        this.allColumns = this.allColumns.filter(ele=>ele.title!="").sort((a, b) =>
          a.title.localeCompare(b.title)
        );
      }
    }
  }

  selectAllColumn(isSelectAll: boolean) {
    if (isSelectAll) {
      this.columnsSelected = this.allColumns;
    } else {
      this.columnsSelected = [];
    }
    this.ColumnSelectEmitValue.emit(this.columnsSelected);
  }

  changeColumn(selVal) {
    // if(this.columnsSelected.length > 10 && this.callFrom == 'profitandloss'){
    //   let a = selVal.slice(0,10);
    //   this.ColumnSelectEmitValue.emit(a);
    //   this.warningNotifierId = this.alertMessage.warningNotifier(this.translate.instant("SharedModule.Maximum of 10 Custom Field Only can select"), this.warningNotifierId);
    // }  
    // else{
    //   this.ColumnSelectEmitValue.emit(selVal); 
    // }  
    this.ColumnSelectEmitValue.emit(selVal); 
  }
  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.title.toLocaleLowerCase().indexOf(term) > -1;
  }
  
  toggleGroupSelection(group: string, checked: boolean): void {
    const itemsInGroup = this.allColumns.filter(item => item.ReportType === group);
    
    if (checked) {
      itemsInGroup.forEach(item => {
        if (!this.columnsSelected.includes(item)) {
          this.columnsSelected.push(item);
        }
      });
    } else {
      itemsInGroup.forEach(item => {
        const index = this.columnsSelected.indexOf(item);
        if (index !== -1) {
          this.columnsSelected.splice(index, 1);
        }
      });
    }
  this.changeColumn(this.columnsSelected);
  }
}
