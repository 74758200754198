
import { Injectable } from "@angular/core";
import { reporttype } from "../../Service/pdfmake.model";

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  rowType: string;
  data: any;
  groupid:any;
}  

@Injectable({
  providedIn: "root",
})
export class PublishGroupingService {
  constructor() {}

  addGroups(data: any[], groupByColumns: any[]): any[] {
    var rootGroup = new Group();
    let res = this.getSublevel(data, 0, groupByColumns, rootGroup);
    return res;
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    // Recursive function, stop when there are no more levels.
    if (level >= groupByColumns.length)
      return data;

    var groups = this.uniqueBy(
      data.map(
        row => {
          var result = new Group();
          result.level = level + 1;
          result.parent = parent;
          result.groupid = row.groupid;
          result.rowType = row.rowType;
          for (var i = 0; i <= level; i++)
            result[groupByColumns[i]] = row[groupByColumns[i]]  ;
          return result;
        }
      ),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    data = data.filter(res=>res.RowType != 'total');
    let grandtotal : any;
    if(data.filter(res=>Object.keys(res).includes('total')).length > 0){
      grandtotal = data.filter(res=>res.RowType.toLowerCase() == 'total')[0];}

    var subGroups = [];
    groups.forEach(group => {
      let rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn] && row.RowType == '');
    let subTotal: any;
    if(data.filter(res=>Object.keys(res).includes('subtotal')).length > 0){
      subTotal = data.filter(row => group[currentColumn] === row[currentColumn] && row.RowType.toLowerCase() == 'subtotal');}
    
     if(rowsInGroup.length > 0){
      rowsInGroup.forEach(res=>{ res.levelGroup = level + 1 })
      let subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);

      if(group[currentColumn] != null){

        if (subTotal != undefined && subTotal != null){
        if(subTotal.length > 0 ){
          subGroup=subGroup.concat(subTotal);
        }}
      subGroup.unshift(group);

      }
      subGroups = subGroups.concat(subGroup);
      }
    })
    if(grandtotal)
    subGroups.push(grandtotal);
    return subGroups;
  }

  uniqueBy(a, key) {
    var seen = {};
    return a.filter(function (item) {
      var k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
  }




}

