import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

export default class Utils {
  static DiscountType() {
    var discountType =
      [
        {
          "name": "At Transaction Level",
          "DisTypeID": 1
        },
        {
          "name": "At Item Level",
          "DisTypeID": 2
        }
      ]
    return discountType;
  }

  static copyToContent() {
    var copyContent =
      [
        {
          "ID":4,
          "CopyType": "Bill",
          "Content": "Turn this Purchase Order into a Bill to pay"
        },
        {
          "ID":3,
          "CopyType": "Purchase Order",
          "Content": "Reuse the contents for a new Purchase Order"
        },
        {
          "ID":2,
          "CopyType": "Invoice",
          "Content": "Charge this Purchase Order to a customer"
        },
        {
          "ID":5,
          "CopyType": "Quote",
          "Content": "Reuse the contents for a new Quote"
        },
        {
          "ID":6,
          "CopyType": "Sales Order",
          "Content": "Reuse the contents for a new Sales Order"
        }
      ]
    return copyContent;
  }

  GroupBYName(data, key) {
    return data.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});;
  }

  getNumberFromString(str: string) {
    const regex = /\d+/g;
    return str.match(regex)?str.match(regex) : 0;
  }

  checkSpecialCharFrmString(strValu:string){
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if(format.test(strValu)){
        return true;
      } else {
        return false;
      }
  }

  numberFormatToFixed(val: Number) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    let toFixed: Number = val ? val : 0;
    return formatter.format(Number(toFixed))
  }

  static copyToQuotationContent() {
    var copyQuoteContent =
      [
        {
          "ID":1,
          "CopyType": "Quote",
          "Content": "Reuse the contents for a new Quote"
        },
        {
          "ID":8,
          "CopyType": "Invoice",
          "Content": "Charge this Quote to a customer"
        },
        {
          "ID":2,
          "CopyType": "Delivery Order",
          "Content": "Reuse the contents for a new Delivery Order"
        },
        {
            "ID":30,
            "CopyType": "Progress invoice",
            "Content": "Create Progressive Invoice"
          },
          {
            "ID":5,
            "CopyType": "Sales Order",
            "Content": "Reuse the contents for a new Sales Order"
          },
          {
            "ID":3,
            "CopyType": "Purchase Order",
            "Content": "Reuse the contents for a new Purchase Order"
          },
          {
            "ID":4,
            "CopyType": "Bill",
            "Content": "Turn this Quotation into a Bill to pay"
          }
      ]
    return copyQuoteContent;
  }

  static copyToInvoiceContent() {
    var copyQuoteContent =
      [
        {
          "ID":1,
          "CopyType": "Quote",
          "Content": "Reuse the contents for a new Quote"
        },
        {
          "ID":8,
          "CopyType": "Invoice",
          "Content": "Charge this Quote to a customer"
        },
        {
          "ID":2,
          "CopyType": "Delivery Order",
          "Content": "Reuse the contents for a new Delivery Order"
        }
      ]
    return copyQuoteContent;
  }
  static copyToDebitNoteContent() {
    var copyDebitNoteContent =
      [
        {
          "ID":1,
          "CopyType": "Invoice",
          "Content": "Reuse the contents for a new Invoice"
        },
        {
          "ID":2,
          "CopyType": "Debit Note",
          "Content": "Reuse the contents for a new Debit Note"
        }
      ]
    return copyDebitNoteContent;
  }
  static copyToPurchaseDebitNoteContent() {
    var copyDebitNoteContent =
      [
        {
          "ID":1,
          "CopyType": "Bill",
          "Content": "Reuse the contents for a new Bill"
        },
        {
          "ID":2,
          "CopyType": "Debit Note",
          "Content": "Reuse the contents for a new Debit Note"
        }
      ]
    return copyDebitNoteContent;
  }
  static copyToSalesOrderContent() {
    var copySalesOrderContent =
      [
        {
          "ID":1,
          "CopyType": "Sales Order",
          "Content": "Reuse the contents for a new Sales Order"
        },
        {
          "ID":2,
          "CopyType": "Invoice",
          "Content": "Charge this Sales Order to a customer"
        },
        {
          "ID":3,
          "CopyType": "Delivery Order",
          "Content": "Reuse the contents for a new Delivery Order"
        },
        {
          "ID":4,
          "CopyType": "Purchase Order",
          "Content": "Reuse the contents for a new Purchase Order"
        },
        {
          "ID":5,
          "CopyType": "Bill",
          "Content": "Reuse the contents for a new Bill"
        }
      ]
    return copySalesOrderContent;
  }

}

