import { Component, OnInit, Inject,Input  } from '@angular/core';
import { MasterServices } from '../../../../utilities/_services/master.service';
import { AddcurrencyDialogService } from "../addcurrency-dialog.service";
import { FormBuilder, FormGroup, FormArray, Validators, NgForm } from '@angular/forms';
import { CustomValidator } from '../../../../utilities/_directives/customvalidator.directive';
import { environment } from '../../../../../environments/environment'
import { HttpServices } from '../../../../utilities/_services/http.service';
import { PageServices } from '../../../../utilities/_services/page.service';
import { AlertMessage } from '../../../../utilities/_helpers/alert.message'

import { MatDialog, MatDialogRef, MatDialogModule, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
  exchangeDate: string
  orgCurrencyCode:string
}

@Component({
  selector: 'app-addcurrency-dialog',
  templateUrl: './addcurrency-dialog.component.html',
  styleUrls: ['./addcurrency-dialog.component.scss']
})
export class AddcurrencyDialogComponent implements OnInit {
  addCurrencyForm: FormGroup | any;
  loading = false;
  submitted = false;
  currencyTypeID: number;
  exchangeRate: any = "0.00"
  exchangeDate:any;
  orgCurrencyCode:string;

  lstCurrencyType: any;
  constructor(private master: MasterServices, private formBuilder: FormBuilder, private http: HttpServices,
    private page: PageServices, private alertMessage: AlertMessage,
    public dialogRef: MatDialogRef<AddcurrencyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.exchangeDate=data.exchangeDate;
    this.orgCurrencyCode =data.orgCurrencyCode;
    this.loadCurrencyType();
  }
  async loadCurrencyType() {
    let unUsedCunnecyType:any = await this.master.getAllCurrencyTypes();
    this.lstCurrencyType = unUsedCunnecyType.filter(x=> x.InUse == false)
  }

  ngOnInit(): void {
    this.addCurrencyForm = this.formBuilder.group({
      currencyTypeID: [0, CustomValidator.required],
      pageID: [0]
    });
  }
  get f() { return this.addCurrencyForm.controls; }

  getLiveExchangeRate() {   
    let currencyTypeID = this.addCurrencyForm.value.currencyTypeID
    let selectedRate = this.lstCurrencyType.filter(x => x.CurrencyTypeID == currencyTypeID);
    let toCurrencyCode = selectedRate[0].CurrencyCode
    this.http.getservice(environment.organizationApiUrl + '/CurrencySetting/GetLiveExchangeRate?OrgCurrencyCode='+this.orgCurrencyCode +'&ToCurrencyCode='+toCurrencyCode+'&ExchangeDate=' + this.exchangeDate).subscribe({
      next: (data) => {       
        this.exchangeRate = data[0].rate;
      }
    });    
  }

  onSubmit() {
    this.submitted = true;
    if (this.addCurrencyForm.invalid) {
      return;
    }
    this.submitted = false;
    this.loading = true;
    this.addCurrencyForm.patchValue({
      PageID: this.page.pageDetails.pageID
    })    
    this.http.postservice(environment.organizationApiUrl + '/CurrencySetting/UpdateUseCurrenyType', this.addCurrencyForm.value).subscribe({
      next: (data1) => {
        if (data1 > 0) {
          this.closeDialog('save');
          this.loading = false;
        }
      }
    });
  }

  closeDialog(event: string) {
    this.dialogRef.close({ event: event, currencyTypeID: this.addCurrencyForm.value.currencyTypeID });
  }
}