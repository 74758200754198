<ul class="nav">
    <ng-template ngFor let-navitem [ngForOf]="navItems">
        <li *ngIf="!isHasChild(navitem)" (click)="parentPageID=(navitem.pageid)"
            [ngClass]="{'nav-title': isTitle(navitem), 'nav-item': !(isTitle(navitem) || isDivider(navitem)), 'nav-divider': isDivider(navitem)}">
            <span id="btn-Parent-{{navitem.name}}" *ngIf="isTitle(navitem)">{{navitem.name | translate}}</span>
            <span *ngIf="isDivider(navitem)"></span>
            <a id="btn-Parent-{{navitem.name}}" class="nav-link" *ngIf="!(isTitle(navitem) || isDivider(navitem))"
                [routerLink]="navitem.url" routerLinkActive="active">
                <i class="nav-icon material-icons-outlined"> {{navitem.icon}}</i> {{navitem.name | translate}}
            </a>
        </li>
        <li *ngIf="isHasChild(navitem)" appNavDropdown routerLinkActive="open"
            [ngClass]="parentPageID==navitem.pageid?'nav-item nav-dropdown open':'nav-item nav-dropdown'"
            (click)="parentPageID=(navitem.pageid)">
            <a id="btn-Parent-{{navitem.name}}" appNavDropdownToggle class="nav-link nav-dropdown-toggle"
                role="button"><i class="nav-icon material-icons-outlined">{{navitem.icon}}</i> {{navitem.name | translate}}</a>
            <ul class="nav-dropdown-items">
                <li class="nav-item" *ngFor="let cnavitem of navitem.children">
                    <a id="btn-{{navitem.name}}-Child-{{cnavitem.name}}" class="nav-link"
                        *ngIf="!(isTitle(cnavitem) || isDivider(cnavitem))" [routerLink]="cnavitem.url"
                        routerLinkActive="active">
                        <i class="nav-icon material-icons-outlined">arrow_right</i>{{cnavitem.name | translate}}
                    </a>
                </li>
            </ul>
        </li>
    </ng-template>
</ul>