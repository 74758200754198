<label class="col-form-label" for="columns">{{label | translate}}</label>
<!-- <button type="button" *ngIf="callFrom=='profitandloss'" style="margin-top: -5px;" triggers="mouseenter:mouseleave" [popover]="popTemplateHtml" class="btn inforight pb-0">
  <i class="fa fa-info-circle"></i></button>
<ng-template #popTemplateHtml>
  <div><span class="help-block text-info">{{'SharedModule.Only can select 10 custom fields' | translate}}</span>
  </div>
</ng-template> -->
<ng-select [multiple]="true" id="columns" class="auto-grow" [items]="allColumns"
  (change)="changeColumn(columnsSelected)" name="columnSelect" [(ngModel)]="columnsSelected"  bindLabel="field"
  [closeOnSelect]="false" [clearable]="false" groupBy="ReportType" [placeholder]="placeHolder" [searchFn]="customSearchFn" 
  [selectableGroup]="callFrom=='profitandloss' || callFrom=='trialbalance'" [selectableGroupAsModel]="false"> 

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngIf="items.length > 0">
      <span class="ng-value-label">{{columnsSelected.length}} {{customLabel}}</span>
    </div>
  </ng-template>
  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index" *ngIf="callFrom=='profitandloss' || callFrom=='trialbalance'">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
      (change)="toggleGroupSelection(item['ReportType'], $event.target.checked)" [ngModelOptions]="{standalone: true}" /> 
      {{item['ReportType']}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" />
    {{item['title']}}
  </ng-template>
  <ng-template ng-header-tmp>
      <button id="btnSelectAllColumn" (click)="selectAllColumn(true)" class="btn btn-sm btn-secondary" *ngIf="(callFrom!='profitandloss' && callFrom!='trialbalance') || ((callFrom=='profitandloss' || 'trialbalance') && allColumns.length > 0)">
          {{'ReportsModule.Select All' | translate}}
      </button>&nbsp;
      <button id="btnUnSelectAllColumn" (click)="selectAllColumn(false)" class="btn btn-sm btn-secondary" *ngIf="(callFrom!='profitandloss' &&  callFrom!='trialbalance') || ((callFrom=='profitandloss' || 'trialbalance') && columnsSelected.length > 0)">
          {{'ReportsModule.Unselect All' | translate}}
      </button>
  </ng-template>
</ng-select>
