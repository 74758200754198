export class MalaysiaStatecode {
static State:any=[
        {
            "Code": "00",
            "State": "All States"
        },
        {
            "Code": "01",
            "State": "Johor"
        },
        {
            "Code": "02",
            "State": "Kedah"
        },
        {
            "Code": "03",
            "State": "Kelantan"
        },
        {
            "Code": "04",
            "State": "Melaka"
        },
        {
            "Code": "05",
            "State": "Negeri Sembilan"
        },
        {
            "Code": "06",
            "State": "Pahang"
        },
        {
            "Code": "07",
            "State": "Pulau Pinang"
        },
        {
            "Code": "08",
            "State": "Perak"
        },
        {
            "Code": "09",
            "State": "Perlis"
        },
        {
            "Code": "10",
            "State": "Selangor"
        },
        {
            "Code": "11",
            "State": "Terengganu"
        },
        {
            "Code": "12",
            "State": "Sabah"
        },
        {
            "Code": "13",
            "State": "Sarawak"
        },
        {
            "Code": "14",
            "State": "Wilayah Persekutuan Kuala Lumpur"
        },
        {
            "Code": "15",
            "State": "Wilayah Persekutuan Labuan"
        },
        {
            "Code": "16",
            "State": "Wilayah Persekutuan Putrajaya"
        },
        {
            "Code": "17",
            "State": "Not Applicable"
        }
    ];
}
