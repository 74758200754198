import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { LoaderService } from '../_services/loader.service';
import * as forge from 'node-forge';
import { json } from '@rxweb/reactive-form-validators';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorDialogService } from '../../views/shared/errors/error-dialog.service';
import { NEVER } from 'rxjs';
import { AlertMessage } from './alert.message';
import { Environment } from '@ag-grid-community/all-modules';


@Injectable({
  providedIn: 'root'
})
export class EncdeInterceptor implements HttpInterceptor {
  urlsToSkiploading: Array<string>;
  urlsToSkipRequestEncrypt: Array<string>;
  urlsToSkipResponseEncrypt: Array<string>;

  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAskgPKBcNpz71mi4NSYa5
  mazJrO0WZim7T2yy7qPxk2NqQE7OmWWakLJcaeUYnI0kO3yC57vck66RPCjKxWuW
  SGZ7dHXe0bWb5IXjcT4mNdnUIalR+lV8czsoH/wDUvkQdG1SJ+IxzW64WvoaCRZ+
  /4wBF2cSUh9oLwGEXiodUJ9oJXFZVPKGCEjPcBI0vC2ADBRmVQ1sKsZg8zbHN+gu
  U9rPLFzN4YNrCnEsSezVw/W1FKVS8J/Xx4HSSg7AyVwniz8eHi0e3a8VzFg+H09I
  5wK+w39sjDYfAdnJUkr6PjtSbN4/Sg/NMkKB2Ngn8oj7LCfe/7RNqIdiS+dQuSFg
  eQIDAQAB
  -----END PUBLIC KEY-----`;

  keySize = 256;
  ivSize = 128;
  saltSize = 256;
  iterations = 1000;

  randomKeyValue: string = this.randomKey(16);

  constructor(private alertMessage: AlertMessage, private authenticationService: AuthenticationService, public loaderService: LoaderService) {
    this.urlsToSkiploading = [
      'clientapp/verifyclient'
    ];
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var isEncryptionSuccess = true;
    if (this.isValidRequestForInterceptor(req.url)) {
      this.loaderService.show();
    }
    let ismobile: boolean;
    ismobile = false;
    if (!ismobile) {   
      var encryptedBody;
      let contentType: string = "";
      if (req.body != null) {
        encryptedBody = JSON.stringify(req.body);

        if ((req.body instanceof HttpParams))
          contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
        else
          contentType = 'application/json';

      }
      //65308 indicates unicode char of <
      //65310 indicates unicode char of >        
      if( !req.url.includes("Ticket/InsertUpdateTickets")
        && !req.url.includes("ReplyTicket_DigismeCrm")
        //&& !req.url.includes("ImportDataValidation")
        && !req.url.includes("Document/fileattachment")
        && !req.url.includes("UpdateTemplate")
        && !req.url.includes("PreviewTemplateFromForm")
        && !req.url.includes("PrintTemplateFromForm")
        && !req.url.includes("GetTickets_DigismeCrm")
        && !req.url.includes("CreateTicket_DigismeCrm")
        && !req.url.includes("GetTicketDetails_DigismeCrm")
        && !req.url.includes("DeleteAttachments_DigiSME")
        && !req.url.includes("GetAttachments_DigigSME")
        && !req.url.includes("UploadTemplate")
        && req.body != null && (encryptedBody.includes(">") || encryptedBody.includes("<") || this.isUniqueCodeCharExists(encryptedBody))){
        this.alertMessage.errorNotifier("Invalid data, Your input value should not contain < or > symbols", 1);
        this.loaderService.hide();
        return NEVER;
      }

      if (environment.isEncDecAllowed) {
        var rsa = forge.pki.publicKeyFromPem(this.publicKey);
        var rsaKey = window.btoa(rsa.encrypt(this.randomKeyValue));

        if (req.method.toLowerCase() == "get") {
          let query: string = "";
          let splitURL = req.url.split('?');

          try {
            if (typeof (splitURL[1]) != 'undefined') {
              query = this.encrypt(splitURL[1], this.randomKeyValue);
            }

            if (query != "") {
              query = splitURL[0] + "?" + query;
            }
            else {
              query = splitURL[0];
            }

            req = req.clone({
              url: query,
              setHeaders: {
                'Custom-Key': rsaKey,
                'Custom-Allowed': 'true'.toLowerCase(),
                'Custom-ResponseAllowed': environment.isResponseEncAllowed.toString()
              }
            });
          }
          catch {
            isEncryptionSuccess = false;
          }
        }

        if (req.body != null) {
          try {
            req = req.clone({
              body: JSON.parse('{"str":"' + this.encrypt(encryptedBody, this.randomKeyValue) + '"}'),
              setHeaders: {
                'Custom-Key': rsaKey,
                'Content-Type': contentType,
                'Custom-Allowed': 'true'.toLowerCase(),
                'Custom-ResponseAllowed': this.isValidRequestForResponseEncrypt(req.url) ? environment.isResponseEncAllowed.toString() : "false"
              }
            });
          }
          catch {
            isEncryptionSuccess = false;
          }
        }

        if (req.method.toLowerCase() == "post" && req.body == null) {
          try {
            req = req.clone({
              setHeaders: {
                'Custom-Key': rsaKey,
                'Content-Type': contentType,
                'Custom-Allowed': 'true'.toLowerCase(),
                'Custom-ResponseAllowed': this.isValidRequestForResponseEncrypt(req.url) ? environment.isResponseEncAllowed.toString() : "false"
              }
            });
          } catch {
            isEncryptionSuccess = false;
          }
        }

        if (environment.isResponseEncAllowed && isEncryptionSuccess) {
          return next.handle(req).pipe(
            map((resp: any) => {
              if (resp instanceof HttpResponse) {
                var resenc = resp.headers.get("Custom-ResponseProcessed");
                if (resp.body && resenc === "true") {
                  var json = this.decrypt((resp.body as any).str, this.randomKeyValue);
                  resp = resp.clone<any>({ body: JSON.parse(json) });
                  return resp;
                }
                else {
                  return resp;
                }
              }
              else return resp;
            })
          );
        } else {
          return next.handle(req);
        }
      }
      else {
        return next.handle(req);
      }
    }
    else {
      req = req.clone({
        body: encryptedBody,
        setHeaders: {
          'device': 'mobile'
        }
      });
    }
    /* let contentType = req.detectContentTypeHeader();
     console.log(contentType); */
    return next.handle(req);
  }

  randomKey(length: number) {
    var result = '';
    var characters = '@abcdefghijklmnopqrstuvwxyz123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  encrypt(msg, pass) {
    var salt = CryptoJS.lib.WordArray.random(this.saltSize / 8);

    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: this.keySize / 32,
      hasher: CryptoJS.algo.SHA1,
      iterations: this.iterations
    });

    var iv = CryptoJS.lib.WordArray.random(this.ivSize / 8);

    var encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC

    });

    var encryptedHex = this.base64ToHex(encrypted.toString());

    var base64result = this.hexToBase64(salt + iv + encryptedHex);

    return base64result;
  }

  decrypt(transitmessage, pass) {

    var hexResult = this.base64ToHex(transitmessage)

    var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
    var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
    var encrypted = this.hexToBase64(hexResult.substring(96));

    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: this.keySize / 32,
      hasher: CryptoJS.algo.SHA1,
      iterations: this.iterations
    });

    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC

    })

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null,
      str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
    );
  }

  base64ToHex(str) {
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
      var tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = "0" + tmp;
      hex[hex.length] = tmp;
    }
    return hex.join("");
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.urlsToSkiploading) {
        if (new RegExp(address.toLowerCase()).test(destination.toLowerCase())) {
          //console.log("address ", address.toLowerCase());
          return false;
        }
      }
    }
    return true;
  }
  private isValidRequestForResponseEncrypt(requestUrl: string): boolean {
    return true;
  }


  private isUniqueCodeCharExists(inputText: string) {
    let isExists = false;
    for (let i = 0; i < inputText.length; i++) {
      let ASCIICode = inputText.charCodeAt(i);
      if (ASCIICode === 65308 || ASCIICode === 65310) {
        isExists = true;
      }
    }
    return isExists;
  }
}
