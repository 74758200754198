<div bsModal #showmodal="bs-modal" class="modal fade" [config]="{ backdrop: 'static' }" tabindex="-1" role="dialog"
    aria-labelledby="showmodal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form class="form-horizontal" [formGroup]="ageingPeriodsForm" novalidate="novalidate">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{sendTitle | translate}}</h4>
                    <button type="button" class="close" (click)="CloseModel()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="col">
                            <input type="text" formControlName="interval"
                                (blur)="periodValid(ageingPeriodsForm.value.interval)" class="form-control text-right"
                                phonecodenumber maxlength="2" />
                        </div>
                        <div class="col">
                            <label class="col-form-label">{{'ReportsModule.periods of' | translate}}</label>
                        </div>
                        <div class="col">
                            <input type="text" formControlName="duration" (blur)="periodtypeValid(ageingPeriodsForm.value.duration)" class="form-control text-right"
                                phonecodenumber maxlength="3" />
                        </div>
                        <div class="col">
                            <select formControlName="ageType"  class="form-select">
                                <option *ngFor="let c of Periodtype" [ngValue]="c.value">
                                    {{c.value}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div *ngIf="periodvalidrange" style="color: red;"><i class="cil-warning"></i>1-12</div>
                        </div>
                        <div class="col">
                            <div *ngIf="periodvaluerange" style="color: red;"><i class="cil-warning"></i>1-999</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="CloseModel()"  >{{'Common.Cancel' | translate}}</button>
                    <button type="button" class="btn btn-info"  (click)="onSubmit()">{{'ReportsModule.Apply' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>
