import { Injectable } from '@angular/core';
import { MasterServices } from '../_services/master.service';
@Injectable({
  providedIn: 'root'
})

export class NZTaxrateListServices {
  lstTaxRateMaster: any = [];
  constructor(private master: MasterServices,) {
    this.GetTaxRateMaster();
  }
  async GetTaxRateMaster() {
    this.lstTaxRateMaster = await this.master.GetTaxRateMaster();
  }

  onNZTaxRateList(groupID: any) {
    let lstNZTaxRateMaster= [];
    if (groupID == 1 || groupID == 2) {//Assets || Equity
      lstNZTaxRateMaster = this.lstTaxRateMaster.filter(x => (x.nzReturnID == 1 || x.nzReturnID == 2 || x.nzReturnID == 3 || x.taxCode == 'NOTAX') && x.taxCode != 'ZR');
    }
    else if (groupID == 3) {//Expenses
      lstNZTaxRateMaster = this.lstTaxRateMaster.filter(x => (x.nzReturnID == 2 || x.nzReturnID == 3 || x.taxCode == 'NOTAX') && x.taxCode != 'ZR');
    }
    else if (groupID == 4) {//Liabilities
      lstNZTaxRateMaster = this.lstTaxRateMaster;
    }
    else if (groupID == 5) {//Revenue
      lstNZTaxRateMaster = this.lstTaxRateMaster.filter(x => (x.nzReturnID == 1 || x.nzReturnID == 3 || x.taxCode == 'NOTAX'));
    }
    return lstNZTaxRateMaster;
  }
}

