import { HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpInterceptor } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { ErrorDialogService } from "../../views/shared/errors/error-dialog.service";
import { LoadingDialogService } from "../../views/shared/loading/loading-dialog.service";
import { Injectable } from "@angular/core";
import { LoaderService } from '../_services/loader.service';
import { AuthenticationService } from '../../utilities/_services/authentication.service';
import { PageServices } from "../_services/page.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  urlsToSkiploading: Array<string>;
  pageName:any = null;
  constructor(private errorDialogService: ErrorDialogService, public loaderService: LoaderService, private page: PageServices,
    private loadingDialogService: LoadingDialogService, private authenticationService: AuthenticationService) {
    this.urlsToSkiploading = [
      'AuthLogin/refreshtoken',
      'clientapp/verifyclient'
    ];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(request.url)) {
      this.loaderService.show();
    }
    //this.loadingDialogService.openDialog();
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.isValidRequestForInterceptor(request.url)) {
          //console.log(error);
          this.loaderService.hide();
          let errorMessage = '';
          let errorCoce = error.status;
          if (error.status == 0) {
            if(!navigator.onLine && error.message.startsWith("Http failure response") && error.name == "HttpErrorResponse" && error.statusText == "Unknown Error")
            {
              errorMessage = 'Error: Internet disconnected. Please check your internet connection.';
              this.errorDialogService.openDialog(errorMessage, errorCoce);
            }
            else{
              if(error.url.includes("/Templates/print") && error.statusText == "Unknown Error"){
                errorMessage = `<html>
                  System is facing some difficulties to print this document. Please double check below points and try again:
                  <ol>
                    <li>Please align or wrap the Address, Title & Summary, Footer, and Annexure contents.</li>
                    <li>Consider adjusting the size of the Signature.</li>
                    <li>Try uncheck/check the 'Show page' and 'Repeat header' options.</li>
                    <li>Print the payment stub on the first or a separate page if applicable.</li>
                    <li>Ensure that any copy-pasted content from another source is correctly formatted.</li>
                    <li>Please review the placeholders in the Word template for spelling and spacing.</li>
                  </ol>
                  </html>` 
                this.errorDialogService.openDialog(errorMessage, errorCoce, "650px", "300px");
              }
              else{
                errorMessage = 'Error: ' + error.statusText + ' \t Message: ' + (error.message.includes("/api/") ? "Something went wrong!" : error.message);
                this.errorDialogService.openDialog(errorMessage, errorCoce);
              }
            } 
          }
          else if (error.status == 409) {//Invalid Input
            // errorMessage = 'Error:' + error.error.message;
            errorMessage ="Bad Request or (Incorrect input, Your input value should not contain < or > symbols)";// (this.page.pageDetails.name? this.page.pageDetails.name: "This page") + " transaction process got error. Kindly raise ticket/inform support to check further."
            this.errorDialogService.openDialog(errorMessage, errorCoce);
          }
          else if (error.status == 401) {
            this.authenticationService.logout();
          }
          else if (error.status > 401 && error.status <= 499) {
            errorMessage = 'Error: ' + error.statusText + ' \t Message: ' + (error.message.includes("/api/") ? "Something went wrong!" : error.message);
            this.errorDialogService.openDialog(errorMessage, errorCoce);
            this.authenticationService.logout();
          }
        //   else if (error.status >= 500 && error.status <= 599) {
        //     errorMessage = (this.page.pageDetails.name? this.page.pageDetails.name: "This page") + " transaction process got error. Kindly raise ticket/inform support to check further."
        //     this.errorDialogService.openDialog(errorMessage, errorCoce);
        //   }
          else {
            errorMessage = 'Error:' + error.error;
          }
          return throwError(errorMessage);
        }
        else {
          return throwError(error);
        }
      }),
      finalize(() => {
        this.loaderService.hide();
      })
    ) as Observable<HttpEvent<any>>;
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.urlsToSkiploading) {
        if (new RegExp(address.toLowerCase()).test(destination.toLowerCase())) {
          return false;
        }
      }
    }
    return true;
  }
}
