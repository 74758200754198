import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { HttpServices } from '../../../utilities/_services/http.service';
import { MasterServices } from '../../../utilities/_services/master.service';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { TransactionsModule } from '../../transactions/transactions.module';
import { json } from '@rxweb/reactive-form-validators';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';

@Component({
  selector: 'app-adddeliveryaddress',
  templateUrl: './adddeliveryaddress.component.html',
  styleUrls: ['./adddeliveryaddress.component.scss']
})
export class AdddeliveryaddressComponent implements OnInit {
  @ViewChild('showDeliverymodal') public showDeliverymodal: ModalDirective;
  @Output() toParentAfterDeliveryAddressSave = new EventEmitter<String>();
  deliveryAddressForm: FormGroup | any;
  loadingDelivery = false;
  textareaLength: number = 500;
  lstCountries: any = [];
  alreadyAddressLableExist: boolean = false;
  submitted : boolean = false;
  deliveryaddressArray: any = [];
  ContactModel: any;
  deliveryAddID: number;
  AddNewAddress = false;
  isLabel:boolean=false;
  errorDOAddress = '';
  contactDeliveryAddressID = 0;
  addressLable = "";
  deliveryAddressModel: string;
  attentiontoModel: string;
  contactNumberModel: string;
  deliveryInstructionsModel: string;
  @Input() transactionTypeId: number = 21;
  addressdetails:any=[];
  currentUser: any
  constructor(private http: HttpServices, private masterServices: MasterServices, private formBuilder: FormBuilder,
    private alertMessage: AlertMessage,private local: LocalCacheServices,) { }

  ngOnInit(): void {
    this.deliveryModelFormValues();
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    //this.ContactModel = this.dropDownDatas.DODetail[0].ContactID;
  }
  openAddressModal(deliveryAddressID, deliveryAddress: any = undefined) {
    this.ContactModel = deliveryAddressID.split('~')[0];
    this.deliveryAddID = deliveryAddressID.split('~')[1];
    this.getContactsDeliveryAddress();
    if (this.deliveryAddID == 0 || this.deliveryAddID == undefined) {
      this.isLabel=false;
      this.deliveryAddressForm.reset();
    }
    if (deliveryAddress != undefined) {
      this.deliveryAddressForm.patchValue(deliveryAddress);
    }
    else {
      this.deliveryAddressForm.patchValue({ deliveryAddressID: 0 });
    }
    this.showDeliverymodal.show();
  }

  hidedeliverymodal() {
    // this.formReset()
    this.showDeliverymodal.hide();
  }
  async GetCountries() {
    return new Promise(async resolve => {
      this.lstCountries = await this.masterServices.getCountries();
      resolve('');
    });
  }
  get f() { 
    if(this.deliveryAddressForm.value.zipCode==null){
          this.addressdetails=[];
      }
    return this.deliveryAddressForm.controls; 
  }

  deliveryModelFormValues() {
    this.deliveryAddressForm = this.formBuilder.group({
      deliveryAddressID: [0],
      addressLabel: ['', Validators.required],
      addressAttention: [''],
      streetAddress: [''],
      city: [''],
      region: [''],
      zipCode: [''],
      fcCountry: [null],
      areaCode: [''],
      telePhoneCountryCode: [''],
      telePhone: [''],
      instruction: [''],
      isPrimaryAddress: [false]
    });
  }

  onSubmitDeliveryAddress() {
    if (this.deliveryAddressForm.invalid) {
      this.alreadyAddressLableExist = false;
      this.submitted = true;
      this.isLabel=true;
      return;
    }
    this.isLabel=false;
    let oldDelveryAddress: any = '{}';
    let oldDelAdd: any = this.deliveryaddressArray.filter(x => x.deliveryAddressID == this.deliveryAddressForm.value.deliveryAddressID)[0];
    let strOldDelveryAddress;
    if (oldDelAdd !== undefined) {
      strOldDelveryAddress = {
        "DeliveryAddressID": oldDelAdd.deliveryAddressID,
        "AddressLabel": oldDelAdd.addressLabel,
        "AddressAttention": oldDelAdd.addressAttention,
        "StreetAddress": oldDelAdd.streetAddress,
        "City": oldDelAdd.city,
        "Region": oldDelAdd.region,
        "ZipCode": oldDelAdd.zipCode,
        "CountryID": oldDelAdd.fcCountry,
        "AreaCode": oldDelAdd.areaCode,
        "TelePhoneCountryCode": oldDelAdd.telePhoneCountryCode,
        "Instruction": oldDelAdd.instruction,
        "TelePhone": oldDelAdd.telePhone,
        "CreatedDate": "",
        "ContactID": oldDelAdd.contactID,
        "IsPrimaryAddress": oldDelAdd.isPrimaryAddress
      }
    }
    oldDelveryAddress = JSON.stringify(strOldDelveryAddress);

    let NewDelveryAddress = {
      "DeliveryAddressID": this.deliveryAddressForm.value.deliveryAddressID,
      "AddressLabel": this.deliveryAddressForm.value.addressLabel,
      "AddressAttention": this.deliveryAddressForm.value.addressAttention,
      "StreetAddress": this.deliveryAddressForm.value.streetAddress,
      "City": this.deliveryAddressForm.value.city,
      "Region": this.deliveryAddressForm.value.region,
      "ZipCode": this.deliveryAddressForm.value.zipCode,
      "CountryID": this.deliveryAddressForm.value.fcCountry,
      "AreaCode": this.deliveryAddressForm.value.areaCode,
      "TelePhoneCountryCode": this.deliveryAddressForm.value.telePhoneCountryCode,
      "Instruction": this.deliveryAddressForm.value.instruction,
      "TelePhone": this.deliveryAddressForm.value.telePhone,
      "CreatedDate": "",
      "ContactID": this.ContactModel,
      "IsPrimaryAddress": this.deliveryAddressForm.value.isPrimaryAddress,
      "TransactionTypeId": this.transactionTypeId
    }
    let strNewDelveryAddress = JSON.stringify(NewDelveryAddress);
    this.saveUpdateDeliveryAddress(oldDelveryAddress, strNewDelveryAddress).then(() => {

    })
  }
  onClear() {
    this.addressdetails = [];
  }

  AddressSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.address.toLocaleLowerCase().indexOf(term) > -1
     }

    getaddressdetails(event){
    this.addressdetails=[];
    this.deliveryAddressForm.patchValue({zipCode:event.term.trim().toString()});
     if (event.term.trim() && this.currentUser.orgCountryID == 196) {
     this.http.getservice(environment.administrationApiUrl + '/Admin/GetAddress?strPostalCode=' + this.deliveryAddressForm.value.zipCode).subscribe({
         next: (data: any) => {
           this.addressdetails = data.results;
           this.addressdetails.forEach(e=> {
            if(e.postal=='NIL'){
                e.postal=''
            }
        });
         }
       });    
    }
  }
  onSelectAddress(event){
    if(event!=undefined){
    this.deliveryAddressForm.patchValue({
      streetAddress: event.address.trim().toString(),
      city:'Singapore' ,
      region:'Singapore',
      zipCode: event.postal.trim().toString(),
      fcCountry:196
    });
  }
  }
  saveUpdateDeliveryAddress(oldDeliveryAddress, newDeliveryAddress) {
    var promise = new Promise((resolve, reject) => {
      this.loadingDelivery = true;
      let apiUrl = environment.organizationApiUrl + '/Organization/SaveUpdateContactsDeliveryAddress/'
      if (this.ContactModel > 0) {
        debugger
        this.http.postserviceDeliveryAddress(apiUrl, oldDeliveryAddress, newDeliveryAddress).subscribe(
          {
            next: (res: any) => {
              this.alertMessage.successNotifier('New Address has been added Successfully!', 1);
              this.loadingDelivery = false;
              this.showDeliverymodal.hide();
              this.toParentAfterDeliveryAddressSave.emit(this.ContactModel.toString());
            }
          });
      }
      else {
        this.loadingDelivery = false;
        this.showDeliverymodal.hide();
        var newDelAdd = JSON.parse(newDeliveryAddress);
        newDelAdd.DeliveryAddressID = this.deliveryAddID;
        this.toParentAfterDeliveryAddressSave.emit(newDelAdd);
      }
    });
    return promise;
  }

  onClikAddressModel(deliveryObj?: any) {
    this.AddNewAddress = false;
    this.errorDOAddress = '';
    this.contactDeliveryAddressID = deliveryObj.deliveryAddressID;
    this.addressLable = deliveryObj.addressLabel;
    this.deliveryAddressModel = `${deliveryObj.streetAddress ? deliveryObj.streetAddress : ''}   ${deliveryObj.city ? deliveryObj.city : ''}  ${deliveryObj.region ? deliveryObj.region : ''}  ${deliveryObj.zipCode ? deliveryObj.zipCode : ''} ${deliveryObj.fcCountry ? deliveryObj.fcCountry : ''}`;
    this.attentiontoModel = deliveryObj.addressAttention;
    this.contactNumberModel = deliveryObj.telePhone;
    this.deliveryInstructionsModel = deliveryObj.instruction;
  }

  getContactsDeliveryAddress() {
    var promise = new Promise((resolve, reject) => {
      let apiUrl = environment.organizationApiUrl + '/Organization/GetContactsDeliveryAddress/' + this.ContactModel;
      this.http.getservice(apiUrl).subscribe({
        next: (data) => {
          if (data !== undefined) {
            this.deliveryaddressArray = [];
            data['value'].forEach(element => {
              this.deliveryaddressArray.push(element);
            });
            resolve('');

            this.GetCountries().then(() => {
              if (this.deliveryAddID !== 0) {
                let deliveryAddressobj = this.deliveryaddressArray.filter(x => x.deliveryAddressID == this.deliveryAddID)[0];
                this.deliveryAddressForm.patchValue(deliveryAddressobj);
                if (deliveryAddressobj.countryID > 0) {
                  for (let key in this.lstCountries) {
                    if (this.lstCountries[key].countryID == deliveryAddressobj.countryID) {
                      this.deliveryAddressForm.get('fcCountry').patchValue(this.lstCountries[key].countryID);
                      break;
                    }
                  }
                } else {
                  this.deliveryAddressForm.get('fcCountry').patchValue(null);
                }
              }
            })

          }
        },
        error: err => {
          reject(err);
        }
      });
    });
    return promise;
  }

}
