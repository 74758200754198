export const environment = {
  production: false,
  name: "staging",
  gatewayApiUrl: 'https://stgaccgateway.azurewebsites.net/gateway',
  administrationApiUrl: 'https://stgadministrationapi.azurewebsites.net/api',
  organizationApiUrl: 'https://stgorganizationapi.azurewebsites.net/api',
  authguardApiUrl: 'https://stgauthguardapi.azurewebsites.net/api',
  reconcilationApiUrl: 'https://stgreconcilationapi.azurewebsites.net/api',
  purchaseApiUrl: 'https://stgpurchaseapi.azurewebsites.net/api',
  inventoryApiUrl: 'https://stginventoryapi.azurewebsites.net/api',
  accountsApiUrl: 'https://stgaccountsapi.azurewebsites.net/api',
  reportsApiUrl: 'https://stgreportsapi.azurewebsites.net/api',
  salesApiUrl: 'https://stgsaleapi.azurewebsites.net/api',
  templateApiUrl: 'https://stgtemplatesapi.azurewebsites.net/api',  
  subscriptionApiUrl: 'https://stgsubscription.azurewebsites.net/api',
  yodleeEnvironment: 'DEVELOPMENT',
  idleTimeOut: 15 * 60, // 55 minutes in seconds
  isAllowEncDec: false,
  isEncDecAllowed:true,
  isResponseEncAllowed:true,
  disismeCRMApiUrl: 'https://stgcrmreplica.azurewebsites.net/api',
  disismeCRMSiteUrl:  'https://stgdigicrm.azurewebsites.net',
  crmApiUrl: 'https://stagingcrmapi.azurewebsites.net/api',
};

