import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { responsecls } from './ticket.model';

@Injectable({
  providedIn: 'root'
})
export class TicketService {// DisiSMECRMService

  constructor(private _http: HttpClient) { }

  GetViewPlan(obj: any): Observable<responsecls> {
    return this._http.post<any>(environment.disismeCRMApiUrl + '/Customer/GetCustomerPaymentSession', obj);
  }

  GetAllTickets(obj: any): Observable<responsecls> {
    return this._http.post<any>(environment.administrationApiUrl + "/TicketDetails/GetTickets_DigismeCrm", obj);
    //return this._http.post<any>(environment.crmApiUrl + '/GetTickets_DigismeCrm', obj);
  }

  SubmitTicket(obj: any): Observable<responsecls> {
    return this._http.post<any>(environment.administrationApiUrl + "/TicketDetails/CreateTicket_DigismeCrm", obj);
    //return this._http.post<any>(environment.crmApiUrl + '/CreateTicket_DigismeCrm', obj);
  }

  GetTicketInfo(obj: any): Observable<responsecls> {
    return this._http.post<any>(environment.administrationApiUrl + "/TicketDetails/GetTicketDetails_DigismeCrm", obj);
    //return this._http.post<any>(environment.crmApiUrl + '/GetTicketDetails_DigismeCrm', obj);
  }

  ReplyTicket(obj: any): Observable<responsecls> {
    return this._http.post<any>(environment.administrationApiUrl + "/TicketDetails/ReplyTicket_DigismeCrm", obj);  
    //return this._http.post<any>(environment.crmApiUrl + '/ReplyTicket_DigismeCrm', obj);
  }

  ViewTicketAttachment(obj: any) {
    return this._http.post<any>(environment.administrationApiUrl + "/TicketDetails/GetAttachments_DigigSME", obj); 
    //return this._http.post<any>(environment.crmApiUrl + '/GetAttachments_DigigSME', obj);
  }

  DeleteTicketAttachment(obj: any) {
    return this._http.post<any>(environment.administrationApiUrl + "/TicketDetails/DeleteAttachments_DigiSME", obj); 
    //return this._http.post<any>(environment.crmApiUrl + '/DeleteAttachments_DigiSME', obj);
  }
}
