import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CustomTxtValidator, ValidMultipleEmailWithSemicolonComma } from '../../../../utilities/_directives/customvalidator.directive';

@Component({
  selector: 'app-multi-email-cll-render',
  template:
    `<form class="form-horizontal" [formGroup]="emailtemplateForm" novalidate="novalidate" >
  <span class="requiredError"
   *ngIf="!emailtemplateForm.controls.to.valid"><mat-icon style="vertical-align: middle;">error_outline</mat-icon></span>
{{params.data.Email != undefined? params.data.Email :  params.data.email === "Add email" ? "": params.data.email}}<input style="display: none;" name="name" id="name" type="text" formControlName="to">
<ng-template #popTemplateHtml >
    <div style="overflow: visible;z-index:5000;"><span class="help-block text-warning">
      Email is invalid. Use semi colon ';' to add multiple email
    </span>
    </div>
</ng-template></form>
`,
  styles: ['.requiredError {  color: red; }']
})

export class MultiEmailCellRenderComponent implements AgRendererComponent {
  params;
  constructor(private formBuilder: FormBuilder) { }
  emailtemplateForm: FormGroup | any;
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.emailtemplateForm = this.formBuilder.group({
      to: [params.data.Email != undefined? params.data.Email :  params.data.email, [CustomTxtValidator.required, ValidMultipleEmailWithSemicolonComma()]]
      //to: [params.data.email, [CustomTxtValidator.required, ValidMultipleEmailWithSemicolonComma()]]
    });
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.emailtemplateForm.patchValue({to:params.data.Email != undefined? params.data.Email:params.data.email})
    // this.emailtemplateForm.patchValue({to: params.data.email})
    return true;
  }
}
