import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-column-re-order',
  templateUrl: './column-re-order.component.html',
  styleUrls: ['./column-re-order.component.scss']
})
export class ColumnReOrderComponent implements OnInit {

  @Input() columns: any;
  @Output() hidemodal = new EventEmitter();
  @ViewChild('showmodal') public showModal: ModalDirective;

  activetab = 1;
  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.showModal.show();
    this.showModal.onHide.subscribe((reason: string | any) => {
      this.hidemodal.emit(this.columns);
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    let columnOrder = this.columns.map(res => res.displayOrder).sort((a, b) => a - b);
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.columns.forEach((val, index) => {
      val.displayOrder = columnOrder[index];
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.columns) {
      this.columns = this.columns.sort((a, b) => a.displayOrder - b.displayOrder);
    }
  }

  CloseModel() {
    this.hidemodal.emit(false);
    this.columns = false;
    this.showModal.hide();
  }

  applyRorder() {
    this.showModal.hide();
    // this.hidemodal.emit(this.columns);
  }

}
