import { ICellEditorAngularComp } from "@ag-grid-community/angular";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  CustomTxtValidator,
  ValidMultipleEmailWithSemicolonComma,
} from "../../../../utilities/_directives/customvalidator.directive";
@Component({
  selector: "app-multi-email-tmplate",
  templateUrl: "./multi-email-cell-editor.component.html",
  styleUrls: ["./multi-email-tmplate.component.scss"],
})
export class MultiEmailCellEditorComponent implements ICellEditorAngularComp {
  params;
  constructor(private formBuilder: FormBuilder) {}
  emailtemplateForm: FormGroup | any;
  agInit(params: any): void {
    this.params = params;
    this.emailtemplateForm = this.formBuilder.group({
      // mail: [params.data.email, [CustomTxtValidator.required, ValidMultipleEmailWithSemicolonComma()]]
      mail: [
        params.data.Email != undefined ? params.data.Email : params.data.email==="Add email" ?"":params.data.email,
        [CustomTxtValidator.required, ValidMultipleEmailWithSemicolonComma()],
      ],
    });
  }

  getValue() {
    return this.emailtemplateForm.controls.mail.value;
    // if (this.emailtemplateForm.controls.mail.valid) {
    //   return this.emailtemplateForm.controls.mail.value;
    // } else {
    //   return this.params.data.Email != undefined ? this.params.data.Email : this.params.data.email;
    // }
  }

  afterGuiAttached() {}
}
