import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {  
// Duration - minutes
  @Input() 
get Duration():number {
  return this._duration
}
set Duration(dur:number){
  if(dur != null && dur != undefined && dur != 0){
    this._duration = dur * (60000 + 600);
  }
  else{
    this._duration = this.defMinutes * (60000 + 600);
  }
}

private _duration:number;
private defMinutes:number=2;
@Output() IsExpired = new EventEmitter<boolean>();
  constructor() { }
  public dDay:Date;
  private subscription: Subscription;
  canshow:boolean = false;
    public dateNow = new Date();
    
    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    SecondsInAMinute  = 60;

    public timeDifference;
    public secondsToDday;
    public minutesToDday;
    // public hoursToDday;
    // public daysToDday;


    private getTimeDifference () {
        this.timeDifference = this.dDay.getTime() - new  Date().getTime();
        if(this.timeDifference > 0){          
          this.allocateTimeUnits(this.timeDifference);
        }
        else{
          this.IsExpired.emit(true);
          this.subscription.unsubscribe();
        }
    }

  private allocateTimeUnits (timeDifference) {
        this.secondsToDday = String(Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute)).padStart(2, '0');
        this.minutesToDday = String(Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute)).padStart(2, '0');;
        // this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
        // this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

    ngOnInit() {
      this.dDay = new Date(new Date().getTime() + (this._duration));
       this.subscription = interval(1000)
        .subscribe(x => {             
        this.canshow = true;
        this.getTimeDifference(); 
      });
    }

   ngOnDestroy() {
      this.subscription.unsubscribe();
   }


}
