import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddcurrencyDialogComponent } from "./addcurrency-dialog/addcurrency-dialog.component";

@Injectable()
export class AddcurrencyDialogService {
  exchangeDate: string;
  orgCurrencyCode:string;
  constructor(public dialog: MatDialog) { }
  openDialog(): Observable<any> {
    const dialogRef = this.dialog.open(AddcurrencyDialogComponent, {
      data:{ event: 'open', exchangeDate: this.exchangeDate,orgCurrencyCode:this.orgCurrencyCode},
      maxHeight: "100%",
      width: "650px",
      height: "auto",
      maxWidth: "100%",
      disableClose: true,
      hasBackdrop: true
      
    });
    return dialogRef.afterClosed();
  }
}