<div class="well"></div>
<div class="wellWhite">
  <ng-container>
    <div class="container">
      <div class="px-3 py-2 d-flex">
        <div *ngIf="statusMessage == 'Invoice link has been expired!'">
          <div class="col-md-12">
            <div class="text-left">
              <h2 style="color: Red;">Online Invoice Expired</h2>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-left" *ngIf="statusMessage !== 'Invoice link has been expired!'">
          <h2 *ngIf="invStatusId==4" style="color: green;">Invoice - {{invStatusDesc}}
            <i class="fa fa-check-square" aria-hidden="true"></i>
          </h2>
          <h2 *ngIf="invStatusId<=3" style="color: rgb(3, 4, 63);">Invoice - {{invStatusDesc}}
          </h2>
        </div>
        <div class="ml-auto text-right" *ngIf="statusMessage != 'Invoice link has been expired!'">
          <button class="btn btn-primary" type="button" (click)="print()">
            Print
          </button>
        </div>
        <div class="btn-group" dropdown *ngIf="invStatusId==3 && total>0 && OnlinePaymentList!=null">
          <button dropdownToggle class="btn btn-primary dropdown-toggle" type="button"> Online Payment Options
            <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem" *ngFor="let Payment of OnlinePaymentList;">
              <a class="dropdown-item" (click)="LoadPaymentURL(Payment.RedirectUrl,Payment.PaymentServiceId,Payment.KeyId)">
                <img class="ml-2 img-20 cursor-pointer" alt={{Payment.ServiceName}} src={{Payment.ServiceImage}}>
                {{Payment.ServiceName}}
              </a>
              <!-- <a class="dropdown-item" (click)="LoadPaymentURL(Payment.RedirectUrl,Payment.PaymentServiceId)">{{Payment.ServiceImage}}
              </a> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- <ng-container *ngIf="statusMessage != 'Invoice link has been expired!'">
    <div class="container">
      <div class="row">     
        <div class="col-md-12 ">
          <div class="text-right">
            <button class="btn btn-primary" type="button" (click)="print()">
              Print
            </button>
          </div>
          </div>
      </div>
    </div>
  </ng-container> -->

  <!-- <ng-container>
    <div class="btn-group" dropdown>
      <button dropdownToggle class="btn btn-primary dropdown-toggle" type="button"> Online Payment Options
        <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu" >
        <li role="menuitem" *ngFor="let Payment of OnlinePaymentList;" >
          <a class="dropdown-item" (click)="LoadPaymentURL(Payment.RedirectUrl,Payment.PaymentServiceId)">{{Payment.ServiceName}}
          </a>
        </li>
      </ul>
    </div>
  </ng-container> -->
</div>
<div class="container" *ngIf="statusMessage != 'Invoice link has been expired!'">
  <div class="card mt-3 p-3">
    <div class="row">
      <div class="col-md-6">
        <div class="text-left">
          <h1 *ngIf="invStatusId==3">{{ CurrencyCode }} {{ total | number : '1.2-2' }}</h1>
          <p *ngIf="invStatusId==3" style="color: #DF7508;">Balance Due</p>
          <div class="">
            <p>Invoice #:{{invNo}}</p>
          </div>
          <div class="">
            <p>Due Date:{{invExpDate}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-right">
          <h2>TAX INVOICE</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="text-left">
          <p>Bill To:</p>
          <h4>
            <p><b>{{ContactName}}</b></p>
          </h4>
          <p [innerHTML]="getContactAddressHTML()"></p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-right">
          <img *ngIf="!isDigiSME && Orgimg != ''" src={{Orgimg}} height="50" alt="Info Tech" />
          <img *ngIf="isDigiSME && Orgimg != ''" src={{Orgimg}} height="50" alt="DigiSME" />
          <p>From:</p>
          <p [innerHTML]="getOrgAddressHTML()"></p>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="text-right">
        <p>Amounts are : {{TaxType}}</p>
      </div>

      <div class="custom-ag-grid-4">
        <!-- <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [rowData]="rowData" [gridOptions]="gridOptions"
          [columnDefs]="columnDefs" [modules]="modules" [domLayout]="domLayout">
        </ag-grid-angular> -->
        <app-simple-ctable style="width: 100%; height: 225px;" [rowData]="rowData" [columnDefs]="columnDefs">
        </app-simple-ctable>
      </div>

      <div class="row d-flex justify-content-between">
        <div class="col-md-3 pr-0"> </div>
        <div class="col-md-8">
          <div class="row align-items-start">
            <div class="col-md-8">
              <div class="pt-1 pb-1">Sub Total</div>
            </div>
            <div class="col-md-4">
              <div class="pt-1 pb-1 text-right">{{ CurrencyCode }} {{ subTotal?subTotal : 0 | number : '1.2-2'}}
              </div>
            </div>
          </div>
          <ng-container>
            <accordion class="custom-accordion ac-view">
              <accordion-group #group1 [isOpen]="false" panelClass="customClass">
                <div accordion-heading class="t-link">
                  <div class="d-flex justify-content-between  align-items-start">
                    <div class="">
                      <i class="pull-left fa fa-angle-down rotate-icon"
                        [ngClass]="{'fa-angle-down': group1?.isOpen, 'fa-angle-right': !group1?.isOpen}"></i>
                      <strong>Total Tax Amount</strong>
                    </div>
                    <div>
                      <div>
                        <strong> {{ totalTaxAmount?totalTaxAmount : 0 | number : '1.2-2' }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngFor="let innerObj of taxAmountsAreList">
                  <div class="sub-collapse">
                    <accordion-group #group2 [isOpen]="false" panelClass="customClass">
                      <div accordion-heading class="t-link">
                        <div class="d-flex justify-content-between  align-items-start text-success">
                          <div class="">
                            <i *ngIf='innerObj.Components != null && innerObj.Components.length>1'
                              class="pull-left fa fa-angle-down rotate-icon"
                              [ngClass]="{'fa-angle-down': group2?.isOpen, 'fa-angle-right': !group2?.isOpen}"></i>
                            {{innerObj.LineTotalTaxText.replace("Includes","Total")}}
                          </div>
                          <div>
                            <div class="">
                              {{ braceFormatter(innerObj.LineTotalTaxValue) }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngFor="let componentsObj of innerObj.Components">
                        <div *ngIf='innerObj.Components != null && innerObj.Components.length>1'
                          class="d-flex justify-content-between  align-items-start sub-collapse-item">
                          <div>
                            {{componentsObj.LineTotalTaxText.replace("Includes","Total")}}
                          </div>
                          <div>
                            <div>
                              {{ braceFormatter(componentsObj.LineTotalTaxValue) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </accordion-group>
                  </div>
                </div>
              </accordion-group>
            </accordion>
          </ng-container>
          <hr class="mt-1 mb-1">
          <div class="d-flex justify-content-between align-items-start">
            <div>
              <div class="header-h-24">Total</div>
            </div>
            <div>
              <div class="header-h-24 text-right">
                {{ CurrencyCode }} {{ totalwithTaxAmount?totalwithTaxAmount : 0 | number : '1.2-2' }}
              </div>
            </div>
          </div>
          <hr class="mt-1 mb-1">
          <div class="text-left" *ngIf="showCurrencyInfo">
            * Total Tax equivalent to {{orgCurrencyCode}} {{showPerUnit}} <br> {{showToCurrencyCode}}
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<div bsModal #showPrint="bs-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="showPrintLabel"
  aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog mt-0" style="max-width: 100%;" role="document">
    <div class="modal-content">
      <div class="modal-body" style="padding: 0 0 0 0;">
        <div style='position: relative; height: 93vh;'>
          <div [innerHTML]="pdfhtml" *ngIf="pdfViewerOnDemand.pdfSrc==null"></div>
          <ng2-pdfjs-viewer #pdfViewerOnDemand [externalWindow]="false" [downloadFileName]="'Invoice.pdf'"
            [print]="false" [openFile]="false" [viewBookmark]="false" [download]="true" [fullScreen]="true"
            [showSpinner]="true"></ng2-pdfjs-viewer>
        </div>
      </div>
      <div class="modal-footer" style="padding: 0 0 0 0;">
        <button class="btn btn-primary btn-normal" type="button"
          (click)="printService.printPdf(pdfViewerOnDemand);">Print</button>
        <button class="btn btn-light btn-normal" type="button"
          (click)="showPrint.hide();pdfViewerOnDemand.pdfSrc=null;">Close</button>
      </div>
    </div>
  </div>
</div>
