import { Component, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';

import { ICellEditorAngularComp } from "@ag-grid-community/angular";

@Component({
    selector: 'app-alphanumericeditor',
    // template: `<mat-form-field appearance="standard"  class="example-full-width">
    //             <input type="text" #input  matInput [(ngModel)]="value" decimal2 maxlength="9">
    //         </mat-form-field>`,
    // template: `<input type="text" #input class="form-control" decimal2 appNoRightClick maxlength="15" >`,
    template: `<input type="text" style="margin-bottom: 0px; height:25px;" #input [(ngModel)]="value" class="form-control">`,
})
export class AlphanumericEditor implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value: any;
    private cancelBeforeStart: boolean = false;
    @ViewChild('input', { read: ViewContainerRef }) public input;
    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
    }
    getValue(): any {
        return this.value; 
    }
    ngAfterViewInit() {
        window.setTimeout(() => {
            this.input.element.nativeElement.focus();
        });
    }
}