<div bsModal #showAddCurrencyModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="showAddCurrencyModalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-md" role="document">
    <form [formGroup]="addCurrencyForm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{'OrgModlue.Currencies.Add Currency' | translate}}</h4>
          <button type="button" class="close" (click)="closeModal();">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label" for="text-input">{{'OrgModlue.Currencies.Select a currency' | translate}}</label>
                <div class="col-md-8">
                  <ng-select formControlName="currencyTypeID" class="auto-grow"
                    [ngClass]="{ 'is-invalid': submitted && f.currencyTypeID.errors }" (change)="getLiveExchangeRate();"
                    [items]="lstCurrencyType" bindValue="CurrencyTypeID" bindLabel="CurrencyCodeName">
                  </ng-select>
                  <div *ngIf="submitted && f.currencyTypeID.errors" class="invalid-feedback">
                    <div *ngIf="f.currencyTypeID.errors.required">{{'OrgModlue.Currencies.Is required' | translate}}</div>
                  </div>
                </div>
              </div>
              <div class="custom-table-1">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>{{"OrgModlue.Currencies.Automatic Foreign Exchange Rates provided by fixer.io" | translate}}</th>
                      <th>{{"OrgModlue.Currencies.Latest" | translate}} {{orgCurrencyCode}} {{"OrgModlue.Currencies.Rate" | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{"OrgModlue.Currencies.Exchange rates are updated each hour with the most up to date mid-market
                        rate from fixer.io" | translate}}
                      </td>
                      <td>{{exchangeRate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button [disabled]="loading" class="btn btn-primary btn-normal mr-3" type="button" (click)="onSubmit()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{'OrgModlue.Currencies.Add Currency' | translate}}
          </button>
          <button class="btn btn-secondary" type="button" (click)="closeModal();">{{'Common.Cancel' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
