import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { environment } from '../../../../environments/environment';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { HttpServices } from '../../../utilities/_services/http.service';
import { PageServices } from '../../../utilities/_services/page.service';
import { ValidationService } from '../../../utilities/_common/validation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addcontact',
  templateUrl: './addcontact.component.html'
})
export class AddcontactComponent implements OnInit {
  @Output() toSelectContact = new EventEmitter<String>();
  @ViewChild('selectContact') selectContact: NgSelectComponent;
  lstContacts: any[] = [];
  itemBuffer: any = [];
  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loadingContact = false;
  // contact:any = {
  //   contactID: 0,
  //   contactName:'',
  //   isNewContact:false
  // }
  contactID: number = null;
  @Input() isPartiallypaid: boolean = false;
  contactName: string = '';
  contactNameSearch: string = '';
  inactiveContact: boolean = false;
  contactInvalid: boolean = false;
  archiveInvalid: boolean = false;
  successNotifierId: number = 0;
  isSaveContact: boolean = false;
  constructor(private http: HttpServices, private page: PageServices, private alertMessage: AlertMessage, private translate: TranslateService) {
    this.onGetAllContacts()
  }

  ngOnInit(): void { }

  onGetAllContacts() {
    this.loadingContact = true;
    this.http.getservice(environment.organizationApiUrl + '/Master/GetAllContacts?ContactID=0').subscribe({
      next: (data) => {
        this.lstContacts = data as [];
        this.loadingContact = false;
        if (this.contactID > 0) {
          this.inactiveContact = false;
          let inactiveContact = this.lstContacts.filter(x => x.contactID == this.contactID);
          if (inactiveContact.length == 0) {
            this.inactiveContact = true;
          }
        }
      }
    });
  }

  onGetContact(contact, isCopy?: boolean) {
    isCopy = (isCopy == true) ? true : false;
    this.contactInvalid = false;
    this.archiveInvalid = false;
    this.inactiveContact = false;
    this.contactID = contact.contactID;
    this.contactName = contact.contactName;
    if (contact.status != 0 && contact.contactID > 0) {
      let inactiveContact = this.lstContacts.filter(x => x.contactID == this.contactID);
      if (inactiveContact.length == 0) {
        this.inactiveContact = true;
      }
    }
    else if (contact.status == 0 && contact.contactID > 0 && isCopy == true) {
      let inactiveContact = this.lstContacts.filter(x => x.contactID == this.contactID);
      if (inactiveContact.length == 0) {
        this.inactiveContact = true;
      }
    }
    else if (contact.status == 0 && contact.contactID > 0 && isCopy == false) {
      //let contactName = '';
      let c = this.lstContacts.filter(x => x.contactID == this.contactID);
      if (c.length > 0) {
        this.toSelectContact.emit(c[0]);
      }
      else {
        let cc: any = {
          contactID: contact.contactID,
          contactName: '',
          isNewContact: false
        }
        this.toSelectContact.emit(cc);
      }
    }
  }

  onSelectContact(e) {
    this.contactInvalid = false;
    this.archiveInvalid = false;
    this.isSaveContact = false;
    if (e!!) {
      this.toSelectContact.emit(e);
    }
    else {
      let cc: any = {
        contactID: 0,
        contactName: '',
        isNewContact: false
      }
      this.contactID = null;
      this.contactInvalid = true;
      this.toSelectContact.emit(cc);
    }
  }

  onClear() {
    this.contactID = null;
    this.contactName = '';
    this.isSaveContact = false;
    let cc: any = {
      contactID: 0,
      contactName: '',
      isNewContact: false
    }
    this.toSelectContact.emit(cc);
  }
  onOpen() {
    this.isSaveContact = false;
  }

  onSearchForApi($event) {
    this.isSaveContact = false;
    let c = this.lstContacts.filter(x => x.contactName.trim().toString().toUpperCase() == $event.term.trim().toString().toUpperCase());
    if (c.length == 0 && $event.term.trim().length > 0) {
      this.isSaveContact = true;
    }
    this.contactNameSearch = $event.term.trim();
  }
  saveNewContact() {
    if (this.contactNameSearch!!) {
      let newvalu = {
        "contactID": 0,
        "contactName": this.contactNameSearch
      }
      const strinfynewContact = JSON.stringify(newvalu);
      let contactPayload = { oldValue: null, newValue: strinfynewContact, pageID: this.page.pageDetails.pageID }
      this.http.postservice(environment.organizationApiUrl + '/Contacts/SaveUpdateContacts/', contactPayload).subscribe({
        next: (data) => {
          let retundata: any = data;
          if (Number(retundata.responseData) > 0) {
            let ObjContacts: any = null
            this.http.getservice(environment.organizationApiUrl + '/Master/GetAllContacts?ContactID=' + Number(retundata.responseData)).subscribe({
              next: (data) => {
                ObjContacts = data[0];
                ObjContacts.isNewContact = true;
                this.lstContacts.push(ObjContacts);
                this.lstContacts = this.lstContacts.slice(0);
                this.contactID = Number(retundata.responseData);
                this.toSelectContact.emit(ObjContacts);
                this.isSaveContact = false;
                this.selectContact.isOpen = false;
              }
            });
            let msg = this.translate.instant("ContactModule.Contact.Contacts saved successfully");
            this.successNotifierId = this.alertMessage.successNotifier(msg, this.successNotifierId);
          }
          else {
            let SucessBody = {
              title: "",
              message: retundata.responseData,
              footer: " "
            }
            this.alertMessage.alertWithCustomizeWarning(SucessBody);
          }
        }
      });
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.contactName.toLocaleLowerCase().indexOf(term) > -1;
  }

  onScrollToEnd() {
    this.fetchMore();
  }
  onScroll({ end }) {
    if (this.loadingContact || this.lstContacts.length <= this.itemBuffer.length) {
      return;
    }
    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.itemBuffer.length) {
      this.fetchMore();
    }
  }

  fetchMore() {
    const len = this.itemBuffer.length;
    const more = this.lstContacts.slice(len, this.bufferSize + len);
    this.loadingContact = true;
    // using timeout here to simulate backend API delay
    setTimeout(() => {
      this.loadingContact = false;
      this.itemBuffer = this.itemBuffer.concat(more);
    }, 200)
  }
}
