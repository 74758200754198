import { ITooltipParams } from '@ag-grid-community/all-modules';
import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customtooltip',
  templateUrl: './customtooltip.component.html',
  styleUrls: ['./customtooltip.component.scss']
})
export class CustomtooltipComponent implements OnInit, ITooltipAngularComp {

  params: ITooltipParams;
  valueToDisplay: string;
  Display: string;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: ITooltipParams): void {
    this.params = params;
    this.valueToDisplay = (this.params.valueFormatted != undefined && this.params.valueFormatted != null && this.params.valueFormatted != "" && this.params.valueFormatted != "[object Object]"
      ? this.params.valueFormatted : (this.params.value != undefined && this.params.value != null && this.params.value != "" && this.params.value != "[object Object]"
        ? this.params.value : '')).normalize().replace(/\\n/g, '<br/>');
  }
}
