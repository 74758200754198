<ng-container [formGroup]="form">
  <div *ngIf='!viewPageMode && verticalAlign == false' class="form-group row">
    <ng-container *ngFor="let formItem of dynamicFormFields">
      <ng-container [ngSwitch]="formItem.type">

        <div class="col-md-3 pr-0" *ngSwitchCase="'text'">
          <label class="form-col-form-label">{{formItem.label}} <span *ngIf="formItem.isMandate"
              class="text-danger">*</span></label>
          <input class="form-control overflow-auto mxh-150" type="text" [formControlName]="formItem.id"
            autocomplete="off" [id]="formItem.id" required name="formItem.id" maxlength="250"
            (blur)="onBlurEvent($event,formItem.id)" xssDirective>
          <div *ngIf="formItem.showMandate" style="color: #e55353; font-size: 80%; margin-top: 0.25rem">
            {{formItem.label}} {{'Common. is required' | translate}}
          </div>
        </div>

        <div class="col-md-3 pr-0" *ngSwitchCase="'select'">
          <label class="form-col-form-label">{{formItem.label}} <span *ngIf="formItem.isMandate"
              class="text-danger">*</span></label>
          <ng-select [id]="formItem.id" [multiple]="false" placeholder="Select" [clearable]="true"
            [formControlName]="formItem.id" [items]="formItem.customValues" bindValue="CustomValueID"
            bindLabel="CustomValueText" (change)="onBlurEvent($event,formItem.id)">
          </ng-select>
          <div *ngIf="formItem.showMandate" style="color: #e55353; font-size: 80%; margin-top: 0.25rem">
            {{formItem.label}} is required
          </div>
        </div>

      </ng-container>
    </ng-container>
  </div>
  <div *ngIf='!viewPageMode && verticalAlign == true'>
    <ng-container *ngFor="let formItem of dynamicFormFields">
      <ng-container [ngSwitch]="formItem.type">

        <div class="form-group row" *ngSwitchCase="'text'">
          <div class="col-md-4 pr-0">
            <label class="form-col-form-label">{{formItem.label}} <span *ngIf="formItem.isMandate"
                class="text-danger">*</span></label>
          </div>
          <div class="col-md-8">
            <input class="form-control overflow-auto mxh-150" type="text" [formControlName]="formItem.id"
              autocomplete="off" [id]="formItem.id" required name="formItem.id" maxlength="250"
              (blur)="onBlurEvent($event,formItem.id)" xssDirective>
            <div *ngIf="formItem.showMandate" style="color: #e55353; font-size: 80%; margin-top: 0.25rem">
              {{formItem.label}} is required
            </div>
          </div>
        </div>

        <div class="form-group row" *ngSwitchCase="'select'">
          <div class="col-md-4 pr-0">
            <label class="form-col-form-label">{{formItem.label}} <span *ngIf="formItem.isMandate"
                class="text-danger">*</span></label>
          </div>
          <div class="col-md-8">
            <ng-select [id]="formItem.id" [multiple]="false" placeholder="Select" [clearable]="true"
              [formControlName]="formItem.id" [items]="formItem.customValues" bindValue="CustomValueID"
              bindLabel="CustomValueText" dropdownPosition='top' (change)="onBlurEvent($event,formItem.id)">
            </ng-select>
            <div *ngIf="formItem.showMandate" style="color: #e55353; font-size: 80%; margin-top: 0.25rem">
              {{formItem.label}} is required
            </div>
          </div>
        </div>

      </ng-container>
    </ng-container>
  </div>
  <div *ngIf='viewPageMode && verticalAlign == false' class="form-group row">
    <ng-container *ngFor="let cfs of customFieldSettings">

      <div *ngIf="cfs.altDisplayValue!=null && cfs.altDisplayValue!=''" class="col-md-2 pr-0">
        <label class="form-col-form-label"><b>{{cfs.FieldName}}</b></label>
        <div class="">{{cfs.altDisplayValue}}</div>
      </div>

    </ng-container>
  </div>
  <div *ngIf='viewPageMode && verticalAlign == true' class="form-group row">
    <ng-container *ngFor="let cfs of customFieldSettings">

      <div class="col-md-6 pr-0">
        <label class="form-col-form-label"><b>{{cfs.FieldName}}</b></label>

      </div>

      <div class="col-md-6 pr-0">

        <div class="" *ngIf="cfs.altDisplayValue!=null && cfs.altDisplayValue!=''">{{cfs.altDisplayValue}}</div>
        <div class="" *ngIf="cfs.altDisplayValue==null || cfs.altDisplayValue==''">-</div>
      </div>


    </ng-container>
  </div>

</ng-container>