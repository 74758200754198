import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { HttpServices } from '../../../utilities/_services/http.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @ViewChild('showgallerymodal') public showgallerymodal: ModalDirective;

  fileData: any = [];
  objFile: any = { fileExtension: null, fileName: null, filbytes: null };
  index = 0;
  rotation = 0;
  constructor(private http: HttpServices, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  }

  async openGallery(TransID, TransTypeID) {
    this.rotation = 0;
    this.index = 0;
    let fileData = await this.http.getserviceawait(environment.organizationApiUrl + '/Document/GetFilesBytes/' + TransID + '/' + TransTypeID + '/Viewer');
    this.fileData = fileData['responseData'];
    this.objFile = this.fileData[this.index];
    if (this.objFile.fileExtension == 'pdf') {
      this.OnpdfViewer();
    }
    else {
      this.showgallerymodal.show();
    }
  }

  base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  activetoscroll() {
    setTimeout(() => {
      let parent = $('.gallery-left-files');
      let child = $('.gallery-left-files .active');

      parent.animate({
        scrollTop: child.offset().top - parent.offset().top + parent.scrollTop()
      }, 100);
    }, 100);
  }


  OnAction(action, f?: any) {
    if (action == 'RotateLeft') {
      if (this.rotation == 0) {
        this.rotation = 270;
      }
      else if (this.rotation == 270) {
        this.rotation = 180;
      }
      else if (this.rotation == 180) {
        this.rotation = 90;
      }
      else if (this.rotation == 90) {
        this.rotation = 0;
      }
    }
    else if (action == 'RotateRight') {
      if (this.rotation == 0) {
        this.rotation = 90;
      }
      else if (this.rotation == 90) {
        this.rotation = 180;
      }
      else if (this.rotation == 180) {
        this.rotation = 270;
      }
      else if (this.rotation == 270) {
        this.rotation = 0;
      }
    }
    else if (action == 'ZoomIn') {
      let img: any = this.document.getElementById("imggallery");
      img.width = img.width + 50;
      img.height = img.height + 50;
    }
    else if (action == 'ZoomOut') {
      let img: any = this.document.getElementById("imggallery");
      img.width = img.width - 50;
      img.height = img.height - 50;
    }
    else if (action == 'Previous') {
      this.rotation = 0;
      this.index = this.index - 1;
      if (this.index < 0) {
        this.index = this.fileData.length - 1;
      }
      this.objFile = this.fileData[this.index];
      if (this.objFile.fileExtension == 'pdf') {
        this.OnpdfViewer();
      }
      this.activetoscroll();
    }
    else if (action == 'Next') {
      this.rotation = 0;
      this.index = this.index + 1;
      if (this.index > (this.fileData.length - 1)) {
        this.index = 0;
      }
      this.objFile = this.fileData[this.index];
      if (this.objFile.fileExtension == 'pdf') {
        this.OnpdfViewer();
      }
      this.activetoscroll();
    }
    else if (action == 'Select') {
      this.rotation = 0;
      this.index = f;
      this.objFile = this.fileData[this.index];
      if (this.objFile.fileExtension == 'pdf') {
        this.OnpdfViewer();
      }
    }
    else if (action == 'Download') {
      var hiddenElement = document.createElement('a');
      hiddenElement.id = f.fileId;
      hiddenElement.href = f.filbytes;
      hiddenElement.target = '_blank';
      hiddenElement.download = f.fileName;
      hiddenElement.click();
    }
  }

  // downloadFile(f: any) {
  //   var hiddenElement = document.createElement('a');
  //   hiddenElement.id = f.fileId;
  //   hiddenElement.href = f.filbytes;
  //   hiddenElement.target = '_blank';
  //   hiddenElement.download = f.fileName;
  //   hiddenElement.click();
  // }

  // OnSelect(i) {
  //   this.rotation = 0;
  //   this.index = i;   
  //   this.objFile = this.fileData[this.index];
  //   if (this.objFile.fileExtension == 'pdf') {
  //     this.OnpdfViewer();
  //   }
  // }

  // OnPrevious() {
  //   this.rotation = 0;
  //   this.index = this.index - 1;
  //   if (this.index < 0) {
  //     this.index = this.fileData.length - 1;
  //   }
  //   this.objFile = this.fileData[this.index];
  //   if (this.objFile.fileExtension == 'pdf') {
  //     this.OnpdfViewer();
  //   }
  // }

  // OnNext() {
  //   this.rotation = 0;
  //   this.index = this.index + 1;
  //   if (this.index > (this.fileData.length - 1)) {
  //     this.index = 0;
  //   }
  //   this.objFile = this.fileData[this.index];
  //   if (this.objFile.fileExtension == 'pdf') {
  //     this.OnpdfViewer();
  //   }
  // }

  // OnRotateRight(){
  //   if(this.rotation == 0){
  //     this.rotation = 90;
  //   }
  //   else  if(this.rotation == 90){
  //     this.rotation = 180;
  //   }
  //   else if(this.rotation == 180){
  //     this.rotation = 270;
  //   }
  //   else if(this.rotation == 270){
  //     this.rotation = 0;
  //   }
  // }

  // OnRotateLeft(){
  //   if(this.rotation == 0){
  //     this.rotation = 270;
  //   }
  //   else  if(this.rotation == 270){
  //     this.rotation = 180;
  //   }
  //   else if(this.rotation == 180){
  //     this.rotation = 90;
  //   }
  //   else if(this.rotation == 90){
  //     this.rotation = 0;
  //   }
  // }

  // OnZoomIn(){
  //   let img: any = this.document.getElementById("imggallery");
  //   img.width = img.width + 50;
  //   img.height = img.height + 50;
  // }

  // OnZoomOut(){
  //   let img: any = this.document.getElementById("imggallery");
  //   img.width = img.width - 50;
  //   img.height = img.height - 50;
  // }

  OnpdfViewer() {
    let result = this.objFile.filbytes;
    result = result.replace("data:application/pdf;base64,", "")
    let sampleArr = this.base64ToArrayBuffer(result);
    let blob = new Blob([sampleArr], { type: "application/pdf" });
    let objurl = window.URL.createObjectURL(blob);
    var iframe = this.document.createElement('iframe');
    iframe.id = this.objFile.fileId
    iframe.src = objurl;
    iframe.width = "100%";
    iframe.height = "500px";
    setTimeout(() => {
      let pdfele: any = this.document.getElementById("pdfviewer");
      pdfele.innerHTML = "";
      pdfele.appendChild(iframe);
      this.showgallerymodal.show();
    }, 500);
  }
}
