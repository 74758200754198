<div bsModal #showgallerymodal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="showgallerymodalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-xl modal-dialog-scrollable1" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="pb-0 mb-0">{{objFile.fileName}}</h4>
                <div class="gallery-ctrl-menu">
                    <ng-container
                        *ngIf="objFile.fileExtension == 'jpg' || objFile.fileExtension == 'jpeg' || objFile.fileExtension == 'png'">
                        <span type="button" class="material-icons-outlined" title="Rotate Left"
                            (click)="OnAction('RotateLeft');">rotate_left</span>
                        <span type="button" class="material-icons-outlined" title="Rotate Right"
                            (click)="OnAction('RotateRight');">rotate_right</span>
                        <span type="button" class="material-icons-outlined" title="Zoom In"
                            (click)="OnAction('ZoomIn');">zoom_in</span>
                        <span type="button" class="material-icons-outlined" title="Zoom Out"
                            (click)="OnAction('ZoomOut');">zoom_out</span>
                    </ng-container>
                    <span type="button" (click)="OnAction('Previous');" class="material-icons-outlined"
                        title="Previous">arrow_back</span>
                    <span type="button" (click)="OnAction('Next');" class="material-icons-outlined"
                        title="Next">arrow_forward</span>
            
                    <span type="button" (click)="OnAction('Download',objFile);" class="material-icons-outlined"
                        title="Download">download</span>
                    <span type="button" id="btngalleryModelHide-1" (click)="showgallerymodal.hide()" class="material-icons-outlined"
                        aria-label="Close" title="Close">close</span>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-3 gallery-left-files v-scroll">
                        <ng-container *ngFor="let f of fileData;let i = index;">
                            <div class="row" [ngClass]="index == i? 'active': ''" (click)="OnAction('Select', i);">
                            <div class="col-2 px-2">
                                <span *ngIf="f.fileExtension == 'pdf'" class="material-icons text-danger">
                                    picture_as_pdf
                                </span>
                                <span *ngIf="f.fileExtension == 'txt'" class="material-icons text-light">
                                    text_snippet
                                </span>
                                <span
                                    *ngIf="f.fileExtension == 'jpg' || f.fileExtension == 'jpeg' || f.fileExtension == 'gif' || f.fileExtension == 'png'"
                                    class="material-icons text-warning">
                                    image
                                </span>
                                <span *ngIf="f.fileExtension == 'doc' || f.fileExtension == 'docx'" class="material-icons text-primary">
                                    text_snippet
                                </span>
                                <span *ngIf="f.fileExtension == 'xls' || f.fileExtension == 'xlsx'" class="material-icons text-success">
                                    text_snippet
                                </span>
                                </div>
                                <div class="col-10 px-2">{{f.fileName}}</div>
                            </div>
                        </ng-container>

                    </div>
                    <div class="col-9 gallery-right-files v-scroll">
                        <div class="text-center">
                            <ng-container *ngIf="objFile.fileExtension == 'pdf'">
                                <div id="pdfviewer"></div>
                            </ng-container>
                            <ng-container
                                *ngIf="objFile.fileExtension == 'jpg' || objFile.fileExtension == 'jpeg' || objFile.fileExtension == 'png'">
                                <img [src]="objFile.filbytes" id="imggallery" *ngIf="objFile.filbytes" class="img-fluid"  [ngClass]="rotation == 0? 'r0': rotation == 90? 'r90': rotation == 180? 'r180': rotation == 270? 'r270': 'r0'">
                            </ng-container>
                            <ng-container
                                *ngIf="objFile.fileExtension !== 'pdf' && !(objFile.fileExtension == 'jpg' || objFile.fileExtension == 'jpeg' || objFile.fileExtension == 'png')">
                                <span class="material-icons text-success mt-4" *ngIf="objFile.fileExtension != 'txt' && objFile.fileExtension != 'gif' ">text_snippet</span>
                                <span class="material-icons text-light mt-4" *ngIf="objFile.fileExtension == 'txt'">note</span>
                                <span class="material-icons text-warning mt-4" *ngIf="objFile.fileExtension == 'gif'">image</span>
                                <h6>{{objFile.fileName}}</h6>
                                <button type="button" class="btn btn-outline-info" (click)="OnAction('Download',objFile);">
                                <span   class="c-icon material-icons-outlined m-0"
                                    title="Download">download</span> Download
                                </button>

                               
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>