<ng-container *ngIf="isWithoutLogin">
  <app-header [navbarBrandRouterLink]="['/login']" [fixed]="true"
    [navbarBrandFull]="{src: 'assets/brand/Info-Tech-Logo_sm.png', width: 80, height: 31, alt: 'Info-tech Logo'}"
    [navbarBrandMinimized]="{src: 'assets/brand/Info-Tech-Logo_xsm.png', width: 30, height: 10, alt: 'Info-tech Logo'}"
    [sidebarToggler]="false" [asideMenuToggler]="false" [mobileAsideMenuToggler]="false"></app-header>
</ng-container>
<div class="animated fadeIn" id="target-element" [ngClass]="{'payment-direct-sub':isWithoutLogin}">
  <div class="row">
    <div class="mb-4 col-md-12">
      <mat-horizontal-stepper [linear]="true" #stepper (selectionChange)="onStepChange($event)">
        <mat-step [stepControl]="valid0" label="Plan">
          <div class="card">
            <div class="card-header bg-teal-light border-bottom-0">
              <h6 class="font-weight-bold border-bottom-0 m-0">Choose your subscription plan</h6>
            </div>
            <div class="container-fluid">
            <div class="row">
              <ng-container *ngFor="let p of lstPlans;let i = index;">               
                <div class="col-md-4 mt-4 mb-4">
                  <div class="card subscrip" (click)="selectPlan(p.RecurrenceId)" [ngClass]="objSummary.RecurrenceId == p.RecurrenceId ?'active':'cursor-pointer'">
                    <div class="offer" *ngIf="p.DiscountAmount > 0">
                      <i class="material-icons">bookmark</i>
                      <div class="savetext">Save <br> {{p.DiscountPercentage}}%!</div>
                    </div>
                    <div class="card-body text-center plan-css">
                      <h6 class="font-weight-bold">{{p.RecurrencePeriod}}</h6>
                      <div class="seprator"></div>
                      <div class=""><span class="mr-2 header-3"><sup>{{objSummary.CurrencyCode}}</sup></span>
                        <span class="mr-1 header-3" *ngIf="p.DiscountAmount > 0"> <s>{{p.SubscriptionPrice}}</s></span>
                        <span class="h4 font-weight-bold">{{p.NetAmount}} /</span><span class="h4">{{p.RecurrencePeriod}}</span>
                      </div>
                      <div *ngIf="p.DiscountAmount > 0">
                        <h5> <span class="badge m-0 p-2 ">
                            Save {{objSummary.CurrencyCode}} {{p.DiscountAmount}} ~ {{p.DiscountPercentage}}%! </span>
                        </h5>
                      </div>
                      <!-- <div *ngIf="p.DiscountAmount > 0">
                        <h5> <span class="badge"> &nbsp;
                          </span>
                        </h5>
                      </div> -->
                      <div class=""  innerHTML="{{p.PlanDesc}}">                        
                      </div>
                
                      <button  [ngClass]="objSummary.RecurrenceId == p.RecurrenceId ?'active btn btn-info':'btn btn-light cursor-pointer'"
                      (click)="selectPlan(p.RecurrenceId)">Select</button>
                    </div>
                
                  </div>
                </div>
              </ng-container>
              <div class="col-md-4 d-flex flex-column ml-auto mt-4 mb-4">
                <h3>Summary</h3>
                <div>
                  <div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="pb-1">
                        Product Name
                      </div>
                      <div class=" pb-1">
                        {{objSummary.ProductName}}
                      </div>
                    </div>
                    <hr class="mt-1">
                  </div>
                  <div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class=" pb-1">
                        Plan Name
                      </div>
                      <div class=" pb-1">
                        {{objSummary.RecurrencePeriod}}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class=" pb-1">
                        Amount
                      </div>
                      <div class=" pb-1">
                        {{objSummary.SubscriptionPrice}}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-start" *ngIf="objSummary.Discount > 0">
                      <div class=" pb-1">
                        Discount ({{objSummary.DiscountPercentage}}%)
                      </div>
                      <div class=" pb-1">
                        {{objSummary.Discount}}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-start" *ngIf="objSummary.Discount > 0">
                      <div class=" pb-1">
                        Net Amount
                      </div>
                      <div class=" pb-1">
                        {{objSummary.NetAmount}}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class=" pb-1">
                        Tax Amount ({{objSummary.TaxRate}}%)
                      </div>
                      <div class=" pb-1">
                        {{objSummary.TaxAmount}}
                      </div>
                    </div>
              
                    <hr class="mt-1">
                  </div>
                  <div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class=" pb-1">
                        Paid Amount
                      </div>
                      <div class=" pb-1">
                        {{objSummary.PaidAmount}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right mt-auto pt-5">
                  <button (click)="next(0)" class="btn btn-primary">Next</button>
                </div>

              </div>
            </div>
           </div>
          </div>
         
        </mat-step>
        <mat-step [stepControl]="valid1" label="Billing Account">
          <div class="card">
            <div class="card-header bg-teal-light border-bottom-0">
              <h6 class="font-weight-bold border-bottom-0 m-0">Billing Account Details</h6>
            </div>
            <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 mt-4 mb-4">
              <form class="form-horizontal needs-validation" novalidate="novalidate" [formGroup]="billingAccountForm">
                <div class="row justify-content-center">
                  <div class="col-6">
                    <label class="col-form-label" for="BillingAccountName">Billing Account Name<span
                        class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="BillingAccountName"
                      [ngClass]="{ 'is-invalid': submitted2 && ba.BillingAccountName.errors }" xssDirective />
                    <div *ngIf="submitted2 && ba.BillingAccountName.errors" class="invalid-feedback">
                      <div *ngIf="ba.BillingAccountName.errors.required">Is required</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label" for="Email">Email<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="Email"
                      [ngClass]="{ 'is-invalid': submitted2 && ba.Email.errors }" xssDirective />
                    <div *ngIf="submitted2 && ba.Email.errors" class="invalid-feedback">
                      <div *ngIf="ba.Email.errors.required">Is required</div>
                    </div>
                    <div *ngIf="ba.Email.errors" class="invalid-feedback">
                      <div *ngIf="ba.Email.errors.pattern">Please provide a valid email id.</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label" for="Phone">Phone<span
                      class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="Phone" xssDirective maxlength="15" phonenumber
                      [ngClass]="{ 'is-invalid': submitted2 && ba.Phone.errors }">
                    <div *ngIf="submitted2 && ba.Phone.errors" class="invalid-feedback">
                      <div *ngIf="ba.Phone.errors.required">Is required</div>
                    </div>
                    <div *ngIf="ba.Phone.errors" class="invalid-feedback">
                      <div *ngIf="ba.Phone.errors.pattern">Please provide a valid mobile no.</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label" for="CountryID">Country<span
                      class="text-danger">*</span></label>
                    <ng-select formControlName="CountryID" [items]="lstCountries" bindValue="countryID" bindLabel="countryName"
                    [ngClass]="{ 'is-invalid': submitted2 && ba.CountryID.errors }">
                    </ng-select>
                    <div *ngIf="submitted2 &&  ba.CountryID.errors" class="invalid-feedback">
                      <div *ngIf=" ba.CountryID.errors.required">Is required</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label" for="Address">Address<span
                      class="text-danger">*</span></label>
                    <textarea formControlName="Address" class="form-control" rows="5" maxlength="500"
                    [ngClass]="{ 'is-invalid': submitted2 && ba.Address.errors }" xssDirective></textarea>
                    <div *ngIf="submitted2 && ba.Address.errors" class="invalid-feedback">
                      <div *ngIf="ba.Address.errors.required">Is required</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <label class="col-form-label" for="City">City<span
                          class="text-danger">*</span></label>
                        <input type="text" formControlName="City" class="form-control" maxlength="50" 
                        [ngClass]="{ 'is-invalid': submitted2 && ba.City.errors }" xssDirective> 
                        <div *ngIf="submitted2 && ba.City.errors" class="invalid-feedback">
                          <div *ngIf="ba.City.errors.required">Is required</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <label class="col-form-label" for="State">State<span
                          class="text-danger">*</span></label>
                        <input type="text" formControlName="State" class="form-control" maxlength="50" 
                        [ngClass]="{ 'is-invalid': submitted2 && ba.State.errors }" xssDirective> 
                        <div *ngIf="submitted2 && ba.State.errors" class="invalid-feedback">
                          <div *ngIf="ba.State.errors.required">Is required</div>
                        </div>
                      </div>
                    </div>
                  </div>                 
                  <div class="col-6">
                    <label class="col-form-label" for="ZipCode">Zip Code<span
                      class="text-danger">*</span></label>
                    <input type="text" formControlName="ZipCode" class="form-control" maxlength="10" 
                    [ngClass]="{ 'is-invalid': submitted2 && ba.ZipCode.errors }" xssDirective> 
                        <div *ngIf="submitted2 && ba.ZipCode.errors" class="invalid-feedback">
                          <div *ngIf="ba.ZipCode.errors.required">Is required</div>
                        </div>
                  </div>
                  <div class="col-6">
                   
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-4 d-flex flex-column ml-auto mt-4 mb-1">
              <h3>Summary</h3>
              <div>
                <div>
                  <div class="d-flex justify-content-between align-items-start">
                    <div class="pb-1">
                      Product Name
                    </div>
                    <div class=" pb-1">
                      {{objSummary.ProductName}}
                    </div>
                  </div>
                  <hr class="mt-1">
                </div>
                <div>
                  <div class="d-flex justify-content-between align-items-start">
                    <div class=" pb-1">
                      Plan Name
                    </div>
                    <div class=" pb-1">
                      {{objSummary.RecurrencePeriod}}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-start">
                    <div class=" pb-1">
                      Amount
                    </div>
                    <div class=" pb-1">
                      {{objSummary.SubscriptionPrice}}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-start" *ngIf="objSummary.Discount > 0">
                    <div class=" pb-1">
                      Discount ({{objSummary.DiscountPercentage}}%)
                    </div>
                    <div class=" pb-1">
                      {{objSummary.Discount}}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-start" *ngIf="objSummary.Discount > 0">
                    <div class=" pb-1">
                      Net Amount
                    </div>
                    <div class=" pb-1">
                      {{objSummary.NetAmount}}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-start">
                    <div class=" pb-1">
                      Tax Amount ({{objSummary.TaxRate}}%)
                    </div>
                    <div class=" pb-1">
                      {{objSummary.TaxAmount}}
                    </div>
                  </div>
            
                  <hr class="mt-1">
                </div>
                <div>
                  <div class="d-flex justify-content-between align-items-start">
                    <div class=" pb-1">
                      Paid Amount
                    </div>
                    <div class=" pb-1">
                      {{objSummary.PaidAmount}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="float-right justify-content-between mt-auto">
                <br> <br><br> <br><br> <br>
                <p class="text-info text-justify text-wrap">* Info-Accounting subscriptions auto-renew {{objSummary.RecurrencePeriod | lowercase }} until the organization is cancelled.There is a 1 month notice period required for your cancellation.
                  Contact Infotech support <b>support@info-tech.com.sg</b> if you need help.</p>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-6">
              <div class="float-left">
                <button (click)="previous(1)" class="btn btn-primary">Back</button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="float-right">
                <button [disabled]="loading" (click)="makePayment()" class="btn btn-success">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Pay</button>
              </div>
            </div>
          </div>

        </div>

        </div>
        
        </mat-step>
      </mat-horizontal-stepper>
    </div>

  </div>
</div>