import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { EncryptDecryptService } from '../../../app/utilities/_services/encryptdecrypt.service';
import { AuthenticationService } from '../../../app/utilities/_services/authentication.service';
import { AccsessioncacheService } from '../../../app/utilities/_services/accsessioncache.service';
import { LocalCacheServices } from '../../../app/utilities/_services/acclocalcache.service';
import { CustomValidator, DateValidator, EmailorPhoneValidator, ValidEmail, ValidPhone } from '../../utilities/_directives/customvalidator.directive';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertMessage } from '../../utilities/_helpers/alert.message';
import { v4 as uuid4 } from 'uuid';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { HostService } from '../../utilities/_services/host.serices';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup | any;
  loading = false;
  submitted = false;
  error = '';
  maintanencenote: any = '';

  statusMsg = '';
  ForgotForm: FormGroup | any;
  isForgot = false;

  @ViewChild('showsupportuser') public showsupportuser: ModalDirective;
  userType: any = 1;
  LoingUserID: any = 0;
  IsSupportUser: any = false;
  SupportUserOptions: any[] = [];
  statusMsgID: any = 0;
  popupText: string = "You have access as both Support User and Normal User.";

  get UUID(): string {
    let uuid = JSON.parse(this.local.getlocalStorageBase64('uuid'));
    if (!uuid) {
      this.local.setlocalStorageBase64('uuid', uuid4());
      uuid = JSON.parse(this.local.getlocalStorageBase64('uuid'));
    }
    return uuid;
  }
  isDigisme = false;
  pwdType = 'password';
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,private hostservice:HostService,
    private authenticationService: AuthenticationService, private encdec: EncryptDecryptService,private titleService: Title,
    private local: LocalCacheServices, private session: AccsessioncacheService, private alertMessage: AlertMessage) {
      this.isDigisme = this.hostservice.getDigisme();
      if(this.isDigisme){
        this.titleService.setTitle("DigiSME Accounting Software - Login");
      }
      else{
        this.titleService.setTitle("Info-Tech Accounting Software – Login Here");
      }
    this.userType = 0;
    this.LoingUserID = 0;
    this.SupportUserOptions.push({ userType: 0, userTypeName: "--Select--" });
    this.SupportUserOptions.push({ userType: 1, userTypeName: "Normal User" });
    this.SupportUserOptions.push({ userType: 2, userTypeName: "Support User - Customer Databases" });
    this.SupportUserOptions.push({ userType: 3, userTypeName: "Support User - Internal Screens" });
    if (this.authenticationService.currentUserValue) {
      let timeout = this.authenticationService.getTokenTime();
      if (timeout > 0)
        this.router.navigate(['/']);
    }
    this.authenticationService.GetMaintanenceNotice(this.isDigisme).then(
      (data: any) => {
        this.maintanencenote = data;
      }
    )
  }

  ngOnInit() {
    this.ResendTimeout();
    this.WaitingTimeout();
    window.addEventListener('storage', function (e) {
      if (e.key === "IkN1cnJlbnRVc2VyIg==") // login session key
      {
        window.location.reload();
      }
    }, false);

    let returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';

    if (!this.authenticationService.currentUserValue) {
      if (!decodeURI(returnUrl).includes('links/authorize')) {
        this.local.removeLocalCache();
        this.session.removesession();
      }
    } else {
      this.router.navigateByUrl(decodeURI(returnUrl));
    }

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, EmailorPhoneValidator()]],
      password: ['', Validators.required]
    });

    this.ForgotForm = this.formBuilder.group({
      username: ['', [Validators.required, EmailorPhoneValidator()]]
    });

    this._2FA_OTP_Form = this.formBuilder.group({
      OrgID: [0],
      LoginUserID: [0],
      otp: ['', [Validators.required]],
      otpID: [''],
      CustomerCode: ['']
    });
  }

  get f() { return this.loginForm.controls; }
  get fg() { return this.ForgotForm.controls; }
  get f2fa() { return this._2FA_OTP_Form.controls; }

  IsNewLogin: boolean = true;
  Is2FA_BackupMethod: boolean = false;
  Is2FA_IsAuthDevice: boolean = false;
  Is2FA_IsTwoStepVerification: boolean = false;
  _2FA_OTP_Form: FormGroup;

  TrustedDeviceName: String = '';

  onSubmit() {
    this.IsNewLogin = false;
    this.WaitingTimeoutSec = -1;
    this.GetSupportorNormalUser();
  }



  GetSupportorNormalUser() {
    if (this.IsNewLogin) {
      return;
    }
    let DeviceUID: string = this.encdec.encodeBase64(this.UUID);
    const browserName = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1: return "MS Edge";
        case agent.indexOf("edg/") > -1: return "Edge ( chromium based)";
        case agent.indexOf("opr") > -1 && !!(window as any).opr: return "Opera";
        case agent.indexOf("chrome") > -1 && !!(window as any).chrome: return "Chrome";
        case agent.indexOf("trident") > -1: return "MS IE";
        case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
        case agent.indexOf("safari") > -1: return "Safari";
        default: return "other";
      }
    })(window.navigator.userAgent.toLowerCase());

    this.error = "";
    this.userType = 0;
    this.LoingUserID = 0;
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    let us = this.encdec.encodeBase64(this.f.username.value);
    let pwd = this.encdec.encodeBase64(this.f.password.value);
    this.SupportUserOptions = [];    
    this.authenticationService.GetSupportorNormalUser(us, pwd, DeviceUID, browserName,this.isDigisme).then(
      (resdata: any) => { 
        let data: any = resdata.ResponseData;
        //let data: any = JSON.parse(this.encdec.responsedecode(resdata.responseData));
        this.Is2FA_IsTwoStepVerification = data.IsTwoStepVerification;
        this.Is2FA_IsAuthDevice = data.IsAuthDevice;
        if (this.Is2FA_IsTwoStepVerification) {
          if (this.Is2FA_IsAuthDevice) {
            this.WaitingTimeoutSec = -1;
            this.login(data);
          }
          else if (!data.IsTrusted) {
            this.ResendTimeoutSec = 180;
            this._2FA_OTP_Form.patchValue({ otpID: data.otpID });
            this._2FA_OTP_Form.patchValue({ LoginUserID: data.LoingUserID });
            this._2FA_OTP_Form.patchValue({ OrgID: data.OrgID });
            this._2FA_OTP_Form.patchValue({ CustomerCode: data.CustomerCode });
            this.UseBackupMethod(false);
          }
          else if (data.IsAccept != null && !data.IsAccept) {
            Swal.fire({
              title: 'Confirm',
              text: 'Your request has been declined from truested device. do you want to use backup methods for login.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Ok',
              cancelButtonText: 'Cancel'
            }).then((result) => {
              if (result.isConfirmed) {
                this.UseBackupMethod(true);
              }
              else {
                this.BackToLogin();
              }
            })
          }
          else {
            this.TrustedDeviceName = data.TrustedDeviceName;
            this._2FA_OTP_Form.patchValue({ LoginUserID: data.LoingUserID });
            this._2FA_OTP_Form.patchValue({ OrgID: data.OrgID });
            this._2FA_OTP_Form.patchValue({ CustomerCode: data.CustomerCode });
            if (!this.Is2FA_BackupMethod) {
              setTimeout(() => {
                if (this.WaitingTimeoutSec < 0) {
                  this.WaitingTimeoutSec = 300;
                }
                this.GetSupportorNormalUser()
              }, 2000);
            }
          }
        }
        else {
          this.login(data);
        }
      }, (error) => {
        console.log(error);
        let err = error.toString();
        if (err.includes('blocked') || err.includes('Invalid') || err.includes('User') ||
          err.includes('correct') || err.includes('Unauthorized') || err.includes('registered'))
          this.error = err;
        else
          this.error = "Error while authenticating";
        this.loading = false;
      })
  }

  login(data) {
    this.Is2FA_BackupMethod = false;
    this.Is2FA_IsAuthDevice = false;
    this.Is2FA_IsTwoStepVerification = false;

    if (data.IsNormalUser && data.IssupportUser) {
      this.popupText = "You have access as both Support User and Normal User.";
      this.SupportUserOptions.push({ userType: 0, userTypeName: "--Select--" });
      this.SupportUserOptions.push({ userType: 1, userTypeName: "Normal User" });
      this.SupportUserOptions.push({ userType: 2, userTypeName: "Support User - Customer Databases" });
      this.SupportUserOptions.push({ userType: 3, userTypeName: "Support User - Internal Screens" });
      this.SupportUserOptions = [...this.SupportUserOptions]
      this.loading = false;
      this.LoingUserID = data.LoingUserID;
      this.IsSupportUser = data.IssupportUser;
      this.showsupportuser.show();
    }
    else if (data.IsNormalUser) {
      this.popupText = "You have access as Normal User.";
      this.SupportUserOptions.push({ userType: 0, userTypeName: "--Select--" });
      this.SupportUserOptions.push({ userType: 2, userTypeName: "Support User - Customer Databases" });
      this.SupportUserOptions = [...this.SupportUserOptions]
      this.LoingUserID = data.LoingUserID;
      this.IsSupportUser = data.IssupportUser;
      this.authenticationService.login(this.LoingUserID, data.IssupportUser, 1)
        .pipe(first())
        .subscribe({
          next: () => {            
            let currentUser: any;
            currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
            if (currentUser.licenseTypeID == 3 && currentUser.isSubscriber == true) {
              // get return url from route parameters or default to '/'
              this.router.navigateByUrl(decodeURI("/subscription/subscriptionlist"));
            }
            else {
              // get return url from route parameters or default to '/'
              let returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
              this.router.navigateByUrl(decodeURI(returnUrl));
            }
          },
          error: (err) => {
            console.log(err);
            if (err.includes('blocked') || err.includes('Invalid') || err.includes('User') ||
              err.includes('Email') || err.includes('correct') || err.includes('Unauthorized') ||
              err.includes('registered'))
              this.error = err;
            else
              this.error = "Error while authenticating";
            this.loading = false;
          }
        });
    }
    else if (data.IssupportUser) {
      this.popupText = "Please select which screens you want to see after login.";
      this.SupportUserOptions.push({ userType: 0, userTypeName: "--Select--" });
      this.SupportUserOptions.push({ userType: 2, userTypeName: "Support User - Customer Databases" });
      this.SupportUserOptions.push({ userType: 3, userTypeName: "Support User - Internal Screens" });
      this.SupportUserOptions = [...this.SupportUserOptions]
      this.loading = false;
      this.LoingUserID = data.LoingUserID;
      this.IsSupportUser = data.IssupportUser;
      this.showsupportuser.show();
    }
    else {
      this.error = "Email/Mobile number is not active";
      this.loading = false;
    }
  }

  forgotClick() {
    this.isForgot = true;
    this.statusMsg = "";
    this.loading = false;
    this.submitted = false;
  }

  async onFPSubmit() {
    this.submitted = true;
    if (this.ForgotForm.invalid) {
      return;
    }
    this.loading = true;
    let licenseTypeDigisme = 0
    if(this.isDigisme){
      licenseTypeDigisme = 6;
    }
    else{
      licenseTypeDigisme = 1;
    }
    (await this.authenticationService.ForgotPassword(this.fg.username.value, licenseTypeDigisme))
      .subscribe(
        (response) => {                           //Next callback
          if (response != null && response != undefined) {
            if (response.isSuccess) {
              this.statusMsg = response.StatusMessage;
              this.loading = false;
              this.submitted = false;
            }
            else {
              let err = response.StatusMessage;
              if (err.includes('blocked') || err.includes('Invalid') || err.includes('User') ||
                err.includes('correct') || err.includes('Unauthorized') ||
                err.includes('registered') || err.includes('Further') || err.includes('system'))
                this.statusMsg = response.StatusMessage;
              else
                this.statusMsg = "Unable to send email for forgot password instructions";
              this.loading = false;
              this.submitted = false;
            }
          }
        },
        (error) => {
          let err = error;
          console.log(error);
          if (err.includes('blocked') || err.includes('Invalid') || err.includes('User') ||
            err.includes('correct') || err.includes('Unauthorized') ||
            err.includes('registered') || err.includes('Further'))
            this.statusMsg = error;
          else
            this.statusMsg = "Unable to send email for forgot password instructions";
          this.loading = false;
          this.submitted = false;
        }
      );
  }

  OnCancel() {
    this.isForgot = false;
    this.statusMsg = '';
    this.loading = false;
  }

  onSupportCancel() {
    this.showsupportuser.hide();
    this.loading = false;
  }

  onSupportSubmit() {
    this.error = "";
    if (this.userType <= 0) {
      this.statusMsgID = this.alertMessage.errorNotifier("Please select the profile type", this.statusMsgID);
      return;
    }

    this.loading = true;
    if (this.userType != 3) {
      //authenicate and redirect to customer database screens
      this.authenticationService.login(this.LoingUserID, this.IsSupportUser, this.userType)
        .pipe(first())
        .subscribe({
          next: () => {            
            // get return url from route parameters or default to '/'
            let returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
            this.router.navigateByUrl(decodeURI(returnUrl));
          },
          error: (err) => {
            console.log(err);
            if (err.includes('blocked') || err.includes('Invalid') || err.includes('User') ||
              err.includes('Email') || err.includes('correct') || err.includes('Unauthorized')
              || err.includes('registered'))
              this.error = err;
            else
              this.error = "Error while authenticating";
            this.loading = false;
            this.onSupportCancel();
          }
        });
    }
    else {
      //authenicate and redirect to internal screens
      this.authenticationService.internallogin(this.LoingUserID, this.IsSupportUser, this.userType)
        .pipe(first())
        .subscribe({
          next: () => {
            // get return url from route parameters or default to '/'
            let returnUrl = 'supportdashboard';
            this.router.navigateByUrl(decodeURI(returnUrl));
          },
          error: (err) => {
            console.log(err);
            if (err.includes('blocked') || err.includes('Invalid') || err.includes('User') ||
              err.includes('Email') || err.includes('correct') || err.includes('Unauthorized') ||
              err.includes('registered'))
              this.error = err;
            else
              this.error = "Error while authenticating";
            this.loading = false;
            this.onSupportCancel();
          }
        });
    }
  }

  Resend(param: boolean) {
    this.f2fa.otpID.patchValue('');
    this.UseBackupMethod(param);
  }

  UseBackupMethod(param: boolean) {
    this.WaitingTimeoutSec = 300;
    this.f2fa.otp.patchValue('');
    this.IsNewLogin = true;
    this.submitted = false;
    this.loading = false;
    this.Is2FA_BackupMethod = true;
    if (this.f2fa.otpID.value == '' && param == true) {
      const DeviceUID: string = this.UUID;
      const browserName = (function (agent) {
        switch (true) {
          case agent.indexOf("edge") > -1: return "MS Edge";
          case agent.indexOf("edg/") > -1: return "Edge ( chromium based)";
          case agent.indexOf("opr") > -1 && !!(window as any).opr: return "Opera";
          case agent.indexOf("chrome") > -1 && !!(window as any).chrome: return "Chrome";
          case agent.indexOf("trident") > -1: return "MS IE";
          case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
          case agent.indexOf("safari") > -1: return "Safari";
          default: return "other";
        }
      })(window.navigator.userAgent.toLowerCase());
      let LoginUserID = this.f2fa.LoginUserID.value;
      let OrgID = this.f2fa.OrgID.value;
      let CustomerCode = this.f2fa.CustomerCode.value;
      let Username = this.f.username.value;
      this.authenticationService.ReSendOTP(LoginUserID, Username, OrgID, CustomerCode, DeviceUID, browserName).then(data => {
        this.f2fa.otpID.patchValue(data['responseData']['otpID']);
        this.ResendTimeoutSec = 180;
        this.loading = false;
      });
    }
  }

  On_2FA_OTP_Submit() {
    this.submitted = true;
    if (this._2FA_OTP_Form.invalid) {
      return;
    }
    this.loading = false;
    var otpID = `${this.f2fa.otpID.value}`;
    var otp = `${this.f2fa.otp.value}`;
    this.authenticationService.ValidateOTP(otpID, otp).then(o => {
      if ((o as any).isSuccess == true) {
        this.submitted = false;
        this.onSubmit();
      }
      else {
        const otp = this._2FA_OTP_Form.get('otp');
        otp.setValidators([CustomValidator.ValidOTP]);
        otp.updateValueAndValidity();
      }
    });
  }
  On_2FA_OTP_MSG_ID: number;
  ResendTimeoutSec = 0;
  ResendTimeout() {
    setTimeout(() => {
      if (this.ResendTimeoutSec > 0)
        this.ResendTimeoutSec--;
      this.ResendTimeout();
    }, 1000);
  }
  WaitingTimeoutSec = -1;
  WaitingTimeout() {
    setTimeout(() => {
      if (this.WaitingTimeoutSec > 0)
        this.WaitingTimeoutSec--;
      else if (this.WaitingTimeoutSec == 0) {
        this.BackToLogin();
      }
      this.WaitingTimeout();
    }, 1000);
  }

  otpKeyUp(param) {
    if (param.code != 'Enter') {
      const otp = this._2FA_OTP_Form.get('otp');
      otp.setValidators([Validators.required]);
      otp.updateValueAndValidity();
    }
  }

  BackToLogin() {
    this.f2fa.otpID.patchValue('');
    this.IsNewLogin = true;
    this.Is2FA_BackupMethod = false;
    this.Is2FA_IsTwoStepVerification = false;
    this.loading = false;
    this.submitted = false;
  }
}
