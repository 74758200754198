import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { HostService } from '../app/utilities/_services/host.serices';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  favIcon: HTMLLinkElement = document.querySelector('#favicon');
  idxTitle: HTMLLinkElement = document.querySelector('#idxtitle'); 
  metaDesc: HTMLMetaElement = document.querySelector('#metaDesc'); 
  metaKey: HTMLMetaElement = document.querySelector('#metaKey');
  metaAuthor: HTMLMetaElement = document.querySelector('#metaAuthor');
  isDigiSME = false;
  constructor(
    private router: Router,
    public iconSet: IconSetService,private hostService:HostService
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
    this.isDigiSME = this.hostService.getDigisme();
    if(this.isDigiSME){
      this.favIcon.href = './assets/favicon_digisme.ico';
      this.idxTitle.innerHTML = "DigiSME Accounting Software";
      this.metaDesc.content = "Customer Secure Login Page. Handle your organisation’s finances, track invoices and do much more using our Accounting Software.";
      this.metaKey.content = "DigiSME Accounting Software";
      this.metaAuthor.content = "Digi-SME";
    }
    else{
      this.favIcon.href = './assets/favicon.ico';
      this.idxTitle.innerHTML = "Info-Tech Accounting Software";
      this.metaDesc.content = "Accounting Made Simple with Infotech Accounting Software. Designed to Perfectly Fit for SME’s";
      this.metaKey.content = "Info-Tech Accounting Software";
      this.metaAuthor.content = "Info-Tech";
    }
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
