import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
//import for start
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from "../app/utilities/_helpers/error.interceptor";
import { JwtInterceptor } from '../app/utilities/_helpers/jwt.interceptor';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CustomsidebarnavComponent } from './containers/sidebar/customsidebarnav/customsidebarnav.component'
//import for end

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';
import { SupportdefaultlayoutComponent } from './containers';
import { SubscriptionlayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { customerQuoteComponent } from './views/customerQuote/customerQuote.component'
const APP_CONTAINERS = [
  DefaultLayoutComponent,
  SupportdefaultlayoutComponent,
  SubscriptionlayoutComponent,
  CustomsidebarnavComponent,
  BreadcrumbComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';

import { MaterialModule } from "./material.module";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { SharedModule } from "./views/shared/shared.module";
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { MasterServices } from './utilities/_services/master.service';
import { map } from 'rxjs/operators';
import { TranslationService } from './utilities/_services/translation.service';
import { TemplateMasterRedirectComponent } from './views/template/template-master-redirect/template-master-redirect.component';
import { QuillModule } from 'ngx-quill';
import { LoaderService } from './utilities/_services/loader.service';
import { ToastrModule } from 'ngx-toastr';
import { AgGridModule } from "@ag-grid-community/angular";
import { ModalModule } from 'ngx-bootstrap/modal';
import { TreeviewModule } from 'ngx-treeview';
import { AllowKeypressDirectiveModule } from './utilities/_directives/allowkeypress.directive';
import { GlobalErrorHandler } from './utilities/_helpers/errorhandler';
import { OnlineinvoiceComponent } from './views/onlineinvoice/onlineinvoice.component';
import { PaymentComponent } from './views/payments/payment/payment.component';
import { OnlinepaymentComponent } from './views/payments/onlinepayment/onlinepayment.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { EncdeInterceptor } from './utilities/_helpers/encdec-interceptor.service';
import { SubscriptionsignupComponent } from './views/subscription/subscriptionsignup/subscriptionsignup.component';
import { SubscriptionPaymentComponent } from './views/subscription/subscriptionpayment/subscriptionpayment.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HostService } from './utilities/_services/host.serices';
import { BreadcrumbComponent } from './containers/breadcrumb/breadcrumb.component';
import {  DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  imports: [
    BrowserModule,
    NgSelectModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MaterialModule,
    AppRoutingModule,
    ModalModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    AgGridModule.withComponents([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        //useFactory: httpTranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient]
      }
    }),
    SharedModule, PdfJsViewerModule,
    NgxDatatableModule,
    NgbModule,
    QuillModule.forRoot(),
    ToastrModule.forRoot(),
    TreeviewModule.forRoot(),
    AllowKeypressDirectiveModule,
    AccordionModule,
    NgSelectModule,
    DragDropModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    RegisterComponent,
    TemplateMasterRedirectComponent,
    customerQuoteComponent,
    OnlineinvoiceComponent,
    PaymentComponent,
    OnlinepaymentComponent,
    SubscriptionsignupComponent,
    SubscriptionPaymentComponent
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    IconSetService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EncdeInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" }
    },
    LoaderService,
    HostService    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

// // AOT compilation support
// export function httpTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, environment.organizationApiUrl + "/Master/GetLanguageJson/", "");
// }

