import { Component, OnInit } from '@angular/core';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-template-master-redirect',
  templateUrl: './template-master-redirect.component.html',
  styleUrls: ['./template-master-redirect.component.scss']
})
export class TemplateMasterRedirectComponent implements OnInit {
  currentUser: any;
  constructor( public local: LocalCacheServices, public translate: TranslateService) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    this.loadLanguage();
  }

  loadLanguage(){
    if (this.currentUser.orgCountryID == 94) {        
      this.translate.setDefaultLang('zh_HK');
    }
    else{
      this.translate.setDefaultLang('en_US');    
    }
  }
}
