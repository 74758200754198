<div bsModal #lhdnValidationModal="bs-modal" class="modal fade" tabindex="-1"
    [config]="{ backdrop: 'static', keyboard: false }" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"> Error Detail</h4>
                <button type="button" class="close" (click)="lhdnValidationModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table">
                    <tr>
                        <td>
                            #
                        </td>
                        <td>
                            Element
                        </td>
                        <td>
                            Error
                        </td>
                        <td>

                        </td>
                    </tr>

                    <ng-container *ngFor="let e of result;">
                        <tr>
                            <td colspan="2">{{e.ContactName}}</td>
                            <td><a class="t-link" style="padding:0px" routerLink="/contacts/editcontact"
                                    [queryParams]="{id:encodebtoa(e.contactID)}"> View</a></td>
                        </tr>

                        <tr *ngFor="let a of e.errorList; let i = index">

                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                {{a.Field}}
                            </td>
                            <td class="text-danger">
                                {{ a.Error}}
                            </td>
                            <!-- <td>
                        <a class="t-link" style="padding:0px" routerLink="/contacts/editcontact"
                            [queryParams]="{id:encodebtoa(e.contactID)}"> View</a>
                    </td>  -->
                        </tr>
                    </ng-container>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn btn-info" type="button" (click)="lhdnValidationModal.hide()">Close</button>
            </div>

        </div>
    </div>
</div>

<div bsModal #ErrorModel="bs-modal" class="modal fade" tabindex="-1" [config]="{ backdrop: 'static', keyboard: false }"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Error Detail</h4>
                <button type="button" class="close" (click)="ErrorModel.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="this.Status=='Failed'" class="text-danger">
                     {{ErrorMsg}}
                </div>
                <table class="table" *ngIf="this.Status!='Failed'">
                    <tr>
                        <td>
                            S.No
                        </td>
                        <td>
                            Source
                        </td>
                        <td>
                            Details
                        </td>
                    </tr>

                    <tr *ngFor="let e of ErrorMsg; let i = index">
                        <td>
                            {{i+1}}
                        </td>
                        <td>
                            {{ e.source}}
                        </td>
                        <td class="text-danger">
                            {{ e.details}}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn btn-info" type="button" (click)="ErrorModel.hide()">Close</button>
            </div>

        </div>
    </div>
</div>
<div bsModal #StatusModal="bs-modal" class="modal fade" tabindex="-1" [config]="{ backdrop: 'static', keyboard: false }"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">LHDN Document Details</h4>
                <button type="button" class="close" (click)="StatusModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" role="presentation" *ngIf="!show">
                        <a data-bs-toggle="tab" class="nav-link" id="tabstorecove" href="#storecove" role="tab"
                            aria-controls="storecove" aria-selected="true"
                            (click)="activeTab='Details'"
                            [ngClass]="{'active': activeTab === 'Details'}">Details</a>
                    </li>
                    <li class="nav-item" role="presentation" *ngIf="show">
                        <a data-bs-toggle="tab" class="nav-link" id="tabDetails" href="#Details" role="tab"
                            aria-controls="Details" aria-selected="true"
                            (click)="activeTab='Details'"
                            [ngClass]="{'active': activeTab === 'Details'}">Details</a>
                    </li>
                    <li class="nav-item" role="presentation" *ngIf="show">
                        <a data-bs-toggle="tab" class="nav-link" id="tabValidation" href="#Validation" role="tab"
                            aria-controls="Validation" aria-selected="false" (click)="getValidation();activeTab='Validation'"
                            [ngClass]="{'active': activeTab === 'Validation'}">Validation Results</a>
                    </li>
                    <li class="nav-item" role="presentation"  *ngIf="show &&  (this.LDHNRes?.status=='Valid' || this.LDHNRes?.status=='Cancelled')">
                        <a data-bs-toggle="tab" class="nav-link" id="tabQR" href="#QR" role="tab" aria-controls="QR"
                            aria-selected="false"  (click)="getQR();activeTab='QR'"
                            [ngClass]="{'active': activeTab === 'QR'}">QR Code</a>
                    </li>
                    <li class="nav-item" role="presentation" *ngIf="show">
                        <a data-bs-toggle="tab" class="nav-link" id="tabDocument" href="#Document" role="tab"
                            aria-controls="Document" aria-selected="false"
                            (click)="activeTab='Document'"
                            [ngClass]="{'active': activeTab === 'Document'}">Document</a>
                    </li>
                </ul>
                  
                <div class="tab-content" id="content">
                    <div class="tab-pane fade  text-success" [ngClass]="{'show active': activeTab === 'Details'}" id="storecove" role="tabpanel" aria-labelledby="tabstorecove"  *ngIf="!show">
                        Submitted Successfully!!!
                    </div>
                    <div class="tab-pane fade "  [ngClass]="{'show active': activeTab === 'Details'}" id="Details" role="tabpanel" aria-labelledby="tabDetails" *ngIf="show">
                        <div class="row mt-2">
                            <div class="col-2 text-right text-muted">UUID:</div>
                            <div class="col-10">{{LDHNRes?.uuid}}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-2 text-right text-muted">Submission Id:</div>
                            <div class="col-10">{{LDHNRes?.submissionUid}}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-2 text-right text-muted">Long Id:</div>
                            <div class="col-10">{{LDHNRes?.longID}}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-2 text-right text-muted">Type Name:</div>
                            <div class="col-4">{{LDHNRes?.typeName}}</div>
                            <div class="col-md-2  text-right text-muted">Internal Id:</div>
                            <div class="col-4">{{LDHNRes?.internalId}}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-2 text-right text-muted">Validated On:</div>
                            <div class="col-4">{{LDHNRes?.dateTimeValidated}}</div>
                            <div class="col-2  text-right text-muted">Status:</div>
                            <div class="col-4">
                                <span class="badge badge-pill text-wrap"
                                    [ngClass]="LDHNRes?.status == 'Submitted'? 'badge-info':(LDHNRes?.status == 'Valid'? 'badge-success':(LDHNRes?.status == 'Invalid'? 'badge-warning': (LDHNRes?.status == 'Error' || LDHNRes?.status == 'Cancelled'? 'badge-danger':'')))">
                                    {{LDHNRes?.status}} </span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-2 text-right text-muted">Issued On:</div>
                            <div class="col-4">{{LDHNRes?.dateTimeIssued}}</div>
                            <div class="col-2  text-right text-muted">Received On:</div>
                            <div class="col-4">{{LDHNRes?.dateTimeReceived}}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-2 text-right text-muted">Issuer:</div>
                            <div class="col-10">{{LDHNRes?.issuerTin}} - {{LDHNRes?.issuerName}}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-2 text-right text-muted">Receiver:</div>
                            <div class="col-10">{{LDHNRes?.receiverId}} - {{LDHNRes?.receiverName}}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-2 text-right text-muted">Cancel Time:</div>
                            <div class="col-4">{{LDHNRes?.cancelDateTime}}</div>
                            <div class="col-2  text-right text-muted">Status Reason:</div>
                            <div class="col-4">{{LDHNRes?.documentStatusReason}}</div>
                        </div>
                    </div>
                    <div class="tab-pane fade"   [ngClass]="{'show active': activeTab === 'Validation'}"id="Validation" role="tabpanel" aria-labelledby="tabValidation" *ngIf="show">
                        <div class="row mt-2">
                            <div class="col-12">
                                <div *ngIf="this.Status=='Valid'" class="text-success">
                                    Valid Successfully
                                </div>
                                <div *ngIf="this.Status=='Cancelled'" class="text-red">
                                    The document has been cancelled.
                                </div>
                                <ng-container *ngIf="this.Status=='Invalid'">
                                    <table class="table table-sm  table-hover">
                                        <tr>
                                            <th style="width: 10%;">
                                                Status
                                            </th>
                                            <th style="width: 90%;">
                                                Name
                                            </th>
                                        </tr>
                                        <tr *ngFor="let v of LDHNRes?.validationResults?.validationSteps">
                                            <td style="vertical-align: top;" 
                                            [ngClass]="v.status === 'Invalid' ? 'text-red' : 'text-success'">
                                            {{ v.status }}
                                        </td>
                                            <td style="vertical-align: top;"  [ngClass]="v.status === 'Invalid' ? 'text-red' : 'text-success'">
                                                {{v.name}}
                                                <ng-container *ngIf="v.status == 'Invalid'">
                                                    <table class="table table-sm  table-bordered mb-0">
                                                        <tr>
                                                            <th style="width: 30%;">
                                                                Property Name
                                                            </th>
                                
                                                            <th style="width: 70%;">
                                                                Error
                                                            </th>
                                                        </tr>
                                                        <tr *ngFor="let e of v.error?.innerError"  class="text-red">
                                                            <td class="text-wrap">
                                                                {{e.propertyName}}
                                                            </td>
                                
                                                            <td class="text-wrap">
                                                                {{e.errorCode}} - {{e.error}}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </table>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade "  [ngClass]="{'show active': activeTab === 'QR'}" id="QR" role="tabpanel" aria-labelledby="tabQR" *ngIf="show &&  (this.LDHNRes?.status=='Valid' || this.LDHNRes?.status=='Cancelled')">
                        <div style="margin: 10px 0px 10px 200px;" *ngIf="!loading">
                            <img style='display:block; width:150px;height:150px;' id='base64QRcodeimage' [src]="QRcode">
                        </div>
                        <div *ngIf="loading" style="margin-left: 200px;">
                            Generating QR Code...
                        </div>
                    </div>
                    <div class="tab-pane fade"  [ngClass]="{'show active': activeTab === 'Document'}" id="Document" role="tabpanel" aria-labelledby="tabDocument" *ngIf="show">
                        <div>{{LDHNRes?.document}}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-info" type="button" (click)="StatusModal.hide()">Close</button>
            </div>
        </div>
    </div>
</div><div bsModal #CancelPopup="bs-modal" class="modal fade" tabindex="-1" [config]="{ backdrop: 'static', keyboard: false }"
role="dialog">
<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="modal-header">
            Cancel E-Invoice
        </div>
        <div class="modal-body">
            <label id="reason">Reason<span class="text-danger">*</span></label>
            <textarea type="text" class="form-control" maxlength="100" xssDirective
                [(ngModel)]="cancelReason"></textarea>
            <div *ngIf="!cancelReason && CancelEinvoice" class="text-danger">Please enter cancel reason.</div>
        </div>
        <div class=" modal-footer">
            <button class="btn btn-info" type="button" (click)="this.CancelPopup.hide();">Cancel</button>
            <button class="btn btn-primary" type="button" (click)="cancelDocument()">Ok</button>
        </div>
    </div>
</div>
</div>