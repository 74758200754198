import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { PrintService } from '../../template/services/print.service';
import { environment } from '../../../../environments/environment';
import { HttpServices } from '../../../utilities/_services/http.service';


@Component({
  selector: 'app-ticket-capture',
  templateUrl: './ticket-capture.component.html',
  styleUrls: ['./ticket-capture.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TicketCaptureComponent implements OnInit {
  ticketForm: FormGroup | any;
  ProductID;
  submitted = false;
  ticketTypeLst = [{ id: 1, title: 'Feedback / Report Incident' }, { id: 3, title: 'Technical Support' }]
  IsNew = true;
  ticketAccessLst = [{ id: false, title: 'Visible to myself only' }, { id: true, title: 'Visible to all' }]
  ticketStatus = "";
  localCurrentUser;
  Attachment = [];
  AttachmentID = 0
  orgName = "";
  customerData: any;
  base64Image:any;
  @Input() imgsrc : any;
  img:string
  @ViewChild('showticketCapturemodal') showticketCapturemodal: ModalDirective;
  constructor(private activemodal:NgbActiveModal,private el :ElementRef,private renderer:Renderer2,
    private alertMessage: AlertMessage,private local: LocalCacheServices,
    public printService: PrintService,private http: HttpServices,private formBuilder: FormBuilder,
  ) { this.localCurrentUser = JSON.parse(
    this.local.getlocalStorage(this.local.localCurrentUserKey)
  );
  this.orgName = this.localCurrentUser.organizationName;
}

  ngOnInit(): void {
   
    this.GetCustomerDetailsForInfoAcc();
    this.ticketForm = this.formBuilder.group({
      product: ['', [Validators.required]],
      ticketType: [3, [Validators.required]],
      assignTo: [this.customerData?.ImpUserId],
      ticketAccess: [false, [Validators.required]],
      subject: ['', [Validators.required]],
      remarks: ['', [Validators.required]],
      agreeTerms: [false, [Validators.required]]

    });
    this.ticketForm.patchValue({ product: "Info-Accounting" });
    const contentType = 'image.png'; 
    this.base64Image=this.imgsrc;
    const base64WithoutPrefix = this.base64Image.split(',')[1]; 
   const sizeInBytes = (base64WithoutPrefix.length * (3 / 4)) - (base64WithoutPrefix.endsWith('==') ? 2 : (base64WithoutPrefix.endsWith('=') ? 1 : 0));
   var size = (sizeInBytes / 1024) + ' KB'
    this.Attachment.push({
      Attachment:base64WithoutPrefix,
      AttachmentFileName:contentType,
      AttachmentID:1,
      Size:size
    })
  }
  ngAfterViewInit(){
    const helpMenu = this.el.nativeElement.querySelector('.tui-image-editor-help-menu.top');
    if (helpMenu) {
      this.renderer.setStyle(helpMenu, 'display', 'none');
    }
    const ImageEditor = require('tui-image-editor');
     this.img =this.imgsrc;
     
 const instance = new ImageEditor(document.querySelector('#tui-image-editor'), {
  includeUI: {
    loadImage: {
      path: this.img,
      name: 'SampleImage',
    },
    menu:['crop','draw','shape','text'],
    menuBarPosition: 'bottom',
  },
cssMaxWidth: 700,
cssMaxHeight: 500,
});


 }
 
  get ticket() { return this.ticketForm.controls; }
  closeModal() {
    this.activemodal.close(true);
  }
  GetCustomerDetailsForInfoAcc() {
    let Country:any="SINGAPORE";
    if(this.localCurrentUser.countryID==196)
    {
      Country= 'SINGAPORE';
    }
    else if(this.localCurrentUser.countryID==129)
    {
      Country= 'MALAYSIA';
    }
    else if(this.localCurrentUser.countryID==94)
    {
      Country= 'HONGKONG';
    }

    let obj = {
      "Country": Country,
      "CustomerCode":this.localCurrentUser.customerCode.toString(),
      "CRMCustomerCode":this.localCurrentUser.crmCustomerCode.toString(),
      "userID": this.localCurrentUser.userID
    }

    this.http.postservice(environment.administrationApiUrl + "/TicketDetails/GetCustomerDetailsForInfoAcc", obj).
      subscribe((res: any) => {
        console.log(res)
        this.customerData = JSON.parse(res.responseData);

        if (this.customerData.length > 0) {
          this.customerData = this.customerData[0];
          this.customerData.CustomerCode = this.localCurrentUser.customerCode.toString()
          this.customerData.Country = (this.localCurrentUser.countryID == 196 ? 'SINGAPORE' : this.localCurrentUser.countryID == 129 ? 'MALAYSIA' :this.localCurrentUser.countryID == 94 ? 'HongKong' : this.localCurrentUser.countryID == 153 ? 'New Zealand':'SINGAPORE');
        }
      });

  }
  submitTicket(){
    this.submitted = true;
    if (this.ticketForm.invalid) {
      this.alertMessage.errorNotifier('Please fill mandatory fields.', 1);
      return;
    }else if(!this.ticketForm.value.agreeTerms){
      this.alertMessage.errorNotifier('Please fill agreement mandatory fields.', 1);
      return;
    }

    let obj = {
      CustomerCode: (this.localCurrentUser.crmCustomerCode.toString() == "0" ? this.customerData.CustomerCode : this.localCurrentUser.crmCustomerCode.toString()),
      UserId: this.localCurrentUser.userID,
      Country: (this.localCurrentUser.countryID == 196 ? 'SINGAPORE' : this.localCurrentUser.countryID == 129 ? 'MALAYSIA' :this.localCurrentUser.countryID == 94 ? 'HongKong' : this.localCurrentUser.countryID == 153 ? 'New Zealand':'SINGAPORE'),
      TktType: this.ticketForm.controls.ticketType.value.toString(),
      VisibleToAll: this.ticketForm.controls.ticketAccess.value,
      Subject: this.ticketForm.controls.subject.value,
      Message: this.ticketForm.controls.remarks.value,
      Attachment: this.Attachment,
      ReportedBy: this.localCurrentUser.firstName + ' ' + this.localCurrentUser.lastName,
      Product: 'Accounting',
      AssignTo:this.ticketForm.controls.assignTo.value?.toString()
    };

    obj.Message = this.encodeHTMLContent(obj.Message);

    this.http.postservice(environment.administrationApiUrl + "/TicketDetails/CreateTicket", obj).
      subscribe((res: any) => {
        console.log(res)
        if (res.isSuccess) {
          let responseData = JSON.parse(res.responseData);
          if (responseData.isSuccess) {

            let obj = {
              message: responseData.strMessage,
              url: '/help/submitticketlst'
            }

            this.alertMessage.alertWithSuccessRedirect(obj);
           
          } else {
            this.alertMessage.errorNotifier(responseData.strMessage, 0)
          }
        }
      });

  }
  encodeHTMLContent(data: any) {
    if (typeof (data) != "undefined" && data != null) {
      return data = this.printService.returnEncodedString(data);
    }
  }
}
