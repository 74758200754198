import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutomatebankfeedsService {
  apiUrl: string = "";
  bankFeedEnvironment: string = "";

  constructor(private _http: HttpClient) {
    this.apiUrl = environment.reconcilationApiUrl;
    this.bankFeedEnvironment = environment.yodleeEnvironment;
  }

  async GetAutoFeedLoginUrlNew(BankId: any) {
    let params = new HttpParams();
    params = params.set('environment', this.bankFeedEnvironment);
    params = params.set('bankId', BankId);
    let data = await this._http.post(this.apiUrl + '/reconcile/AutomateFeed/GetAutoFeedLoginUrl', params).toPromise();
    return data;
  }

  async SaveAuthCodeDetails<T>(body: any) {
    var Url = this.apiUrl + "/reconcile/AutomateFeed/SaveAuthCodeDetails";
    //var Url = this.apiUrl + "/reconcile/TestBatch/GetBatchHistory";
    let result = await this._http.post<T>(Url, body);
    return result;
  }

  async RemoveAutoFeedAccount(Param: any) {
    let Params = new HttpParams();
    Params = Params.set('accountId', Param.accountId);
    Params = Params.set('environment', Param.environment);
    let data = await this._http.post(this.apiUrl + '/reconcile/AutomateFeed/DeLinkCustomer', Params).toPromise();
    return data;
  }

  async DeactivateAutoFeedAccount(Param: any) {
    let Params = new HttpParams();
    Params = Params.set('accountId', Param.accountId);
    let data = await this._http.post(this.apiUrl + '/reconcile/AutomateFeed/DeactivateAccount', Params).toPromise();
    return data;
  }

  async ReactivateAutoFeedAccount(Param: any) {
    let Params = new HttpParams();
    Params = Params.set('accountId', Param.accountId);
    let data = await this._http.post(this.apiUrl + '/reconcile/AutomateFeed/ReactivateAccount', Params).toPromise();
    return data;
  }

  async GetBankIdByAccountId(Param: any) {
    let Params = new HttpParams();
    Params = Params.set('accountId', Param.accountId);
    let data = await this._http.post(this.apiUrl + '/reconcile/AutomateFeed/GetBankIdByAccountId', Params).toPromise();
    return data;
  }

  async getDBSAutoFeedLoginURL(BankId: any) {
    let params = new HttpParams();
    params = params.set('environment', this.bankFeedEnvironment);
    params = params.set('bankId', BankId);
    let data = await this._http.post(this.apiUrl + '/reconcile/AutomateFeed/GetDBSAutoFeedLoginURL', params).toPromise();
    return data;
  }

  async invokeDBSAccessTokenAPI(authCode: string, bankID: number) {
    let data = await this._http.get(this.apiUrl + '/reconcile/AutomateFeed/InvokeDBSAccessTokenAPI?authCode=' + authCode + '&bankID=' + bankID + '&environment=' + this.bankFeedEnvironment).toPromise();
    return data;
  }

}
