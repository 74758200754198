import { Component, OnInit, SimpleChange, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { environment } from '../../../../environments/environment';
import { HttpServices } from '../../../utilities/_services/http.service';
import { PageServices } from '../../../utilities/_services/page.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-save-publish',
  templateUrl: './save-publish.component.html',
  styleUrls: ['./save-publish.component.scss']
})
export class SavePublishComponent implements OnInit {

  reportTitle: string;
  date: string;
  orgSelected: any;
  isSubmitted = false;
  curentUser: any;
  preparedBy: string;
  selectedorg: any = [];
  userList = [];
  saveAsTypeDetail:any;
  actionType:any;
  ReportID : number;
  PageID : number;
  constructor(private local: LocalCacheServices,private alertMessage: AlertMessage,private http:HttpServices,
    private page : PageServices,public dialogRef: MatDialogRef<SavePublishComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.reportTitle = data.reportTitle;
    this.date = data.date;
    this.orgSelected = data.orgSelected;
    this.ReportID=data.ReportID== undefined ? 0 : data.ReportID;  
    this.PageID=data.PageID == undefined ? 0 : data.PageID;
    this.curentUser = JSON.parse(
      this.local.getlocalStorage(this.local.localCurrentUserKey)
    )
    let orgId = this.curentUser["orgID"];
    let custCode = this.curentUser["organizations"].filter((currentorg) => currentorg.orgID === orgId)[0]["customerCode"];
    let curCode = this.curentUser["organizations"].filter((currentorg) => currentorg.orgID === orgId)[0]["orgCurrencyCode"];
    let matchingorg: any[] = this.curentUser["organizations"].filter(
      (currentorg) =>
        currentorg.customerCode === custCode &&
        currentorg.orgCurrencyCode === curCode
    );
    matchingorg.forEach((ele) => {
      this.selectedorg.push({
        orgid: "" + ele.orgID + "",
        title: ele.organizationName,
        selected: orgId == ele.orgID ? true : false,
      });
    });

    if(this.orgSelected){
    if (this.orgSelected.length == 0) {
      this.orgSelected = this.selectedorg.filter(a => a.selected == true).length > 0 ? [this.selectedorg.filter(a => a.selected == true)[0]] : this.orgSelected;
    }
    else{
      this.orgSelected = this.selectedorg.filter(a => a.selected == true).length > 0 ? [this.selectedorg.filter(a => a.selected == true)[0]] : this.orgSelected;
    }
  }else{
    this.orgSelected = this.selectedorg.filter(a => a.selected == true).length > 0 ? [this.selectedorg.filter(a => a.selected == true)[0]] : this.orgSelected;
  }
    this.saveAsTypeDetail = data.saveAsTypeDetail;
    this.actionType = data.actionType;
    if (data.saveAsTypeDetail == "Draft" && data.actionType == "Published") {
      this.saveAsTypeDetail = "Published";
    }
    this.loadUser();
  }

  loadUser() {
    this.preparedBy = this.curentUser.firstName + ' ' + this.curentUser.lastName;
  }

  ngOnInit(): void {


  }

  saveAsPublish(event) { }

  ngOnChanges(change: SimpleChange) {
  }

  closeModal() {
    this.dialogRef.close();
  }

  publish(registerForm: NgForm) {
    if (registerForm.invalid) {
      this.isSubmitted = true;
      return;
    }
    if(this.PageID==0){
      this.PageID=this.page.pageDetails.pageID;
    }
   
    let params = new HttpParams();
    params = params.set('PageID', this.PageID.toString());
    params = params.set('ReportID', this.ReportID.toString());
    params = params.set('ReportTitle', this.reportTitle);
    this.http.postservice(environment.reportsApiUrl + "/SaveUpdateReports/CheckReportTitleExistsorNot/"
      ,params).subscribe({
                next: (data: any) => {
                  if(data == true){
                      this.alertMessage.errorNotifier("Report Title Already Exists!", 0);  
                      return;  
                  }
                  else{
                      let Org = '';

                    let obj = {
                      reportTitle: this.reportTitle, date: this.date, orgSelected: this.orgSelected,
                      orgName: this.orgSelected.map(res => res.title).join(','), preparedBy: this.preparedBy
                    };
                    if(this.reportTitle.includes('<') || this.reportTitle.includes('>')){
                      this.alertMessage.errorNotifier("Invalid data, Your input value should not contain < or > symbols", 1);
                      return;
                    }
                    this.dialogRef.close(obj);
                   }
                },
            });
    
  }
}
