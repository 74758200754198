<div class="d-inline-block custom-file">
  <button type="button" class="btn btn-outline-info ml-2" [autoClose]="'outside'" placement="left"
    [ngbPopover]="popContent" #popOver="ngbPopover">
    <span class="c-icon material-icons-outlined m-0">content_copy</span>
    <span class="mx-1" *ngIf="fileAttachments.length > 0">{{fileAttachments.length}}</span>
    <!-- <span class="mx-1" *ngIf="fileAttachments.length === 0">{{ buttonText }}</span> -->
  </button>
  <ng-template #popContent>
    <div class="popover-title popover-header">{{'SharedModule.File upload/Related Files' | translate}}
      <span class="material-icons-outlined font-lg float-right cursor-pointer" (click)="CloseDialog()">close</span>
    </div>
    <div class="dropzone" fileDragDrop (fileDropped)="onFileChange($event)">
      <input *userCanEdit="[true]" type="file" #fileUploader id="file-drag-drop-upload" class="custom-file-inputs" multiple 
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.txt,.xls"
         />
      <h3>{{'SharedModule.Drop file(s) here' | translate}}</h3>
    </div>
    <div class="p-1 max-scroll" style="overflow: auto; height:235px;">
      <span *ngIf="postFileAttachment.length > 0">
        <mat-progress-bar mode="indeterminate" *ngIf="progressBar"></mat-progress-bar>
      </span>
      <div class="text-center mt-5" *ngIf="fileAttachments.length === 0">
        <span class="material-icons-outlined font-6xl text-light">description</span>
      </div>
      <div class="popover-table-3">
        <table class="table table-responsive-sm">
          <tbody>
            <tr *ngFor="let file of fileAttachments let i = index;">
              <td width="10"><span *ngIf="fileExtension(file.fileName) === 'pdf'" class="material-icons text-danger">
                  picture_as_pdf
                </span>
                <span
                  *ngIf="fileExtension(file.fileName) === 'jpg' || fileExtension(file.fileName) === 'gif' || fileExtension(file.fileName) === 'png'"
                  class="material-icons text-warning">
                  image
                </span>
                <span
                  *ngIf="fileExtension(file.fileName) === 'xls' || fileExtension(file.fileName) === 'xlsx'"
                  class="material-icons text-success">
                  text_snippet
                </span>
                <span
                  *ngIf="fileExtension(file.fileName) === 'doc' || fileExtension(file.fileName) === 'docx'"
                  class="material-icons text-primary">
                  text_snippet
                </span>
                <span
                  *ngIf="fileExtension(file.fileName) === 'txt'"
                  class="material-icons text-light">
                  text_snippet
                </span>
                <!-- <span
                  *ngIf="fileExtension(file.fileName) !== 'pdf' && !(fileExtension(file.fileName) == 'jpg' || fileExtension(file.fileName) == 'gif' || fileExtension(file.fileName) == 'png')"
                  class="material-icons text-success">
                  text_snippet
                </span> -->
              </td>
              <td>
                <a class="text-primary cursor-pointer"
                  (click)="downloadAttachmentFile(file.fileName, file.filePath,file.filbytes)"> {{file.fileName}}
                  <small>{{getFileSize(file.contentLength,'F')}}</small><br>
                  <div class="min-text">{{'SharedModule.Last updated on' | translate}}<span *ngIf="!!file.createdDate">{{file.createdDate | date
                      :'dd MMM, yyyy HH:mm':'UTC'}}</span>
                    <span *ngIf="file.createdDate == undefined">{{'SharedModule.few minutes ago' | translate}}</span>
                  </div>
                </a>
                <div class="m-1" *ngIf="isDeleteButton[i]">
                  <button *userCanEdit="[true]" class="btn btn-sm btn-danger" type="button"
                    (click)="deleteFile(i,file.fileID, file.fileName, file.filePath)"> {{'Common.Delete' | translate}}
                  </button>
                  <span *ngIf="transID !== 0"><span *licenseType = "true">
                    <button *userCanEdit="[true]" class="btn btn-sm btn-light" (click)="moveFile(file,'Inbox',i)"
                      type="button"> {{'SharedModule.Remove & Save to file Document' | translate}}
                    </button>
                  </span></span>
                  <span *ngIf="transID === 0"><span *licenseType = "true">
                    <button *userCanEdit="[true]" class="btn btn-sm btn-light" type="button">
                      {{'SharedModule.Remove & Save to file Document' | translate}}
                    </button>
                  </span></span>
                </div>
              </td>
              <td *userCanEdit="[true]" class="text-right">
                <span class="material-icons-outlined cursor-pointer text-success" title="{{'SharedModule.Download' | translate}}"
                  (click)="downloadAttachmentFile(file.fileName, file.filePath,file.filbytes)">file_download</span>
                <span *ngIf="file.fileID !== 0" class="material-icons-outlined  cursor-pointer text-danger"
                  title="{{'Common.Delete' | translate}}" (click)="isDeleteButton[i]=!isDeleteButton[i]">delete</span>
                <span *ngIf="file.fileID === 0" class="material-icons-outlined  cursor-pointer text-danger"
                  title="{{'Common.Delete' | translate}}" (click)="deleteFile(i,file.fileID, file.fileName)">delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="dropdown-divider"></div>    
    <div class="text-center"> <span *licenseType = "true">
      <button *userCanEdit="[true]" class="btn btn-info mr-3" (click)="openLibararyDocument()"><i
          class="fa fa-plus mr-1"></i> {{'SharedModule.Add from the Document' | translate}}
      </button></span>
      <label *userCanEdit="[true]" for="file-upload" class="custom-file-upload btn btn-info">
        <i class="fa fa-cloud-upload mr-1"></i> {{'SharedModule.Upload files' | translate}}
      </label>
      <input *userCanEdit="[true]" type="file" #fileUploader id="file-upload" class="custom-file-inputs" multiple
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.txt,.xls"
        (change)="onFileChange($event)">
    </div>
  </ng-template>
</div>








<div bsModal #showLibrarymmodal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="showLibrarymmodalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'Documents' | translate}}</h4>
        <button type="button" class="close" (click)="closeLibararyDocument()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="animated fadeIn">
          <div class="row">
            <div class="col-md-12 mb-4">
              <div class="card">
                <div class="card-body mnh-300">
                  <div class="c-nav-tabs mt-3 pb-10">
                    <ul class="nav custom-nav-2" role="tablist">
                      <li class="nav-item"
                        *ngIf="groupNamesInbox.groupName === 'Inbox' && groupNamesInbox.isSystem === true">
                        <a (click)="groupNameOption(groupNamesInbox)" class="nav-link"
                          [ngClass]="{'active' : groupNamesInbox.groupName == 'Inbox' }" data-bs-toggle="tab"
                          href="#tab_all" role="tab">{{groupNamesInbox.groupName}}
                          ({{groupNamesInbox.groupFileCount}})</a>
                      </li>
                      <li class="nav-item" *ngFor="let grp of groupNames">
                        <a class="nav-link" (click)="groupNameOption(grp)"
                          [ngClass]="{'active' : grp.groupName == 'Inbox' }" data-bs-toggle="tab" href="#tab_all"
                          role="tab">{{grp.groupName}}
                          ({{grp.groupFileCount}})</a>
                      </li>
                    </ul>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-7">

                      <!-- <button class="btn btn-info" type="button" *userCanEdit="[true]" (click)="fileMoveOption()">
                        <span class="c-icon material-icons-outlined text-warning m-0">add</span>
                        &nbsp; Move File
                      </button> -->

                    </div>
                    <div class="col-md-2 text-right">
                      {{totalGridRows}} {{'Common.Items' | translate}}<br>
                      <span class="t-link edit">{{numSelectedItems}} {{'Common.Item selected' | translate}}</span>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group search">
                        <span class="material-icons-outlined icon-search">search</span>
                        <input type="text" id="search-box" class="form-control" placeholder="{{'Common.Search' | translate}}"
                          [(ngModel)]="searchText" (input)="onQuickFilterChanged()" />
                      </div>
                    </div>
                  </div>
                  <div class="custom-ag-grid-4">
                    <div class="tab-content">
                      <div class="tab-pane active" id="tab_all" role="tabpanel">
                        <ag-grid-angular style="width: 100%;" class="ag-theme-material" [modules]="modules"
                          [columnDefs]="columnDefs" [rowSelection]="rowSelection"
                          [rowGroupPanelShow]="rowGroupPanelShow" [pagination]="true"
                          [paginationPageSize]="paginationPageSize" [rowData]="rowGroupData"
                          [suppressRowClickSelection]="true" (gridSizeChanged)="onGridSizeChanged($event)"
                          [gridOptions]="allGridOptions" [overlayLoadingTemplate]="overlayLoadingTemplate"
                          [overlayNoRowsTemplate]="overlayNoRowsTemplate" (rowSelected)="onRowSelected($event)"
                          [domLayout]="domLayout">
                        </ag-grid-angular>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-success btn-normal mr-3" (click)="addfiles()">
          {{'SharedModule.Add file' | translate}} <span *ngIf="SelectedData.length > 0">({{SelectedData.length}}) </span>
        </button>
        <button class="btn btn-danger" type="button" (click)="closeLibararyDocument()">{{'Common.Cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div bsModal #showDocumentGroupModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="showDocumentGroupModalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'SharedModule.Move File' | translate}}</h4>
        <button type="button" class="close" (click)="cancelFileMove()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row pb-2">
          <div class="col-md-12">
            <form class="form-horizontal needs-validation" novalidate="novalidate">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="groupName">{{'ShareModule.Group Name' | translate}}</label>
                <div class="col-md-8">
                  <ng-select [multiple]="false" [items]="moveGroupNames" bindValue="fileGroupID" bindLabel="groupName"
                    [clearable]="false">
                  </ng-select>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button [disabled]="true" class="btn btn-success btn-normal mr-3">
          {{'Common.Save' | translate}}
        </button>
        <!-- <button [disabled]="loading" class="btn btn-success btn-normal mr-3" *ngIf="isMadeChanges"
                    type="button" (click)="saveContactGroup('M')">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button> -->
        <button class="btn btn-light" (click)="cancelFileMove()" type="button">{{'Common.Cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>