<div class="well"></div>
<div class="wellWhite">

  <ng-container *ngIf="(Qstatus==3 || Qstatus==4) && statusMessage != 'Quotation link has been expired!'">
    <div class="container">
      <div class="px-3 py-2 d-flex">
        <div>
          <a (click)=acceptquotes()>
            <button class="btn btn-success" type="button">
              Accept
            </button>
          </a>
          <a (click)=declinequotes()>
            <button class="btn btn-secondary" type="button">
              Decline
            </button>
          </a>
        </div>

        <div class="p-2">{{ CurrencyCode }}</div>
        <div class="py-1 h4"> <b>{{ qoTotal }} </b></div>
        <div class="ml-auto">


          <button class="btn btn-primary" type="button" (click)="print()">
            Print
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="Qstatus == 6">
    <div class="container">
      <div class="row">
        <div class="col-md-3 text-left">
          <h2 style="color: green; font-weight: bold;">Quote Accepted</h2>
        </div>
        <div class="col-md-6 text-left">
          <h3>{{ CurrencyCode }} {{ qoTotal }}</h3>
        </div>
        <div class="col-md-3 text-left">
          <button class="btn btn-primary" type="button" (click)="print()">
            Print
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="Qstatus == 8 || Qstatus == 7">
    <div class="container">
      <div class="row">
        <div class="col-md-3 " *ngIf="Qstatus == 7">
          <h2 style="color: Red;">Quote Deleted</h2>
        </div>
        <div class="col-md-3 " *ngIf="Qstatus == 8">
          <h2 style="color: green;">Quote Invoiced</h2>
        </div>
        <div class="col-md-6 text-left">
          <h3>{{ CurrencyCode }} {{ qoTotal }}</h3>
        </div>
        <div class="col-md-3 text-left">
          <button class="btn btn-primary" type="button" (click)="print()">
            Print
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="Qstatus == 5">
    <div class="container">
      <div class="row">
        <div class="col-md-3 text-left">
          <h2 style="color: Red;">Quote Declined</h2>
        </div>
        <div class="col-md-6 text-left">
          <h3>{{ CurrencyCode }} {{ qoTotal }}</h3>
        </div>
        <div class="col-md-3 text-left">

          <button class="btn btn-primary" type="button" (click)="print()">
            Print
          </button>
        </div>
      </div>
    </div>


  </ng-container>

  <ng-container *ngIf="statusMessage == 'Quotation link has been expired!'">
    <div class="container">
      <div class="row">
        <div class="col-md-3 mt-2">
          <h2 style="color: Red;">Online Quote Expired</h2>
        </div>
        <!-- <div class="col-md-6 text-left mt-2">
          <h3>{{ CurrencyCode }} {{ qoTotal?qoTotal : 0 | number : '1.2-2' }}</h3>
        </div>
        <div class="col-md-3 text-left">
          <button class="btn btn-primary" type="button" (click)="print()">
            Print
          </button>
          </div> -->
      </div>
    </div>
  </ng-container>
</div>
<div class="container" *ngIf="statusMessage != 'Quotation link has been expired!'">
  <div class="card mt-3 p-3">
    <div class="row">
      <div class="col-md-6">
        <h1>Quote</h1>
        <div class="">
          <p>To:{{ContactName}}</p>
        </div>
        <div class="">
          <p>Quote No:{{Qno}}</p>
        </div>
        <div class="">
          <p>Quote Date:{{QDate}}</p>
        </div>
        <div class="">
          <p>Quote Expiry Date:{{QExpDate}}</p>
        </div>
      </div>
      <div class="col-md-6 text-right">
        <img *ngIf="!isDigiSME && this.Orgimg != ''" src={{this.Orgimg}} height="50" alt="Info Tech" />
        <img *ngIf="isDigiSME && this.Orgimg != ''" src={{this.Orgimg}} height="50" alt="DigiSME" />
        <p>From:</p>
        <p [innerHTML]="getAddressHTML()"></p>
      </div>
    </div>

    <div class="col-md-12">
      <div class="custom-ag-grid-5">
        <!-- <ag-grid-angular style="width: 100%; height: 225px;" class="ag-theme-alpine" [rowData]="rowData"
          [gridOptions]="gridOptions" [columnDefs]="columnDefs" [modules]="modules">
        </ag-grid-angular> -->
        <app-simple-ctable style="width: 100%; height: 225px;" [rowData]="rowData" [columnDefs]="columnDefs">
        </app-simple-ctable>
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-md-3 pr-0"> </div>
      <div class="col-md-6">
        <!-- <div class="text-right">
          Sub Total (Include a discount of) {{ CurrencyCode }} {{ subTotal?subTotal : 0 | number : '1.2-2'}}
        </div> -->
        <div class="text-right">
          {{subTotalLabel}} {{ CurrencyCode }} {{ subTotal?subTotal : 0 | number : '1.2-2'}}
        </div>
        <ng-container>
          <div *ngFor="let amountsare of taxAmountsAreList" class="d-flex justify-content-between align-items-start ">
            <div>
              <div class="pt-1 pb-1 text-right">{{ amountsare.LineTotalTaxText }} </div>
            </div>
            <div>
              <div class="pt-1 pb-1 text-right">
                {{ numberFormatToFixed(amountsare.LineTotalTaxValue?amountsare.LineTotalTaxValue:0) }}
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container>
          <div *ngIf="TaxAdjustment!==0" class="d-flex justify-content-between align-items-start">
            <div>
              <div class="pt-1 pb-1"> includes Adjustment to Tax</div>
            </div>
            <div>
              <div class="pt-1 pb-1">
                {{ numberFormatToFixed(TaxAdjustment) }}
              </div>
            </div>
          </div>
        </ng-container>
        <hr class="mt-1 mb-1">
        <div class="text-right">
          Total Amount: {{ CurrencyCode }} {{ qoTotal?qoTotal : 0 | number : '1.2-2' }}
        </div>
        <hr class="mt-1 mb-1">
        <div class="text-left" *ngIf="showCurrencyInfo">
          * Total Tax equivalent to {{orgCurrencyCode}} {{showPerUnit}} <br> {{showToCurrencyCode}}
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-md-12 mx-auto">
      <div class="p-4">
        <div class="card-body text-start">
          <div class="panel panel-default">

          </div>
          <div class="text-right">
            <div *ngFor="let amountsare of taxAmountsAreList" class="d-flex justify-content-between align-items-start">
              <div>
                <div class="pt-1 pb-1">{{ amountsare.taxType }} {{ amountsare.taxRate }}%</div>
              </div>
              <div>
                <div class="pt-1 pb-1">{{ CurrencyCode }} {{
                  numberFormatToFixed(amountsare.taxcalAmt?amountsare.taxcalAmt:0)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
<div bsModal #showPrint="bs-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="showPrintLabel"
  aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog mt-0" style="max-width: 100%;" role="document">
    <div class="modal-content">    
      <div class="modal-body" style="padding: 0 0 0 0;">
        <div style='position: relative; height: 93vh;'>
          <div [innerHTML]="pdfhtml" *ngIf="pdfViewerOnDemand.pdfSrc==null"></div>
          <ng2-pdfjs-viewer #pdfViewerOnDemand [externalWindow]="false" [downloadFileName]="'Quotation.pdf'"
            [print]="false" [openFile]="false" [viewBookmark]="false" [download]="true" [fullScreen]="true"
            [showSpinner]="true"></ng2-pdfjs-viewer>
        </div>
      </div>
      <div class="modal-footer" style="padding: 0 0 0 0;">
        <button class="btn btn-primary btn-normal" type="button"
          (click)="printService.printPdf(pdfViewerOnDemand);">Print</button>
        <button class="btn btn-light btn-normal" type="button"
          (click)="showPrint.hide();pdfViewerOnDemand.pdfSrc=null;">Close</button>
      </div>
    </div>
  </div>
</div>
