<div bsModal #modalAllocateBatchQuantity="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="modalDisposeLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-xl modal-dialog-scrollable1" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font-weight-bold">{{'InventoryModule.Item.Tracking Method' | translate}} - {{trackingMethod}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeAllocateBatchPopup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal needs-validation">
          <div class="">
            <!-- <div class="row-eq-height"> -->
            <div class="row-eq-height">
              <div class="col track-box-success">
                <div class="bd-highlight text-center  p-1">
                  <div class="header-4 text-grey">{{batchItemDetails.itemName}}</div>
                  <div class="header-h-18 ">{{batchItemDetails.itemCode}}</div>
                </div>
              </div>
              <div class="col track-box-warning">
                <div class="bd-highlight text-center p-1">
                  <div class="header-4 text-grey">{{_quantityLabel}} {{'InventoryModule.Item.Quantity' | translate}}</div>
                  <div class="header-h-18">{{_requiredQuantity | number : '1.2-10'}}</div>
                </div>
              </div>
              <!-- <div *ngIf="_transactionTypeId==20" class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <div class="header-4 text-grey">Required Quantity</div>
                  <div class="header-h-18">{{_requiredQuantity}}</div>
                </div>
              </div> -->
              <!-- <div class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <div class="header-4 text-grey">Total Quantity</div>
                  <div class="header-h-18">{{totalQuantity}}</div>
                </div>
              </div> -->
              <div class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <div class="header-4 text-grey">{{'InventoryModule.Item.Quantity with Batch' | translate}}</div>
                  <div class="header-h-18">{{assignedQty}}</div>
                </div>
              </div>

              <div class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <div class="header-4 text-grey">{{'InventoryModule.Item.Untracked Quantity' | translate}}
                  </div>
                  <div class="header-h-18">{{_requiredQuantity - assignedQty | number : '1.2-10'}}</div>
                  <!-- <div class="header-h-18">{{unAssignedQty}}</div> -->
                </div>
              </div>

            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-5" *ngIf="isEnabledActionButtons">
              <!-- <div class="row">
                <div class="col-md-4"> <button class="btn btn-success" type="button" (click)="addRows(1)">Add
                    Batch</button>
                </div>
              </div> -->
            </div>
            <div class="col-md-12 pull-right">
              <div class="d-flex justify-content-end">
                <div class="col-md-2 pr-0">
                  <div class="form-group search">
                    <span class="material-icons-outlined icon-search">search</span>
                    <input type="text" id="search-box" class="form-control" [(ngModel)]="searchText"
                      [ngModelOptions]="{standalone: true}" (input)="onQuickFilterChanged()" placeholder="Search"
                      xssDirective autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <div class="custom-ag-grid-4 mt-2 mb-0 ag-grid-modal">
              <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-material" [modules]="modules"
                [singleClickEdit]="true" [rowData]="batchMasterData" [gridOptions]="batchItemsGridOptions"
                [columnDefs]="itemscolumnDefs" rowSelection="multiple" [frameworkComponents]="frameworkComponents"
                [stopEditingWhenGridLosesFocus]="true" (cellClicked)="onCellClicked($event)" (sortChanged)="onSortChanged()"
                (rowSelected)="onRowSelected($event)" [pagination]="true" (cellValueChanged)="onCellValueChanged($event)">
              </ag-grid-angular>
              <!-- <div class="d-flex ag-pagination">
                <label class="col-form-label" for="PageSize">Page Size: </label>
                <div style=" width: 70px;">
                  <ng-select [(ngModel)]="paginationPageSize" [ngModelOptions]="{standalone: true}"
                    (change)="onPageSizeChanged()" [items]="lstPagination" id="PageSize" bindValue="value"
                    bindLabel="text" [clearable]="false" [clearOnBackspace]="false">
                  </ng-select>
                </div>
              </div> -->
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer d-block" *ngIf="isEnabledActionButtons">
        <div class="row mb-3">
          <div class="col-md-5">
            <div class="ml-1">
              <button class="btn btn-primary" id="btnNewBatch" type="button" (click)="addRows(1)">
                <span class="c-icon material-icons-outlined text-warning m-0">add</span>
                &nbsp;{{'Common.Add New Line' | translate}}
              </button>
            </div>
          </div>
          <div class="col-md-7 sec-btn d-flex justify-content-end">
            <button *userCanEdit="[true]"   class="btn btn-success btn-normal" (click)="onSubmit()">{{'Common.Save' | translate}}</button>
            <button class="btn btn-danger btn-normal" type="button" (click)="closeModal()">
              {{'Common.Cancel' | translate}}
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <div class="mb-3">
          <div class="col sec-btn" *ngIf="isEnabledActionButtons">
            <button class="btn btn-success btn-normal" (click)="onSubmit()">Save</button>
            <button class="btn btn-danger btn-normal" type="button" (click)="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
