import { Injectable, Inject } from '@angular/core';
import { HttpServices } from './http.service'
import { LocalCacheServices } from './acclocalcache.service'
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import Utils from '../../utilities/_common/helpers'

@Injectable({
  providedIn: 'root'
})
export class MasterServices {
  apiUrl: string = "";

  paginationPageSize: any = 10;
  constructor(private http: HttpServices, private local: LocalCacheServices, private _http: HttpClient, private utils: Utils) {
    this.apiUrl = environment.organizationApiUrl;
  }

  async getOrganisationType() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetOrganisationType/');
    return data;
  }

  async getLineOfBusinessType() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetLineOfBusinessType/');
    return data;
  }

  async getPaymentMode() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetPaymentMode/');
    return data;
  }

  async getCountries() {
    if (localStorage.getItem(this.local.localCountriesKey) == null) {
      let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetCountries/');
      this.local.setlocalStorage(this.local.localCountriesKey, data);
      return data;
    }
    else {
      let data = JSON.parse(this.local.getlocalStorage(this.local.localCountriesKey));
      return data;
    }
  }

  async getAccessRights() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetAccessRights/');
    return data;
  }

  async getTimeZone() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetTimeZone/');
    return data;
  }

  async getTaxBasis() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetTaxBasis/');
    return data;
  }

  async getTaxPeriod() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetTaxPeriod/');
    return data;
  }

  async GetPlaceholder(typeID) {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetPlaceholder?typeID=' + typeID);
    return data;
  }

  async getTaxType() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetTaxType/');
    return data;
  }

  async getTaxCompoundRate() {
    return await this._http.get(this.apiUrl + '/Master/GetTaxCompoundRate').toPromise();
    //return this.http.getserviceSync(this.apiUrl + '/Master/GetTaxCompoundRate/').toPromise();
  }

  async getOrgSettings() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetOrgSettings/');
    return data;
  }


  async getAllCurrencyTypes() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetAllCurrencies/');
    return data;
  }

  async getInUseCurrencyTypes(addNewCurrency?: string) {
    if (addNewCurrency === 'New') {
      let data = await this._http.get(this.apiUrl + '/Master/GetInUseCurrencies').toPromise();
      this.local.setlocalStorage(this.local.localInUseCurrencyTypes, data);
      return data;
    } else {
      if (localStorage.getItem(this.local.localInUseCurrencyTypes) == null) {
        let data = await this._http.get(this.apiUrl + '/Master/GetInUseCurrencies').toPromise();
        this.local.setlocalStorage(this.local.localInUseCurrencyTypes, data);
        return data;
      }
      else {
        let data = JSON.parse(this.local.getlocalStorage(this.local.localInUseCurrencyTypes));
        return data;
      }
    }
  }

  async getBankAccountTypes() {
    let data = await this._http.get(this.apiUrl + '/Master/GetBankAccountTypes').toPromise();
    return data;
  }

  async getFxRate(FromCurrencyCode: string, ToCurrencyCode: string, FxDate: Date) {
    let params = new HttpParams();
    params = params.set('FromCurrencyCode', FromCurrencyCode);
    params = params.set('ToCurrencyCode', ToCurrencyCode);
    params = params.set('FxDate', FxDate.toDateString());
    let data = await this._http.post(this.apiUrl + '/Master/GetFxRate', params).toPromise();
    return data;

    //console.log(await this.master.getFxRate("USD","JPY",new Date(2021,5,17)));
  }

  async GetTaxRateMaster() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetTaxRateMaster/');
    return data;
  }

  async GetChartOfAccounts() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetChartOfAccounts/');
    return data;
  }

  async GetPurchaseChartOfAccounts() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetPurchaseChartOfAccounts/');
    return data;
  }

  async GetSalesChartOfAccounts() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetSalesChartOfAccounts/');
    return data;
  }

  async GetTransChartOfAccounts(TransTypeID: number) {
    let params = new HttpParams();
    params = params.set('TransTypeID', TransTypeID.toString());
    let data = await this._http.post(this.apiUrl + '/Master/GetTransChartOfAccounts', params).toPromise();
    return data;
  }

  async getF5Returns() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetF5Returns/');
    return data;
  }
  async getNZReturns() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetNZReturns/');
    return data;
  }
  async getLanguageList() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetLanguageList');
    return data;
  }

  async getLanguageJson(lang: any) {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetLanguageJson/' + lang);
    return data;
  }

  async getDueType() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetDueType/');
    return data;
  }
  async GetInvoiceDueType() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetInvoiceDueType/');
    return data;
  }

  async GetQuoteDueType() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetQuoteDueType/');
    return data;
  }

  async GetAccountsType() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetAccountsType/');
    return data;
  }

  async GetAccountGroup() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetAccountGroup/');
    return data;
  }

  async GetTemplateThemeList(themeType: any) {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetTemplateThemeList/' + themeType);
    return data;
  }
  async GetTemplateColor() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetTemplateColor/');
    return data;
  }

  async SaveTransactionNotes(TransID: number, TransTypeID: number, Notes: string) {
    let params = new HttpParams();
    params = params.set('TransID', TransID.toString());
    params = params.set('TransTypeID', TransTypeID.toString());
    params = params.set('Notes', Notes);
    let response = await this._http.post(environment.organizationApiUrl + '/Notes/SaveTransactionNotes', params).toPromise();
    return response;
  }

  async GetTransactionType() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetTransactionType/');
    return data;
  }
  async GetMasterTransactionType() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetTransactionType?type=Master');
    return data;
  }

  async GetActiveUserMaster() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetActiveUserMaster/');
    return data;
  }

  async GetHistoryPeriod() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetHistoryPeriod/');
    // data.forEach((e,i) => {
    //   data[i].DperiodType = this.translate.instant(data[i].periodType);
    // });
    return data;
  }
  async GetJournalStatusType() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetJournalStatusType/');
    return data;
  }

  async CalculateGainLoss(orgCurrencyCode: string = "", TransID: Number = 0, TransTypeId: Number = 0) {
    let reqparams = new HttpParams();
    reqparams = reqparams.set('OrgCurrency', orgCurrencyCode);
    let response = await this._http.post(environment.organizationApiUrl + '/CurrencySetting/calculateGainLoss/' + TransID + "/" + TransTypeId, reqparams).toPromise();
    return response;

  }
  async getBudgetPeriod() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetBudgetPeriod/');
    return data;
  }

  async getInventorySetting() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/getInventorySetting/');
    return data;
  }
  async getSubscribers() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/getSubscribers/');
    return data;
  }

  async getPOTaxRate() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetPOTaxRate/');
    return data;
  }

  async getSalesTaxRate() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetSalesTaxRate/');
    return data;
  }

  async getQuantityByID(Itemid: number, TransID: number, TransTypeID: number) {
    let data;
    data = await this.http.getserviceawait(environment.inventoryApiUrl + '/Inventory/GetQuantityByID?ItemID=' + Itemid + '&TransID=' + TransID + '&TransTypeID=' + TransTypeID);
    return data.quantity;
  }

  getPagination() {
    let data: any = [];
    data = [{ value: 10, text: '10' }, { value: 25, text: '25' }, { value: 50, text: '50' }, { value: 100, text: '100' }]
    return data;
  }

  async GetSSTReturns() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetSSTReturns/');
    return data;
  }

  async GetStatementTypes() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetStatementType/');
    return data;
  }

  async getOrgCountryDetails() {
    let data = await this._http.get(this.apiUrl + '/Master/GetOrgCountryDetails/').toPromise();
    return data;
  }

  async GetJournalChartOfAccounts() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetJournalChartOfAccounts/');
    return data;
  }
  
  async getCostingMethod() {
    let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetCostingMethod/');
    return data;
  }
  
  mask(input) {
    return input.slice(0, -4).replace(/./g, 'x') + input.slice(-4);
  }
  getlicensetype(value:any='') {
    let user = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    return (user.licenseTypeID==9|| user.licenseTypeID==6 || (user.licenseTypeID==11 && value=='trackeditem'))?false:true;
  }
    async GetParentAccounts() {
        let data = await this.http.getserviceawait(this.apiUrl + '/Master/GetParentAccounts/');
        return data;
    }
  
  async getMISCcodes() {
    let data:any = await this.http.getserviceawait(this.apiUrl + '/Master/GetMISCcodes/');
    return data;
  }

  async getClassificationCode() {
    let data:any = await this.http.getserviceawait(this.apiUrl + '/Master/GetClassificationCode/');
    return data.responseData;
  }
}
