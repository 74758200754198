import { Component, EventEmitter, OnInit, Output, ViewChild,ViewEncapsulation } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ContentChange, QuillEditorComponent } from 'ngx-quill';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Quill from 'quill';

const parchment = Quill.import('parchment')
const block = parchment.query('block')
block.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true)
const fontSizeArr = ['6pt', '8pt', '9pt', '10pt', '11pt', '12pt', '13pt', '14pt', '16pt', '18pt', '24pt', '30pt', '36pt', '48pt'];
const Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size /* or NewBlock */, true);

@Component({
  selector: 'app-addtextdesc',
  templateUrl: './addtextdesc.component.html',
  styleUrls: ['./addtextdesc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddtextdescComponent implements OnInit {
  @ViewChild(QuillEditorComponent, { static: true }) body: QuillEditorComponent
  @ViewChild('showtextmodal') public showtextmodal: ModalDirective; 
  @Output() toParentAfterAdd = new EventEmitter<String>();
  htmlText: string; 
  mode: string; 
  constructor() { }

  ngOnInit(): void {
    this.body
    .onContentChanged
    .pipe(
      debounceTime(400),
      distinctUntilChanged()
    )
    .subscribe((data: ContentChange) => {
      this.htmlText = data.html;
    })
  }

  openTextModal(htmlText,mode){
    this.htmlText = htmlText;
    this.mode = mode;
    this.showtextmodal.show();
  }

  addDescription(){
    this.toParentAfterAdd.emit(this.htmlText);
    this.hideDescmodal();
  }

  hideDescmodal(){
    this.htmlText = null;
    this.showtextmodal.hide();
  }

}
