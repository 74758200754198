import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AgCustomDateAdapter } from '../../../utilities/_services/CustomDateAdapter';
import { CustomDateParserFormatter_FullDate } from '../../../utilities/_services/CustomDateParserFormatter';

@Component({
  selector: 'app-agdatepicker',
  template: `  <input class="ag-input-field" placeholder="dd/mm/yyyy" name="d2" date ngbDatepicker
  maxlength="10" autocomplete="off" #d2="ngbDatepicker" (click)="d2.toggle()"
  (dateSelect)="onDateChange($event)" [(ngModel)]="ngbslectedDate">`,
  providers: [{ provide: NgbDateAdapter, useClass: AgCustomDateAdapter },
  { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter_FullDate }]
})
export class AgdatepickerComponent implements OnInit, ICellEditorAngularComp {
  public params: any;
  public selectedDate: any;
  public ngbslectedDate: any;
  @ViewChild('d2') input;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params;
    this.selectedDate = params.value;
    let date = new Date(params.value);
    this.ngbslectedDate = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } as NgbDateStruct;
  }

  getValue(): any {
    return this.selectedDate;
  }

  isPopup(): boolean {
    return true;
  }

  afterGuiAttached() {
    if (this.input != undefined) {
      this.input.toggle();
      this.input.navigateTo({ year: this.ngbslectedDate.year, month: this.ngbslectedDate.month, day: this.ngbslectedDate.day });
    }
  }

  onDateChange(date: NgbDate) {
    let y = date.year.toString().padStart(4, '0');
    let m = date.month.toString().padStart(2, '0');
    let d = date.day.toString().padStart(2, '0');
    this.selectedDate = new Date(y + '-' + m + '-' + d);
    this.ngbslectedDate = { year: date.year, month: date.month, day: date.day } as NgbDateStruct;
    this.params.api.stopEditing();
  }
}
