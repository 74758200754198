import { Injectable } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
@Injectable()
export class CustomDateAdapter {
  fromModel(value: string): NgbDateStruct {
    if (!value)
      return <any>null
    let parts = value.split('/');
    return { year: +parts[0], month: +parts[1], day: +parts[2] } as NgbDateStruct
  }

  toModel(date: NgbDateStruct): string // from internal model -> your mode
  {
    return date ? date.year + "/" + ('0' + date.month).slice(-2) + "/" + ('0' + date.day).slice(-2) : <any>null
  }
}

@Injectable()
export class AgCustomDateAdapter {
  fromModel(value: any): NgbDateStruct {
    // console.log(value);
    if (!value)
      return <any>null;
    if (value.split != undefined) {
      let parts = value.split('/');
      return { year: +parts[0], month: +parts[1], day: +parts[2] } as NgbDateStruct
    }
    return value as NgbDateStruct
  }

  toModel(date: NgbDateStruct): string // from internal model -> your mode
  {
    return date ? date.year + "/" + ('0' + date.month).slice(-2) + "/" + ('0' + date.day).slice(-2) : <any>null
  }
}
