import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { HttpServices } from "../../../utilities/_services/http.service";
import { MasterServices } from "../../../utilities/_services/master.service";
import { AlertMessage } from "../../../utilities/_helpers/alert.message";
import { Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-assettype",
  templateUrl: "./assesttype.component.html",
  styleUrls: ["./assesttype.component.scss"],
})
export class AssettypeComponent implements OnInit {
  lstAccounts: any = [];
  lstDepreciationAccounts: any = [];
  lstAverageMethod: any = [];
  lstDepreciationType: any = [];
  errorList: any = [];
  heading: string;
  selectedRB: string;

  // Properties
  assetTypeID: number = 0;
  assetType: string;
  accumulatedDepreciationAccount: number;
  assetAccount: number;
  depreciationExpenseAccount: number;
  depreciationMethod: number;
  averageMethod: number;
  rate: string;
  effectiveLife: string;
  isRateSelected: boolean;
  isEffectiveLifeSelected: any;
  isEffectiveLife: any;
  assignedRegisteredCount: number;
  disableDepProperty = false;
  // Properties

  @ViewChild('modalDeleteAssetType')
  public modalDeleteAssetType: ModalDirective

  constructor(
    public dialogRef: MatDialogRef<AssettypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private master: MasterServices,
    private http: HttpServices,
    private alertMessage: AlertMessage,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.heading = 'Add New Asset Type';
    this.isRateSelected = true;
    this.onRatioButtonChange('rate');
    this.getAssetTypeMasters();
    if (this.data != null && this.data.assetTypeDetails != undefined) {
      this.loadDetails(this.data.assetTypeDetails);
    }
  }

  loadDetails(assetType) {
    this.heading = 'Edit Asset Type';
    this.assetTypeID = assetType.assetTypeID;
    this.assetType = assetType.assetTypeName;
    this.accumulatedDepreciationAccount = assetType.accumulatedDepreciationAccountID;
    this.assetAccount = assetType.assetAccountID;
    this.depreciationExpenseAccount = assetType.depreciationExpenseAccountID;
    this.averageMethod = assetType.averagingMethodID;
    this.depreciationMethod = assetType.depreciationMethodID;
    this.effectiveLife = assetType.effectiveLife;
    this.rate = assetType.rate;
    this.isRateSelected = assetType.effectiveLife == null ? true : false;
    this.assignedRegisteredCount = assetType.assignedRegisteredCount;
    this.onRatioButtonChange(assetType.effectiveLife == null ? 'rate' : 'effectiveLife');
    this.enableDisableDepProperties();
  }

  async getAssetTypeMasters() {
    this.http
      .getservice(
        environment.accountsApiUrl + "/FixedAsset/GetAssetTypeMasters"
      )
      .subscribe({
        next: (response: any) => {
          if (response.isSuccess) {
            this.lstAverageMethod = response.responseData.item1;
            this.lstDepreciationType = response.responseData.item2;
            var chartOfAccounts = response.responseData.item3;
            this.lstAccounts = chartOfAccounts.filter(x => x.accGroupID == 1 && x.accTypeID == 2);
            this.lstDepreciationAccounts = chartOfAccounts;//.filter(x => x.accGroupID == 3);
          }
        },
      });
  }

  closeModal() {
    this.dialogRef.close();
  }

  validateAssetType(assetType: any): Boolean {
    this.errorList = [];
    if (assetType.AssetTypeName === "" || assetType.AssetTypeName == null) {
      this.errorList.push({ message: this.translate.instant('SharedModule.Please provide a Asset Type Name') });
    } else if (assetType.AccumulatedDepreciationAccountID === "" || assetType.AccumulatedDepreciationAccountID == null) {
      this.errorList.push({ message: this.translate.instant('SharedModule.Please provide a Accumulated Depreciation Account Name') });
    } else if (assetType.AssetAccountID === "" || assetType.AssetAccountID == null) {
      this.errorList.push({ message: this.translate.instant('SharedModule.Please provide a Asset Account Name') });
    } else if (assetType.DepreciationExpenseAccountID === "" || assetType.DepreciationExpenseAccountID == null) {
      this.errorList.push({ message: this.translate.instant('SharedModule.Please provide a Depreciation Expense Account Name') });
    } else if (assetType.DepreciationMethodID === "" || assetType.DepreciationMethodID == null) {
      this.errorList.push({ message: this.translate.instant('SharedModule.Please provide a Depreciation Method Name') });
    } else if (!this.disableDepProperty && (assetType.AveragingMethodID === "" || assetType.AveragingMethodID == null)) {
      this.errorList.push({ message: this.translate.instant('SharedModule.Please provide a Averaging Method Name') });
    } if (!this.disableDepProperty && (assetType.EffectiveLife != null && (Number(assetType.EffectiveLife) == 0 || Number(assetType.EffectiveLife >= 1000)))) {
      this.errorList.push({ message: this.translate.instant("SharedModule.Effecitve Life years between 0.01 and 999.99") });
    } if (!this.disableDepProperty && assetType.Rate != null && Number(assetType.Rate >= 1000)) {
      this.errorList.push({ message: this.translate.instant("SharedModule.Rate should be less than or equal 999.99") });
    } if (this.errorList.length > 0) {
      this.alertMessage.errorNotifier(this.errorList[0]["message"], 0);
      return false;
    }
    else {
      return true;
    }
  }

  onSaveAssetType(): void {
    var assetType = {
      AssetTypeID: this.assetTypeID,
      AssetTypeName: this.assetType,
      AssetAccountID: this.assetAccount,
      AccumulatedDepreciationAccountID: this.accumulatedDepreciationAccount,
      DepreciationExpenseAccountID: this.depreciationExpenseAccount,
      DepreciationMethodID: this.depreciationMethod,
      AveragingMethodID: this.averageMethod,
      Rate: this.rate == '' ? null : Number(this.rate),
      EffectiveLife: this.effectiveLife == '' ? null : Number(this.effectiveLife)
    };
    // console.log(assetType);
    if (this.validateAssetType(assetType)) {
      this.http
        .postservice(
          environment.accountsApiUrl + "/FixedAsset/SaveUpdateAssetType",
          assetType
        )
        .subscribe({
          next: (response: any) => {
            let responseObject = {
              "isSuccess": response.isSuccess
            };
            if (response.isSuccess) {
              if (response.statusMessage !== null) {
                this.alertMessage.errorNotifier(this.translate.instant("SharedModule.Asset Type already exists !"), 0);
              } else {
                this.alertMessage.successNotifier(this.translate.instant("SharedModule.Asset type has been created"), 0);
                this.dialogRef.close(responseObject);
              }
            } else {
              this.alertMessage.errorNotifier(this.translate.instant("SharedModule.Error while save Asset Type !"), 0);
            }
          },
        });
    }
  }

  onRatioButtonChange(selectedRadioButton) {
    if (selectedRadioButton === "rate") {
      this.isRateSelected = true;
      this.effectiveLife = "";
      this.selectedRB = 'rate';
    }
    else {
      this.isRateSelected = false;
      this.rate = "";
      this.selectedRB = 'effectiveLife';
    }
  }

  onAssignedRegisterCount() {
    this.dialogRef.close();
    this.router.navigate(['/accounting/fixedassets/fixedassets'], { queryParams: { action: btoa('Registered') } });
  }

  onOpenModalPopup(PopupName: string) {
    if (PopupName === 'DeleteAssetType')
      this.modalDeleteAssetType.show();
  }

  onCloseModalPopup(PopupName: string) {
    if (PopupName === 'DeleteAssetType')
      this.modalDeleteAssetType.hide();
  }

  onDelete() {
    let apiUrl = environment.accountsApiUrl + '/FixedAsset/DeleteAssetTypes';
    var assetType = [];
    assetType.push({ "AssetTypeID": Number(this.assetTypeID) });
    Swal.fire({
      html: "<p>" + this.translate.instant("SharedModule.If you click Delete") + "," + this.assetType + "  " + this.translate.instant("SharedModule.will be permanently removed including from draft assets. This can't be undone") + "</p>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('SharedModule.Yes, delete it!'),
      cancelButtonText: this.translate.instant('Common.No')
    }).then((result) => {
      if (result.value) {
        this.http.postservice(apiUrl, assetType).subscribe({
          next: (data) => {
            if (data['isSuccess']) {
              let responseObject = {
                "isSuccess": data["isSuccess"]
              };
              this.dialogRef.close(responseObject);
              this.alertMessage.successNotifier(this.translate.instant('SharedModule.Asset Type has been deleted'), 0);
              // this.router.navigate(['/accounting/fixedassetsettings']);
            }
          }
        });
      }
    })
  }

  enableDisableDepProperties() {
    this.disableDepProperty = this.depreciationMethod == 4;
    if (this.disableDepProperty) {
      this.averageMethod = null;
      this.rate = null;
      this.effectiveLife = null;
      this.selectedRB = null;
    } else {
      this.onRatioButtonChange(this.isRateSelected ? 'rate' : 'effectiveLife')
    }
  }

  onChangeDepreciationMethod() {
    this.enableDisableDepProperties();
  }
}

