import { AlertMessage } from './../_helpers/alert.message';
import { Directive, HostListener, ElementRef, OnInit, Renderer2, NgModule, ViewChild, EventEmitter, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AutoSequenceRegEx } from './customvalidator.directive';


@Directive({
  selector: '[alphabets]'
})

export class Alphabets {
  regEx = /^[A-Za-z\s]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[alphabetnumeric]'
})
export class AlphabetNumeric {
  regEx = /^[A-Za-z0-9\s]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[accountcode]'
})
export class AccountCode {
  regEx = /^[A-Za-z0-9\/._-]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[textwithlimitedspecialchar]'
})
export class TextWithLimitedSpecialChar {
  regEx = /^[a-zA-Z0-9#@ /]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[alphabetnumericsymbol]'
})
export class AlphabetNumericSymbol {
  regEx = /^[A-Za-z0-9&(),.-\s]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[alphabetsymbol]'
})
export class AlphabetSymbol {
  regEx = /^[A-Za-z&(),.-\s]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[phonenumber]'
})
export class PhoneNumber {
  regEx = /^[0-9]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[numbers]'
})
export class Numbers {
  regEx = /^[0-9]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}


@Directive({
  selector: '[decimal2_old]'
})
export class Decimal2 {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}


@Directive({
  selector: '[negdecimal2]'
})
export class NegDecimal2 {
  private regex: RegExp = new RegExp(/^-?\d*(\.\d+)?$/g);
  //private regex: RegExp = new RegExp(/^[-]([.]\d+|\d+([.]\d+)?)$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}


@Directive({
  selector: '[decimal3]'
})
export class Decimal3 {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal', '.'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[decimal6]'
})
export class Decimal6 {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,7}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[decimal12]'
})
export class Decimal12 {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,12}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[digit4]'
})
export class Digit4 {
  private regex: RegExp = new RegExp(/^((?!0)\d{1,4}|0|)($|\.$|\.\d{1,12}$)/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[digit10]'
})
export class Digit10 {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^([-]|[\d])(\d{0,9}|0|)($|\.$|\.\d{1,4}$)/g);
  // private regex: RegExp = new RegExp(/^\d*\.?\d{0,4}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  // private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
  @HostListener('drop', ['$event'])
  onDragDrop(event: DragEvent) {
    if (event && event.dataTransfer) {
      const value = event.dataTransfer.getData('text/plain').toString().trim();
      const next = value;
      
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
    }
  }
}

@Directive({
  selector: '[date]'
})
export class _Date {
  // constructor(private _el: ElementRef) { }
  // @HostListener('input', ['$event']) onInputChange(event: any) {
  //     const initalValue = this._el.nativeElement.value;
  //     this._el.nativeElement.value = initalValue.replace(/[^0-9-/.]*/g, '');
  //     if (initalValue !== this._el.nativeElement.value) {
  //         event.stopPropagation();
  //     }
  // }
  regEx = /^[0-9/]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      //(event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[specialchardirective]'
})
export class SpecialCharDirective {
  regEx = /[^<>]$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('paste', ['$event'])
  onSpecialCharPaste(event: ClipboardEvent) {
    let dataToPaste = event.clipboardData.getData('text');
    if(String(dataToPaste).includes(">") || String(dataToPaste).includes("<") ){
       event.preventDefault();
       return;
    }   
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[autosequence]'
})
export class AutoSequence {
  regEx = AutoSequenceRegEx.pattern;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[phonecodenumber]'
})
export class PhoneCodeNumber {
  regEx = /^[0-9+]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      //(event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[appNoRightClick]'
})
export class appNoRightClick {
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
  constructor() { }
}


@Directive({
  selector: '[decimal2]'
})
export class TwoDigitDecimaNumberDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  // private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[xssDirective]'
})
export class XssDirective {
  statusMsgID = 0;
  constructor(private _el: ElementRef, private alertMessage: AlertMessage, private control: NgControl, private _sanitize: DomSanitizer) { }

  ngOnInit() {
    const initialOnChange = (this.control.valueAccessor as any).onChange;
    (this.control.valueAccessor as any).onChange = (value) => initialOnChange(this.verify(value));
  }

  @HostListener('blur', ['$event'])
  OnBlur($event: KeyboardEvent) {
    let str: string = this._el.nativeElement.value;
    this.control.valueAccessor.writeValue(this.verify(str).trim());
  }

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(value: any) {
    let str = this.verify(value);
    if (str == "")
      this.control.valueAccessor.writeValue(str);
  }

  // isHTML(str) {
  //   var doc = new DOMParser().parseFromString(str, "text/html");
  //   return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  // }

  // verify(str: string) {
  //   if (str != undefined && str != null) {
  //     str = str.normalize('NFKC');
  //     if (this.isHTML(str)) {
  //       // this._el.nativeElement.value = "";
  //       str = "";
  //       this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
  //     }
  //     if (str && String(str).includes("cmd")) {
  //       // this._el.nativeElement.value = "";
  //       str = "";
  //       this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
  //     }
  //   }
  //   return str;
  // }

  isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;
    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType == 1)
        return true;
    }
    return false;
  }

  isURL(str) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    var urls = str.match(urlRegex);
    if ((urls != undefined && urls.length > 0) || str.includes('localhost')) {
      return true;
    } else return false;
  }

  verify(str: string) {
    if (str != undefined && str != null && str != '') {
      if (typeof str == 'number' && !isNaN(str)) {
        return str;
      }
      str = str.normalize('NFKC');
      if (this.isURL(str)) {
        str = "";
        this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
      }
      else if (this.isHTML(str) || this._sanitize.sanitize(1, str) == '') {
        str = "";
        this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
      }
      else if (String(str).includes("cmd") || String(str).includes("<script")) {
        str = "";
        this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
      }
    }
    return str;
  }
}

@Directive({
  selector: '[agGridXssDirective]'
})
export class agGridXssDirective {
  statusMsgID = 0;
  @ViewChild("eInput", { read: ElementRef }) eInput: ElementRef;
  constructor(private _el: ElementRef, private alertMessage: AlertMessage, private _sanitize: DomSanitizer) { }
  @HostListener('cellValueChanged', ['$event'])
  oncellValueChanged(params) {
    let str = this.verify(params.newValue);
    let colName = params.colDef.field;
    if (str == "") {
      let rowData = [];
      params.api.forEachNode((rowNode, index) => {
        if (params.rowIndex == index) {
          rowNode.data[colName] = '';
          rowData.push(rowNode.data)
        }
        else rowData.push(rowNode.data);
      });
      params.api!.setRowData(rowData);
      // params.preventDefault();
    }
  }
  isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;
    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType == 1) return true;
    }
    return false;
  }

  isURL(str) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    var urls = str.match(urlRegex);
    if ((urls != undefined && urls.length > 0) || str.includes('localhost')) {
      return true;
    } else return false;
  }
  verify(str: string) {
    if (str != undefined && str != null) {
      if (typeof str == 'number' && !isNaN(str)) {
        return str;
      }
      str = str.normalize('NFKC');

      if (str != '' && str != null && str != undefined) {
        if (this.isURL(str)) {
          str = "";
          this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
          return false;
        }
        if (this.isHTML(str) || this._sanitize.sanitize(1, str) == '') {
          str = "";
          this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
          return false;
        }
        if (String(str).includes("cmd") || String(str).includes("<script")) {
          str = "";
          this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
          return false;
        }
      }
    }
    return str;
  }
}


@Directive({
  selector: '[xssURLDirective]'
})
export class xssURLDirective {
  statusMsgID = 0;
  constructor(private _el: ElementRef, private alertMessage: AlertMessage, private control: NgControl, private _sanitize: DomSanitizer) { }

  ngOnInit() {
    const initialOnChange = (this.control.valueAccessor as any).onChange;
    (this.control.valueAccessor as any).onChange = (value) => initialOnChange(this.verify(value));
  }

  @HostListener('blur', ['$event'])
  OnBlur($event: KeyboardEvent) {
    let str: string = this._el.nativeElement.value;
    this.control.valueAccessor.writeValue(this.verify(str).trim());
  }

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(value: any) {
    let str = this.verify(value);
    if (str == "")
      this.control.valueAccessor.writeValue(str);
  }

  isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;
    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType == 1)
        return true;
    }
    return false;
  }

  verify(str: string) {
    if (str != undefined && str != null && str != '') {
      if (typeof str == 'number' && !isNaN(str)) {
        return str;
      }
      str = str.normalize('NFKC');
      if (this.isHTML(str) || this._sanitize.sanitize(1, str) == '') {
        str = "";
        this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
      }
      if (String(str).includes("cmd") || String(str).includes("<script")) {
        str = "";
        this.statusMsgID = this.alertMessage.errorNotifier("Entered text is invalid due to security reasons", this.statusMsgID);
      }
    }
    return str;
  }
}

@Directive({
  selector: '[percentage]'
})
export class Percentage {
  regEx = /^[0-9]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
      return;
    }
    if (+next > 100 || +next < 0) {
      event.preventDefault();
      return;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    let dataToPaste = event.clipboardData.getData('text');
    let isNotNumber = isNaN(Number(dataToPaste));
    if (isNotNumber) {
      event.preventDefault();
      return;
    } else {

      if (!String(dataToPaste).match(this.regEx)) {
        event.preventDefault();
        return;
      }
      if (+dataToPaste > 100 || +dataToPaste < 0) {
        event.preventDefault();
        return;
      }
    }

  }
}


@Directive({
  selector: '[creditcardnumbers]'
})
export class CreditCardNumbers {
  regEx = /^[0-9\s]*?$/g;
  constructor(private _el: ElementRef) { }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    const input = event.target as HTMLInputElement;
    let trimmed = input.value.replace(/\s+/g, '');
    if (trimmed.length > 16) {
      trimmed = trimmed.substr(0, 15);
    }

    let numbers = [];
    for (let i = 0; i < trimmed.length; i += 4) {
      numbers.push(trimmed.substr(i, 4));
    }

    input.value = numbers.join(' ');

    this.validateRegEx(event);
  }
  validateRegEx(event) {
    let current: string = this._el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[decimal4]'
})
export class FourDigitDecimaNumberDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^([-]|[\d])[\d]*\.?\d{0,4}$/g);
  // private regex: RegExp = new RegExp(/^\d*\.?\d{0,4}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  // private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
  @HostListener('drop', ['$event'])
  onDragDrop(event: DragEvent) {
    if (event && event.dataTransfer) {
      const value = event.dataTransfer.getData('text/plain').toString().trim();
      const next = value;
      
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
    }
  }
}

@Directive({
  selector: '[Decimal10]'
})
export class Decimal10 {
  private regex: RegExp = new RegExp(/^\d+\.?\d{0,10}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef) {
  }

  @HostListener('drop', ['$event'])
  onDragDrop(event: DragEvent) {
    if (event && event.dataTransfer) {
      const value = event.dataTransfer.getData('text/plain').toString().trim();
      this.preventValue(null, value);
    }
  }
  
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.preventValue(event);
  }

  private preventValue(event?, value?) {
    let next: string;
    if (event) {
      if(this.specialKeys.indexOf(event.key) !== -1) {
        return;
      }
      const current: string = this.el.nativeElement.value;
      const position = this.el.nativeElement.selectionStart;
      next = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    } else {
      next = value;
    }

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}


@Directive({
  selector: '[quantityDecimal4]'
})
export class QuantityFourDigitDecimaNumberDirective {
  private regex: RegExp = new RegExp(/^\d+\.?\d{0,4}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef) {
  }

  @HostListener('drop', ['$event'])
  onDragDrop(event: DragEvent) {
    if (event && event.dataTransfer) {
      const value = event.dataTransfer.getData('text/plain').toString().trim();
      this.preventValue(event, value);
    }
  }
  
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.preventValue(event);
  }

  private preventValue(event, value?) {
    let next: string;
    if (value === undefined) {
      if(this.specialKeys.indexOf(event.key) !== -1) {
        return;
      }
      const current: string = this.el.nativeElement.value;
      const position = this.el.nativeElement.selectionStart;
      next = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    } else {
      next = value;
    }

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}


@Directive({
  selector: '[decimal2WithPercentage]'
})
export class TwoDigitDecimaNumberWithorWithoutPercentageDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d+(\.\d{0,2})?%?$/gm);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  // private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Decimal'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}



@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  constructor(private elementRef: ElementRef) { }
  @Output() clickOutside = new EventEmitter<MouseEvent>();
   @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
      if (!targetElement) {
        return;
        }
      var clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if(targetElement.id == "txtsearchtdeliveryaddress"||targetElement.id =="txtsearchtermsandcondition" ){
        clickedInside = true;
        }
        if (!clickedInside) {
        this.clickOutside.emit(event);
        }
    }
}

@Directive({
  selector: '[drExchangeRate]',
})
export class ExchangeRateDirective {
  private regex: RegExp = new RegExp(/^\d{0,9}(\.\d{0,12})?$/g);
  //allow 9 whole digits and 12 decimal digits.
  //allow copy, cut and paste

  constructor(private el: ElementRef, private formControl: NgControl) {}
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    const key = event.key;
    const isNumPeriod = key.match(/[\d.]/g) !== null;
    const ctrlCmdModifiers = ['a', 'c', 'v', 'x', 'A', 'C', 'V', 'X'];
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    const specialKeys = [
      'Delete',
      'Backspace',
      'ArrowUp',
      'ArrowLeft',
      'ArrowRight',
      'ArrowDown',
      'Home',
      'End'];
    //allow ctrl+c, ctrl+v, ctrl+a, ctrl+x
    const isCtrlCmd = ctrlCmdModifiers.indexOf(key) > -1 && (event.ctrlKey || event.metaKey);

    if (isNumPeriod) {
      const current = this.el.nativeElement.value;
      const cursorPosition = this.el.nativeElement.selectionStart;
      const next = [current.slice(0, cursorPosition), key, current.slice(cursorPosition)].join('');
      const decimalPosition = next.indexOf('.');
      console.log(decimalPosition);
      if (!String(next).match(this.regex)) {
        event.preventDefault();
      }
    } else if (!(isCtrlCmd || specialKeys.indexOf(key) > -1)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text/plain') || window['clipboardData'].getData('Text');
    if (pastedText) {
      const current = this.el.nativeElement.value;
      const cursorPosition = this.el.nativeElement.selectionStart;
      const next = [current.slice(0, cursorPosition), pastedText.replace(/[^\d.]/g, ''), current.slice(cursorPosition)].join('');
      const decimalPosition = next.indexOf('.');
      let output = '';

      if (decimalPosition > -1) {
        const wholeNumber = next.substring(0, decimalPosition);
        if (wholeNumber.length > 9) {
          output = wholeNumber.substring(0, 9);
        } else {
          const decimalDigits = next.substring(decimalPosition + 1).substring(0, 12);
          output = `${wholeNumber}.${decimalDigits}`;
        }
      } else {
        output = next.substring(0, 9);
      }
      if (!String(output).match(this.regex)) {
        event.preventDefault();
      } else {
        this.formControl.control.setValue(output);
      }
    }
    event.preventDefault();
  }
}

@Directive({
    selector: '[drUnitPrice]'
})
export class UnitPriceDirective {
    private regex: RegExp = new RegExp(/[-+*/^()]*?-?\d{0,9}(\.\d{0,4})*?[-+*/^()]*?$/g);
    //allow negative and decimal numbers.
    //allow 9 whole digits, and 4 decimal digits.
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home

    constructor(private el: ElementRef, private formControl: NgControl) {}

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const key = event.key;
        const isNumPeriod = key.match(/[\d.]/g) !== null;
        const ctrlCmdModifiers = ['a', 'c', 'v', 'x', 'A', 'C', 'V', 'X'];
        // Allow key codes for special events. Reflect :
        // Backspace, tab, end, home
        const specialKeys = [ '+','-','*','/','^','(',')','Shift','Tab',
            'Delete',
            'Backspace',
            'ArrowUp',
            'ArrowLeft',
            'ArrowRight',
            'ArrowDown',
            'Home',
            'End'];
        //allow ctrl+c, ctrl+v, ctrl+a, ctrl+x
        const isCtrlCmd = ctrlCmdModifiers.indexOf(key) > -1 && (event.ctrlKey || event.metaKey);
        if (isNumPeriod) {
            const current = this.el.nativeElement.value;
            const cursorPosition = this.el.nativeElement.selectionStart;
            const next = [current.slice(0, cursorPosition), key, current.slice(cursorPosition)].join('');

            if (next && !String(next).match(this.regex)) {
                event.preventDefault();
            }
        } else if (!(isCtrlCmd || specialKeys.indexOf(key) > -1)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
        const pastedText = event.clipboardData.getData('text/plain') || window['clipboardData'].getData('Text');
        if (pastedText) {
            const current = this.el.nativeElement.value;
            const cursorPosition = this.el.nativeElement.selectionStart;
            const next = [current.slice(0, cursorPosition), pastedText.replace(/[^\d.]/g, ''), current.slice(cursorPosition)].join('');
            const decimalPosition = next.indexOf('.');
            let output = '';
            if (decimalPosition > -1) {
                const wholeNumber = next.substring(0, decimalPosition);
                if (wholeNumber.length > 9){
                    output = wholeNumber.substring(0, 9);
                } else {
                    const decimalDigits = next.substring(decimalPosition + 1).substring(0, 4);
                    output = `${wholeNumber}.${decimalDigits}`;
                }
            } else {
                output = next.substring(0, 9);
            }

            if (!String(output).match(this.regex)) {
                event.preventDefault();
            } else {
                this.formControl.control.setValue(output);
            }
        }
        event.preventDefault();
    }
}

@Directive({
    selector: '[drQuantity]',
})
export class QuantityDirective {
    private regex: RegExp = new RegExp(/[-+*/^()]*?-?\d{0,9}(\.\d{0,10})*?[-+*/^()]*?$/g);
    //allow decimal numbers.
    //allow 9 whole digits and 10 decimal digits.

    constructor(private el: ElementRef, private formControl: NgControl) {}

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        const key = event.key;
        const isNumPeriod = key.match(/[\d.]/g) !== null;
        const ctrlCmdModifiers = ['a', 'c', 'v', 'x', 'A', 'C', 'V', 'X'];
        // Allow key codes for special events. Reflect :
        // Backspace, tab, end, home
        const specialKeys = [ '+','-','*','/','^','(',')','Shift','Tab',
            'Delete',
            'Backspace',
            'ArrowUp',
            'ArrowLeft',
            'ArrowRight',
            'ArrowDown',
            'Home',
            'End'];
        //allow ctrl+c, ctrl+v, ctrl+a, ctrl+x
        const isCtrlCmd = ctrlCmdModifiers.indexOf(key) > -1 && (event.ctrlKey || event.metaKey);
        if (isNumPeriod) {
            const current = this.el.nativeElement.value;
            const cursorPosition = this.el.nativeElement.selectionStart;
            const next = [current.slice(0, cursorPosition), key, current.slice(cursorPosition)].join('');

            if (next && !String(next).match(this.regex)) {
                event.preventDefault();
            }
        } else if (!(isCtrlCmd || specialKeys.indexOf(key) > -1)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
        const pastedText = event.clipboardData.getData('text/plain') || window['clipboardData'].getData('Text');
        if (pastedText) {
            const current = this.el.nativeElement.value;
            const cursorPosition = this.el.nativeElement.selectionStart;
            const next = [current.slice(0, cursorPosition), pastedText.replace(/[^\d.]/g, ''), current.slice(cursorPosition)].join('');
            const decimalPosition = next.indexOf('.');
            let output = '';

            if (decimalPosition > -1) {
                const wholeNumber = next.substring(0, decimalPosition).substring(0, 9);
                const decimalDigits = next.substring(decimalPosition + 1).substring(0, 10);
                output = `${wholeNumber}.${decimalDigits}`;
            } else {
                output = next.substring(0, 9);
            }

            if (output && !String(output).match(this.regex)) {
                event.preventDefault();
            } else {
                this.formControl.control.setValue(output);
            }
        }
        event.preventDefault();
    }
}

@Directive({
  selector: '[drAmount]',
})
export class AmountDirective {
  //allow copy paste and has same functionality as 'decimal2' directive
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  //allow 9 whole digits and 12 decimal digits.
  //allow copy, cut and paste

  constructor(private el: ElementRef, private formControl: NgControl) {}
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    const key = event.key;
    const isNumPeriod = key.match(/[\d.]/g) !== null;
    const ctrlCmdModifiers = ['a', 'c', 'v', 'x', 'A', 'C', 'V', 'X'];
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    const specialKeys = [
      'Delete',
      'Backspace',
      'ArrowUp',
      'ArrowLeft',
      'ArrowRight',
      'ArrowDown',
      'Home',
      'End'];
    //allow ctrl+c, ctrl+v, ctrl+a, ctrl+x
    const isCtrlCmd = ctrlCmdModifiers.indexOf(key) > -1 && (event.ctrlKey || event.metaKey);

    if (isNumPeriod) {
      const current = this.el.nativeElement.value;
      const cursorPosition = this.el.nativeElement.selectionStart;
      const next = [current.slice(0, cursorPosition), key, current.slice(cursorPosition)].join('');
      const decimalPosition = next.indexOf('.');
      if (!String(next).match(this.regex)) {
        event.preventDefault();
      }
    } else if (!(isCtrlCmd || specialKeys.indexOf(key) > -1)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text/plain');
      if (pastedText) {
        const current = this.el.nativeElement.value;
        const cursorPosition = this.el.nativeElement.selectionStart;
        const next = [current.slice(0, cursorPosition), pastedText.replace(/[^\d.]/g, ''), current.slice(cursorPosition)].join('');
        const decimalPosition = next.indexOf('.');
        let output = '';

        if (decimalPosition > -1) {
          const wholeNumber = next.substring(0, decimalPosition);
          if (wholeNumber.length > 15) {
            output = wholeNumber.substring(0, 15);
          } else {
            const decimalDigits = next.substring(decimalPosition + 1).substring(0, 2);
            output = `${wholeNumber}.${decimalDigits}`;
          }
        } else {
          output = next.substring(0, 15);
        }
        if (!String(output).match(this.regex)) {
          event.preventDefault();
        } else {
          this.formControl.control.setValue(output);
        }
      }
    }
    event.preventDefault();
  }
}
@NgModule({
    declarations: [ClickOutsideDirective, Alphabets, AlphabetNumeric, AccountCode, TextWithLimitedSpecialChar, AlphabetNumericSymbol, AlphabetSymbol, PhoneNumber, Numbers, Decimal2, Decimal3, Decimal6, Decimal12, Digit4, Digit10, _Date, AutoSequence, PhoneCodeNumber, appNoRightClick, NegDecimal2, TwoDigitDecimaNumberDirective, XssDirective, agGridXssDirective, xssURLDirective, Percentage, CreditCardNumbers, FourDigitDecimaNumberDirective, TwoDigitDecimaNumberWithorWithoutPercentageDirective, QuantityFourDigitDecimaNumberDirective, SpecialCharDirective, Decimal10, ExchangeRateDirective, UnitPriceDirective, QuantityDirective, AmountDirective],
    exports: [ClickOutsideDirective, Alphabets, AlphabetNumeric, AccountCode, TextWithLimitedSpecialChar, AlphabetNumericSymbol, AlphabetSymbol, PhoneNumber, Numbers, Decimal2, Decimal3, Decimal6, Decimal12, Digit4, Digit10, _Date, AutoSequence, PhoneCodeNumber, appNoRightClick, NegDecimal2, TwoDigitDecimaNumberDirective, XssDirective, agGridXssDirective, xssURLDirective, Percentage, CreditCardNumbers, FourDigitDecimaNumberDirective, TwoDigitDecimaNumberWithorWithoutPercentageDirective, QuantityFourDigitDecimaNumberDirective, SpecialCharDirective, Decimal10, ExchangeRateDirective, UnitPriceDirective, QuantityDirective, AmountDirective]
})

export class AllowKeypressDirectiveModule { }
