import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  urlsToSkiploading: Array<string>;

  constructor(private authenticationService: AuthenticationService) {
    this.urlsToSkiploading = [
      'clientapp/verifyclient'
    ];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    if (this.isValidRequestForInterceptor(request.url)) {
      const currentUser = this.authenticationService.currentUserValue;
      const isLoggedIn = currentUser && currentUser.accessToken;
      if (isLoggedIn) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.accessToken}`
          }
        });
      }
    }
    return next.handle(request);
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.urlsToSkiploading) {
        if (new RegExp(address.toLowerCase()).test(destination.toLowerCase())) {
          //console.log("address ", address.toLowerCase());
          return false;
        }
      }
    }
    return true;
  }
}
