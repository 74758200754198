<ng-container>
    <div *ngFor="let totaltaxamount1 of LinetaxtotalAmountsAreList" >
    <accordion class="custom-accordion ac-view">
        <accordion-group #group1 [isOpen]="false" panelClass="customClass">
          <div accordion-heading class="t-link" *ngIf="totaltaxamount1.totalTaxAmount!='0'"> 
            <div class="d-flex justify-content-between  align-items-start">
              <div class="">
                <i class="pull-left fa fa-angle-down rotate-icon" [ngClass]="{'fa-angle-down': group1?.isOpen, 'fa-angle-right': !group1?.isOpen}"></i>
                <strong>{{totaltaxamount1.taxType}} </strong>
              </div>
              <div>
                <div >
                <strong>  {{ braceFormatter(totaltaxamount1.totalTaxAmount) }}</strong>
                </div>
              </div>
            </div>
           </div>
          

            <div  *ngFor="let totaltaxamount of totaltaxamount1.totallinetaxAmountsList" class="sub-collapse" >
              <accordion-group #group2 [isOpen]="false" panelClass="customClass">
              <div accordion-heading class="t-link">
                <div class="d-flex justify-content-between  align-items-start text-success">
                  <div class="">
                    <i *ngIf='totaltaxamount.taxCompoundAmountList.length>1' class="pull-left fa fa-angle-down rotate-icon" [ngClass]="{'fa-angle-down': group2?.isOpen, 'fa-angle-right': !group2?.isOpen}"></i>
                    {{totaltaxamount.taxType}} 
                  </div>
                  <div>
                    <div class="">
                      {{ braceFormatter(totaltaxamount.linetaxAmount) }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngFor="let amountsare of totaltaxamount.taxCompoundAmountList" >
                  <div class="d-flex justify-content-between  align-items-start sub-collapse-item">
                    <div>
                      {{amountsare.taxType}}  {{amountsare.taxRate}}%
                    </div>
                    <div>
                      <div>
                        {{ braceFormatter(amountsare.taxCompoundAmt) }}
                      </div>
                    </div>
                  </div>
              </div> 
           
          </accordion-group>
            </div>
         
        </accordion-group>
    
    </accordion>
  </div>

  </ng-container>