import { ICellEditorParams } from '@ag-grid-community/all-modules';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warehouselist',
  templateUrl: './warehouselist.component.html',
  styleUrls: ['./warehouselist.component.scss']
})
export class WarehouselistComponent implements ICellEditorAngularComp, AfterViewInit {
  lstWarehouse: any = [];
  gridParams: any;
  warehouseInfo: any;
  constructor() {

  }

  getValue() {
    return this.warehouseInfo;
  }

  agInit(params: ICellEditorParams): void {
    this.gridParams = params;
    this.lstWarehouse = this.gridParams.lstWarehouse;
    this.warehouseInfo = params.value;
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  onChange(e) {
    this.gridParams.api.stopEditing();
  }

  isPopup(): boolean {
    return true;
  }

}
