import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[fileDragDrop]'
})
export class FileDragDropDirective {@HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  @HostListener('dragover', ['$event']) onDragOver(evnt) {
    evnt.preventDefault();
    evnt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evnt){
    evnt.preventDefault();
    evnt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(evnt){
    evnt.preventDefault();
    evnt.stopPropagation();
    this.fileOver = false;
    const files = evnt.dataTransfer.files;
    if(files.length > 0){
      this.fileDropped.emit(files);
    }
  }
}
