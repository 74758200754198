import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpServices } from '../../../utilities/_services/http.service';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter } from '../../../utilities/_services/CustomDateAdapter';
import { CustomDateParserFormatter_FullDate } from '../../../utilities/_services/CustomDateParserFormatter';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-dynamicitemfield',
  templateUrl: './dynamicitemfield.component.html',
  styleUrls: ['./dynamicitemfield.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter_FullDate }]
})
export class DynamicitemfieldComponent implements OnInit {
  lstCustomFields:any =[];
  lstCustomFieldsAll:any =[];
  dynamicform: FormGroup;
  submitted:boolean = false;
  constructor(private rootFormGroup: FormGroupDirective,private http: HttpServices, private formBuilder: FormBuilder,
    private ngbDateAdapter: NgbDateAdapter<string>, private ngbCalendar: NgbCalendar, public datepipe: DatePipe) {
    this.dynamicform = this.rootFormGroup.control;
  }

  ngOnInit(): void {  
    this.dynamicform  = this.formBuilder.group({
      fieldArray: this.formBuilder.array([]),
    }); 
  }     
 
  get customFields(): FormArray {
    return <FormArray>this.dynamicform.controls['fieldArray'];
  }
  
  GetItemCustomFields(itemTypeID, itemID) {
    if(itemTypeID == 3){
      this.lstCustomFieldsAll=[];
      this.lstCustomFields=[];
      const control = <FormArray>this.dynamicform.controls['fieldArray'];
      for (let i = control.length - 1; i >= 0; i--) {
        control.removeAt(i)
      }
      control.reset();
      return;
    }
    this.http.getservice(environment.organizationApiUrl + '/CustomFieldSetting/GetItemCustomFields?Flag=2&ItemID=' + itemID).subscribe({
      next: (data: any) => {
        this.lstCustomFieldsAll = data;
        for (let i = 0; i < this.lstCustomFieldsAll.length; i++) {
          if (this.lstCustomFieldsAll[i].fieldTypeID == 2) {
            this.lstCustomFieldsAll[i].customValue = JSON.parse(this.lstCustomFieldsAll[i].customValue);
          }
        }        
        this.lstCustomFields = this.lstCustomFieldsAll.filter(x => x.itemTypeID == itemTypeID);
        const control = <FormArray>this.dynamicform.controls['fieldArray'];
        for (let i = control.length - 1; i >= 0; i--) {
          control.removeAt(i)
        }
        control.reset();
        this.addFields(this.lstCustomFields, itemID);
      }
    });
  }

  addFields(types, itemID) {
    ['fieldArray'];
    types.forEach((element, i) => {      
      let value = null;      
      if(element["transCustomValue"] != null && element["transCustomValue"] != undefined && element["transCustomValue"] != '' ){
        let tcv = JSON.parse(element["transCustomValue"]);
        if(tcv.length > 0){
          if (itemID > 0 && (element["fieldTypeID"] == 1 || element["fieldTypeID"] == 3)) {
            value = tcv[0].CustomValueText;
          }
          else if (itemID > 0 && element["fieldTypeID"] == 2) {
            value = tcv[0].CustomValueID;
          }
        }
      }

      if (element["isMandatory"] == true) {
        this.customFields.push(this.formBuilder.group({ [element["fieldID"]]: new FormControl(value, [Validators.required]) }));
      }
      else {
        this.customFields.push(this.formBuilder.group({ [element["fieldID"]]: new FormControl(value) }));
      }
    });
  }
}
