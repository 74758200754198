<div class="row">
    <div class="col-md-12">
        <table class="table table-bordered custom-table-grid-1" style="width:100%;">
            <thead>
                <tr>
                    <ng-container *ngFor="let column of columnDefs;">
                        <ng-container *ngIf="column.isVisible == true">
                            <th [ngStyle]="column.css ? column.css:''">
                                {{column.title}}
                                <span *ngIf="column.isMandatory" class="text-danger">*</span>
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let row of GroupRowData; let parentIndex = index;">
                    <tr>
                        <ng-container *ngFor="let column of columnDefs;">
                            <ng-container *ngIf="column.isVisible == true">
                                <td [ngStyle]="column.align == 'right' && {'text-align': 'right'}">
                                    <app-simple-ccell [rowType]="1" [row]="row" [column]="column"></app-simple-ccell>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <ng-container *ngFor="let childrow of row.lstChild; let childIndex = index;">
                        <tr style="background-color: #f4f4f4;">
                            <ng-container *ngFor="let column of columnDefs;">
                                <ng-container *ngIf="column.isVisible == true">
                                    <td [ngStyle]="column.align == 'right' && {'text-align': 'right'}">
                                        <app-simple-ccell [rowType]="2" [row]="childrow" [column]="column">
                                        </app-simple-ccell>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>