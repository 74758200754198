import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(private translate: TranslateService) { }

  async generateExcel(params: any) {

    params.header.forEach((a,i) => {
        params.header[i] = this.translate.instant(a);
    });

    const header = params.header;
    const data = params.data;
    const Cells = params.header.length;
    const title = this.translate.instant(params.title);
    const formats = params.format;


    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(this.translate.instant('Sharing Data'));


    // Add Row and formatting
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.addRow([]);
    var todayDate = new Date();
    let currntTime = todayDate.toTimeString().split(' ')[0];
    let month = todayDate.getUTCMonth() + 1;
    let currentDate = todayDate.getUTCDate().toString() + '-' + month + '-' + todayDate.getUTCFullYear().toString() + ':'+ currntTime;
    let headerDate = todayDate.getUTCDate().toString() + '-' + month + '-' + todayDate.getUTCFullYear().toString() + ':'+ currntTime;
    const subTitleRow = worksheet.addRow([this.translate.instant('Date') +' : ' + headerDate]);
    worksheet.mergeCells('A1:D2');


    // Blank Row
    worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'CCCCCCCC' },
        bgColor: { argb: 'CCCCCCCC' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    
    const idxlist:any = [];
    if(params.title == 'Journal Entry'){
      const alignRight = ['Debit','Credit']
      
      alignRight.forEach(ar => {
        idxlist.push(params.header.findIndex(m => m == ar)+1);
      });
    }
    // Add Data 
    data.forEach(d => {
      const row = worksheet.addRow(d);

      if(params.title == 'Journal Entry'){
        row.eachCell((cell, number) => {
          if(idxlist.indexOf(number) != -1){
            cell.alignment = { horizontal: 'right' };
            cell.numFmt = "0.00";
          }
        });
      }

      if (formats) {
        row.eachCell((cell, number) => {
          let formatType = formats[number - 1];
          if (formatType == 'number2') {
            cell.numFmt = "0.00";
          }
          else if (formatType == 'number2-4') {
            cell.numFmt = "0.00##"
          }
          else if (formatType == 'number2-10') {
            cell.numFmt = "0.00########"
          }
        });
      }

      // const qty = row.getCell(Cells);
    });

    // header set width
    for (var i = 1; i <= header.length; i++) {
      worksheet.getColumn(i).width = 30;
    }

    // worksheet.getColumn(1).width = 40;
    worksheet.addRow([]);

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '-' + currentDate + '.xlsx');
    });

  }


  async generatTemplate(params: any) {
    const header = params.header;
    const data = params.data;
    const Cells = params.header.length;
    const filename = params.filename;
    const filetype = params.filetype;

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(this.translate.instant('Sharing Data'));

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'CCCCCCCC' },
        bgColor: { argb: 'CCCCCCCC' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    
    // Add Data 
    //worksheet.addRow(data);
    const idxlist:any = [];
    data.forEach(d => {
      const row = worksheet.addRow(d);
      const qty = row.getCell(Cells);
    });

    // header set width
    for (var i = 1; i <= header.length; i++) {
      worksheet.getColumn(i).width = 20;
    }

    // worksheet.getColumn(1).width = 40;
    worksheet.addRow([]);

    // Generate Excel File with given name
    if (filetype == 'csv'){
      workbook.csv.writeBuffer().then((data: any) => {
        const blob = new Blob([data], { type: filetype });
        fs.saveAs(blob, filename);
      });
    }
    else{
      workbook.xlsx.writeBuffer().then((data: any) => {
        const blob = new Blob([data], { type: filetype });
        fs.saveAs(blob, filename);
      });
    }
  }
}
