import { CATCH_ERROR_VAR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { HttpServices } from '../../../utilities/_services/http.service';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
@Component({
  selector: 'app-unitpricefrompricelist',
  templateUrl: './unitpricefrompricelist.component.html'
})
export class UnitpricefrompricelistComponent implements OnInit {
  // rowData: any = [];
  // contactID: any;
  // transDate: any;
  // transType: any;
  // callFrom: any;
  // index: any;
  // itemID: any;
  // quantity: any;
  // Obj = {
  //   rowData: null,
  //   contactID: null,
  //   transDate: null,
  //   transType: null,
  //   callFrom: null,
  //   index: null,
  //   itemKey: null,
  //   qtyKey: null,
  //   unitPriceKey: null,
  //   amountKey: null
  //  }
  orgSettings:any;
  alerteditemlist:any=[];
  @Output() toReturnUnitPrice = new EventEmitter<any>();
  constructor(private http: HttpServices,private local: LocalCacheServices) { }

  ngOnInit(): void {
    this.orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
  }

  lstPriceListItems = [];
  getItemUnitPrice(Obj: any) {
    // Obj = Obj;

    if (Obj.callFrom == "contact" || Obj.callFrom == "date" || Obj.callFrom == "edit") {
      if (Obj.contactID && Obj.transDate) {
        this.getValidPriceList(Obj);
      }
      else {
        this.lstPriceListItems = [];
      }
    }
    else if(Obj.callFrom == "delete"){
      let index=this.alerteditemlist.findIndex(e=>e.index==Obj.index);
      if(index>=0){
        this.alerteditemlist.splice(index,1);
      }
      this.alerteditemlist.forEach(e=>{
        if(e.index>Obj.index){
            e.index=e.index-1;
        }
    })
    }
    else if (this.lstPriceListItems.length > 0) {
      this.checkUnitPrice(Obj);
    }
  }

  getValidPriceList(Obj: any) {
    this.lstPriceListItems = [];
    let payload = {
      contactID: Obj.contactID,
      transDate: Obj.transDate,
      itemID: 0,
      quantity: 0,
      TransType: Obj.transType
    };
    this.http.postservice(environment.inventoryApiUrl + '/Inventory/GetPriceListItems', payload).subscribe({
      next: (rdata: any) => {
        if (rdata.responseData != "") {
          this.lstPriceListItems = JSON.parse(rdata.responseData);
        }
        if (Obj.callFrom != "edit") {
          this.checkUnitPrice(Obj);
        }
      }
    });
  }

  checkUnitPrice(Obj: any) {
    if ((Obj.callFrom == "contact" || Obj.callFrom == "date") && this.lstPriceListItems.length > 0) {
      let ischeck = false;
      let pl = [];
      this.alerteditemlist=[];
      Obj.rowData.forEach((row: any, index) => {
        if (row.IsGroup == false || row.IsGroup == undefined || row.IsGroup == null) {
          let i = row[Obj.itemKey];
          let q = Number(row[Obj.qtyKey]);
          pl = this.lstPriceListItems.filter(x => x.ItemID == i && x.Quantity <= q);
        } else {
          row.lstChild.forEach(ch => {
            let i = ch[Obj.itemKey];
            let q = Number(ch[Obj.qtyKey]);
            pl = this.lstPriceListItems.filter(x => x.ItemID == i && x.Quantity <= q);
          });
        }

        if (pl.length > 0 && ischeck == false) {
          ischeck = true;
        }
      });

      if (ischeck) {
        this.alertWithConfirmBox(Obj);
      }
    }
    else if ((Obj.callFrom == "quantity" || Obj.callFrom == "item") && this.lstPriceListItems.length > 0) {
      let pl = [];
      let index=this.alerteditemlist.findIndex(e=>e.index==Obj.index);
      if(index>=0 && Obj.callFrom == "item"){
        this.alerteditemlist.splice(index,1);
      }
      let row = Obj.rowData[Obj.index];
      if (row.isGroup == false) {
        let i = row[Obj.itemKey];
        let q = Number(row[Obj.qtyKey]);
        pl = this.lstPriceListItems.filter(x => x.ItemID == i && x.Quantity <= q);
      }
      else {
        pl = [];
        row.lstChild.forEach(ch => {
          let i = ch[Obj.itemKey];
          let q = Number(ch[Obj.qtyKey]);
          if (pl.length == 0)
            pl = this.lstPriceListItems.filter(x => x.ItemID == i && x.Quantity <= q);
        });
      }

      if (pl.length > 0) {
        this.alertWithConfirmBox(Obj);
      }
    }
  }

  setItemUnitPrice(Obj: any) {    
    if ((Obj.callFrom == "contact" || Obj.callFrom == "date") && this.lstPriceListItems.length > 0) {
      let rd = Obj.rowData.filter(x => x[Obj.itemKey] > 0);
      if (rd.length > 0) {
        let pl = [];
        Obj.rowData.forEach((row: any, index) => {
          if (row.IsGroup == false || row.IsGroup == undefined || row.IsGroup == null) {
            let i = row[Obj.itemKey];
            let q = Number(row[Obj.qtyKey]);
            pl = this.lstPriceListItems.filter(x => x.ItemID == i && x.Quantity <= q);
            if (pl.length > 0) {
              pl.sort((a, b) => b.PriceListID - a.PriceListID);
              pl.sort((a, b) => b.Quantity - a.Quantity);
              row[Obj.unitPriceKey] = pl[0].UnitPrice;
              row.PriceListID = pl[0].PriceListID;
              row[Obj.amountKey] = q * row[Obj.unitPriceKey];
              Obj.affectedRowIndexes.push(index);
            }
          } else {
            row.lstChild.forEach(ch => {
              let i = ch[Obj.itemKey];
              let q = Number(ch[Obj.qtyKey]);
              pl = this.lstPriceListItems.filter(x => x.ItemID == i && x.Quantity <= q);

              if (pl.length > 0) {
                pl.sort((a, b) => b.PriceListID - a.PriceListID);
                pl.sort((a, b) => b.Quantity - a.Quantity);
                ch[Obj.unitPriceKey] = pl[0].UnitPrice;
                ch.PriceListID = pl[0].PriceListID;
                ch[Obj.amountKey] = q * ch[Obj.unitPriceKey];
              }
            });
          }
        });
      }
    }
    else if ((Obj.callFrom == "quantity" || Obj.callFrom == "item") && this.lstPriceListItems.length > 0) {
      let pl = [];
      let row = Obj.rowData[Obj.index];
      if (row.isGroup == false) {
        let i = row[Obj.itemKey];
        let q = Number(row[Obj.qtyKey]);
        pl = this.lstPriceListItems.filter(x => x.ItemID == i && x.Quantity <= q);

        if (pl.length > 0) {
          pl.sort((a, b) => b.PriceListID - a.PriceListID);
          pl.sort((a, b) => b.Quantity - a.Quantity);
          row[Obj.unitPriceKey] = pl[0].UnitPrice;
          row.PriceListID = pl[0].PriceListID;
          row[Obj.amountKey] = q * row[Obj.unitPriceKey];
        }
      }
      else {
        let UnitPrice = 0;
        row.lstChild.forEach(ch => {
          let i = ch[Obj.itemKey];
          let q = Number(ch[Obj.qtyKey]);
          pl = this.lstPriceListItems.filter(x => x.ItemID == i && x.Quantity <= q);
          if (pl.length > 0) {
            pl.sort((a, b) => b.PriceListID - a.PriceListID);
            pl.sort((a, b) => b.Quantity - a.Quantity);
            ch[Obj.unitPriceKey] = pl[0].UnitPrice;
            ch.PriceListID = pl[0].PriceListID;
            ch[Obj.amountKey] = q * ch[Obj.unitPriceKey];
          }
          UnitPrice += Number(ch[Obj.unitPriceKey]) * (Number(ch[Obj.qtyKey]) / Number(row[Obj.qtyKey]));
        });
        row[Obj.unitPriceKey] = UnitPrice;
        row[Obj.amountKey] = Number(row[Obj.qtyKey]) * row[Obj.unitPriceKey];
      }
    }

    this.toReturnUnitPrice.emit(Obj);
  }

  alertWithConfirmBox(Obj: any) {
    if(!this.orgSettings.enablePricelistWarningMsg){
      this.setItemUnitPrice(Obj);
    }
    else{
    let index=this.alerteditemlist.findIndex(e=>e.index==Obj.index);
    if(index==-1){
    Swal.fire({
      title: 'Update Unit price',
      text: 'Do you want to update unit prices to follow the price list?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        this.setItemUnitPrice(Obj);
        if(Obj.callFrom != "contact" && Obj.callFrom != "date"){
          this.alerteditemlist.push({index:Obj.index,isshow:result.isConfirmed});
        }
        else if(Obj.callFrom == "contact" || Obj.callFrom == "date"){
          let val=this.lstPriceListItems.map(e=>e.ItemID);
          Obj.rowData.forEach((row :any,index)=>{
            if(val.includes(row.itemID)){
              this.alerteditemlist.push({index:index,isshow:true});
            }
          })
        }
      }
      else{
        if(Obj.callFrom != "contact" && Obj.callFrom != "date"){
        this.alerteditemlist.push({index:Obj.index,isshow:result.isConfirmed});
        }
        else if(Obj.callFrom == "contact" || Obj.callFrom == "date"){
          let val=this.lstPriceListItems.map(e=>e.ItemID);
          Obj.rowData.forEach((row :any,index)=>{
            if(val.includes(row.itemID)){
              this.alerteditemlist.push({index:index,isshow:false});
            }
          })
        }
      }
    })
  }
  else{
    let IsShow=this.alerteditemlist[index].isshow;
    if(IsShow){
      this.setItemUnitPrice(Obj);
    }
  }
  }
  }
}
