import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { required } from '@rxweb/reactive-form-validators';
import { ValidEmail } from '../../../utilities/_directives/customvalidator.directive';
import { HttpServices } from '../../../utilities/_services/http.service';
import { environment } from '../../../../environments/environment';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { HttpParams } from '@angular/common/http';
import { EncryptDecryptService } from '../../../utilities/_services/encryptdecrypt.service';
import { MasterServices } from '../../../utilities/_services/master.service';
import { TranslateService } from '@ngx-translate/core';
import { MalaysiaStatecode } from '../../../utilities/_models/malaysia-statecode';
import { EinvoiceServices } from '../../../utilities/_services/einvoice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  @ViewChild("OnBoardModalFormDetail") OnBoardModalFormDetail: ModalDirective;
  currentUser: any;
  categoryvalue: any = '1';
  close: boolean = true;
  constructor(public local: LocalCacheServices, private formBuilder: FormBuilder, private el: ElementRef, private http: HttpServices,
    private alertMessage: AlertMessage, private encdec: EncryptDecryptService, private master: MasterServices, private translate: TranslateService,
    private einvoiceService: EinvoiceServices
  ) {    
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
  }
  OnBoardForm: FormGroup;
  submitted: boolean = false;
  MISCcodes: any = [];
  Country: any = [];
  State: any = MalaysiaStatecode.State
  supplierdetails: any = [];
  ShowPage1: boolean = false;
  ShowPage2: boolean = false;
  orgSettings: any
  isProduction: boolean;
  imgsrc:any="../assets/img/einvoicelogo.png";
  supplierregtype: any = [{ name: 'BRN', value: 1, key: 'BRN' }, { name: 'NRIC', value: 2, key: 'NRIC' },
  { name: 'ARMY', value: 3, key: 'ARMY' }, { name: 'Passport', value: 4, key: 'PASSPORT' }]
  async ngOnInit() {
    this.OnBoardForm = this.formBuilder.group({
      supplierName: ['', Validators.required],
      supplierTIN: ['', Validators.required],
      supplierRegNo: ['', Validators.required],
      supplierTourNo: [''],
      supplierSSTNo: [''],
      supplierEmail: ['', [Validators.required, Validators.pattern(ValidEmail.pattern)]],
      SupplierMISCCode: ['00000', Validators.required],
      SupplierActDescription: ['', Validators.required],
      SupplierAddress: ['', Validators.required],
      SupplierCity: ['', Validators.required],
      SupplierState: ['00', Validators.required],
      SupplierCountry: [{ value: 'MY', disabled: true }, Validators.required],
      SupplierPostalZipCode: ['', Validators.required],
      SupplierContactNo: ['', Validators.required],
      SupplierRegNotype: ['BRN', Validators.required],
      Acknowledgement: [false, Validators.required],
      AccessPointID: [0, [Validators.min(1)]]
    });
    let MISCcodes: any = await this.master.getMISCcodes();
    this.Country = await this.master.getCountries();
    this.MISCcodes = MISCcodes.responseData;
    this.isProduction = environment.production;
    this.GetOnboardingDetails();
  }
  closeModal() {
    this.imgsrc=''
    this.OnBoardModalFormDetail.hide();
    this.ShowPage1 = false;
    this.ShowPage2 = false;
    localStorage.setItem("MyOnBoardModal"+this.currentUser.centralOrgID.toString(), this.encdec.encode(true));
  }
  openModal() {
    this.imgsrc="../assets/img/einvoicelogo.png";
    this.OnBoardModalFormDetail.show();
    this.ShowPage1 = true;
  }
  get f() {
    return this.OnBoardForm.controls;
  }
  closeOnBoardModalFormDetail() {
    this.ShowPage2 = false;
    this.ShowPage1 = true;
  }
  OpenOnBoardModalFormDetail() {
    this.ShowPage1 = false;
    this.ShowPage2 = true;
  }
  onsupplierRegnotypechange() {
    this.OnBoardForm.patchValue({ supplierRegNo: '' });
  }
  async onSubmit(Value: any) {
    this.submitted = true;    
    if (this.OnBoardForm.invalid) {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
      firstInvalidControl.focus();
      return;
    }
    let onboardingDetails = {
      supplierName: this.OnBoardForm.getRawValue().supplierName,
      supplierTIN: this.OnBoardForm.getRawValue().supplierTIN,
      supplierRegNo: this.OnBoardForm.getRawValue().supplierRegNo,
      supplierTourNo: this.OnBoardForm.getRawValue().supplierTourNo,
      supplierSSTNo: this.OnBoardForm.getRawValue().supplierSSTNo,
      supplierEmail: this.OnBoardForm.getRawValue().supplierEmail,
      SupplierMISCCode: this.OnBoardForm.getRawValue().SupplierMISCCode,
      SupplierActDescription: this.OnBoardForm.getRawValue().SupplierActDescription,
      SupplierAddress: this.OnBoardForm.getRawValue().SupplierAddress,
      SupplierContactNo: this.OnBoardForm.getRawValue().SupplierContactNo,
      SupplierRegNotype: this.OnBoardForm.getRawValue().SupplierRegNotype,
      Acknowledgement: this.OnBoardForm.getRawValue().Acknowledgement,
      SupplierPostalZipCode: this.OnBoardForm.getRawValue().SupplierPostalZipCode,
      SupplierCity: this.OnBoardForm.getRawValue().SupplierCity,
      SupplierState: this.OnBoardForm.getRawValue().SupplierState,
      SupplierStateName: this.State.filter(e => e.Code == this.OnBoardForm.getRawValue().SupplierState)[0].State,
      SupplierCountry: this.OnBoardForm.getRawValue().SupplierCountry,
      Category: this.categoryvalue,
      AccessPointID: this.OnBoardForm.getRawValue().AccessPointID,
    };
    this.SubmitDetail(onboardingDetails, Value);
  }
  async SubmitDetail(onboardingDetails: any, val: any) {
    let reqparams = new HttpParams();
    reqparams = reqparams.set('onboardingDetails', JSON.stringify(onboardingDetails));
    this.http.postservice(environment.organizationApiUrl + '/Organization/SaveEinvoiceOnboarding', reqparams).subscribe({
      next: async (data: any) => {
        if (data.isSuccess) {
            let orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
            orgSettings.accessPointID=this.OnBoardForm.value.AccessPointID;
            localStorage.setItem(this.local.localCurrentOrganization, this.encdec.encode(orgSettings));
              this.successpopup();
              if(this.OnBoardForm.getRawValue().AccessPointID==1){
                let params = new HttpParams();
                params = params.set('customerName', this.OnBoardForm.getRawValue().supplierName);
                this.http.postservice(environment.organizationApiUrl + '/Organization/sendEinvoiceConfirmationMail', params).subscribe({
                  next: async (data: any) => {}})
              }
        }
      }
    });
  }

successpopup(){
  if(this.OnBoardForm.value.AccessPointID=="3"){
  Swal.fire({
    title: 'E-invoice onboarding process is completed successfully!',
    icon: 'success',
    html: `<b>Next step:</b> Add Info-Tech as an intermediary on your MyInvois portal.
    <br> 
    Please download the MyTax payer setup guidance file here and proceed <a href="https://accountsstorage.blob.core.windows.net/container-public/maintanenceNote/Setup_taxpayer_profile.pdf.pdf" target="_blank">click here</a>.`,
    confirmButtonText: 'Proceed',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    allowOutsideClick: false,
  }).then((result) => {
    if (result.value) {
      window.open("https://mytax.hasil.gov.my/", "_blank");
    }
    this.closeModal();
  });
}
else{
  Swal.fire({
    title: 'E-invoice onboarding process is completed successfully!',
    icon: 'success',
    html: this.OnBoardForm.value.AccessPointID=="1"?`<b>Info-tech will setup the E-invoice will notify you within 24 hours.</b>`:"",
    confirmButtonText: 'Ok',
    showCancelButton: false,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.value) {
      this.closeModal();
    }
  });
}
}


  isredirectfromsettings: boolean = false;
  openmodalforview() {
    this.imgsrc="../assets/img/einvoicelogo.png";
    this.OnBoardModalFormDetail.show();
    this.ShowPage2 = true;
    this.isredirectfromsettings = true
    this.GetOnboardingDetails();

  }

  GetOnboardingDetails() {
    this.http.getservice(environment.organizationApiUrl + '/Organization/GetOnboardingDetails').subscribe({
      next: (data: any) => {
        this.supplierdetails = data.responseData ? JSON.parse(data.responseData) : [];
        if (this.supplierdetails.length > 0) {
          this.OnBoardForm.patchValue({
            supplierName: this.supplierdetails[0].SupplierName,
            supplierTIN: this.supplierdetails[0].SupplierTIN,
            supplierRegNo: this.supplierdetails[0].SupplierRegNo,
            supplierTourNo: this.supplierdetails[0].SupplierTourismRegNo,
            supplierSSTNo: this.supplierdetails[0].SupplierSSTRegNo,
            supplierEmail: this.supplierdetails[0].SupplierEmail,
            SupplierMISCCode: this.supplierdetails[0].SupplierMISCcode,
            SupplierActDescription: this.supplierdetails[0].SupplierActivityDesc,
            SupplierAddress: this.supplierdetails[0].SupplierAddress,
            SupplierCity: this.supplierdetails[0].SupplierCityName,
            SupplierState: this.supplierdetails[0].SupplierStateCode,
            SupplierCountry: this.supplierdetails[0].SupplierCountryCode,
            SupplierPostalZipCode: this.supplierdetails[0].SupplierPostalZipCode,
            SupplierContactNo: this.supplierdetails[0].SupplierContactNumber,
            SupplierRegNotype: this.supplierdetails[0].SupplierRegNotype,
            Acknowledgement: true,
            AccessPointID: this.supplierdetails[0].AccessPointID
          })
          if (this.isredirectfromsettings) {
            this.OnBoardForm.disable();
            if (this.currentUser.userType == 2) {
              this.OnBoardForm.get('AccessPointID').enable();
            }
          }
          this.categoryvalue = this.supplierdetails[0].CategoryNo.toString()
        }
      }
    });
  }
}
