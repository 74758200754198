import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-nav-custom',
  templateUrl: './customsidebarnav.component.html',
  styleUrls: ['./customsidebarnav.component.scss']
})
export class CustomsidebarnavComponent {
  @Input() navItems: Array<any>;
  @HostBinding('class.sidebar-nav') true;
  @HostBinding('attr.role') role;
  parentPageID = 0;
  constructor(private router: Router) {
  }

  ngOnInit(): void {

  }

  isDivider(navItem) {
    return !!navItem.divider
  }

  isTitle(navItem) {
    return !!navItem.title
  }

  isHasChild(navItem) {
    return navItem.hasOwnProperty('children') && navItem.children.length > 0;
  }

  redirect(url: any, params: any) {
    // this.router.navigate([url], {  queryParams: {  templategroup: params } });
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url], { queryParams: { templategroup: params } }));
  }
}
