import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpServices } from '../../../utilities/_services/http.service';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as moment from "moment";
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PageServices } from '../../../utilities/_services/page.service';
import { CustomValidator } from '../../../utilities/_directives/customvalidator.directive';
import { ExchangerateRoundUp, removingTrailingZeros } from '../../../utilities/_common/commonfunctions';

@Component({
  selector: 'edit-exchange-rates',
  templateUrl: './edit-exchange-rates.component.html',
  styleUrls: ['./edit-exchange-rates.component.scss']
})
export class EditExchangeRatesComponent implements OnInit {
  exchangeRateForm: FormGroup | any;
  lstEexchangeRate: any;
  Exsubmitted: boolean = false;
  loading = false;
  orgCurrencyCode: string;
  exchangeDate: any;
  ExchangeRate: string = "";
  CurrencyCode: any = 0;
  currencyTooltip: any = "";
  currencyTypeID: any = 0;
  FxRate: number = 0;
  billTotal: Number = 0;
  oldExValue: any;
  BillDate: any;
  fnRemovingTrailingZeros = removingTrailingZeros;
  
  constructor(private http: HttpServices,public datepipe: DatePipe, private page: PageServices,
    private formBuilder: FormBuilder) { }
  @ViewChild('showExchangeRatemodal') public showExchangeRatemodal: ModalDirective;
  @Output() sentToParent = new EventEmitter<String>();
  
  get f() { return this.exchangeRateForm.controls; }

  ngOnInit(): void {
    this.exchangeRateForm = this.formBuilder.group({
      customRate: [null, CustomValidator.required],
      pageID: [0]
    });
    this.lstEexchangeRate = [];
  }

  /*  */
  getExchangeData(lstRate:any, billDate,oCurrencyCode,newCurrency){
    this.exchangeDate = billDate;
    this.lstEexchangeRate = lstRate;
    this.orgCurrencyCode = oCurrencyCode;
    this.CurrencyCode = newCurrency;
    this.showExchangeRatemodal.show()
  }

  saveExchangeRate() {
    let index = this.lstEexchangeRate.findIndex(x => x.isSelected == true && x.isCustom == true);
    this.Exsubmitted = true;
    if (index >= 0) {
      if (this.exchangeRateForm.invalid) {
        if (this.f.customRate.status == "INVALID") {
          return;
        }
      }
    }
    let isLive = false;
    this.Exsubmitted = false;
    this.loading = true;
    let customRate: number = 0;
    if (index >= 0) {
      customRate = parseFloat(this.exchangeRateForm.value.customRate)
    }
    else {
      let liveindex = this.lstEexchangeRate.findIndex(x => x.isSelected == true && x.isCustom == false && x.isLive == true);
      if (liveindex >= 0) {
        isLive = true;
        customRate = parseFloat(this.lstEexchangeRate[liveindex].unitsper)
      }
      else {
        this.showExchangeRatemodal.hide();
        this.loading = false;
        return
      }

    }
    let newValue = {
      orgCurrencyCode: this.orgCurrencyCode,
      trnStartDate: this.datepipe.transform(this.exchangeDate, 'yyyy-MM-dd'),
      trnEndDate: this.datepipe.transform(this.exchangeDate, 'yyyy-MM-dd'),
      customRate: customRate,
      toCurrencyCode: this.CurrencyCode,
      isLive: isLive
    }
    let payload = { OldValue: this.oldExValue, NewValue: newValue, PageID: this.page.pageDetails.pageID };
    this.http.postservice(environment.organizationApiUrl + '/CurrencySetting/SaveUpdateExchangeRate', payload).subscribe({
      next: (data) => {
        this.exchangeRateForm.patchValue({ customRate: '' })
        this.showExchangeRatemodal.hide();
        this.loading = false;
        this.showExchangeRatemodal.hide();
        this.sentToParent.emit(String(newValue.customRate));
      }
    });    

  }
  
  rateRoundUp() {
    let rate= ExchangerateRoundUp(this.exchangeRateForm.value.customRate);
    this.exchangeRateForm.patchValue({ customRate:rate});
 
   }

  selectCustom(j: number) {
    for (let i = 0; i < this.lstEexchangeRate.length; i++) {
      if (i == j) {
        if (this.lstEexchangeRate[i].isCustom == false) {
          this.exchangeRateForm.patchValue({ customRate: '' })
        }
        this.lstEexchangeRate[i].isSelected = true;
      }
      else {
        this.lstEexchangeRate[i].isSelected = false;
      }
    }

    this.oldExValue = {
      orgCurrencyCode: this.orgCurrencyCode,
      trnStartDate: this.datepipe.transform(this.exchangeDate, 'yyyy-MM-dd'),
      trnEndDate: this.datepipe.transform(this.exchangeDate, 'yyyy-MM-dd'),
      customRate: parseFloat(this.lstEexchangeRate[j].unitsper),
      toCurrencyCode: this.lstEexchangeRate[j].toCurrencyCode,
      isLive: this.lstEexchangeRate[j].isLive
    }

  }

  numberFormatToFixed(val: Number) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    });
    let toFixed: Number = val ? val : 0;
    return formatter.format(Number(toFixed))
  }

}
