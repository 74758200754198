import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CustomTxtValidator } from '../../../../utilities/_directives/customvalidator.directive';
import { AgeingPeriods } from './model';

@Component({
  selector: 'app-ageing-periods',
  templateUrl: './ageing-periods.component.html',
  styleUrls: ['./ageing-periods.component.scss']
})

export class AgeingPeriodsComponent implements OnInit {
  
  @Input() sendTitle: string = "Aging Periods";
  @Input() periods: AgeingPeriods;
  @Input() showmodal: string = 'hide';

  @Output() hidemodal = new EventEmitter();
  @Output() ageingPeriods = new EventEmitter<AgeingPeriods>();

  ageingPeriodsForm: FormGroup | any;
  periodvalidrange:boolean = false;
  periodvaluerange:boolean = false;
  submitted:boolean = false;

  Periodtype = [{
    value: 'Day'
  }, {
    value: 'Week'
  }, {
    value: 'Month'
  }
  ]

  @ViewChild('showmodal') public showModal: ModalDirective;
  
  constructor(private formBuilder: FormBuilder ) { }

  ngOnInit(): void {
    this.emailFormValues()
  }
  ngOnChanges() {
    if (this.showmodal == 'show') {
      this.periodvalidrange = false;
      this.periodvaluerange = false;
      this.showModal.show();
      this.ageingPeriodsForm.patchValue(this.periods);
    }
  }
  get f() { return this.ageingPeriodsForm.controls; }
  emailFormValues() {
    this.ageingPeriodsForm = this.formBuilder.group({
       interval:  ['', CustomTxtValidator.required],
       duration:  ['', CustomTxtValidator.required],
       ageType:   ['']
    });
  }

  onSubmit() {
    if(this.periodvaluerange  || this.periodvalidrange ) 
      return;
    let ageingPeriods =  this.ageingPeriodsForm.value
    this.ageingPeriods.emit(ageingPeriods);
    this.CloseModel();
  }

  CloseModel() {
    this.showmodal = 'hide';
    this.showModal.hide();
    this.hidemodal.emit(this.showmodal);
  }

  periodValid(period:number)
  {
    if(period == 0 || 12<period)
    {
      this.periodvalidrange = true;
    }
    else{
      this.periodvalidrange = false;
    }
  }
  periodtypeValid(periodvalue:number)
  {
    if(periodvalue == 0 || 999<periodvalue)
    {
      this.periodvaluerange = true;
    }
    else{
      this.periodvaluerange = false;
    }
  }

}
