import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../utilities/_services/authentication.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../utilities/_services/loader.service';
import { LocalCacheServices } from '../../utilities/_services/acclocalcache.service';
import { AccsessioncacheService } from '../../utilities/_services/accsessioncache.service';
import { Menunav } from '../../utilities/_models/menunav'
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { PageServices } from '../../utilities/_services/page.service';
import { AlertMessage } from '../../utilities/_helpers/alert.message'
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { DefaultValueAccessor } from '@angular/forms';
import { DocumentInterruptSource, Idle, StorageInterruptSource } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MasterServices } from '../../utilities/_services/master.service';

@Component({
  selector: 'app-supportdefaultlayout',
  templateUrl: './supportdefaultlayout.component.html'
})
export class SupportdefaultlayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public sidenav: Menunav[] = [];
  public topnav: any = [];

  currentUser: any;
  horizantalmenus: any;
  activePageId: number = null;
  parentPageID: number;
  pageID: number;
  routerurl: string;

  usermanualurl:string = "https://support.infotech-accounting.com";

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  routeEventSubscription: any;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(private authenticationService: AuthenticationService, private bnIdle: BnNgIdleService,
    public translate: TranslateService, public loader: LoaderService, public local: LocalCacheServices,
    public session: AccsessioncacheService, private alertMessage: AlertMessage,
    private page: PageServices, private router: Router,
    private idle: Idle, private keepalive: Keepalive,private master: MasterServices) {
  }

  ngOnInit(): void {
    this.usermanualurl =  environment.production ? "https://support.infotech-accounting.com": "http://localhost:4200";;
    window.addEventListener('storage', function (e) {
      if (e.key === "IkN1cnJlbnRVc2VyIg==") // login session key
      {
        // window.location.reload();
        window.location.href = window.location.origin + '/supportdashboard';
        window.location.reload();
      }
    }, false);

    this.pageInitLoad();
    this.master.getOrgCountryDetails().then((data: any) => {
      this.usermanualurl = this.usermanualurl + "?cid=" +  btoa(data.ResponseData);
    })

    const original = DefaultValueAccessor.prototype.registerOnChange;

    DefaultValueAccessor.prototype.registerOnChange = function (fn) {
      return original.call(this, value => {
        const trimmed = typeof (value) === 'string' ? value.trim() : value;
        return fn(trimmed);
      })
    }
  }

  setupIdle() {
    console.log("Loaded Datetime : ", new Date());

    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(environment.idleTimeOut);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(30);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts([new DocumentInterruptSource("keydown DOMMouseScroll touchmove scroll click"), new StorageInterruptSource()]);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
      this.reset();
    });

    // this.idle.onInterrupt.subscribe(() => {
    //   this.idleState = 'Watching has interrupted.'
    //   console.log(this.idleState, " : ", new Date(), " : ", this.router.url );
    // });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
      this.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.alertMessage.clearAlertbox();
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
      this.childModal.show();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will be logged out in ' + countdown + ' seconds!'
      // console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  ngAfterContentChecked() {
    if (this.routerurl != this.router.url) {
      this.alertMessage.clearNotifier();
      this.routerurl = this.router.url;
      this.checkMenuViewAccees();
    }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  reset() {
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    if (this.currentUser != null) {
      this.idle.watch();
      this.idleState = 'Watching Started.';
      this.timedOut = false;
      if (this.childModal)
        this.childModal.hide();
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
    }
    // else {
    //   this.idle.stop();
    // }
  }

  stay() {
    this.childModal.hide();
    this.reset();
    // this.router.navigateByUrl(this.router.url);
    window.location.reload();
  }

  logout() {
    this.childModal.hide();
    this.idle.stop();
    this.idleState = 'logout'
    console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
    this.routeEventSubscription.unsubscribe();
    this.alertMessage.clearNotifier();
    this.authenticationService.logout();
  }

  pageInitLoad() {
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    if (this.currentUser != null) {
      this.menusplit();
      this.setupIdle();

      this.routeEventSubscription = this.router.events.subscribe(async (val) => {
        if (val instanceof NavigationEnd) {
          let timeout = this.authenticationService.getTokenTime();
          if (timeout <= 180 && timeout > 0) {
            await this.authenticationService.refreshToken();
          }
          this.authenticationService.startRefreshTokenTimer();
          this.reset();
        }
      });
    }
    else {
      this.logout();
    }
  }

  menusplit() {
    this.sidenav = this.currentUser.sideMenuList;

    let alltopnav = this.currentUser.menuList.filter(x => x.menuType == "topnav");
    let parenttopnav = alltopnav.filter(x => x.parentPageID == 0);
    for (let i = 0; i < parenttopnav.length; i++) {
      let childmenu = alltopnav.filter(x => x.parentPageID == parenttopnav[i].pageID);
      parenttopnav[i].childMenu = childmenu
    }
    this.topnav = parenttopnav;
  }

  checkMenuViewAccees() {
    this.page.setPageDetails();
    this.page.setAccessRights();
    this.horizantalmenus = [];
    this.activePageId = 0;

    if (this.router.url != "/supportdashboard" && this.router.url != "/dashboard") {
      if (this.page.accessRights.canView == true) {
        let childpages = this.currentUser.menuList.filter(x => x.pageID == this.page.pageDetails.parentPageID);
        if (childpages.length > 0) {
          this.horizantalmenus = childpages[0].childMenu;
          if (this.page.pageDetails.menuType == "function") {
            this.activePageId = this.page.pageDetails.dependantPageID;
          }
          else {
            this.activePageId = this.page.pageDetails.pageID;
          }
        }
      }
      else {
        //alert("You are not allowed to use this screen");
        this.alertMessage.alertWithCustomizeError({ message: this.translate.instant("Common.You are not allowed to use this screen") });
        this.router.navigate(['dashboard']);
      }
    }
  }
}
