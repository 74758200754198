export class Report {

}

export enum saveAsType {
Draft = 'Draft',
Published= 'Published',
Archive= 'Archive',
Filled= 'Filled'

}


export interface Node {
    data:any;
    children?: Node[];
  }



  export interface FlatNode {
    expandable: boolean;
    data:any;
    level: number;
  }

export enum CompareType {
    month = 1,
    quarter = 2,
    year = 3,
    day = 4
}
