<div bsModal #showItemmodal="bs-modal" class="modal fade modal-fullscreen" tabindex="-1" role="dialog"
    aria-labelledby="showItemmodalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header pb-0">
                <div class="col-md-2">
                    <h6>{{'InventoryModule.Item.Item Historical Info' | translate}}</h6>
                </div>
                <div class="col-md-6">
                    <button id="btnHideItemModal-1" type="button" class="close" (click)="hideItemmodal()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div class="custom-ag-grid-4">
                    <ag-grid-angular style="width: 100%;" class="ag-theme-material" [modules]="modules"
                        [columnDefs]="columnDefs" [rowData]="rowData" [gridOptions]="gridOptions"
                        (gridReady)="onGridReady($event)" (gridSizeChanged)="onGridSizeChanged($event)" (cellClicked)="onCellClick($event)"
                        [domLayout]="domLayout">
                    </ag-grid-angular>
                </div>
            </div>
            <div class="modal-footer">
                <button id="btnHideItemModal-2" class="btn btn-danger" type="button"
                    (click)="hideItemmodal()">{{'Common.Close' | translate}}</button>
            </div>
        </div>
    </div>
</div>