<div bsModal #showreconciliationmodal="bs-modal" class="modal fade modal-fullscreen" tabindex="-1" role="dialog"
  aria-labelledby="showreconciliationmodalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'BankingModule.BankAccounts.Reconciled transaction details' | translate}}</h4>
        <button type="button" class="close" (click)="showreconciliationmodal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 mb-4">
            <div class="d-flex justify-content-between py-2 mb-3">
              <!-- <button *userCanDelete="[true]" class="btn btn-danger" type="button" (click)="deletestmt()">
                Delete
              </button> -->
              <!-- <button class="text-success t-link edit d-none">View Statement</button> -->
              <!-- <span class="text-success t-link edit">Statement Line</span> -->
              <div class="col-12 text-center">
                <b>{{'BankingModule.BankAccounts.Statement Line' | translate}}</b>
              </div>
            </div>
          
            <div>
                <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [modules]="modules"
                  [columnDefs]="columnDefsStmtLines" [rowData]="rowDataStmt" [gridOptions]="stmtLineGridOptions"
                  [singleClickEdit]="true" [stopEditingWhenGridLosesFocus]="true"
                  [frameworkComponents]="frameworkComponents" rowSelection="single"
                  (gridSizeChanged)=" ($event)" [animateRows]="true"
                  [overlayLoadingTemplate]="overlayLoadingTemplate" [domLayout]="domLayout">
                </ag-grid-angular>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            
            <div class="d-flex justify-content-between py-2">
            <button class="btn btn-danger" [disabled]="!showRmRdOps" (click)="deleteAccountTrans()">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{'BankingModule.BankAccounts.Remove & Redo' | translate}}</button>
             <!-- <button class="text-success t-link edit d-none">Reconcilation Report</button> -->
             <!-- <span class="row col-12 text-center">Payments for Reconcilation</span> -->
            <div class="col-9 text-center">
              <b>{{'BankingModule.BankAccounts.Payments for Reconciliation' | translate}}</b>
            </div>
              </div>
            <div>
                <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [modules]="modules"
                  [columnDefs]="columnDefsAdjustment" [rowData]="rowDataAdj" [gridOptions]="adjGridOptions"
                  [singleClickEdit]="true" [stopEditingWhenGridLosesFocus]="true"
                  [frameworkComponents]="frameworkComponents" rowSelection="single" (cellClicked)="onAdjustmentCellClicked($event)"
                  (gridSizeChanged)="onGridSizeChanged($event)" [animateRows]="true"
                  [overlayLoadingTemplate]="overlayLoadingTemplate" [domLayout]="domLayout">
                </ag-grid-angular>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger" type="button" (click)="showreconciliationmodal.hide();">{{'Common.Close' | translate}}</button>
      </div>
    </div>
  </div>
</div>

