var globalOffset = require("global-offset"),
  resize = require("resize-event");


export class floatingScroll {

  static scroll(element) {
    document.getElementById("horizontalScroll")?.remove();
    document.getElementById("horizontalScrollinner")?.remove();
    var offset = globalOffset(element),
      scrollbar = document.createElement("div"),
      inner = document.createElement("div");
    inner.id = 'horizontalScrollinner'
    element.style.overflowX = "scroll";
    element.parentNode.insertBefore(scrollbar, element);
    scrollbar.id = "horizontalScroll";
    scrollbar.style.backgroundColor = "transparent";
    scrollbar.style.position = "fixed";
    scrollbar.style.bottom = '50px';
    scrollbar.style.left = offset.left + "px";
    scrollbar.style.height = "20px";
    scrollbar.style.width = element.getBoundingClientRect().width + "px";
    scrollbar.style.overflowX = "scroll";
    scrollbar.style.overflowY = "hidden";
    if (element.getBoundingClientRect().width == element.children[0]?.getBoundingClientRect()?.width) {
      document.getElementById("horizontalScroll")?.remove();
      return
    }

    scrollbar.appendChild(inner);
    inner.style.height = "1px";
    inner.style.width = element.children[0]?.getBoundingClientRect()?.width + "px";
    resize(element.children[0], function () {
      inner.style.width = element.children[0].getBoundingClientRect().width + "px";
    });

    scrollbar.onscroll = function () {
      element.scrollLeft = scrollbar.scrollLeft;
    };
    element.onscroll = function () {
      scrollbar.scrollLeft = element.scrollLeft;
    };
    if (true) {
      scrollbar.classList.add("style-bar");
      element.classList.add("style-bar");
      // this.customCss("#c0c0c0");
    }

    window.onscroll = function () {
      scrollbar.style.display = element.getBoundingClientRect().height + offset.top <= window.scrollY + window.innerHeight ? "none" : "block";
      scrollbar.scrollLeft = element.scrollLeft;
    };
  };
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  static customCss(color) {
    var styleEl = document.createElement('style');
    styleEl.innerHTML = `.style-bar::-webkit-scrollbar-track
    {
       background-color:#ede9e9; }
      .style-bar::-webkit-scrollbar
       { width: 12px; background-color: #f1f1f1; }
       .style-bar::-webkit-scrollbar-thumb {


        background-color: `+ color + `; }`;
    document.head.appendChild(styleEl);
  }
}
floatingScroll.customCss("#c0c0c0");
// exports.customScrollBar = customScroll;
