<!-- <div style="height: 100vh;" class="bg-success" >
<div class="centered">
    <i class="fa fa-check-circle font-20" aria-hidden="true"></i>
    <h3>Your Payment was successful!</h3>
    <h1>We will {{replyTo}} email the receipt for your invoice</h1>
    <div class=""><span class="cursor-pointer mr-2">Close Tab</span>
    |<span class="cursor-pointer ml-2">Return to invoice</span></div>
</div>
</div> -->

<!-- Success Msg -->
<div class="centered" *ngIf="isSuccess">
    <div class="col-md-5 mx-auto">
        <div class="card text-center border-success">
            <div class="card-body text-success">
                <i class="fa fa-check-circle font-6xl " aria-hidden="true"></i>
                <h1>Your Payment was successful!</h1>
                <!-- <h3>We will {{replyTo}} email the receipt for your invoice</h3> -->
                <!-- <div class="mt-3"><span class="cursor-pointer mr-2 text-info">Close Tab</span>
                |<span class="cursor-pointer ml-2 text-info">Return to invoice</span></div> -->
                <div class="mt-4">
                    <a href="#" class="btn btn-danger mr-2">Close Tab</a>
                    <a class="btn btn-success text-white"  (click)="onBackToOnlineInv()">Return to invoice</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Failure Msg -->
<div class="centered" *ngIf="isSuccess==false">
    <div class="col-md-5 mx-auto">
        <div class="card text-center border-success">
            <div class="card-body text-danger">
                <i class="fa fa-check-circle font-6xl " aria-hidden="true"></i>
                <h1>Your Payment was not successful!</h1>
                <h3>{{statusMessage}}</h3>
                <!-- <div class="mt-3"><span class="cursor-pointer mr-2 text-info">Close Tab</span>
                |<span class="cursor-pointer ml-2 text-info">Return to invoice</span></div> -->
                <div class="mt-4">
                    <a href="#" class="btn btn-danger mr-2">Close Tab</a>
                    <a href="#" class="btn btn-success text-white" (click)="onBackToOnlineInv()">Return to invoice</a>
                </div>
            </div>
        </div>
    </div>
</div>


 
    
