<div class="form-group row">
  <label class="col-md-4 col-form-label mr-3" for="btnDeliveryAddress"
    *ngIf="ctrlOptions.showLabel">{{'Common.' + ctrlOptions.label | translate}}</label>
  <div class="row d-flex justify-content-start">
    <div class="col-md-5">
    <div  class="dropdown" dropdown>
      <div>
        <button dropdownToggle1 (click)="onclickTC()" (clickOutside)="termsandcondition = false"
         type="button" class="btn btn-light dropdown-toggle" 
          [disabled]="isDisabled">
          {{'Common.' + ctrlOptions.btnlabel | translate}}
        </button>
        <div style="color: red;" class="error_message ng-invalid">
          <span class="e_arrow"></span>
          <!-- <i>{{errorDOAddress}}</i> -->
        </div>
      </div>
     
      <ul  *ngIf="termsandcondition"  class=" w-100 border mh-x-300 p-0 position-absolute" role="menu" style="min-width:300px;background-color: #FFF;z-index:999;">
        <a class="dropdown-item" (click)="clearContactAddressDetails();termsandcondition=false;">{{'Common.None' | translate}}</a>
        <a class="dropdown-item" (click)="showDialog(null);termsandcondition=false;" *userCanEdit="[true]"><b class="mr-1">+</b> {{'PurchaseModule.PurchaseOrder.Add New' | translate}} {{'Common.' + ctrlOptions.btnlabel | translate}}</a>
        <input id="txtsearchtermsandcondition" type="text" class="form-control w-75 ml-3" [(ngModel)]="searchtext" (keyup)="search()" placeholder="{{'PurchaseModule.PurchaseOrder.Search Terms and Condition' | translate}}">


        <ng-container *ngFor="let terms of ctrlOptions.contactTCArray let index = index">
          <div class="dropdown-divider"></div>
          <div class="dropdown-item-div" (click)="onClikTermsModel(terms);termsandcondition=false;">
              <div class="w-95 text-wrap">
                  <b>{{ terms.termsTitle}}</b>
              </div>
              <div class="drop-down-edit" (click)="showDialog(terms);termsandcondition=false;">
                  <span class="material-icons-outlined">edit_note</span>
              </div>
              <div class="drop-down-edit" (click)="ondelete(terms)" *userCanEdit="[true]">
                  <span class="material-icons-outlined">delete</span>
              </div>
          </div>
        </ng-container>
        <div *ngIf="Nodata" class="dropdown-item-div" >
          <b style="color: #1f1b1b67;">{{'PurchaseModule.PurchaseOrder.No items found' | translate}}</b>
         </div>
      </ul>
    </div>
    </div>  
    <div class="pt-2" style="white-space: nowrap;" *ngIf="!ctrlOptions.showResultVertical">
      <div class="col-md-12 t-link mb-2">
        <b (click)="showDialog(selected)">
          {{selected?.termsTitle}}
        </b>
      </div>
    </div>
  </div>

</div>
<div class="row" *ngIf="ctrlOptions.showResultVertical">
  <div class="col-md-12 t-link mb-2">
    <b (click)="showDialog(selected)">
      {{selected?.termsTitle}}
    </b>
  </div>
  <div class="ms-2 overflow-auto mxh-150" *ngIf="ctrlOptions.isShowCondition" innerHTML="{{selected?.termsCondition}}">
  </div>
</div>

<app-add-contact-tc [TransactionTypeID]="ctrlOptions.TransTypeID" [ContactID]="ContactID"
  (getTC)="getContactsTermsCondition($event)"></app-add-contact-tc>
