import { Injectable, Inject } from '@angular/core';
import { Base64 } from 'js-base64';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {

  keySize = 256;
  ivSize = 128;
  saltSize = 256;
  iterations = 1000;
  //randomKeyValue: string | undefined = "F68A228A-8706-4A6D-99EF-703F49A5FB47";
  charstringArray = ['C', '2', '6', 'A', '4', '6', '4', '6', '-', 'D', '1', '1', 'C', '-', '4', '6', '0', 'B', '-', '9', 'C', '2', 'B', '-', 'A', 'C', '3', '5', '2', '9', 'C', 'F', 'A', '9', '1', 'F'];
  randomKeyValue: string | undefined;

  constructor() {
    this.randomKeyValue = this.charstringArray.join('');
  }

  encodeBase64(data: any) {
    var encrypted = Base64.encode(JSON.stringify(data)); //btoa(JSON.stringify(data));
    return encrypted.toString();
  }

  decodeBase64(data: any) {
    var decrypted = Base64.decode(data); //atob(data);
    return decrypted.toString();
  }

  encode(data: any) {
    var encrypted = this.encrypt(JSON.stringify(data), this.randomKeyValue);
    return encrypted.toString();
  }

  decode(data: any) {
    var decrypted = this.decrypt(data, this.randomKeyValue);
    return decrypted.toString();
  }

  responseencode(data: any) {
    var encrypted = this.encrypt(JSON.stringify(data), this.randomKeyValue, 'Response');
    return encrypted.toString();
  }

  responsedecode(data: any) {
    var decrypted = this.decrypt(data, this.randomKeyValue, 'Response');
    return decrypted.toString();
  }

  encrypt(msg: any, pass: any, callfrom?: any) {
    var salt = CryptoJS.lib.WordArray.random(this.saltSize / 8);

    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: this.keySize / 32,
      hasher: CryptoJS.algo.SHA1,
      iterations: this.iterations
    });

    var iv = CryptoJS.lib.WordArray.random(this.ivSize / 8);
    var encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    var encryptedHex = this.base64ToHex(encrypted.toString(), callfrom);
    var base64result = this.hexToBase64([salt, iv, encryptedHex].join(''), callfrom);
    return base64result;
  }

  decrypt(transitmessage: any, pass: any, callfrom?: any) {
    if (transitmessage != null && transitmessage != undefined && transitmessage != '') {
      var hexResult = this.base64ToHex(transitmessage, callfrom)

      var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
      var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
      var encrypted = this.hexToBase64(hexResult.substring(96), callfrom);

      var key = CryptoJS.PBKDF2(pass, salt, {
        keySize: this.keySize / 32,
        hasher: CryptoJS.algo.SHA1,
        iterations: this.iterations
      });

      var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
      })

      return decrypted.toString(CryptoJS.enc.Utf8);
    }
    else
      return '{"str":""}';
  }

    hexToBase64(str: any, callfrom: any) {
    if (callfrom == 'Response') {
      return btoa(String.fromCharCode.apply(null,
        str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
      );
    }
    else {
      return str;
    }
  }

  base64ToHex(str: any, callfrom: any) {
    if (callfrom == 'Response') {
      for (var i = 0, bin = atob(str), hex = []; i < bin.length; ++i) {
        var tmp = bin.charCodeAt(i).toString(16);
        if (tmp.length === 1) tmp = "0" + tmp;
        hex[hex.length] = tmp;
      }
      return hex.join("");
    }
    else {
      return str;
    }
  }
}
