<div bsModal #showmodal="bs-modal" class="modal fade" [config]="{ backdrop: 'static' }" tabindex="-1" role="dialog"
  aria-labelledby="showmodal" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <form class="form-horizontal" #reportFilterForm="ngForm" id="customSelect"
      (ngSubmit)="onSubmit(reportFilterForm)">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"> <span>
              <i class="fa fa-cog fa-2" aria-hidden="true"></i> {{title | translate}} 
              <button *ngIf="isCustomFilter" type="button" triggers="mouseenter:mouseleave" [popover]="popTemplateHtml"
              class="btn inforight pb-0">
             <i class="fa fa-info-circle"></i></button>
           <ng-template #popTemplateHtml>
             <div><span class="help-block text-info">{{'ReportsModule.Only custom fields will be listed in the filter dropdown' | translate}}</span>
             </div>
           </ng-template>
            </span> </h4>
          <button type="button" class="close" id="btnCloseModel" (click)="CloseModel()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body pb-0">
          <table class="table">
            <thead>
              <tr>
                <th colspan="4" class="pt-0" style="border-top: none;">
                  <span class="nobold">{{'ReportsModule.Use advanced filters to filter the report based on the fields of Reports, Item.' | translate}}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of columnsFilter; let idx = index">
                <td style="width: 30%;" class="form-group">
                  <ng-select [items]="columns" [id]="'filterName'+item.id" [name]="'filterName'+item.id"
                    class="auto-grow  mt-2" required bindLabel="title" groupBy="ReportType" [(ngModel)]="item.field"
                    (change)="loadFilterOption(item,idx)">
                  </ng-select>
                </td>
                <td style="width: 25%;" class="form-group">
                  <ng-select [items]="item.Options" [id]="'filterCondition'+item.id" [name]="'filterCondition'+item.id"
                    required class="auto-grow mt-2" bindLabel="field" bindValue="value" [(ngModel)]="item.condition">
                  </ng-select>
                </td>
                <td style="width: 40%;" class="form-group">
                  <span *ngIf="item?.field?.type == 'auto'">
                    <ng-select [id]="'autotext'+item.id" [name]="'autotext'+item.id" [items]="item.auto" class="mt-2"
                      required [virtualScroll]="true" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                      (add)="SelectChange($event,idx)" bindLabel="fieldName" placeholder="{{'Select' | translate}} {{item.field.title | translate}}"
                      [(ngModel)]="item.autovalue" maxSelectedItems="100">
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:2">
                          {{item.fieldName}}
                          <span class="ng-value-icon right" type="button" (click)="clear(item)"
                            aria-hidden="true">×</span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 2">
                          <span class="ng-value-label">{{items.length - 2}} more...</span>
                        </div>
                      </ng-template>
                    </ng-select>
                  </span>
                  <span *ngIf="item?.field?.type == 'date'" class="table-border-0">
                    <div *ngIf="item.condition == 'Between'" class="input-group custom-dp mt-2">
                      <input class="form-control" placeholder="dd/mm/yyyy" name="d1" [(ngModel)]="item.fromDate" ngbDatepicker id="txtstartDate" #txtstartDate maxlength="20" autocomplete="off" #d1="ngbDatepicker"
                      (click)="d1.toggle();" required (ngModelChange)="DateRangechange(item)">
                      <input *ngIf="item.condition == 'Between'" class="form-control" placeholder="dd/mm/yyyy" name="d2" [(ngModel)]="item.toDate" ngbDatepicker id="txtendDate" #txtendDate maxlength="20" autocomplete="off" #d2="ngbDatepicker" (click)="d2.toggle();" required (ngModelChange)="DateRangechange(item)">
                    </div>
                    <input *ngIf="item.condition != 'Between'" class="form-control mt-2" [(ngModel)]="item.dateSelect"
                      [id]="'dateText'+item.id" [name]="'dateText'+item.id" placeholder="dd/mm/yyyy" name="d2" date
                      ngbDatepicker required maxlength="10" autocomplete="off" #d2="ngbDatepicker" (click)="d2.toggle()"
                      (ngModelChange)="setDate(item.dateSelect,item)">
                  </span>
                  <input type="text" *ngIf="!(item?.field?.type == 'auto' || item?.field?.type == 'date')"
                    [pattern]="item.pattern" [id]="'filterText'+item.id" [name]="'filterText'+item.id"
                    class="form-control mt-2" [(ngModel)]="item.textvalue" required>
                  <span style="font-size: 12px;color: rgb(255, 72, 0);"
                    *ngIf="item?.field?.type.toLowerCase()=='number' && isNAN(item.textvalue)">
                    {{'ReportsModule.Please enter only numbers' | translate}}
                  </span>
                </td>
                <td style=" width: 5%;">
                  <ng-template #popHtml>
                    <div>
                      <span class="help-block text-warning">{{'ReportsModule.Remove Criterion' | translate}}</span>
                    </div>
                  </ng-template>
                  <a id="btnremoveFilter-{{idx}}" href="javascript:void(0);" style="color: red;"
                    (click)="removeFilter(idx)" [popover]="popHtml" triggers="mouseenter:mouseleave">
                    <mat-icon class="mt-3 ">highlight_off</mat-icon>
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">
                  <a id="btnAddFilter" href="javascript:void(0);" (click)="addFilter()">
                    <i class="fa fa-plus-circle fa-2" aria-hidden="true"></i> {{'ReportsModule.Add Filter' | translate}}
                  </a>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="modal-footer">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-md-1 pt-3 pl-4 pr-0 text-align:left">
                <a id="btnReset" href="javascript:void(0);" class="" (click)="reset()">
                  <i class="fa fa-refresh" aria-hidden="true"></i> {{'ReportsModule.Reset' | translate}} </a>
              </div>
              <div class="col-md-5 pt-3 pl-0 text-align:left">
                <a id="btnResetApply" href="javascript:void(0);" class="" (click)="resetApply()">
                  <i class="fa fa-refresh" aria-hidden="true"></i> {{'ReportsModule.Reset & Apply' | translate}} </a>
              </div>
              <div class="col-md-6 pt-2" style="text-align: end;">
                <button id="btnApply" type="submit" class="btn btn-info" [disabled]="loading">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  <i class="fa fa-filter"></i> {{'ReportsModule.Apply' | translate}}
                </button>
                <button id="btnCloseModel-2" type="button" class="btn btn-danger" type="button"
                  (click)="CloseModel()">{{'Common.Cancel' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
