<ng-select id="toBatch" style="min-width:250px" #selectToBatch [items]="selectList" [virtualScroll]="true" bindLabel="BatchNo" bindValue="BatchID"
    placeholder="Select Batch" [(ngModel)]="selectedBatch" (search)="onSearchForApi($event)"
    groupBy="WarehouseName"
    (change)="onChangeValue($event)" [searchFn]="customSearchFn" (clear)="onClear()" (focus)="onFocus()">
    <ng-template ng-optgroup-tmp let-item="item">
        {{item.WarehouseName || 'Unnamed group'}}
    </ng-template>
    <ng-template ng-header-tmp *ngIf="isNewBatchNo">
        <a class="t-link" (click)="saveNewBatch()"><b>+</b>Add New Batch
        </a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
        {{item.BatchNo }}
    </ng-template>
</ng-select>