export class filter {
  textFilterCondition: any = [
    {
      field: "Is",
      value: "Is"
    },
    {
      field: "Is Not",
      value: "IsNot"
    },
    {
      field: "Start With",
      value: "SW"
    },
    {
      field: "Ends With",
      value: "EW"
    },
    {
      field: "Contains",
      value: "Con"
    },
    {
      field: "Doesn't Contains",
      value: "DCon"
    }
  ]

  autoFilterCondition: any = [
    {
      field: "Is",
      value: "Is"
    },
    {
      field: "Is Not",
      value: "IsNot"
    }
  ]

  numberFilterCondition: any = [
    {
      field: "Is",
      value: "Is"
    },
    {
      field: "Is Not",
      value: "IsNot"
    },
    {
      field: "Less Than",
      value: "LT"
    },
    {
      field: "Less Than (Or) Equals",
      value: "LTE"
    },
    {
      field: "Greater Than",
      value: "GT"
    },
    {
      field: "Greater Than (Or) Equals",
      value: "GTE"
    }
  ];

  dateFilterCondition: any = [
    {
      field: "Between",
      value: "Between"
    },
    {
      field: "Is",
      value: "Is"
    },
    {
      field: "Less Than",
      value: "LT"
    },
    {
      field: "Less Than (Or) Equals",
      value: "LTE"
    },
    {
      field: "Greater Than",
      value: "GT"
    },
    {
      field: "Greater Than (Or) Equals",
      value: "GTE"
    }

  ]

}

export class filterResult {
  title: string;
  field: string;
  reportType:string;
  fieldID?:number;
  condition: string;
  text: any;
  datatype: string;
  values?:any;
}

export class AutoCompleteList {
  listid: string;
  field:string;
  autoCompleteList: []
}
