<!-- bsModal #showBackOrdermodal="bs-modal" class="modal fade" tabindex="-1" role="dialog"  [ngStyle]="{'display':showBackOrdermodal}"
    aria-labelledby="showBackOrdermodalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }" -->
    <!-- class="modal-dialog modal-lg" role="document" -->
<div >
  <div >
    <form class="form-horizontal needs-validation" novalidate="novalidate" >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Out of Stock Items</h4>
          <button type="button" class="close " (click)="hideBackOrdermodal()" >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <ng-container>
              <div class="mnh-30">
                <div class="">
                  {{'The following item(s) have a negative balance. Do you want to proceed?' | translate}}
                </div>
              </div>
          </ng-container>
        </div>
            <ag-grid-angular style="width: 100%;" class="ag-theme-material"
            [columnDefs]="columnDefs" [overlayLoadingTemplate]="overlayLoadingTemplate" [rowData]="rowData" domLayout="autoHeight"
            [suppressRowClickSelection]="true">
          </ag-grid-angular>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-normal mr-3" (click)="hideBackOrdermodal(true)">Yes</button>
          <button class="btn btn-light" type="button" (click)="hideBackOrdermodal()">No</button>
        </div>
      </div>
    </form>
    <!-- /.modal-content-->
  </div>
  <!-- /.modal-dialog-->
</div>
