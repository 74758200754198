<div bsModal #showExchangeRatemodal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="showExchangeRatemodalLabel" aria-hidden="true" data-coreui-backdrop="static"
    data-coreui-keyboard="false">
    <div class="modal-dialog  modal-dialog-scrollable" role="document">
        <form [formGroup]="exchangeRateForm">
            <div class="modal-content">
                <div class="modal-header">
                <h4 class="modal-title">Set Exchange Rate</h4>
                <button type="button" class="close" (click)="showExchangeRatemodal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <div class="row pb-2">
                    <div class="col-md-12">
                    <div class="custom-table-1">
                        <table class="table table-bordered">
                        <tbody>
                            <tr>
                            <td colspan="2">
                                <div class="form-group">
                                <label class="col-form-label" for="startDate">
                                    Date {{ exchangeDate | date : 'dd MMM yyyy' }}</label>
                                </div>
                            </td>

                            </tr>
                            <tr *ngFor="let c of lstEexchangeRate; let i = index">
                            <ng-container>
                                <td scope="row">
                                <input type="radio" id="custom{{i}}" name="custom" [value]="c.isSelected"
                                    [checked]="c.isSelected" (click)="selectCustom(i);">
                                </td>
                                <td *ngIf="c.isCustom == false">
                                {{c.currencyName}}
                                {{c.exchangeDate | date :"dd-MM-yyyy" }}
                                <br>

                                1 {{orgCurrencyCode}} = {{fnRemovingTrailingZeros(c.unitsper)}}
                                {{c.toCurrencyCode}}<br>
                                1 {{c.toCurrencyCode}} = {{fnRemovingTrailingZeros(c.perUnit)}}
                                {{orgCurrencyCode}}<br>
                                {{c.notes}}
                                </td>
                                <td *ngIf="c.isCustom == true">
                                <div class="input-group">
                                    1 {{orgCurrencyCode}} = &nbsp;
                                    <input type="text" maxlength="16" drExchangeRate formControlName="customRate"
                                    class="form-control form-control-sm" 
                                    [ngClass]="{ 'is-invalid': Exsubmitted && f.customRate.errors }"
                                    (click)="selectCustom(i);" />
                                    &nbsp;{{c.toCurrencyCode}}

                                    <div *ngIf="Exsubmitted && f.customRate.errors" class="invalid-feedback">
                                    <div *ngIf="f.customRate.errors.required">
                                        Rate is required</div>
                                    <div *ngIf="f.customRate.errors.pattern">
                                        Please provide valid rate</div>
                                    </div>
                                </div>
                                </td>
                            </ng-container>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
                <div class="modal-footer">
                <button *userCanEdit="[true]" [disabled]="loading" class="btn btn-success btn-normal mr-3"
                    (click)="saveExchangeRate()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button>
                <button class="btn btn-light  btn-normal" type="button"
                    (click)="showExchangeRatemodal.hide();">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>