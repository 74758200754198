import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MasterServices } from '../../../utilities/_services/master.service';
import { FormBuilder, FormGroup, FormArray, Validators, NgForm } from '@angular/forms';
import { CustomValidator } from '../../../utilities/_directives/customvalidator.directive';
import { environment } from '../../../../environments/environment'
import { HttpServices } from '../../../utilities/_services/http.service';
import { PageServices } from '../../../utilities/_services/page.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { TranslateService } from '@ngx-translate/core';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';

@Component({
  selector: 'app-addcurrency',
  templateUrl: './addcurrency.component.html',
  styleUrls: ['./addcurrency.component.scss']
})
export class AddcurrencyComponent implements OnInit {
  addCurrencyForm: FormGroup | any;
  loading = false;
  submitted = false;
  currencyTypeID: number;
  exchangeRate: any = "0.00"
  exchangeDate: any;
  orgCurrencyCode: string;
  statusMsgID: any;

  @ViewChild('showAddCurrencyModal') public showAddCurrencyModal: ModalDirective;
  @Output() sentToParent = new EventEmitter<String>();

  lstCurrencyType: any;
  constructor(private master: MasterServices, private formBuilder: FormBuilder, private http: HttpServices,
    private page: PageServices, private alertMessage: AlertMessage,private translate:TranslateService, private local: LocalCacheServices) {
    // this.exchangeDate = data.exchangeDate;
    // this.orgCurrencyCode = data.orgCurrencyCode;
    // this.loadCurrencyType();
  }

  async loadCurrencyType() {
    let unUsedCunnecyType: any = await this.master.getAllCurrencyTypes();
    this.lstCurrencyType = unUsedCunnecyType.filter(x => x.InUse == false && x.CurrencyCode != this.orgCurrencyCode)
  }

  getData(exchangeDate, orgCurrencyCode) {
    this.exchangeDate = exchangeDate;
    this.orgCurrencyCode = orgCurrencyCode;
    this.loadCurrencyType();
    this.submitted = false;
    this.showAddCurrencyModal.show()
  }

  ngOnInit(): void {
    this.addCurrencyForm = this.formBuilder.group({
      currencyTypeID: [null, CustomValidator.required],
      pageID: [0]
    });
  }
  get f() { return this.addCurrencyForm.controls; }

  getLiveExchangeRate() {
    let currencyTypeID = this.addCurrencyForm.value.currencyTypeID
    let selectedRate = this.lstCurrencyType.filter(x => x.CurrencyTypeID == currencyTypeID);
    let toCurrencyCode = selectedRate[0].CurrencyCode
    this.http.getservice(environment.organizationApiUrl + '/CurrencySetting/GetFixerExchangeRate?OrgCurrencyCode=' + this.orgCurrencyCode + '&ToCurrencyCode=' + toCurrencyCode + '&ExchangeDate=' + this.exchangeDate).subscribe({
      next: (data: any) => {
        // console.log(data);
        this.exchangeRate = data.rates ? data.rates[toCurrencyCode] : '0.00';
      },
      error: (err) => {
        this.statusMsgID = this.alertMessage.errorNotifier(this.translate.instant("OrgModlue.Currencies.Error while getting the exchange rate"), this.statusMsgID);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.addCurrencyForm.invalid) {
      return;
    }
    this.submitted = false;
    this.loading = true;
    this.addCurrencyForm.patchValue({
      PageID: this.page.pageDetails.pageID
    })
    this.http.postservice(environment.organizationApiUrl + '/CurrencySetting/UpdateUseCurrenyType', this.addCurrencyForm.value).subscribe({
      next: (data1) => {
        if (data1 > 0) {
          this.loading = false;
          this.sentToParent.emit(this.addCurrencyForm.value.currencyTypeID);
          this.addCurrencyForm.patchValue({
            currencyTypeID: null
          })
          this.exchangeRate ="0.00"
          this.showAddCurrencyModal.hide();
    
          this.http.awaitGetservice(environment.organizationApiUrl + '/Master/GetInUseCurrencies').then(data => {
            this.local.setlocalStorage(this.local.localInUseCurrencyTypes, data);
          });
        }
      },
      error: (err) => {
        this.statusMsgID = this.alertMessage.errorNotifier(this.translate.instant("OrgModlue.Currencies.Error while adding the currency"), this.statusMsgID);
      }
    });
  }

  closeModal() {
    this.addCurrencyForm.patchValue({
      currencyTypeID: null
    })
    this.exchangeRate ="0.00"
    this.showAddCurrencyModal.hide();
  }
}
