import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, CdkDragEnter } from '@angular/cdk/drag-drop';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { HttpServices } from '../../../utilities/_services/http.service';
import { DropInfo, EditLayoutNodeService, ELTreeFlatNode, ELTreeNode } from './edit-layout-node.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-edit-layout',
  templateUrl: './report-edit-layout.component.html',
  styleUrls: ['./report-edit-layout.component.scss']
})
export class ReportEditLayoutComponent implements OnInit {

  @ViewChild('showLayout') public showLayout: ModalDirective;
  @ViewChild('childModal') public childModal: ModalDirective;
  @ViewChild('switchRuleModal') public switchRuleModal: ModalDirective;
  @Output() closeModel = new EventEmitter<boolean>();
  @Input() pageCode;
  @Input() title = 'Layout Setting';
  enableSwitchFun = false;
  showCreateNode = false;
  balanceType;
  header;
  data: any = [];
  prevData;
  selectedID: string;
  nodeSelected: ELTreeNode;
  nodes: ELTreeNode[] = [];

  dropTargetIds = [];
  nodeLookup = {};
  dropActionTodo: DropInfo = null;
  isComplete = false;
  showMergeBtn = false;
  parent;
  accType = ['Credit', 'Debit'];

  dynamicColumn = [];
  dragging = false;
  expandTimeout: any;
  expandDelay = 1000;
  validateDrop = false;
  isEdited = false;
  currentUser: any;
  loading = false;
  LayoutType: string;
  showSaveChanges = false;
  existingGroupNames = [];
  nodeNegative
  ruleType = '0';
  ddGroupNode = [];
  IsSupportUser: boolean = false;
  // flatNodeMap = new Map<TreeNode, TreeNode>();
  flatNodeMap = new Map<ELTreeFlatNode, ELTreeNode>();
  nestedNodeMap = new Map<ELTreeNode, ELTreeFlatNode>();
  private _transformer = (node: ELTreeNode, level: number) => {
    return {
      expandable: !!node.Children && node.Children.length > 0,
      GroupName: node.GroupName,
      level: level,
      GroupID: node.GroupID,
      IsExpanded: node.IsExpanded,
      IsTotalRequired: node.IsTotalRequired,
      Children: node.Children,
      AccountType: node.AccountType,
      IsNode: node.IsNode,
      ParentGroupID: node.ParentGroupID,
      IsDebit: node.IsDebit,
      IsCredit: node.IsCredit,
      CAAccountID: node.CAAccountID,
      IsDragable: node.IsDragable,
      RowLevel: node.RowLevel,
      LayoutID: node.LayoutID,
      LayoutName: node.LayoutName,
      AccountName: node.AccountName,
      AccDisplayOrder: node.AccDisplayOrder,
      IsFormula: node.IsFormula,
      Formula: node.Formula,
      FormulaDesc: node.FormulaDesc,
      AccountCode: node.AccountCode,
      AccountTypeID: node.AccountTypeID,
      SystemCode: node.SystemCode,
      RowNo: node.RowNo,
      SwitchGroupID: node.SwitchGroupID,
      BankAccTypeID: node.BankAccTypeID
    };
  };

  transformer = (node: ELTreeNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.GroupID === node.GroupID ? existingNode : new ELTreeFlatNode();
    flatNode.expandable = this.parseValue(node.IsNode);
    flatNode.GroupName = node.GroupName;
    flatNode.level = level;
    flatNode.GroupID = node.GroupID;
    flatNode.IsExpanded = node.IsExpanded;
    flatNode.IsTotalRequired = node.IsTotalRequired;
    flatNode.AccountType = node.AccountType;
    flatNode.IsNode = node.IsNode;
    flatNode.ParentGroupID = node.ParentGroupID;
    flatNode.IsDebit = node.IsDebit;
    flatNode.IsCredit = node.IsCredit;
    flatNode.CAAccountID = node.CAAccountID;
    flatNode.IsDragable = node.IsDragable;
    flatNode.RowLevel = node.RowLevel;
    flatNode.LayoutID = node.LayoutID;
    flatNode.LayoutName = node.LayoutName;
    flatNode.level = level;
    flatNode.Formula = node.Formula;
    flatNode.AccountTypeID = node.AccountTypeID;
    flatNode.IsFormula = node.IsFormula;
    flatNode.FormulaDesc = node.FormulaDesc
    flatNode.SystemCode = node.SystemCode
    flatNode.AccountName = node.AccountName;
    flatNode.RowNo = node.RowNo;
    flatNode.AccDisplayOrder = node.AccDisplayOrder;
    flatNode.SwitchGroupID = node.SwitchGroupID;
    flatNode.BankAccTypeID = node.BankAccTypeID;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  treeControl: FlatTreeControl<ELTreeFlatNode>;
  treeFlattener: MatTreeFlattener<ELTreeNode, ELTreeFlatNode>;
  dataSource: MatTreeFlatDataSource<ELTreeNode, ELTreeFlatNode>;

  expansionModel = new SelectionModel<string>(true);
  getLevel = (node: ELTreeFlatNode) => node.level;
  isExpandable = (node: ELTreeFlatNode) => this.parseValue(node.IsNode);
  getChildren = (node: ELTreeNode): ELTreeNode[] => node.Children;
  hasChild = (_: number, _nodeData: ELTreeFlatNode) => this.parseValue(_nodeData.IsNode);
  constructor(
    // public matDialog: MatDialog,
    private http: HttpServices,
    private alertMessage: AlertMessage,
    private treeNodeService: EditLayoutNodeService,
    // public dialogRef: MatDialogRef<ReportLayoutComponent>,
    // @Inject(MAT_DIALOG_DATA) public matData: any,
    private local: LocalCacheServices,
    private translate: TranslateService) {

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ELTreeFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.dynamicColumn = [moment().format('MMM yyyy'), moment().add(1, 'year').format('MMM yyyy')];
    this.currentUser = this.local.getlocalStorage(this.local.localCurrentUserKey);
    this.IsSupportUser = (JSON.parse(this.currentUser).userType == 1) ? false : true;

    this.treeNodeService.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }



  getTotalNode(res): ELTreeNode {
    let totalNode = new ELTreeNode();
    // this.subGroupID += 1;
    totalNode.GroupID = res.GroupID;
    // this.maxGroupId += 1;
    totalNode.IsDragable = false;
    totalNode.AccountType = 'TR';
    totalNode.GroupName = res.GroupName
    totalNode.AccountName = 'Total ' + res.GroupName
    totalNode.ParentGroupID = res.ParentGroupID;
    totalNode.Children = [];
    this.rowNo = this.rowNo + 1;
    totalNode.RowNo = this.rowNo;
    return totalNode;
  }

  ngOnInit(): void {
    this.enableSwitchFun = this.pageCode == 'BS' ? true : false;
    this.getData(false);
  }

  ngAfterViewInit() {
    this.showLayout.show();
  }
  maxGroupID = 0;
  maxRowID = 0;
  rowNo = 0;
  getData(IsDefault?: boolean) {
    this.loading = true;
    this.expansionModel.selected.forEach(res => {
      this.expansionModel.deselect(res);
    })

    if (IsDefault) {
      this.isEdited = false;
      this.showSaveChanges = true;
    }
    this.http
      .postservice(
        environment.reportsApiUrl + "/Reports/GetLayoutInfo/",
        { PageCode: this.pageCode, IsDefault: IsDefault }
      )
      .subscribe({
        next: (data: any) => {
          this.title = data[0].LayoutName;
          this.LayoutType = data[0].LayoutType;
          this.nodes = data;

          this.maxGroupID = Math.max(...this.nodes.map(object => object.GroupID));
          this.maxRowID = Math.max(...this.nodes.map(object => object.AccDisplayOrder));


          let nodeData = this.nodes.filter(res => Number(res.ParentGroupID) == 0);

          let parentnodeData = [];
          nodeData.forEach(res => {
            parentnodeData.push({
              GroupID: res.GroupID,
              IsExpanded: res.IsExpanded,
              IsTotalRequired: res.IsTotalRequired,
              Children: [],
              AccountType: null,
              GroupName: res.GroupName,
              IsNode: true,
              ParentGroupID: 0,
              IsDebit: res.IsDebit,
              IsCredit: res.IsCredit,
              CAAccountID: null,
              IsDragable: res.IsDragable,
              RowLevel: 1,
              LayoutID: res.LayoutID,
              LayoutName: res.LayoutName,
              FormulaDesc: res.FormulaDesc,
              IsFormula: res.IsFormula,
              Formula: null,
              AccountName: null,
              AccDisplayOrder: res.DisplayOrder,
              SwitchGroupID: res.SwitchGroupID,
              BankAccTypeID: res.BankAccTypeID
            })
          });

          let parentnodearrayUniqueByKey = [];
          let i = 0;
          parentnodeData.forEach(r => {
            let data = parentnodearrayUniqueByKey.filter(res => res.GroupID == r.GroupID);
            if (data.length == 0) {
              this.rowNo += 1;

              r.RowNo = this.rowNo;
              if (r.RowLevel == 1 && r.IsNode == true) {
                r.IsDragable = false;
              }
              parentnodearrayUniqueByKey.push(r);
            }
          })

          // let Children = this.nodes.filter(res => Number(res.ParentGroupID) != 0);
          let Children = this.nodes.filter(res => true);
          parentnodearrayUniqueByKey = parentnodearrayUniqueByKey.sort((a, b) => a.AccDisplayOrder - b.AccDisplayOrder);
          this.nodes = this.prepareNode(parentnodearrayUniqueByKey, Children, 0);
          this.rebuildTreeForData(this.nodes);
          this.data = JSON.parse(JSON.stringify(this.nodes)); //Object.assign([], this.nodes);
          this.loading = false;

        }
      })
  }
  subGroupID = 0;



  prepareNode(node, Children, ParentGroupID) {
    let nodeData = [];
    node.forEach(res => {
      if (res.IsNode) {
        let childGroups = Children.filter(N => N.ParentGroupID == res.GroupID);
        let childAccounts = Children.filter(N => N.GroupID == res.GroupID && N.CAAccountID != null);
        let childElement = []
        childAccounts.forEach(element => {
          element.IsNode = false;
          element.IsExpanded = false;
          element.IsDragable = true;
          element.ParentGroupID = ParentGroupID;
          this.rowNo += 1;
          element.RowNo = this.rowNo;
          element.RowLevel = res.RowLevel + 1;
          childElement.push(element);
        });

        let parentnodeData = [];
        childGroups.forEach(p => {

          if (res.GroupID != p.GroupID) {
            let elTreeNode = new ELTreeNode();

            elTreeNode.Children = [];
            elTreeNode.LayoutID = p.LayoutID;
            elTreeNode.LayoutName = p.LayoutName;
            elTreeNode.GroupID = p.GroupID;
            elTreeNode.GroupName = p.GroupName;
            elTreeNode.ParentGroupID = res.GroupID;
            elTreeNode.DisplayOrder = p.DisplayOrder;
            elTreeNode.IsDragable = p.IsDragable;
            elTreeNode.IsExpanded = p.IsExpanded;
            elTreeNode.IsTotalRequired = p.IsTotalRequired;
            elTreeNode.IsDebit = p.IsDebit;
            elTreeNode.IsCredit = p.IsCredit;
            elTreeNode.IsFormula = p.IsFormula;
            elTreeNode.Formula = p.Formula;
            elTreeNode.FormulaDesc = p.FormulaDesc;
            elTreeNode.CAAccountID = null;
            elTreeNode.AccountCode = null;
            elTreeNode.SystemCode = null;
            elTreeNode.AccountName = null;
            elTreeNode.AccountTypeID = null;
            elTreeNode.AccDisplayOrder = null;
            elTreeNode.AccountType = null;
            elTreeNode.IsNode = true;
            elTreeNode.AccDisplayOrder = p.DisplayOrder;
            elTreeNode.RowLevel = res.RowLevel + 1;
            elTreeNode.SwitchGroupID = p.SwitchGroupID;
            elTreeNode.BankAccTypeID = p.BankAccTypeID;
            parentnodeData.push(elTreeNode);
          }
        });

        let parentnodearrayUniqueByKey = [];
        parentnodeData.forEach(r => {
          let data = parentnodearrayUniqueByKey.filter(x => x.GroupID == r.GroupID);
          if (data.length == 0) {
            this.rowNo += 1;
            r.RowNo = this.rowNo;
            parentnodearrayUniqueByKey.push(r);
          }
        })

        let groupAccCombined = [...parentnodearrayUniqueByKey, ...childAccounts];
        res.Children = groupAccCombined.sort((a, b) => a.AccDisplayOrder - b.AccDisplayOrder);
        if (res.IsTotalRequired && res.IsNode) {
          let totalNode = this.getTotalNode(res);
          groupAccCombined.push(totalNode)
        }
        if (groupAccCombined.filter(x => x.IsNode).length > 0) {
          res.Children = Object.assign([], this.prepareNode(res.Children, Children, res.GroupID));
        }

      }
      nodeData.push(res);
    })
    return nodeData;
  }

  childParentForamtion(nodes: any) {
    let nodeData = [];
    let node = nodes;
    let rowLevel = nodes.map(res => Number(res.RowLevel));
    let maxOfRowLevel = Math.max.apply(Math, rowLevel);
    for (let i = maxOfRowLevel; i > 1; i--) {
      let rowLevelNode = node.filter(res => Number(res.RowLevel) == i);

      node = node.filter(res => Number(res.RowLevel) != i);
      node = node.map(X => {
        let child = rowLevelNode.filter(res => X.GroupID == res.ParentGroupID);
        if (child.length > 0) {
          X.Children = child;
        }
        return X;
      });
    }

    return node;
  }

  nodeClick(node) {
    if (node.AccountType == 'TR') {
      this.nodeSelected = null;
      this.selectedID = null;
      return;
    }
    // this.nodeSelected.emit(id);
    this.selectedID = node.RowNo;
    this.parent = this.getParentNode(node);
    this.nodeSelected = node;
    this.nodeSelected.IsCredit = this.parseValue(this.nodeSelected.IsCredit);
    this.header = this.nodeSelected.GroupName;
    let nodeSelected = this.flatNodeMap.get(this.nodeSelected);
    if (this.parseValue(this.nodeSelected.IsNode)) {
      this.showMergeBtn = true;
      // this.checkMergeOrSplit(nodeSelected.Children);
    }
    this.existingGroupNames = [];
    let nestedParentNode = this.flatNodeMap.get(this.parent);
    let nestedNode = this.flatNodeMap.get(node);
    this.existingGroupNames.push(nestedParentNode.GroupName?.toLowerCase())
    nestedParentNode.Children.forEach(element => {
      if (element.IsNode) {
        this.existingGroupNames.push(element.GroupName.toLowerCase())
      }
    });
    if (nestedNode.Children && nestedNode.Children.length > 0) {
      nestedNode.Children.forEach(element => {
        if (element.IsNode) {
          this.existingGroupNames.push(element.GroupName.toLowerCase())
        }
      });
    }
  }

  async createGroup(node) {
    this.isEdited = true;
    let nestedNode = this.flatNodeMap.get(this.nodeSelected);
    let nodeSelected = this.nodeSelected;
    if (!nestedNode.Children) {

      if (nodeSelected.level > 2) {
        this.alertMessage.errorNotifier(this.translate.instant('ReportsModule.Cant create multi level of grouping'), 0);
        return;
      }
    } else {
      this.maxLevel = 0;
      await this.checkMaxLevel(nestedNode.Children);
      if (this.maxLevel > 1) {
        this.alertMessage.errorNotifier(this.translate.instant('ReportsModule.Cant create multi level of grouping'), 0);
        return;
      }
    }
    this.showCreateNode = true;
    this.childModal.show();
  }

  hideModalCreateGroup(result) {
    let nodeSelected = this.nodeSelected;
    let nestedNode = this.flatNodeMap.get(this.nodeSelected);
    if (result) {
      // this.nodeSelected.RowLevel = (this.parseValue(nodeSelected.RowLevel)) + 1;
      // this.nodeSelected.Children = this.updateLevel(this.nodeSelected.Children, this.nodeSelected.RowLevel);
      let tempNode = Object.assign({}, nestedNode);

      let newGroup = new ELTreeNode();
      nestedNode.LayoutID = this.nodeSelected.LayoutID;
      nestedNode.LayoutName = this.nodeSelected.LayoutName;
      nestedNode.RowLevel = this.nodeSelected.RowLevel;
      nestedNode.GroupName = result.groupHeader;
      nestedNode.IsExpanded = true;
      nestedNode.IsTotalRequired = result.totalRequired;
      nestedNode.IsNode = true;
      nestedNode.CAAccountID = null;
      nestedNode.IsCredit = !result.balanceType;
      nestedNode.IsDebit = result.balanceType;
      nestedNode.AccountName = null;
      nestedNode.ParentGroupID = this.nodeSelected.GroupID;
      //sertting Parent Relation
      this.maxGroupID += 1;

      if (!tempNode.IsNode) {
        tempNode.GroupID = this.maxGroupID;
        tempNode.GroupName = result.groupHeader;
      }else{
        tempNode.SwitchGroupID = null;
        tempNode.BankAccTypeID = null;
      }

      tempNode.ParentGroupID = nestedNode.GroupID;
      nestedNode.GroupID = this.maxGroupID;

      this.rowNo = this.rowNo + 1;
      nestedNode.RowNo = this.rowNo;

      const rowLevel = (this.parseValue(nodeSelected.RowLevel)) + 1;
      tempNode.RowLevel = rowLevel;
      if(tempNode.IsNode == false) {
        tempNode.IsTotalRequired = nestedNode.IsTotalRequired;
        tempNode.IsCredit = nestedNode.IsCredit;
        tempNode.IsDebit = nestedNode.IsDebit;
      }

      if (result.totalRequired) {
        let totalNode = new ELTreeNode();
        totalNode.GroupID = this.maxGroupID;
        this.maxGroupID += 1;
        totalNode.IsDragable = false;
        totalNode.RowLevel = rowLevel;
        totalNode.AccountType = 'TR';
        totalNode.IsCredit = result.balanceType;
        totalNode.IsDebit = !result.balanceType;
        totalNode.GroupName = 'Total ' + result.groupHeader;
        totalNode.AccountName = 'Total ' + result.groupHeader;
        totalNode.ParentGroupID = this.nodeSelected.GroupID;
        totalNode.Children = [];
        this.rowNo = this.rowNo + 1;
        totalNode.RowNo = this.rowNo;
        nestedNode.Children = [tempNode, totalNode];
      } else {
        nestedNode.Children = [tempNode];
      }

      this.treeNodeService.insertNode(nestedNode, newGroup);
      let c = this.treeControl.dataNodes.filter(rs => rs.RowNo == nestedNode.RowNo)[0];
      this.treeControl.expand(c);
      this.nodeClick(this.treeControl.dataNodes.filter(res => res.RowNo == this.nodeSelected.RowNo)[0]);
    }
    this.showCreateNode = false;
    this.childModal.hide();
  }

  updateLevel(node, level) {
    let nodeData = [];
    level += 1;
    node.forEach(res => {
      res.RowLevel = level;
      if (res.Children.length > 0) {
        res.Children = this.updateLevel(res.Children, level + 1);
        nodeData.push(res);
      } else {
        nodeData.push(res);
      }
    });
    return nodeData;
  }
  maxLevel = 0
  checkMaxLevel(node) {
    let max = 0;
    node.forEach(res => {
      let c = this.treeControl.dataNodes.filter(rs => rs.GroupID == res.GroupID)[0];
      max = c.level;

      if (max > this.maxLevel) {
        this.maxLevel = max;
      }

      if (res.Children && res.Children.length > 0) {
        this.checkMaxLevel(res.Children);
      }
    });
  }

  changeHeader(header) {
    const nestedNode = this.flatNodeMap.get(this.nodeSelected);
    nestedNode.GroupName = header;
    this.treeNodeService.updateItem(nestedNode!);
    this.isEdited = true;
    return;
  }

  getAllAccountID(node) {
    let accountIDs = [];
    node.forEach(res => {
      if (!this.parseValue(res.IsNode)) {
        accountIDs.push(res.CAAccountID);
      } else if (res.Children.length > 0) {
        accountIDs = accountIDs.concat(this.getAllAccountID(res.Children));
      }
    });
    return accountIDs;
  }


  showPrevBtn = false;
  ResetData() {

    this.isEdited = false;
    this.showPrevBtn = true;
    this.nodes = [];
    this.prevData = this.dataSource.data;
    this.nodes = JSON.parse(JSON.stringify(this.data));
    this.rebuildTreeForData(this.nodes);
    this.nodeSelected = null;
    this.selectedID = null;

  }

  RestoreDefault() {
    Swal.fire({
      title: this.translate.instant('Common.Confirm'),
      text: this.translate.instant('ReportsModule.Do you want to restore default settings? Changes made will be lost.'),
      icon: 'question',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        this.getData(true);
      }
    })

  }

  showPreview() {
    this.isEdited = true;
    // this.nodes = this.prevData;
    this.rebuildTreeForData(this.prevData);

  }

  async droped(event: CdkDragDrop<string[]>) {

    this.isEdited = true;
    // console.log('origin/destination', event.previousIndex, event.currentIndex);


    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) return;

    // construct a list of visible nodes, this will match the DOM.
    // the cdkDragDrop event.currentIndex jives with visible nodes.
    // it calls rememberExpandedTreeNodes to persist expand state
    const visibleNodes = this.visibleNodes();

    // deep clone the data source so we can mutate it
    const changedData = JSON.parse(JSON.stringify(this.dataSource.data));

    // recursive find function to find siblings of node
    function findNodeSiblings(arr: Array<any>, id: string): Array<any> {
      let result, subResult;
      arr.forEach((item, i) => {
        if (item.RowNo.toString() === id.toString()) {
          result = arr;
        } else if (item.Children) {
          subResult = findNodeSiblings(item.Children, id);
          if (subResult) {
            result = subResult
          };
        }
      });
      return result;
    }

    // determine where to insert the node
    const nodeAtDest = visibleNodes[event.currentIndex];
    if (event.item.data.GroupID == nodeAtDest.ParentGroupID && event.item.data.CAAccountID == null) {
      return;
    }
    // if (nodeAtDest.AccountType == 'TR') {
    //   return false;
    // }
    if (this.parseValue(nodeAtDest.RowLevel) == 1) {
      return;
    }
    if (nodeAtDest.CAAccountID > 0 && nodeAtDest.RowLevel < 2) {
      return false;
    }
    const newSiblings = findNodeSiblings(changedData, nodeAtDest.RowNo.toString());
    if (!newSiblings) return;
    const insertIndex = newSiblings.findIndex((s) => s.RowNo === nodeAtDest.RowNo);

    // remove the node from its old place
    const node = event.item.data;
    const siblings = findNodeSiblings(changedData, node.RowNo);
    const siblingIndex = siblings.findIndex((n) => n.RowNo === node.RowNo);
    const nodeToInsert: ELTreeNode = siblings.splice(siblingIndex, 1)[0];
    if (nodeAtDest.RowNo === nodeToInsert.RowNo) return;
    if (nodeToInsert.IsNode == true && nodeAtDest.GroupID === nodeToInsert.GroupID) return;

    // ensure validity of drop - must be same level
    const nodeAtDestFlatNode = this.treeControl.dataNodes.find(
      (n) => nodeAtDest.RowNo === n.RowNo
    );
    if (this.validateDrop && nodeAtDestFlatNode.level !== node.level) {
      alert(this.translate.instant('ReportsModule.Items can only be moved within the same level.'));
      return;
    }

    let parent = this.getParentNodeOfDropedIndex(visibleNodes, event);
    if (parent) {
      const RowLevel = (this.parseValue(parent.RowLevel)) + 1
      if (nodeToInsert.IsNode) {
        this.maxLevel = 0;
        await this.checkMaxLevel(nodeToInsert.Children);
        const nodesInInsertNode = this.maxLevel + 1 - nodeToInsert.RowLevel
        if ((nodesInInsertNode + parent.RowLevel) >= 3) {
          this.alertMessage.errorNotifier(this.translate.instant('ReportsModule.Cant create multi level of grouping'), 0);
          return;
        }

        nodeToInsert.ParentGroupID = parent.GroupID
        if (nodeToInsert.Children) {
          nodeToInsert.Children.forEach(e => {
            if (!e.IsNode) {
              e.ParentGroupID = nodeToInsert.ParentGroupID
            }
          })
        }
      } else {
        nodeToInsert.GroupID = parent.GroupID;
        nodeToInsert.ParentGroupID = parent.ParentGroupID;
        nodeToInsert.GroupName = parent.GroupName;
        nodeToInsert.IsTotalRequired = parent.IsTotalRequired;
        nodeToInsert.IsCredit = parent.IsCredit;
        nodeToInsert.IsDebit = parent.IsDebit;
      }
      nodeToInsert.RowLevel = RowLevel;
    }

    // insert node
    newSiblings.splice(insertIndex, 0, nodeToInsert);

    // rebuild tree with mutated data
    this.rebuildTreeForData(changedData);
  }

  getParentNodeOfDropedIndex(visibleNodes: ELTreeNode[], event: CdkDragDrop<string[], string[]>): ELTreeFlatNode {
    const reduceIndex = event.currentIndex > event.previousIndex ? 0 : 1;
    const dropIndex = event.currentIndex;
    const nodeAtDestination = visibleNodes[dropIndex].IsNode ? visibleNodes[dropIndex - reduceIndex] : visibleNodes[dropIndex];

    const parentNode = visibleNodes
      .filter(node => node.IsNode && !node.IsFormula)
      .find(node => node.GroupID === nodeAtDestination.GroupID);

    return parentNode;
  }

  dragStart() {
    this.dragging = true;
  }

  dragEnd() {
    this.dragging = false;
  }

  dragHover(node: ELTreeNode) {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
      this.expandTimeout = setTimeout(() => {
        this.treeControl.expand(node);
      }, this.expandDelay);
    }
  }

  dragHoverEnd() {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
    }
  }

  visibleNodes(): ELTreeNode[] {
    const result = [];

    function addExpandedChildren(node: ELTreeNode, expanded: string[]) {
      result.push(node);
      if (expanded.includes(node.RowNo?.toString())) {
        node.Children.map((child) => addExpandedChildren(child, expanded));
      }
    }
    this.dataSource.data.forEach((node) => {
      addExpandedChildren(node, this.expansionModel.selected);
    });
    return result;
  }

  async rebuildTreeForData(data: any) {
    this.loading = true;
    this.treeNodeService.buildFileTree(data);
    this.treeNodeService.dataChange.next(data);
    // console.log(JSON.stringify(data));
    this.expansionModel.clear();
    let treeExpand = this.treeControl.dataNodes.filter(res => this.parseValue(res.RowLevel) == 1 || this.parseValue(res.IsExpanded));
    this.expansionModel.select(...treeExpand.map(res => res.RowNo.toString()));
    this.expansionModel.selected.forEach((id) => {
      const node = this.treeControl.dataNodes.find((n) => n.RowNo.toString() === id.toString());
      this.treeControl.expand(node);
    });
    this.loading = false;
  }

  /* Get the parent node of a node */
  getParentNode(node: ELTreeFlatNode): ELTreeFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  deleteNode() {
    this.isEdited = true;
    let nestedNode = this.flatNodeMap.get(this.nodeSelected);
    let parentNode = this.getParentNode(this.nodeSelected);
    let nestedParentNode = this.flatNodeMap.get(parentNode);
    let firstChild;
    if (nestedNode.Children) {
      firstChild = nestedNode.Children[0];
    }
    if (this.parseValue(nestedNode.IsTotalRequired)) {
      nestedNode.Children = nestedNode.Children.filter(res => res.AccountType != 'TR');
    }

    let nestedParentNodeChild = [];
    nestedParentNode.Children.forEach(element => {
      if (element.RowNo == this.nodeSelected.RowNo) {
        nestedNode.Children.forEach(res => {
          nestedParentNodeChild.push(res)
        })
      } else {
        nestedParentNodeChild.push(element)
      }
    });
    nestedParentNode.Children = nestedParentNodeChild;
    this.treeNodeService.updateItem(nestedParentNode);
    if (firstChild)
      this.nodeClick(this.treeControl.dataNodes.filter(res => res.RowNo == firstChild.RowNo)[0]);
    else {
      this.nodeSelected = null;
    }

    return;
  }

  onDragEntered(event: CdkDragEnter) {
    console.log("enter " + event.container.id);
  }
  groupID = 0;

  async createNodeOrder(nodes: ELTreeNode[], parentID, level, nodeParentID, node) {
    let nodeData = [];
    let i = 0;
    nodes.forEach(element => {
      element.AccDisplayOrder = i++;
      if (element.IsNode) {
        element.DisplayOrder = i;
      }

      element.RowLevel = level;
      if (element.CAAccountID != null) {
        element.IsExpanded = !node ? true : node.IsExpanded;
        element.GroupID = parentID;
        element.DisplayOrder = !node ? i : node.DisplayOrder;
        element.ParentGroupID = nodeParentID;
        element.GroupName = node && node?.GroupName != '' ? node.GroupName : element.GroupName;
        element.IsExpanded = node && node?.IsExpanded != '' ? node.IsExpanded : element.IsExpanded;
        element.IsCredit = node.IsCredit;
        element.IsDebit = node.IsDebit;
        element.SwitchGroupID = node.SwitchGroupID;
      } else {
        element.ParentGroupID = parentID;
      }


      if (element.IsNode && element.Children.length > 0) {
        this.createNodeOrder(element.Children, element.GroupID, level + 1, element.ParentGroupID, element);
      }

      nodeData.push(element);
    });
    return nodeData;
  }


  flatten(ary) {
    var ret = [];
    for (var i = 0; i < ary.length; i++) {
      if (Array.isArray(ary[i])) {
        ret = ret.concat(this.flatten(ary[i]));
      } else {
        ret.push(ary[i]);
      }
    }
    return ret;
  }



  async submitChanges() {
    this.groupID = 0;
    let nodes = await this.createNodeOrder(this.dataSource.data, 0, 1, 0, null);

    let flatNodeData = this.FlatGroup(nodes);
    let Data = flatNodeData.filter(r => !r.IsNode && r.AccountType != 'TR');
    let group = flatNodeData.filter(r => r.IsNode);
    let groupWIhtoutchild = group.filter(r => {
      const hasValidChildren = r.Children.filter(d => d.AccountType != 'TR' && !d.IsNode).length > 0;
      return !hasValidChildren;
    })

    groupWIhtoutchild = groupWIhtoutchild.map(r => { r.IsNode = false; return r });
    // let LayoutInfo = [
    //   { Layout: nodes, MaxGroupId: this.maxGroupId }
    // ];
    // this.flatten(nodes);
    let data = [...Data, ...groupWIhtoutchild];

    let JSONData = JSON.stringify(data);
    let obj = {
      LayoutJson: JSONData,
      Pagecode: this.pageCode,
      LayoutName: this.title
    }

    this.http
      .postservice(
        environment.reportsApiUrl + "/Reports/SaveReportLayout/",
        obj
      )
      .subscribe({
        next: (data: any) => {
          this.alertMessage.successNotifier(this.translate.instant('ReportsModule.Layout Saved Successfully'), 0);
          this.closeDialog(true);
        }
      });

  }

  FlatGroup(data) {

    let FlatData = [];
    data.forEach(res => {
      FlatData.push(res);
      if (res.Children?.length > 0) {
        FlatData = [...FlatData, ...this.FlatGroup(res.Children)];
      }
    })
    return FlatData;
  }

  closeDialog(data) {
    this.showLayout.hide();
    this.closeModel.emit(data);
  }

  parseValue(data) {
    try {
      var o = JSON.parse(data);
      return o;
    }
    catch (e) { }
    return data;
  }

  changeTotalRequired(val) {
    this.isEdited = true;
    const nestedNode = this.flatNodeMap.get(this.nodeSelected);

    nestedNode.IsTotalRequired = val;

    nestedNode.Children = nestedNode.Children.filter(res => res.AccountType != 'TR')
    nestedNode.Children?.forEach(e => {
      if (!e.IsNode) { e.IsTotalRequired = nestedNode.IsTotalRequired }
    });
    if (val) {
      let totalNode = new ELTreeNode();
      totalNode.GroupID = this.nodeSelected.GroupID;
      totalNode.IsNode = false;
      totalNode.IsDragable = false;
      totalNode.AccountType = 'TR';
      totalNode.AccountName = 'Total ' + this.nodeSelected.GroupName;
      totalNode.GroupName = 'Total ' + this.nodeSelected.GroupName;
      totalNode.ParentGroupID = this.nodeSelected.ParentGroupID;
      totalNode.Children = [];
      totalNode.RowNo = this.rowNo++;
      nestedNode.Children.push(totalNode);
    }
    this.treeNodeService.updateItem(nestedNode);
    return;
  }

  changeBalance(IsCredit) {
    this.isEdited = true;

    const nestedNode = this.flatNodeMap.get(this.nodeSelected);
    nestedNode.IsCredit = IsCredit;
    nestedNode.IsDebit = !IsCredit;
    nestedNode.Children.forEach(res => {
      if (res.IsNode == false) {
        res.IsCredit = IsCredit;
        res.IsDebit = !IsCredit;
      }
    });
    this.treeNodeService.updateItem(nestedNode);


    return;
  }

  expandNode(Id) {
    this.expansionModel.toggle(Id.toString());
    let isExpanded: boolean = false;
    if (this.expansionModel.selected.filter(res => res == Id).length > 0) {
      isExpanded = true
    }
    const nestedNode = this.flatNodeMap.get(this.nodeSelected);
    nestedNode.IsExpanded = (Boolean)(isExpanded == false ? false : isExpanded);
    this.treeNodeService.updateItem(nestedNode);
    this.isEdited = true;
    return;
  }

  clickOutSide(event) {
    var target = event.target || event.srcElement || event.currentTarget;
    var idAttr = target.attributes.id;
    var value = idAttr?.nodeValue;
    if (!value) {
      this.nodeSelected = null;
      this.selectedID = null;
    }

  }

  editSwitchERule() {
    this.GetGroupNode();
    this.switchRuleModal.show();
  }

  GetGroupNode() {
    this.ddGroupNode = [];
    let groupNodes = this.treeControl.dataNodes.filter(r => r.IsNode && r.level < 3);
    console.log(groupNodes);
    this.nodeNegative = this.nodeSelected?.SwitchGroupID;
    groupNodes.forEach(r => {
      if (this.nodeSelected.GroupID.toString() != r.GroupID?.toString()) {
        this.ddGroupNode.push({ GroupID: r.GroupID, GroupName: r.GroupName })
      }
    });

  }

  closeRuleDialog() {
    this.ruleType = '0';
    this.nodeNegative = null;
    this.switchRuleModal.hide();
  }

  addEditRule() {
    this.isEdited = true;
    let nestedNode = this.flatNodeMap.get(this.nodeSelected);
    nestedNode.SwitchGroupID = this.nodeNegative;
    nestedNode.Children.forEach(res => {
      res.SwitchGroupID = this.nodeNegative;
    })
    this.treeNodeService.updateItem(nestedNode);
    this.closeRuleDialog();
  }

  deleteRule() {
    this.isEdited = true;
    let nestedNode = this.flatNodeMap.get(this.nodeSelected);
    nestedNode.SwitchGroupID = null;
    nestedNode.Children.forEach(res => {
      res.SwitchGroupID = 0;
    })
    this.treeNodeService.updateItem(nestedNode);
    this.closeRuleDialog();
  }

  getNodeNameByID(ID) {
    let data = this.treeControl.dataNodes.filter(r => r.GroupID == ID)
    if (data.length > 0) {
      return data[0].GroupName;
    }
    // let data = this.ddGroupNode.filter(r => r.GroupID == ID);
    // if (data.length > 0) {
    //   return data[0].GroupName;
    // }
  }
}

function tryParseJSONObject(jsonString) {
  try {
    var o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o.Children;
    }
  }
  catch (e) { }
  return false;
};
