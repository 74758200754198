import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as internal from 'stream';
import { environment } from '../../../../environments/environment';
import { HttpServices } from '../../../utilities/_services/http.service';
import { AddContactTCComponent } from './add-contact-tc/add-contact-tc.component';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-terms-condition',
  templateUrl: './contact-terms-condition.component.html',
  styleUrls: ['./contact-terms-condition.component.scss']
})
export class ContactTermsConditionComponent implements OnInit, OnChanges {

  @Input() ctrlOptions = {
    label: 'Terms & Conditions',
    btnlabel: 'Terms & Conditions',
    contactTCArray: [],
    showLabel: true,
    TransTypeID: null,
    showResultVertical: false,
    ContactID: null,
    isShowCondition: false
  }
  @Input() submitted;
  @Input() _ContactID = null;
  @Input() isDisabled = false;
  @Input() get ContactID(): any {
    return this._ContactID;
  }
  set ContactID(val: any) {
    this._ContactID = val;
    this.getContactsTermsCondition();
  }
  returnvalue:any=[];
  @Output() ctrlOptionsChange = new EventEmitter<any>();

  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Input() TermsID: number;
  constructor(private http: HttpServices,private alertMessage: AlertMessage, private translate: TranslateService) { }
  termsandcondition:boolean=false;
  Nodata:boolean=false;
  searchtext:string='';
  searcharray:any[]=[];
  @ViewChild(AddContactTCComponent) public addTCModal: AddContactTCComponent;
  selected;

  ngOnInit(): void {
    this.getContactsTermsCondition();
  }

  clearContactAddressDetails() { 
    this.selected = '';
    this.onSelect.emit(false);
  }

  showDialog(obj) {
    if (!this.isDisabled)
      this.addTCModal.openModal(obj, this.ctrlOptions.contactTCArray);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.submitted){
       this.termsandcondition=false;
    }
    if (changes.TermsID && this.TermsID > 0) {
      this.loadTC();
    }
    if (changes.ContactID && this.ContactID > 0) {

      let loadTC = this.ctrlOptions.contactTCArray.filter(r => r.contactID == this.ContactID);
      loadTC.sort((a, b) => b.termsID.localeCompare(a.termsID));
      if (loadTC.length > 0) {
        this.TermsID = loadTC[0].termsID
      }
      this.loadTC();
    }
  }
ondelete(terms){
  Swal.fire({
    title: this.translate.instant('Common.Confirm'),
    text: this.translate.instant('PurchaseModule.PurchaseOrder.Do you want to delete this Terms & Condition.'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: this.translate.instant('Common.Yes'),
    cancelButtonText: this.translate.instant('Common.No')
  }).then((result) => {
  if (result.value) {
  let apiUrl = environment.organizationApiUrl + '/Organization/DeleteTermscondition/'
  let Params={
    termsID:terms.termsID,
  };
  this.http.postservice(apiUrl,Params).subscribe({
      next: (res: any) => {
        this.returnvalue=[];
        this.returnvalue=JSON.parse(res.responseData);
        if(this.returnvalue[0].SuccessCount>0){
          let msg = this.translate.instant('Common.Deleted Successfully') + '!';
          this.alertMessage.successNotifier(msg, 1);
          this.selected=''
          this.onSelect.emit(false);
          this.getContactsTermsCondition();

        }
        else if(this.returnvalue[0].ErrorCount>0){
          let msg = this.translate.instant("PurchaseModule.PurchaseOrder.Could not be delete due to used in some contacts");
          this.alertMessage.errorNotifier(msg, 0);
        }
    }
  })
}
else if (result.dismiss === Swal.DismissReason.cancel) {
  return false;
}
})
}

  loadTC() {
    if (this.ctrlOptions.contactTCArray.length > 0) {
      let data = this.ctrlOptions.contactTCArray.filter(res => res.termsID == this.TermsID)[0];
      if (data){
        this.selected = data;
        this.onSelect.emit(data);
      }
    }
  }
 search(){
  this.ctrlOptions.contactTCArray=this.searcharray.filter(t=>t.termsTitle.toLowerCase().includes(this.searchtext.toLowerCase()))
  if(this.ctrlOptions.contactTCArray.length==0){
    this.Nodata=true;
  }
  else{
    this.Nodata=false;
  }
 }
 onclickTC(){
  this.termsandcondition = !this.termsandcondition
  //this.termsandcondition=this.termsandcondition?false:true;
  this.searchtext='';
  this.search();
 }

  onClikTermsModel(terms) {
    this.selected = terms;
    this.onSelect.emit(terms);
    this.searchtext='';
    this.search();
  }

  getContactsTermsCondition(event?) {

    let apiUrl = environment.organizationApiUrl + '/Organization/GetContactsTermscondition/' +
      (this.ctrlOptions.TransTypeID ?? '0') + '?ContactID=' + (this.ContactID ?? 0);
    this.http.getservice(apiUrl).subscribe({
      next: (res: any) => {
        if (res !== undefined) {
          this.ctrlOptions.contactTCArray = [];
          this.ctrlOptions.contactTCArray = res.value;
          this.searcharray=res.value;
          this.ctrlOptionsChange.emit(this.ctrlOptions);
          if (event) {
            let data = this.ctrlOptions.contactTCArray.filter(res => res.termsTitle == event);
            if (data.length > 0) {
              this.onClikTermsModel(data[0]);
              return;
            }
          }
          if (this.TermsID > 0 && this.selected?.TermsID != this.TermsID) {
            this.loadTC();
          }
        }
      },
      error: err => {
      }
    });

  }
}
