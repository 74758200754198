import { AllCommunityModules, GridOptions, Module } from '@ag-grid-community/all-modules';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { currencyFormatter } from '../../../utilities/_common/commonfunctions';
import { HttpServices } from '../../../utilities/_services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportDataPassingService } from '../../reports/Service/report-data-passing.service';

@Component({
  selector: 'app-itemhistory',
  templateUrl: './itemhistory.component.html',
  styleUrls: ['./itemhistory.component.scss']
})
export class ItemhistoryComponent implements OnInit {
  @ViewChild('showItemmodal') public showItemmodal: ModalDirective;
  modules: Module[] = AllCommunityModules;
  public gridOptions: GridOptions;
  rowData:any = [];
  columnDefs:any = [];
  domLayout: string = "autoHeight"
  constructor(private http: HttpServices, 
    private translate : TranslateService,
    private reportDataPassingService: ReportDataPassingService) {
    this.translate.onDefaultLangChange.subscribe(async() => {
      this.gridOptions.api.refreshHeader();
    });
    this.translate.onLangChange.subscribe(async() => {
      this.gridOptions.api.refreshHeader();
    });
   }

  ngOnInit(): void {
    this.gridOptions = <GridOptions>{ };
    this.columnDefs = [
      {
        headerName: "Contact Name",
        field: "ContactName",
        sortable: true,
        filter: true,
        wrapText: true,       
        autoHeight: true,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Contact Name")
      },
      {
        headerName: "Trans Type",
        field: "TransType",
        sortable: true,
        filter: true,
        wrapText: true,       
        autoHeight: true,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Trans Type"),
        cellRenderer: (params) => {
          if ([12, 27, 28].some(tranTypeID => tranTypeID === params.data.TransTypeID)) {
            return params.value;
          } else {
            return this.actionCellRenderer(params);
          }
        }
      },
      {
        headerName: "Trans Date",
        field: "TransDate",
        sortable: true,
        filter: true,
        wrapText: true,       
        autoHeight: true,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Trans Date")
      },
      {
        headerName: "Reference",
        field: "Reference",
        sortable: true,
        filter: true,
        wrapText: true,       
        autoHeight: true,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Reference")
      },
      {
        headerName: "Quantity",
        field: "Quantity",
        sortable: true,
        filter: true,
        wrapText: true,       
        autoHeight: true,
        type: 'numericColumn',
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Quantity")
      },
      {
        headerName: "Unit Price",
        field: "Amount",
        sortable: true,   
        wrapText: true,       
        autoHeight: true,
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Unit Price")
      }   
    ];
  }
  onGridReady(params: any) {
    params.api.showLoadingOverlay();  
    params.api.sizeColumnsToFit();
  }
  onGridSizeChanged(params: any) {
    params.api.sizeColumnsToFit();
  }
   actionCellRenderer(params: any) {
    let eGui = document.createElement("div");
    eGui.innerHTML = `<a class="t-link edit">${params.value}</a>`;
    return eGui;
  }

  getItemHistory(itemID){
    this.http.getservice(environment.inventoryApiUrl + '/Inventory/GetItemHistory?ItemID=' + itemID).subscribe({
      next: (data) => {
        this.rowData = data; 
        this.gridOptions.api.sizeColumnsToFit();
        this.showItemmodal.show();
       }
    });
  }

  hideItemmodal(){
    this.showItemmodal.hide();
  }
  async onCellClick(params) {  
    let SalesTranTransTypeID = params.data.TransTypeID;
    if (params.data && SalesTranTransTypeID != 27 && SalesTranTransTypeID != 28 && SalesTranTransTypeID != 12) {
      this.reportDataPassingService.navigate(params.data.LinkDetail);
    }
  }
}
