<div bsModal #exportDetailModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="exportDetailModalLabel" aria-hidden="true" [config]="{
    backdrop: 'static' }">
    <div class="modal-dialog modal modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{'Detail Columns' | translate}} - {{payload?.TransactionType | translate}} 
                    <span>
                        <button type="button" triggers="mouseenter:mouseleave" [popover]="popsymbolsHtml" class="btn inforight py-0">
                            <i class="fa fa-info-circle"></i></button>
                        <ng-template #popsymbolsHtml>
                            <div>
                                <span class="help-block text-info">
                                    <ol>
                                        <li>{{'Mandatory column cannot be unchecked' | translate}}</li>
                                        <li>{{'Inactive custom fields are highlighted in red' | translate}}</li>
                                    </ol>
                                </span>
                            </div>
                        </ng-template>
                    </span>
                </h4>
                <button type="button" class="close" (click)="closeModal()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="custom-ag-grid-4 h-100 m-0">
                    <!-- <div class="d-flex mr-4 justify-content-end t-link edit">{{numSelectedColumn}} Column selected</div> -->
                    <!--[rowDragManaged]="true"
                        [rowDragEntireRow]="true"
                        [rowDragMultiRow]="true"-->
                    <ag-grid-angular agGridXssDirective style="width: 100%;height: 100%;overflow-x: hidden" class="ag-theme-material" 
                        [modules]="modules" [columnDefs]="columnDefs" [rowSelection]="rowSelection" 
                        [gridOptions]="gridOptions" [rowGroupPanelShow]="rowGroupPanelShow" 
                        [rowData]="rowData" [suppressRowClickSelection]="true" 
                        [overlayLoadingTemplate]="overlayLoadingTemplate" accentedSort="true"
                        [overlayNoRowsTemplate]="overlayNoRowsTemplate">
                    </ag-grid-angular>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success" (click)="onExport()" *userCanEdit="[true]">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{'Common.Export' | translate}}
                </button>
                <button class="btn btn-danger" type="button" (click)="closeModal()">{{'Common.Cancel' | translate}}</button>
            </div>
        </div>
    </div>
</div>