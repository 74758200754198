import * as moment from "moment";

export const toolTipValueGetter = (params) => ({ value: params.value });

export function anchorCellRender(params: any) {
  let eGui = document.createElement("div");
  eGui.innerHTML = `<a class="t-link edit">${params.value}</a>`;
  return eGui;
}

export function currencyFormatter(params) {
  let value;
  if (params.value == undefined || params.value == null) {
    value = params;
  }
  else {
    value = params.value;
  }

  let num;
  if (Number(value) > 0)
    num = Number(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  else if (Number(value) == 0) {
    num = Number(value).toFixed(2);
  }
  else {
    num = "(" + Number(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
  }
  return num;
}
export function dateComparator(date1, date2) {
  var date1Number = date1 && new Date(date1).getTime();
  var date2Number = date2 && new Date(date2).getTime();

  if (date1Number == null && date2Number == null) {
    return 0;
  }

  if (date1Number == null) {
    return -1;
  } else if (date2Number == null) {
    return 1;
  }

  return date1Number - date2Number;
}

export function braceFormatter(params) {
  let num;
  if (params.value > 0)
    num = Number(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  else if (Number(params.value) == 0) {
    num = "";
  }
  else {
    num = "(" + Number(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
  }
  return num;
}

export function braceFormatternew(params) {
  let num;
  let dgt = params.value.toString().length - Number(params.value).toFixed(0).length-1;
  dgt = dgt < 0 ? 2 : dgt;
  if (params.value > 0)
    //num = Number(params.value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+Number(params.value - Math.floor(params.value)).toFixed(dgt).replace("0.",".");
    num = Math.floor(Number(params.value)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+Number(params.value - Math.floor(params.value)).toFixed(dgt).replace("-", "").replace("0.",".");
  else if (Number(params.value) == 0) {
    num = "";
  }
  else {
    num = "(" + Number(params.value).toFixed(dgt).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
    //num = "(" + Number(params.value).toFixed(dgt).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "")+Number(params.value - Math.ceil(params.value)).toFixed(dgt).replace("0.",".") + ")";
    num = "(" + Math.ceil(Number(params.value)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "")+Number(params.value - Math.ceil(params.value)).toFixed(dgt).replace("-", "").replace("0.",".") + ")";
  }
  return num;
}

export function braceFormatter4(params) {
  let num;
  let dgt = params.value.toString().length - Number(params.value).toFixed(0).length-1;
  dgt = 4;//dgt < 0 ? 4 : dgt;
  if (params.value > 0)
    //num = Number(params.value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+Number(params.value - Math.floor(params.value)).toFixed(dgt).replace("0.",".");
    num = Math.floor(Number(params.value)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+Number(params.value - Math.floor(params.value)).toFixed(dgt).replace("-", "").replace("0.",".");
  else if (Number(params.value) == 0) {
    num = "";
  }
  else {
    num = "(" + Number(params.value).toFixed(dgt).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
    //num = "(" + Number(params.value).toFixed(dgt).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "")+Number(params.value - Math.ceil(params.value)).toFixed(dgt).replace("0.",".") + ")";
    num = "(" + Math.ceil(Number(params.value)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "")+Number(params.value - Math.ceil(params.value)).toFixed(dgt).replace("-", "").replace("0.",".") + ")";
  }
  return num;
}

export function zerobraceFormatter(params) {
  let num;
  if (params.value > 0)
    num = Number(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  else if (Number(params.value) == 0) {
    num = Number(params.value).toFixed(2);
  }
  else {
    num = "(" + Number(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
  }
  return num;
}

export function actionCellRenderer(params) {
  let eGui = document.createElement("div");
  eGui.innerHTML = `<span class="close-x" data-action="delete">x</span>`;
  return eGui;
}


export function getDefaultSetting(orgdue, orgduetypeid,datevalue?) {
  var retdate = '';
  var date = new Date(datevalue);
  if (orgduetypeid == 1) {
    date.setDate(date.getDate() + orgdue);
    retdate = moment(date).format('YYYY/MM/DD');
    return retdate;
  }
  else if (orgduetypeid == 2) {
    var getdays = daysInMonth(date.getMonth() + 1, date.getFullYear());
    var calcdays = getdays - date.getDate();
    date.setDate(date.getDate() + calcdays + orgdue);
    retdate = moment(date).format('YYYY/MM/DD');
    return retdate;
  }
}

export function isValidDate(dt){
  var validDate = /^\d{4}\/\d{2}\/\d{2}$/;
  return validDate.test(dt);
}

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function ExchangerateRoundUp(customRate:string) {
  if (customRate != '' && customRate != undefined) {
    let b = customRate.split('.');
    let c = '0';
    if (b.length > 1) {
      if (b.length == 2 && b[1].length > 6) {
        if(Number(customRate)){
          c = Number(customRate).toFixed(6);
          return c;
        }
      }
      else if (b.length > 2){
        c = b[0]+'.'+ b[1];
        return c;
      }
      else{
        return customRate;
      }
    }
    else{
      return customRate;
    }
  }
}
export function  replaceNewline(params,field) {
  if (params.value != undefined && params.value != null && params.value != '') {
    let input = params.value;
    var newline =  String.fromCharCode(10);
    params.data[field] = params.data[field].replaceAll('\\n', newline);
    input = input.replaceAll('\\n', '<br>');
    return input.replaceAll(newline, '<br>');
  }
  else{
    return '';
  }
}

export function  changeDateTime_toOrgTimeZone(zoneValue)
{
  let localdate:any=new Date();
  // convert to msec
  // add local time zone offset
  // get UTC time in msec
  const utc= localdate.getTime() + (localdate.getTimezoneOffset() * 60000);
  let result:any= new Date(utc +(3600000* zoneValue.replace(':','.')));
  return moment(result).format('DD MMM,yyyy HH:mm');
}

export function returnDecimal(value, digits)
{
  return Number((Math.round(value*100)/100).toFixed(digits));
}

export function removingTrailingZeros(value: any): string{
  if(value.toString().includes('e')){
    value = value.toFixed(12);
    let dotIdx = value.indexOf(".") + 1;
    let decimalValues = value.slice(dotIdx, value.length);
    let result = "";
    
    for(let i = decimalValues.length - 1; i > 0; i--){
        if(decimalValues[i] == 0){
            if(result == "")
                result = value.slice(0, value.length - 1);
            else
                result = result.slice(0, result.length - 1);
        }
        else
            break;
    }
    return (result == "" ? value : result);
  }
  return value;
}

export function removeInvalidFileNameCharacters(fileName: string): string {
  const invalidFileChars: string[] = ["*", ":", "|", '"', "<", ">", "?", "/", "\\", "%"];
  invalidFileChars.forEach(invalidChar => {
    if (fileName.includes(invalidChar)) {
      fileName = fileName.replaceAll(invalidChar, '');
    }
  });
  return fileName.trim() + '.pdf';
}
export function zerobraceFormatter4(params) {
  let num;
  if (params.value > 0)
    num = Number(params.value).toFixed(4);
  else if (Number(params.value) == 0) {
    num = Number(params.value).toFixed(4);
  }
  else {
    num = "(" + Number(params.value).toFixed(4);
  }
  return num;
}

declare global {
  interface Number {
    /**
     * Round to decimal as Database, Eg: decimal = 2, this = 8.555 then return 8.56
     * @param decimal Decimal count*/
    round(decimal?: number): String;
  }
}
 
Number.prototype.round = function (decimal) {
  let tense = Math.pow(10, decimal??0);
  return (Math.round((Number(this) * tense)) / tense).toString();
};
