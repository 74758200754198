import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { OnlineinvoiceComponent } from '../../views/onlineinvoice/onlineinvoice.component';
import { PageServices } from './page.service';
import { EmailTemplate } from '../../views/payments/onlinepayment/onlinepayment.component';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  apiUrl: string = "";

  constructor(private _http: HttpClient, private page: PageServices) {
    this.apiUrl = environment.reconcilationApiUrl;
  }
 
  async GetStripeKeys<T>() {
    var Url = this.apiUrl + "/reconcile/StripePayment/GetStripeKeys";
    let result = await this._http.get<T>(Url).toPromise();
    return result;
  }


  async GetAllStripeKeys<T>() {
    var Url = this.apiUrl + "/reconcile/StripePayment/GetStripeKeys?from=all";
    let result = await this._http.get<T>(Url).toPromise();
    return result;

  }
  async SaveStripeKeys<T>(body: any) {
    var Url = this.apiUrl + "/reconcile/StripePayment/SaveStripeKeyInfo";
    let result = await this._http.post<T>(Url, body);
    return result;
  }

  async SavePayPalKeys<T>(body: any) {
    var Url = this.apiUrl + "/reconcile/PaypalPayment/SavePaypalKeyInfo";
    let result = await this._http.post<T>(Url, body);
    return result;
  }

  async GetPaymentServicesInfo<T>() {
    var Url = this.apiUrl + "/reconcile/Payments/GetPaymentServices";
    let result = await this._http.get<T>(Url).toPromise();
    return result;
  }

  async GetStripeRedirectionURL<T>() {
    var Url = this.apiUrl + "/reconcile/StripePayment/GetStripeRedirectionURL";
    let result = await this._http.get<T>(Url).toPromise();
    return result;
  }

  async VerifyKeyandsession(key: any,sessionId: any,TransactionDatakey: any ,status: any,keyId:any) {
      let params = new HttpParams();
      params = params.set('keyId', keyId);
    params = params.set('key', key);
    params = params.set('sessionId', sessionId);
    params = params.set('TransactionDatakey', TransactionDatakey);
    params = params.set('status', status);
    let response = await this._http.post(this.apiUrl + '/reconcile/StripePayment/StripeCallback', params).toPromise();
    return response;
  }

  async VerifyPayPalCallBack(key: any,paymentId: any,token: any ,PayerID: any,status: any) {
    let params = new HttpParams();
    params = params.set('key', key);
    params = params.set('paymentId', paymentId);
    params = params.set('token', token);
    params = params.set('PayerID', PayerID);
    params = params.set('status', status);
    let response = await this._http.post(this.apiUrl + '/reconcile/PaypalPayment/PayPalCallback', params).toPromise();
    return response;
  }

  async VerifyKey(key: any) {
    let params = new HttpParams();
    params = params.set('key', key);
    let response = await this._http.post(environment.authguardApiUrl + '/AuthLogin/verifykey', params).toPromise();
    return response;
  }

  async emailOnlineInvoicePayment(emailTemplates:EmailTemplate,contactname:string,invoiceno:string){
    let params = new HttpParams();
    params = params.set('emailParams', JSON.stringify(emailTemplates));
    params = params.set('contactname', contactname);
    params = params.set('invoiceno', invoiceno);
    let response = await this._http.post(environment.templateApiUrl + '/TemplatesAnonymous/sendPaymentEmailtoCustomer',params).toPromise();
    return response; 
       
  } 

  async GetStripeAccessKey(StripeCode: any) {
    let params = new HttpParams();
    params = params.set('key', StripeCode);
    let response = await this._http.post(this.apiUrl + '/reconcile/StripePayment/GetStripeAccessKey', params).toPromise();
    return response;
  }

  async DeAuthorizeStripeAccessKey(StripeCode: any) {
    let params = new HttpParams();
    params = params.set('key', StripeCode);
    let response = await this._http.post(this.apiUrl + '/reconcile/StripePayment/DeAuthorizeStripeAccessKey', params).toPromise();
    return response;
  }

}
