import { Component,ViewEncapsulation , ViewChild, ViewContainerRef } from "@angular/core";
import { ICellEditorAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/all-modules";

@Component({
    selector: "select-cell",
     template: `
<div id="InvItem" style="width:100%" >
<ng-select  #input
[items]="lstInventoryItem"
[virtualScroll]="true"
[loading]="loading"
bindLabel="itemName"
bindValue="itemID"
placeholder="Select Item"
(scroll)="onScroll($event)"
(scrollToEnd)="onScrollToEnd()"
 [(ngModel)]="itemID"
(change)="itemChange($event)"
[searchFn]="customSearchFn"
>
<ng-template ng-header-tmp>
<a *ngIf="!isItemPage" class="t-link" (click)="addItem()"><b>+</b>New Item
</a>
</ng-template>

<ng-template ng-option-tmp let-item="item" let-index="index">
<div style="margin: 5px 0;box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
border: 0;border-radius: 4px;">
<table style="width: 100%;">
<tr>
<td style="padding: 5px;"> {{item.itemName }}</td>
<td rowspan="1" style="vertical-align: top;text-align: right;padding-right: 5px;"> {{ item.productCategoryName }}</td>
</tr>
<tr>
<td style="padding: 5px;color: darkgrey;"> {{item.itemCode }}</td>
<td style="padding: 5px; text-align: right;width: 50%;" class="text-danger" *ngIf="item.isTrackedItem">Stock on Hand:  {{item.quantity }}</td>
</tr>
</table>
</div>
</ng-template>

</ng-select>
</div>
`,
styles:[
  `
   #InvItem .ng-dropdown-panel{
    min-width: 350px !important;
    max-width: 450px !important;
    white-space: normal;
   }
   #InvItem .ng-option {
       padding: 1px 4px !important;
   }
   `
],
encapsulation: ViewEncapsulation.None
})


export class InventoryItemSelectComponent implements ICellEditorAngularComp {
    public params: any;

    public lstInventoryItem: any = [];
    public itemID: string = '';
    public selectedIndex: number = 0;
    public page:string='';
    public isItemPage: boolean=false;

    @ViewChild('input') input;
    itemBuffer: any = [];
    bufferSize = 50;
    numberOfItemsFromEndBeforeFetchingMore = 10;
    loading = false;
    constructor() { }

    agInit(params: any): void {
        this.params = params;
        this.itemID = this.params.value;
        this.page=this.params.page;
        if(this.params.page=='Item'){
            this.isItemPage=true;
        }
        this.lstInventoryItem = this.params.lstInventoryItem;
      this.itemBuffer = this.lstInventoryItem.slice(0, this.bufferSize);
        this.selectedIndex = this.lstInventoryItem.findIndex(item => {
            return item === this.params.value;
        });
    }
    onScrollToEnd() {
        this.fetchMore();
    }
    onScroll({ end }) {
        if (this.loading || this.lstInventoryItem.length <= this.itemBuffer.length) {
            return;
        }

        if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.itemBuffer.length) {
            this.fetchMore();
        }
    }

    private fetchMore() {
        const len = this.itemBuffer.length;
        const more = this.lstInventoryItem.slice(len, this.bufferSize + len);
        this.loading = true;
        // using timeout here to simulate backend API delay
        setTimeout(() => {
            this.loading = false;
            this.itemBuffer = this.itemBuffer.concat(more);
        }, 200)
    }

    getValue() {
        return this.itemID;
    }

    isPopup(): boolean {
        return true;
    }
    itemChange(e) {
        this.params.api.stopEditing();
    }
    addItem() {
         let SetitemID:any = 0;
         this.itemID = SetitemID;
         this.params.api.stopEditing();
    }
    customSearchFn(term: string, item: any)
    {
        term = term.toLocaleLowerCase().trim();
        return item.itemCode.toLocaleLowerCase().indexOf(term) > -1 ||
               item.itemName.toLocaleLowerCase().indexOf(term) > -1 ||
               item.productCategoryName.toLocaleLowerCase().indexOf(term) > -1;
    }

    afterGuiAttached() {
        this.input.focus();
    }
}
