<div bsModal #showmodal="bs-modal" (onShown)="onShown()" class="modal fade" [config]="{ backdrop: 'static' }"
    tabindex="-1" role="dialog" aria-labelledby="showmodal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <form class="form-horizontal" [formGroup]="emailtemplateForm" novalidate="novalidate" (ngSubmit)="onSubmit()">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{sendTitle}}</h4>
                    <button id="bntSendCloseModel" type="button" class="close" (click)="CloseModel()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-form-label" for="From">{{'PurchaseModule.PurchaseOrder.Template Name' | translate}}</label>
                            <div class="col-md-8">
                                <select id="ddSendemailTempID" formControlName="emailTempID" class="form-select" #grid1
                                    [(ngModel)]="defaultselectedOption" (change)="loadTemplate(defaultselectedOption)">
                                    <option *ngFor="let c of lstEmailTemplate" [ngValue]="c.emailTempID">
                                        {{c.templateName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2 col-form-label" for="From">{{'PurchaseModule.PurchaseOrder.From' | translate}}</label>
                            <div class="col-md-4" style="font-size: medium;font-weight: bold;">
                                <label class="col-form-label" for="From"> {{emailtemplateForm.value.from}}</label>
                            </div>
                            <label class="col-md-1 col-form-label" for="From">{{'PurchaseModule.PurchaseOrder.Reply To' | translate}}</label>
                            <div class="col-md-4" style="font-size: medium;font-weight: bold;">
                                <label class="col-form-label" for="Reply"> {{emailtemplateForm.value.replyTo}}</label>
                            </div>
                        </div>
                        <!-- <div class="form-group row text-left">
                            <div class="col-md-12">
                                <small> <strong>Note:- </strong> Separate multiple email addresses with a comma (,) or a
                                    semicolon (;)</small>
                            </div>
                        </div> -->
                        <div #grid id="grid-send-mailpopup-wrapper"
                            [className]=" 'highLighttheme custom-ag-grid-5 mb-3'" *ngIf="sendEmailmodal == 'show'"
                            style="border: 0px;">
                            <div class="row text-left">
                                <div class="col-md-12">
                                    <small> <strong>{{'PurchaseModule.PurchaseOrder.Note' | translate}}:- </strong> {{'PurchaseModule.PurchaseOrder.Separate multiple email addresses with a comma(,) or a semicolon(;)' | translate}}</small>
                                </div>
                            </div>
                            <ag-grid-angular style="width: 100%; height:200px;" class="ag-theme-alpine"
                                [modules]="modules" [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                [rowData]="rowData" [rowSelection]="rowSelection" [singleClickEdit]="true"
                                [suppressRowClickSelection]="true" [frameworkComponents]="frameworkComponents"
                                (rowSelected)="onRowSelected($event)" (cellValueChanged)="onCellValueChanged($event)"
                                (gridReady)="onGridReady($event)">
                            </ag-grid-angular>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2 col-form-label" for="subject">{{'SharedModule.Subject' | translate}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <input type="text" formControlName="subject" class="form-control" maxlength="500"
                                    [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" xssDirective />
                                <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                    <div *ngIf="f.subject.errors.required">{{'SharedModule.Please provide a Subject' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <quill-editor formControlName="body" class="w-100">
                                <div quill-editor-toolbar>
                                    <span class="ql-formats">
                                        <select id="ddfont" class="ql-font">
                                            <option selected></option>
                                            <option value="serif"></option>
                                            <option value="monospace"></option>
                                        </select>   
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-size">
                                            <option value="6pt">6pt</option>
                                            <option value="8pt" selected>8pt</option>
                                            <option value="9pt">9pt</option>
                                            <option value="10pt">10pt</option>
                                            <option value="11pt">11pt</option>
                                            <option value="12pt">12pt</option>
                                            <option value="13pt">13pt</option>
                                            <option value="14pt">14pt</option>
                                            <option value="16pt">16pt</option>
                                            <option value="18pt">18pt</option>
                                            <option value="24pt">24pt</option>
                                            <option value="30pt">30pt</option>
                                            <option value="36pt">36pt</option>
                                            <option value="48pt">48pt</option>
                                        </select>
                                    </span>   
                                    <span id="ddfontformats" class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span id="ddColorFormats" class="ql-formats">
                                        <select class="ql-color"></select>
                                        <select class="ql-background"></select>
                                    </span>
                                    <span id="ddstyleformats" class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <select id="ddfornalight" class="ql-align">
                                            <option selected></option>
                                            <option value="center"></option>
                                            <option value="right"></option>
                                            <option value="justify"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button id="btnSendlink" class="ql-link"></button>
                                        <button id="bntSendimage" class="ql-image"></button>
                                    </span>
                                    <span class="ql-formats">

                                        <div class="dropdown" *ngIf="typeID!=18"  dropdown>
                                            <button class="dropdown-toggle" id="dropdownMenu4" type="button"
                                                dropdownToggle aria-haspopup="true" aria-expanded="false">{{'SharedModule.Insert Placeholders' | translate}}</button>
                                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right ddm-placeholder-size"
                                                style="margin: 0px;">
                                                <div class="d-flex">
                                                    <div *ngFor="let phdetail of phdetails;let idx=index">
                                                        <h6 class="dropdown-header text-muted h6"
                                                            *ngIf="phdetail.header">
                                                            {{phdetail.field}}</h6>
                                                        <div *ngFor="let subMenulist of phdetail.submenu;">
                                                            <a class="dropdown-item ddi-placeholder-size"
                                                                id="ddmInsertPlaceholders_subMenulist-{{subMenulist.field}}"
                                                                (click)="changeCustom($event,subMenulist.code)">{{subMenulist.field}}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </quill-editor>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="submitted && text==null">
                    <div class="form-group row" style="text-align: center;">
                        <small class="col-md-11 col-form-label" style="color: #f86c6b;font-size:80%"> <i
                                class="cil-warning"></i> {{'SharedModule.Please provide a Template Content' | translate}} </small>
                    </div>
                    <div class="form-group row" *ngIf="Placeholder!=''">
                        <small class="col-md-11 col-form-label" style="color: #f86c6b;font-size:80%"> <span
                                [innerHTML]="Placeholder"></span> </small>
                    </div>
                </div>
                <div class="modal-footer">
                    <div *ngIf="isIncludeAttachment" class="custom-control-mid custom-checkbox">
                        <input id="includeAttachment" formControlName="includeAttachment" type="checkbox"
                            class="custom-control-input" />
                        <label class="custom-control-label" for="includeAttachment">{{'SharedModule.Include Attachment' | translate}} </label>
                    </div>
                    <div class="custom-control-mid custom-checkbox">
                        <input id="Sendmecopy" formControlName="Sendmecopy" type="checkbox"
                            class="custom-control-input" />
                        <label class="custom-control-label" for="Sendmecopy">{{'SharedModule.Send me a copy' | translate}} </label>
                    </div>
                    <div *ngIf="isMarkasSent" class="custom-control-mid custom-checkbox">
                        <input id="markasSent" formControlName="markasSent" type="checkbox"
                            class="custom-control-input" />
                        <label class="custom-control-label" for="markasSent">{{'PurchaseModule.PurchaseOrder.Mark as Sent' | translate}} </label>
                    </div>
                    <button id="btnSendSend" [disabled]="loading" class="btn btn-info" *userCanView="[true]">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        <span class="c-icon material-icons-outlined m-0">send</span> {{'Common.Send' | translate}}
                    </button>
                    <button id="btnSendCloseModel" class="btn btn-danger" type="button"
                        (click)="CloseModel()">{{'Common.Cancel' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>