import { ICellEditorParams } from '@ag-grid-community/all-modules';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component } from '@angular/core';


@Component({
  selector: 'app-batchlist',
  templateUrl: './batchlist.component.html',
  styleUrls: ['./batchlist.component.scss']
})
export class BatchlistComponent implements ICellEditorAngularComp, AfterViewInit {
  isShowCreateNew: boolean = false;
  newBatchNo: string;
  lstBatch: any = [];
  gridParams: any;
  batchInfo: any;
  constructor() {

  }

  getValue() {
    return this.batchInfo;
  }

  agInit(params: ICellEditorParams): void {
    this.gridParams = params;
    if (this.gridParams.isShowCreateNew != undefined && this.gridParams.isShowCreateNew)
      this.isShowCreateNew = true;
    if (this.gridParams.isWarehouseEnabled && !this.gridParams.data.warehouseID) {
      this.lstBatch = [];
    } else {
      let allBatchs = this.gridParams.allBatchs.filter(e => e.warehouseID == params.data.warehouseID);
      this.lstBatch = this.gridParams.lstBatch.filter(e => e.warehouseID == params.data.warehouseID);
      if (params.value != '' && this.lstBatch.findIndex(e => e.batchID == params.value) == -1) {
        let batch = allBatchs.find(e => e.batchID == params.value)
        if (batch) this.lstBatch.splice(0, 0, batch);
      }
      this.batchInfo = params.value;
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  onChange() {
    if (this.batchInfo)
      this.gridParams.api.stopEditing();
  }

  isPopup(): boolean {
    return true;
  }

  onSearchForApi(params) {
    this.newBatchNo = params.term;
    if (this.gridParams.isShowCreateNew != undefined && this.gridParams.isShowCreateNew)
      this.isShowCreateNew = this.lstBatch?.filter(e => e.batchNo.toString().toUpperCase() == this.newBatchNo.toString().toUpperCase()).length > 0 ? false : true;
  }

  saveNewBatch() {
    if (this.newBatchNo) {
      this.batchInfo = {
        batchNo: this.newBatchNo,
        isNew: true
      };
      this.gridParams.api.stopEditing();
    }
  }
}
