import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../utilities/_services/authentication.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../utilities/_services/loader.service';
import { LocalCacheServices } from '../../utilities/_services/acclocalcache.service';
import { AccsessioncacheService } from '../../utilities/_services/accsessioncache.service';
import { Menunav } from '../../utilities/_models/menunav'
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { PageServices } from '../../utilities/_services/page.service';
import { AlertMessage } from '../../utilities/_helpers/alert.message'
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { DefaultValueAccessor,FormControl } from '@angular/forms';
import { DocumentInterruptSource, Idle, StorageInterruptSource } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MasterServices } from '../../utilities/_services/master.service';
import { DIR_DOCUMENT_FACTORY } from '@angular/cdk/bidi/dir-document-token';
import { debounceTime, filter, finalize, mergeMap, tap } from 'rxjs/operators';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';

@Component({
  selector: 'app-subscriptionlayout',
  templateUrl: './subscription-layout.component.html'
})
export class SubscriptionlayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public sidenav: Menunav[] = [];
  public topnav: any = [];

  currentUser: any;
  isuserActiveOrgs:number;
  horizantalmenus: any;
  activePageId: number = null;
  parentPageID: number;
  pageID: number;
  routerurl: string;

  usermanualurl:string = "https://support.infotech-accounting.com";

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  routeEventSubscription: any;
  SubscriberOrgID = 0;
  isProduction: boolean;
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  
  searchCustomerCtrl = new FormControl();
  filteredCustomers: any = [];
  loading = false;
  error = '';
  value = 0;
  items: TreeviewItem[];
  config = TreeviewConfig.create({
    hasFilter: true,
    hasCollapseExpand: true,
    maxHeight: 500
  });

  constructor(private authenticationService: AuthenticationService, private bnIdle: BnNgIdleService,
    public translate: TranslateService, public loader: LoaderService, public local: LocalCacheServices,
    public session: AccsessioncacheService, private alertMessage: AlertMessage,
    private page: PageServices, private router: Router,
    private idle: Idle, private keepalive: Keepalive,private master: MasterServices,private location: Location) {
    this.isProduction = environment.production;
    }

  ngOnInit(): void {
    this.usermanualurl =  environment.production ? "https://support.infotech-accounting.com": "http://localhost:4200";;
    window.addEventListener('storage', function (e) {
      if (e.key === "IkN1cnJlbnRVc2VyIg==") // login session key
      {
        // window.location.reload();
        window.location.href = window.location.origin + '/supportdashboard';
        window.location.reload();
      }
    }, false);

    this.pageInitLoad();
    this.master.getOrgCountryDetails().then((data: any) => {
      this.usermanualurl = this.usermanualurl + "?cid=" +  btoa(data.ResponseData);
    })

    const original = DefaultValueAccessor.prototype.registerOnChange;

    DefaultValueAccessor.prototype.registerOnChange = function (fn) {
      return original.call(this, value => {
        const trimmed = typeof (value) === 'string' ? value.trim() : value;
        return fn(trimmed);
      })
    }
  }

  setupIdle() {
    console.log("Loaded Datetime : ", new Date());

    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(environment.idleTimeOut);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(30);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts([new DocumentInterruptSource("keydown DOMMouseScroll touchmove scroll click"), new StorageInterruptSource()]);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
      this.reset();
    });

    // this.idle.onInterrupt.subscribe(() => {
    //   this.idleState = 'Watching has interrupted.'
    //   console.log(this.idleState, " : ", new Date(), " : ", this.router.url );
    // });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
      this.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.alertMessage.clearAlertbox();
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
      this.childModal.show();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will be logged out in ' + countdown + ' seconds!'
      // console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  ngAfterContentChecked() {
    if (this.routerurl != this.router.url) {
      this.alertMessage.clearNotifier();
      this.routerurl = this.router.url;
      this.checkMenuViewAccees();
    }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  reset() {
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    if (this.currentUser != null) {
      this.idle.watch();
      this.idleState = 'Watching Started.';
      this.timedOut = false;
      if (this.childModal)
        this.childModal.hide();
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
    }
    // else {
    //   this.idle.stop();
    // }
  }

  stay() {
    this.childModal.hide();
    this.reset();
    // this.router.navigateByUrl(this.router.url);
    window.location.reload();
  }

  logout() {
    this.childModal.hide();
    this.idle.stop();
    this.idleState = 'logout'
    console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
    this.routeEventSubscription.unsubscribe();
    this.alertMessage.clearNotifier();
    this.authenticationService.logout();
  }

  pageInitLoad() {
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    let userActiveOrgs=this.currentUser.organizations.filter(x=>x.licenseTypeID ==1);
    this.isuserActiveOrgs=0;
    if(userActiveOrgs.length>0)
    {
      this.isuserActiveOrgs=1;
      this.items=this.getOrgNodes();
    }

    let subscriberOrg = this.currentUser.organizations.filter(x=>x.customerCode == this.currentUser.customerCode);
    if(subscriberOrg.length > 0){
      if(subscriberOrg.filter(x=>x.orgID==this.currentUser.orgID).length>0)
      {
        this.SubscriberOrgID = subscriberOrg.filter(x=>x.orgID==this.currentUser.orgID)[0].subscribedOrgID;
      }
      else{
        this.SubscriberOrgID = subscriberOrg[0].subscribedOrgID;
      }
    }
    if (this.currentUser != null) {
      this.menusplit();
      this.setupIdle();

      this.routeEventSubscription = this.router.events.subscribe(async (val) => {
        if (val instanceof NavigationEnd) {
          let timeout = this.authenticationService.getTokenTime();
          if (timeout <= 180 && timeout > 0) {
            await this.authenticationService.refreshToken();
          }
          this.authenticationService.startRefreshTokenTimer();
          this.reset();
        }
      });
    }
    else {
      this.logout();
    }
  }

onBackClick(){
    this.router.navigate(['dashboard']);
  // if(this.location.getState()['navigationId'] > 1)
  // {
  //   this.location.back();
  // }
  // else{
  //   this.router.navigate(['dashboard']);
  // }
}

  menusplit() {
    let filtMenu:any = this.currentUser.menuList.filter(m => m.menuType=="profile");
    let subscriptionMenu:any = this.currentUser.menuList.filter(m => m.menuType=="subscriber" && m.url.includes("subscriptionlist"));
    let profileMenu:any = [];
    filtMenu.forEach(ele => {
        let oMenu = {attributes: null,
                    childMenu: null,
                    children: [],
                    class: "",
                    divider: null,
                    href: null,
                    icon: null,
                    label: null,
                    linkProps: null,
                    name: ele.name,
                    pageid: ele.pageID,
                    parentPageID: 0,
                    title: false,
                    url: ele.url,
                    variant: null,
                    wrapper: null};
        
        profileMenu.push(oMenu);
    });

    if(subscriptionMenu.length > 0){
      //let subMenu:any; // = subscriptionMenu.filter(m => m.url.includes("subscriptionlist"));      
      subscriptionMenu.forEach(ele => {
      let oMenu = {attributes: null,
                  childMenu: null,
                  children: [],
                  class: "",
                  divider: null,
                  href: null,
                  icon: null,
                  label: null,
                  linkProps: null,
                  name: "Subscriptions",
                  pageid: ele.pageID,
                  parentPageID: 0,
                  title: false,
                  url: ele.url,
                  variant: null,
                  wrapper: null};        
        profileMenu.push(oMenu);
      });
    }
    
    this.sidenav = profileMenu;
    let alltopnav = this.currentUser.menuList.filter(x => x.menuType == "topnav");
    let parenttopnav = alltopnav.filter(x => x.parentPageID == 0);
    for (let i = 0; i < parenttopnav.length; i++) {
      let childmenu = alltopnav.filter(x => x.parentPageID == parenttopnav[i].pageID);
      parenttopnav[i].childMenu = childmenu
    }
    this.topnav = parenttopnav;
  }

  checkMenuViewAccees() {
    this.page.setPageDetails();
    this.page.setAccessRights();
    this.horizantalmenus = [];
    this.activePageId = 0;

    if (this.router.url != "/supportdashboard" && this.router.url != "/dashboard") {
      if (this.page.accessRights.canView == true) {
        let childpages = this.currentUser.menuList.filter(x => x.pageID == this.page.pageDetails.parentPageID);
        if (childpages.length > 0) {
          this.horizantalmenus = childpages[0].childMenu;
          if (this.page.pageDetails.menuType == "function") {
            this.activePageId = this.page.pageDetails.dependantPageID;
          }
          else {
            this.activePageId = this.page.pageDetails.pageID;
          }
        }
      }
      else {
        //alert("You are not allowed to use this screen");
        this.alertMessage.alertWithCustomizeError({ message: this.translate.instant("Common.You are not allowed to use this screen") });
        this.router.navigate(['dashboard']);
      }
    }
  }

  redirectSubscriptionPayment() {
    if(this.currentUser.orgCountryID==129)
    {
      let _subParams:any = {'orgID':this.currentUser.centralOrgID,'countryID':this.currentUser.orgCountryID ,'customercode':this.currentUser.customerCode,'pageOption':1,'planType':2};
      this.router.navigate(['subscription/payment'],{queryParams: {subParams: btoa(JSON.stringify(_subParams))}});
    }
    else{
      let _subParams:any = {'orgID':this.currentUser.centralOrgID,'countryID':this.currentUser.orgCountryID ,'customercode':this.currentUser.customerCode,'pageOption':1};
      this.router.navigate(['subscription/payment'],{queryParams: {subParams: btoa(JSON.stringify(_subParams))}});
    }
  }

  redirectToBookDemo() {
    this.router.navigate(['organization/bookdemo']);
  }


  customerSearch() {
    this.searchCustomerCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.error = "";
          this.filteredCustomers = [];
          this.loading = true;
        }),
        mergeMap(value => (this.authenticationService.GetCustomersbySearchText(value))
          .pipe(
            finalize(() => {
              this.loading = false
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (data == undefined) {
          this.error = "Unable to fetch the data";
          this.filteredCustomers = [];
        } else {
          this.error = "";
          this.filteredCustomers = data.ResponseData;
        }
      }, (error) => {
        console.log(error);
      });
  }


  ChangeOrgAPI(centralOrgID: any, customerCode: any) {
    this.loading = true;
    this.authenticationService.changeOrganization(centralOrgID, customerCode).subscribe({
      next: () => {
        this.pageInitLoad();
        this.customerSearch();
        this.items = this.getOrgNodes();
        window.location.href = '';
      },
      error: error => {
        this.error = error;
        this.loading = false;
      }
    });
  }
  getOrgNodes(): TreeviewItem[] {
    let nodeitems: TreeviewItem[] = [];
    let orgs = this.currentUser.organizations;
    let custCodes = orgs.map(item => item.customerCode).filter((value, index, self) => self.indexOf(value) === index);
    custCodes.forEach(custcode => {
      let children = [];
      let custOrgs = orgs.filter(x => x.customerCode == custcode);
      let collapsed = custCodes.length == 1 || custcode == this.currentUser.customerCode ? false : true;
      custOrgs.forEach(org => {
        children.push(new TreeviewItem({ text: org.organizationName.toString(), value: org.centralOrgID, collapsed: collapsed }));
      });
      nodeitems.push(new TreeviewItem({
        text: custOrgs[0].customerName.toString(), value: 0, collapsed: collapsed, children: children
      }));
    });
    this.value = this.currentUser.centralOrgID;
    return nodeitems;
  }
  onValueChange(value: number): void {
    this.ChangeOrgAPI(value, 0);
  }
}
