<div >
	<div >
		  <form class="form-horizontal needs-validation" novalidate="novalidate" [formGroup]="ticketForm" (ngSubmit)="submitTicket()" >
			<div class="modal-content">
			  <div class="modal-header">
				<h4 class="modal-title">Submit Ticket</h4>
				<button type="button" class="close " (click)="closeModal()" >
				  <span aria-hidden="true">×</span>
				</button>
			  </div>
			  <div class="modal-body">
			  <div class="row">
			  <div class="col-md-12">
			  <!-- <div class="card card-body" style="line-height: 1.8"> -->
				  <div class="row">
					<div class="col-md-2" *ngIf="IsNew">
						<label class="col-form-label" for="Product">Product<span class="text-danger">*</span></label>
						{{ ProductID }}
						<br>
						<b>Info-Accounting</b>
					  </div>
					<div class="col-md-3" *ngIf="IsNew">
					  <label class="col-form-label" for="ticketTypeLst">Ticket Type<span class="text-danger">*</span></label>
					  <ng-select [items]="ticketTypeLst" placeholder="Select Ticket Type" id="ticketTypeLst" class="auto-grow"
						[clearable]="false" bindLabel="title" bindValue="id" formControlName="ticketType">
					  </ng-select>
					  <div class="text-danger" *ngIf="submitted && ticket.ticketType.errors">
						<div *ngIf="ticket.ticketType.errors.required">Required</div>
					  </div>
					</div>
					<!-- </div> -->
					<!-- <div class="row"> -->
					<div class="col-md-3" *ngIf="IsNew">
					  <label class="col-form-label" for="ticketAccess">Ticket Access<span class="text-danger">*</span></label>
					  <ng-select [items]="ticketAccessLst" placeholder="Select Ticket Access" id="ticketAccess"
						class="auto-grow" bindLabel="title" bindValue="id" [clearable]="false" formControlName="ticketAccess">
					  </ng-select>
					  <div class="text-danger" *ngIf="submitted && ticket.ticketAccess.errors">
						<div *ngIf="ticket.ticketAccess.errors.required">Required</div>
					  </div>
					<!-- </div> -->
				  </div>
				  <!-- <div class="row"> -->
					<div class="col-md-3" *ngIf="IsNew">
					  <label class="col-form-label">Subject <span class="text-danger">*</span></label>
		
					  <input type="text" id="subject" name="subject" class="form-control" formControlName="subject">
					  <div class="text-danger" *ngIf="submitted && ticket.subject.errors">
						<div *ngIf="ticket.subject.errors.required">Required</div>
					  </div>
					</div>
				  <!-- </div> -->
				 
				  <!-- <div class="row col my-3" *ngIf="!IsNew && (ticketStatus == 'Provide More Data' || ticketStatus == 'Provide More Data - User')">
					<button style="height:35px;width:80px;margin-left: auto;" type="button" (click)="replyTicket()" class="btn btn-info"> Reply </button>
		
					<button style="height:35px;width:80px;margin-left: auto;" type="button" class="btn btn-info" (click)="cancel()"> Back </button>
				  </div> -->
				  <!-- <div class="row">
					<div id="tui-image-editor"></div>

				</div> -->
			  </div>
			  </div>
			  
				</div>
				<div  *ngIf="IsNew || (ticketStatus == 'Provide More Data' || ticketStatus == 'Provide More Data - User')">
					<label class="col-form-label" for="remarks">Remarks <span class="text-danger">*</span></label>
					<quill-editor class="w-100" id="remarks" formControlName="remarks">
					  <div quill-editor-toolbar>
						<span class="ql-formats">
		
						  <select class="ql-size" [title]="'Size'">
							<option value="6pt"></option>
							<option value="8pt"></option>
							<option value="9pt"></option>
							<option value="10pt"></option>
							<option value="11pt"></option>
							<option value="12pt"></option>
							<option value="13pt"></option>
							<option value="14pt"></option>
							<option value="16pt"></option>
							<option value="18pt"></option>
							<option value="24pt"></option>
							<option value="30pt"></option>
							<option value="36pt"></option>
							<option value="48pt"></option>
						  </select>
						</span>
						<span class="ql-formats">
						  <button class="ql-bold"></button>
						  <button class="ql-italic"></button>
						  <button class="ql-underline"></button>
						  <button class="ql-strike"></button>
						</span>
						<span class="ql-formats">
						  <select class="ql-color"></select>
						  <select class="ql-background"></select>
						</span>
						<span class="ql-formats">
						  <button class="ql-list" value="ordered"></button>
						  <button class="ql-list" value="bullet"></button>
						  <select class="ql-align">
							<option selected></option>
							<option value="center"></option>
							<option value="right"></option>
							<option value="justify"></option>
						  </select>
						</span>
		
		
					  </div>
					</quill-editor>
				  </div>
				  
					<div  id="tui-image-editor"></div>
				  
				  
				  <!-- <img [src]="imgsrc" style="width:100%;height:100%"> -->
				  <div class="row col my-3" *ngIf="IsNew">
					<div class="col-md-12">
					  <input type="checkbox" id="agreeTC" class="form-check-input" formControlName="agreeTerms"/>
					  <label class="form-check-label mr-4 text-danger font-weight-bold" for="agreeTC">
						I agree to grant data view access to Info-Tech for trouble shooting purpose
					  </label>
					</div>
				  </div>
				</div>
			  <div class="modal-footer">
				<button type="submit" class="btn btn-info"> Submit </button>
		  
				<button type="button" class="btn btn-info" (click)="closeModal()"> Cancel </button>
			  </div>
			</div>
			
		  </form>
		  <!-- /.modal-content-->
		</div>
		<!-- /.modal-dialog-->
	  </div>
