import { Component } from "@angular/core";
import { AgRendererComponent } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

@Component({
  selector: "app-multi-markassent-cell-render",
  templateUrl: "./multi-markassent-cell-render.component.html",
  styleUrls: ["./multi-markassent-cell-render.component.scss"],
})
export class MultiMarkassentCellRenderComponent implements AgRendererComponent {
  hideMarkasSent: boolean;
  params;

  agInit(params: ICellRendererParams): void {
      this.params = params;
      if (params.data.SourceType != undefined && params.data.SourceType == "Purchase") {
        this.hideMarkasSent = !params?.data.IsPOSent;
        params.data.IsPOSent = true;
      } else if (params.data.SourceType != undefined && params.data.SourceType == "Quotation") {
        this.hideMarkasSent = !params?.data.IsSent;
        params.data.IsSent = true;
      } else if (params.data.SourceType != undefined && (params.data.SourceType == "Invoice" || params.data.TransTypeID === 8)) {
        this.hideMarkasSent = !params?.data.IsInvoiceSent;
        params.data.IsInvoiceSent = true;
      } else if (params.data.sourceType != undefined && params.data.sourceType == "Sales Credit Note") {
        this.hideMarkasSent = !params?.data.isSent;
        params.data.isSent = true;
      }
      else if (params.data.SourceType != undefined && params.data.SourceType == "Sales Order") {
        this.hideMarkasSent = !params?.data.IsSent;
        params.data.IsSent = true;
      }
      else if (params.data.SourceType != undefined && params.data.SourceType == "Debit Note") {
        this.hideMarkasSent = !params?.data.IsSent;
        params.data.IsSent = true;
      }
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  checkedHandler(event) {
    // ;
    let checked = event.target.checked;
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }
}
