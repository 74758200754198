<div bsModal #OnBoardModalFormDetail="bs-modal" class="modal fade" tabindex="-1"
    [config]="{ backdrop: 'static', keyboard: false }" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header p-0 row"><img src={{imgsrc}}>
            </div>
            <ng-container *ngIf="ShowPage1">
                <div class="modal-body">
                    <h5>Introducing E-Invoicing</h5>
                    <p>
                        To support the growth of digital economy, the government intends to implement e-Invoice in
                        stages in
                        an effort
                        to enhance the efficiency of Malaysia's tax administration management.It is in line with the
                        Twelfth
                        Malaysia Plan, where the focus is on strengthening the digital services
                        infrastructure and digitalising the tax administration. The e-Invoice will enable real time or
                        near
                        real time validation and storage of transactions,
                        catering to Business-to-Business (B2B), Business-to-Consumer (B2C) and Business-to-Government
                        (B2G)
                        Transactions.<br>
                        For more information:<br>
                        <a href="https://www.hasil.gov.my/en/e-invoice/about-e-invoice/"
                            target="_blank">1.<u>https://www.hasil.gov.my/en/e-invoice/about-e-invoice/</u></a><br>
                        <a href="https://www.hasil.gov.my/media/np1j2bpo/irbm-e-invoice-guideline-version-23.pdf"
                            target="_blank">2.<u>https://www.hasil.gov.my/media/np1j2bpo/irbm-e-invoice-guideline-version-23.pdf</u></a><br>
                        <a href="https://www.hasil.gov.my/media/iuafn1o0/irbm-e-invoice-specific-guideline-version-21.pdf"
                            target="_blank">3.<u>https://www.hasil.gov.my/media/iuafn1o0/irbm-e-invoice-specific-guideline-version-21.pdf</u></a>

                    </p>
                    <hr>
                    <h5>Implementation Timeline:</h5>
                    <table class="table table-bordered w-75">
                        <thead class="table-color">
                            <tr>
                                <th class="w-15">Category</th>
                                <th class="w-25">Targeted Taxpayers</th>
                                <th class="w-25">Implementation Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Category 1</td>
                                <td>Taxpayers with an annual turnover or revenue of more than RM100 million</td>
                                <td>1 August 2024</td>
                            </tr>
                            <tr>
                                <td>Category 2</td>
                                <td>Taxpayers with an annual turnover or revenue of more than RM25 million and upto
                                    RM100
                                    million</td>
                                <td>1 January 2025</td>
                            </tr>
                            <tr>
                                <td>Category 3</td>
                                <td>All Taxpayers</td>
                                <td>1 July 2025</td>
                            </tr>
                            <tr>
                                <td>Category 4</td>
                                <td>Exempted,Clause 1.6,<br>
                                    <a href="https://www.hasil.gov.my/media/np1j2bpo/irbm-e-invoice-guideline-version-23.pdf"
                                        target="_blank"><u>https://www.hasil.gov.my/media/np1j2bpo/irbm-e-invoice-guideline-version-23.pdf</u></a>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>

                    </table>
                    <hr>
                    <h5>Please select your taxpayer category.</h5>
                    Please be advised that this selection cannot be modified once submitted. <span
                        class="text-danger">*</span>
                    <div>
                        <mat-radio-group [(ngModel)]="categoryvalue" [disabled]="isredirectfromsettings"
                            class="d-flex flex-column">
                            <mat-radio-button value="1">Category 1
                            </mat-radio-button>
                            <mat-radio-button value="2">Category 2
                            </mat-radio-button>
                            <mat-radio-button value="3">Category 3
                            </mat-radio-button>
                            <mat-radio-button value="4">Category 4
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                </div>
                <div class="modal-footer">
                    <div class="row col-12">
                        <div class="col-6 d-flex p-0">
                            <button class="btn btn-info" *ngIf="!isredirectfromsettings && categoryvalue != 1"
                                type="button" (click)="closeModal()">Skip</button>
                        </div>
                        <div class="col-6 d-flex  justify-content-end">
                            <button class="btn btn-info" type="button" (click)="OpenOnBoardModalFormDetail()"
                                *ngIf="currentUser.userType!=2">Next</button>
                            <button class="btn btn-primary" type="button" (click)="closeModal()"
                                *ngIf="isredirectfromsettings">Close</button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="ShowPage2">              
                <div class="modal-body">
                    <h5>Please provide or review the mandatory information below.</h5>
                    <form [formGroup]="OnBoardForm">
                        <div class="row">
                            <div class="col-6">
                                <label class="col-form-label" for="supplierName">Taxpayer Name
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" maxlength="100" formControlName="supplierName"
                                    placeholder="Taxpayer Name" 
                                    [ngClass]="{ 'is-invalid': submitted && f.supplierName.errors }" xssDirective />
                                <div *ngIf="submitted && f.supplierName.errors?.required" class="invalid-feedback">
                                    Mandatory
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="col-form-label" for="supplierTIN">Tax Identification Number (TIN)
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" maxlength="15" formControlName="supplierTIN"
                                    placeholder="C1234567890" alphabetnumeric
                                    [ngClass]="{ 'is-invalid': submitted && f.supplierTIN.errors }" xssDirective />
                                <div *ngIf="submitted && f.supplierTIN.errors?.required" class="invalid-feedback">
                                    Mandatory
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 p-0">
                                <label class="col-12 m-0" for="supplierRegNo">Registration / Identification
                                    Number /Passport Number
                                    <span class="text-danger">*</span>
                                </label>
                                <label class="col-12"><small class="text-muted">For Businesses:Business registration
                                        number;For Malaysian individual:MyKad/
                                        MyTentera identification number;For non-Malaysian individual:Passport Number/
                                        MyPR/MyKAS identification number
                                    </small>
                                </label>
                            </div>
                            <div class="col-6">
                                <div class="input-group mb-3">
                                    <ng-select [clearable]="false" [items]="supplierregtype" bindLabel="name" bindValue="key" class="idtype"
                                        formControlName="SupplierRegNotype" [searchable]="false" placeholder="Type"
                                        [ngClass]="{ 'is-invalid': submitted && f.SupplierRegNotype.errors }">
                                    </ng-select>
                                    <input type="text" class="form-control" maxlength="15" placeholder="1234567890" alphabetnumeric
                                        formControlName="supplierRegNo" [ngClass]="{ 'is-invalid': submitted && f.supplierRegNo.errors }"
                                        xssDirective />                                
                                    <div *ngIf="submitted && (f.SupplierRegNotype.errors?.required || f.supplierRegNo.errors?.required)"
                                        class="invalid-feedback">
                                        Mandatory
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 p-0">
                                <label class="col-12 m-0" for="supplierSSTNo">SST Registration Number</label>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" maxlength="20" formControlName="supplierSSTNo"
                                    placeholder="123456789000" alphabetnumeric xssDirective />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 p-0">
                                <label class="col-12 m-0" for="supplierTourNo">Tourism Registration Number</label>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" maxlength="20" formControlName="supplierTourNo"
                                    placeholder="123456789000000" alphabetnumeric xssDirective />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="col-form-label" for="supplierEmail">E-mail
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" maxlength="100" formControlName="supplierEmail"
                                    placeholder="general@supplier.com"
                                    [ngClass]="{ 'is-invalid': submitted && f.supplierEmail.errors }" xssDirective />
                                <div *ngIf="submitted && f.supplierEmail.errors?.required" class="invalid-feedback">
                                    Mandatory
                                </div>
                                <div *ngIf="submitted && f.supplierEmail.errors?.pattern" class="invalid-feedback">
                                    Email is invalid
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="col-form-label" for="SupplierMISCCode">Malaysia Standard
                                    Industrial
                                    Classification (MSIC) Code
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <ng-select [clearable]="false" [items]="MISCcodes" bindLabel="description"
                                    bindValue="code" formControlName="SupplierMISCCode"  placeholder="MSIC Code"
                                    [ngClass]="{ 'is-invalid': submitted && f.SupplierMISCCode.errors }">
                                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                        <div>
                                            {{item.description}}
                                        </div>
                                    </ng-template>
                                </ng-select>
                                <div *ngIf="submitted && f.SupplierMISCCode.errors?.required" class="invalid-feedback">
                                    Mandatory
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="col-form-label" for="SupplierActDescription">Business Activity
                                    Description <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-6  mt-2">
                                <input type="text" class="form-control" maxlength="1000" placeholder="Growing of maize"
                                    [ngClass]="{ 'is-invalid':submitted && f.SupplierActDescription.errors }"
                                    formControlName="SupplierActDescription" xssDirective />
                                <div *ngIf="submitted && f.SupplierActDescription.errors?.required"
                                    class="invalid-feedback">
                                    Mandatory
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="col-form-label" for="SupplierAddress">Address <span
                                        class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" maxlength="100"
                                    formControlName="SupplierAddress"  placeholder="Address"
                                    [ngClass]="{ 'is-invalid':submitted && f.SupplierAddress.errors }" xssDirective />
                                <div *ngIf="submitted && f.SupplierAddress.errors?.required" class="invalid-feedback">
                                    Mandatory
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="col-form-label" for="SupplierAddress">City Name / Postal Zip
                                    Code <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-3">
                                <input type="text" class="form-control" maxlength="30" formControlName="SupplierCity"
                                    placeholder="City Name" alphabets
                                    [ngClass]="{ 'is-invalid':submitted && f.SupplierCity.errors }" xssDirective />
                                <div *ngIf="submitted && f.SupplierCity.errors?.required" class="invalid-feedback">
                                    Mandatory
                                </div>
                            </div>
                            <div class="col-3">
                                <input type="text" class="form-control" maxlength="10" numbers
                                    formControlName="SupplierPostalZipCode" placeholder="Zip Code"
                                    [ngClass]="{ 'is-invalid':submitted && f.SupplierPostalZipCode.errors }"
                                    xssDirective />
                                <div *ngIf="submitted && f.SupplierPostalZipCode.errors?.required"
                                    class="invalid-feedback">
                                    Mandatory
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="col-form-label" for="SupplierAddress">State / Country
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-3 mb-2">
                                <ng-select [clearable]="false" [items]="State" bindLabel="State" bindValue="Code"
                                    formControlName="SupplierState" placeholder="State"
                                    [ngClass]="{ 'is-invalid': submitted && f.SupplierState.errors }">
                                </ng-select>
                                <div *ngIf="submitted && f.SupplierState.errors?.required" class="invalid-feedback">
                                    Mandatory
                                </div>
                            </div>
                            <div class="col-3 mb-2">
                                <ng-select [clearable]="false" [items]="Country" bindLabel="countryName"
                                    bindValue="countryISOCode" formControlName="SupplierCountry"
                                    [ngClass]="{ 'is-invalid': submitted && f.SupplierCountry.errors }">
                                </ng-select>
                                <div *ngIf="submitted && f.SupplierCountry.errors?.required" class="invalid-feedback">
                                    Mandatory
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="col-form-label" for="SupplierContactNo">Contact Number <span
                                        class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" maxlength="15"
                                    formControlName="SupplierContactNo" phonecodenumber placeholder="123456789"
                                    [ngClass]="{ 'is-invalid':submitted && f.SupplierContactNo.errors }" xssDirective />
                                <div *ngIf="submitted && f.SupplierContactNo.errors?.required" class="invalid-feedback">
                                    Mandatory
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="col-form-label" >Access Point<span
                                        class="text-danger">*</span>                                   
                                </label>                               
                            </div>
                            <div class="col-6">                      
                                <input type="radio" [value]="2" formControlName="AccessPointID" id="LHDN">
                                <label  for="LHDN">&nbsp;LHDN</label>                                                         
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" [value]="1" formControlName="AccessPointID" id="Peppol">
                                    <label  for="Peppol">&nbsp;Peppol</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" [value]="3" formControlName="AccessPointID"  id="LHDNPeppol">
                                <label  for="LHDNPeppol">&nbsp;LHDN & Peppol</label>                                                                
                                <div *ngIf="submitted && f.AccessPointID.errors?.min" class="text-danger" style="font-size: 80%;">
                                    Mandatory
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="custom-control-mid custom-checkbox">
                                <input id="Acknowledgement1" type="checkbox" class="form-check-input"
                                    formControlName="Acknowledgement" />
                                <label class="">By Proceeding, I confirm the
                                    accuracy of my submitted information and authorise Info-Tech
                                    Systems Integrators (M) Sdn Bhd to serve as
                                    my E-Invoicing service provider.</label>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="row col-12">
                                <div class="col-6 d-flex p-0">
                                    <button class="btn btn-info" *ngIf="!isredirectfromsettings && categoryvalue != 1"
                                        type="button" (click)="closeModal()">Skip</button>
                                    <!--<div class="text-muted d-flex align-items-center" *ngIf="!isredirectfromsettings">
                                        **Until 31st July, the user can skip this onboarding e-invoice popup
                                    </div> -->
                                </div>
                                <div class="col-6  d-flex  justify-content-end">
                                    <button class="btn btn-danger" type="button"
                                        (click)="closeOnBoardModalFormDetail()">Back</button>
                                    <button
                                        [ngClass]="!f.Acknowledgement.value? 'btn btn-secondary' : 'btn btn-primary'"
                                        type="button" *ngIf="!isredirectfromsettings || this.currentUser.userType == 2"
                                        [disabled]="!f.Acknowledgement.value" (click)="onSubmit('save')">Save</button>
                                    <button class="btn btn-info" type="button" (click)="closeModal()"
                                        *ngIf="isredirectfromsettings">Close</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-container>
        </div>
    </div>
</div>
