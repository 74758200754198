<div class="d-inline-block custom-file">
    <button type="button" class="btn btn-outline-info ml-2" [autoClose]="'outside'" placement="left"
        [ngbPopover]="popContent" #popOver="ngbPopover" popoverClass="custom-popover">
        <span class="c-icon material-icons-outlined m-0">attach_file</span>
        <small class="mx-1" *ngIf="lstEmailAttachments.length > 0">{{usedAttachmentSize}}</small>
    </button>
    <ng-template #popContent>
        <div class="popover-title popover-header">{{'SharedModule.Attachment' | translate}} <small class="text-mute">({{'SharedModule.Used' | translate}} {{usedAttachmentSize}} /
                {{'SharedModule.Maximun 10 MB' | translate}})</small>
            <span class="material-icons-outlined font-lg float-right cursor-pointer"
                (click)="CloseDialog()">close</span>
        </div>
        <div class="p-1 mnh-200 max-scroll">
            <mat-progress-bar mode="indeterminate" *ngIf="progressBar"></mat-progress-bar>
            <div class="text-center mt-5" *ngIf="fileAttachments.length === 0">
                <span class="material-icons-outlined font-6xl text-light">description</span>
            </div>
            <div class="popover-table-3">
                <table class="table table-responsive-sm">
                    <tbody>
                        <tr *ngFor="let file of fileAttachments let i = index;">
                            <td>
                                <input type="checkbox" [(ngModel)]="file.isAttached" (change)="selectFile();" />
                            </td>
                            <td><span *ngIf="file.fileExtension.toLowerCase() === 'pdf'"
                                    class="material-icons text-danger">
                                    picture_as_pdf
                                </span>
                                <span
                                    *ngIf="file.fileExtension.toLowerCase() === 'jpg' || file.fileExtension.toLowerCase() === 'jpeg' || file.fileExtension.toLowerCase() === 'gif' || file.fileExtension.toLowerCase() === 'png'"
                                    class="material-icons text-warning">
                                    image
                                </span>
                                <span
                                    *ngIf="file.fileExtension.toLowerCase() !== 'pdf' && !(file.fileExtension.toLowerCase() == 'jpg' || file.fileExtension.toLowerCase() === 'jpeg' || file.fileExtension.toLowerCase() == 'gif' || file.fileExtension.toLowerCase() == 'png')"
                                    class="material-icons text-success">
                                    text_snippet
                                </span>
                            </td>
                            <td>
                                <a class="text-primary cursor-pointer"
                                    (click)="downloadAttachmentFile(file.fileName, file.filePath,file.filbytes)">
                                    {{file.fileName}} <small>{{file.fileSize}}</small> <br>
                                    <small class="min-text">{{'SharedModule.Last updated on' | translate}} <span
                                            *ngIf="!!file.createdDate">{{file.createdDate | date
                                            :'dd
                                            MMM, yyyy HH:mm':'UTC'}}</span>
                                        <span *ngIf="file.createdDate == undefined">{{'SharedModule.few minutes ago' | translate}}</span>
                                    </small>
                                </a>
                            </td>
                            <td>
                                <i class="fa fa-trash" *ngIf="file.fileID == 0" (click)="deleteFile(i)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="dropdown-divider"></div>
        <div class="text-center">
            <label *userCanEdit="[true]" for="file-upload" class="custom-file-upload btn btn-info">
                <i class="fa fa-paperclip mr-1"></i> {{'SharedModule.Attach more files' | translate}}
            </label>
            <input *userCanEdit="[true]" type="file" #fileUploader id="file-upload" class="custom-file-inputs" multiple
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.txt,.xls"
                (change)="onFileChange($event)">
        </div>
    </ng-template>
</div>
