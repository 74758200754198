import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-create-node',
  templateUrl: './create-node.component.html',
  styleUrls: ['./create-node.component.scss']
})
export class CreateNodeComponent implements OnInit {
  isChecked: boolean;
  nodedetails = { groupHeader: '', balanceType: false, totalRequired: true };
  showExistErrorMsg = false;

  @Output() createNode = new EventEmitter<any>();
  @Input() existingGroupNames = [];

  ngOnInit(): void {
  }

  constructor() {
  }

  onClose(): void {
    this.createNode.emit(false);
    // this.dialogRef.close();
  }

    setBalanceType(isChecked) {

    this.nodedetails.balanceType = isChecked
  }

  submit(addForm: NgForm) {
    if (addForm.invalid) {
      return;
    }
    if (this.existingGroupNames.includes(this.nodedetails.groupHeader.toLowerCase())) {
      this.showExistErrorMsg = true;
      return;
    }
    this.createNode.emit(this.nodedetails);
    // this.dialogRef.close(this.nodedetails);
  }

}
