import { Injectable, Inject } from '@angular/core';
import { EncryptDecryptService } from './encryptdecrypt.service'
import { LocalCacheServices } from './acclocalcache.service'
import { Router,NavigationEnd  } from '@angular/router';
import { PageDetails,AccessRights } from '../../utilities/_models/page';

@Injectable({
  providedIn: 'root'
})

export class PageServices {
  public pageDetails: PageDetails;
  public accessRights: AccessRights;
  constructor(private encdec:EncryptDecryptService,private local:LocalCacheServices, private router: Router) {
  }

  setPageDetails(){
    let users = JSON.parse(this.encdec.decode(localStorage.getItem(this.local.localCurrentUserKey)));
    let routerurl = this.router.url;
    let spliturl = routerurl.split("?");
    let url = spliturl[0];
    let titleMenu = users.menuList.filter(x =>x.url == url);
    this.pageDetails= new PageDetails;
    if (titleMenu.length > 0) {
      this.pageDetails = titleMenu[0];
    }

  }

  setAccessRights(){
    let users = JSON.parse(this.encdec.decode(localStorage.getItem(this.local.localCurrentUserKey)));
    let spliturl = this.router.url.split("?");
    let url = spliturl[0];
    let currentMenu = users.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == users.userRole);
    this.accessRights= new AccessRights;
    if (currentMenu.length > 0) {
      this.accessRights = currentMenu[0];
    }
    //console.log("userAccess",this.accessRights );
  }
}
