import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { HttpServices } from '../../../utilities/_services/http.service';
import { environment } from '../../../../environments/environment'
import { PageServices } from '../../../utilities/_services/page.service';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GridOptions, Module, AllCommunityModules } from "@ag-grid-community/all-modules";
import { MasterServices } from '../../../utilities/_services/master.service';
import * as moment from "moment";
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fileattachment',
  templateUrl: './fileattachment.component.html',
  styleUrls: ['./fileattachment.component.scss']
})
export class FileattachmentComponent implements OnInit {
  @ViewChild('showaddItemmodal') public showaddItemmodal: ModalDirective;
  @ViewChild('showDocumentGroupModal') public showDocumentGroupModal: ModalDirective;
  @ViewChild('showLibrarymmodal') public showLibrarymmodal: ModalDirective;
  @Input() buttonText: string;
  fileAttachments: any = [];
  postFileAttachment: any = [];
  fileName: any;
  fileSrc: any;
  @Input() transID: any;
  @Input() typeID: any;
  @Output() attachmentFiles = new EventEmitter();
  isDeleteButton: any = {};
  groupName: string = '';
  errorNotifierId: number = 0;
  succesNotifierId: number = 0;
  errorList: any = [];
  @ViewChild('fileUploader') fileUploader: ElementRef;
  groupNames: any;
  columnDefs: any;
  modules: Module[] = AllCommunityModules;
  public groupGridOptions: GridOptions;
  rowSelection: any;
  rowGroupPanelShow: any;
  paginationPageSize: any;
  public overlayLoadingTemplate: string;
  overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No more row to display</span>';
  public allGridOptions: GridOptions;
  rowData: any = [];
  domLayout: string = "autoHeight";
  moveGroupNames: any = [];
  fileAttachmentMove: any = [];
  rowGroupData: any = [];
  groupNameData: any = [];
  groupNameDataFile: any = [];
  SelectedData: any = [];
  hasTransactionType: any;
  groupNamesInbox: any = {};
  totalGridRows: Number = 0;
  searchText: any;
  numSelectedItems: number = 0;
  progressBar: boolean;
  @Input() attachfileIDs: any;
  @ViewChild('popOver') public popover: NgbPopover;
  constructor(private http: HttpServices, private master: MasterServices, private page: PageServices,
    private alertMessage: AlertMessage, private translate: TranslateService) {
    this.rowSelection = 'single';
    this.rowSelection = 'multiple';
    this.numSelectedItems = 0;
    this.translate.onDefaultLangChange.subscribe(async () => {
      this.allGridOptions.api.refreshHeader();
    });
    this.translate.onLangChange.subscribe(async () => {
      this.allGridOptions.api.refreshHeader();
    });
  }

  async getTransactionType() {
    this.hasTransactionType = await this.master.GetTransactionType();
  }

  ngOnInit(): void {
    this.getGroupName();
    this.createColumnDefs();
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
    this.paginationPageSize = 10;
    this.allGridOptions = <GridOptions>{}
    this.getTransactionType();
    this.getGroupNameFiles();

    //file attached based on page
    setTimeout(() => {
      if (this.transID != undefined) {
        if (this.transID.toString() !== '0') {
          this.getFiles(this.transID, this.typeID);
        }
      }

      if (this.attachfileIDs != '' && !!this.attachfileIDs) {
        let transactionType = this.hasTransactionType.filter(x => x.trasactionTypeID == this.typeID)[0];
        let groupNameTrans = transactionType.trasactionTypeName.replace(/(\r\n|\n|\r)/gm, "").trim();
        this.groupName = groupNameTrans.replace(/ /g, "_");
        this.fileAttachments = [];
        this.postFileAttachment = [];
        const fileIDs = this.attachfileIDs.split(',');
        for (let i = 0; i < fileIDs.length; i++) {
          const file = this.groupNameDataFile.filter(x => x.fileID == fileIDs[i])[0];
          let filesAttachObj = {
            fileID: file.fileID,
            transactionID: this.transID,
            transactionTypeID: this.typeID,
            filbytes: "",
            fileName: file.fileName,
            fileGroup: file.fileGroup ? file.fileGroup : this.groupName,
            filePath: file.filePath,
            contentLength: file.contentLength
          }
          //this.fileAttachmentMove.push(filesAttachObj);
          this.fileAttachments.push(filesAttachObj);
          this.postFileAttachment.push(filesAttachObj);
          this.attachmentFiles.emit(this.postFileAttachment);
          //
        }
      }
    }, 1500);
    //file attached based on page

  }

  CloseDialog() {
    this.popover.close();
  }

  url: any = [];
  onFileChange(event: any) {

    let files:any;
    if (event instanceof Event && event.target instanceof HTMLInputElement) {
      files = event.target.files;
    } else if (event instanceof FileList) {
      files = event;
    } else {
      return;
    }

    let isEmailMsg: boolean = false;
    let allowedExtn = ['.doc', '.docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/*', '.pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.txt', '.xls', 'image/png', 'image/jpg', 'image/jpeg', '.xlsx', 'application/pdf', '.msg', 'text/plain', 'message/rfc822', 'image/gif', 'application/vnd.ms-outlook'];
    let transactionType = this.hasTransactionType.filter(x => x.trasactionTypeID == this.typeID)[0];
    let groupNameTrans = transactionType.trasactionTypeName.replace(/(\r\n|\n|\r)/gm, "").trim();
    this.groupName = groupNameTrans.replace(/ /g, "_");

    let filesCount = 0,uploadfilesize=0,sizecount=0,postfilesize=0;
    if(this.transID==0){
      for(var i=0;i<this.postFileAttachment.length;i++){
        postfilesize+=this.postFileAttachment[i].contentLength;
      }
    }
    if (!!files && this.fileLimtValidation(this.fileAttachments.length, files.length)) {
      filesCount = files.length;
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.name !== null && file.name !== undefined && file.name.endsWith(".msg")) {
          isEmailMsg = true;
        }
        if (allowedExtn.includes(file.type) || isEmailMsg) {
          uploadfilesize += file.size;
          if(this.transID==0 && ((postfilesize+uploadfilesize)/1024)/1024 > 20){
            let fileSize = 'Maximum of 20MB file size only allowed to upload.';
            this.errorNotifierId = this.alertMessage.errorNotifier(fileSize,1);
            this.progressBar = false;
            this.errorList = [];
            sizecount++;
            return;
          }
          else if(((uploadfilesize / 1024) / 1024) > 20){
            let fileSize = 'Maximum of 20MB file size only allowed to upload.';
            this.errorNotifierId = this.alertMessage.errorNotifier(fileSize,1);
            this.progressBar = false;
            this.errorList = [];
            sizecount++;
            if (!!this.fileUploader)
              this.fileUploader.nativeElement.value = null;
            return;
          }
          else if (file.size <= 10 * 1048576) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = (event: any) => {
              this.fileSrc = reader.result as string;
              this.fileName = file.name;
              this.url.push(event.target.result)
              let filesAttachObj = {
                fileID: 0,
                transactionID: this.transID ? this.transID : 0,
                transactionTypeID: this.typeID,
                filbytes: this.fileSrc,
                fileName: this.fileName,
                FileGroup: this.groupName,
                contentLength: file.size
              }

                if(sizecount==0){
                  this.fileAttachments.push(filesAttachObj);
                  this.postFileAttachment.push(filesAttachObj);
                  if (filesCount === this.postFileAttachment.length) {
                    this.attachmentFiles.emit(this.postFileAttachment);
                    filesCount = 0;
                    if (this.transID.toString() !== '0') {
                      this.fileUpload(this.postFileAttachment)
                    }
                  }
                }

              if (!!this.fileUploader)
                this.fileUploader.nativeElement.value = null;
            }
            } else {
              if (!!this.fileUploader)
                this.fileUploader.nativeElement.value = null;
              let fileSize = file.name + ' - File size should be maximum 10MB. </br>';
              this.errorList.push({ message: fileSize });
              filesCount--;
            }
          } else {
            let fileSize = file.name + ' - File extension is not allowed. </br>';
            this.errorList.push({ message: fileSize });
            filesCount--;
          }
      }
      if (this.errorList.length > 0 && sizecount==0) {
        this.errorNotifierId = this.alertMessage.errorNotifierList(this.errorList, this.errorNotifierId);
        this.progressBar = false;
        this.errorList = [];
        if (!!this.fileUploader)
          this.fileUploader.nativeElement.value = null;
      }
    } else {
      let fileSize = 'Maximum 100 files only allowed!.';
      this.errorList.push({ message: fileSize });
      this.errorNotifierId = this.alertMessage.errorNotifierList(this.errorList, this.errorNotifierId);
      this.progressBar = false;
      this.errorList = [];

      if (!!this.fileUploader)
        this.fileUploader.nativeElement.value = null;
    }
  }

  fileLimtValidation(existFil, uploadfile) {
    let isValid = false;
    let limtfile = uploadfile + existFil;
    if (limtfile === 0) {
      isValid = true
    } else if (limtfile <= 100) {
      isValid = true
    } else {
      isValid = false
    }
    return isValid;
  }

  fileUpload(fileAttachments) {
    this.progressBar = true;
    this.http.postservice(environment.organizationApiUrl + '/Document/fileattachment?PageID=' + this.page.pageDetails.pageID, fileAttachments).subscribe({
      next: (data) => {
        //this.fileAttachments = [];
        this.postFileAttachment = [];
        this.getFiles(this.transID, this.typeID);
        this.attachmentFiles.emit(this.postFileAttachment);
        this.progressBar = false;
      }
    });
  }

  deleteFile(index, fileID, filename, filePath?: any) {
    let hasFile = this.fileAttachments.filter(x => x.fileID == fileID);
    if (hasFile.length > 0 && fileID !== 0) {
      if (this.transID.toString() == '0') {
        let fileitem = this.fileAttachments.filter(function (x, i) {
          return i == index;
        })
        this.fileAttachments.splice(index, 1);
        this.postFileAttachment = this.postFileAttachment.filter(item => item !== fileitem[0]);
      } else {
        this.deleterecord(fileID, filename, filePath);
      }
      this.isDeleteButton[index] = false;
    } else {
      let fileitem = this.fileAttachments.filter(function (x, i) {
        return i == index;
      })
      this.fileAttachments.splice(index, 1);
      this.postFileAttachment = this.postFileAttachment.filter(item => item !== fileitem[0]);
      this.isDeleteButton[index] = false;
      this.attachmentFiles.emit(this.postFileAttachment);
    }
  }
  deleterecord(fileID, filename, filePath) {
    let apiUrl = environment.organizationApiUrl + '/Document/DeleteAttachementFile';
    this.http.DeleteAttachmentFile(apiUrl, fileID, this.typeID, this.page.pageDetails.pageID, this.transID, filename, filePath).subscribe({
      next: (data) => {
        this.getFiles(this.transID, this.typeID);
      }
    })
  }

  getFiles(TransID, TransTypeID) {
    let apiUrl = environment.organizationApiUrl + '/Document/GetFiles/' + TransID + '/' + TransTypeID;
    this.http.getservice(apiUrl).subscribe({
      next: (data) => {
        if (this.postFileAttachment.length === 0) {
          // this.fileAttachments = data['value'];
          this.fileAttachments = data['responseData'];
        } else if (!!this.postFileAttachment) {
          this.fileAttachments = data['responseData'];
        }
      }
    })
  }

  downloadAttachmentFile(FileName: any, filePath: any, fileSrc: any) {
    var todayDate = new Date();
    let currntTime = todayDate.toTimeString().split(' ')[0];
    let month = todayDate.getUTCMonth() + 1;
    let currentDate = todayDate.getUTCDate().toString() + '-' + month + '-' + todayDate.getUTCFullYear().toString() + ':' + currntTime;
    if (!!filePath) {
      let apiUrl = environment.organizationApiUrl + '/Document/DownloadAttachFile';
      this.http.DownloadAttachmentFile(apiUrl, FileName, filePath).subscribe({
        next: (data) => {
          var hiddenElement = document.createElement('a');
          hiddenElement.href = data['responseData'];
          hiddenElement.target = '_blank';
          hiddenElement.download = FileName;
          hiddenElement.click();
        }
      })
    } else {
      var hiddenElement = document.createElement('a');
      hiddenElement.href = fileSrc;
      hiddenElement.target = '_blank';
      hiddenElement.download = FileName;
      hiddenElement.click();
    }
  }

  fileExtension(fileName: any) {
    let ext = fileName.split('.');
    console.log(ext[ext.length-1].toLowerCase());
    return ext[1].toLowerCase();
  }

  deleteRecordButton(i) {
    this.isDeleteButton[i] = true;
  }

  moveFile(file: any, destFileGrpName: string, i?: any) {
    // ;
    let fileRecord = file;
    let filesAttachObj = {
      fileID: fileRecord.fileID,
      transactionID: fileRecord.transactionID,
      transactionTypeID: fileRecord.transactionTypeID,
      filbytes: "",
      fileName: fileRecord.fileName,
      fileGroup: fileRecord.fileGroup,
      filePath: fileRecord.filePath
    }
    this.fileAttachmentMove.push(filesAttachObj);
    let moveFileGroup = {
      PageID: 0,
      destGroupName: destFileGrpName,
      fileAttachment: this.fileAttachmentMove
    }
    this.isDeleteButton[i] = false;
    this.moveDocument(moveFileGroup);
  }

  moveDocument(document) {
    this.http.postservice(environment.organizationApiUrl + '/Document/MoveFile', document).subscribe({
      next: (data) => {
        this.fileAttachmentMove = [];
        this.postFileAttachment = []
        this.getFiles(this.transID, this.typeID);
        this.getGroupNameFiles();
        this.getGroupName();
      }
    });
  }

  getGroupName() {
    this.http.getservice(environment.organizationApiUrl + '/Document/GetGroupName').subscribe({
      next: (data) => {
        this.groupNames = data['responseData'];
        this.groupNameData = this.groupNames.filter(x => x.groupName == 'Inbox');
        this.groupNames = data['responseData'].filter(x => x.groupName !== 'Inbox' && x.isSystem !== true);
        this.groupNamesInbox = data['responseData'].filter(x => x.groupName === 'Inbox' && x.isSystem === true)[0];
      },
    });
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  onGridSizeChanged(params: any) {
    params.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    this.columnDefs = [
      {
        headerName: '',
        field: "attachfile",
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      {
        headerName: "File Name",
        field: "fileName",
        headerValueGetter: (params) => this.translate.instant("SharedModule.File Name"),
        sortable: true,
        filter: true,
        unSortIcon: true
      },
      {
        headerName: "Size",
        field: "contentLength",
        headerValueGetter: (params) => this.translate.instant("SharedModule.Size"),
        sortable: true,
        filter: true,
        unSortIcon: true,
        cellRenderer: (data) => {
          return this.getFileSize(data.value, 'G')
        }
      },
      {
        headerName: "Upload Date",
        field: "createdDate",
        headerValueGetter: (params) => this.translate.instant("SharedModule.Upload Date"),
        sortable: true,
        unSortIcon: true,
        filter: true,
        cellRenderer: (data) => {
          return (data.data.createdDate.split('T')[0] === '1900-01-01' ? null : moment(data.data.createdDate).format('DD MMM YYYY HH:mm A'))
        }

      },
      {
        headerName: "Upload By",
        field: "createdByName",
        headerValueGetter: (params) => this.translate.instant("SharedModule.Upload By"),
        sortable: true,
        unSortIcon: true,

      },
      {
        headerName: "Moved Date",
        field: "updatedDate",
        headerValueGetter: (params) => this.translate.instant("SharedModule.Moved Date"),
        sortable: true,
        unSortIcon: true,
        cellRenderer: (data) => {
          return (data.data.updatedDate.split('T')[0] === '0001-01-01' ? "" : moment(data.data.updatedDate).format('DD MMM YYYY HH:mm A'))
        }
      },
      {
        headerName: "Moved By",
        field: "updatedByName",
        headerValueGetter: (params) => this.translate.instant("SharedModule.Moved By"),
        sortable: true,
        unSortIcon: true,
      }
    ];
  }

  openLibararyDocument() {
    this.popover.close();
    this.fileAttachmentMove = [];
    this.SelectedData = [];
    this.getGroupNameFiles();
    this.showLibrarymmodal.show()
  }

  closeLibararyDocument() {
    this.fileAttachmentMove = [];
    this.SelectedData = [];
    this.showLibrarymmodal.hide()
  }

  fileMoveOption() {
    this.moveGroupNames = this.groupNames.filter(x => x.groupName !== 'Inbox');
    this.showDocumentGroupModal.show();
  }

  cancelFileMove() {
    this.showDocumentGroupModal.hide();
  }

  getGroupNameFiles() {
    this.http.getservice(environment.organizationApiUrl + '/Document/GetGroupNameFile').subscribe({
      next: (data) => {
        this.numSelectedItems = 0;
        this.groupNameDataFile = data['responseData'];
        if (!!this.groupNameData[0]) {
          this.rowGroupData = data['responseData'].filter(x => x.fileGroupID === this.groupNameData[0].fileGroupID);
          this.totalGridRows = this.rowGroupData.length;
        }
      },
    });
  }

  groupNameOption(grp: any) {
    this.rowGroupData = this.groupNameDataFile.filter(x => x.fileGroupID === grp.fileGroupID);
    this.totalGridRows = this.rowGroupData.length;
    this.numSelectedItems = 0;
  }

  onRowSelected(params: any) {
    this.SelectedData = params.api.getSelectedRows();
    this.numSelectedItems = this.SelectedData.length;
  }

  addfiles() {
    // ;
    this.SelectedData.forEach(ele => {
      if (this.fileAttachments.length < 100) {
        let isHaveFile = this.fileAttachments.some(x => x.fileID == ele.fileID);
        if (!isHaveFile) {
          let filesAttachObj = {
            fileID: ele.fileID,
            transactionID: this.transID,
            transactionTypeID: this.typeID,
            filbytes: "",
            fileName: ele.fileName,
            fileGroup: ele.fileGroup ? ele.fileGroup : this.groupName,
            filePath: ele.filePath,
            contentLength: ele.contentLength
          }
          this.fileAttachmentMove.push(filesAttachObj);
          this.fileAttachments.push(filesAttachObj);
          this.postFileAttachment.push(filesAttachObj);
          this.attachmentFiles.emit(this.postFileAttachment);
        } else {
          let fileSize = 'File already attached, Please attach another file!.';
          this.errorList.push({ message: fileSize });
          this.errorNotifierId = this.alertMessage.errorNotifierList(this.errorList, this.errorNotifierId);
          this.errorList = [];
        }
      } else {
        let fileSize = 'Maximum 100 files only allowed!.';
        this.errorList.push({ message: fileSize });
        this.errorNotifierId = this.alertMessage.errorNotifierList(this.errorList, this.errorNotifierId);
        this.errorList = [];
      }
    });
    let moveFileGroup = {
      PageID: 0,
      destGroupName: this.groupName,
      fileAttachment: this.fileAttachmentMove
    }
    if (this.transID.toString() !== '0') {
      this.SelectedData = [];
      this.moveDocument(moveFileGroup);
      this.showLibrarymmodal.hide();
    } else {
      this.SelectedData = [];
      this.showLibrarymmodal.hide();
    }
  }

  onQuickFilterChanged() {
    this.allGridOptions.api.setQuickFilter(this.searchText);
    this.totalGridRows = this.allGridOptions.api.getDisplayedRowCount();
    if (this.totalGridRows == 0) {
      this.allGridOptions.api.showNoRowsOverlay();
    }
    else {
      this.allGridOptions.api.hideOverlay();
    }
  }

  getFileSize(bytes, callFrom) {
    let size = '';
    if (bytes > 0) {
      let kb = bytes / 1024;
      let mb = kb / 1024;
      let gb = mb / 1024;

      if (kb < 1024) {
        size = (Math.round(kb * 100) / 100).toString() + ' KB';
      }
      else if (mb < 1024) {
        size = (Math.round(mb * 100) / 100).toString() + ' MB';
      }
      else {
        size = (Math.round(gb * 100) / 100).toString() + ' GB';
      }
    }
    if (size != '' && callFrom == 'F') {
      size = '(' + size + ')'
    }
    return size;
  }
}
