<div bsModal #showLayout="bs-modal" class="modal modal-fullscreen bounceInLeft" tabindex="-1" role="dialog"
  [config]="{backdrop: 'static', keyboard: false}" aria-labelledby="showitemImportmodalLabel" aria-hidden="true">
  <div class="modal-dialog modal-primary " role="document">
    <div class="modal-content">
      <div id="btnclickOutSide" class="modal-header h4" (click)="clickOutSide($event)">
        {{title}}
        <button id="btncloseDialog-1" class="close float-right" (click)="closeDialog(false)"><span class="text-white"
            aria-hidden="true">x</span></button>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col-md-3">
            <div class="card h-100">
              <div id="btnclickOutSide" class="card-header" (click)="clickOutSide($event)">
                <i class="fa fa-cog"></i>{{'Settings' | translate}}
              </div>
              <div class="card-body p-2">
                <form class="form" style="display: flex;flex-flow: column;height: 100%;">
                  <div class="row" *ngIf="selectedID">
                    <div class="col-md-12" *ngIf="parseValue(nodeSelected.IsNode)">
                      <label class="form-label" for="header">{{'ReportsModule.Header' | translate}}</label>
                      <input type="text" id="header" class="form-control" placeholder="Untitled Header"
                        (blur)="changeHeader(header,nodeSelected)" [(ngModel)]="header" name="header" maxlength="200" />
                    </div>
                    <div class="col-md-12"
                      *ngIf="parseValue(nodeSelected.IsNode) && treeControl.isExpanded(this.nodeSelected) && (!nodeSelected.IsFormula )">
                      <div class="font-weight-bold mb-2">
                        {{'ReportsModule.Balance Type' | translate}}
                      </div>
                      <div class="col-md-12 row mb-1" *ngIf="nodeSelected.IsNode && (!nodeSelected.IsFormula )">
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                          id="balanceTypeRadio" name="IsCredit" [(ngModel)]="nodeSelected.IsCredit">
                          <mat-radio-button class="example-radio-button mr-2" (change)="changeBalance($event.value)"
                            id="balanceTypeCR" [value]="true">
                            {{'ReportsModule.Credit Positive' | translate}}
                          </mat-radio-button>
                          <mat-radio-button class="example-radio-button" (change)="changeBalance($event.value)"
                            id="balanceTypeDR" [value]="false">
                            {{'ReportsModule.Debit Positive' | translate}}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="col-md-12 mb-1"
                      *ngIf="nodeSelected.AccountType != 'TR' && parseValue(nodeSelected.RowLevel)  > 1 && (!nodeSelected.IsFormula )">
                      <button class="btn btn-info w-100" (click)="createGroup()" id="CreateGroup-1">
                        {{'ReportsModule.Create Group for' | translate}} <span *ngIf="nodeSelected.IsNode">{{nodeSelected.GroupName}}</span>
                        <span *ngIf="!nodeSelected.IsNode"> {{nodeSelected.AccountName}}</span>
                      </button>
                    </div>
                    <div class="col-md-12 mb-1"
                      *ngIf="parseValue(nodeSelected.IsNode) && parseValue(nodeSelected.RowLevel)  > 1">
                      <button class="btn btn-secondary w-100" (click)="deleteNode()" id="DeleteGroup">
                        {{'ReportsModule.Delete node' | translate}} {{nodeSelected.GroupName}}
                      </button>
                    </div>
                    <div class="col-md-12 mb-2"
                      *ngIf="nodeSelected.AccountType != 'TR' && parseValue(nodeSelected.RowLevel)  > 1 && (!nodeSelected.IsFormula ) &&  parseValue(nodeSelected.IsNode) && enableSwitchFun">
                      <button class="btn btn-info w-100" (click)="editSwitchERule()" id="CreateGroup-2">
                        <span *ngIf="!nodeSelected.SwitchGroupID">{{'ReportsModule.Add' | translate}} </span>
                        <span *ngIf="nodeSelected.SwitchGroupID > 0">{{'Common.Edit' | translate}}</span>
                        {{'ReportsModule.Switch Rule' | translate}}
                      </button>
                    </div>



                    <div class="mt-2" *ngIf="parseValue(nodeSelected.IsNode) && (!nodeSelected.IsFormula )">
                      <div class="form-check">

                        <mat-checkbox [(ngModel)]="nodeSelected.IsTotalRequired" name="IsTotalRequired" id="TRrequired"
                          (change)="changeTotalRequired(nodeSelected.IsTotalRequired)">
                          {{'ReportsModule.Total Required' | translate}}
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="parseValue(nodeSelected.IsNode) && (nodeSelected.IsFormula )">
                      {{'ReportsModule.Formula' | translate}} <br>
                      {{nodeSelected.FormulaDesc}}
                    </div>
                  </div>
                  <div class="row h-100 b-b-1" (click)="clickOutSide($event)" style="flex:1 1 auto">
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="custom-filter mt-0 mb-2" (click)="clickOutSide($event)">
              <div *ngIf="!IsSupportUser" class=" text-right">

                <button *ngIf="LayoutType?.toLowerCase() == 'c' || isEdited" class="btn btn-info mr-1" type="button"
                  (click)="RestoreDefault()" id="restoreDefault">
                  {{'ReportsModule.Restore Default' | translate}}
                </button>
                <button *ngIf="isEdited" class="btn btn-info mr-1" type="button" (click)="ResetData()" id="ResetData">
                  {{'ReportsModule.Undo Changes' | translate}}
                </button>
                <!-- <button *ngIf="showPrevBtn || LayoutType.toLowerCase() == 'c'" class="btn btn-info" type="button" (click)="showPreview()">
                  Show Previous Data
                </button> -->
                <button *ngIf="isEdited || showSaveChanges" class="btn btn-info" type="button" id="saveChanges"
                  (click)="submitChanges()">
                  {{'ReportsModule.Save Changes' | translate}}
                </button>

              </div>
            </div>

            <div class="custom-mat-tree py-1 px-2">
              <div class="d-flex align-items-stretch justify-content-end">
                <div class="d-flex align-items-stretch mw text-left">
                  <div style="width: 100%;"> </div>
                </div>
                <div class="d-flex align-items-stretch">
                  <div class=" cell-width text-right font-weight-bold p-1 border" *ngFor="let col of dynamicColumn">
                    {{col}}
                  </div>

                </div>
              </div>
              <section id="btnclickOutSide" (click)="clickOutSide($event)">

                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                <mat-tree id="btndroped" *ngIf="!loading" [dataSource]="dataSource" [treeControl]="treeControl"
                  cdkDropList (cdkDropListDropped)="droped($event)">

                  <!-- This is the tree node template for leaf nodes -->
                  <mat-tree-node class="d-flex align-items-stretch justify-content-end node-children"
                    *matTreeNodeDef="let node" matTreeNodePadding cdkDrag
                    [cdkDragDisabled]="!parseValue(node.IsDragable)" [cdkDragData]="node" (mouseenter)="dragHover(node)"
                    (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()"
                    (cdkDragEntered)="onDragEntered($event)">
                    <!-- use a disabled button to provide padding for tree leaf -->
                    <button mat-icon-button disabled></button>


                    <div class="d-flex align-items-stretch justify-content-end node_hover">
                      <div class="d-flex  align-items-stretch mw text-left flex-grow-1 border"
                        [ngClass]="'row-node-' + node.level">

                        <i *ngIf="parseValue(node.IsNode) && parseValue(node.RowLevel) > 1" class="fa  btn"
                          [ngClass]="{'fa-angle-double-down': treeControl.isExpanded(node) , 'fa-angle-double-left':!treeControl.isExpanded(node)}"
                          aria-hidden="true"></i>

                        <div style="width: 100%;" class="btn text-left" (click)="nodeClick(node)"
                          [id]="'RowNo-1-'+node.RowNo"
                          [ngClass]="{'active_node': node.RowNo == this.selectedID &&  node?.RowNo, 'font-grey disabled': node.AccountType == 'TR'}">
                          {{node.AccountName}} <span class="debit" style="color: grey;"
                            *ngIf="accType.includes(node.Flag)">
                            {{node.Flag == 'Debit'? '(Dr)' : node.Flag == 'Credit'? '(Cr)':''}}

                          </span>
                        </div>
                      </div>
                      <div class="d-flex align-items-stretch">
                        <div *ngFor="let col of dynamicColumn"
                          class=" cell-width text-right font-grey p-1 border rounded"
                          [ngClass]="{'font-grey': node.AccountType == 'TR'}">0.00</div>

                      </div>
                    </div>
                  </mat-tree-node>
                  <!-- This is the tree node template for expandable nodes -->
                  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding cdkDrag
                    [cdkDragDisabled]="!parseValue(node.IsDragable)" [cdkDragData]="node"
                    class="d-flex justify-content-end align-items-stretch " (mouseenter)="dragHover(node)"
                    (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">


                    <div class="d-flex align-items-stretch justify-content-end node_hover">
                      <div class="d-flex  align-items-stretch mw text-left flex-grow-1 border"
                        [ngClass]="'row-node-' + node.level">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
                          [id]="'bntRowNo'+node.RowNo" *ngIf="parseValue(node.RowLevel) > 1"
                          (click)="nodeClick(node);expandNode(node.RowNo);">
                          <i class="fa  btn"
                            [ngClass]="{'fa-angle-double-down': treeControl.isExpanded(node) , 'fa-angle-double-left': !treeControl.isExpanded(node)}"
                            aria-hidden="true"></i>
                        </button>


                        <div style="width: 100%;" class="btn text-left" (click)="nodeClick(node)"
                          [id]="'RowNo-2-'+node.RowNo"
                          [ngClass]="{'active_node': node.RowNo == this.selectedID &&  node?.RowNo,'font-grey': node.AccountType == 'TR'}">
                          {{node.GroupName}}
                          <span *ngIf="node.SwitchGroupID > 0" style="color: black!important;" class="float-right p-1"
                            [ngbPopover]="popTemplateHtml" triggers="mouseenter:mouseleave" [popoverTitle]="popTitle"
                            [id]="'RowNoSpan'+node.RowNo">
                            <i class='fa fa-arrow-left' [id]="'RowNoi'+node.RowNo"
                              [ngClass]="{'active_node': node.RowNo == this.selectedID}"></i>


                            <ng-template #popTitle><span style="color: #264477 !important;">{{'ReportsModule.Switch Rule' | translate}}</span>
                            </ng-template>
                            <ng-template #popTemplateHtml>
                              <div><span class="help-block">
                                  {{'ReportsModule.If Items are negative move to' | translate}} {{ getNodeNameByID(node.SwitchGroupID) }}
                                  .</span>
                              </div>
                            </ng-template>

                          </span>
                          <span class="formulafont" *ngIf="parseValue(node.IsNode) && node.IsFormula"> {{'ReportsModule.Formula' | translate}}
                          </span>
                        </div>
                      </div>
                      <div class="d-flex align-items-stretch">
                        <div *ngFor="let col of dynamicColumn"
                          class=" cell-width text-right font-grey p-1 border rounded"
                          [ngClass]="{'font-grey': node.AccountType == 'TR'}">0.00 </div>

                      </div>
                    </div>
                  </mat-tree-node>
                </mat-tree>
                <hr />
              </section>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<div class="modal fade" bsModal #childModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}" tabindex="-1"
  role="dialog" aria-labelledby="dialog-nested-name2">
  <div class="modal-dialog modal-primary">
    <div class="modal-content">
      <div class="modal-header p-2">
        <h5 id="dialog-nested-name2" class="modal-title pull-left">{{'ReportsModule.Add Group' | translate}}</h5>


        <button id="btnchildModalClose" class="close float-right" (click)="childModal.hide()"><span class="text-white"
            aria-hidden="true">x</span></button>


      </div>
      <div class="modal-body">
        <app-create-node (createNode)="hideModalCreateGroup($event)" [existingGroupNames]="existingGroupNames"
          *ngIf="showCreateNode"></app-create-node>

      </div>
    </div>
  </div>
</div>



<div class="modal fade" bsModal #switchRuleModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}"
  tabindex="-1" role="dialog" aria-labelledby="dialog-nested-name2">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header p-2">
        <h5 id="dialog-nested-name2" class="modal-title pull-left">{{'ReportsModule.Switch Rule' | translate}} {{nodeSelected?.GroupName}}</h5>


        <button id="btnswitchRuleModalHide-1" class="close float-right" (click)="switchRuleModal.hide()"><span
            class="text-white" aria-hidden="true">x</span></button>


      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5">
            <ng-select id="ddruleType" [(ngModel)]="ruleType">
              <ng-option value="0">{{'ReportsModule.If items are negative' | translate}}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <h4>{{'ReportsModule.move to' | translate}}</h4>
          </div>
          <div class="col-md-5">
            <!-- <ng-select [(ngModel)]="nodeNegative">
              <ng-option value="0">If ttems are negative</ng-option>
            </ng-select> -->

            <ng-select id="ddGroupNode" [items]="ddGroupNode" bindLabel="GroupName" bindValue="GroupID"
              [(ngModel)]="nodeNegative">
            </ng-select>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-2">
            <button id="btndeleteRule" class="btn btn-danger" (click)="deleteRule()"
              *ngIf="nodeSelected?.SwitchGroupID > 0">{{'Common.Delete' | translate}}</button>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-6  d-flex justify-content-end">
            <button id="btnaddEditRule" type="submit" class="btn btn-info" (click)="addEditRule()"> {{'ReportsModule.Add Rule' | translate}} </button>
            <button id="btncloseRuleDialog" type="button" class="btn btn-secondary" (click)="closeRuleDialog()"> {{'Common.Cancel' | translate}}
            </button>
          </div>
        </div>





      </div>
    </div>
  </div>
</div>