import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../utilities/_services/authentication.service';
import { HttpServices } from '../../utilities/_services/http.service';
@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit  {
  registerForm: FormGroup | any;
  loading = false;
  submitted = false;
  error = '';

  constructor(private http: HttpClient, private http1: HttpServices, private formBuilder: FormBuilder,private authenticationService: AuthenticationService) { }


  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      country: ['', Validators.required] 
    });
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;    
    if (this.registerForm.invalid) {
      return;
    }
    this.loading = true;
    this.http1.postservice('Subscriber/CreateSubscriber/', this.registerForm.value).subscribe({
      next: (data) => {
        this.loading = false;
      },
      error: error => {
        this.loading = false;
        this.error = error;          
      }
    }); 

    // this.http.post<any>(environment.apiUrl + '/Subscriber/CreateSubscriber',this.registerForm.value)
    //   .pipe(first())
    //   .subscribe({
    //     next: (data) => {
    //       this.loading = false;
    //     },
    //     error: error => {
    //       this.loading = false;
    //       this.error = error;          
    //     }
    //   });
  }
  
  onCancel() {
    this.submitted = false;
    this.loading = false;
    this.registerForm.setValue({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      country: ''
   });
  }

  onLogin() {    
      this.authenticationService.logout(); 
  }
}
