import { Component, Input, OnInit } from '@angular/core';
import { CTableColumn, RowType } from '../simple-ccell/simple-ccell.component';

@Component({
  selector: 'app-simple-ctable',
  templateUrl: './simple-ctable.component.html',
  styleUrls: ['./simple-ctable.component.scss']
})
export class SimpleCTableComponent implements OnInit {
  @Input() isPageEditable: boolean;
  @Input() columnDefs: CTableColumn[];

  private _rowData: any[];
  @Input() get rowData(): any[] {
    return this._rowData;
  }
  set rowData(val: any[]) {
    this._rowData = val;
    this.SetGroupRowData(val);
  }
  GroupRowData: any[];

  constructor() { }

  ngOnInit(): void {
  }

  SetGroupRowData(rowData: any[]) {
    this.GroupRowData = [];
    if (rowData) {
      this.GroupRowData = rowData.filter(o => o.ItemGroupID == 0);
      this.GroupRowData.filter(o => o.ItemID > 0).forEach(row => {
        row.lstChild = rowData.filter(o => o.ItemGroupID == row.ItemID) as any[]
      });
    }
  }
}
