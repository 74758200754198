import { Component, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from "@ag-grid-community/angular";

@Component({
    selector: 'qty-cell',
    template: `
    <div>
        <input type="text" #input class="form-control" [(ngModel)]="value" *ngIf="decimalType == 'decimal2'" decimal2 appNoRightClick 
        style="margin-bottom: 0px; height:25px;" appNoRightClick maxlength="8" (ngModelChange)="validateDecimal($event)">
        <input type="text" #input class="form-control" [(ngModel)]="value" *ngIf="decimalType == 'decimal4'" decimal4 appNoRightClick 
        style="margin-bottom: 0px; height:25px;" appNoRightClick maxlength="8" (ngModelChange)="validateDecimal($event)">
        <input type="text" #input class="form-control" [(ngModel)]="value" *ngIf="decimalType == 'Decimal10'" Decimal10
        appNoRightClick style="margin-bottom: 0px; height:25px;" appNoRightClick maxlength="16" (ngModelChange)="validateDecimal($event)">
    </div> 
    <div class="grid-sm-text">
        {{this.unitType}}
    </div>
    `,
})
export class QuanityUOMComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value: number;
    public unitType: any;
    decimalType = 'decimal2';
    @ViewChild('input', { read: ViewContainerRef }) public input;
    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
        this.unitType = this.params.data.UnitType;
        this.decimalType = !!this.params.colDef?.cellEditorParams?.decimalType ?this.params.colDef?.cellEditorParams?.decimalType :this.decimalType;
    
    }
    getValue(): any {
        return this.value;
    }
    ngAfterViewInit() {
        window.setTimeout(() => {
            this.input.element.nativeElement.focus();
        });
    }

    validateDecimal(event: number) {
        if (event.toString() == '.' || event.toString() == '.00') {
            this.value = 0.00;
        }
    }
    // afterGuiAttached() {
    //     this.input.focus();
    // }
}