import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent, SubscriptionlayoutComponent, SupportdefaultlayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { customerQuoteComponent } from './views/customerQuote/customerQuote.component';
//import  start
import { AuthGuard } from '../app/utilities/_helpers/auth.guard';
import { TemplateMasterRedirectComponent } from './views/template/template-master-redirect/template-master-redirect.component';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { OnlineinvoiceComponent } from './views/onlineinvoice/onlineinvoice.component';
import { OnlinepaymentComponent } from './views/payments/onlinepayment/onlinepayment.component';
import { SubscriptionsignupComponent } from './views/subscription/subscriptionsignup/subscriptionsignup.component';
import { SubscriptionPaymentComponent } from './views/subscription/subscriptionpayment/subscriptionpayment.component';
//import  End

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: 'customerQuote',
    component: customerQuoteComponent,
    data: {
      title: 'Quote Customer Page'
    }
  },
  {
    path: 'onlineinvoice',
    component: OnlineinvoiceComponent,
    data: {
      title: 'Online Invoice'
    }
  },
  {
    path: 'onlinepayment',
    component: OnlinepaymentComponent,
    data: {
      title: 'Online Payment'
    }
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'templates',
    component: TemplateMasterRedirectComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Template Page Redirect'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/template/template-master.module').then(m => m.TemplateMasterModule),
        canActivate: [AuthGuard]
      },
    ]
  },

  {
    path: '',
    data: {
      title: 'Links'
    },
    children: [
      {
        path: 'links',
        loadChildren: () => import('./views/links/links.module').then(m => m.LinksModule)
      }
    ]
  },
  {
    path: '',
    data: {
      title: 'Subscribe'
    },
    children: [
      {
        path: 'subscribebanks',
        loadChildren: () => import('./views/subscribebanks/subscribebanks.module').then(m => m.SubscribebanksModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'subscriptionsingup',
    component: SubscriptionsignupComponent,
    data: {
      title: 'Subscription SignUp'
    }
  },
  {
    path: 'payment',
    component: SubscriptionPaymentComponent,
    data: {
      title: 'Subscription Payment'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'organization',
        loadChildren: () => import('./views/organization/organization.module').then(m => m.OrganizationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contacts',
        loadChildren: () => import('./views/contacts/contacts.module').then(m => m.ContactsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'bankaccounts',
        loadChildren: () => import('./views/bankaccounts/bankaccount.module').then(m => m.BankaccountModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'transactions',
        loadChildren: () => import('./views/transactions/transactions.module').then(m => m.TransactionsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'purchase',
        loadChildren: () => import('./views/purchase/purchase.module').then(m => m.PurchaseModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'purchase/bills',
        loadChildren: () => import('./views/purchase/bills/bills.module').then(m => m.BillsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'purchase/receiveorder',
        loadChildren: () => import('./views/purchase/Receive/receive.module').then(m => m.ReceiveModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'purchase/creditnotes',
        loadChildren: () => import('./views/purchase/creditnotes/creditnote.module').then(m => m.CreditnotesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'purchase/pbulkpay',
        loadChildren: () => import('./views/purchase/pbulkpay/pbulkpay.module').then(m => m.PbulkpayModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sales/creditnotes',
        loadChildren: () => import('./views/sales/creditnotes/creditnote.module').then(m => m.SalesCreditnotesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'inventory',
        loadChildren: () => import('./views/inventory/inventory.module').then(m => m.InventoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sales/quotation',
        loadChildren: () => import('./views/sales/quotation/quote.module').then(m => m.QuotationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sales/invoice',
        loadChildren: () => import('./views/sales/invoice/invoice.module').then(m => m.InvoiceModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sales/delivery',
        loadChildren: () => import('./views/sales/delivery/delivery.module').then(m => m.DeliveryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sales/salesorder',
        loadChildren: () => import('./views/sales/salesorder/salesorder.module').then(m => m.SalesorderModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sales/sbulkpay',
        loadChildren: () => import('./views/sales/sbulkpay/sbulkpay.module').then(m => m.SbulkpayModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'accounting',
        loadChildren: () => import('./views/accounting/accounting.module').then(m => m.AccountingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'accounting/journalentries',
        loadChildren: () => import('./views/accounting/journalentries/journalentries.module').then(m => m.JournalentriesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'template',
        loadChildren: () => import('./views/template/template.module').then(m => m.TemplateModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'accounting/migration',
        loadChildren: () => import('./views/accounting/migration/migration.module').then(m => m.MigrationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'accounting/budget',
        loadChildren: () => import('./views/accounting/budget/budget.module').then(m => m.BudgetModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tools',
        loadChildren: () => import('./views/tools/tools.module').then(m => m.ToolsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'accounting/fixedassets',
        loadChildren: () => import('./views/accounting/fixedassets/fixedassets.module').then(m => m.FixedassetsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'payments',
        loadChildren: () => import('./views/payments/payments.module').then(m => m.PaymentsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'import',
        loadChildren: () => import('./views/import/import.module').then(m => m.HistoricalMigrationModule),
        //canActivate: [AuthGuard]
      },
      {
        path: 'help',
        loadChildren: () => import('./views/help/help.module').then(m => m.HelpModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'latest',
        loadChildren: () => import('./views/releasenotes/releasenotes.module').then(m => m.ReleasenotesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sales/debitnote',
        loadChildren: () => import('./views/sales/debitnote/debitnote.module').then(m => m.DebitnoteModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'purchase/debitnote',
        loadChildren: () => import('./views/purchase/debitnote/debitnote.module').then(m => m.DebitnoteModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sales/einvoice',
        loadChildren: () => import('./views/sales/consolidated-Einvoice/consolidatedeinv.module').then(m => m.ConsolidatedeinvModule),
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'subscription',
      //   loadChildren: () => import('./views/subscription/subscription.module').then(m => m.SubscriptionModule),
      //   canActivate: [AuthGuard]
      // },
    ]
  },
  {
    path: '',
    component: SupportdefaultlayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'supportdashboard',
        loadChildren: () => import('./views/supportdashboard/supportdashboard.module').then(m => m.SupportDashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'administration',
        loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule),
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: '',
    component: SubscriptionlayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'subscription',
        loadChildren: () => import('./views/subscription/subscription.module').then(m => m.SubscriptionModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', enableTracing: false, onSameUrlNavigation: 'reload', useHash : false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
