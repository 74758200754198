import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ContentChange, QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CustomTxtValidator, ValidEmail, ValidMultipleEmail, ValidMultipleEmailWithSemicolonComma } from '../../../utilities/_directives/customvalidator.directive';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { HttpServices } from '../../../utilities/_services/http.service';
import { MasterServices } from '../../../utilities/_services/master.service';
import { PrintService } from '../../template/services/print.service';
import { EmailattachmentComponent } from '../emailattachment/emailattachment.component';
import { EmailTemplateTypes } from './model/EmailTemplateTypes';
import { TranslateService } from '@ngx-translate/core';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';

export interface PHDialogData {
  text: string;
  headerName: string;
  phtypeId: any;
}
const parchment = Quill.import('parchment')
const block = parchment.query('block')
block.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true)

@Component({
  selector: 'app-sendemailtemplate',
  templateUrl: './sendemailtemplate.component.html',
  styleUrls: ['./sendemailtemplate.component.scss']
})
export class sendemailtemplateComponent implements OnInit {
  submitted = false;
  emailtemplateForm: FormGroup | any;
  text: string;
  phdetails: any = [];

  lstEmailTemplate: any;
  defaultselectedOption: any;
  loading = false;
  SucessBody: any;

  @Input() sendEmailmodal: string = 'hide';
  @Input() sendTitle: string = '';
  @Input() transID: any;
  @Input() typeID: any;
  @Input() transTypeID: any;
  @Input() bccEnable: boolean = false;
  @Input() ccEnable: boolean = false;
  @Input() isMarkasSent: boolean = true;
  @Input() fromDate: string;
  @Input() toDate: string;
  @Input() dateRange: string;
  @Input() statementBalance: string;
  @Input() pageID: number;
  @Input() contactID: number;
  @Input() templateId: number;
  @Input() templateType: string;
  @Input() statementType: string;
  @Input() expiryDate: string;
  @Input() isIncludeAttachment: boolean = false;
  @Input() contactIds: number[] = [];
  @Input() templateGroupId: number;
  @Input() IsNewViewScreen: boolean;
  @Input() ageType:string;
  @Input() interval:number;
  @Input() duration:number;
  @Output() hidepoSendEmailmodal = new EventEmitter();
  @Output() IsMailSendStatus = new EventEmitter<boolean>();
  @ViewChild(EmailattachmentComponent) public emailattachment: EmailattachmentComponent;
  @ViewChild(QuillEditorComponent, { static: true }) body: QuillEditorComponent
  @ViewChild('showmodal') public showemailmodal: ModalDirective;
  errorNotifierId: number = 0;
  accountTypeID:number=0;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.CloseModel();
    }
  }
  constructor(private formBuilder: FormBuilder, private http: HttpServices, private el: ElementRef,
    private master: MasterServices, private alertMessage: AlertMessage, @Inject(MAT_DIALOG_DATA) public data: PHDialogData,
    private printService: PrintService, private translate: TranslateService,private local: LocalCacheServices) {
    this.phdetails = [];
  }

  ngOnInit(): void {
    this.emailFormValues();
    this.body
      .onContentChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((data: ContentChange) => {
        this.text = data.html;
        // if (this.transTypeID == 11 || this.transTypeID == 10) {
        //   this.getContactDetails();
        // } else if (this.transTypeID == 24 || this.transTypeID == 23) {
        //   this.getAllContactDetails();
        // }
      })

  }

  ngOnChanges() {
    if (this.sendEmailmodal == 'show') {
      if (this.showemailmodal != undefined)
        this.showemailmodal.show();
      this.bccEnable = false;
      this.ccEnable = false;
      // this.loadTemplateName();
      // this.emailattachment.getFiles();
      // if (this.transTypeID == 11 || this.transTypeID == 24) {
      //   this.emailattachment.getPaymentReceipt();
      // } else if (this.transTypeID == 10 || this.transTypeID == 23) {
      //   this.emailattachment.getPaymentVoucher();
      // }
    }
  }

  ngAfterViewInit() {
    if (this.sendEmailmodal == 'show') {
      this.showemailmodal.show();
      this.loadTemplateName().then(() => {
        this.emailattachment.getFiles();
        if (this.transTypeID == 11 || this.transTypeID == 24) {
          this.emailattachment.getPaymentReceipt();
        } else if (this.transTypeID == 10 || this.transTypeID == 23) {
          this.emailattachment.getPaymentVoucher();
        }
      });

    }
  }

  get f() { return this.emailtemplateForm.controls; }
  emailFormValues() {
    this.emailtemplateForm = this.formBuilder.group({
      emailTempID: [0],
      from: [''],
      replyTo: [''],
      orgID: [0],
      to: ['', [CustomTxtValidator.required, ValidMultipleEmailWithSemicolonComma()]],
      subject: ['', CustomTxtValidator.required],
      cc: ['', ValidMultipleEmailWithSemicolonComma()],
      bcc: ['', ValidMultipleEmailWithSemicolonComma()],
      body: [this.text],
      markasSent: [true],
      Sendmecopy: [false],
      fromDate: [''],
      toDate: [''],
      ContactID: [0],
      transID: [0],
      UserId: [0],
      pageID: [0],
      typeID: [0],
      templateId: [0],
      statementType: [''],
      expiryDate: [''],
      includeAttachment: [false],
      accountTypeID:[this.accountTypeID],
      IsNewViewScreen:[false],
      ageType:[''],
      interval:[0],
      duration:[0]
    });
  }

  async loadPlaceholder(mapPlaceHolderTypeID) {
    this.phdetails = await this.master.GetPlaceholder(mapPlaceHolderTypeID);
  }



  loadTemplate(emailTempID) {
    let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    let objEmailTempParams = {
      transID: this.transID,
      typeID: this.typeID,
      emailTempID: emailTempID
    }
    this.http.postservice(environment.templateApiUrl + '/EmailTemplate/GetEmailTemplate', objEmailTempParams).subscribe({
      next: (data: any) => {
        if (this.typeID == EmailTemplateTypes.CustomerStatement && this.accountTypeID==1) {
          data.body = data.body.toString().replaceAll('%Statement Date Range%', this.dateRange);
          data.subject = data.subject.toString().replaceAll('%Statement Date Range%', this.dateRange);
          data.subject = data.subject.toString().replaceAll('%Statement Balance%', this.statementBalance);
          data.body = data.body.toString().replaceAll('%Statement Balance%', this.statementBalance);
          data.body = data.body.toString().replaceAll('%Supplier Statement Date Range%', '');
          data.subject = data.subject.toString().replaceAll('%Supplier Statement Date Range%', '');
          data.subject = data.subject.toString().replaceAll('%Supplier Statement Balance%', '');
          data.body = data.body.toString().replaceAll('%Supplier Statement Balance%', '');
        }
        else if (this.accountTypeID==2) {
          data.body = data.body.toString().replaceAll('%Statement Date Range%', '');
          data.subject = data.subject.toString().replaceAll('%Statement Date Range%', '');
          data.subject = data.subject.toString().replaceAll('%Statement Balance%', '');
          data.body = data.body.toString().replaceAll('%Statement Balance%', '');
          data.body = data.body.toString().replaceAll('%Supplier Statement Date Range%', this.dateRange);
          data.subject = data.subject.toString().replaceAll('%Supplier Statement Date Range%', this.dateRange);
          data.subject = data.subject.toString().replaceAll('%Supplier Statement Balance%', this.statementBalance);
          data.body = data.body.toString().replaceAll('%Supplier Statement Balance%', this.statementBalance);
        }

        if(this.typeID == EmailTemplateTypes.MyInvoisEmail){
          data.subject = data.subject.toString().replaceAll('%OrganizationName%', currentUser.organizationName);
          data.body = data.body.toString().replaceAll('%OrganizationName%', currentUser.organizationName);
        }
        this.emailtemplateForm.patchValue(data);
        this.loadPlaceholder(data.mapPlaceHolderTypeID);
        if (this.emailtemplateForm.value.cc != null) {
          if (this.emailtemplateForm.value.cc != "")
            this.ccEnable = true;
        }
        if (this.emailtemplateForm.value.bcc != null) {
          if (this.emailtemplateForm.value.bcc != "")
            this.bccEnable = true;
        }
      }
    });
  }

  loadTemplateName() {
    if(this.typeID==1||this.typeID==2){
      this.accountTypeID=this.typeID;
      this.typeID=8; //Customer Statement
      
    }
    var promise = new Promise(async (resolve, reject) => {
      this.http.getservice(environment.templateApiUrl + '/EmailSetting/GetEmailTemplates?TypeID=' + this.typeID).subscribe({
        next: (data: any) => {
          this.lstEmailTemplate = data;
          this.defaultselectedOption = this.lstEmailTemplate.filter(x => x.isDefault == true)[0].emailTempID
          this.loadTemplate(this.defaultselectedOption);
          resolve('');
        }

      });
    });
    return promise;
  }

  onSubmit() {
    this.submitted = true;
    if (this.emailtemplateForm.invalid) {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
      firstInvalidControl.focus();
      return;
    }
    if (this.text == undefined || this.text.replace(/<[^>]+>/g, '').trim() == '') {
      const editor = this.body.quillEditor;
      editor.focus();
      this.emailtemplateForm.value.body = null;
      this.text = null;
      return;
    }
    let emailTemplates: any;
    this.submitted = false;

    if (this.emailtemplateForm.value.markasSent == null)
      this.emailtemplateForm.value.markasSent = false;
    if (this.emailtemplateForm.value.Sendmecopy == null)
      this.emailtemplateForm.value.Sendmecopy = false;

    this.emailtemplateForm.value.transID = this.transID;
    this.emailtemplateForm.value.typeID = this.typeID;
    this.emailtemplateForm.value.ContactID = this.contactID;
    this.emailtemplateForm.value.pageID = this.pageID;
    this.emailtemplateForm.value.fromDate = this.fromDate;
    this.emailtemplateForm.value.toDate = this.toDate;
    this.emailtemplateForm.value.templateId = this.templateId;
    this.emailtemplateForm.value.statementType = this.statementType;
    this.emailtemplateForm.value.expiryDate = this.expiryDate;
    this.emailtemplateForm.value.includeAttachment = false; //this.isIncludeAttachment;
    this.emailtemplateForm.value.accountTypeID=this.accountTypeID;
    this.emailtemplateForm.value.IsNewViewScreen = this.IsNewViewScreen;
    this.emailtemplateForm.value.ageType=this.ageType;
    this.emailtemplateForm.value.interval=this.interval;
    this.emailtemplateForm.value.duration=this.duration;
    emailTemplates = this.emailtemplateForm.value;

    if (this.typeID == EmailTemplateTypes.CustomerStatement && this.accountTypeID==1) {
      this.text = this.text.toString().replace('%Statement Date Range%', this.dateRange);
      emailTemplates.subject = emailTemplates.subject.toString().replace('%Statement Date Range%', this.dateRange);
      emailTemplates.subject = emailTemplates.subject.toString().replace('%Statement Balance%', this.statementBalance);
      this.text = this.text.toString().replace('%Statement Balance%', this.statementBalance);
    }
    else  if (this.accountTypeID==2) {
      this.text = this.text.toString().replace('%Supplier Statement Date Range%', this.dateRange);
      emailTemplates.subject = emailTemplates.subject.toString().replace('%Supplier Statement Date Range%', this.dateRange);
      emailTemplates.subject = emailTemplates.subject.toString().replace('%Supplier Statement Balance%', this.statementBalance);
      this.text = this.text.toString().replace('%Supplier Statement Balance%', this.statementBalance);
    }
   
    this.text = this.text.replaceAll('class="ql-font-monospace"'," style='font-family: Nunito Sans,Courier New' ");
    this.text = this.text.replaceAll('class="ql-font-serif"'," style='font-family: Georgia,Times New Roman,serif' ");
    
    emailTemplates.body = this.printService.returnEncodedString((this.text.replaceAll("\\","\\\\")));//Added for encoding.
    //console.log(emailTemplates.body);
    emailTemplates.attachments = this.emailattachment.lstEmailAttachments;
    emailTemplates["templateType"] = this.templateType;
    if (this.emailattachment.attachmentSize > 10) {
      let msg = this.translate.instant('SharedModule.Attachments total size per mail allowed maximum 10MB only. Please remove some files.');
      this.errorNotifierId = this.alertMessage.warningNotifier(msg, this.errorNotifierId);
      return;
    }
    this.loading = true;
    this.http.postservice(environment.templateApiUrl + '/EmailTemplate/SendEmailTemplates', emailTemplates).subscribe({
      next: (data: any) => {
        if (data.mailStatus == "1") {
          this.alertMessage.successNotifier(data.message, 0);
          this.reset();
          this.CloseModel();
          this.IsMailSendStatus.emit(true);
        }
        else {
          this.alertMessage.errorNotifier(data.error, 0);
          this.IsMailSendStatus.emit(false);
        }
        this.loading = false;
      }
    });
  }

  CloseModel() {
    this.submitted = false;
    this.reset();
    this.sendEmailmodal = 'hide';
    this.showemailmodal.hide();
    this.hidepoSendEmailmodal.emit(this.sendEmailmodal);
  }

  reset() {
    this.emailtemplateForm.reset();
  }

  changeCustom(event, codevalue) {
    const editor = this.body.quillEditor;
    if (codevalue != "") {
      let PlaceholderValue: string = codevalue;
      const cursorPosition = editor.getSelection(true).index;
      editor.insertText(cursorPosition, PlaceholderValue);
      editor.setSelection(cursorPosition, PlaceholderValue.length);
    }
  }
  showCC() {
    if (!this.ccEnable)
      this.ccEnable = true;
    else
      this.ccEnable = false;
  }
  showBcc() {
    if (!this.bccEnable)
      this.bccEnable = true;
    else
      this.bccEnable = false;
  }
  getContactDetails() {
    this.http.getservice(environment.organizationApiUrl + '/Contacts/GetContactDetailByID?contactID=' + this.contactID).subscribe((response) => {
      if (response["isSuccess"] && response["responseData"]["personDetailTable"].length > 0) {
        var EmailId = response["responseData"]["personDetailTable"][0]?.email;
        this.emailtemplateForm.patchValue({ to: EmailId });
      }
    });
  }

  getAllContactDetails() {
    this.http.postservice(environment.organizationApiUrl + '/Contacts/GetContactsById', this.contactIds).subscribe((response) => {
      if (response["isSuccess"] && response["responseData"].length > 0) {
        var EmailId = (response["responseData"]);
        this.emailtemplateForm.patchValue({ to: EmailId });
      }
    });

  }

  public showModal() {

    this.showemailmodal.show();
    this.bccEnable = false;
    this.ccEnable = false;
    this.loadTemplateName().then(() => {
      this.emailattachment.getFiles();
      if (this.transTypeID == 11 || this.transTypeID == 24) {
        this.emailattachment.getPaymentReceipt();
      } else if (this.transTypeID == 10 || this.transTypeID == 23) {
        this.emailattachment.getPaymentVoucher();
      }
    })
  }
}
