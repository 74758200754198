import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogService } from "./errors/error-dialog.service";
import { LoadingDialogService } from "./loading/loading-dialog.service";
import { MaterialModule } from "../../material.module";
import { UserAccessDirectiveModule } from '../../utilities/_directives/userRole.directive';
import { AllowKeypressDirectiveModule } from '../../utilities/_directives/allowkeypress.directive';
import { LoadingDialogComponent } from "./loading/loading-dialog/loading-dialog.component";
import { ErrorDialogComponent } from "./errors/error-dialog/error-dialog.component";
import { AddaccountComponent } from "./addaccount/addaccount.component"
import { AddcurrencyDialogComponent } from "./addcurrency/addcurrency-dialog/addcurrency-dialog.component"
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AgGridModule } from "@ag-grid-community/angular";
import { AddcurrencyDialogService } from "./addcurrency/addcurrency-dialog.service";
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { sendemailtemplateComponent } from "./sendemailtemplate/sendemailtemplate.component";
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuillModule } from 'ngx-quill'
import { EditaccountComponent } from "./editaccount/editaccount.component";
import { AddcurrencyComponent } from "./addcurrency/addcurrency.component";
import { AgcolorcellrendererComponent } from './agrenderer/agcolorcellrenderer/agcolorcellrenderer.component';
import { CustomtooltipComponent } from './agrenderer/customtooltip/customtooltip.component';
import { DaterangepickerComponent } from './report/daterangepicker/daterangepicker.component';
import { NewspendmoneyComponent } from './newspendmoney/newspendmoney.component';
import { NewtransfermoneyComponent } from './newtransfermoney/newtransfermoney.component';
import { NewreceivemoneyComponent } from './newreceivemoney/newreceivemoney.component';
import { ReportMatTableComponent } from './report/report-mat-table/report-mat-table.component';
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AgeingPeriodsComponent } from './report/ageing-periods/ageing-periods.component';
import { CustomDatePickerComponent } from "./report/custom-date-picker/custom-date-picker.component";
import { ComparewithperiodsComponent } from './report/comparewithperiods/comparewithperiods.component';
import { TreeviewModule } from 'ngx-treeview';
import { DropdownTreeviewSelectComponent } from './dropdown-treeview-select/dropdown-treeview-select.component'
import { ContactSelectComponent } from "./gridComponent/contact-select.component";
import { ColumnSelectionDropDownComponent } from './report/column-selection-drop-down/column-selection-drop-down.component';
import { ColumnReOrderComponent } from './report/column-re-order/column-re-order.component';
import { FileattachmentComponent } from './fileattachment/fileattachment.component';
import { sendmultipleemailtemplateComponent } from "./sendmultipleemailtemplate/sendmultipleemailtemplate.component";
import { MultiEmailCellRenderComponent } from "./sendmultipleemailtemplate/multi-email-cell-render/multi-email-cell-render.component";
import { MultiEmailCellEditorComponent } from "./sendmultipleemailtemplate/multi-email-cell-editor/multi-email-cell-editor.component";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatRadioModule } from '@angular/material/radio';
import { ImportComponent } from './import/import.component';
import { MultiMarkassentCellRenderComponent } from './sendmultipleemailtemplate/multi-markassent-cell-render/multi-markassent-cell-render.component';
import { ReportFilterComponent } from './report/report-filter/report-filter.component';
import { SavePublishComponent } from "../reports/save-publish/save-publish.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgdatepickerComponent } from "./gridComponent/agdatepicker.component";
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { EditExchangeRatesComponent } from './edit-exchange-rates/edit-exchange-rates.component';
import { DynamicfieldComponent } from './dynamicfield/dynamicfield.component';
import { BooleanComponent } from "./gridComponent/boolean.component";
import { TotaltaxComponent } from './totaltax/totaltax.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AssettypeComponent } from './assettype/assesttype.component'
import { MatCheckbox } from '@angular/material/checkbox';
import { DynamicItemLevelComponent } from "./gridComponent/dynamic-item-level.component";
import { DynamicitemfieldComponent } from './dynamicitemfield/dynamicitemfield.component';
import { FAFinancialsettingsComponent } from './fafinancialsettings/fafinancialsettings.component';
import { AdditemComponent } from './inventoryitem/additem.component';
import { RecurringSettingComponent } from './RecurringSetting/recurring-setting/recurring-setting.component';
import { AddtextdescComponent } from './inventoryitem/addtextdesc/addtextdesc.component';
import { termsAndConditionComponent } from "./termsAndCondition/termsAndCondition.component";
import { AddcontactComponent } from './addcontact/addcontact.component';
import { ViewjournaldetailsComponent } from './viewjournaldetails/viewjournaldetails.component';
import { CtableComponent } from "./ctable/ctable.component";
import { TextareaAutoresizeDirective } from "../../utilities/_directives/textareaautoresizedirective";
import { AutofocusDirective } from "../../utilities/_directives/autofocusdirective";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { EmailattachmentComponent } from './emailattachment/emailattachment.component';
import { CreateNodeComponent } from './report-edit-layout/create-node/create-node.component';
import { ReportEditLayoutComponent } from "./report-edit-layout/report-edit-layout.component";
import { AdddeliveryaddressComponent } from './adddeliveryaddress/adddeliveryaddress.component';
import { ItemhistoryComponent } from './itemhistory/itemhistory.component';
import { ChartsModule } from 'ng2-charts';
import { ContactTermsConditionComponent } from "../contacts/contact-terms-condition/contact-terms-condition.component";
import { AddContactTCComponent } from "../contacts/contact-terms-condition/add-contact-tc/add-contact-tc.component";
import { UnitpricefrompricelistComponent } from './unitpricefrompricelist/unitpricefrompricelist.component';
import { CountdownComponent } from './countdown/countdown.component';
import { NumericEditorDecimalComponent } from './gridComponent/numeric-editor-decimal.component';
import { SimpleCTableComponent } from './simple-ctable/simple-ctable.component';
import { SimpleCCellComponent } from './simple-ccell/simple-ccell.component';
import { TraineeemailcellrendererComponent } from './traineeemailcellrenderer/traineeemailcellrenderer.component';
import { TraineephonenumbercelleditorComponent } from './traineephonenumbercelleditor/traineephonenumbercelleditor.component';
import { TraineephonenumbercellrendererComponent } from './traineephonenumbercellrenderer/traineephonenumbercellrenderer.component';
import { NzgstreconadjustmentcelleditorComponent } from './nzgstreconadjustmentcelleditor/nzgstreconadjustmentcelleditor.component';
import { NzgstreconadjustmentcellrendererComponent } from './nzgstreconadjustmentcellrenderer/nzgstreconadjustmentcellrenderer.component';
import { NzgstreconfiledtcellrendererComponent } from './nzgstreconfiledtcellrenderer/nzgstreconfiledtcellrenderer.component';
import { NzgstreconfiledtcelleditorComponent } from './nzgstreconfiledtcelleditor/nzgstreconfiledtcelleditor.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AgoptionsbuttonrendererComponent } from "./agrenderer/agoptionsbuttonrenderer/agoptionsbuttonrenderer.component";
import { AlphanumericEditor } from "./gridComponent/alphanumeric-editor.component";
import { CreatebatchComponent } from './createbatch/createbatch.component';
import { AssignbatchComponent } from './assignbatch/assignbatch.component';
import { BatchlistComponent } from './batchlist/batchlist.component';
import { CreateserialnoComponent } from './createserialno/createserialno.component';
import { SerialnosequenceComponent } from './serialnosequence/serialnosequence.component';
import { CreateserialComponent } from './createserial/createserial.component';
import { AssignserialComponent } from './assignserial/assignserial.component';
import { SeriallistComponent } from './seriallist/seriallist.component';
import { WarehouselistComponent } from './warehouselist/warehouselist.component';
import { DropdownWithAddNewComponent } from './gridComponent/dropdown-with-add-new/dropdown-with-add-new.component';
import { TranslateModule} from "@ngx-translate/core";
import { ExportdetailComponent } from './export/exportdetail/exportdetail.component';
import { DropdownwithtextboxComponent } from './gridComponent/dropdownwithtextbox/dropdownwithtextbox.component';
import { FileDragDropDirective } from './fileattachment/directives/file-drag-drop.directive';
import { BackorderComponent } from './backorder/backorder.component';
import { ViewstatementReconcilationComponent } from "../template/shared/viewstatement-reconcilation/viewstatement-reconcilation.component";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { LHDNValidationComponent } from "./lhdnvalidation/lhdnvalidation.component";
import { ImagepopupComponent } from './imagepopup/imagepopup.component';
import { TicketCaptureComponent } from './ticket-capture/ticket-capture.component';


const sharedComponents = [LoadingDialogComponent, termsAndConditionComponent, EditaccountComponent, AddcurrencyComponent, AdditemComponent,
  ErrorDialogComponent, AddaccountComponent, AddcurrencyDialogComponent, sendemailtemplateComponent,
  sendmultipleemailtemplateComponent, ReportMatTableComponent, AgeingPeriodsComponent, DropdownTreeviewSelectComponent,
  ColumnSelectionDropDownComponent, ColumnReOrderComponent, ReportFilterComponent, FileattachmentComponent,
  SavePublishComponent, EditExchangeRatesComponent, DynamicfieldComponent, DynamicitemfieldComponent,
  TotaltaxComponent, AssettypeComponent, RecurringSettingComponent, AddtextdescComponent, AddcontactComponent, AdddeliveryaddressComponent,
  ItemhistoryComponent, UnitpricefrompricelistComponent, ExportdetailComponent,ViewstatementReconcilationComponent,
  ReportEditLayoutComponent, CreateNodeComponent, CtableComponent, SimpleCTableComponent, SimpleCCellComponent,
    ContactTermsConditionComponent, AddContactTCComponent, CountdownComponent, CtableComponent, GalleryComponent, AlphanumericEditor, DropdownWithAddNewComponent, OnboardingComponent, BackorderComponent,
  LHDNValidationComponent];

@NgModule({
  declarations: [...sharedComponents, ErrorDialogComponent, LoadingDialogComponent, AddcurrencyDialogComponent, ContactSelectComponent,
    AgcolorcellrendererComponent, CustomtooltipComponent, DaterangepickerComponent, ImportComponent,
    NewspendmoneyComponent, NewtransfermoneyComponent, NewreceivemoneyComponent, CustomDatePickerComponent, ComparewithperiodsComponent, ColumnSelectionDropDownComponent, ColumnReOrderComponent, MultiEmailCellEditorComponent, MultiEmailCellRenderComponent, MultiMarkassentCellRenderComponent,
    AgdatepickerComponent, BooleanComponent, DynamicfieldComponent, DynamicItemLevelComponent, FAFinancialsettingsComponent, RecurringSettingComponent, ViewjournaldetailsComponent,
    TextareaAutoresizeDirective, AutofocusDirective, EmailattachmentComponent, ReportEditLayoutComponent, NumericEditorDecimalComponent, TraineeemailcellrendererComponent,
    TraineephonenumbercelleditorComponent, TraineephonenumbercellrendererComponent, NzgstreconadjustmentcelleditorComponent, NzgstreconadjustmentcellrendererComponent,
    NzgstreconfiledtcellrendererComponent, NzgstreconfiledtcelleditorComponent, AgoptionsbuttonrendererComponent, CreatebatchComponent, AssignbatchComponent, BatchlistComponent, CreateserialnoComponent, SerialnosequenceComponent, CreateserialComponent, AssignserialComponent, SeriallistComponent, WarehouselistComponent, DropdownWithAddNewComponent, ExportdetailComponent, DropdownwithtextboxComponent, FileDragDropDirective, ImagepopupComponent, TicketCaptureComponent],
  imports: [CommonModule, RouterModule, MaterialModule, MatDialogModule, ModalModule, QuillModule,
    NgSelectModule,
    FormsModule, ReactiveFormsModule, BsDropdownModule, ButtonsModule,
    UserAccessDirectiveModule, NgbModule, AgGridModule,
    AllowKeypressDirectiveModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule, MatRadioModule, ChartsModule,TranslateModule,
    MatIconModule,
    MatProgressSpinnerModule, TreeviewModule.forRoot(), PopoverModule, MatTooltipModule, AccordionModule, DragDropModule, MatTreeModule],
  exports: [...sharedComponents, AgcolorcellrendererComponent, CustomtooltipComponent, DaterangepickerComponent, NewspendmoneyComponent, NewtransfermoneyComponent, NewreceivemoneyComponent, CustomDatePickerComponent, ComparewithperiodsComponent,
    AgdatepickerComponent, BooleanComponent, MatSliderModule, MatSlideToggleModule, MatTreeModule, FileattachmentComponent, MatDialogModule, MatRadioModule, MatCheckbox, ViewjournaldetailsComponent, AllowKeypressDirectiveModule, ChartsModule],
  providers: [DatePipe, ErrorDialogService, LoadingDialogService, AddcurrencyDialogService, { provide: MAT_DIALOG_DATA, useValue: {} }],
  entryComponents: [...sharedComponents]
})
export class SharedModule { }
