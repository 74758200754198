import {Directive, Input, TemplateRef, ViewContainerRef, NgModule} from "@angular/core";
import { LocalCacheServices } from "../_services/acclocalcache.service";
import { PageServices } from '../_services/page.service';

@Directive({
    selector:"[userCanEdit]"
})
export class userCanEditDirective  {
allowedRoles:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private page: PageServices) {
        
    }

    @Input()
    set userCanEdit(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.page.accessRights) {
            this.viewContainer.clear();
            return;
        }       
        const allowed:boolean = this.page.accessRights.canEdit === this.allowedRoles[0];
       if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}



@Directive({
    selector:"[userCanDelete]"
})
export class userCanDeleteDirective  {
allowedRoles:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private page: PageServices) {
        
    }

    @Input()
    set userCanDelete(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.page.accessRights) {
            this.viewContainer.clear();
            return;
        }
        
        const allowed:boolean = this.page.accessRights.canDelete === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}


@Directive({
    selector:"[userCanApprove]"
})
export class usercanApproveDirective  {
allowedRoles:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private page: PageServices) {
        
    }

    @Input()
    set userCanApprove(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.page.accessRights) {
            this.viewContainer.clear();
            return;
        }
      
        const allowed:boolean = this.page.accessRights.canApprove === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanView]"
})
export class usercanViewDirective  {
allowedRoles:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private page: PageServices) {
        
    }

    @Input()
    set userCanView(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.page.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.page.accessRights.canView === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}
@Directive({
    selector:"[canPayment]"
})
export class canPaymentDirective  {
allowedRoles:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private page: PageServices) {
        
    }

    @Input()
    set canPayment(allowedRoles: string) {        
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.page.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.page.accessRights.canPayment === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}


@Directive({
    selector:"[userCanViewInvoice]"
})
export class usercanViewInvoiceDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/sales/invoice/invoiceview';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanViewInvoice(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canView === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanViewBill]"
})
export class usercanViewBillDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/purchase/bills/billsview';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanViewBill(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canView === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanViewBank]"
})
export class usercanViewBankDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/bankaccounts/bankaccounts';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanViewBank(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canView === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanViewPO]"
})
export class usercanViewPODirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/purchase/purchaseorder';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanViewPO(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canView === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanViewQuotation]"
})
export class usercanViewQuotationDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/sales/quotation/quotesview';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanViewQuotation(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canView === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanViewRM]"
})
export class userCanViewRMDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/transactions/receivemoneyview';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanViewRM(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canView === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanEditRM]"
})
export class userCanEditRMDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/transactions/newreceivemoney';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanEditRM(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canEdit === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanDeleteRM]"
})
export class userCanDeleteRMDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/transactions/newreceivemoney';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanDeleteRM(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canDelete === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanViewSM]"
})
export class userCanViewSMDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/transactions/spendmoneyview';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanViewSM(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canView === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanEditSM]"
})
export class userCanEditSMDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/transactions/newspendmoney';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanEditSM(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canEdit === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanDeleteSM]"
})
export class userCanDeleteSMDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/transactions/newspendmoney';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanDeleteSM(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canDelete === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanViewTM]"
})
export class userCanViewTMDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/transactions/transfermoneyview';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanViewTM(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canView === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanEditTM]"
})
export class userCanEditTMDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/transactions/newtransfermoney';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanEditTM(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canEdit === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[userCanDeleteTM]"
})
export class userCanDeleteTMDirective  {
allowedRoles:any;
accessRights:any;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,private local: LocalCacheServices,) {
        let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
        let url = '/transactions/newtransfermoney';
        let uar = currentUser.menuAccessRights.filter(x => x.pageURL == url && x.accessRight == currentUser.userRole);
        this.accessRights = uar[0];            
    }

    @Input()
    set userCanDeleteTM(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.accessRights) {
            this.viewContainer.clear();
            return;
        }
         
        const allowed:boolean = this.accessRights.canDelete === this.allowedRoles[0];
        if (allowed) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector:"[licenseType]"
})
export class licenseTypeDirective  {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private local: LocalCacheServices) {
        
    }

    @Input()
    set licenseType(isview: any) {
       let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));   
       if (currentUser.licenseTypeID!=6 && currentUser.licenseTypeID!=9 &&  !((isview=='currency' || isview=='trackeditem') && currentUser.licenseTypeID==11 )) {
           this.viewContainer.createEmbeddedView(this.templateRef);//show)
        }
        else {
            this.viewContainer.clear();//hide
        }
    }
}
@Directive({
    selector:"[isSupportUserOrCanEdit]"
})
export class isSupportUserOrCanEditDirective  {
allowedRoles:any;
accessRights:any;
    constructor (private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private page: PageServices) 
    {}

    @Input()
    set isSupportUserOrCanEdit(allowedRoles: string) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 ||
            !this.page.accessRights) {
            this.viewContainer.clear();
            return;
        }       
        this.accessRights = this.page.accessRights;
        if ((this.accessRights.accessRightID == 7 && this.accessRights.canView) || (this.page.accessRights.canEdit === this.allowedRoles[0])) {
           this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    }
}


@NgModule({
    declarations: [ userCanEditDirective,userCanDeleteDirective, usercanApproveDirective, usercanViewDirective,canPaymentDirective,
        usercanViewInvoiceDirective,usercanViewBillDirective,usercanViewBankDirective,usercanViewPODirective,usercanViewQuotationDirective,
        userCanViewRMDirective,userCanEditRMDirective,userCanDeleteRMDirective,userCanViewSMDirective,userCanEditSMDirective, userCanDeleteSMDirective,userCanViewTMDirective,userCanEditTMDirective, userCanDeleteTMDirective,licenseTypeDirective,isSupportUserOrCanEditDirective ],
    exports: [ userCanEditDirective,userCanDeleteDirective, usercanApproveDirective, usercanViewDirective,canPaymentDirective,
        usercanViewInvoiceDirective,usercanViewBillDirective,usercanViewBankDirective,usercanViewPODirective,usercanViewQuotationDirective,
        userCanViewRMDirective,userCanEditRMDirective,userCanDeleteRMDirective,userCanViewSMDirective,userCanEditSMDirective, userCanDeleteSMDirective,userCanViewTMDirective,userCanEditTMDirective, userCanDeleteTMDirective,licenseTypeDirective,isSupportUserOrCanEditDirective ]
  })
  
  export class UserAccessDirectiveModule {}
