import { Component, Input, OnInit, EventEmitter, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { HttpServices } from '../../../utilities/_services/http.service';
import { BankaccountService } from '../../../utilities/_services/bankaccount.service';
import { Dynamicformfield } from '../dynamicformfield';
import { environment } from '../../../../environments/environment'
import { PageServices } from '../../../utilities/_services/page.service';

@Component({
  selector: 'app-dynamicfield',
  templateUrl: './dynamicfield.component.html',
  styleUrls: ['./dynamicfield.component.scss'] 
})
export class DynamicfieldComponent implements OnInit, OnChanges {

  @Input() formItem!: Dynamicformfield
  @Input() transTypeId: any = null;
  @Input() mappingTransTypeId: any = null;
  @Input() transId: any;
  @Input() disableMode: any;
  @Input() viewPageMode: any;
  @Input() verticalAlign: boolean = false;
  @Input() masterTransTypeId: any;
  @Input() masterTransId: any;
  @Input() isCopyTransaction: boolean = false;
  @Output() customSettingsLoaded: EventEmitter<string> = new EventEmitter<string>();
  @Input() spendmoneyobj: any = {};
  @Input() receivemoneyobj: any = {};

  form!: FormGroup
  dynamicFormFields: any;
  customFieldSettings: any = [];

  customFiledsMasterData = [];

  constructor(private page: PageServices,private rootFormGroup: FormGroupDirective, private formBuilder: FormBuilder,
    private http: HttpServices, private bankService: BankaccountService) {
    this.form = this.rootFormGroup.control;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
    this.dynamicFormFields = [];
    this.loadCustomFieldSettings().then(() => {
      if(this.page.pageDetails.pageID==44){ 
        if(this.spendmoneyobj!=undefined && this.spendmoneyobj.ContactID!=undefined)
            this.customFieldSettings=this.spendmoneyobj.DynamicCusFieldsData;
        else if(this.receivemoneyobj!=undefined && this.receivemoneyobj.ContactID!=undefined)
          this.customFieldSettings=this.receivemoneyobj.DynamicCusFieldsData;
      }
      let id, label, type, cv, mandate;
      if (this.customFieldSettings.length > 0) {
        for (let x in this.customFieldSettings) {
          if (this.transTypeId != 12 && this.transTypeId != 101) {
            if (this.customFieldSettings[x].IsDetail == true) continue;
          }
          id = this.customFieldSettings[x].FieldID;
          mandate = this.customFieldSettings[x].IsMandatory;
          label = this.customFieldSettings[x].FieldName;
          if (this.customFieldSettings[x].TypeID == '2') {
            type = 'select';
          }
          if (this.customFieldSettings[x].TypeID == '1') {
            type = 'text';
          }
          if (this.customFieldSettings[x].CustomListValues == null) {
            cv = [];
          } else {
            if (this.customFieldSettings[x].CustomListValues.length > 0) {
              cv = this.customFieldSettings[x].CustomListValues;
            } else {
              cv = [];
            }
          }
          let a = { id: id, label: label, type: type, customValues: cv, altDisplayValue: '', isMandate: mandate, showMandate: false };
          this.dynamicFormFields.push(a);
        }
      }
      this.dynamicFormFields.forEach(formItem => {
        this.form.addControl(formItem.id, this.formBuilder.control(null))
      });

      if ((this.transId > 0 && this.viewPageMode == false) || this.page.pageDetails.pageID==44) {

        if (this.customFieldSettings.length > 0) {
          for (let x in this.customFieldSettings) {
            if (this.transTypeId != 12 && this.transTypeId != 101) {
              if (this.customFieldSettings[x].IsDetail == true) continue;
            }
            if (this.customFieldSettings[x].CustomSelectedListValues != null) {
              if (this.customFieldSettings[x].CustomSelectedListValues.length > 0) {
                if (this.customFieldSettings[x].TypeID == '2') {
                  this.form.controls[this.customFieldSettings[x].FieldID].setValue(this.customFieldSettings[x].CustomSelectedListValues[0].CustomValueID);
                }
                if (this.customFieldSettings[x].TypeID == '1') {
                  this.form.controls[this.customFieldSettings[x].FieldID].setValue(this.customFieldSettings[x].CustomSelectedListValues[0].CustomValueText);
                }
              }
            }
          }
        }
      }
      if (this.transId > 0 && this.viewPageMode == true) {

        if (this.customFieldSettings.length > 0) {
          for (let x in this.customFieldSettings) {
            if (this.transTypeId != 12 && this.transTypeId != 101) {
              if (this.customFieldSettings[x].IsDetail == true) continue;
            }
            if (this.customFieldSettings[x].CustomSelectedListValues != null) {
              if (this.customFieldSettings[x].CustomSelectedListValues.length > 0) {
                this.customFieldSettings[x].altDisplayValue = this.customFieldSettings[x].CustomSelectedListValues[0].CustomValueText;
              }
            }
          }
        }
      }
      this.customSettingsLoaded.emit("Custom Settings Content Loaded");
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.masterTransId?.currentValue != undefined) {
      if (!((this.transId > 0) && changes.masterTransId.previousValue == undefined)) {
        if (this.customFiledsMasterData.filter(x => x.MasterTransId == this.masterTransId && x.MasterTransTypeId == this.masterTransTypeId).length > 0) {

          this.loadCustomFieldsBasedOnMaster(this.customFiledsMasterData.filter(x => x.MasterTransId == this.masterTransId && x.MasterTransTypeId == this.masterTransTypeId)[0]);
        } else {
          this.http.getservice(environment.organizationApiUrl + '/CustomFieldSetting/GetCustomFieldSettingsByField?fieldID=0&transTypeId='
            + this.masterTransTypeId + '&transId=' + this.masterTransId + '').subscribe({
              next: (data: any) => {
                if (data.StatusMessage == '' || data.StatusMessage == null) {
                  return;
                } else {
                  let customFieldSettings = { MasterTransId: this.masterTransId, MasterTransTypeId: this.masterTransTypeId, Data: JSON.parse(data.StatusMessage) };
                  this.customFiledsMasterData.push(customFieldSettings);
                  this.loadCustomFieldsBasedOnMaster(customFieldSettings);
                }
              }
            });
        }
      }
    }
  }

  loadCustomFieldsBasedOnMaster(customFieldSettings) {
    let filterdData = [];
    if (this.masterTransTypeId == '101' && this.transTypeId == '6') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.InvoiceEnabled == true);
    } else if (this.masterTransTypeId == '101' && this.transTypeId == '1') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.POEnabled == true);
    } else if (this.transTypeId == '2' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.BillsEnabled == true);
    } else if (this.transTypeId == '5' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.QuotationEnabled == true);
    } else if (this.transTypeId == '8' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.SCreditNoteEnabled == true);
    } else if (this.transTypeId == '4' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.CreditNoteEnabled == true);
    } else if (this.transTypeId == '21' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.DOEnabled == true);
    } else if (this.transTypeId == '10' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.SpendMoneyEnabled == true);
    } else if (this.transTypeId == '11' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.ReceiveMoneyEnabled == true);
    } else if (this.transTypeId == '14' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.JournalEntryEnabled == true);
    }
    else if (this.transTypeId == '29' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.SalesOrderEnabled == true);
    } 
    else if (this.transTypeId == '30' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.DebitNoteEnabled == true);
    } 
    else if (this.transTypeId == '31' && this.masterTransTypeId == '101') {
      filterdData = customFieldSettings.Data.filter(y => y.ContactsEnabled == true && y.VDebitNoteEnabled == true);
    } 
    if (filterdData.length > 0) {
      filterdData.forEach(element => {
        if (this.form.controls[element.FieldID]) {
          if (element.TypeID == '2') {
            this.form.controls[element.FieldID].setValue(element.CustomSelectedListValues ? element.CustomSelectedListValues[0].CustomValueID : null);
          }
          if (element.TypeID == '1') {
            this.form.controls[element.FieldID].setValue(element.CustomSelectedListValues ? element.CustomSelectedListValues[0].CustomValueText : null);
          }
        }
      });
    }
  }

  resetDynamicForm() {
    this.form.reset();
  }

  onBlurEvent(event: any, controlId: any) {
    for (let x in this.dynamicFormFields) {
      if (this.dynamicFormFields[x].id == controlId) {
        if (this.form.controls[controlId].value == null || this.form.controls[controlId].value == '') {
          this.dynamicFormFields[x].showMandate = true;
        } else {
          this.dynamicFormFields[x].showMandate = false;
        }
        break;
      }
    }
  }

  loadCustomFieldSettings() {
    if (this.http.copyToFuncActivate == true) {
      this.transId = this.http.copyToFuncSourceTransId;
    } 

    var promise = new Promise((resolve, reject) => {
      this.http.getservice(environment.organizationApiUrl + '/CustomFieldSetting/GetCustomFieldSettingsByField?fieldID=0' +
        '&transTypeId=' + ((this.mappingTransTypeId ?? 0) == 0 ? this.transTypeId : (this.mappingTransTypeId == "20" ? "1" : this.mappingTransTypeId)) + '&transId=' + this.transId + '&isCopyTransaction=' + this.isCopyTransaction).subscribe({
          next: (data: any) => {
            if (data.StatusMessage == '' || data.StatusMessage == null) {
              this.customFieldSettings = [];
            } else {
              this.customFieldSettings = JSON.parse(data.StatusMessage);
            }
            if (this.transTypeId == '6') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.InvoiceEnabled == true);
            }
            if (this.transTypeId == '2') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.BillsEnabled == true);
            }
            if (this.transTypeId == '1') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.POEnabled == true);
            }
            if (this.transTypeId == '10') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.SpendMoneyEnabled == true);
            }
            if (this.transTypeId == '11') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.ReceiveMoneyEnabled == true);
            }
            if (this.transTypeId == '5') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.QuotationEnabled == true);
            }
            if (this.transTypeId == '8') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.SCreditNoteEnabled == true);
            }
            if (this.transTypeId == '4') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.CreditNoteEnabled == true);
            }
            if (this.transTypeId == '12') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.InventoryEnabled == true);
            }
            if (this.transTypeId == '21') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.DOEnabled == true);
            }
            if (this.transTypeId == '101') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.ContactsEnabled == true);
            }
            if (this.transTypeId == '14') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.JournalEntryEnabled == true);
            }
            if (this.transTypeId == '29') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.SalesOrderEnabled == true);
            }
            if (this.transTypeId == '30') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.DebitNoteEnabled == true);
            }
            if (this.transTypeId == '31') {
              this.customFieldSettings = this.customFieldSettings.filter(y => y.VDebitNoteEnabled == true);
            }
            if (this.http.copyToFuncActivate == true) {
              this.http.copyToFuncActivate = false;
              this.http.copyToFuncSourceTypeId = '';
              this.http.copyToFuncSourceTransId = '';
              this.http.copyToFuncDestinationTypeId = '';
            }
            resolve('');
          },
          error: err => {
            reject(err);
          }
        });
    });
    return promise;
  }
}
