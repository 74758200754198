import { Component, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';

import { ICellEditorAngularComp } from "@ag-grid-community/angular";

@Component({
    selector: 'numeric-cell',
    template: `<input style="height:100%" type="text" *ngIf="decimalType == 'negdecimal2'" #input class="form-control" [(ngModel)]="value" negdecimal2 appNoRightClick maxlength="12" (ngModelChange)="validateDecimal($event)">
    <input style="height:100%" type="text" type="text" *ngIf="decimalType == 'decimal4'" #input class="form-control" [(ngModel)]="value" decimal4 appNoRightClick maxlength="12" (ngModelChange)="validateDecimal($event)">
    <input style="height:100%" type="text" type="text" *ngIf="decimalType == 'Decimal10'" #input class="form-control" [(ngModel)]="value" Decimal10 appNoRightClick maxlength="16" (ngModelChange)="validateDecimal($event)">`,
})
export class NumericNegativeComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value: number;
    private cancelBeforeStart: boolean = false;
    decimalType = 'negdecimal2'
    @ViewChild('input', { read: ViewContainerRef }) public input;
    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
        this.decimalType = !!this.params.colDef?.cellEditorParams?.decimalType ?this.params.colDef?.cellEditorParams?.decimalType :this.decimalType;
    }
    getValue(): any {
        return this.value;
    }
    ngAfterViewInit() {
        window.setTimeout(() => {
            this.input.element.nativeElement.focus();
        });
    }

    validateDecimal(event: number) {
        let x:number =-1;
        if (event.toString() == '.' || event.toString() == '.00') {
            this.value = 0.00;
        }
    }
}
