import { PrintType } from "./enumlist";
import { HTMLResult, TemplateGroupParams } from "./groupparams";
import { TemplateProperties } from "./templateproperties";


export class UpdateTemplateVoucher {
    templateVoucher: TemplateVoucher;
    templateVoucherParams: TemplateVoucherParams;
}

export interface HTMLTemplateVoucher {
    templateVoucher: TemplateVoucher;
    htmlResult: HTMLResult;
}

export class TemplateVoucherParams extends TemplateGroupParams {
    templateType: string="templatedetails";
    isBatch:boolean=false;
    masterCode:string = "STD";
}

export class TemplateVoucher {
    templateVoucherProperties: TemplateVoucherProperties;
    templateVoucherHeader: TemplateVoucherHeader;
    templateVoucherItem:TemplateVoucherItem;
    templateVoucherTotal:TemplateVoucherTotal;
    templateVoucherFooter: TemplateVoucherFooter;
    templateVoucherAnnexure: TemplateVoucherAnnexure;
    templateVoucherCustomFields:any;
    templateVoucherDetailCustomFields:any;
    templateProperties:TemplateProperties;
}

export class TemplateVoucherProperties {
    id: number;
    tmVoucherId: number;
    templateName: string;
    paperSizeId: number;
    orientationId: number;
    marginTop: number;
    marginBottom: number;
    marginLeft: number;
    marginRight: number;
    pDFFontId: number | null;
    fontFaceCss: string;
    fontFamilyCss: string;
    addAttention: string;
    attentionPHTypeId: number;
    backImage: string;
    backImageInBase64: string;
    backImageSize: string;
    imagePositionId: number;
    imagePostition: string;
    colorThemeId: number;
    colorTheme: string;
    showBackColor: boolean;
    backColor: string;
    labelColor: string;
    fontColor: string;
    fontSize: number;
}

export class TemplateVoucherHeader {
    iD: number;
    tmVoucherId: number;
    showOrgLogo: boolean;
    orgLogo: string;
    orgLogoInBase64: string;
    orgWidth: string;
    orgHeight: string;
    showOrgName: boolean;
    showOrgAddress: boolean;
    orgAddressFormat: string;
    orgAddressPHTypeId: number;
    showDocTitle: boolean;
    docTitle: string;
    backImage: string;
    backImageInBase64: string;
    backImageSize: string;
    imgPosId: number;
    imagePostition: string;
    showBackColor: boolean;
    backColor: string;
    headerContent: string;
    applyFirstPage: boolean;
    headerContentForOtherPages: string;
    headHeightFirstPage: number;
    docTitleFontSize: number;
    docTitleFontColor: string;
    orgNameColor: string;
    orgNameFontSize: number;
    orgPhone: string;
    orgFax: string;
    vendFontColor: string;
    vendFontSize: number;
    showBillTo: boolean;
    billTo: string;
    billToAddress: string;
    billAddressPHTypeId: number;
    showShipTo: boolean;
    shipTo: string;
    shipToAddress: string;
    shipAddressPHTypeId: number;
    showDocNumFld: boolean;
    docNumFld: string;
    showDocDateFld: boolean;
    docDateFld: string;
    showDocTermsFld: boolean;
    docTermsFld: string;
    showDocDueDateFld: boolean;
    docDueDateFld: string;
    showDocRefFld: boolean;
    showDocSMNoFld:boolean;
    docRefFld: string;
    docSMNoFld: string;
    showDocShipPrefFld: boolean;
    docShipPrefFld: string;
    docNumFontSize: number;
    docDateFontSize: number;
    docTermsFontSize: number;
    docDueDateFontSize: number;
    docRefFontSize: number;
    docSMNoFontSize: number;
    orgLogoWidthPerc: number;
    orgAddressWidthPerc: number;
    docInfoWidthPerc: number;
    orgLogoWidthLine: number;
    orgAddressWidthLine: number;
    docInfoWidthLine: number;
    showContactTo: boolean;
    contactTo: string;
    contactToAddress: string;
    contactAddressPHTypeId: number;
    showHdrBackColor:boolean;
    showDocPayMode: boolean;
    docPayMode: string;
    docPayModeFontSize: number;
    showBankAccountName : boolean;
    bankAccountName : string;
    bankAccountNameFontSize : number;
}

export class TemplateVoucherItem{
    templateVoucherItemLabel:TemplateVoucherItemLabel;
    templateVoucherItemLayout:TemplateVoucherItemLayout;
}

export class TemplateVoucherItemLabel {
    id: number;
    tmVoucherId: number;
    showLINumber: boolean;
    lINumWidth: number;
    lINumLabel: string;
    showItem: boolean;
    itemWidth: number;
    itemLabel: string;
    showDesc: boolean;
    descLabel: string;
    showQuantity: boolean;
    quantityWidth: number;
    quantityLabel: string;
    showUnit: boolean;
    showRate: boolean;
    rateWidth: number;
    rateLabel: string;
    showTax: boolean;
    taxWidth: number;
    taxLabel: string;
    showTaxAmt: boolean;
    taxAmtWidth: number;
    taxAmtLabel: string;
    showDiscount: boolean;
    discountWidth: number;
    discountLabel: string;
    showAmount: boolean;
    amountWidth: number;
    amountLabel: string;
    showAccountDetail: boolean;
    accountWidth: number;
    accountLabel: string;
    showItemCode: boolean;
    showItemName: boolean;
    showProdCategory: boolean;
    showProdGroup:boolean;
    showRateCurrency: boolean;
    showTaxAmtCurrency: boolean;
    showDisCurrency: boolean;
    showAmtCurrency: boolean;
}

export class TemplateVoucherItemLayout {
    id: number;
    tmVoucherId: number;
    showTableBorder: boolean;
    tableBorder: string;
    thFontSize: number;
    showTHBackColor: boolean;
    thBackColor: string;
    thFontColor: string;
    irFontSize: number;
    showIRBackColor: boolean;
    irBackColor: string;
    irFontColor: string;
    idFontSize: number;
    idFontColor: string;
}

export class TemplateVoucherTotal{
    templateVoucherTotalLabel:TemplateVoucherTotalLabel;
    templateVoucherTotalLayout:TemplateVoucherTotalLayout;
}

export class TemplateVoucherTotalLabel {
    id: number;
    tmVoucherId: number;
    showTotalSection: boolean;
    showSubTotal: boolean;
    subTotal: string;
    showTaxDetail: boolean;
    showTaxTotal: boolean;
    taxTotal: string;
    showDiscount: boolean;
    total: string;
    currencySymbolDisplayId: number;
    showQuantity: boolean;
    itemsInTotal: string;
}

export class TemplateVoucherTotalLayout {
    id: number;
    tmVoucherId: number;
    totalFontSize: number;
    totalFontColor: string;
    showTotalBackColor: boolean;
    totalBackColor: string;
    balDueFontSize: number;
    balDueFontColor: string;
    showBalDueBackColor: boolean;
    balDueBackColor: string;
}

export class TemplateVoucherFooter {
    id: number;
    tmVoucherId: number;
    notesLabel: string;
    notesFontSize: number;
    showTC: boolean;
    tCLabel: string;
    tCText: string;
    tCFontSize: number;
    showSignature: boolean;
    sigLabel: string;
    sigImage: string;
    sigImageInBase64: string;
    sigWidth: string;
    sigHeight: string;
    sigName: string;
    showSigLine:boolean;
    sliderControl:number;
    rightShowSignature: boolean;
    rightSigLabel: string;
    rightSigImage: string;
    rightSigImageInBase64: string;
    rightSigWidth: string;
    rightSigHeight: string;
    rightSigName: string;
    rightShowSigLine:boolean;
    rightSliderControl:number;
    footFontSize: number;
    footFontColor: string;
    showFootBackColor: boolean;
    footBackColor: string;
    backImage: string;
    backImageInBase64: string;
    backImageSize: string;
    imagePositionId: number;
    imagePostition: string;
    showPageNum: boolean;
    customFooterContent: string;
    customFooterPHTypeId: string;
    showSignature2: boolean;
    sigLabel2: string;
    sigImage2: string;
    sigImageInBase642: string;
    sigWidth2: string;
    sigHeight2: string;
    sigName2: string;
    showSigLine2:boolean;
    sliderControl2:number;
    rightShowSignature2: boolean;
    rightSigLabel2: string;
    rightSigImage2: string;
    rightSigImageInBase642: string;
    rightSigWidth2: string;
    rightSigHeight2: string;
    rightSigName2: string;
    rightShowSigLine2:boolean;
    rightSliderControl2:number;
}

export class TemplateVoucherAnnexure {
    id: number;
    tmVoucherId: number;
    annexContent: string;
    annexPHTypeId: number;
}

export class PODetails {
    headerContent: string;
    orgName: string;
    orgAddress: string;
    billToCompanyName: string;
    billToAddress: string;
    shipToAddress: string;
    docNumber: string;
    shipPrefField: string;
    dateField: string;
    termsField: string;
    dueDateField: string;
    refField: string;
    addAttenContent: string;
    itemList: VoucherItemList[];
    totalList: VoucherTotalList[];
    totalQuantity: number;
    subTotal: number;
    total: number;
    currencySymbol: string;
    notes: string;
    termsConditions: string;
    customFooterContent: string;
    annexure: string;
    contactToAddress:string;
}

export class VoucherItemList {
    lineNumber: number;
    item: string;
    desc: string;
    quantity: number;
    unit: string;
    rate: number;
    taxPerc: string;
    taxAmount: number;
    discount: number;
    amount: number;
}

export class VoucherTotalList {
    desc: string;
    amount: number;
}
