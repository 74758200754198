<ng-container [formGroup]="dynamicform">
    <ng-container formArrayName="fieldArray" *ngIf="dynamicform.get('fieldArray')['controls'].length > 0">
        <div class="row pb-2">
        <ng-container *ngFor="let item of dynamicform.get('fieldArray')['controls']; let k = index">
            <div class="col-md-3" [formGroup]="item">
                <ng-container *ngIf="lstCustomFields[k].fieldTypeID==1">
                    <label class="col-form-label">{{ lstCustomFields[k].fieldName }}
                        <span *ngIf="lstCustomFields[k].isMandatory" class="text-danger">*</span>
                    </label>
                    <input class="form-control" type="text" formControlName="{{ lstCustomFields[k].fieldID }}"
                        autocomplete="off" maxlength="100" xssDirective
                        [ngClass]="{ 'is-invalid': submitted && item.controls[lstCustomFields[k].fieldID].errors }">
                    <div *ngIf="submitted && item.controls[lstCustomFields[k].fieldID].errors" class="invalid-feedback">
                        Is required
                    </div>
                </ng-container>
                <ng-container *ngIf="lstCustomFields[k].fieldTypeID==2">
                    <label class="col-form-label">{{ lstCustomFields[k].fieldName }}
                        <span *ngIf="lstCustomFields[k].isMandatory" class="text-danger">*</span>
                    </label>
                    <ng-select [multiple]="false" placeholder="Select" [clearable]="true"
                        formControlName="{{ lstCustomFields[k].fieldID }}" [items]="lstCustomFields[k].customValue"
                        bindValue="CustomValueID" bindLabel="CustomValueText"
                        [ngClass]="{ 'is-invalid': submitted && item.controls[lstCustomFields[k].fieldID].errors }">
                    </ng-select>
                    <div *ngIf="submitted && item.controls[lstCustomFields[k].fieldID].errors" class="invalid-feedback">
                        Is required
                    </div>
                </ng-container>
                <ng-container *ngIf="lstCustomFields[k].fieldTypeID==3">
                    <label class="col-form-label">{{ lstCustomFields[k].fieldName }}
                        <span *ngIf="lstCustomFields[k].isMandatory" class="text-danger">*</span>
                    </label>
                    <input class="form-control" formControlName="{{ lstCustomFields[k].fieldID }}"
                        placeholder="dd/mm/yyyy" name="d4" date ngbDatepicker maxlength="10" autocomplete="off"
                        #d4="ngbDatepicker" (click)="d4.toggle()"
                        [ngClass]="{ 'is-invalid': submitted &&  item.controls[lstCustomFields[k].fieldID].errors }">
                    <div *ngIf="submitted && item.controls[lstCustomFields[k].fieldID].errors" class="invalid-feedback">
                        Is required</div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    </ng-container>
</ng-container>