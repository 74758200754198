<div bsModal #showmodal="bs-modal" class="modal fade" [config]="{ backdrop: 'static' }" tabindex="-1" role="dialog"
    aria-labelledby="showmodal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">

        <form class="form-horizontal" [formGroup]="emailtemplateForm" novalidate="novalidate" (ngSubmit)="onSubmit()">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{'SharedModule.EmailTitles.' + sendTitle | translate}}</h4>

                    <button type="button" class="close" (click)="CloseModel()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-form-label" for="From">{{'PurchaseModule.PurchaseOrder.Template Name' | translate}}</label>
                            <div class="col-md-8">
                                <select formControlName="emailTempID" class="form-select" id="ddTemplateName"
                                    [(ngModel)]="defaultselectedOption" (change)="loadTemplate(defaultselectedOption)">
                                    <option *ngFor="let c of lstEmailTemplate" [ngValue]="c.emailTempID">
                                        {{c.templateName}}
                                    </option>
                                </select>
                            </div>

                            <app-emailattachment class="col-md-2 ps-0" [transID]='transID' [transTypeID]='transTypeID' 
                            [templateId]="templateId" [templateType]="templateType" [templateGroupId]="templateGroupId"
                            [IsNewViewScreen]="IsNewViewScreen">
                            </app-emailattachment>

                        </div>
                        <div class="form-group row">
                            <label class="col-md-2 col-form-label" for="From">{{'PurchaseModule.PurchaseOrder.From' | translate}}</label>
                            <div class="col-md-4" style="font-size: medium;font-weight: bold;">
                                <label class="col-form-label" for="From"> {{emailtemplateForm.value.from}}</label>
                            </div>
                            <label class="col-md-1 col-form-label" for="From">{{'PurchaseModule.PurchaseOrder.Reply To' | translate}}</label>
                            <div class="col-md-4" style="font-size: medium;font-weight: bold;">
                                <label class="col-form-label" for="Reply"> {{emailtemplateForm.value.replyTo}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2 col-form-label" for="to">{{'Common.To' | translate}}<span class="text-danger">*</span>
                                <button type="button" triggers="mouseenter:mouseleave" [popover]="popTemplateHtml"
                                    popoverTitle="{{'SharedModule.Email Format To' | translate}}" class="btn inforight  pb-0">
                                    <i class="fa fa-info-circle"></i></button>
                                <ng-template #popTemplateHtml>
                                    <div><span class="help-block text-warning">{{'PurchaseModule.PurchaseOrder.Separate multiple email addresses with a comma(,) or a semicolon(;)' | translate}}</span>
                                    </div>
                                </ng-template>
                            </label>
                            <div class="col-md-10">

                                <div class="controls">
                                    <div class="input-group">
                                        <input type="text" class="form-control" formControlName="to" id="txtdo"
                                            [ngClass]="{ 'is-invalid': submitted && f.to.valid }" />


                                        <span class="input-group-append">
                                            <button [ngClass]="{'active': ccEnable }" type="button" (click)="showCC()" id="btncc"
                                                class="btn btn-outline-info">{{'SharedModule.CC' | translate}}</button>
                                            <button [ngClass]="{'active': bccEnable }" type="button" (click)="showBcc()" id="btnbcc"
                                                class="btn btn-outline-info">{{'SharedModule.BCC' | translate}}</button>
                                        </span>
                                        <br />
                                        <div *ngIf="submitted && f.to.errors"
                                            [style.display]="submitted && f.to.errors?'block':'none'"
                                            class="invalid-feedback">
                                            <div *ngIf="f.to.errors.required">{{'SharedModule.Please provide a To Mail ID' | translate}}</div>
                                            <div *ngIf="emailtemplateForm.controls.to?.errors.custom">{{'SharedModule.Please provide a valid To Mail ID' | translate}}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="ccEnable">
                            <label class="col-md-2 col-form-label" for="cc">{{'SharedModule.cc' | translate}}
                                <button type="button" triggers="mouseenter:mouseleave" [popover]="popTemplateHtml"
                                    popoverTitle="Email Format CC" class="btn inforight  pb-0">
                                    <i class="fa fa-info-circle"></i></button>
                                <ng-template #popTemplateHtml>
                                    <div><span class="help-block text-warning">{{'PurchaseModule.PurchaseOrder.Separate multiple email addresses with a comma(,) or a semicolon(;)' | translate}}</span>
                                    </div>
                                </ng-template>
                            </label>
                            <div class="col-md-10">
                                <input type="text" formControlName="cc" class="form-control" id="txtcc"
                                    [ngClass]="{ 'is-invalid': submitted && f.cc.errors }" />
                                <div *ngIf="submitted && f.cc.errors" class="invalid-feedback"
                                    [style.display]="submitted && f.cc.errors?'block':'none'">
                                    <div *ngIf="f.cc.errors.custom">{{'SharedModule.Please provide a valid cc' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="bccEnable">
                            <label class="col-md-2 col-form-label" for="bcc">{{'SharedModule.bcc' | translate}}
                                <button type="button" triggers="mouseenter:mouseleave" [popover]="popTemplateHtml"
                                    popoverTitle="Email Format Bcc" class="btn inforight  pb-0">
                                    <i class="fa fa-info-circle"></i></button>
                                <ng-template #popTemplateHtml>
                                    <div><span class="help-block text-warning">{{'PurchaseModule.PurchaseOrder.Separate multiple email addresses with a comma(,) or a semicolon(;)' | translate}}</span>
                                    </div>
                                </ng-template>
                            </label>
                            <div class="col-md-10">
                                <input type="text" formControlName="bcc" class="form-control"id="txtbcc"
                                    [ngClass]="{ 'is-invalid': submitted && f.bcc.errors }" />
                                <div *ngIf="submitted && f.bcc.errors"
                                    [style.display]="submitted && f.bcc.errors?'block':'none'" class="invalid-feedback">
                                    <div *ngIf="f.bcc.errors.custom">{{'SharedModule.Please provide a valid bcc' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2 col-form-label" for="subject">{{'SharedModule.Subject' | translate}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <input type="text" formControlName="subject" class="form-control" maxlength="500" id="txtsubject"
                                    [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" />
                                <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                    <div *ngIf="f.subject.errors.required">{{'SharedModule.Please provide a Subject' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <quill-editor formControlName="body" class="w-100">
                                <div quill-editor-toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-font" title="Font">
                                            <option selected></option>
                                            <option value="serif"></option>
                                            <option value="monospace"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-size">
                                            <option value="6pt">6pt</option>
                                            <option value="8pt" selected>8pt</option>
                                            <option value="9pt">9pt</option>
                                            <option value="10pt">10pt</option>
                                            <option value="11pt">11pt</option>
                                            <option value="12pt">12pt</option>
                                            <option value="13pt">13pt</option>
                                            <option value="14pt">14pt</option>
                                            <option value="16pt">16pt</option>
                                            <option value="18pt">18pt</option>
                                            <option value="24pt">24pt</option>
                                            <option value="30pt">30pt</option>
                                            <option value="36pt">36pt</option>
                                            <option value="48pt">48pt</option>
                                        </select>
                                    </span>   
                                    <span class="ql-formats">
                                        <button class="ql-bold" title="Bold"></button>
                                        <button class="ql-italic" title="Italic"></button>
                                        <button class="ql-underline" title="Underline"></button>
                                        <button class="ql-strike" title="Strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color" title="Font Color"></select>
                                        <select class="ql-background" title="Font Background Color"></select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered" title="Ordered List"></button>
                                        <button class="ql-list" value="bullet" title="UnOrdered List"></button>
                                        <select class="ql-align">
                                            <option selected></option>
                                            <option value="center" title="Center Position"></option>
                                            <option value="right" title="Right Position"></option>
                                            <option value="justify" title="justify Position"></option>
                                        </select>
                                    </span>
                                    <!-- <span class="ql-formats">
                                        <button class="ql-link"></button>
                                        <button class="ql-image"></button>
                                    </span> -->
                                    <span class="ql-formats">

                                        <div class="dropdown" *ngIf="typeID!=18" dropdown>
                                            <button class="dropdown-toggle" id="dropdownMenu4" type="button"
                                                dropdownToggle aria-haspopup="true" aria-expanded="false">{{'SharedModule.Insert Placeholders' | translate}}</button>
                                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right ddm-placeholder-size"
                                                style="margin-right: -150px; margin-top:30px">
                                                <div class="d-flex">
                                                    <div *ngFor="let phdetail of phdetails;let idx=index">
                                                        <h6 class="dropdown-header text-muted h6"
                                                            *ngIf="phdetail.header">
                                                            {{phdetail.field}}</h6>
                                                        <div *ngFor="let subMenulist of phdetail.submenu;">
                                                            <a class="dropdown-item ddi-placeholder-size"
                                                                id="ddmInsertPlaceholders_subMenulist.code"
                                                                (click)="changeCustom($event,subMenulist.code)">{{subMenulist.field}}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </quill-editor>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="submitted && text==null">
                    <div class="form-group row" style="text-align: center;">
                        <small class="col-md-11 col-form-label" style="color: #f86c6b;font-size:80%"> <i
                                class="cil-warning"></i> {{'SharedModule.Please provide a Template Content' | translate}} </small>
                    </div>
                    <div class="form-group row" *ngIf="Placeholder!=''">
                        <small class="col-md-11 col-form-label" style="color: #f86c6b;font-size:80%"> <span
                                [innerHTML]="Placeholder"></span> </small>
                    </div>
                </div>
                <div class="modal-footer">
                    <div *ngIf="isIncludeAttachment" class="custom-control-mid custom-checkbox d-none">
                        <input id="includeAttachment" formControlName="includeAttachment" type="checkbox"
                            class="custom-control-input" />
                        <label class="custom-control-label" for="includeAttachment">{{'Common.Include Attachement' | translate}} </label>
                    </div>
                    <div class="custom-control-mid custom-checkbox">
                        <input id="Sendmecopy" formControlName="Sendmecopy" type="checkbox"
                            class="custom-control-input" />
                        <label class="custom-control-label" for="Sendmecopy" id="lblsendmeacopy">{{'SharedModule.Send me a copy' | translate}} </label>
                    </div>
                    <div *ngIf="isMarkasSent" class="custom-control-mid custom-checkbox">
                        <input id="markasSent" type="checkbox" name="markasSent" formControlName="markasSent"
                            class="custom-control-input" />
                        <label class="custom-control-label" for="markasSent" id="lblmarkassent">{{'Common.Mark as Sent' | translate}} </label>
                    </div>
                    <button [disabled]="loading" class="btn btn-info" *userCanView="[true]" id="btnSend"> 
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        <span class="c-icon material-icons-outlined m-0">send</span> {{'Common.Send' | translate}}
                    </button>
                    <button class="btn btn-danger" type="button" (click)="CloseModel()" id="btnCancel">{{'Common.Cancel' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>