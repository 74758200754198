import { ICellEditorParams } from '@ag-grid-community/all-modules';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seriallist',
  templateUrl: './seriallist.component.html',
  styleUrls: ['./seriallist.component.scss']
})
export class SeriallistComponent implements ICellEditorAngularComp, AfterViewInit {
  lstSerial: any = [];
  isShowCreateNew: boolean = false;
  newSerialNo: string;
  gridParams: any;
  serialInfo: any;
  constructor() {

  }

  getValue() {
    return this.serialInfo;
  }

  agInit(params: ICellEditorParams): void {
    this.gridParams = params;
    if (this.gridParams.isShowCreateNew != undefined && this.gridParams.isShowCreateNew)
      this.isShowCreateNew = true;
    if (this.gridParams.isWarehouseEnabled && (this.gridParams.data.warehouseID == 0 || this.gridParams.data.warehouseID == undefined)) {
      this.lstSerial = [];
    } else {
      let allSerials = this.gridParams.allSerials.filter(e => e.warehouseID == params.data.warehouseID);
      this.lstSerial = this.gridParams.lstSerial.filter(e => e.warehouseID == params.data.warehouseID);
      if (params.value!! && params.value != '' && this.lstSerial.findIndex(e => e.serialID == params.value) == -1) {
        let serial = allSerials.find(e => e.serialID == params.value);
        if (serial)
          this.lstSerial.splice(0, 0, serial);
      }
      this.serialInfo = params.value;
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  onChange() {
    if (this.serialInfo)
      this.gridParams.api.stopEditing();
  }

  isPopup(): boolean {
    return true;
  }

  onSearchForApi(params) {
    this.newSerialNo = params.term;
    if (this.gridParams.isShowCreateNew != undefined && this.gridParams.isShowCreateNew)
      this.isShowCreateNew = this.lstSerial?.filter(e => e.serialNo.toString().toUpperCase() == this.newSerialNo.toString().toUpperCase()).length > 0 ? false : true;
  }

  saveNewSerial() {
    if (this.newSerialNo) {
      this.serialInfo = {
        serialNo: this.newSerialNo,
        isNew: true
      };
      this.gridParams.api.stopEditing();
    }
  }

}
