import { Component, ElementRef, Inject, KeyValueDiffers, OnInit, Renderer2, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../../utilities/_services/authentication.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../utilities/_services/loader.service';
import { LocalCacheServices } from '../../utilities/_services/acclocalcache.service';
import { AccsessioncacheService } from '../../utilities/_services/accsessioncache.service';
import { MasterServices } from '../../utilities/_services/master.service';
import { Menunav } from '../../utilities/_models/menunav'
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { PageServices } from '../../utilities/_services/page.service';
import { AlertMessage } from '../../utilities/_helpers/alert.message'
import { HttpServices } from '../../utilities/_services/http.service';
import { environment } from '../../../environments/environment';
import { EncryptDecryptService } from '../../utilities/_services/encryptdecrypt.service'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import { debounceTime, filter, finalize, mergeMap, tap } from 'rxjs/operators';

import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { DefaultValueAccessor } from '@angular/forms';
import { DEFAULT_INTERRUPTSOURCES, DocumentInterruptSource, Idle, InterruptSource, StorageInterruptSource } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ReleaseNotesService } from '../../utilities/_services/release-notes.service';
import { DOCUMENT, DatePipe } from '@angular/common';
import { OrganizationModule } from '../../views/organization/organization.module';
import { Title } from '@angular/platform-browser';
import { HostService } from '../../utilities/_services/host.serices';
import { TicketService } from '../../views/help/digisme/ticket/ticket.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OnboardingComponent } from '../../views/shared/onboarding/onboarding.component';
import { TicketCaptureComponent } from '../../views/shared/ticket-capture/ticket-capture.component';
import html2canvas from 'html2canvas';
//declare let gtag: Function;

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  host: {
    "(window:click)": "onClick()"
  }
})

export class DefaultLayoutComponent implements OnInit {
  lacalspace: string = "";
  sessionspace: string = "";
  public sidebarMinimized = false;
  public sidenav: Menunav[] = [];
  public topnav: any = [];
  public shortcutnav: any = [];
  public profilenav: any = [];
  public favoritenav: any = [];

  hidegstDiv: boolean = true;
  currentUser: any;
  horizantalmenus: any;
  activePageId: number = null;
  parentPageID: number;
  pageID: number;
  routerurl: string
  languages: any;
  selectedLang: any;

  usermanualurl: string = "https://support.infotech-accounting.com";

  showlogin = "none";
  loginForm: FormGroup | any;
  submitted = false;
  loading = false;
  error = '';

  searchCustomerCtrl = new FormControl();
  filteredCustomers: any = [];

  UnReadCnt: number = 0;
  TotalCnt: number = 0;
  TotalNoteCnt: number = 0;
  notifications: any = [];
  approvalNotifications: any = [];
  isProduction: boolean;
  backgroundTaskNotification: any = [];

  value = 0;
  items: TreeviewItem[];
  config = TreeviewConfig.create({
    hasFilter: true,
    hasCollapseExpand: true,
    maxHeight: 500
  });

  isSetting = false;
  sidePanel = false;
  searchtopnav: any = '';
  public showtopnav: any = [];
  sidePanelAction: string = '';
  manageFavorite: string = 'Add';
  isFavorite: boolean = false;
  successNotifierId: number = 0;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  routeEventSubscription: any;
  subscriptionDate: any[] = [];
  startdate: string = '';
  enddate: string = '';
  date: any;
  allTransactionsCount: any[] = [];

  @ViewChild('modalPDPAAlert')
  public modalPDPAAlert: ModalDirective
  isLinkcliked: boolean = true;
  Openedwindows = [];
  HRMSUrl: string = 'https://www.infotech-cloudhr.com.sg/';

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  favoriteName: string = null;
  SubscriberOrgID = 0;
  @ViewChild('showaddorgmodal', { static: false }) showaddorgmodal: ModalDirective;
  @ViewChild('modallicenseGUID', { static: false }) modallicenseGUID: ModalDirective;
  @ViewChild('modalDigismePlan', { static: false }) modalDigismePlan: ModalDirective;
  @ViewChild(OnboardingComponent) public Onboarding: OnboardingComponent;
  isDigiSME = false;
  isDigiSMESuccess = false;
  logoimg: any = "";
  minilogoimg: any = "";
  isDashboard: boolean = false;
  isSearch = false;
  lstSearchedContent: any = [];
  searchText: string = "";
  loadingContent = false;
  hidegstDivorgID = 0;
  @ViewChild("screen") screen!: ElementRef;
  img: any;
  isCaptureLoading : boolean=false;
  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private bnIdle: BnNgIdleService,
    public translate: TranslateService, public loader: LoaderService, public local: LocalCacheServices,
    public session: AccsessioncacheService, private http: HttpServices, private encdec: EncryptDecryptService,
    private alertMessage: AlertMessage, private page: PageServices, private _http: HttpClient,
    private router: Router, private master: MasterServices, private activatedRoute: ActivatedRoute,
    private releaseNotesService: ReleaseNotesService, public datepipe: DatePipe,
    private idle: Idle, private keepalive: Keepalive, @Inject(DOCUMENT) public document: Document, private titleService: Title,
    private hostService: HostService, private digismecrmservice: TicketService, private modalServive: NgbModal,
    private renderer:Renderer2) {
    this.isProduction = environment.production;
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = 'site-theme';
    style.rel = 'stylesheet';
    style.href = `${styleName}`;
    head.appendChild(style);
  }

  async ngOnInit(): Promise<void> {
    
    this.translate.onLangChange.subscribe(async() => { this.selectedLang = localStorage.getItem('SelectedLang')}); 
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    this.isDigiSME = this.hostService.getDigisme();
    if(this.currentUser.orgCountryID == 129){
     this.hidegstDivorgID =  this.currentUser.orgID;
     let hidegstDiv =  localStorage.getItem("hidegstDiv" + this.hidegstDivorgID.toString());
     if (hidegstDiv == "true"){
      this.hidegstDiv = true;
     }
     else{
      this.CheckTaxRateMYUpdate();
     }
    }
    console.log("isDigiSME: ", this.isDigiSME);
    if (this.isDigiSME) {
      this.loadStyle("DigiSME.css");
      this.titleService.setTitle("DigiSME Accounting Software");
      this.logoimg = { src: 'assets/brand/digisme-logo.png', width: 80, height: 18, alt: 'Digi-SME Logo' };
      this.minilogoimg = { src: 'assets/brand/digisme-Logo_xsm.svg', width: 40, alt: 'Digi-SME Logo' }
      let spliturl = this.router.url.split("?");
      if (spliturl[0] == "/dashboard") {
        if (spliturl.length > 1) {
          if (spliturl[1] == "status=success") {
            this.isDigiSMESuccess = true;
          }
        }
      }
    }
    else {
      //this.loadStyle("Accounting.css");
      this.titleService.setTitle("Info-Tech Accounting Software");
      this.logoimg = { src: 'assets/brand/Info-Tech-Logo_sm.png', width: 80, height: 31, alt: 'Info-Tech Logo' };
      this.minilogoimg = { src: 'assets/brand/Info-Tech-Logo_xsm.png', alt: 'Info-Tech Logo' }
    }
    this.usermanualurl = environment.production ? (this.isDigiSME?"https://digismeaccmanual.azurewebsites.net/":"https://support.infotech-accounting.com") : "https://stgsgaccmanual.azurewebsites.net";
    window.addEventListener('storage', function (e) {
      if (e.key === "IkN1cnJlbnRVc2VyIg==") // login session key
      {
        // window.location.reload();
        window.location.href = window.location.origin + '/dashboard';
        window.location.reload();
      }
    }, false);

    this.pageInitLoad();

    if(this.currentUser.orgCountryID == 129 || this.currentUser.orgCountryID == 196){
      this.usermanualurl = this.usermanualurl + "?cid=" + btoa(this.currentUser.orgCountryID) + "&lid=" + (this.isDigiSME ? btoa("6") : btoa("1"));
    }else{
      this.usermanualurl = this.usermanualurl + "?cid=" + btoa('196') + "&lid=" + (this.isDigiSME ? btoa("6") : btoa("1"));
    }

// this.master.getOrgCountryDetails().then((data: any) => {
//       this.usermanualurl = this.usermanualurl + "?cid=" + btoa(data.ResponseData) + "&lid=" + (this.isDigiSME ? btoa("6") : btoa("1"));
//     })

    //google analytics commented
    //this.setUpAnalytics();
    this.customerSearch();
    if (this.currentUser.userType == 1) {
      this.GetFavoritePage();
    }
    this.items = this.getOrgNodes();

    const original = DefaultValueAccessor.prototype.registerOnChange;

    DefaultValueAccessor.prototype.registerOnChange = function (fn) {
      return original.call(this, value => {
        const trimmed = typeof (value) === 'string' ? value.trim() : value;
        return fn(trimmed);
      })
    }
  }

  ngAfterViewInit() {
    // GetPDPAUser -- If orgCountryID is SGD
    if (!this.isDigiSME) {
      if (this.currentUser.orgCountryID == 196 && this.currentUser.licenseTypeID !== 2) {
        this.http.getservice(environment.organizationApiUrl + '/Organization/GetPDPAUser/USERCHECK').subscribe({
          next: (data: any) => {
            if (data == "1") {
              this.modalPDPAAlert.show();
            }
          }
        });
      }
    }
    else {
      if (this.currentUser.licenseRemaining < 0) {
        this.modalDigismePlan.show();
      }
    }
    // this.getTraining();
  }

  setupIdle() {
    console.log("Loaded Datetime : ", new Date());

    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(environment.idleTimeOut);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(30);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts([new DocumentInterruptSource("keydown DOMMouseScroll touchmove scroll click"), new StorageInterruptSource()]);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
      this.reset();
    });

    // this.idle.onInterrupt.subscribe(() => {
    //   this.idleState = 'Watching has interrupted.'
    //   console.log(this.idleState, " : ", new Date(), " : ", this.router.url );
    // });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
      this.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.alertMessage.clearAlertbox();
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
      this.modalPDPAAlert.hide();
      this.modalServive.dismissAll();
      this.childModal.show();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will be logged out in ' + countdown + ' seconds!'
      // console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  //Google analytics commented
  // setUpAnalytics() {
  //   this.router.events.pipe(filter(event => event instanceof NavigationEnd))
  //     .subscribe((event: NavigationEnd) => {
  //       gtag('config', 'G-E69N6NS9GG',
  //         {
  //           page_path: event.urlAfterRedirects
  //         }
  //       );
  //     });
  // }

  redirectPDPA() {
    this.Openedwindows.push(window.open('https://www.info-tech.com.sg/pdpa.htm', '_blank'))
    this.isLinkcliked = false;
  }

  ConfirmClick(): void {
    let param = new HttpParams();
    param = param.set('optionMode', 'UPDATE');
    this.http.postservice(environment.organizationApiUrl + '/Organization/UpdatePDPAUser', param).subscribe(data => {
      this.modalPDPAAlert.hide();

      // close the tab automtically
      this.CloseOpenedTabs();
    });
  }

  CloseOpenedTabs() {
    if (this.Openedwindows.length > 0) {
      this.Openedwindows.forEach(element => {
        element.close();
      });
      this.Openedwindows = [];
    }
  }

  CancelClick(): void {
    this.isLinkcliked = true;
    this.modalPDPAAlert.hide();
    this.logout();
  }

  pageInitLoad() {
    //this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    let subscriberOrg = this.currentUser.organizations.filter(x => x.customerCode == this.currentUser.customerCode);
    if (subscriberOrg.length > 0) {
      if (subscriberOrg.filter(x => x.orgID == this.currentUser.orgID).length > 0) {
        this.SubscriberOrgID = subscriberOrg.filter(x => x.orgID == this.currentUser.orgID)[0].subscribedOrgID;
      }
      else {
        this.SubscriberOrgID = subscriberOrg[0].subscribedOrgID;
      }
    }

    if (this.currentUser != null) {
      if (this.currentUser.userType == 3) {
        //this.router.navigate(['supportdashboard']);
        window.location.href = window.location.origin + '/supportdashboard';
        //window.location.reload();
        return;
      }

      this.setupIdle();
      this.menusplit();

      this.routeEventSubscription = this.router.events.subscribe(async (val) => {
        if (val instanceof NavigationEnd) {
          let timeout = this.authenticationService.getTokenTime();
          if (timeout <= 180 && timeout > 0) {
            await this.authenticationService.refreshToken();
          }
          this.authenticationService.startRefreshTokenTimer();
          this.reset();
        }
      });
      // if(this.authenticationService.idleState == "Not started.")
      // {
      //   this.authenticationService.refreshToken();
      //   this.reset();
      // }
       
      
      this.getorganizationsetting();
      this.getLangDetails();
      this.GetUserNotifications();
      this.lacalspace = this.local.getLocalStorageSize();
      this.sessionspace = this.session.getSessionStorageSize();

      this.routerurl = '';

      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
      });
    }
    else {
      this.logout();
    }
  }

  reset() {
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
    if (this.currentUser != null) {
      this.idle.watch();
      this.idleState = 'Watching Started.';
      this.timedOut = false;
      if (this.childModal)
        this.childModal.hide();
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
    }
    // else {
    //   this.idle.stop();
    // }
  }

  stay() {
    this.childModal.hide();
    this.reset();
    // this.router.navigateByUrl(this.router.url);
    window.location.reload();
  }

  menusplit() {
    /*  let allsidenav = this.currentUser.menuList.filter(x => x.menuType == "sidenav");
      let parentsidenav = allsidenav.filter(x => x.parentPageID == 0);
     for (let i = 0; i < parentsidenav.length; i++) {
       let childmenu = allsidenav.filter(x => x.parentPageID == parentsidenav[i].pageID);
       parentsidenav[i].childMenu = childmenu
     }  */
    //this.sidenav = parentsidenav;
    this.sidenav = this.currentUser.sideMenuList;

    let alltopnav = this.currentUser.menuList.filter(x => x.menuType == "topnav");
    let parenttopnav = alltopnav.filter(x => x.parentPageID == 0) as any[];
    for (let i = 0; i < parenttopnav.length; i++) {
      let childmenu = alltopnav.filter(x => x.parentPageID == parenttopnav[i].pageID);
      parenttopnav[i].childMenu = childmenu
    }
    if ((this.currentUser.isSubscriber == true && this.SubscriberOrgID > 0) || this.currentUser.licenseTypeID == 7) {
      this.topnav = parenttopnav;
    }
    else {
      this.topnav = parenttopnav.filter(o => o.url != '/settings/AddOrganization');
    }
    this.showtopnav = alltopnav;

    this.shortcutnav = this.currentUser.menuList.filter(x => x.isShortcut == true);
    this.profilenav = this.currentUser.menuList.filter(x => x.menuType == "profile");

    if (this.currentUser.isSubscriber) {  // Subscriber
      let subscriptionMenu = this.currentUser.menuList.filter(x => x.menuType == "subscriber");
      if (subscriptionMenu.length > 0) {
        let subMenu: any = subscriptionMenu.filter(m => m.url.includes("subscriptionlist"))[0];
        subMenu.name = "Subscription";
        this.profilenav.push(subMenu);
      }

    }

    for (let i = 0; i < this.topnav.length; i++) {
      for (let s = 0; s < this.topnav[i].childMenu.length; s++) {
        let subChildMenu = alltopnav.filter(x => x.parentPageID == this.topnav[i].childMenu[s].pageID);
        this.topnav[i].childMenu[s].subChildMenu = subChildMenu;
      }
    }
  }

  async getLangDetails() {
    if (this.currentUser.orgCountryID == 94) {
      this.master.getLanguageList().then((data) => {
        this.languages = data;
        let langarray: string[] = [];
        this.languages.forEach((lang) => {
          langarray.push(lang.LanguageCode);
        });
        this.translate.addLangs(langarray);
        var SelectLanguage=localStorage.getItem('SelectedLang');
        let orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
        if(orgSettings && SelectLanguage==undefined && orgSettings.languageCode!=undefined){
          this.translate.setDefaultLang(orgSettings.languageCode);
          this.selectedLang = orgSettings.languageCode;
        }
        else if(SelectLanguage==undefined){
          this.translate.setDefaultLang('zh_HK');
          this.selectedLang = "zh_HK";
        }else{
          this.translate.setDefaultLang(SelectLanguage);
          this.selectedLang = SelectLanguage;
        }
      });
    }
    else {
      this.translate.setDefaultLang('en_US');
      this.selectedLang = "en_US";
    }    
  }

  ngAfterContentChecked() {
    //this.titleService.setTitle("Info-Tech Accounting Software")
    if (this.routerurl != this.router.url) {
      if (this.currentUser.isPasswordExpired && this.router.url != "users/profile") {
        this.router.navigate(['users/profile'], { queryParams: { isforce: this.encodebtoa("force") } });
      }
      if (this.router.url == "/dashboard") {
        this.isDashboard = true;
      }
      else {
        this.isDashboard = false;
      }
      this.alertMessage.clearNotifier();
      this.routerurl = this.router.url;
      this.checkMenuViewAccees();
      this.lacalspace = this.local.getLocalStorageSize();
      this.sessionspace = this.session.getSessionStorageSize();

      if ((this.router.url.indexOf('/reports') > -1) || (this.router.url.indexOf('/budget') > -1) || (this.router.url.indexOf('/help') > -1)
        || (this.router.url.indexOf('/dashboard') > -1)) {
        this.sidebarMinimized = true;
      } else {
        this.sidebarMinimized = false;
      }
    }
  }

  isBookDemo: boolean = false;
  async getTraining() {
    let apiUrl = environment.administrationApiUrl + '/Training/getTraining';
    await this.http.getserviceawait(apiUrl)
      .then((result: any) => {
        if (result.isSuccess) {
          let lstTraining = JSON.parse(result.responseData) as any[];
          this.isBookDemo = (lstTraining.length > 0);
        }
      }).catch(() => {
      });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.childModal.hide();
    this.idle.stop();
    this.idleState = 'logout'
    console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
    if (this.routeEventSubscription)
      this.routeEventSubscription.unsubscribe();
    this.alertMessage.clearNotifier();
    this.authenticationService.logout();
  }

  switchLang(event: any) {  
    this.selectedLang = event.target.value;
    localStorage.setItem('SelectedLang',event.target.value);
    this.translate.use(event.target.value);  
  }

  checkMenuViewAccees() {
    this.page.setPageDetails();
    this.page.setAccessRights();
    this.horizantalmenus = [];
    this.activePageId = 0;

    if (this.router.url != "/dashboard" && this.router.url != "/supportdashboard") {
      if (this.page.accessRights.canView == true) {
        let childpages = this.currentUser.menuList.filter(x => x.pageID == this.page.pageDetails.parentPageID);
        if (childpages.length > 0) {
          this.horizantalmenus = childpages[0].childMenu;
          if (this.page.pageDetails.menuType == "function") {
            this.activePageId = this.page.pageDetails.dependantPageID;
          }
          else {
            this.activePageId = this.page.pageDetails.pageID;
          }
        }
      }
      else {
        console.log("DL-routerurl:", this.router.url)
        console.log("DL-accessRights: ", this.page.accessRights)
        let spliturl = this.router.url.split("?");
        let url = spliturl[0];
        if (url == '/login'){
          console.log("log time:", new Date())
          this.router.navigate(['dashboard']);         
        }
        else{
          this.alertMessage.alertWithCustomizeError({ message: this.translate.instant("Common.You are not allowed to use this screen") });
          this.router.navigate(['dashboard']);
        }
      }
    }
    this.onCheckFavoritePage();
  }

  async getorganizationsetting() {
    let httpData = await this.http.getservice(environment.organizationApiUrl + '/Organization/GetFinanceSetting').subscribe({
      next: (data:any) => {
        localStorage.setItem(this.local.localCurrentOrganization, this.encdec.encode(data));    
        let showMyOnBoardModal = this.encdec.decode(localStorage.getItem("MyOnBoardModal"+this.currentUser.centralOrgID));
        data.isonboardformacknowledge = showMyOnBoardModal == "true" ? true:  data.isonboardformacknowledge;      
        if(!data.isonboardformacknowledge && this.currentUser.orgCountryID == 129 && this.currentUser.userType!=2){
          this.Onboarding.openModal();
        }
      }
    });
  }

  async GetUserNotifications() {
    await this.http.getservice(environment.authguardApiUrl + '/UserProfile/GetUserNotifications').subscribe({
      next: (data: any) => {
        let json = JSON.parse(data.value);
        this.UnReadCnt = json.UnReadCnt;
        this.TotalNoteCnt = json.notifications.length;
        this.TotalCnt = json.notifications.filter(x => x.IsHidden == false).length;
        this.notifications = json.notifications.filter(x => x.IsHidden == false);//.splice(0, 3);
        this.approvalNotifications = json.approvalNotificationDetails;
        this.TotalNoteCnt = this.TotalCnt + this.approvalNotifications.length;
        this.UnReadCnt += this.approvalNotifications.length;
        this.GetUserBackgroundTaskNotification();
      }, error: (err) => {
        console.log(err);
      }
    });
  }

  async GetUserBackgroundTaskNotification() {
    let params = new HttpParams();
    params = params.set('flagName', 'Notification');
    await this.http.postservice(environment.organizationApiUrl + '/BackgroundTask/GetUserBackgroudTasks', params).subscribe({
      next: (data: any) => {
        if (data.length > 0) {
          let jsonResponse = data;
          this.backgroundTaskNotification = jsonResponse;
        } else {
          this.backgroundTaskNotification = [];
        }
      }, error: (err) => {
        console.error(err);
      }
    });
  }

  get f() { return this.loginForm.controls; }

  onLoginSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    let us = this.encdec.encode(this.f.username.value);
    let pwd = this.encdec.encode(this.f.password.value);
    // this.authenticationService.login(us, pwd)
    //   .subscribe({
    //     next: () => {
    //       window.location.reload();
    //     },
    //     error: error => {
    //       this.error = error;
    //       this.loading = false;
    //     }
    //   });
  }

  refresh() {
    this.authenticationService.refreshToken();
    window.location.reload();
  }

  ChangeOrg($event, option: any) {
    this.ChangeOrgAPI(option.centralOrgID, 0);
  }

  redirect(url: any, params: any) {
    // this.router.navigate([url], {  queryParams: {  templategroup: params } });
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url], { queryParams: { templategroup: params } }));
  }

  customerSearch() {
    this.searchCustomerCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.error = "";
          this.filteredCustomers = [];
          this.loading = true;
        }),
        mergeMap(value => (this.authenticationService.GetCustomersbySearchText(value))
          .pipe(
            finalize(() => {
              this.loading = false
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (data == undefined) {
          this.error = "Unable to fetch the data";
          this.filteredCustomers = [];
        } else {
          this.error = "";
          this.filteredCustomers = data.ResponseData;
        }
      }, (error) => {
        console.log(error);
      });
  }

  displayFn(value?: any): string {
    return value ? this.filteredCustomers.find((cust: { CustomerCode: any; }) => cust.CustomerCode === value.CustomerCode).CustomerCodeName : value;
  }

  onSelectionChanged(event: any, cust: any) {
    this.ChangeOrgAPI(0, cust.CustomerCode);
  }

  async onHideNotification(event: any, note: any) {
    let params = new HttpParams();
    params = params.set('NotificationID', note.NotificationID);
    await this.http.postservice(environment.authguardApiUrl + '/UserProfile/HideUserNotification', params).subscribe({
      next: (data: any) => {
        let json = JSON.parse(data.value);
        this.UnReadCnt = json.UnReadCnt;
        this.TotalNoteCnt = json.notifications.length;
        this.TotalCnt = json.notifications.filter(x => x.IsHidden == false).length;
        this.notifications = json.notifications.filter(x => x.IsHidden == false);//.splice(0, 3);
        this.GetUserNotifications();
      }, error: (err) => {
        console.log(err);
      }
    });
  }

  async onViewedUserNotification(event: any, note: any) {
    let params = new HttpParams();
    params = params.set('NotificationID', note.NotificationID);
    await this.http.postservice(environment.authguardApiUrl + '/UserProfile/ViewedUserNotification', params).subscribe({
      next: (data: any) => {
        let json = JSON.parse(data.value);
        this.UnReadCnt = json.UnReadCnt;
        this.TotalNoteCnt = json.notifications.length;
        this.TotalCnt = json.notifications.filter(x => x.IsHidden == false).length;
        this.notifications = json.notifications.filter(x => x.IsHidden == false);//.splice(0, 3);
        this.approvalNotifications = json.approvalNotificationDetails;
        this.UnReadCnt += this.approvalNotifications.length;
        this.router.navigate(['/users/usernotifications']);
      }, error: (err) => {
        console.log(err);
      }
    });
  }

  onValueChange(value: number): void {
    this.ChangeOrgAPI(value, 0);
  }

  getOrgNodes(): TreeviewItem[] {
    let nodeitems: TreeviewItem[] = [];
    let orgs = this.currentUser.organizations;
    let custCodes = orgs.map(item => item.customerCode).filter((value, index, self) => self.indexOf(value) === index);
    custCodes.forEach(custcode => {
      let children = [];
      let custOrgs = orgs.filter(x => x.customerCode == custcode);
      let collapsed = custCodes.length == 1 || custcode == this.currentUser.customerCode ? false : true;
      custOrgs.forEach(org => {
        children.push(new TreeviewItem({ text: org.organizationName.toString(), value: org.centralOrgID, collapsed: collapsed }));
      });
      nodeitems.push(new TreeviewItem({
        text: custOrgs[0].customerName.toString(), value: 0, collapsed: collapsed, children: children
      }));
    });
    this.value = this.currentUser.centralOrgID;
    return nodeitems;
  }

  ChangeOrgAPI(centralOrgID: any, customerCode: any) {
    this.loading = true;
    this.authenticationService.changeOrganization(centralOrgID, customerCode).subscribe({
      next: () => {
        this.pageInitLoad();
        //Google analytics commented
        //this.setUpAnalytics();
        this.customerSearch();
        this.items = this.getOrgNodes();
        window.location.href = '';
        //window.location.reload();
        // if (this.router.url === '/dashboard')
        //   window.location.href = '/dashboard';
        // else
        //   window.location.href = '/dashboard';
        //this.router.navigate(['/dashboard']);
      },
      error: error => {
        this.error = error;
        this.loading = false;
      }
    });
  }

  // OnNotificationIconClick(event: any) {
  //   try {
  //     this.GetUserNotifications();
  //   } catch (exceptionDetails) {
  //     console.error("Error occured at OnNotificationIconClick");
  //   }
  // }

  encodebtoa(path: string) {
    return btoa(path);
  }

  filtertopnav() {
    let searchText = this.searchtopnav.toLowerCase();
    let alltopnav = this.showtopnav.filter(x => x.name.toLowerCase().includes(searchText))
    this.topnav = [];
    let parenttopnav = this.showtopnav.filter(x => x.parentPageID == 0);
    for (let i = 0; i < parenttopnav.length; i++) {
      let childmenu = alltopnav.filter(x => x.parentPageID == parenttopnav[i].pageID);
      parenttopnav[i].childMenu = childmenu
    }
    if ((this.currentUser.isSubscriber == true && this.SubscriberOrgID > 0) || this.currentUser.licenseTypeID == 7) {
      this.topnav = parenttopnav;
    }
    else {
      this.topnav = parenttopnav.filter(o => o.url != '/settings/AddOrganization');
    }
  }

  onClick() {
    // environment.isAttachmentPopup = false;
    if (this.sidePanel == true && this.isSetting == false) {
      this.sidePanel = false;
    }
    this.isSetting = false;
  }
  onSetting(action) {
    if (this.sidePanel == true && this.sidePanelAction == action) {
      this.isSetting = false;
      this.sidePanel = false;
    }
    else {
      this.searchtopnav = '';
      if (action == 'S') {
        this.filtertopnav();
      } else if (action == "N") {
        this.GetUserNotifications();
      }
      this.isSetting = true;
      this.sidePanel = true;
    }
    this.sidePanelAction = action;
  }

  ManageFavorite(action) {
    let isAllowFavorite = this.page.pageDetails.isAllowFavorite;
    let pageID = this.page.pageDetails.pageID;
    if (action == '') {
      if (isAllowFavorite == false) {
        this.manageFavorite = '';
      }
      else {
        if (this.isFavorite) {
          this.manageFavorite = 'Remove'
        }
        else {
          this.favoriteName = this.translate.instant(this.page.pageDetails.name);
          this.manageFavorite = 'Add'
        }
      }
    }
    else {
      let spliturl = this.router.url.split("?");
      let payload = {
        pageID: pageID,
        action: action,
        favoriteURL: spliturl[0],
        favoriteQueryParams: spliturl.length == 2 ? spliturl[1] : null,
        favoriteName: this.favoriteName,
      }
      this.http.postservice(environment.authguardApiUrl + '/UserProfile/AddFavoritePage', payload).subscribe({
        next: (data: any) => {
          if (data.responseData != "" && data.responseData != null) {
            this.GetFavoritePage();
            if (action == 'Add') {
              this.successNotifierId = this.alertMessage.successNotifier(this.translate.instant(this.page.pageDetails.name) + " " + this.translate.instant('Common.added to favorite'), this.successNotifierId)
            }
            else if (action == 'Remove') {
              this.successNotifierId = this.alertMessage.successNotifier(this.translate.instant(this.page.pageDetails.name) + " " + this.translate.instant("Common.removed from favorite"), this.successNotifierId)
            }
          }
          else {
            this.favoritenav = [];            
            this.onCheckFavoritePage()
          }
        }
      });
    }
  }

  GetFavoritePage() {
    this.http.getservice(environment.authguardApiUrl + '/UserProfile/GetFavoritePage').subscribe({
      next: (data: any) => {
        if (data.responseData != "" && data.responseData != null) {
          this.favoritenav = JSON.parse(data.responseData);
          this.onCheckFavoritePage();
        }
        else {
          this.favoritenav = [];
        }
      }
    });
  }
  onCheckFavoritePage() {
    let spliturl = this.router.url.split("?");
    let queryParams = spliturl.length == 2 ? spliturl[1] : null;
    let favoritePage = this.favoritenav.filter(x => x.PageID == this.page.pageDetails.pageID && ((x.favoriteQueryParams == '') || (x.favoriteQueryParams == queryParams)))
    if (favoritePage.length > 0) {
      this.isFavorite = true;
    }
    else {
      this.isFavorite = false;
    }
  }

  approvalNotificationsRedirect(approval) {
    if (approval.NotificationType == 'Release Update !') {
      let relaseNoteID = approval.ReleaseID;
      relaseNoteID = !isNaN(Number(relaseNoteID)) ? relaseNoteID : 0;
      this.releaseNotesService.ViewedReleaseNotes().then(result => {
        this.router.navigateByUrl("/latest/releasenotes");
        //window.open(approval.NotificationRoute + '?inputType=' + encodeURIComponent(this.encodebtoa('2')) + '&id=' + encodeURIComponent(this.encodebtoa(relaseNoteID)))
      }).catch(err => {
        this.alertMessage.errorNotifier(JSON.stringify(err), 0);
      });
      return;
    }
    var url = approval.NotificationRoute + '?path=' + this.encodebtoa('WFA') + '&fd=' + this.encodebtoa(approval.FirstTranDate)+ '&ld=' + this.encodebtoa(approval.LastTranDate);
    if(approval.NotificationRoute==this.routerurl.split('?')[0]){
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigateByUrl(url));
    }else{
        this.router.navigateByUrl(url);
    }
  }

  queryformat(queryParams) {
    if (queryParams != undefined && queryParams != null) {
      var qp = queryParams.split('&');
      var strqs = {};
      for (var i = 0; i < qp.length; i++) {
        var a = qp[i].split('=');
        var obj = { [a[0]]: decodeURIComponent(a[1]) };
        strqs = Object.assign(strqs, obj);
      }
      return strqs;
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onWindowScroll(event) {
    var mybutton = document.getElementById("myBtn");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  getHRMSURL(): void {
    console.log(this.currentUser.customerCode);
    let params = new HttpParams();
    params = params.set('customerCode', this.currentUser.customerCode);
    this.http.postservice(environment.authguardApiUrl + "/AuthLogin/GetHRMSUrl", params).subscribe((response: any) => {
      if (response.isSuccess) {
        window.open(response.responseData, "_blank");
      } else {
        console.log('Error on getHRMSURL()');
      }
    });
  }

  redirectSubscriptionPayment() {
    if (this.currentUser.orgCountryID == 129) {
      let _subParams: any = { 'orgID': this.currentUser.centralOrgID, 'countryID': this.currentUser.orgCountryID, 'customercode': this.currentUser.customerCode, 'pageOption': 1, 'planType': 2 };
      this.router.navigate(['subscription/payment'], { queryParams: { subParams: btoa(JSON.stringify(_subParams)) } });
    }
    else {
      let _subParams: any = { 'orgID': this.currentUser.centralOrgID, 'countryID': this.currentUser.orgCountryID, 'customercode': this.currentUser.customerCode, 'pageOption': 1 };
      this.router.navigate(['subscription/payment'], { queryParams: { subParams: btoa(JSON.stringify(_subParams)) } });
    }
  }

  redirectToBookDemo() {
    if (this.isDigiSME && (this.currentUser.bookingURL != "")) {
      window.open(this.currentUser.bookingURL, '_blank');
    }
  }

  onalert() {
    this.sidePanel = true;
  }

  onLicenseInfo(action) {
    if (action == 'O') {
      if (this.isDigiSME) {
        this.getSubscriptiondate();
      }
      else {
        this.modallicenseGUID.show();
      }
    }
    else {
      this.modallicenseGUID.hide();
    }
  }

  ondigisme(callfrom: any) {
    if (callfrom == 'V') {
      this.childModal.hide();
      this.idle.stop();
      this.idleState = 'logout'
      console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
      if (this.routeEventSubscription)
        this.routeEventSubscription.unsubscribe();
      this.alertMessage.clearNotifier();
      this.authenticationService.clearlogouttourl();
    }
    let obj = {
      "CustomerCode": this.currentUser.customerCode,
      "CountryId": (this.currentUser.countryID == 196 ? 1 : (this.currentUser.countryID == 129 ? 2 : (this.currentUser.countryID == 94 ? 3 : (this.currentUser.countryID == 13 ? 4 : (this.currentUser.countryID == 97 ? 5 : 6))))),
      "ReturnUrl": this.currentUser.purchaseNowURL,
      "EmailId": this.currentUser.username,
    }
    let SessionKey = this.currentUser.countryID == 153 ? 'eyJDb3VudHJ5TmFtZSI6Ik5FV1pFQUxBTkQifQ==' : 'eyJDb3VudHJ5TmFtZSI6IkRJR0lTTUVTRyJ9';
    this.digismecrmservice.GetViewPlan(obj).subscribe((r: any) => {
      if (r.IsSuccess) {
        window.open(`${environment.disismeCRMSiteUrl}/checkout?SessionId=${r.StatusObject[0]['SessionId']}&SessionKey=${SessionKey}`, '_self');
      } else {
        console.log("something went worng")
      }
    });
  }
  digiSMEPaymentHistory() {
    let obj = {
      "CustomerCode": this.currentUser.customerCode,
      "CountryId": (this.currentUser.countryID == 196 ? 1 : (this.currentUser.countryID == 129 ? 2 : (this.currentUser.countryID == 94 ? 3 : (this.currentUser.countryID == 13 ? 4 : (this.currentUser.countryID == 97 ? 5 : 6))))),
      "ReturnUrl": this.currentUser.purchaseNowURL,
      "EmailId": this.currentUser.username,
    }
    let SessionKey = this.currentUser.countryID == 153 ? 'eyJDb3VudHJ5TmFtZSI6Ik5FV1pFQUxBTkQifQ==' : 'eyJDb3VudHJ5TmFtZSI6IkRJR0lTTUVTRyJ9';
    this.digismecrmservice.GetViewPlan(obj).subscribe((r: any) => {
      if (r.IsSuccess) {
        window.open(`${environment.disismeCRMSiteUrl}/paymentsinfo?SessionId=${r.StatusObject[0]['SessionId']}&SessionKey=${SessionKey}`, '_blank');
      } else {
        console.log("something went worng")
      }
    });
  }
  getSubscriptiondate() {
    this.http.getservice(environment.organizationApiUrl + '/Dashboard/GetLicenseSubscriptionDate').subscribe({
      next: (data: any) => {
        this.subscriptionDate = JSON.parse(data.responseData).data;

        let currentdate = new Date();
        let date = this.datepipe.transform(currentdate, 'yyyy-MM-dd');
        let currentDate = this.subscriptionDate.find(e => date >= this.datepipe.transform(e.StartDate, 'yyyy-MM-dd') && date <= this.datepipe.transform(e.EndDate, 'yyyy-MM-dd'));
        this.date = currentDate;
        this.getTransactionCount(this.date);
      }
    });
  }
  getTransactionCount(event: any) {
    this.startdate = event.StartDate;
    this.enddate = event.EndDate;
    if (this.startdate != '' && this.enddate != '') {
      this.http.getservice(environment.organizationApiUrl + '/Dashboard/GetTransactionscount' + '?startdate=' + this.startdate + '&enddate=' + this.enddate).subscribe({
        next: (data: any) => {
          this.allTransactionsCount = JSON.parse(data.responseData).data;
          this.modallicenseGUID.show();
        }
      });
    }
  }
  onSearch(searchValue) {
    if (searchValue = 1) {
      this.isSearch = true;
    }
    else {
      this.isSearch = false;
    }
  }
  onSearchFilter(event) {
    this.searchText = event.term.trim().toString();
    this.loadingContent = true;
    var parmURL = '/Admin/GetSearchText?searchText=' + this.searchText;
    if (this.searchText.length >= 3) {
      this.http.getservice(environment.administrationApiUrl + parmURL).subscribe({
        next: (data: any) => {
          this.lstSearchedContent = data.Table;
          this.loadingContent = false;
        }
      });
    }
    else {
      this.lstSearchedContent =  this.translate.instant("lstSearchedContent");
      return false;
    }
  }
  onSelectContent(event, selectCtrl) {
    if (!event) {
      return;
    }
    if (event.TransTypeName == "Inventory") {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/inventory/newitem'], { queryParams: { id: btoa(event.TransID) } }));
    }
    else if (event.TransTypeName == "Purchase Order") {
      if (event.StatusTypeOrAccountID === 3 || event.StatusTypeOrAccountID === 4 || event.StatusTypeOrAccountID === 5) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/purchase/purchaseorderview'], { queryParams: { id: btoa(event.TransID) } }));
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/purchase/editpurchaseorder'], { queryParams: { id: btoa(event.TransID) } }));
      }
    }
    else if (event.TransTypeName == "Sales Order") {
      if (event.StatusTypeOrAccountID > 2) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/sales/salesorder/viewsalesorder'], { queryParams: { id: btoa(event.TransID + ',' + event.TransTypeID) } }));
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/sales/salesorder/editsalesorder'], { queryParams: { id: btoa(event.TransID + ',' + event.TransTypeID) } }));
      }
    }
    else if (event.TransTypeName == "Purchase Debit Note") {
      if (event.StatusTypeOrAccountID > 2) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/purchase/debitnote/viewdebitnote'], { queryParams: { id: btoa(event.TransID + ',' + event.TransTypeID + ',' + event.ContactID) } }));
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/purchase/debitnote/editdebitnote'], { queryParams: { id: btoa(event.TransID + ',' + event.TransTypeID + ',' + event.ContactID) } }));
      }
    }
    else if (event.TransTypeName == "Sales Debit Note") {
      if (event.StatusTypeOrAccountID > 2) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/sales/debitnote/viewdebitnote'], { queryParams: { id: btoa(event.TransID + ',' + event.TransTypeID + ',' + event.ContactID) } }));
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/sales/debitnote/editdebitnote'], { queryParams: { id: btoa(event.TransID + ',' + event.TransTypeID + ',' + event.ContactID) } }));
      }
    }
    else if (event.TransTypeName == "Bill") {
      let billtransID = event.TransID + "," + event.TransTypeID + "," + event.ContactID;
      if (event.StatusTypeOrAccountID < 3) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['purchase/bills/editbill'], { queryParams: { id: btoa(billtransID) } }));
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['purchase/bills/viewbill'], { queryParams: { id: btoa(billtransID) } }));
      }
    }
    else if (event.TransTypeName == "Receive Order") {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/purchase/listreceive'], { queryParams: { id: btoa(event.TransID), IsReceived: btoa('2'), TransType: btoa('1') } }));
    }
    else if (event.TransTypeName == "Vendor Credit Note") {
      let creditnotetransID = event.TransID + "," + event.TransTypeID;
      if (event.StatusTypeOrAccountID < 3) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['purchase/creditnotes/editcreditnote'], { queryParams: { id: btoa(creditnotetransID) } }));
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['purchase/creditnotes/creditnoteview'], { queryParams: { id: btoa(creditnotetransID) } }));
      }
    }
    else if (event.TransTypeName == "Quotation") {
      let quotetransID = event.TransID + "," + event.TransTypeID;
      if (event.StatusTypeOrAccountID < 3) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['sales/quotation/editquotation'], { queryParams: { id: btoa(quotetransID) } }));
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['sales/quotation/viewquotation'], { queryParams: { id: btoa(quotetransID) } }));
      }
    }
    else if (event.TransTypeName == "Invoice") {
      let InvoicetransID = event.TransID + "," + event.TransTypeID + "," + event.ContactID;
      if (event.StatusTypeOrAccountID < 3) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['sales/invoice/editinvoice'], { queryParams: { id: btoa(InvoicetransID) } }));
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['sales/invoice/viewinvoice'], { queryParams: { id: btoa(InvoicetransID) } }));
      }
    }
    else if (event.TransTypeName == "Sales Credit Note") {
      let creditnotetransID = event.TransID + "," + event.TransTypeID;
      if (event.StatusTypeOrAccountID < 3) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(["sales/creditnotes/editcreditnote"], { queryParams: { id: btoa(creditnotetransID) } }));
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(["sales/creditnotes/creditnoteview"], { queryParams: { id: btoa(creditnotetransID) } }));
      }
    }
    else if (event.TransTypeName == "Delivery Order") {
      if (event.StatusTypeOrAccountID === 1 || event.StatusTypeOrAccountID === 2) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/sales/delivery/editdelivery'], { queryParams: { id: btoa(event.TransID) } }));
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/sales/delivery/deliveryview'], { queryParams: { id: btoa(event.TransID) } }));
      }
    }
    else if (event.TransTypeName == "Manual Journal") {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/accounting/journalentries/viewjournalentries'], { queryParams: { id: btoa(event.TransID) } }));
    }
    else if (event.TransTypeName == "Fixed Asset") {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/accounting/fixedassets/viewasset'], { queryParams: { id: btoa(event.TransID) } }));
    }
    else if (event.TransTypeName == "Contacts") {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/contacts/overview'], { queryParams: { id: btoa(event.ContactID) } }));
    }
    else if (event.TransTypeName == "Spend Money") {
      if (event.TransTypeID == 10) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/transactions/viewtransaction'], { queryParams: { account: btoa(event.StatusTypeOrAccountID), tranid: btoa(event.TransID), trantypeid: btoa(event.TransTypeID) } }));
      }
      else if (event.TransTypeID == 23) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/transactions/viewtransaction'], { queryParams: { account: btoa(event.StatusTypeOrAccountID), tranid: btoa("0"), trantypeid: btoa("10"), batchid: btoa(event.TransID) } }));
      }
      else if (event.AccountTranTypeID == 24) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/transactions/viewtransaction'], { queryParams: { account: btoa(event.StatusTypeOrAccountID), tranid: btoa("0"), trantypeid: btoa("11"), batchid: btoa(event.TransID) } }));
      }
    }
    else if (event.TransTypeName == "Transfer Money") {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/transactions/transfermoney'], { queryParams: { account: btoa(event.StatusTypeOrAccountID), tranid: btoa(event.TransID), trantypeid: btoa(event.TransTypeID) } }));
    }
    else if (event.TransTypeName == "Receive Money") {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/transactions/viewtransaction'], { queryParams: { account: btoa(event.StatusTypeOrAccountID), tranid: btoa(event.TransID), trantypeid: btoa(event.TransTypeID) } }));
    }
    selectCtrl.clearModel();
  }
  onFocus() {
    this.lstSearchedContent = this.translate.instant("lstSearchedContent");
  }
  onClear() {
    this.lstSearchedContent = this.translate.instant("lstSearchedContent");
  }


  CheckTaxRateMYUpdate(){
    this.http.getservice(environment.organizationApiUrl + '/Organization/CheckTaxRateMYUpdate').subscribe({
      next: (data: any) => {
        if(data.responseData != "" && data.isSuccess == true){
          this.hidegstDiv = true;
          localStorage.setItem("hidegstDiv" + this.hidegstDivorgID.toString(), "true");
        }
      }
    });
  }
  UpdateMYTaxRate(){
    this.http.getservice(environment.organizationApiUrl + '/Organization/UpdateMYTaxRate').subscribe({
      next: (data: any) => {
        if(data.responseData != "" && data.isSuccess == true){
          this.hidegstDiv = true;
          localStorage.setItem("hidegstDiv" + this.hidegstDivorgID.toString(), "true");
          this.alertMessage.successNotifier("Tax codes auto-updated successfully",0);
        }
      }
    });
  }
  SubmitTicket(){
    this.isCaptureLoading =true;
    var ticketcapture = document.getElementById("ticketcapture");
    ticketcapture.style.display = "none";
    
    const fadeInElement = document.body.querySelector('.fadeIn');
    const fadeInElement1 = document.body.querySelector('.fade-in');
    if (fadeInElement) {
      this.renderer.removeClass(fadeInElement, 'fadeIn');
    }
    if (fadeInElement1) {
      this.renderer.removeClass(fadeInElement1, 'fade-in');
    }
     
      html2canvas(document.body,{scale:1,
          scrollY:0,
      }).then((canvas) => {
            const imgData = canvas.toDataURL('image.png');
            let modalref=this.modalServive.open(TicketCaptureComponent, {modalDialogClass: 'm-xxl', size: "", backdrop:'static',keyboard:false  });
            modalref.componentInstance.imgsrc=imgData;
            this.isCaptureLoading=false;
      });
      ticketcapture.style.display = "block";
  }
}
