import { Injectable, Inject } from '@angular/core';
import { HttpServices } from './http.service'
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class AccsessioncacheService {
  public cacheSize: any;
  encodedata: string
  decodedata: string

  constructor() {
    this.cacheSize = "0 KB";
  }

  setsessionStorage(key: string, data: any) {
    this.encodedata = this.encode(JSON.stringify(data));
    sessionStorage.setItem(this.encode(key), this.encodedata);
  }

  getsessionStorage(key: string) {
    this.encodedata = sessionStorage.getItem(this.encode(key));
    if (this.encodedata != null && this.encodedata != undefined) {
      this.decodedata = this.decode(this.encodedata);
      return JSON.parse(this.decodedata);
    }
    else
      return null;
  }

  removesessionStorage(key: string) {
    sessionStorage.removeItem(this.encode(key));
  }

  removesession() {
    sessionStorage.clear();
  }

  getSessionStorageSize() {
    var data = '';
    for (var key in window.sessionStorage) {
      if (window.sessionStorage.hasOwnProperty(key)) {
        data += window.sessionStorage[key];
      }
    }
    this.cacheSize = (data ? '\n' + 'sessionStorage : (' + ((data.length * 16) / (8 * 1024)).toFixed(2) + ' KB)' : 'sessionStorage (0 KB)');
    return this.cacheSize;
  }


  encode(data: any) {
    var encrypted = Base64.encode(data); //btoa(data);
    return encrypted.toString();
  }

  decode(data: any) {
    var decrypted = Base64.decode(data);  //atob(data);
    return decrypted.toString();
  }
}
