import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PageServices } from './page.service';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {
  private ApiUrl: string = environment.administrationApiUrl + "/ReleaseNotes/";

  constructor(
    private _http: HttpClient,
    private _page: PageServices
  ) {

  }

  async GetReleaseNotes<T>(PageNo: number, PageSize: number, IsActive: boolean = false) {
    var Url = this.ApiUrl + "GetReleaseNotes?" +
      "PageNo=" + PageNo + "&" +
      "PageSize=" + PageSize + "&" +
      "IsActive=" + IsActive;

    let result = await this._http.get<T>(Url).toPromise();
    return result;
  }
  async SaveReleaseNotes<T>(data) {
    var Url = this.ApiUrl + "SaveReleaseNotes";
    let result = await this._http.post<T>(Url, data).toPromise();
    return result;
  }
  async DeleteReleaseNotes<T>(data) {
    var Url = this.ApiUrl + "DeleteReleaseNotes";
    let result = await this._http.post<T>(Url, data).toPromise();
    return result;
  }
  async ViewedReleaseNotes<T>() {
    var Url = this.ApiUrl + "ViewedReleaseNotes";
    let result = await this._http.post<T>(Url, null).toPromise();
    return result;
  }
}
