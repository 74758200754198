import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { date } from '@rxweb/reactive-form-validators';

@Injectable({providedIn: 'root'})
export class HostService {
   
    constructor(@Inject(DOCUMENT) private document: any) {
    }

    getHostname(): string {
        return this.document.location.hostname;
    }

    getDigisme(): boolean {
        let isDigiSME:boolean = false;
        let hostname = this.document ? this.document.location.hostname: window.location.hostname;
        if ((hostname.indexOf('digisme') > -1) || (hostname.indexOf('digi-sme') > -1)) {
            isDigiSME = true;
        }
        else{
            isDigiSME = false;
        }
        return isDigiSME;
    }

    logout(url?: any) {        
        if (url) {
            window.location.href = "/login?returnUrl=" + url;
        }
        else {
            window.location.href = "/login";
        }
    }
}