import { Orientation, PaperSize, PrintType } from "./enumlist";

export class GroupParams {
    templategroup:string = "purchaseorder";    
}

export class TemplateParams{
    groupid:string;
    templateid:string;   
}

export class TemplateGroupParams {
    userId: number;
    orgId: number;
    groupId: number;
    templateId: number;
    isMockData: boolean;
    printType: PrintType;
    transIds: string;
    templateType: string = "";
    isNewViewScreen: boolean = false;
}

export class TemplateUploadParam {
    userId:number;
    orgId:number;
    groupId:number;
    groupName:string;
    templateId:number;
    templateName: string;
    masterCode: string;
    templatePath: string;
    templateFile:any;
    templateType: string;
    customerID:number;
    templateFilename: string;
    oldTemplatePath: string;
}

export class PrintHtml {
    htmlResult: HTMLResult;
    fileName: string;
}

export interface HTMLResult {
    htmlString: string;
    paperSize: PaperSize;
    orientation: Orientation;
}

export class PreviewWordTemplates {
    TemplateId: number;
    GroupId: number;
    IsNotes: boolean;
    CountryID: number;
}