<form [formGroup]="addCurrencyForm">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Add Currency</h4>
            <button type="button" class="close" (click)="closeDialog('close');">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Select a
                            currency</label>
                        <div class="col-md-8">
                            <select formControlName="currencyTypeID" class="form-select form-select-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.currencyTypeID.errors }"
                                (change)="getLiveExchangeRate();">
                                <option [ngValue]="0">Select</option>
                                <option *ngFor="let c of lstCurrencyType" [ngValue]="c.CurrencyTypeID">
                                    {{c.CurrencyCode}} - {{c.CurrencyName}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.currencyTypeID.errors" class="invalid-feedback">
                                <div *ngIf="f.currencyTypeID.errors.required">Currency is
                                    required</div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-table-1">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Automatic Foreign Exchange Rates provided by fixer.io</th>
                                    <th>Latest {{orgCurrencyCode}}  Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Exchange rates are updated each hour with the most up to date mid-market rate from fixer.io
                                    </td>
                                    <td>{{exchangeRate}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button [disabled]="loading" class="btn btn-primary btn-normal mr-3" type="button"  (click)="onSubmit()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Add Currency
            </button>
            <button class="btn btn-secondary" type="button" (click)="closeDialog('close');">Cancel</button>
        </div>
    </div>
</form>