<ng-container #action *ngIf="column.type == 'action'">
    <ng-container *ngIf="column.field == 'drag'; then drag else delete"></ng-container>
</ng-container>
<ng-template #drag>
    <span [ngStyle]="column.css ? column.css:''" cdkDrag class="draggable"
        *ngIf="column.isCustom == false && column.field == 'drag' && column.isVisible == true">
        <i class="fa fa-bars text-muted" aria-hidden="true"></i>
    </span>
</ng-template>
<ng-template #delete>
    <span [ngStyle]="column.css ? column.css:''" *ngIf="column.field == 'delete'">
        <i *ngIf="rowData.length > 1" class="fa fa-trash" aria-hidden="true"></i>
    </span>
</ng-template>

<ng-container #text *ngIf="column.type == 'text'">
    <container-element [ngSwitch]="column.field">
        <some-element *ngSwitchCase="'ItemDescription'" [ngStyle]="rowType == 2 && {'padding-left': '15px'}">
            <span [ngStyle]="column.css ? column.css:''">
                {{row[column.field]}}
            </span>
        </some-element>
        <some-element *ngSwitchDefault>
            <span [ngStyle]="column.css ? column.css:''">
                {{row[column.field]}}
            </span>
        </some-element>
    </container-element>
</ng-container>

<ng-container #numeric *ngIf="column.type == 'numeric'">
    <container-element [ngSwitch]="column.field">
        <some-element *ngSwitchCase="'Quantity'">
            <span [ngStyle]="column.css ? column.css:''">
                {{ row[column.field] < 0 ? "(" : "" }}{{row[column.field] * (row[column.field]<0 ? -1 : 1) | number
                    : '1.2-4' }}{{row[column.field]<0 ? ")" : "" }} </span>
        </some-element>
        <some-element *ngSwitchCase="'UnitPrice'">
            <span [ngStyle]="column.css ? column.css:''">
                {{ row[column.field] < 0 ? "(" : "" }}{{row[column.field] * (row[column.field]<0 ? -1 : 1) | number
                    : '1.2-4' }}{{row[column.field]<0 ? ")" : "" }} </span>
        </some-element>
        <some-element *ngSwitchCase="'TaxAmount'">
            <span *ngIf="rowType == 1" [ngStyle]="column.css ? column.css:''">
                {{ row[column.field] < 0 ? "(" : "" }}{{row[column.field] * (row[column.field]<0 ? -1 : 1) | number
                    : '1.2-4' }}{{row[column.field]<0 ? ")" : "" }} </span>
        </some-element>
        <some-element *ngSwitchCase="'Amount'">
            <span *ngIf="rowType == 1" [ngStyle]="column.css ? column.css:''">
                {{ row[column.field] < 0 ? "(" : "" }}{{row[column.field] * (row[column.field]<0 ? -1 : 1) | number
                    : '1.2-4' }}{{row[column.field]<0 ? ")" : "" }} </span>
        </some-element>
        <some-element *ngSwitchDefault>
            <span [ngStyle]="column.css ? column.css:''">
                {{row[column.field]}}
            </span>
        </some-element>
    </container-element>
</ng-container>