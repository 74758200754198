export class Pagination {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

export class PageDetails {
  class: string = "";
  displayOrder: number = 0;
  icon: string = "";
  isApprovePage: boolean = false;
  isTransactionPage: boolean = false;
  name: string = "";
  pageID: number = 0;
  parentPageID: number = 0;
  title: boolean = false;
  url: string = "";
  menuType: string = "";
  dependantPageID: number = 0;
  isShortcut:boolean = false;
  isAllowFavorite:boolean = false;
}

export class AccessRights {
  canApprove: boolean = false;
  canDelete: boolean = false;
  canEdit: boolean = false;
  canView: boolean = false;
  canPayment:boolean=false;
  pageID: number = 0;
}
