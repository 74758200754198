import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../../utilities/_services/loader.service';
import { Environment } from '@ag-grid-community/all-modules';

@Injectable({
  providedIn: 'root'
})
export class HttpServices {
  //webapiUrl: string = "";
  copyToFuncActivate = false;
  copyToFuncSourceTypeId = '';
  copyToFuncSourceTransId = '';
  copyToFuncDestinationTypeId = '';

  constructor(private _http: HttpClient, public loader: LoaderService) {

  }

  getservice(myAppUrl: string) {
    this.loader.isloading = true;
    return this._http.get(myAppUrl).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  async awaitGetservice(myAppUrl: string) {
    this.loader.isloading = true;
    var res = await this._http.get(myAppUrl).toPromise();
    this.loader.isloading = false;
    return res;
  }

  postservice(myAppUrl: string, record: any) {
    this.loader.isloading = true;
    return this._http.post(myAppUrl, record).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  async awaitPostservice(myAppUrl: string, record: any) {
    this.loader.isloading = true;
    var res = await this._http.post(myAppUrl, record).toPromise();
    this.loader.isloading = false;
    return res;
  }

  postserviceSearchText(myAppUrl: string, SearchText: string) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.append('SearchText', SearchText);
    return this._http.post(myAppUrl, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }


  postserviceNewPurchageOrder(myAppUrl: string, oldPo: string, newPo: string, id: any) {
    this.loader.isloading = true;
    let params = {
      oldPO: oldPo,
      newPO: newPo,
      pageID: id
    };
    return this._http.post(myAppUrl, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }


  postserviceNewDO(myAppUrl: any, oldDo: any, newDo: any) {
    this.loader.isloading = true;
    let payload = { OldDo: oldDo, NewDo: newDo}   
    return this._http.post(myAppUrl, payload).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  postserviceNewContact(myAppUrl: string, oldcontact: string, newContact: string) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.append('oldContact', oldcontact);
    params = params.append('newContact', newContact);
    return this._http.post(myAppUrl, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  postserviceDeliveryAddress(myAppUrl: string, oldDeliverAdd: string, newDeliverAdd: string) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.append('oldDelivery', oldDeliverAdd);
    params = params.append('newDelivery', newDeliverAdd);
    return this._http.post(myAppUrl, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  postserviceTerms(myAppUrl: string, oldterms: string, newterms: string) {
    this.loader.isloading = true;
    let params = {
      oldTermsCondition: oldterms,
      newTermsCondition: newterms
    }
    return this._http.post(myAppUrl, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }


  postserviceBill(myAppUrl: any, oldBill: any, newBill: string, id: any) {
    this.loader.isloading = true;
    let payload = { oldBill: oldBill, newBill: newBill, pageID:id }     
    return this._http.post(myAppUrl, payload).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  postserviceCreditnotes(myAppUrl: any, oldCN: any, newCN: string, id: any) {
    this.loader.isloading = true;
    let payload = { oldCN: oldCN, newCN: newCN, pageID:id }     
    return this._http.post(myAppUrl, payload).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  postserviceCreditnotesBill(myAppUrl: any, oldCN: any, newCN: string, newSP: string, id: any) {
    this.loader.isloading = true;
    let payload = { oldCN: oldCN, newCN: newCN, SpendMoneyBill: newSP, pageID:id }     
    return this._http.post(myAppUrl, payload).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }
  postserviceSalesCreditnotes(myAppUrl: any, oldSCN: any, newSCN: string, id: any, newRM?: string) {
    this.loader.isloading = true;
    var payload:any;
    if (!!newRM) {
      payload = { oldSCN: oldSCN, newSCN: newSCN, PageID:id, receiveMoney: newRM} 
    }
    else
    {
      payload = { oldSCN: oldSCN, newSCN: newSCN, PageID: id} 
    }

    return this._http.post(myAppUrl, payload).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  putservice(myAppUrl: string, record: any) {
    this.loader.isloading = true;
    return this._http.put(myAppUrl, record).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  deleteservice(myAppUrl: string) {
    this.loader.isloading = true;
    return this._http.delete(myAppUrl).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  deleteservicePut(myAppUrl: string, record: any) {
    this.loader.isloading = true;
    return this._http.put(myAppUrl, record).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }



  async getserviceawait(myAppUrl: string) {
    this.loader.isloading = true;
    const data = await this._http.get(myAppUrl).toPromise()
    this.loader.isloading = false;
    return data;
  }

  postsvc(myAppUrl: string, record: any) {
    this.loader.isloading = true;
    const data = this._http.post(myAppUrl, record)
    this.loader.isloading = false;
    return data;
  }

  SaveSpendMoney(newSpendMoney: any, oldSpendMoney: any, PageID: any, EInvoiceID? : number) {
    EInvoiceID = EInvoiceID ? EInvoiceID : 0;
    let bankParams = {
      OldIO: JSON.stringify(oldSpendMoney),
      NewIO: JSON.stringify(newSpendMoney),
      PageID: PageID.toString(),
      EInvoiceID: EInvoiceID.toString()
    };
    return this._http.post(environment.reconcilationApiUrl + '/reconcile/SpendMoney/SaveSpendMoneyTrans/', bankParams).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  async SaveBulkPayments(oldSpendMoney: any, newSpendMoney: any, newBatchPaymentDetails: any, PageID: any, EInvoiceID? : number) {
    EInvoiceID = EInvoiceID ? EInvoiceID : 0;
    let bankParams = {
      OldIO: JSON.stringify(oldSpendMoney),
      NewIO: JSON.stringify(newSpendMoney),
      BatchIO: JSON.stringify(newBatchPaymentDetails),
      PageID: PageID.toString(),
      EInvoiceID: EInvoiceID.toString()
    };
    let response = await this._http.post(environment.reconcilationApiUrl + '/reconcile/SpendMoney/BatchPayments', bankParams).toPromise();
    return response;
  }

  // ESaveSpendMoney(newSpendMoney: any) {
  //   let params = new HttpParams();
  //   params = params.set('newSpendMoney', JSON.stringify(newSpendMoney));
  //   ;
  //   return this._http.post(environment.reconcilationApiUrl + '/reconcile/SpendMoney/SaveSpendMoney/', params).pipe(map(data => {
  //     this.loader.isloading = false;
  //     return data;
  //   }));

  // }


  SaveReceiveMoney(newReceiveMoney: any, oldReceiveMoney: any, PageID: any) {
    let bankParams = {
      OldIO: JSON.stringify(oldReceiveMoney),
      NewIO: JSON.stringify(newReceiveMoney),
      PageID: PageID.toString()
    };
    return this._http.post(environment.reconcilationApiUrl + '/reconcile/ReceiveMoney/SaveReceiveMoneyTrans/', bankParams).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));

  }

  RefundPCN(allocatecredit: any, refundPayment: any, PageID: any) {
    let params = new HttpParams();
    // params = params.set('PageID', PageID.toString());
    params = params.set('allocatecredit', JSON.stringify(allocatecredit));
    params = params.set('refundPayment', JSON.stringify(refundPayment));

    return this._http.post(environment.purchaseApiUrl + '/Creditnotes/RefundCreditNote/', params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));

  }

  SavePOStatus(url, billTransacttion: any, PageID: any, PoStatusID: any) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.set('PageId', PageID.toString());
    params = params.set('PoStatusID', PoStatusID.toString());
    params = params.set('POTransactions', JSON.stringify(billTransacttion));
    return this._http.post(url, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));

  }

  SaveDOStatus(url, packTransaction: any, PageID: any, DoStatusID: any) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.set('PageId', PageID.toString());
    params = params.set('DoStatusID', DoStatusID.toString());
    params = params.set('DOTransactions', JSON.stringify(packTransaction));
    return this._http.post(url, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));

  }

  SavePOCopyToDraft(url, POTransID: any, PageID: any, POCopy: string, MerCopy?: string, IsQuoteTracked?: any) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.set('POTransIDs', POTransID);
    params = params.set('PageId', PageID);
    params = params.set('PoCopy', POCopy);
    params = params.set('MergeCopy', MerCopy);
    params = params.set('IsQuoteTracked', IsQuoteTracked);
    return this._http.post(url, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));

  }

  DeleteAttachmentFile(url, FileID: any, TransTypeID: any, PageID: any, transID: any, fileName: any, FilePath: any) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.set('FileID', FileID);
    params = params.set('TransTypeID', TransTypeID);
    params = params.set('FileName', fileName);
    params = params.set('TransactionID', transID);
    params = params.set('PageId', PageID);
    params = params.set('FilePath', FilePath);
    return this._http.post(url, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));

  }

  DeleteGroupName(url, FileGroupID: any, PageID: any) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.set('FileGroupID', FileGroupID);
    params = params.set('PageId', PageID);
    return this._http.post(url, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));

  }

  DownloadAttachmentFile(url, FileName: any, FilePath: any) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.set('FileName', FileName);
    params = params.set('FilePath', FilePath);
    return this._http.post(url, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));

  }

  async CheckBillReferenceDuplicated(myAppUrl: any, record: any) {
    this.loader.isloading = true;
    var data = await this._http.post(myAppUrl, record).toPromise();
    this.loader.isloading = false;
    return data;

    // .pipe(map(data => {
    //   this.loader.isloading = false;
    //   return data;
    // }));
  }

  checkInvoiceReferenceDuplicated(myAppUrl: any, newInvoice: string) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.set('newInvoice', newInvoice);
    return this._http.post(myAppUrl, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  postserviceNewQuotationOrder(myAppUrl: any, oldQo: any, newQo: any, id: any) {
    this.loader.isloading = true;
    let payload = { oldQo: oldQo, newQo: newQo, PageID:id }
    return this._http.post(myAppUrl, payload).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }


  postserviceNewInvoiceOrder(myAppUrl: string, oldInv: string, newInv: string, id: any,PageID: any) {
    this.loader.isloading = true;
    let payload ={
      OldIO: oldInv,
      NewIO: newInv,
      PageID: PageID
    }
    return this._http.post(myAppUrl, payload).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  postserviceRecurringSetting(myAppUrl: string, oldInv: string, newInv: string) {
    this.loader.isloading = true;
    let params = new HttpParams();
    params = params.append('OldIO', oldInv);
    params = params.append('newIO', newInv);
    return this._http.post(myAppUrl, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }



  postserviceNewReceiveOrder(myAppUrl: string, oldRec: string, newRec: string, recstatus: any, pageID: any) {
    this.loader.isloading = true;
    let params = {
      oldReceive: oldRec,
      newReceive: newRec,
      receivestatus: recstatus,
      PageID: pageID
    }
    return this._http.post(myAppUrl, params).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

  postserviceBlob(myAppUrl: string, record: any) {
    this.loader.isloading = true;
    return this._http.post(myAppUrl, record, { responseType: 'blob' }).pipe(map(data => {
      this.loader.isloading = false;
      return data;
    }));
  }

}
