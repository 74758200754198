import { AllCommunityModules, GridOptions, Module } from '@ag-grid-community/all-modules';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ContentChange, QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CustomTxtValidator, ValidEmail, ValidMultipleEmailWithSemicolonComma } from '../../../utilities/_directives/customvalidator.directive';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { HttpServices } from '../../../utilities/_services/http.service';
import { MasterServices } from '../../../utilities/_services/master.service';
import { MultiEmailCellEditorComponent } from './multi-email-cell-editor/multi-email-cell-editor.component';
import { MultiEmailCellRenderComponent } from './multi-email-cell-render/multi-email-cell-render.component';
import { MultiMarkassentCellRenderComponent } from './multi-markassent-cell-render/multi-markassent-cell-render.component';
import { Router } from '@angular/router';
import { EmailTemplateTypes } from '../sendemailtemplate/model/EmailTemplateTypes';
import { PrintService } from '../../template/services/print.service';
import { TranslateService } from '@ngx-translate/core';
export interface PHDialogData {
  text: string;
  headerName: string;
  phtypeId: any;
}

const parchment = Quill.import('parchment')
const block = parchment.query('block')
block.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true)

@Component({
  selector: "sendmultipleemailtemplate",
  templateUrl: "./sendmultipleemailtemplate.component.html",
  styleUrls: ["./sendmultipleemailtemplate.component.scss"],
})
export class sendmultipleemailtemplateComponent implements OnInit {
  submitted = false;
  emailtemplateForm: FormGroup | any;
  text: string;
  phdetails: any = [];

  lstEmailTemplate: any;
  defaultselectedOption: any;
  loading = false;
  SucessBody: any;
  transID: string = "";
  SelectedData: any;

  rowData: any = [];
  modules: Module[] = AllCommunityModules;
  public gridOptions: GridOptions;
  rowSelection: any;
  private gridApi;
  private gridColumnApi;

  private customerCode: number;
  private userID: number;
  private orgID: number;

  @Input() columnDefs: any = [];
  @Input() sendEmailmodal: string = "hide";
  @Input() sendTitle: string = "";
  @Input() transList: any;
  @Input() typeID: any;
  @Input() isMarkasSent: boolean = true;
  @Input() fromDate: string;
  @Input() toDate: string;
  @Input() dateRange: string;
  @Input() statementBalance: string;
  @Input() statementType: string;
  @Input() templateID: string;
  @Input() isIncludeAttachment:boolean = false;
  @Input() IsNewViewScreen:boolean = false;
  @Input() ageType :string;
  @Input() interval:number;
  @Input() duration:number;

  @Output() hidepoSendEmailmodal = new EventEmitter();
  @Output() onGridRefresh = new EventEmitter();

  @ViewChild(QuillEditorComponent, { static: true }) body: QuillEditorComponent;
  @ViewChild("showmodal") public showemailmodal: ModalDirective;

  frameworkComponents = {
    MultiEmailCellRenderComponent: MultiEmailCellRenderComponent,
    MultiEmailCellEditorComponent: MultiEmailCellEditorComponent,
    MultiMarkassentCellRenderComponent: MultiMarkassentCellRenderComponent,
  };

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpServices,
    private el: ElementRef,
    private master: MasterServices,
    private alertMessage: AlertMessage,
    @Inject(MAT_DIALOG_DATA) public data: PHDialogData,
    private local: LocalCacheServices,
    private router: Router,
    private printService:PrintService,
    private translate: TranslateService
  ) {
    this.phdetails = [];
    this.rowSelection = "multiple";
    this.translate.onDefaultLangChange.subscribe(async() => {
      this.gridOptions.api.refreshHeader();
    });
    this.translate.onLangChange.subscribe(async() => {
      this.gridOptions.api.refreshHeader();
    });
  }

  onShown() {
    this.cellFocus();
  }

  ngOnInit(): void {
    this.userID = JSON.parse(
      this.local.getlocalStorage(this.local.localCurrentUserKey)
    )["userID"];

    this.orgID = JSON.parse(
      this.local.getlocalStorage(this.local.localCurrentUserKey)
    )["orgID"];

    this.customerCode = JSON.parse(
      this.local.getlocalStorage(this.local.localCurrentUserKey)
    )["organizations"].filter(
      (currentorg) => currentorg.orgID === this.orgID
    )[0]["customerCode"];

    this.emailFormValues();
    this.body.onContentChanged
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((data: ContentChange) => {
        this.text = data.html;
      });

    this.gridOptions = <GridOptions>{
      onGridReady: (params: any) => {
        this.gridApi = params.api;
        this.gridApi.selectAll();
      },
      stopEditingWhenCellsLoseFocus: true,
    };
  }

  cellFocus() {
    let rowDataLength = 0;
    for (var el of this.gridOptions.rowData) {
      if (ValidateMultipleEmail(el.Email != undefined ? el.Email : el.email)) {
        this.gridApi.setFocusedCell(rowDataLength, el.Email != undefined ? "Email" : "email");
        break;
      }
      rowDataLength++;
    }
  }

  ngOnChanges() {
    if (this.sendEmailmodal == "show") {
      if (this.sendTitle === "Invoice Mail") {
        this.rowData = this.transList.filter(x => x.ReceiveTypeID === 0);
      } else {
        this.rowData = this.transList;
      }
      this.gridApi?.selectAll();
      this.showemailmodal.show();
      this.loadTemplateName();
    }
  }

  onCellValueChanged(params: any) {
    const colId = params.column.getId();
  }

  get f() {
    return this.emailtemplateForm.controls;
  }
  emailFormValues() {
    this.emailtemplateForm = this.formBuilder.group({
      emailTempID: [0],
      from: [""],
      replyTo: [""],
      orgID: [0],
      to: [
        "",
        [CustomTxtValidator.required, ValidMultipleEmailWithSemicolonComma()],
      ],
      subject: ["", CustomTxtValidator.required],
      body: [this.text],
      markasSent: [false],
      Sendmecopy: [false],
      includeAttachment:[false],
      IsNewViewScreen: [false],
      agetType:[""],
      interval:[0],
      duration:[0]
    });
  }

  async loadPlaceholder(mapPlaceHolderTypeID) {
    this.phdetails = await this.master.GetPlaceholder(mapPlaceHolderTypeID);
  }

  loadTemplate(emailTempID) {

    let emailtemplate = this.lstEmailTemplate.filter(
      (x) => x.emailTempID == emailTempID
    )[0];
    this.emailtemplateForm.patchValue(emailtemplate);
  }

  loadTemplateName() {
    this.http
      .getservice(
        environment.templateApiUrl +
        "/EmailSetting/GetEmailTemplates?TypeID=" +
        this.typeID
      )
      .subscribe({
        next: (data: any) => {
          this.lstEmailTemplate = data;
          this.loadPlaceholder(data[0].mapPlaceHolderTypeID);
          let emailtemplate = this.lstEmailTemplate.filter(
            (x) => x.isDefault == true
          )[0];
          let currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
          if(this.typeID == EmailTemplateTypes.MyInvoisEmail){
            emailtemplate.subject = emailtemplate.subject.toString().replaceAll('%OrganizationName%', currentUser.organizationName);
            emailtemplate.body = emailtemplate.body.toString().replaceAll('%OrganizationName%', currentUser.organizationName);
          }
          this.emailtemplateForm.patchValue(emailtemplate);

        },
      });
  }

  onRowSelected(params: any) {
    this.SelectedData = params.api.getSelectedRows();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  onSubmit() {
    let serviceParams = [];
    let isValid: boolean = true;
    let rowDataLength: number = 0;
    this.transID = "";
    let errorSummary: any = [];

    if (this.SelectedData.length == 0 || this.SelectedData == undefined) {
      let msg = this.translate.instant("PurchaseModule.PurchaseOrder.Please select at least one record");
      this.alertMessage.errorNotifier(msg, 0);
      return;
    }

    this.SelectedData.forEach((data) => {
      if (!this.isFromCustomerStatment(data.Email != undefined ? data.Email : data.email)) {
        if (!this.isNullOrEmptyOrUndefined(data.Email) || !this.isNullOrEmptyOrUndefined(data.email)) {
          if (ValidateMultipleEmail(data.Email != undefined ? data.Email : data.email)) {
            isValid = false;
            let msg = this.translate.instant("PurchaseModule.PurchaseOrder.Please enter valid email ID for selected record(s)");
            if (errorSummary.filter(x => x.message == msg).length === 0) {
              errorSummary.push({ "message": msg });
            }
          } else {
            isValid = true;
          }
        } else {
          isValid = false;
          let msg = this.translate.instant("PurchaseModule.PurchaseOrder.Email ID is required for selected record(s)");
          if (errorSummary.filter(x => x.message == msg).length === 0) {
            errorSummary.push({ "message": msg });
          }
        }
      } else {
        isValid = false;
        let msg = this.translate.instant("PurchaseModule.PurchaseOrder.Email ID is required for selected record(s)");
        if (errorSummary.filter(x => x.message == msg).length === 0) {
          errorSummary.push({ "message": msg });
        }
      }
      if (isValid) {
        var serviceParam = {
          transID: parseInt(this.getCorrespondingValue(data, this.sendTitle, "transID")),
          fromAddress: "",
          toAddress: data.Email != undefined ? data.Email : data.email,
          StmtBal: data.stmtBalance != '' || data.stmtBalance != undefined ? data.stmtBalance : "",
          replyTo: this.emailtemplateForm.value.replyTo,
          sendmeCopy: this.emailtemplateForm.value.Sendmecopy,
          markasSent: Boolean(this.getCorrespondingValue(data, this.sendTitle, "markasSent")),
          subject: this.emailtemplateForm.value.subject.replace("#", "@@").replace("#", "@@"),
          orgID: this.orgID,
          fromDate: this.getCorrespondingValue(data, this.sendTitle, "fromDate"),
          toDate: this.getCorrespondingValue(data, this.sendTitle, "toDate"),
          expiryDate: data.ExpiryDate,
          sourceType: data.SourceType != undefined ? data.SourceType : data.sourceType,
          templateType: (data.TemplateType) ? data.TemplateType : data.templateType,
          contactID: parseInt(this.getCorrespondingValue(data, this.sendTitle, "ContactID")), 
          // contactID: Object.keys(data).filter(ele => ele == 'ContactID').length > 0 ? data.ContactID : 0,
          transactionTypeID: parseInt(this.getCorrespondingValue(data, this.sendTitle, "TransTypeID")), 
          attachments :data.Files,
          ageType:this.ageType,
          interval:this.interval,
          duration:this.duration
        };
        serviceParams.push(serviceParam);
      }
      rowDataLength++;
    });
    console.log(serviceParams);

    if (serviceParams.length === this.SelectedData.length && isValid) {
      isValid = true;
    } else {
      isValid = false;
    }

    if (isValid) {
      this.submitted = true;
      var emailTemplates = [];
      let backgroundTask: any;
      this.submitted = false;
      this.loading = true;
      if (this.emailtemplateForm.value.markasSent == null)
        this.emailtemplateForm.value.markasSent = false; 
      if (this.emailtemplateForm.value.Sendmecopy == null)
        this.emailtemplateForm.value.Sendmecopy = false;

      this.emailtemplateForm.value.IsNewViewScreen = this.IsNewViewScreen;
      backgroundTask = this.emailtemplateForm.value;
      let sendemailURL: any;
      let Param = JSON.stringify(serviceParams);
      let taskType: string = "";
      if (this.sendTitle == "Send Purchase Order") taskType = "PO Mail";
      else if (this.sendTitle == "Send Quotation") taskType = "Quote Mail";
      else if (this.typeID == EmailTemplateTypes.InvoiceEmail) taskType = "Invoice Mail";
      else if (this.typeID == EmailTemplateTypes.CreditNoteEmail) taskType = "Sales Credit Mail";
      else if (this.sendTitle == "Statement Mail") taskType = "Statement Mail";
      else if (this.sendTitle == "Send Sales Order") taskType = "Sales Order Mail";
      else if (this.sendTitle == "Send Debit Note") taskType = "Debit Note Mail";      
      else if (this.sendTitle == "Supplier Statement Mail") taskType = "Supplier Statement Mail";
      else if (this.sendTitle == "Send Request Data(MyInvois)") taskType = "Send Request Data(MyInvois)";
      if (this.typeID == EmailTemplateTypes.CustomerStatement && taskType == "Statement Mail") {
        backgroundTask.subject = backgroundTask.subject.toString().replaceAll('%Statement Date Range%', this.dateRange);
        this.text=this.text.replaceAll('%Supplier Statement Date Range%','');
        this.text=this.text.replaceAll('%Supplier Statement Balance%','');
 
      }
      else if (taskType == "Supplier Statement Mail") {
        this.text=this.text.replaceAll('%Statement Date Range%','');
        this.text=this.text.replaceAll('%Statement Balance%','')
      }
      backgroundTask.backgroundServiceParams = Param;
      backgroundTask.customerCode = this.customerCode;
      backgroundTask.taskType = taskType;
      // backgroundTask.body = this.printService.returnEncodedString(backgroundTask.body);//Added for encoding.
      backgroundTask.body = this.printService.returnEncodedString(this.text);//Added for TFS BUG : #13199.
      backgroundTask.agetType=this.ageType;
      backgroundTask.interval=this.interval;
      backgroundTask.duration=this.duration;
      //console.log(backgroundTask.body);
      if (this.SelectedData.length > 9) {
        sendemailURL = environment.organizationApiUrl + "/BackgroundTask/CreateBackgroundTask";
        this.http.postservice(sendemailURL, backgroundTask)
          .subscribe({
            next: (data: any) => {
              if (data) {
                let msg = this.translate.instant("PurchaseModule.PurchaseOrder.Task has been created, please click on notification icon to check this task status");
                this.alertMessage.successNotifier(msg, 0);
                this.reset();
                this.CloseModel();
              } else {
                let msg = this.translate.instant("PurchaseModule.PurchaseOrder.Creation of task has been failed, please contact administrator");
                this.alertMessage.errorNotifier(msg, 0);
                this.reset();
                this.CloseModel();
              }
              this.loading = false;
            },
          });
      }
      else {
        console.log(this.emailtemplateForm);
        backgroundTask["templateID"]=this.templateID;
        backgroundTask["statementType"]=this.statementType;
        backgroundTask["ageType"]=this.ageType;
        backgroundTask["interval"]=this.interval;
        backgroundTask["duration"]=this.duration;
        // backgroundTask.emailTempID =
        sendemailURL = environment.templateApiUrl + "/EmailTemplate/SendMultiEmailTemplates";
        this.http.postservice(sendemailURL, backgroundTask)
          .subscribe({
            next: (data: any) => {
              if (data) {
                // this.alertMessage.successNotifier("Mail Sent Successfully", 0);
                this.alertMessage.successNotifier(data.message, 0);
                this.reset();
                this.CloseModel();
              } else {
                let msg = this.translate.instant("PurchaseModule.PurchaseOrder.Error while sent email");
                this.alertMessage.successNotifier(msg, 0);
                this.reset();
                this.CloseModel();
              }
              this.loading = false;
            },
          }
          );
      }
    } else {
      this.alertMessage.errorSummaryNotifierWithAutoClose(errorSummary, 0);
    }
  }

  CloseModel() {
    this.submitted = false;
    this.reset();
    this.sendEmailmodal = "hide";
    this.showemailmodal.hide();
    this.hidepoSendEmailmodal.emit(this.sendEmailmodal);
    this.onGridRefresh.emit('refresh');
  }

  reset() {
    this.emailtemplateForm.reset();
  }

  isFromCustomerStatment(Param: any): boolean {
    if (this.typeID === EmailTemplateTypes.CustomerStatement && Param === "Add email") {
      return true;
    } else {
      return false;
    }
  }

  changeCustom(event, codevalue) {
    const editor = this.body.quillEditor;
    if (codevalue != "") {
      let PlaceholderValue: string = codevalue;
      const cursorPosition = editor.getSelection(true).index;
      editor.insertText(cursorPosition, PlaceholderValue);
      editor.setSelection(cursorPosition, PlaceholderValue.length);
    }
  }
  getCorrespondingValue(data: any, pageTitle: string, columnName: string): string {
    let returnValue: string;
    if (columnName == "transID") {
      if (pageTitle == "Send Purchase Order") returnValue = data.POTransID;
      else if (pageTitle == "Send Quotation") returnValue = data.QuoteTransID;
      else if (pageTitle == "Invoice Mail") returnValue = data.TransID;
      else if (pageTitle == "Sales Credit Mail") returnValue = data.scnid;
      else if (pageTitle == "Statement Mail") returnValue = data.contactID;
      else if (pageTitle == "Send Sales Order") returnValue = data.SalesOrderID;
      else if (pageTitle == "Send Debit Note") returnValue = data.DebitNoteID;
      else if (pageTitle == "Supplier Statement Mail") returnValue = data.contactID;
      else if (pageTitle == "Send Request Data(MyInvois)") returnValue = data.contactID;
    } else if (columnName == "markasSent") {
      if (pageTitle == "Send Purchase Order") returnValue = data.IsPOSent;
      else if (pageTitle == "Send Quotation") returnValue = data.IsSent;
      else if (pageTitle == "Invoice Mail") returnValue = data.IsInvoiceSent;
      else if (pageTitle == "Sales Credit Mail") returnValue = data.isSent;
      else if (pageTitle == "Statement Mail") returnValue = null;
      else if (pageTitle == "Send Sales Order") returnValue = data.IsSent;
      else if (pageTitle == "Send Debit Note") returnValue = data.IsSent;
      else if (pageTitle == "Supplier Statement Mail") returnValue = null;
      else if (pageTitle == "Send Request Data(MyInvois)") returnValue = null;
    } else if (columnName == "fromDate") {
      if (this.typeID == EmailTemplateTypes.CustomerStatement) returnValue = this.fromDate;
      else returnValue = null;
    } else if (columnName == "toDate") {
      if (this.typeID == EmailTemplateTypes.CustomerStatement) returnValue = this.toDate;
      else returnValue = null;
    } else if (columnName == 'TransTypeID') {
      if (pageTitle == "Sales Credit Mail")
        returnValue = '8';
      else if (pageTitle == "Invoice Mail")
        returnValue = data.TransTypeID;
      else if (pageTitle == "Send Purchase Order")
        returnValue = '1';
      else if (pageTitle == "Send Quotation")
        returnValue = '5';
      else if (pageTitle == "Send Sales Order")
        returnValue = '29';
      else if (pageTitle == "Statement Mail")
        returnValue = '10';
      else if (pageTitle == "Supplier Statement Mail")
        returnValue = '10';
      else if (pageTitle == "Send Debit Note")
        returnValue = '30';
      else if (pageTitle == "Send Request Data(MyInvois)")
        returnValue = '18';
    } else if (columnName == "ContactID") {
      if (pageTitle == "Sales Credit Mail")
        returnValue = data.salesContactID
      else if (pageTitle == "Invoice Mail")
        returnValue = data.ContactID;
      else if (pageTitle == "Send Purchase Order")
        returnValue = data.ContactID;
      else if(pageTitle=="Send Quotation")  
        returnValue=data.ContactID;
      else if(pageTitle=="Send Sales Order")  
        returnValue=data.ContactID;
      else if(pageTitle=="Send Debit Note")
        returnValue=data.ContactID;
    }
    return returnValue;
  }

  isNullOrEmptyOrUndefined(Param: any): boolean {
    if (Param != undefined && Param != null && !(Param === "")) {
      return false;
    } else {
      return true;
    }
  }
}

export function ValidateMultipleEmail(value): boolean {
  if (value != undefined) {
    const valmail = value.replaceAll(";", ",");
    let emails = valmail.split(",").map(e => e.trim());
    emails = emails.filter(res => res != '');
    const forbidden = emails.filter(email => !(new RegExp(ValidEmail.pattern).test(email)));
    return forbidden.length > 0;
  }
}
