<app-header [navbarBrandRouterLink]="['/login']" [fixed]="true" 
[navbarBrandFull]="{src: 'assets/brand/Info-Tech-Logo_sm.png', width: 80, height: 31, alt: 'Info-tech Logo'}"
  [navbarBrandMinimized]="{src: 'assets/brand/Info-Tech-Logo_xsm.png', width: 30, height: 10, alt: 'Info-tech Logo'}"
  [sidebarToggler]="false" [asideMenuToggler]="false" [mobileAsideMenuToggler]="false"></app-header>

  <div class="container h-100" style="margin-top:70px;">    
    <ng-container *ngIf="!isSuccess">
        <div class="row" style="margin:10px 0px 5px 0px">
            <div *ngIf="this.pageOption==1 && trialdays!=0" class="col-md-6 offset-sm-3" style="text-align:center;">
                <h1 style="font-weight: 900;">Start your {{trialdays}}-day free trial</h1>
            </div>
            <div *ngIf="this.pageOption==2" class="col-md-6 offset-sm-3" style="text-align:center;">
                <h3 style="font-weight: 900;">Fill up the below details to proceed payment</h3>
            </div>
        </div>
        <div class="col-12 text-center mb-4">
            <img class="tick-36 " src="assets/img/bullet-tick.png" *ngIf="pageOption == 1"><span *ngIf="pageOption == 1" class="h6 pl-1 pr-4"> No Credit Card
                Required</span>
            <img class="tick-36" src="assets/img/bullet-tick.png"><span class="h6 pl-1  pr-4"> Online &amp;
                Phone Support</span>
            <img class="tick-36" src="assets/img/bullet-tick.png"><span class="h6 pl-1"> Free
                Implementation &amp; Training provided</span>                
        </div>
        <div class="col-md-6 mb-4 offset-sm-3">
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label>Company Name<span class="text-danger">*</span></label>
                    <input type="text" formControlName="companyName" xssDirective class="form-control" [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" />
                    <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
                        <div *ngIf="f.companyName.errors.required">Company Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>First Name<span class="text-danger">*</span></label>
                    <input type="text" formControlName="firstName" xssDirective class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Last Name<span class="text-danger">*</span></label>
                    <input type="text" formControlName="lastName" xssDirective class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Email<span class="text-danger">*</span></label>
                    <input type="text" formControlName="email" xssDirective class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                </div>
                <div class="form-group">                    
                    <label>Phone<span class="text-danger">*</span></label>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <select style="border: 1px solid #ababab !important; text-align: left;" formControlName="dialcountry" class="form-control input-group-text border-right-radius-0" (change)="ChangeDialing($event);">                                        
                                        <option *ngFor="let c of lstDailing" [value]="c.CountryDialID">{{c.CountryISOCode}} (+{{ c.DailingCode }})</option>
                                    </select>
                                </div>
                                <input type="text" formControlName="phone" phonenumber maxlength="15" class="form-control border-left-radius-0"                            
                                (keyup)="onKey($event)"
                                [ngClass]="{'border-right-radius-0':f.phone.value.length > 0,'is-invalid': submitted && f.phone.errors }" />
                                <div *ngIf="f.phone.value.length > 0" class="input-group-append">
                                    <div class="input-group-text bg-transparent" style="border: 1px solid #ababab !important;">
                                        <span class="material-icons-outlined" [ngClass]="{'text-secondary':!isphoneValid,'text-success':isphoneValid, 'text-danger':(f.phone.value.length > 0 && !isphoneValid)}" >verified</span>
                                    </div>
                                </div>
                                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                    <div *ngIf="f.phone.errors.required">Phone No is required</div>
                                    <div *ngIf="f.phone.errors.pattern">Numbers only allowed</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group d-none">
                    <label>Country</label>
                    <select disabled="true" formControlName="country" class="form-control" (change)="ChangeCountry($event);" [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                        <option value="">Choose Country</option>
                        <option *ngFor="let c of lstCountry" [value]="c.CountryID">{{c.CountryName}}</option>
                    </select>
                    <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                        <div *ngIf="f.country.errors.required">Country is required</div>
                    </div>
                </div>
                <div class="form-group d-none">
                    <label>Time Zone </label>
                    <input type="text" formControlName="timeZone" value="{{timeZoneName}}" class="form-control" disabled="true"/>
                </div>
                <div class="form-group d-none">
                    <label>Currency </label>
                    <input type="text" formControlName="currencyName" value="{{currency}}" class="form-control" disabled="true"/>
                </div>
                <!-- <div class="form-group">
                    <label>LOB Type</label>
                    <select formControlName="lobType" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lobType.errors }">
                        <option value="">Choose LOB Type</option>
                        <option *ngFor="let c of lstLOBtype" [value]="c.LOBID">{{c.LOBName}}</option>
                    </select>
                    <div *ngIf="submitted && f.lobType.errors" class="invalid-feedback">
                        <div *ngIf="f.lobType.errors.required">LOB Type is required</div>
                    </div>
                </div> -->
                <div class="text-center mt-35">
                    <button [disabled]="loading" class="btn btn-primary mr-3">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        {{this.pageOption == 1?"Start Trial":"Continue"}}
                    </button>
                    <button class="btn btn-secondary ml-3" type="button" (click)="onReset()">Cancel</button>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-container *ngIf="isSuccess">
        <div class="col-md-8 mb-4 offset-sm-2" style="margin-top: 20%;">
            <div class="row mt-1">
                <h1 style="font-weight:900">Success!</h1>
            </div>
            <div class="row mt-5">
                <h1 style="font-weight:500"> Hi {{fullName}}, please accept your email invite to start using Info Accounting.</h1>
            </div>
            <div class="row mt-4" *ngIf="this.pageOption==1">
                <h5>we sent an email to {{emailAddress}}</h5>
            </div>
            <div class="row mt-4">
                <h5> <a routerLink="/login" routerLinkActive="active">click here</a> to redirect Login page.</h5>
            </div>
        </div>
    </ng-container>
    
    <ng-container  *ngIf="!isSuccess && this.pageOption==2" class="d-none">
        <div class="col-md-8 mb-4 offset-sm-2 d-none" style="margin-top: 20%;">
            <div class="row mt-1">
                <h1 style="font-weight:900">Failed!</h1>
            </div>
            <div class="row mt-5">
                <h1 style="font-weight:500"> Hi {{fullName}}, please accept your email invite to start using Info Accounting.</h1>
            </div>
            <!-- <div class="row mt-4">
                <h5>we sent an email to {{emailAddress}}</h5>
            </div> -->
            <div class="row mt-4">
                <h5> <a routerLink="/login" routerLinkActive="active">click here</a> to redirect Login page.</h5>
            </div>
        </div>
    </ng-container>
</div>
<ng-container *ngIf="showLoading">
    <div class="transparentCover "></div>
    <div class="loading-text">
        We are configuring your {{(this.pageOption==1)?"trial":""}} account. You will receive an email, once it has been completed. </div>
    <div class="loading"></div>
</ng-container>

 <!-- Mobile no OTP verification -->

 <div bsModal #showMobileOTP="bs-modal" class="modal fade" tabindex="-1" role="dialog"
 aria-labelledby="MobileOTP" aria-hidden="true" [config]="{ backdrop: 'static' }">
<div class="modal-dialog modal-md" role="document">
<div class="modal-content">
 <div class="modal-header">
   <h4 class="modal-title">Validating your mobile number</h4>
   <button type="button" class="close" (click)="onCancelOTPmodal()">
     <span aria-hidden="true">×</span>
   </button>
 </div>
 <div class="modal-body" style="margin: 20px 0px;">
     <div class="form-group">
       <div class="col-md-8 offset-md-2">
         <label for="mobileOTP">Enter OTP<span class="text-danger">*</span></label>
         <input class="form-control" type="text" name="mobileOTP" autocomplete="off" [(ngModel)]="mobileOTP" type="text" maxlength="6">
       </div>
       <div class="col-md-8 offset-md-2" *ngIf="isOTPerror">
          <span class="text-danger">{{errmsgOTP}}</span>
       </div>
     </div>
     <div *ngIf="this.showMobileOTP.isShown && !this.isOTPexpired" class="text-center text-success" style="height: 65px;">
        <app-countdown [Duration]="OTPDuration" (IsExpired)="EmitCountDown($event)"></app-countdown>
     </div>
     <div *ngIf="this.isOTPexpired" class="text-center text-danger">
        OTP has been expired. Click Resend OTP to validate again.
     </div>
     <div style="margin-top:2%;position: absolute;">
        <b>Note:</b> You will receive an OTP to verify your mobile number.
     </div>
 </div>
 <div class="modal-footer">
    <div class="container">
    <div class="row">
        <div class="col-md-6 text-left">
            <button [disabled]="isOTPexpired" class="btn" [ngClass]="{'btn-secondary':isOTPexpired,'btn-primary':!isOTPexpired}" (click)="onValidateOTP()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                <i class="fa fa-check fa-md mr-3" aria-hidden="true"></i>
                Validate
              </button>
        </div>
        <div class="col-md-6 text-right">
            <button [disabled]="!isOTPexpired" [ngClass]="{'btn-secondary':!isOTPexpired,'btn-primary':isOTPexpired}" class="btn"
            (click)="onResendOTP()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                <i class="fa fa-paper-plane fa-md mr-3" aria-hidden="true"></i>
                Resend OTP
              </button>
        </div>
    </div>
    </div>
 </div>
</div>
</div>
</div>
