import { Injectable, Inject } from '@angular/core';
import { HttpServices } from './http.service'
import { EncryptDecryptService } from './encryptdecrypt.service'

@Injectable({
  providedIn: 'root'
})
export class LocalCacheServices {
  public localCountriesKey: string;
  public localCurrentUserKey: string;
  public localSelectedBank: string;
  public localInUseCurrencyTypes: string;
  public localparentPageID: string;
  public localpageID: string;
  public cacheSize: any;
  public localCurrentOrganization: string;
  public localNZIRDAccessToken: string;
  public localNewViewScreen: string;

  encodedata: string
  deodedata: string

  constructor(private http: HttpServices, private encdec: EncryptDecryptService) {
    this.localCurrentUserKey = this.encdec.encodeBase64("CurrentUser");
    this.localCountriesKey = this.encdec.encodeBase64("Countries");
    this.localSelectedBank = this.encdec.encodeBase64("SelectedBank");
    this.localInUseCurrencyTypes = this.encdec.encodeBase64("InUseCurrencyTypes");
    this.localCurrentOrganization = this.encdec.encodeBase64("CurrentOrganization");
    this.localNZIRDAccessToken = this.encdec.encodeBase64("NZIRDAccessToken");
    this.localNewViewScreen = this.encdec.encodeBase64("NewViewScreen");
    this.cacheSize = "0 KB";
  }
  setlocalStorageBase64(key: string, data: any) {
    this.encodedata = this.encdec.encodeBase64(data);
    localStorage.setItem(key, this.encodedata);
  }

  getlocalStorageBase64(key: string) {
    this.encodedata = localStorage.getItem(key);
    if(this.encodedata != null)
    {
      this.deodedata = this.encdec.decodeBase64(this.encodedata)
      return this.deodedata;
    }else
    {
      return null;
    }
  }

  setlocalStorage(key: string, data: any) {
    this.encodedata = this.encdec.encode(data);
    localStorage.setItem(key, this.encodedata);
  }

  setlocalStoragewithExpiry(key: string, data: any,_mintues:number) {
    this.removelocalStorage(key);
    const now = new Date();
    const item = {
      value:data,
      expiry:now.getTime()+(_mintues*60000)
    }
    this.encodedata = this.encdec.encode(item);
    localStorage.setItem(key, this.encodedata);
  }

  getlocalStoragewithExpiry(key: string){
    this.encodedata = localStorage.getItem(key);
    if(this.encodedata != null)
    {
      this.deodedata = this.encdec.decode(this.encodedata)
      let jsonObj = JSON.parse(this.deodedata);
      const now = new Date();
      if(now.getTime() > jsonObj.expiry){
        localStorage.removeItem(key);
        return null;
      }
      return jsonObj.value;
    }
  }

  getlocalStorage(key: string) {
    this.encodedata = localStorage.getItem(key);
    if(this.encodedata != null)
    {
      this.deodedata = this.encdec.decode(this.encodedata)
      return this.deodedata;
    }else
    {
      return null;
    }
  }

  removelocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  removeLocalCache() {
    localStorage.removeItem(this.localCurrentUserKey);
    localStorage.removeItem(this.localCountriesKey);
    localStorage.removeItem(this.localSelectedBank);
    localStorage.removeItem(this.localInUseCurrencyTypes);
    localStorage.removeItem(this.localCurrentOrganization);
    localStorage.removeItem(this.localNZIRDAccessToken);
    localStorage.removeItem(this.localNewViewScreen);
    localStorage.removeItem("DropdownContacts");
    localStorage.removeItem('SelectedLang');
    let rptKeys = Object.keys(localStorage).filter( m => m.includes("MyOnBoardModal"));
    rptKeys.forEach((key) => {
        localStorage.removeItem(key);
    });    
    this.RemoveReportCache();
  }

  refreshChangeOrg(){
    localStorage.removeItem(this.localCountriesKey);
    localStorage.removeItem(this.localSelectedBank);
    localStorage.removeItem(this.localInUseCurrencyTypes);
    localStorage.removeItem(this.localCurrentOrganization);
    localStorage.removeItem(this.localNZIRDAccessToken);
    localStorage.removeItem(this.localNewViewScreen);
    localStorage.removeItem("DropdownContacts");
    localStorage.removeItem('SelectedLang');   
    this.RemoveReportCache();
  }

  public RemoveReportCache() {
    // Delete the localstorage cache items which are related to reports
    let rptKeys = Object.keys(localStorage).filter( m => m.includes("rpt_"));
    rptKeys.forEach((key) => {
        localStorage.removeItem(key);
    });
  }

  getLocalStorageSize() {
    var data = '';
    for (var key in window.localStorage) {
      if (window.localStorage.hasOwnProperty(key)) {
        data += window.localStorage[key];
        //console.log(key + " = " + ((window.localStorage[key].length * 16) / (8 * 1024)).toFixed(2) + ' KB');
      }
    }
    this.cacheSize = (data ? '\n' + 'localStorage : (' + ((data.length * 16) / (8 * 1024)).toFixed(2) + ' KB)' : 'localStorage (0 KB)');
    return this.cacheSize;
  }
}
