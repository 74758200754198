import { AllCommunityModules, ColDef, ColumnApi, GridApi, GridOptions, Module } from '@ag-grid-community/all-modules';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { HttpServices } from '../../../utilities/_services/http.service';
import { AgdatepickerComponent } from '../gridComponent/agdatepicker.component';
import { AlphanumericEditor } from '../gridComponent/alphanumeric-editor.component';
import { QuanityUOMComponent } from '../gridComponent/quanity-uom.component';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MasterServices } from '../../../utilities/_services/master.service';
import { WarehouselistComponent } from '../warehouselist/warehouselist.component';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { BatchlistComponent } from '../batchlist/batchlist.component';
import { AdvanceinventoryService } from '../../../utilities/_services/advanceinventory.service';
import { DropdownwithtextboxComponent } from '../gridComponent/dropdownwithtextbox/dropdownwithtextbox.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-createbatch',
  templateUrl: './createbatch.component.html',
  styleUrls: ['./createbatch.component.scss']
})
export class CreatebatchComponent implements OnInit {
  @ViewChild('modalAllocateBatchQuantity')
  public modalAllocateBatchQuantity: ModalDirective;
  @Output() emitOnSubmitValue = new EventEmitter<any>();
  @Output() emitAfterBatchorSerialSaved = new EventEmitter<any>();
  trackingMethod: string = '';
  batchItemDetails: any = [];
  batchItemsGridOptions: GridOptions;
  modules: Module[] = AllCommunityModules;
  itemscolumnDefs: any = [];
  batchMasterData: any = [];
  _requiredQuantity: number = 0;
  _POQuantity: number = 0;
  Duplicates: any = []
  isEnabledActionButtons: boolean = true;
  searchText: string;
  paginationPageSize: any;
  lstPagination: any = [];
  settingsForm: FormGroup;
  batchDetailForm: FormGroup;
  minDate: { year: number; month: number; day: number };
  minDate2: { year: number; month: number; day: number };
  selectedRowCount: number = 0;
  submitted: boolean = false;
  loading: boolean;
  selectedRows: any;
  totalGridRows: number = 0;
  BatchID: any = 0;
  ItemID: number = 0;
  frameworkComponents = {
    datePicker: AgdatepickerComponent,
    alphanumeric: AlphanumericEditor,
    quantity: QuanityUOMComponent,
    Warehouse: WarehouselistComponent,
    batchList: BatchlistComponent,
    batchNo: DropdownwithtextboxComponent
  }
  orgSettings: any;
  isWarehouseEnabled: any
  itemID: number = 0;
  _ROTransDetailId: number;
  batchID: number = 0;
  transactionQuantity: number;
  quantity: number;
  succesNotifierId: number = 0;
  _mode: string = 'View';
  _transactionTypeId: number = 0;
  _quantity: number;
  _quantityLabel: string = 'Purchase';
  totalQuantity: any;
  unassignedQuantity: any;
  assignedQty: any;
  lstBatch: any = [];
  lstWarehouse: any = [];
  _transactionType: any = [
    { transactionTypeId: 20, transactionTypeName: 'Receive Order' },
    { transactionTypeId: 2, transactionTypeName: 'Bill' },
    { transactionTypeId: 8, transactionTypeName: 'Sales Credit Note' },
    { transactionTypeId: 4, transactionTypeName: 'Purchase Credit Note' },
  ];
  _transactionDetailId: number = 0;
  gridApi: GridApi;
  tracktype:string='';
  gridColumnApi: ColumnApi;
  agGridHearderColumnDefTemplateString: string = '<div class="ag-cell-label-container" role="presentation">' +
    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
    '     &nbsp <span class="text-danger">*</span>' +
    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    '  </div>' +
    '</div>';

  constructor(private http: HttpServices,
    private datepipe: DatePipe,
    private master: MasterServices,
    private fb: FormBuilder,
    private alertMessage: AlertMessage,
    private local: LocalCacheServices,
    private router: Router,
    private AI: AdvanceinventoryService,
    private translate: TranslateService
  ) {
    this.paginationPageSize = this.master.paginationPageSize;
    this.lstPagination.push({ text: '5', value: 5 });
    this.lstPagination = this.master.getPagination();
    this.translate.onDefaultLangChange.subscribe(async() => {
      this.batchItemsGridOptions.api.refreshHeader();
      this.trackingMethod = this.translate.instant("InventoryModule.Item.Batch Number");
    });
    this.translate.onLangChange.subscribe(async() => {
      this.batchItemsGridOptions.api.refreshHeader();
      this.trackingMethod = this.translate.instant("InventoryModule.Item.Batch Number");
    });
    this.trackingMethod = this.translate.instant("InventoryModule.Item.Batch Number");
    this.initFormGroup();
  }

  ngOnInit(): void {
    this.orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
    this.isWarehouseEnabled = this.orgSettings.enableWarehouse;
    this.batchItemsGridOptions = <GridOptions>{
      domLayout: "autoHeight",
      sortable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      rowHeight: 40,
      onGridReady: (params: any) => {     
        this.batchItemsGridOptions.api.sizeColumnsToFit();
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;        
      },
      onGridSizeChanged: () => {
        this.batchItemsGridOptions.api.sizeColumnsToFit();
      },
    }   
  }

  dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var date = new Date(dateAsString.substring(0, 10));
      var cellDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    },
    browserDatePicker: true,
    minValidYear: 2000,
    closeOnApply: true,
    inRangeInclusive: true,
    suppressAndOrCondition: true,
    buttons: ['reset', 'apply']
  };

  get f() {
    return this.settingsForm.controls;
  }


  initFormGroup() {
    this.settingsForm = new FormGroup({
      BatchNo: new FormControl('', [Validators.required]),
      Description: new FormControl(),
      ManufacturedDate: new FormControl(),
      ExpiryDate: new FormControl(),
      Quantity: new FormControl('', [Validators.required]),
      Warehouse: new FormControl()
    })
  }

  loadBatchDetail(data: any) {
    this.batchDetailForm.patchValue({
      BatchNo: data.batchNo,
      Description: data.description,
      ManufacturedDate: moment(data.manufacturedDate).format('YYYY/MM/DD'),
      ExpiryDate: moment(data.expiryDate).format('YYYY/MM/DD'),
      Quantity: data.quantity,
      Warehouse: data.quantity,
    });
    if (data.isLocked)
      this.batchDetailForm.controls.BatchNo.disable();
    else
      this.batchDetailForm.controls.BatchNo.enable();
  }

  dateFormatter(params) {
    return params.value == "" ? params.value : (moment(params.value)).format('DD/MM/YYYY');
  }

  async openAllocateBatchPopup(_itemID: number, _quantity: number, _POQuantity: number, _mode: string, _transactionDetailId: number, _transactionTypeId: number) {
    await this.createColumnDefs(_transactionTypeId);
    this.batchItemsGridOptions.columnApi.resetColumnState();
    this._mode = _mode;
    this._transactionTypeId = _transactionTypeId;
    this._quantityLabel = this._transactionType.find(x => x.transactionTypeId == _transactionTypeId).transactionTypeName;

    this.lstWarehouse = await this.getWarehouseDetails();
    // this.lstBatch = await this.getBatchList(_itemID, _transactionDetailId, _transactionTypeId);
    this.lstBatch = await this.AI.getBatchList(_itemID, _transactionDetailId, _transactionTypeId);

    this.setNameCellEditorValues_Warehouse(this.lstWarehouse);
    this.setNameCellEditorValues(this.lstBatch);

    if (_transactionTypeId == 20) { //Batch from Receive Order
      if (this._mode == 'View') {
        this.isEnabledActionButtons = true;
        this.assignedQty = _quantity;
        await this.getAssignedBatch(_transactionDetailId, _itemID, _POQuantity, _quantity);
        this.assignedQty = this.calculateAssingedBatchCount();
        this.modalAllocateBatchQuantity.show();
      } else if (this._mode == 'Edit') {
        this.isEnabledActionButtons = true;
        await this.getAssignedBatch(_transactionDetailId, _itemID, _POQuantity, _quantity);
        this.assignedQty = this.calculateAssingedBatchCount();
        this._requiredQuantity = Number(_quantity);
        this.modalAllocateBatchQuantity.show();
      } else {
        this.isEnabledActionButtons = true;
        this._requiredQuantity = Number(_quantity);
        this.assignedQty = 0;
        this._POQuantity = Number(_POQuantity);
        this.modalAllocateBatchQuantity.show();
        this.getBatchMaster(_itemID);
      }
    }
    else if (_transactionTypeId == 2) { //Batch from Bill
      await this.getAssignedBatchByBillDetailId(_transactionDetailId, _itemID, _POQuantity, _quantity);
      this.modalAllocateBatchQuantity.show();
    } else if (_transactionTypeId == 8) { //Batch from SCN
      await this.getAssignedBatchBySCNTransId(_transactionDetailId, _itemID, _POQuantity, _quantity);
      this.modalAllocateBatchQuantity.show();
    } else if (_transactionTypeId == 4) { //Batch from vCN
      await this.getAssignedBatchByVCNTransId(_transactionDetailId, _itemID, _POQuantity, _quantity);
      this.modalAllocateBatchQuantity.show();
    }
  }

  async openTempAllocateBatchPopup(_itemID: number, _quantity: number, _POQuantity: number, _mode: string, _transactionDetailId: number, _transactionTypeId: number, tempBatchAllocationData: any, ctableRowData?: any) {
    await this.createColumnDefs(_transactionTypeId);
    this._mode = _mode;
    this.itemID = _itemID;
    this._ROTransDetailId = _transactionDetailId;
    this._quantity = _quantity
    this.batchItemDetails = ctableRowData;
    this._transactionTypeId = _transactionTypeId;
    this._quantityLabel = this._transactionType.find(x => x.transactionTypeId == _transactionTypeId).transactionTypeName;
    if (_transactionTypeId == 20) { //Batch from Receive Order
      this.lstWarehouse = await this.getWarehouseDetails();
      this.setNameCellEditorValues_Warehouse(this.lstWarehouse);
      if (this._mode == 'Edit') {
        this.isEnabledActionButtons = true;
        if (!isNaN(_POQuantity)) {
          this._POQuantity = _POQuantity;
        } if (!isNaN(_quantity)) {
          this._requiredQuantity = _quantity;
        }
        let sumOfQuantity = tempBatchAllocationData.map(col => col.Quantity).reduce((a, b) => {
          return Number(a) + Number(b);
        });
        this.assignedQty = Number(sumOfQuantity);
        this._requiredQuantity = Number(_quantity);
        // await this.getAssignedBatch(_transactionDetailId, _itemID, _POQuantity, _quantity);
        this.modalAllocateBatchQuantity.show();
        this.loadTempAllocationData2(0, this.itemID, _POQuantity, _quantity, tempBatchAllocationData);

      } else {
        this.isEnabledActionButtons = true;
        if (!isNaN(_POQuantity)) {
          this._POQuantity = _POQuantity;
        } if (!isNaN(_quantity)) {
          this._requiredQuantity = _quantity;
        }
        let sumOfQuantity = tempBatchAllocationData.map(col => col.Quantity).reduce((a, b) => {
          return Number(a) + Number(b);
        });
        this.assignedQty = Number(sumOfQuantity);
        this._requiredQuantity = Number(_quantity);
        this.modalAllocateBatchQuantity.show();
        this.loadTempAllocationData2(0, this.itemID, _POQuantity, _quantity, tempBatchAllocationData);
      }
    }
  }

  async getWarehouseDetails(): Promise<any> {
    let returnvalue: any[];
    let data: any;
    data = await this.http.getserviceawait(environment.purchaseApiUrl + `/Bills/GetWareHouseDetails`);
    if (data.isSuccess && data.responseData != null) {
      returnvalue = data.responseData.map(row => ({
        warehouseID: row.warehouseID,
        warehouseName: row.warehouseName
      }));
    }
    return returnvalue;
  }

  closeAllocateBatchPopup() {
    this.modalAllocateBatchQuantity.hide();
  }

  async checkDublicateBatch(batchMasterModel) {
    await this.http.awaitPostservice(environment.inventoryApiUrl + '/Inventory/SaveUpdateBatchMaster', batchMasterModel);
  }

  async onDBValidate(): Promise<boolean> {
    let isSuccess = false;
    const batchMasterModel = [];
    for (var i = 0; i < this.batchMasterData.length; i++) {
      if (this.batchMasterData[i].batchNo != '' || Number(this.batchMasterData[i].quantity) > 0 || this.batchMasterData[i].description != '' || this.batchMasterData[i].expiryDate) {
        batchMasterModel.push({
          "BatchID": this.batchMasterData[i].batchID,
          "ItemID": this.itemID,
          "BatchNo": this.batchMasterData[i].batchNo,
          "Description": this.batchMasterData[i].description,
          "ManufacturedDate": this.batchMasterData[i].manufacturedDate == "" ? null : moment(this.batchMasterData[i].manufacturedDate).format("YYYY-MM-DD"),
          "ExpiryDate": this.batchMasterData[i].expiryDate == "" ? null : moment(this.batchMasterData[i].expiryDate).format("YYYY-MM-DD"),
          "LastSaleDate": this.batchMasterData[i].lastSaleDate == "" ? null : moment(this.batchMasterData[i].lastSaleDate),
          "Quantity": this.batchMasterData[i].quantity,
          "WarehouseID": this.isWarehouseEnabled ? this.batchMasterData[i].warehouseID : null,
        });
      }
    }
    if (batchMasterModel.length != 0) {
      let APIResponse = await this.http.awaitPostservice(environment.inventoryApiUrl + '/Inventory/OnValidateBatchItem', batchMasterModel);
      if (APIResponse["isSuccess"] == false) {
        let responseMessage = APIResponse["statusMessage"];
        responseMessage = JSON.parse(responseMessage);
        this.alertMessage.errorSummaryNotifierWithAutoClose(responseMessage.map(e => { return { message: e.Message } }), 0);
        isSuccess = false;
      } else {
        isSuccess = true;
      }
      return isSuccess
    } else {
      // this.alertMessage.errorNotifier("Batch shouldn't be empty", 0);
      return true;
    }
  }


  onUIValidate(): boolean {
    const errorMessage = [];
    const totalQuantity = this.batchMasterData.map((ele) => Number(ele.quantity)).reduce((a, b) => Number(a) + Number(b), 0);
    const validRowCount = this.batchMasterData.filter(e => e.batchNo != '' || Number(e.quantity) > 0 || e.warehouseID > 0).length;
    if (this.batchMasterData.length > 0) {
      this.batchMasterData.forEach((ele, index) => {
        index++;
        if (ele.batchNo != '' || Number(ele.quantity) > 0 || ele.description != '' || ele.expiryDate || ele.warehouseID > 0) {
          if (ele.batchNo == '') {
            errorMessage.push({ message: this.translate.instant('InventoryModule.Item.Row Number') + ' ' + (index) + ' : ' + this.translate.instant('InventoryModule.Item.Batch No should not be empty') });
          }
          if (Number(ele.quantity) == 0 || ele.quantity == '') {
            errorMessage.push({ message: this.translate.instant('InventoryModule.Item.Row Number') + ' ' + (index) + ' : ' + this.translate.instant('InventoryModule.Item.Quantity should not be blank or zero') });
          }
          if (Date.parse(ele.expiryDate) < Date.parse(ele.manufacturedDate)) {
            errorMessage.push({ message: this.translate.instant('InventoryModule.Item.Row Number') + ' ' + (index) + ' : ' + this.translate.instant('InventoryModule.Item.The expiry date should be later than the manufacture date') });
          }
          if (this.isWarehouseEnabled == true) {
            if (Number(ele.warehouseID) == 0) {
              errorMessage.push({ message: this.translate.instant('InventoryModule.Item.Row Number') + ' ' + (index) + ' : ' + this.translate.instant('InventoryModule.Item.Warehouse Name should not be empty') });
            }
            if (this.isWarehouseEnabled && this.batchMasterData.filter(column => column.warehouseID == ele.warehouseID && column.batchNo == ele.batchNo).length > 1) {
              const dublicateData = this.batchMasterData.filter(column => column.warehouseID == ele.warehouseID && column.batchNo == ele.batchNo);
              let warehouseName = this.lstWarehouse.find(e => e.warehouseID == dublicateData[0].warehouseID)?.warehouseName;
              let compareText = ' ' + warehouseName + ' & ' + dublicateData[0].batchNo + ' cannot be duplicate';
              if (errorMessage.filter(msg => msg.message.toString().split(':')[1] == compareText).length == 0)
                errorMessage.push({ message: this.translate.instant('InventoryModule.Item.Row Number') + ' ' + (index) + ' : ' + warehouseName + ' & ' + dublicateData[0].batchNo + ' ' + this.translate.instant('InventoryModule.Item.cannot be duplicate') });
            }
          } else {
            if (this.batchMasterData.filter(column => column.batchNo == ele.batchNo).length > 1) {
              errorMessage.push({ message: this.translate.instant('InventoryModule.Item.Row Number') + ' ' + (index) + ' : ' + this.translate.instant('InventoryModule.Item.Batch No')+ '. ' + this.translate.instant('InventoryModule.Item.cannot be duplicate') });
            }
          }
        }
      });
      if (totalQuantity > this._requiredQuantity) {
        errorMessage.push({ message: this.translate.instant('InventoryModule.Item.Batch quantity should not be exceed than required quantity') });
      }
      // if (validRowCount == 0) {
      //   errorMessage.push({ message: 'There are no rows to save, Please add Batch with quantity' });
      // } 
      if (errorMessage.length > 0) {
        this.alertMessage.errorSummaryNotifierWithAutoClose(errorMessage, 0);
        return false;
      }
    }
    return true;
  }

  async onSubmit() {
    if (this._transactionTypeId == 20) { //Receive Order
      if (this._mode == 'Edit' || this._mode == 'View') {//Edit Receive Order
        const batchMasterModel = [];
        if (this.batchMasterData.length >= 0) {
          if (this.onUIValidate() && await this.onDBValidate()) { //&& await this.onDBValidate() 
            for (var i = 0; i < this.batchMasterData.length; i++) {
              if (this.batchMasterData[i].batchNo != '' || Number(this.batchMasterData[i].quantity) > 0 || this.batchMasterData[i].description != '' || this.batchMasterData[i].expiryDate) {
                batchMasterModel.push({
                  "BatchID": (this.batchMasterData[i].batchID != null && this.batchMasterData[i].batchID != undefined) ? this.batchMasterData[i].batchID : 0,
                  "ItemID": this.itemID,
                  "BatchNo": this.batchMasterData[i].batchNo,
                  "Description": this.batchMasterData[i].description,
                  "ManufacturedDate": this.batchMasterData[i].manufacturedDate == "" ? null : moment(this.batchMasterData[i].manufacturedDate).format("YYYY-MM-DD"),
                  "ExpiryDate": this.batchMasterData[i].expiryDate == "" ? null : moment(this.batchMasterData[i].expiryDate).format("YYYY-MM-DD"),
                  "LastSaleDate": this.batchMasterData[i].lastSaleDate == "" ? null : moment(this.batchMasterData[i].lastSaleDate),
                  "Quantity": this.batchMasterData[i].quantity,
                  // "Warehouse": this.batchMasterData[i].WarehouseName,
                  "WarehouseID": this.isWarehouseEnabled ? this.batchMasterData[i].warehouseID : null,
                  "IsLocked": this.batchMasterData[i].IsLocked,
                  "ROTransDetailID": this._ROTransDetailId
                });
              }
            }
            if (this._mode == "View") {
              let isDBSaved = await this.onSaveUpdateROBatchDetails(batchMasterModel);
              if (isDBSaved) {
                // this.emitOnSubmitValue.emit(batchMasterModel);
                this.emitAfterBatchorSerialSaved.emit({
                  transactionTypeID: 20,
                  transactionTypeName: 'Receive Order',
                  transactionDetailID: this._ROTransDetailId,
                  track:this.tracktype
                });
                this.closeAllocateBatchPopup();
              } else {
                let msg = this.translate.instant("InventoryModule.Item.Cannot save the Batch created");
                this.alertMessage.errorNotifier(msg, 0);
              }
            }
            this.emitOnSubmitValue.emit(batchMasterModel);           
            this.closeAllocateBatchPopup();
          }
        } else {
          //  this.closeAllocateBatchPopup();
          let msg = this.translate.instant('InventoryModule.Item.There are no rows to save, Please add Batch with quantity');
          this.alertMessage.errorNotifier(msg, 0)
        }
      } else {
        const batchMasterModel = [];
        if (this.batchMasterData.length > 0) {
          if (this.onUIValidate() && await this.onDBValidate()) {//&& await this.onDBValidate()
            for (var i = 0; i < this.batchMasterData.length; i++) {
              if (this.batchMasterData[i].batchNo != '' || Number(this.batchMasterData[i].quantity) > 0 || this.batchMasterData[i].description != '' || this.batchMasterData[i].expiryDate) {
                batchMasterModel.push({
                  "BatchID": this.batchMasterData[i].batchID,
                  "ItemID": this.itemID,
                  "BatchNo": this.batchMasterData[i].batchNo,
                  "Description": this.batchMasterData[i].description,
                  "ManufacturedDate": this.batchMasterData[i].manufacturedDate == "" ? null : moment(this.batchMasterData[i].manufacturedDate),
                  "ExpiryDate": this.batchMasterData[i].expiryDate == "" ? null : moment(this.batchMasterData[i].expiryDate),
                  "LastSaleDate": this.batchMasterData[i].lastSaleDate == "" ? null : moment(this.batchMasterData[i].lastSaleDate),
                  "Quantity": this.batchMasterData[i].quantity,
                  // "Warehouse": this.batchMasterData[i].WarehouseName,
                  "WarehouseID": this.isWarehouseEnabled ? this.batchMasterData[i].warehouseID : null,
                });
              }
            }
            this.emitOnSubmitValue.emit(batchMasterModel);
            this.closeAllocateBatchPopup();
          }
        } else {
          let msg = this.translate.instant('InventoryModule.Item.There are no rows to save, Please add Batch with quantity');
          this.alertMessage.errorNotifier(msg, 0);
        }
      }
    }
    else if (this._transactionTypeId == 2) {//Bill
      this.submitted = true;
      if (this.batchMasterData.length > 0) {
        if (this.onUIValidate()) {
          this.onSaveUpdateBillBatchDetails();
          this.loading = false;
        }
      }
      else {
        this.ondeletebill();
      }
    }
    else if (this._transactionTypeId == 8) {//SCN
      this.submitted = true;
      if (this.batchMasterData.length > 0) {
        if (this.onUIValidate()) {
          this.onSaveUpdateSCNBatchDetails();
          this.loading = false;
        }
      } else {
        this.ondeleteSCN();
      }
    } else if (this._transactionTypeId == 4) {//VCN
      this.submitted = true;
      if (this.batchMasterData.length > 0) {
        if (this.onUIValidate()) {
          this.onSaveUpdateVCNBatchDetails();
          this.loading = false;
        }
      } else {
        this.onDeleteVCreditNoteBatchDetails();
      }
    }
  }
  async onSaveUpdateROBatchDetails(batchMasterModel: any[]): Promise<boolean> {
    let isSuccess = false;
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    let APIResponse;
    if (batchMasterModel.length > 0) {
      APIResponse = await this.http.awaitPostservice(environment.purchaseApiUrl + '/ReceiveOrder/SaveUpdateROAdvanceTracking', {
        trackingType: 'Batch', detailsJson: JSON.stringify(batchMasterModel)
      });
    } else {
      APIResponse = await this.http.deleteservice(
        environment.purchaseApiUrl
        + `/ReceiveOrder/DeleteROBatchSerialDetails?ROTransDetailId=${this._ROTransDetailId}&ItemID=${this.itemID}&TrackingType=${"Batch"}`
      ).toPromise();
    }
    if (APIResponse["isSuccess"] == false) {
      let responseMessage = APIResponse["statusMessage"];
      responseMessage = JSON.parse(responseMessage);
      let errorList = responseMessage.map(ele => ({ message: ele.Message })) ?? [];
      this.alertMessage.errorNotifierList(errorList, 0);
      isSuccess = false;
    } else {
      isSuccess = true;
      let msg = this.translate.instant("Common.Saved successfully");
      this.alertMessage.successNotifier(msg, 0);
    }
    return isSuccess;
  }

  ondeletebill() {
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    this.http.deleteservice(environment.purchaseApiUrl + `/Bills/DeleteBatchitems?DetailId=${this._transactionDetailId}&ItemId=${this.itemID}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          let msg = this.translate.instant("Common.Saved successfully");
          this.alertMessage.successNotifier(msg, this.succesNotifierId);
          this.emitAfterBatchorSerialSaved.emit({
            transactionTypeID: 2,
            transactionTypeName: 'Bill',
            track:this.tracktype
          });
          this.closeModal();
        }
        else {
          this.alertMessage.errorNotifier(data.responseData.message, this.succesNotifierId);
        }
      }
    })
  }
  ondeleteSCN() {
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    this.http.deleteservice(environment.salesApiUrl + `/SalesCreditnotes/DeleteBatchitems?SCNTransID=${this._transactionDetailId}&ItemId=${this.itemID}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          let msg = this.translate.instant("Common.Saved successfully");
          this.alertMessage.successNotifier(msg, this.succesNotifierId);
          this.emitAfterBatchorSerialSaved.emit({
            transactionTypeID: 8,
            transactionTypeName: 'SCN',
            transactionDetailID: this._transactionDetailId,
            track:this.tracktype
          });
          this.closeModal();
        }
        else {
          this.alertMessage.errorNotifier(data.responseData.message, this.succesNotifierId);
        }
      }
    });
  }

  addRows(count): void {
    for (let i = 1; i <= count; i++) {
      let rowFeild = {
        batchID: 0,
        batchNo: '',
        description: '',
        manufacturedDate: '',
        expiryDate: '',
        lastSaleDate: '',
        quantity: '',
        IsLocked: false,
        warehouseID: 0,
        isCreatedFromCN: false
      }
      this.batchMasterData.push(rowFeild);
      this.batchItemsGridOptions.api.addItems([rowFeild]);
    }
  }

  calculateAssingedBatchCount(): number {
    return this.batchMasterData.map((ele) => Number(ele.quantity)).reduce((a, b) => Number(a) + Number(b), 0);
  }

  async getAssignedBatch(_ROTransDetailId: number, _itemID: number, _POQuantity: number, _quantity: number) {
    this.itemID = _itemID;
    this._ROTransDetailId = _ROTransDetailId;
    this._quantity = _quantity
    let data: any = await this.http.getserviceawait(environment.purchaseApiUrl + `/ReceiveOrder/GetAssignedBatch?ROTransDetailId=${_ROTransDetailId}&ItemId=${_itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.batchMasterData = [];
      this.batchItemDetails = data.responseData;
      if (!isNaN(_POQuantity)) {
        this._POQuantity = _POQuantity;
      } if (!isNaN(_quantity)) {
        this._requiredQuantity = _quantity;
      } if (this.batchItemDetails.assignedQuantity != null && this.batchItemDetails.assignedQuantity != undefined) {
        this.assignedQty = this.batchItemDetails.assignedQuantity;
      }
      data.responseData.batchMasterDetails.forEach(ele => {
        let rowFeild = {
          batchID: ele.batchID,
          batchNo: ele.batchNo,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
          expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
          lastSaleDate: ele.lastSaleDate == null ? "" : ele.lastSaleDate,
          quantity: ele.quantity,
          IsLocked: ele.isLocked,
          warehouseID: ele.warehouseID,
        }
        // this.setNameCellEditorValues(this.lstWarehouse)
        this.batchMasterData.push(rowFeild);
        this.batchItemsGridOptions.api.addItems([rowFeild]);
      });
    }
  }

  getBatchMaster(_itemID: number) {
    this.itemID = _itemID;
    this.http.getserviceawait(environment.inventoryApiUrl + `/Inventory/GetBatchMasterDetails?ItemId=${_itemID}`).then((data: any) => {
      if (data.isSuccess && data.responseData != null) {
        this.batchMasterData = [];
        this.batchItemDetails = data.responseData;
        // data.responseData.batchMasterDetails.forEach(ele => {
        //   let rowFeild = {
        //     batchID: ele.batchID,
        //     batchNo: ele.batchNo,
        //     description: ele.description,
        //     manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
        //     expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
        //     lastSaleDate: ele.lastSaleDate == null ? "" : ele.lastSaleDate,
        //     quantity: ele.quantity
        //   }
        //   this.batchMasterData.push(rowFeild);
        //   this.batchItemsGridOptions.api.addItems([rowFeild]);
        // })
      }
    });
  }

  onCellClicked(params: any) {
    let BatchId = params.data.batchID;
    this.batchID = BatchId;
    let isLock = params.data.IsLocked ? params.data.IsLocked : false;
    const colId = params.column.getId();
    if (isLock == false) {
      if (colId === "Delete") {  
        this.onSortChanged();     
        const selectedRow = this.batchItemsGridOptions.api.getFocusedCell();
        this.batchMasterData.splice(selectedRow.rowIndex, 1);
        this.batchItemsGridOptions.api.setRowData(this.batchMasterData);
        this.assignedQty = this.calculateAssingedBatchCount();
      }
    }
  }

  async onSortChanged() {   
    const isSortApplied = this.gridApi.getSortModel().length > 0;
    const rowElements = [];
    this.gridApi.forEachNode(ele => rowElements.push({ rowIndex: ele.rowIndex, data: ele.data }));
    rowElements.sort((a, b) => {
      return a.rowIndex - b.rowIndex
    });
    if (isSortApplied) {
      if (this._transactionTypeId == 4) {
        this.batchMasterData = rowElements.map(col => col.data).map(ele => ({
          batchNo: ele.batchNo,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate ?? "",
          expiryDate: ele.expiryDate ?? "",
          quantity: ele.quantity,
          IsLocked: ele.IsLocked, //#11782 Fixed
          warehouseID: ele.warehouseID,
          transactionTypeID: ele.transactionTypeID,
          billDetailId: ele.billDetailID,
          CNTransId: ele.cnTransId,
          isCreatedFromCN: ele.isCreatedFromCN
        })) ?? [];
      } else if (this._transactionTypeId == 8) {
        this.batchMasterData = rowElements.map(col => col.data).map(ele => ({
          batchNo: ele.batchNo,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate ?? "",
          expiryDate: ele.expiryDate ?? "",
          lastSaleDate: ele.lastSaleDate ?? "",
          quantity: ele.quantity,
          IsLocked: ele.IsLocked, //#11782 Fixed
          warehouseID: ele.warehouseID,
          invoiceDetailId: ele.invoiceDetailId,
          isCreatedFromCN: ele.isCreatedFromCN
        })) ?? [];
      } else {
        this.batchMasterData = rowElements.map(col => col.data).map(ele => ({
          batchID: ele.batchID,
          batchNo: ele.batchNo,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate ?? "",
          expiryDate: ele.expiryDate ?? "",
          lastSaleDate: ele.lastSaleDate ?? "",
          quantity: ele.quantity,
          IsLocked: ele.isLocked,
          warehouseID: ele.warehouseID,
        })) ?? [];
      }
      this.batchItemsGridOptions.api.addItems(this.batchMasterData);
      this.batchItemsGridOptions.api.refreshCells(); 
    }
  }

  async loadTempAllocationData(_billDetailId: number, _itemID: number, _transactionQuantity: number, _quantity: number, tempBatchAllocationData: any) {
    this._transactionDetailId = _billDetailId;
    this.itemID = _itemID;
    this.transactionQuantity = _transactionQuantity;
    this.quantity = _quantity;
    if (tempBatchAllocationData.length > 0) {
      this.batchMasterData = [];
      tempBatchAllocationData.forEach(ele => {
        let rowField = {
          batchID: ele.batchID,
          batchNo: ele.batchNo,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
          expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
          lastSaleDate: ele.lastSaleDate == null ? "" : ele.lastSaleDate,
          quantity: ele.quantity,
          IsLocked: ele.isLocked,
          warehouseID: ele.warehouseID,
        }
        this.batchMasterData.push(rowField);
        this.batchItemsGridOptions.api.addItems([rowField]);
      });
    }
  }

  async loadTempAllocationData2(_billDetailId: number, _itemID: number, _transactionQuantity: number, _quantity: number, tempBatchAllocationData: any) {
    this._transactionDetailId = _billDetailId;
    this.itemID = _itemID;
    this.transactionQuantity = _transactionQuantity;
    this.quantity = _quantity;
    if (tempBatchAllocationData.length > 0) {
      this.batchMasterData = [];
      tempBatchAllocationData.forEach(ele => {
        let rowField = {
          batchID: ele.BatchID,
          batchNo: ele.BatchNo,
          description: ele.Description,
          manufacturedDate: ele.ManufacturedDate == null ? "" : ele.ManufacturedDate,
          expiryDate: ele.ExpiryDate == null ? "" : ele.ExpiryDate,
          lastSaleDate: ele.LastSaleDate == null ? "" : ele.LastSaleDate,
          quantity: ele.Quantity,
          IsLocked: ele.IsLocked,
          warehouseID: ele.WarehouseID,
        }
        this.batchMasterData.push(rowField);
        this.batchItemsGridOptions.api.addItems([rowField]);
      });
    }
  }

  async getAssignedBatchByBillDetailId(_billDetailId: number, _itemID: number, _transactionQuantity: number, _quantity: number) {
    this._transactionDetailId = _billDetailId;
    this.itemID = _itemID;
    this.transactionQuantity = _transactionQuantity;
    this.quantity = _quantity;
    let data: any = await this.http.getserviceawait(environment.purchaseApiUrl + `/Bills/GetAssignedBatch?BillDetailId=${_billDetailId}&ItemId=${_itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.batchMasterData = [];
      this.batchItemDetails = data.responseData;
      if (!isNaN(_transactionQuantity)) {
        this._POQuantity = _transactionQuantity;
      } if (!isNaN(_quantity)) {
        this._requiredQuantity = _quantity;
      }
      if (data.responseData.batchMasterDetails.length <= 0) {
        this.addRows(2);
      }
      this.totalQuantity = this.batchItemDetails.totalQuantity
      this.unassignedQuantity = this.batchItemDetails.unassignedQuantity
      this.assignedQty = this.batchItemDetails.assignedQuantity

      this.batchMasterData = data.responseData.batchMasterDetails.map(ele => ({
        batchID: ele.batchID,
        batchNo: ele.batchNo,
        description: ele.description,
        manufacturedDate: ele.manufacturedDate ?? "",
        expiryDate: ele.expiryDate ?? "",
        lastSaleDate: ele.lastSaleDate ?? "",
        quantity: ele.quantity,
        IsLocked: ele.isLocked,
        warehouseID: ele.warehouseID,
      })) ?? [];
      this.batchItemsGridOptions.api.addItems(this.batchMasterData);
      this.batchItemsGridOptions.columnApi.setColumnVisible("Delete", (this._mode != 'View'));
    }
  }

  async getAssignedBatchBySCNTransId(_SCNTransId: number, _itemID: number, _transactionQuantity: number, _quantity: number) {  
    this._transactionDetailId = _SCNTransId;
    this.itemID = _itemID;
    this.transactionQuantity = _transactionQuantity;
    this.quantity = _quantity;
    let data: any = await this.http.getserviceawait(environment.salesApiUrl + `/SalesCreditnotes/GetAssignedBatch?SCNTransId=${_SCNTransId}&ItemId=${_itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.batchMasterData = [];
      this.batchItemDetails = data.responseData;
      if (!isNaN(_transactionQuantity)) {
        this._POQuantity = _transactionQuantity;
      } if (!isNaN(_quantity)) {
        this._requiredQuantity = _quantity;
      }
      this.totalQuantity = this.batchItemDetails.totalQuantity
      this.unassignedQuantity = this.batchItemDetails.unassignedQuantity
      this.assignedQty = this.batchItemDetails.assignedQuantity
    
      this.batchMasterData = data.responseData.batchMasterDetails.map(ele => ({
        // batchID: ele.batchID,
        batchNo: ele.batchID,
        // batchNo: ele.batchN/o,
        description: ele.description,
        manufacturedDate: ele.manufacturedDate ?? "",
        expiryDate: ele.expiryDate ?? "",
        lastSaleDate: ele.lastSaleDate ?? "",
        quantity: ele.quantity,
        IsLocked: ele.isLocked,
        warehouseID: ele.warehouseID,
        invoiceDetailId: ele.invoiceDetailId,
        isCreatedFromCN: ele.isCreatedFromCN
      })) ?? [];
      this.batchItemsGridOptions.api.addItems(this.batchMasterData);      
    }
  }

  async getAssignedBatchByVCNTransId(_VCNTransId: number, _itemID: number, _transactionQuantity: number, _quantity: number) {
    this._transactionDetailId = _VCNTransId;
    this.itemID = _itemID;
    this.transactionQuantity = _transactionQuantity;
    this.quantity = _quantity;
    let data: any = await this.http.getserviceawait(environment.purchaseApiUrl + `/Creditnotes/GetAssignedBatch?VCNTransId=${_VCNTransId}&ItemId=${_itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.batchMasterData = [];
      this.batchItemDetails = data.responseData;
      if (!isNaN(_transactionQuantity)) {
        this._POQuantity = _transactionQuantity;
      } if (!isNaN(_quantity)) {
        this._requiredQuantity = _quantity;
      }
      this.totalQuantity = this.batchItemDetails.totalQuantity
      this.unassignedQuantity = this.batchItemDetails.unassignedQuantity
      this.assignedQty = this.batchItemDetails.assignedQuantity

      this.batchMasterData = data.responseData.batchMasterDetails.map(ele => ({
        // batchID: ele.batchID,
        // batchNo: ele.batchNo,
        batchNo: ele.batchID,
        description: ele.description,
        manufacturedDate: ele.manufacturedDate ?? "",
        expiryDate: ele.expiryDate ?? "",
        quantity: ele.quantity,
        IsLocked: ele.isLocked,
        warehouseID: ele.warehouseID,
        transactionTypeID: ele.transactionTypeID,
        billDetailId: ele.billDetailID,
        CNTransId: ele.cnTransId,
        isCreatedFromCN: ele.isCreatedFromCN
      })) ?? [];
      this.batchItemsGridOptions.api.addItems(this.batchMasterData);
      this.batchItemsGridOptions.api.refreshCells(); 
    }
  }

  async onSaveUpdateBillBatchDetails(): Promise<boolean> {
    let isSuccess = false;
     if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    const batchMasterModel = this.batchMasterData.filter(col => col.batchNo != '' && Number(col.quantity) > 0).map(ele => ({
      "BatchID": ele.batchID,
      "ItemID": this.itemID,
      "BatchNo": ele.batchNo,
      "Description": ele.description,
      "ManufacturedDate": ele.manufacturedDate == "" ? null : moment(ele.manufacturedDate).format("YYYY-MM-DD"),
      "ExpiryDate": ele.expiryDate == "" ? null : moment(ele.expiryDate).format("YYYY-MM-DD"),
      "LastSaleDate": ele.lastSaleDate == "" ? null : moment(ele.lastSaleDate),
      "Quantity": ele.quantity,
      "BillDetailID": this._transactionDetailId,
      "WarehouseID": this.isWarehouseEnabled ? ele.warehouseID : null,
    })) ?? [];

    let APIResponse = await this.http.awaitPostservice(environment.purchaseApiUrl + '/Bills/SaveUpdateBillBatchDetails', batchMasterModel);
    this.settingsForm.reset();
    if (APIResponse["isSuccess"] == false) {
      let responseMessage = APIResponse["statusMessage"];
      responseMessage = JSON.parse(responseMessage);
      let errorList = responseMessage.map(ele => ({ message: ele.Message })) ?? [];
      this.alertMessage.errorNotifierList(errorList, 0);
    } else {
      isSuccess = true;
      let msg = this.translate.instant("Common.Saved successfully");
      this.alertMessage.successNotifier(msg, 0);
      this.modalAllocateBatchQuantity.hide();
      this.emitAfterBatchorSerialSaved.emit({
        transactionTypeID: 2,
        transactionTypeName: 'Bill',
        track:this.tracktype
      });
    }
    return isSuccess
  }

  async onSaveUpdateSCNBatchDetails(): Promise<boolean> {
    let isSuccess = false;
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    const batchMasterModel = this.batchMasterData.filter(col => col.batchNo != '' && Number(col.quantity) > 0).map(ele => ({
      // "BatchID": ele.batchID,
      // "ItemID": this.itemID,
      // "BatchNo": ele.batchNo,
      "BatchID": this.lstBatch.find(e => e.batchID == ele.batchNo).batchID,
      "ItemID": this.itemID,
      "BatchNo": this.lstBatch.find(e => e.batchID == ele.batchNo).batchNo,
      "Description": ele.description,
      "ManufacturedDate": ele.manufacturedDate == "" ? null : moment(ele.manufacturedDate).format("YYYY-MM-DD"),
      "ExpiryDate": ele.expiryDate == "" ? null : moment(ele.expiryDate).format("YYYY-MM-DD"),
      "LastSaleDate": ele.lastSaleDate == "" ? null : moment(ele.lastSaleDate).format("YYYY-MM-DD"),
      "Quantity": ele.quantity,
      "SCNTransID": this._transactionDetailId,
      "WarehouseID": this.isWarehouseEnabled ? ele.warehouseID : null,
      "InvoiceDetailId": ele.invoiceDetailId
    })) ?? [];

    let APIResponse = await this.http.awaitPostservice(environment.salesApiUrl + '/SalesCreditnotes/SaveUpdateBatchDetails', batchMasterModel);
    this.settingsForm.reset();
    if (APIResponse["isSuccess"] == false) {
      let responseMessage = APIResponse["statusMessage"];
      responseMessage = JSON.parse(responseMessage);
      let errorList = responseMessage.map(ele => ({ message: ele.Message })) ?? [];
      this.alertMessage.errorNotifierList(errorList, 0);
    } else {
      isSuccess = true;
      let msg = this.translate.instant("Common.Saved successfully");
      this.alertMessage.successNotifier(msg, 0);
      this.modalAllocateBatchQuantity.hide();
      this.emitAfterBatchorSerialSaved.emit({
        transactionTypeID: 8,
        transactionTypeName: 'SCN',
        transactionDetailID: this._transactionDetailId,
        track:this.tracktype
      });
    }
    return isSuccess
  }


  async onSaveUpdateVCNBatchDetails(): Promise<boolean> {
    let isSuccess = false;
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    const batchMasterModel = this.batchMasterData.filter(col => col.batchNo != '' && Number(col.quantity) > 0).map(ele => ({
      "BatchID": this.lstBatch.find(e => e.batchID == ele.batchNo).batchID,
      "ItemID": this.itemID,
      "BatchNo": this.lstBatch.find(e => e.batchID == ele.batchNo).batchNo,
      "Description": ele.description,
      "ManufacturedDate": ele.manufacturedDate == "" ? null : moment(ele.manufacturedDate),
      "ExpiryDate": ele.expiryDate == "" ? null : moment(ele.expiryDate),
      "Quantity": ele.quantity,
      "CNTransID": this._transactionDetailId,
      "WarehouseID": this.isWarehouseEnabled ? ele.warehouseID : null,
      "BillDetailID": ele.billDetailId
    })) ?? [];

    let APIResponse = await this.http.awaitPostservice(environment.purchaseApiUrl + '/Creditnotes/SaveUpdateBatchDetails', batchMasterModel);
    this.settingsForm.reset();
    if (APIResponse["isSuccess"] == false) {
      let responseMessage = APIResponse["statusMessage"];
      responseMessage = JSON.parse(responseMessage);
      let errorList = responseMessage.map(ele => ({ message: ele.Message })) ?? [];
      this.alertMessage.errorNotifierList(errorList, 0);
    } else {
      isSuccess = true;
      let msg = this.translate.instant("Common.Saved successfully");
      this.alertMessage.successNotifier(msg, 0);
      this.modalAllocateBatchQuantity.hide();
      this.emitAfterBatchorSerialSaved.emit({
        transactionTypeID: 4,
        transactionTypeName: 'VCN',
        transactionDetailID: this._transactionDetailId,
        track:this.tracktype
      });
    }
    return isSuccess
  }

  closeModal() {
    this.modalAllocateBatchQuantity.hide();
  }


  toFindDuplicates(arry) {
    return [...new Set(arry.filter((elem, idx, arry) => arry.indexOf(elem) !== idx))]
  }

  onQuickFilterChanged() {
    this.batchItemsGridOptions.api.setQuickFilter(this.searchText);
    this.totalGridRows = this.batchItemsGridOptions.api.getDisplayedRowCount();
    if (this.totalGridRows == 0) {
      this.batchItemsGridOptions.api.showNoRowsOverlay();
    }
    else {
      this.batchItemsGridOptions.api.hideOverlay();
    }
  }

  onPageSizeChanged() {
    this.batchItemsGridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    this.batchItemsGridOptions.api.sizeColumnsToFit();

  }

  onRowSelected(params: any) {
    this.selectedRows = params.api.getSelectedRows();
    this.selectedRowCount = this.selectedRows.length;
  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const ManufacturedDate = this.settingsForm && this.settingsForm.get("ManufacturedDate").value;
    const ExpiryDate = this.settingsForm && this.settingsForm.get("ExpiryDate").value;
    if (ManufacturedDate && ExpiryDate) {
      invalid = new Date(ManufacturedDate).valueOf() > new Date(ExpiryDate).valueOf();
    }
    return invalid ? { invalidRange: { ManufacturedDate, ExpiryDate } } : null;
  };



  clearValues() {
    this.submitted = false;
    this.settingsForm.reset();
  }

  onCellValueChanged(params) {
    const colId = params.column.getId();
    this.assignedQty = this.calculateAssingedBatchCount();
    if (colId === "batchNo") {
      if ([4, 8].includes(this._transactionTypeId)) {
        if (params?.value?.isNew == true) {
          let isAlreadyExists = this.lstBatch?.filter(ele => ele.batchNo == params.value.batchNo).length > 0 ? true : false;
          if (isAlreadyExists == false) {
            const batchInfo = {
              batchID: this.generateUniqueID(),
              batchNo: params.value.batchNo,
              quantity: 0,
              warehouseID: params.data.warehouseID
            };
            this.addBatch(batchInfo);
            this.batchMasterData[params.rowIndex].batchNo = batchInfo.batchID;
            this.batchMasterData[params.rowIndex].isCreatedFromCN = true;
          }
        } else {
          let batches: any[] = this.lstBatch?.filter(ele => ele.batchID == params.value);
            this.batchMasterData[params.rowIndex].isCreatedFromCN = false;
            this.batchMasterData[params.rowIndex].availableQty = batches.length > 0 ? batches[0].availableQty : '';
            this.batchMasterData[params.rowIndex].manufacturedDate = batches.length > 0 && batches[0].manufacturedDate != null ? batches[0].manufacturedDate : '';
            this.batchMasterData[params.rowIndex].expiryDate = batches.length > 0 && batches[0].expiryDate != null ? batches[0].expiryDate : '';
            this.batchMasterData[params.rowIndex].description = batches.length > 0 ? batches[0].description : '';
        }
      }
      params.api.refreshCells({
        force: true,
      });

      // if (params.data.warehouseID > 0 && batchID > 0) {
      //   let batchInfo = this.lstBatch.filter(item => item.batchID == batchID);
      //   if (batchInfo?.length == 1) {
      //     // this.batchMasterData[index].batchNo = batchInfo[0].batchNo;
      //     // this.batchMasterData[index].availableQty = batchInfo[0].availableQty;
      //     //  this.batchMasterData[index].manufacturedDate = batchInfo[0].manufacturedDate == null ? '' : batchInfo[0].manufacturedDate;
      //     //  this.batchMasterData[index].expiryDate = batchInfo[0].expiryDate == null ? '' : batchInfo[0].expiryDate;
      //   }
      // }
    } else if (colId === "warehouseID") {
      if ([4, 8].some(e => e == this._transactionTypeId)) {
        let batches = this.getSelectableBatches().filter(item => item.warehouseID == params.data.warehouseID);
        // if (batches?.length > 0) {
        //   this.batchMasterData[params.rowIndex].batchNo = batches[0].batchID;
        //   this.batchMasterData[params.rowIndex].availableQty = batches[0].availableQty;
        //   this.batchMasterData[params.rowIndex].manufacturedDate = batches[0].manufacturedDate == null ? '' : batches[0].manufacturedDate;
        //   this.batchMasterData[params.rowIndex].expiryDate = batches[0].expiryDate == null ? '' : batches[0].expiryDate;
        //   this.batchMasterData[params.rowIndex].description = batches[0].description;
        // } else {
        //   this.batchMasterData[params.rowIndex].batchID = '';
        //   this.batchMasterData[params.rowIndex].availableQty = '';
        //   this.batchMasterData[params.rowIndex].manufacturedDate = '';
        //   this.batchMasterData[params.rowIndex].expiryDate = '';
        //   this.batchMasterData[params.rowIndex].description = '';
        // }
        params.api.refreshCells({
          force: true,
        });
        this.setNameCellEditorValues(batches);
      }
    }

    // else if (colId === "warehouseID") {
    //   let batches = this.lstBatch.filter(item => item.warehouseID == params.data.warehouseID);
    //   if (batches?.length > 0) {
    //     this.batchMasterData[index].batchNo = batches[0].batchNo;
    //     this.batchMasterData[index].availableQty = batches[0].availableQty;
    //     this.batchMasterData[index].manufacturedDate = batches[0].manufacturedDate == null ? '' : batches[0].manufacturedDate;
    //     this.batchMasterData[index].expiryDate = batches[0].expiryDate == null ? '' : batches[0].expiryDate;
    //   } else {
    //     this.batchMasterData[index].batchID = '';
    //     this.batchMasterData[index].availableQty = '';
    //     this.batchMasterData[index].manufacturedDate = '';
    //     this.batchMasterData[index].expiryDate = '';
    //   }
    //   params.api.refreshCells({
    //     force: true,
    //   });
    // }
  }

  private getSelectableBatches() {
    let selectedBatchs: { WarehouseID: number; BatchID: number; }[] = [];
    this.batchMasterData.filter(e => e.batchNo != '').forEach(e => {
      selectedBatchs.push({ WarehouseID: e.warehouseID, BatchID: e.batchNo });
    });
    let batches = [];
    this.lstBatch.forEach(e => {
      let isSelected = selectedBatchs.findIndex(sb => sb.WarehouseID == e.warehouseID && sb.BatchID == e.batchID);
      if (isSelected == -1) {
        batches.push(e);
      }
    });
    return batches;
  }

  lookupValue(warehouseList: any, params: any) {
    if (params.value == null || params.value == "" || params.value == undefined || warehouseList == null) {
      return;
    }
    else {
      let index = 0;
      if (params.column.colId == 'warehouseID') {
        index = warehouseList.findIndex(item => item.warehouseID == params.value);
        if (index != -1) {
          return warehouseList[index].warehouseName;
        } else {
          return "";
        }
      }
    }
  }

  batchListLookup(batchList: any, params: any) {
    if (params.value == null || params.value == "" || params.value == undefined || batchList == null) {
      return;
    }
    else {
      let index = 0;
      if (params.column.colId == 'batchNo') {
        if ([4, 8].some(e => e == this._transactionTypeId)) {
          // if (params.value.isNew != undefined) {
          //   index = batchList.findIndex(item => item.batchNo == params.value.batchNo);
          // } else {
          index = batchList.findIndex(item => item.batchID == params.value);
          // }
          if (index != -1) {
            return batchList[index].batchNo;
          } else {
            return "";
          }
        } else {
          return params.value;
        }
      }
    }
  }

  setNameCellEditorValues(values) {
    let colDefs: ColDef[] = this.batchItemsGridOptions.api.getColumnDefs();
    colDefs[1].cellEditorParams.lstBatch = values;
    colDefs[1].cellEditorParams.allBatchs = this.lstBatch;
    this.batchItemsGridOptions.api.setColumnDefs(colDefs);
  }

  setNameCellEditorValues_Warehouse(values) {
    let colDefs: any = this.batchItemsGridOptions.api.getColumnDefs();
    colDefs[0].cellEditorParams.lstWarehouse = values;
    this.batchItemsGridOptions.api.setColumnDefs(colDefs);
  }

  async createColumnDefs(transactionTypeID) {
    this.itemscolumnDefs = [
      {
        headerName: "Warehouse",
        field: "warehouseID",
        sortable: true,
        filter: true,
        wrapText: true,      
        cellEditor: 'Warehouse',
        hide: !this.isWarehouseEnabled,
        cellEditorParams: {
          lstWarehouse: []
        },
        cellStyle: {
          'overflow-y': 'auto!important',
          'overflow': 'visible'
        },
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Warehouse"),
        cellRenderer: (params: any) => {
          return this.lookupValue(this.lstWarehouse, params);
        },
        editable: (params) => {
          return params.data.IsLocked ? params.data.IsLocked == false : true;
        },
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        }
      },
      {
        headerName: "Batch Number",
        field: "batchNo",
        sortable: true,
        filter: true,
        wrapText: true,
        autoHeight: true,
        // cellEditor: 'alphanumeric',
        cellEditor: this.gridCellEditor(transactionTypeID),
        cellEditorParams: {
          lstBatch: [],
          allBatchs: [],
          isShowCreateNew: [4, 8].some(ele => ele == transactionTypeID) ? true : false,
          isWarehouseEnabled: this.isWarehouseEnabled
        },
        editable: (params) => {
          return params.data.IsLocked ? params.data.IsLocked == false : true;
        },
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Batch Number"),
        cellRenderer: (params: any) => {
          return this.batchListLookup(this.lstBatch, params);
        },  
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        },
        cellStyle: { height: '40px !important;' },
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        filter: true,
        wrapText: true,
        editable: (params) => this.onGridCellEditable(params, transactionTypeID),
        autoHeight: true,
        cellEditor: 'alphanumeric',
        cellStyle: { height: '40px !important;' },
        headerValueGetter:(params)=>this.translate.instant("Common.Description")
      },
      {

        headerName: "Manufactured Date",
        field: "manufacturedDate",
        sortable: true,
        editable: (params) => this.onGridCellEditable(params, transactionTypeID),
        cellEditor: 'datePicker',
        valueFormatter: this.dateFormatter,
        filter: "agDateColumnFilter",
        filterParams: this.dateFilterParams,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Manufactured Date")
      },
      {
        headerName: "Expiry Date",
        field: "expiryDate",
        sortable: true,
        wrapText: true,
        editable: (params) => this.onGridCellEditable(params, transactionTypeID),
        cellEditor: 'datePicker',
        autoHeight: true,
        valueFormatter: this.dateFormatter,
        filter: "agDateColumnFilter",
        filterParams: this.dateFilterParams,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Expiry Date")
      },

      {
        headerName: "Quantity",
        field: "quantity",
        sortable: true,
        filter: true,
        wrapText: true,
        editable: true,
        autoHeight: true,
        cellEditor: 'quantity',
        cellEditorParams: {
          decimalType : 'Decimal10'
        },
        cellStyle: { height: '40px !important;' },
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        },
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Quantity")
      },
      {
        headerName: "",
        field: "Delete",
        editable: false,
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params) => {
          if (params.node.data.IsLocked && params.node.data.IsLocked) {
            return `<div  tabindex="-1" unselectable="on" role="gridcell" aria-colindex="1"  class="ag-cell ag-cell-not-inline-editing ag-cell-auto-height" style="left: 0px;bottom:0px; pointer-events: none; opacity: 0.4;">
            <span class="c-icon material-icons-outlined m-0" style="font-size:20px">lock</span>
          </div>`;
          }
          else {
            return '<span class="close-x" data-action="delete">x</span>';
          }
        },
        width: 50,
        maxWidth: 50,
      },
    ];
  }

  onGridCellEditable(params, transactionTypeId) {
    if (params.data.IsLocked == true) {
      return false;
    } else if ([4, 8].some(e => e == transactionTypeId) && params.data.isCreatedFromCN == false) {
      return false;
    } else {
      return true;
    }
  }

  gridCellEditor(transactionTypeId): string {
    if ([4, 8].some(e => e == transactionTypeId)) {
      return 'batchList';
    } else {
        return 'alphanumeric';
    }
  }

  async getBatchList(_itemID: number, _transactionDetailId: number, _transactionTypeId: number): Promise<any> {
    let data: any = await this.http.getserviceawait(environment.inventoryApiUrl + `/Inventory/GetBatchList?ItemId=${_itemID}&transDetailId=${_transactionDetailId}&transactionTypeId=${_transactionTypeId}`);
    if (data.isSuccess && data.responseData != null) {
      return data.responseData.map(row => ({
        batchID: row.batchID,
        batchNo: row.batchNo,
        availableQty: row.quantity,
        manufacturedDate: row.manufacturedDate,
        expiryDate: row.expiryDate,
        warehouseID: row.warehouseID
      }));
    }
  }

  addBatch(batchInfo: any) {
    this.lstBatch.push({
      batchID: batchInfo.batchID,
      batchNo: batchInfo.batchNo,
      availableQty: batchInfo.quantity,
      manufacturedDate: null,
      expiryDate: null,
      warehouseID: batchInfo.warehouseID
    });
  }

  private generateUniqueID(): number {
    while (true) {
      let newBatchId = Math.floor(Math.random() * 1000 + 1);
      let i = this.lstBatch.findIndex(e => e.batchID == (newBatchId));
      if (i == -1)
        return newBatchId;
    }
  }

  async onDeleteVCreditNoteBatchDetails() {
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    let dbResponse: any = await this.http.deleteservice(environment.purchaseApiUrl + `/Creditnotes/onDeleteVCNBatch?CNTransId=${this._transactionDetailId}&ItemID=${this.itemID}`).toPromise();
    if (dbResponse['isSuccess'] as boolean == true) {
      let msg = this.translate.instant("Common.Saved successfully");
      this.alertMessage.successNotifier(msg, this.succesNotifierId);
      this.emitAfterBatchorSerialSaved.emit({
        transactionTypeID: 4,
        transactionTypeName: 'VCN',
        transactionDetailID: this._transactionDetailId,
        track:this.tracktype
      });
      this.closeModal();
    } else {
      this.alertMessage.errorNotifier(dbResponse.responseData.message, this.succesNotifierId);
    }
  }
 
}
