import { Component, OnInit } from "@angular/core";
import { Subject } from 'rxjs';
import { LoaderService } from '../../../../utilities/_services/loader.service';

@Component({
  selector: "app-loader",
  templateUrl: "./loading-dialog.component.html",
  styleUrls: ["./loading-dialog.component.scss"]
})
export class LoadingDialogComponent {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  constructor(private loaderService: LoaderService) {}
}
