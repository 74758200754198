<div bsModal #modalCreateSerial="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="modalCreateSerialLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-xl modal-dialog-scrollable1" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font-weight-bold">{{'InventoryModule.Item.Tracking Method' | translate}} - {{trackingMethod}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal needs-validation">
          <div class="">
            <div class="row-eq-height">
              <div class="col track-box-success">
                <div class="bd-highlight text-center  p-1">
                  <div class="header-4 text-grey">{{'InventoryModule.Item.Item Code' | translate}}: {{serialItemDetails.itemCode}}</div>
                  <div class="header-h-18 ">{{serialItemDetails.itemName}}</div>

                </div>
              </div>
              <!-- <div class="col track-box-warning">
                <div class="bd-highlight text-center p-1">
                  <div class="header-4 text-grey">{{_quantityLabel}} Quantity</div>
                  <div class="header-h-18">{{_POQuantity}}</div>
                </div>
              </div> -->
              <!-- *ngIf="_transactionTypeId==20" -->
              <div class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <!-- <div class="header-4 text-grey">{{_requiredorReceivedQtyLabel}}</div> -->
                  <div class="header-4 text-grey">{{_quantityLabel}} {{'InventoryModule.Item.Quantity' | translate}}</div>
                  <div class="header-h-18">{{_requiredQuantity}}</div>
                </div>
              </div>
              <div class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <div class="header-4 text-grey">{{'InventoryModule.Item.Quantity with Serial' | translate}}</div>
                  <div class="header-h-18">{{assignedQty}}</div>
                </div>
              </div>
              <div class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <div class="header-4 text-grey">{{'InventoryModule.Item.Untracked Quantity' | translate}}</div>
                  <div class="header-h-18">{{_requiredQuantity - assignedQty}}</div>
                  <!-- <div class="header-h-18">{{unAssignedQty}}</div> -->
                </div>
              </div>
            </div>
          </div>
          <ng-container *userCanEdit="[true]">
            <div class="custom-filter" *ngIf="isEnabledActionButtons">
                <form class="form-horizontal needs-validation" novalidate="novalidate" [formGroup]="settingsForm">
                  <div class="row">
                    <div class="col-md-2 pr-0" *ngIf="isWarehouseEnabled">
                      <label class="col-form-label" for="WarehouseID">{{'InventoryModule.Item.Warehouse' | translate}}</label>
                      <ng-select formControlName="WarehouseID" [items]="lstWarehouse" bindValue="warehouseID"
                        id="WarehouseID" bindLabel="warehouseName" [clearable]="false" [clearOnBackspace]="false"
                        [ngClass]="{ 'is-invalid': submitted && f.WarehouseID.errors }">
                      </ng-select>
                      <div *ngIf="submitted && f.WarehouseID.errors" class="invalid-feedback">
                        <div *ngIf="f.WarehouseID.errors.required">{{'InventoryModule.Item.Please select Warehouse' | translate}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 pr-0">
                      <label class="col-form-label" for="Prefix">{{'InventoryModule.Item.Prefix' | translate}}</label>
                      <input type="text" formControlName="Prefix" class="form-control" xssDirective />
                    </div>
                    <div class="col-md-2 pr-0">
                      <!-- <label class="col-form-label" for="StartingNo">Starting No.<span class="text-danger">*</span></label> -->
                      <label class="col-form-label" for="StartingNo">{{'InventoryModule.Item.Starting No' | translate}}.<span class="text-danger">*</span>
                        <!-- <button type="button" triggers="mouseenter:mouseleave" [popover]="popTemplateHtml"
                          popoverTitle="" class="btn inforight  pb-0">
                          <i class="fa fa-info-circle"></i></button>
                        <ng-template #popTemplateHtml>
                          <div><span class="help-block text-warning">Starting No. will include</span>
                          </div>
                        </ng-template> -->
                      </label>
                      <input type="text" formControlName="StartingNo" class="form-control" id="StartingNo"
                        autocomplete="off" numbers [ngClass]="{ 'is-invalid': submitted && f.StartingNo.errors }"
                        xssDirective />
                      <div *ngIf="submitted && f.StartingNo.errors" class="invalid-feedback">
                        <div *ngIf="f.StartingNo.errors.required">{{'InventoryModule.Item.Please enter Starting No.' | translate}}</div>
                      </div>
                    </div>
                    <div class="col-md-2 pr-0">
                      <label class="col-form-label" for="EndingNo">{{'InventoryModule.Item.Ending No' | translate}}.<span class="text-danger">*</span></label>
                      <input type="text" formControlName="EndingNo" class="form-control" id="EndingNo" autocomplete="off"
                        numbers [ngClass]="{ 'is-invalid': submitted && f.EndingNo.errors }" xssDirective />
                      <div *ngIf="submitted && f.EndingNo.errors" class="invalid-feedback">
                        <div *ngIf="f.EndingNo.errors.required">{{'InventoryModule.Item.Please enter Ending No.' | translate}}</div>
                      </div>
                    </div>
                    <div class="col-md-1 pr-0">
                      <label class="col-form-label" for="Postfix">{{'InventoryModule.Item.Postfix' | translate}}</label>
                      <input type="text" formControlName="Postfix" class="form-control" id="Postfix" autocomplete="off"
                        xssDirective />
                    </div>
                    <div class="col-md-2 pr-0">
                      <label class="col-form-label" for="ManufacturedDate">{{'InventoryModule.Item.Manufactured Date' | translate}}</label>
                      <input type="text" formControlName="ManufacturedDate" id="ManufacturedDate" autocomplete="off"
                        class="form-control" placeholder="dd/mm/yyyy" name="d1" date ngbDatepicker maxlength="10"
                        autocomplete="off" #d1="ngbDatepicker" (click)="d1.toggle()" readonly [maxDate]="maxDate"
                        (dateSelect)="onManufacturedDateSelect()" style="background-color:#fff !important" />
                    </div>
                    <div class="col-md-2 pr-0">
                      <label class="col-form-label" for="ExpiryDate">{{'InventoryModule.Item.Expiry Date' | translate}}</label>
                      <input type="text" formControlName="ExpiryDate" id="ExpiryDate" class="form-control"
                        autocomplete="off" placeholder="dd/mm/yyyy" name="d2" date ngbDatepicker maxlength="10"
                        autocomplete="off" #d2="ngbDatepicker" (click)="d2.toggle()" readonly [minDate]="minDate"
                        (dateSelect)="onExpiryDateSelect()" style="background-color:#fff !important" />
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="col-form-label" for="SerialNoBarcode">{{'InventoryModule.Item.Scan Serial No. Bar Code' | translate}}</label>
                        <input type="text" formControlName="SerialNoBarcode" class="form-control" autocomplete="off"
                          (paste)="onBarCodeBind($event)" />
                      </div>
                      <div class="col-md-2 text-end" style="margin-top: 35px;">
                        <input type="checkbox" class="Checked checkbox" formControlName="isAutoPush"> {{'InventoryModule.Item.Auto Push' | translate}}
                      </div>
                      <div class="col-md-6 text-end" style="margin-top: 35px;">
                        <button class="btn btn-success" type="button" (click)="onUISerialDetailDataBinding()">
                          {{'InventoryModule.Item.Generate' | translate}}
                        </button>
                        <button class="btn btn-danger " type="button" (click)="clearValues()">
                          {{'InventoryModule.Item.Reset' | translate}}
                        </button>
                      </div>
                    </div>
                    <!-- <div class="col-md-12 mt-2 text-end">
                      <button class="btn btn-success" type="button" (click)="onUISerialDetailDataBinding()">
                        Generate
                      </button>
                      <button class="btn btn-danger" type="button" (click)="clearValues()">
                        Reset
                      </button>
                    </div> -->
                  </div>
                </form>
              </div>
          </ng-container>


          <div class="row mt-4">
            <div class="col-md-5">
              <div class="ml-1" *userCanDelete="[true]">
                <button [disabled]="loading" class="btn btn-danger" type="button" *ngIf="selectedRowCount>0"
                  (click)="onDelete()">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{'Common.Delete' | translate}}</button>
              </div>
            </div>
            <div class="col-md-7 pull-right">
              <div class="d-flex justify-content-end">
                <!-- <div class="text-right" *userCanDelete="[true]">
                  {{totalGridRows}} Items<br>
                  <span class="t-link edit">{{selectedRowCount}} Item selected</span>
                </div> -->
                <div class="col-md-4 pr-0">
                  <div class="form-group search">
                    <span class="material-icons-outlined icon-search">search</span>
                    <input type="text" id="search-box" class="form-control" [(ngModel)]="searchText"
                      [ngModelOptions]="{standalone: true}" (input)="onQuickFilterChanged()" placeholder="{{'Common.Search' | translate}}"
                      xssDirective autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <div class="custom-ag-grid-4 mt-2 mb-0 ag-grid-modal">
              <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-material" agGridXssDirective
                [modules]="modules" [columnDefs]="serialDetailColumnDefs" [singleClickEdit]="false"
                [rowData]="serialDetailRowData" rowSelection="multiple" [gridOptions]="serialDetailGridOptions"
                [pagination]="true" [paginationPageSize]="paginationPageSize" (cellClicked)="onCellClicked($event)"
                [singleClickEdit]="true" (rowSelected)="onRowSelected($event)" [suppressRowClickSelection]="true"
                [stopEditingWhenCellsLoseFocus]="true" [frameworkComponents]="serialMasterframeworkComponents">
              </ag-grid-angular>
              <div class="d-flex ag-pagination">
                <label class="col-form-label" for="PageSize">{{'Common.Page Size' | translate}}: </label>
                <div style=" width: 70px;">
                  <ng-select [(ngModel)]="paginationPageSize" [ngModelOptions]="{standalone: true}"
                    (change)="onPageSizeChanged()" [items]="lstPagination" id="PageSize" bindValue="value"
                    bindLabel="text" [clearable]="false" [clearOnBackspace]="false">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="mb-3">
          <div class="col sec-btn" *ngIf="isEnabledActionButtons">
            <button *userCanEdit="[true]" class="btn btn-success btn-normal" (click)="onSubmit()">{{'Common.Save' | translate}}</button>
            <button class="btn btn-danger btn-normal" type="button" (click)="closeModal()">
              {{'Common.Cancel' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
