<div style="height: 100%; width:100%;min-width: 170px;">
    <ng-select [(ngModel)]="batchInfo" [items]="lstBatch" bindValue="batchID" (change)="onChange()"
        placeholder="Select Batch" bindLabel="batchNo" (search)="onSearchForApi($event)">
        <ng-template ng-header-tmp *ngIf="isShowCreateNew">
            <a class="t-link" (click)="saveNewBatch()"><b>+</b>Add New Batch
            </a>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
            {{item.batchNo }}
        </ng-template>
    </ng-select>
</div>