import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentsService } from '../../../utilities/_services/payments.service';

@Component({
  selector: 'app-onlinepayment',
  templateUrl: './onlinepayment.component.html',
  styleUrls: ['./onlinepayment.component.scss']
})
export class OnlinepaymentComponent implements OnInit {

  TransactionDatakey: any;
    key: any;
    KeyId: any;
  sessionId: any;
  status: any;
  resObj: any;
  paymentresObj: any;
  statusMessage: any;
  isSuccess: any
  invoiceno: any;
  replyTo: string = "";
  from: any;
  paymentId: any;
  token: any;
  PayerID: any;
  constructor(private route: ActivatedRoute, private router: Router, private payment: PaymentsService) {
    this.route.queryParams.subscribe(params => {

      this.from = params.from;
      this.key = params.key;
      this.status = params.status;
      this.TransactionDatakey = params.TransactionDatakey;
      
      if (this.from == 'paypal') {
        this.paymentId = params.paymentId;
        this.token = params.token;
        this.PayerID = params.PayerID;
      }
      else {
          this.KeyId = params.keyId;
        this.sessionId = params.id;
      }
    });
  }

  async ngOnInit() {
    if (this.from == 'paypal') {
      await this.payment.VerifyPayPalCallBack(this.key,this.paymentId,this.token ,this.PayerID, this.status).then((res) => this.paymentresObj = res);
      this.statusMessage = this.paymentresObj.statusMessage;
      this.isSuccess = this.paymentresObj.isSuccess;
    }
    else {
        await this.payment.VerifyKeyandsession(this.key, this.sessionId, this.TransactionDatakey, this.status, this.KeyId).then((res) => this.paymentresObj = res);
      this.statusMessage = this.paymentresObj.statusMessage;
      this.isSuccess = this.paymentresObj.isSuccess;
    }

    await this.payment.VerifyKey(this.key).then((res) => this.resObj = res);
    this.replyTo = this.resObj.ResponseData.ReplyToEmail;

    if (this.isSuccess === true) {
      let emailTemplates = new EmailTemplate();
      emailTemplates.to = this.replyTo;
      emailTemplates.orgId = this.resObj.ResponseData.OrgID;
      emailTemplates.userId = this.resObj.ResponseData.UserId;
      emailTemplates.customerCode = this.resObj.ResponseData.CustCode;
      emailTemplates.transId = this.resObj.ResponseData.TransID;
      //  emailTemplates.contactname=this.paymentresObj.responseData['contactName'];
      //  emailTemplates.InvoiceNo=this.paymentresObj.responseData['InvoiceNo'];
      //  emailTemplates.InvoiceAmount=this.paymentresObj.responseData['InvoiceAmount'];
      emailTemplates.body = "Hi, " +
        "The payment for Invoice " + this.paymentresObj.responseData['invoiceNo'] + " from " + this.paymentresObj.responseData['contactName'] + " has been completed successfully" +
        "Thanks "
      let response = this.payment.emailOnlineInvoicePayment(emailTemplates,this.paymentresObj.responseData['contactName'],this.paymentresObj.responseData['invoiceNo']);
    }

  }
  onBackToOnlineInv() {
    this.router.navigate(['onlineinvoice'], { queryParams: { key: this.key } })
  }
}

export class EmailTemplate {
  userId: number;
  orgId: number;
  to: string;
  subject: string;
  body: string;
  customerCode: number;
  transId: number;
  contactname: string;
  InvoiceNo: string;
  InvoiceAmount: number;
}
