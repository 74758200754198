import { PrintType } from "./enumlist";
import { HTMLResult } from "./groupparams";
import { TemplateProperties } from "./templateproperties";


export class UpdateTemplateStatement {
    templateStatement: TemplateStatement;
    templateStatementParams: TemplateStatementParams;
}

export interface HTMLTemplateStatement {
    templateStatement: TemplateStatement;
    htmlResult: HTMLResult;
}

export class TemplateStatementParams {
    userId: number=0;
    orgId: number=0;
    templateId: number=1;
    templateType: string="templatedetails";
    isMockData: boolean=true;
    printType: PrintType;
    statementType:string;
    fromDate:string;
    toDate:string;
    groupId:number;
    transIds: string;
    masterCode:string = "STD";
    interval:number;
    duration:number;
    ageType:string;
}

export class TemplateStatement {
    templateStatementProperties: TemplateStatementProperties;
    templateStatementHeader: TemplateStatementHeader;
    templateStatementItem:TemplateStatementItem;
    templateStatementTotal:TemplateStatementTotal;
    templateStatementFooter: TemplateStatementFooter;
    templateStatementAnnexure: TemplateStatementAnnexure;
    templateStatementCustomFields:any;
    templateProperties:TemplateProperties;
}

export class TemplateStatementProperties {
    id: number;
    tmStmId: number;
    templateName: string;
    paperSizeId: number;
    orientationId: number;
    marginTop: number;
    marginBottom: number;
    marginLeft: number;
    marginRight: number;
    pDFFontId: number | null;
    fontFaceCss: string;
    fontFamilyCss: string;
    addAttention: string;
    attentionPHTypeId: number;
    backImage: string;
    backImageInBase64: string;
    backImageSize: string;
    imagePositionId: number;
    imagePostition: string;
    colorThemeId: number;
    colorTheme: string;
    showBackColor: boolean;
    backColor: string;
    labelColor: string;
    fontColor: string;
    fontSize: number;
    showPaymentStub:boolean;
    paymentStubLabel:string;
    paymentStubPositionId:number;
}

export class TemplateStatementHeader {
    iD: number;
    tmStmId: number;
    showOrgLogo: boolean;
    orgLogo: string;
    orgLogoInBase64: string;
    orgWidth: string;
    orgHeight: string;
    showOrgName: boolean;
    showOrgAddress: boolean;
    orgAddressFormat: string;
    orgAddressPHTypeId: number;
    showDocTitle: boolean;
    docTitle: string;
    backImage: string;
    backImageInBase64: string;
    backImageSize: string;
    imgPosId: number;
    imagePostition: string;
    showBackColor: boolean;
    backColor: string;
    headerContent: string;
    applyFirstPage: boolean;
    headerContentForOtherPages: string;
    headHeightFirstPage: number;
    docTitleFontSize: number;
    docTitleFontColor: string;
    orgNameColor: string;
    orgNameFontSize: number;
    orgPhone: string;
    orgFax: string;
    vendFontColor: string;
    vendFontSize: number;
    showBillTo: boolean;
    billTo: string;
    billToAddress: string;
    billAddressPHTypeId: number;
    showFromDateFld: boolean;
    fromDateFld: string;
    showToDateFld: boolean;
    toDateFld: string;
    showBalanceDue: boolean;
    orgLogoWidthPerc: number;
  orgAddressWidthPerc: number;
  docInfoWidthPerc: number;
  orgLogoWidthLine: number;
  orgAddressWidthLine: number;
  docInfoWidthLine: number;
  showHdrBackColor: boolean;
}

export class TemplateStatementItem{
    templateStatementItemLabel:TemplateStatementItemLabel;
    templateStatementItemLayout:TemplateStatementItemLayout;
}

export class TemplateStatementItemLabel {
    id: number;
    tmStmId: number;
    showDate: boolean;
    dateWidth: number;
    dateLabel: string;
    showActivity: boolean;
    activityWidth: number;
    activityLabel: string;
    showReference: boolean;
    referenceWidth: number;
    referenceLabel: string;
    showDueDate: boolean;
    dueDateWidth: number;
    dueDateLabel: string;
    showInvoiceAmount: boolean;
    invoiceWidth: number;
    invoiceLabel: string;
    showPayments: boolean;
    paymentsWidth: number;
    paymentsLabel: string;
    showBalance: boolean;
    balanceWidth: number;
    balanceLabel: string;
    showRunningBalance: boolean;
    runningBalanceWidth: number;
    runningBalanceLabel: string;
    showAmtCurrency:boolean;
    showPayCurrency:boolean;
    showBalanceCurrency:boolean;
    showRunningBalanceCurrency: boolean;
}

export class TemplateStatementItemLayout {
    id: number;
    tmStmId: number;
    showTableBorder: boolean;
    tableBorder: string;
    thFontSize: number;
    showTHBackColor: boolean;
    thBackColor: string;
    thFontColor: string;
    irFontSize: number;
    showIRBackColor: boolean;
    irBackColor: string;
    irFontColor: string;
    idFontSize: number;
    idFontColor: string;
}

export class TemplateStatementTotal{
    templateStatementTotalLabel:TemplateStatementTotalLabel;
    templateStatementTotalLayout:TemplateStatementTotalLayout;
}

export class TemplateStatementTotalLabel {
    id: number;
    tmStmId: number;
    currencySymbolDisplayId: number;
    balanceDueText:string;
}

export class TemplateStatementTotalLayout {
    id: number;
    tmStmId: number;
    balDueFontSize: number;
    balDueFontColor: string;
    showBalDueBackColor: boolean;
    balDueBackColor: string;
}

export class TemplateStatementFooter {
    id: number;
    tmStmId: number;
    footFontSize: number;
    footFontColor: string;
    showFootBackColor: boolean;
    footBackColor: string;
    backImage: string;
    backImageInBase64: string;
    backImageSize: string;
    imagePositionId: number;
    imagePostition: string;
    showPageNum: boolean;
    customFooterContent: string;
    customFooterPHTypeId: string;
}

export class TemplateStatementAnnexure {
    id: number;
    tmStmId: number;
    annexContent: string;
    annexPHTypeId: number;
}

export class StatementDetails {
    tmStmId: number;
    statementTransID:number;
    headerContent: string;
    orgName: string;
    orgAddress: string;
    billToAddress: string;
    docNumber: string;
    dateField: string;
    dueDateField: string;
    refField: string;
    addAttenContent: string;
    itemList: StatementItemList[];
    currencySymbol: string;
    customFooterContent: string;
    annexure: string;
    balanceDueText:string;
    balanceDue:number;
}

export class StatementItemList {
    tmStmId: number;
    statementTransID:number;
    stmDate: number;
    activity: string;
    reference: string;
    dueDate: number;
    invoiceAmount: string;
    payments: string;
    balance: string;
}
