import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-ccell',
  templateUrl: './simple-ccell.component.html',
  styleUrls: ['./simple-ccell.component.scss']
})
export class SimpleCCellComponent implements OnInit {
  @Input() row: any;
  @Input() column: CTableColumn;
  @Input() rowType: RowType;

  constructor() { }

  ngOnInit(): void {
  }

}

export class CTableColumn {
  field: string;
  title: string;
  type: string;
  align: string;
  isVisible: boolean;
  isEditable: boolean;
  isCustom: boolean;
  isMandatory: boolean;
  css: any;
}
export enum RowType {
  Parent = 1,
  Child = 2
}
