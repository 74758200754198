import { ICellEditorParams, IAfterGuiAttachedParams, BodyDropPivotTarget } from '@ag-grid-community/all-modules';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { random } from 'node-forge';
import { ToBatchModel } from '../../../inventory/stock-transfer/stock-transfer.interface';

@Component({
  selector: 'app-dropdown-with-add-new',
  templateUrl: './dropdown-with-add-new.component.html',
})
export class DropdownWithAddNewComponent implements ICellEditorAngularComp {
  newLabel: string;

  @ViewChild('selectToBatch') ngSelectBatch: NgSelectComponent;

  selectList: ToBatchModel[] = [];
  isNewBatchNo: boolean;
  selectedBatch: number;
  params: any;
  targetWarehouseID: number;
  allBatchsInTargetWarehouse: any = [];

  constructor() { }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    this.ngSelectBatch.isOpen = true;
    this.ngSelectBatch.focus();
  }

  getValue() {
    return this.selectList.find(e => e.BatchID == Number(this.selectedBatch));
  }

  isPopup?(): boolean {
    return true;
  }

  agInit(params: any): void {
    this.params = params;
    this.targetWarehouseID = this.params.targetWarehouseID;
    if (this.params.targetWarehouseID && this.params.targetWarehouseID != 0) {
      let batches = this.params.selectList
        .filter(e =>
          e.WarehouseID == this.params.targetWarehouseID ||
          e.WarehouseID == this.params.sourceWarehouseID);
      this.allBatchsInTargetWarehouse = batches.filter(e => e.WarehouseID == this.params.targetWarehouseID);
      let fromBatch = batches.find(e => e.BatchID == params.data.FromBatch);
      if (fromBatch) {

        // Filter batchs with same manufature and expiry date
        batches.filter(sl => this.batchesWithSameManfactureAndExpiryDate(sl, fromBatch))
          .forEach(e => this.selectList.push(e));

        // If the FromBatchNo already exist in target warehouse, then it should not show from the other warehouses
        if (this.selectList.findIndex(e =>
          e.BatchNo == fromBatch.BatchNo &&
          e.WarehouseID == this.targetWarehouseID) != -1
        ) {
          this.selectList = this.selectList
            .filter(e => {
              if (e.WarehouseID != this.targetWarehouseID)
                return e.BatchNo != fromBatch.BatchNo
              else
                return true;
            });
        }

        // If BatchNo already exist in Target warehouse, remove the batches in the other warehouses
        let batchsInTarget = this.selectList.filter(e => e.WarehouseID == this.targetWarehouseID);
        batchsInTarget.forEach(e => {
          let index = this.selectList.findIndex(bt => bt.WarehouseID != this.targetWarehouseID && bt.BatchNo == e.BatchNo);
          if (index != -1) {
            this.selectList.splice(index, 1);
          }
        });

      }

      if (this.params.value && this.params.value.BatchID) {
        this.selectedBatch = this.params.value.BatchID;
        let selectedBatchIsLocallyCreated: ToBatchModel = batches
          .find(e => e.BatchID == this.selectedBatch &&
            (this.selectList.findIndex(sl => sl.BatchID == this.selectedBatch) == -1));
        if (selectedBatchIsLocallyCreated && selectedBatchIsLocallyCreated.isLocallyCreated) {
          this.selectList.push(selectedBatchIsLocallyCreated);
        }
      } else {
        this.selectedBatch = null;
      }
    } else {
      this.selectList = [];
      this.selectedBatch = null;
    }
  }

  private batchesWithSameManfactureAndExpiryDate(sl: any, fromBatch: any) {
    let man = sl.ManufacturedDate != null ? (sl.ManufacturedDate as string).split('T')[0] : null;
    let exp = sl.ExpiryDate != null ? (sl.ExpiryDate as string).split('T')[0] : null;
    let fman = fromBatch.ManufacturedDate != null ? (fromBatch.ManufacturedDate as string).split('T')[0] : null;
    let fexp = fromBatch.ExpiryDate != null ? (fromBatch.ExpiryDate as string).split('T')[0] : null;
    if (man == fman && exp == fexp)
      return true
    // Match if both expiry and manfacture date is Null
    // else if (!sl.ExpiryDate && !sl.ManufacturedDate && !fromBatch.ManufacturedDate && !fromBatch.ExpiryDate) {
    //   return true;
    // }
    return sl.BatchID == fromBatch.BatchID;
  }

  formatValue() { }

  public GetValueModel() {
    return ''
  }

  onFocus() {
    this.isNewBatchNo = false;
    this.newLabel = '';
  }

  onClear() {
    this.selectedBatch = null;
    this.newLabel = '';
    this.isNewBatchNo = false;
  }

  onSearchForApi($event) {
    this.isNewBatchNo = false;
    let c = this.selectList.filter(x => x.BatchNo.trim().toString().toUpperCase() == $event.term.trim().toString().toUpperCase());
    if (c.length == 0 && $event.term.trim().length > 0) {
      this.isNewBatchNo = true;
    }
    this.newLabel = $event.term.trim();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.BatchNo.toLocaleLowerCase().indexOf(term) > -1;
  }

  onChangeValue(e) {
    let isInTargetWarehouse = this.selectList.findIndex(sl => sl.BatchID == e.BatchID && sl.WarehouseID == this.targetWarehouseID);
    if (isInTargetWarehouse == -1) {
      let newBatchID = this.getNewBatchIDRandom();
      this.selectList.push({
        BatchNo: e.BatchNo,
        BatchID: newBatchID,
        WarehouseID: this.targetWarehouseID,
        isLocallyCreated: true,
        newBatchWithExistingName: true,
        WarehouseName: 'New Batchs in Target Warehouse',
        ManufacturedDate: e.ManufacturedDate,
        ExpiryDate: e.ExpiryDate,
        Description: e.Description
      });
      this.selectedBatch = Number(newBatchID);
    }

    this.params.api.stopEditing();
  }

  private getNewBatchIDRandom() {
    let newBatchNo = '';
    while (true) {
      newBatchNo = Math.floor(Math.random() * 200 + 1).toString();
      let i = this.selectList.findIndex(e => e.BatchID == Number(newBatchNo));
      if (i == -1)
        break;
    }
    return Number(newBatchNo);
  }

  saveNewBatch() {
    if (this.newLabel) {
      let labelInList = this.selectList.findIndex(e => e.BatchNo.toLowerCase() == this.newLabel.trim().toLowerCase());
      if (labelInList == -1) {
        let newBatchNo = this.getNewBatchIDRandom();
        this.selectList.push({
          BatchNo: this.newLabel,
          BatchID: newBatchNo,
          WarehouseID: this.targetWarehouseID,
          isLocallyCreated: true,
          WarehouseName: 'New Batchs in Target Warehouse',
          ManufacturedDate: null,
          ExpiryDate: null,
          Description: null
        });
        this.selectedBatch = Number(newBatchNo);
        this.ngSelectBatch.isOpen = false;
        this.params.api.stopEditing();
      } else {
        this.selectedBatch = this.selectList[labelInList].BatchID;
      }
    }
  }

}
