import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditLayoutNodeService {
  dataChange = new BehaviorSubject<ELTreeNode[]>([]);

  get data(): ELTreeNode[] { return this.dataChange.value; }

  constructor() { }

  buildFileTree(data: ELTreeNode) {
    this.dataChange.next(this.data)
  }

  insertNode(parentNode: ELTreeNode, name: ELTreeNode) {
    this.dataChange.next(this.data);
    return parentNode;
  }

  updateItem(node: ELTreeNode) {
    this.dataChange.next(this.data);
  }

}

export class ELTreeNode {
  public expandable: boolean;
  public level: number;
  Children: ELTreeNode[];
  LayoutID: number;
  LayoutName: string;
  GroupID: number;
  GroupName: string;
  ParentGroupID: number;
  DisplayOrder: number;
  IsDragable: boolean;
  IsExpanded: boolean;
  IsTotalRequired: boolean;
  IsDebit: boolean;
  IsCredit: boolean;
  IsFormula: boolean;
  Formula: string;
  FormulaDesc: string;
  CAAccountID: number;
  AccountCode: string;
  SystemCode: string;
  AccountName: string;
  AccountTypeID: string;
  AccDisplayOrder: number;
  AccountType: string;
  IsNode: any;
  RowLevel: number;
  RowNo:number;
  RowType:string;
  SwitchGroupID:number;
  BankAccTypeID:string;
}

export class ELTreeFlatNode {
  public expandable: boolean;
  public level: number;
  LayoutID: number;
  LayoutName: string;
  GroupID: number;
  GroupName: string;
  ParentGroupID: number;
  DisplayOrder: number;
  IsDragable: boolean;
  IsExpanded: boolean;
  IsTotalRequired: boolean;
  IsDebit: boolean;
  IsCredit: boolean;
  IsFormula: boolean;
  Formula: string;
  FormulaDesc: string;
  CAAccountID: number;
  AccountCode: string;
  SystemCode: string;
  AccountName: string;
  AccountTypeID: string;
  AccDisplayOrder: number;
  AccountType: string;
  IsNode: any;
  RowLevel: number;
  RowNo:number;
  SwitchGroupID:number;
  BankAccTypeID:string;

}

export interface DropInfo {
  targetId: string;
  action?: string;
}

