export enum EmailTemplateTypes
{
    InvitationEmail=1,
    ForgotPassword=2,
    BillEmail=3,
    PurchaseOrder=4,
    QuoteEmail=5,
    CreditNoteEmail=6,
    InvoiceEmail=7,
    CustomerStatement=8,
    DeliveryOrder=9,
    SalesOrder=13,
    DebitNote=15,
    MyInvoisEmail=18
}