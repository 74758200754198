import {Injectable} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
@Injectable()
export class CustomDateParserFormatter_DateMonth {
  parse(value: string): NgbDateStruct
  {
    if (!value)
      return <any>null
     let parts=value.split('/');
     return {year:+parts[0],month:+parts[1],day:+parts[2]} as NgbDateStruct

  }
  format(date: NgbDateStruct): string
  {
    return date? ('0'+date.day).slice(-2) + "-" +('0'+date.month).slice(-2):<any>null
  }
}

@Injectable()
export class CustomDateParserFormatter_YearMonth {
  parse(value: string): NgbDateStruct
  {
    if (!value)
      return <any>null
     let parts=value.split('/');
     return {year:+parts[0],month:+parts[1],day:+parts[2]} as NgbDateStruct

  }
  format(date: NgbDateStruct): string
  {
    return date?date.year+"-"+('0'+date.month).slice(-2):<any>null
  }
}

@Injectable()
export class CustomDateParserFormatter_FullDate {
  parse(value: string): NgbDateStruct
  {
    if (!value)
      return <any>null
     let parts = value.split('/');
     return {
       day:+parts[0],
       month:+parts[1],
       year:+parts[2]
      } 

  }
  format(date: NgbDateStruct): string
  {
    return date? ('0'+date.day).slice(-2) + "/" + ('0'+date.month).slice(-2) + "/" + (date.year):<any>null
  }
}