<app-header [navbarBrandRouterLink]="['/supportdashboard']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/brand/Info-Tech-Logo_sm.png', width: 80, height: 31, alt: 'Info-tech Logo'}"
  [navbarBrandMinimized]="{src: 'assets/brand/Info-Tech-Logo_xsm.png', width: 30, height: 10, alt: 'Info-tech Logo'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]="false" [mobileAsideMenuToggler]="false">
  <ul class="nav navbar-nav ml-auto c-header-nav ml-auto mr-2">
    <li class="c-header-nav-item d-md-down-none mx-1 dropdown">
      <a class="c-header-nav-link " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
        title="Apps">
        <div class="c-icon">
          <span class="material-icons-outlined">apps</span>
        </div>
      </a>
      <div class="dropdown-menu dropdown-menu-right pt-0">
        <a class="dropdown-item">
          <div class="avatar avatar-xs mr-3">
            <span class="avatar-text avatar-text-inv-primary rounded"><span class="initial-wrap"><span>AC</span></span>
            </span>
          </div>Accounting
        </a>
        <a class="dropdown-item" href="https://www.infotech-cloudhr.com.sg/" target="_blank" rel="noopener noreferrer">
          <div class="avatar avatar-xs mr-3">
            <span class="avatar-text avatar-text-inv-primary rounded"><span class="initial-wrap"><span>HR</span></span>
            </span>
          </div>HRMS
        </a>
      </div>
    </li>
    <li class="c-header-nav-item mx-1"><a class="c-header-nav-link" href="{{usermanualurl}}"
        target="_blank" role="button" aria-haspopup="true" aria-expanded="false" title="Help & Support">
        <div class="c-icon">
          <span class="material-icons-outlined text-dark">help_outline</span>
        </div>
      </a></li>
    <li class="c-header-nav-item mx-1"><a class="c-header-nav-link" (click)="logout();" role="button"
        aria-haspopup="true" aria-expanded="false" title="Logout">
        <div class="c-icon">
          <span class="material-icons-outlined">logout</span>
        </div>
      </a>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)">
    <div class="c-sidebar-nav-item dropdown">
      <div class="c-sidebar-user">
        <div class="c-avatar-lg"><img class="c-avatar-img" src="{{currentUser.image}}" alt="User Name">
        </div>
        <div class="c-sidebar-userdetails">
          <div class="c-sidebar-username">{{currentUser.firstName}}</div>
          <div class="c-sidebar-useraccount">{{currentUser.userRole}}</div>
        </div>
      </div>
      <ul class="dropdown-content user-expand">
        <li class="c-sidebar-nav-item">
          <div class="full-uname">{{currentUser.firstName}} {{currentUser.lastName}}</div><a
            class="user-link">{{currentUser.username}}</a>
        </li>
        <li class="c-sidebar-nav-item"><a class="c-sidebar-nav-link" (click)="logout();" role="button"
            aria-haspopup="true" aria-expanded="false"> Logout</a></li>
      </ul>
    </div>
    <app-sidebar-nav-custom class="sidebar-nav" [navItems]="sidenav" [perfectScrollbar]
      [disabled]="appSidebar.minimized"></app-sidebar-nav-custom>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main" style="min-height:84vh;height: auto;">
    <cui-breadcrumb>
      <li class="breadcrumb-menu d-md-down-none">
      </li>
    </cui-breadcrumb>
    <div class="container-fluid">
      <app-loader></app-loader>
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
</div>
<app-footer style="min-height: 50px;height: auto;">
  <div><a href="https://www.info-tech.com.sg/">Info-tech systems</a> © 2021 All rights reserved</div>
  <div class="ml-auto">Powered by&nbsp;<a href="https://www.info-tech.com.sg/">Info-tech systems</a></div>
</app-footer>
<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name"
  [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">You have been Idle!</h4>
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-body">
        <div class="row mt-2">
          <div class="col-12 text-center font-lg">
            {{idleState}}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 pull-right text-right">
            <button type="button" (click)="stay()" class="btn btn-success br-2">Continue</button>
            <button type="button" (click)="logout()" class="btn btn-danger">Logout</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
